
import { Component, OnChanges, OnInit, DoCheck, AfterContentChecked, AfterViewChecked, AfterViewInit, AfterContentInit, OnDestroy, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { IReport, IDemandItemNoBOMReport } from '../../core/models/report.model';
import { Helper } from '../../core/services/helper.service';
import { AlertService } from '../../core/services/alert.service';
import { ReportServiceHttp } from '../../core/services/report.servicehttp';
import { Router } from '@angular/router';
import { SortCriteria} from '../../core/models/sort.model';

@Component({
  selector: 'app-demanditemnobom',
  template: require('./demanditemnobom.component.html'),  
  styles: [require('./demanditemnobom.component.css')],
  providers: 
  []
    
})
export class DemandItemNoBOMComponent implements OnInit, AfterViewInit, AfterViewChecked, AfterContentChecked, OnDestroy, AfterContentInit {
  reports: IDemandItemNoBOMReport[]; 
  selectedReport: IReport;
  public term: string;  
  dir ="asc";  
  @ViewChild('reportForm' , {static: true}) reportForm: ElementRef;  
  @ViewChild('inputName' , {static: false}) inputName; 
  
  
  isLoading = true;

  constructor(
    private reportService: ReportServiceHttp,
    private alert: AlertService,
    private navRouter: Router,
    private utils: Helper   
    ) { }


  ngOnDestroy() {
      console.log('reports.component.ngOnDestroy');      
    }

  ngOnInit() {
    console.log('reports.component.ngOnInit');    

    this.reportService.getDemandItemNoBOMReport().subscribe(reports => {
      console.log('reports.component- Get Reports call to report service');
      this.isLoading = false;
      this.reports = reports;
      })  
    }

  onSorted(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'itemnumber')
          {           
           this.reports.sort(this.sortByItemNumberDesc)  
          }
          else if (criteria.sortColumn == 'itemdescription')
          {
            this.reports.sort(this.sortByItemDescriptionDesc)
          }
          else if (criteria.sortColumn == 'reported')
          {
            this.reports.sort(this.sortByReportedDesc)
          }
          
        }
        else {
          if(criteria.sortColumn == 'itemnumber')
          {
            this.reports.sort(this.sortByItemNumberAsc)
          }
          else if (criteria.sortColumn == 'itemdescription')
          {
            this.reports.sort(this.sortByItemDescriptionAsc)
          }
          else if (criteria.sortColumn == 'reported')
          {
            this.reports.sort(this.sortByReportedAsc)
          }
        }
      }
      
  }


//   sortTable(n, event) {
//   var elementId: string = (event.target as Element).id;
 

//   if (elementId.substring(0,4) == 'col1' && n == 0)
//   {    
//     if (this.dir == 'asc')
//     {
//       this.dir = 'desc'
//       this.reports.sort(this.sortByItemNumberAsc)
//     }
//     else 
//     {
//       this.dir = 'asc'
//       this.reports.sort(this.sortByItemNumberDesc)
//     }
    
//   }
//   if (elementId.substring(0,4) == 'col2' && n == 1)
//   if (this.dir == 'asc')
//     {
//       this.dir = 'desc'
//       this.reports.sort(this.sortByItemDescriptionAsc)
//     }
//     else 
//     {
//       this.dir = 'asc'
//       this.reports.sort(this.sortByItemDescriptionDesc)
//     }
//     if (elementId.substring(0,4) == 'col3' && n == 1)
//   if (this.dir == 'asc')
//     {
//       this.dir = 'desc'
//       //this.reports.sort(this.sortByReportedAsc)
//     }
//     else 
//     {
//       this.dir = 'asc'
//       //this.reports.sort(this.sortByReportedDesc)
//     }
// }


sortByItemNumberAsc(a1: IDemandItemNoBOMReport, a2: IDemandItemNoBOMReport){ 
  return Helper.cmpStringAlphaNumeric(a1.itemnumber, a2.itemnumber); 
}

sortByItemNumberDesc(a1: IDemandItemNoBOMReport, a2: IDemandItemNoBOMReport) { 
  return Helper.cmpStringAlphaNumericDesc(a1.itemnumber, a2.itemnumber); 
}

sortByItemDescriptionAsc(a1: IDemandItemNoBOMReport, a2: IDemandItemNoBOMReport) { 
  return Helper.cmpString(a1.itemdescription, a2.itemdescription); 
}

sortByItemDescriptionDesc(a1: IDemandItemNoBOMReport, a2: IDemandItemNoBOMReport) {
  return Helper.cmpStringDesc(a1.itemdescription, a2.itemdescription); 
}

sortByReportedAsc(a1: IDemandItemNoBOMReport, a2: IDemandItemNoBOMReport) { 
  return Helper.cmpDateTime(a1.reported, a2.reported); 
}

sortByReportedDesc(a1: IDemandItemNoBOMReport, a2: IDemandItemNoBOMReport) {
  return Helper.cmpDateTimeDesc(a1.reported, a2.reported); 
}

get isOverlayVisible() {
  return this.reportForm.nativeElement.offsetParent != null;
}

ngAfterContentChecked() {
  
}

 
ngAfterViewChecked() {
  const c1 = $('.col1').width();
  const c2 = $('.col2').width();
  const c3 = $('.col3').width();
  $('#col1').width(c1 + 1);
  $('#col2').width(c2);
  $('#col3').width(c3);
  $('.SearchBar').width(c1 + c2 + c3 + 12);

  var th = $('thead').width();
  $("#Cover").width(th);

 // $('#number').focus();
}
ngAfterViewInit() {
      console.log('Report.Component.AfterViewInit');      
      
      // runs every time the user re-sizes the window
          $(window).resize(function(){
        // gets td column widths
          const c1 = $('.col1').width();
          const c2 = $('.col2').width();
          const c3 = $('.col3').width();
          const th = $('thead').width();
        // sets th column widths to match td's
          $("#Cover").width(th+1);
          $('#col1').width(c1 + 1);
          $('#col2').width(c2);
          $('#col3').width(c3);
          $('.SearchBar').width(c1 + c2 + c3 + 12);
          console.log ('resize header occured');
          });
    }
    ngAfterContentInit() {
      $(document).ready(function(){
        $('.SearchField').focus();
      })
    }

}
