
import {Observable } from 'rxjs';
import { IRouter } from '../core/models/router.model';
import { RouterDomainService } from '../core/services/router-domain.service';
import { RouterDetailComponent } from './router-detail.component';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { RouterServiceHttp } from '../core/services/router.servicehttp';
import { of as observableOf} from 'rxjs/observable/of'

 @Injectable()
 export class CanDeactivateRouterDetail implements CanDeactivate<RouterDetailComponent> {
  constructor(private routerService: RouterServiceHttp,
  private domain: RouterDomainService) {}

  canDeactivate(
    component: RouterDetailComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> {

    if (component && component.hasRouterChanges) {
      const r: IRouter = component.newRouter;
      console.log('router-details.candeactivate id:' + r.partNumber + ' v' + r.revision);
       return this.routerService.updateRouter1(r);
    } else {
      return observableOf(true);
    }
  }
}
