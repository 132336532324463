
import {Observable } from 'rxjs';
import { StepNotesComponent } from './step-notes/step-notes.component';
import { IRouter } from '../core/models/router.model';
import { RouterDomainService } from '../core/services/router-domain.service';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { RouterServiceHttp } from '../core/services/router.servicehttp';
import { StepDetailComponent } from './step-detail.component';
import { RouterStepServiceHttp } from '../core/services/routerstep.servicehttp';
import { of as observableOf} from 'rxjs/observable/of'


 @Injectable()
 export class CanDeactivateStepNotes implements CanDeactivate<StepDetailComponent> {
  constructor(private stepService: RouterStepServiceHttp,
  private domain: RouterDomainService) {}

  canDeactivate(
    component: StepDetailComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> {

    console.log('step-detail.candeactivateNotes.canDeactivate');
    if (component && component.hasNotesChanges) {
      console.log('step-notes.candeactivate notes:' + component.newNotes);
       return this.stepService.updateRouterStepNotes1(component.newNotes);
    } else {
      return observableOf(true);
    }
  }
}
