import { Component, Input, OnInit, ViewChild, ElementRef, AfterContentChecked, OnDestroy, EventEmitter, Output } from '@angular/core';
import { WorkCenterDataService } from '../../core/services/workcenter-data.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IWorkCenter, ScheduleTypeUtils, WorkCenterTypeUtils, WorkCenterUtils, ScheduleType } from '../../core/models/workcenter.model';
import { WorkCenterService } from '../../core/services/workcenter.service';
import { WorkStationServiceHttp } from '../../core/services/workstation.servicehttp';
import { Helper } from '../../core/services/helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkCenterInfoComponentBizLogic } from '../../work-centers/work-center-detail/work-center-info.component.bizl';
import { IUserMaintenance, IAuthorizedWorkStation } from '../../core/models/usermaintenance.model';
import { IUser } from '../../core/shared/gatekeeper/user/user.response';
import { UserMaintenanceDataService } from '../../core/services/usermaintenance-data.service';
import { IWorkStation } from '../../core/models/workstation.model';
import { UserDetailComponentBizLogic } from './user-detail.component.bizl';
import { UserMaintenanceServiceHttp} from '../../core/services/usermaintenance.servicehttp';
import { AlertLevel, AlertReponse } from '../../core/models/alert.model';
import { AlertService } from '../../core/services/alert.service';
import {bindCallback} from 'rxjs/observable/bindCallback';

@Component({
  selector: 'user-info',
  template: require('./user-info.component.html'),
  styles: [require('./user-info.component.css').toString()]
})
export class UserInfoComponent implements OnInit, AfterContentChecked, OnDestroy {
 UserDetails: FormGroup;
 name: FormControl;
 employee: FormControl;
 warehouse: FormControl;
 workstation: FormControl;
 @ViewChild('userDetails', {static: true}) view: ElementRef;
 viewWasVisible = false;
 userId: string;
 userBizl: UserDetailComponentBizLogic;
 bizl: WorkCenterInfoComponentBizLogic;
 lastWorkCenterDescriptionValidationMsg: string = null;
 // get workCenters(): IWorkCenter[] { return this.data.workCenters; }
 userSubmitted = false;
 workStations: IWorkStation[];
 workStationObjectSelected: IWorkStation
 workStationSelected: string;

  constructor(public data: UserMaintenanceDataService
  , private userMaintenanceService: UserMaintenanceServiceHttp
  , private centerService: WorkCenterService
  , private wsService: WorkStationServiceHttp
  , private helper: Helper
  , private alert: AlertService
  , private navRouter: Router
  , private route: ActivatedRoute ) { }

  get selectedUser(){
    return this.data.selectedUser;
    }

   get selectedWorkStation(){
     return this.data.selectedWorkStation;
   }

  getWorkStations() {
      this.wsService.getWorkStations().subscribe(stations => 
      {
        this.workStations  = stations;
      });
    }

  ngOnInit() {
    console.log('user-info.component.ngOnInit');

    this.userId = this.route.snapshot.params['id'];

    this.name = new FormControl(this.selectedUser.firstName + ' ' + this.selectedUser.lastName,  Validators.required);
    this.employee = new FormControl(this.selectedUser.empNum, Validators.required);
    this.warehouse = new FormControl(this.selectedUser.defaultWarehouse1, Validators.required);
    this.workstation = new FormControl(this.selectedUser.workstationDescription, Validators.required);

    this.UserDetails = new FormGroup({
      name: this.name,
      employee: this.employee,
      warehouse: this.warehouse,
      workstation: this.workstation
    });

    this.getWorkStations();

    this.bizl = new WorkCenterInfoComponentBizLogic(this);
  }

  updateUserFormGroup(u: IUserMaintenance) {
    // console.log('user-info.updateWorkCenterFormGroup');
    // console.log(this.bizl);
    this.UserDetails.patchValue({
      name: u.userName,
      employee: u.employeeID,
      warehouse: u.defaultWarehouse1,
      workstation: u.workstationDescription
    });
    
    console.log(this.UserDetails.value)

    if (this.bizl.isWorkCenterEditable) {
      this.UserDetails.enable();
    } else {
      this.UserDetails.disable();
    }

  }
  fromFormValues(): IUserMaintenance {
    const info = this.UserDetails.getRawValue();
    console.log('user-info.component.fromFormValues:' + this.workStationSelected + 'user default WorkStation is : ' + this.selectedUser.defaultWorkStation);
    return {
       userId: this.selectedUser != null ? this.selectedUser.userId : null,
       manufacturingUserId: this.selectedUser != null ? this.selectedUser.manufacturingUserId : null,
       userName: this.selectedUser != null ? this.selectedUser.userName : null,
       firstName: this.selectedUser != null ? this.selectedUser.firstName : null,
       lastName: this.selectedUser != null ? this.selectedUser.lastName : null,
       middleInitial: this.selectedUser != null ? this.selectedUser.middleInitial : null,
       employeeID: this.selectedUser != null ? this.selectedUser.employeeID : null,
       empNum: this.selectedUser != null ? this.selectedUser.empNum : null,
       email: this.selectedUser != null ? this.selectedUser.email : null,
       defaultWarehouse: info.warehouse,
       defaultWarehouse1: info.warehouse, //this.selectedUser != null ? this.selectedUser.defaultWarehouse1 : null,
       defaultWorkStation:  this.workStationSelected != null ?  this.workStationSelected : this.selectedUser.defaultWorkStation,
       workstationDescription: info.workstation,//this.selectedUser != null ? this.selectedUser.workstationDescription : null,
       active: this.selectedUser != null ? this.selectedUser.active : false,
       userEnabled: this.selectedUser != null ? this.selectedUser.userEnabled : false,

    };
  }

  onWorkStationSelected(event){ 
    var currentEvent: string = event.target.value;
    this.workStationObjectSelected = this.workStations.find(ws => ws.description == currentEvent.split(":").pop().substring(1));
    //if (this.workStationObjectSelected != null)
    //{
      this.workStationSelected = this.workStationObjectSelected.id; 
      console.log('user-info.component.onWorkStationSelected:  ' + this.workStationSelected);
    //}
    //else
    //{
     // this.alert.showOk('Workstation is not in list!', AlertLevel.INFO,
    //         (ar: AlertReponse) => 
    //         { 
    //           if (ar === AlertReponse.OK)
    //           {
    //             // this.closeOverlay();
    //           }
    //         });
    //}
       
  }

get getUserMaintenanceInfo() {
  return this.fromFormValues();
}

private get isVisible(): boolean {
  return this.view.nativeElement.offsetParent != null;
}

ngAfterContentChecked() {
  console.log('user-info.component.ngAfterContentChecked isVisible:' + String( this.isVisible ? 'true' : 'false'));

  if (!this.isVisible && this.viewWasVisible) {
    this.viewWasVisible = false;
      if (this.helper.isExistingItem(this.getUserMaintenanceInfo)) {
        console.log('user-info.component.ngAfterContentChecked...visible to hidden> updating workCenter');
        if (this.userBizl.canUpdateUser) {
          this.userMaintenanceService.updateUser(this.selectedUser).subscribe(updated =>{ });
        }
      }
   } else if (this.isVisible && !this.viewWasVisible) {
       console.log('user-info.component.ngAfterContentChecked...hidden to visible');
       this.viewWasVisible = true;
       this.userSubmitted = false;
       this.updateUserFormGroup(this.selectedUser);
   } 
}

  ngOnDestroy() {
      //console.log('user-info.component.ngOnDestroy');
      //if (this.userBizl.canUpdateUser) {
        //this.selectedUser.defaultWarehouse = 'MPWTX'
        //this.selectedUser.defaultWorkStation = 'CC0E3E5E-AF3E-479E-A317-04C9A8628FA1' //this.workstation.value;
      //  this.userMaintenanceService.updateUser(this.selectedUser).subscribe(updated =>{ });
      //}
    }



  validateWorkCenterDescription() {
     console.log('user-info.component.validateWorkCenterDescription');
       let valid = !this.bizl.isWorkCenterEditable ||
      (this.bizl.isNewWorkCenter && !this.userSubmitted) ||
      (!this.bizl.isNewWorkCenter && this.UserDetails.controls.description.pristine);

      // console.log('user-info.component.validateWorkCenterDescription valid:' + valid);

      if (!valid) {
          if (!this.UserDetails.controls.description.valid) {
            this.lastWorkCenterDescriptionValidationMsg = 'Required';
          } else if (!this.bizl.isUniqueWHDescription) {
            this.lastWorkCenterDescriptionValidationMsg = 'Warehouse + description combination must be unique';
          } else {
            valid = true;
          }
       }
       return valid;
    }


}
