import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, AfterContentInit} from '@angular/core';
import { emit } from 'cluster';
import { ActivatedRoute } from '@angular/router';
import { IWorkCenterToolKit, IToolBag, IToolKit, ITool, IToolBagTool, ToolUtils } from '../../../../core/models/tool.model';
import { ToolServiceHttp } from '../../../../core/services/tool.servicehttp';
import { Helper } from '../../../../core/services/helper.service';

@Component({
  selector: 'tool-kit-management1',
  template: require('./tool-kit-management1.component.html'),
  // styles: [require('./tool-kit-management1.component.css')]
  styles: [require('./tool-kit-management1.component.css').toString()]
})
export class ToolKitManagement1Component implements OnInit, AfterContentInit {

  @Output() kitModal = new EventEmitter;
  @Input() targetToolKit: IToolKit = null; // target toolKit
  @Input() targetStations: IToolBag[] = null; // toolbags of target toolkit
  @ViewChild('orgTable', {static: true}) Table: ElementRef;
  @ViewChild('selectSourceToolKit', {static: true}) selectSourceToolKit: ElementRef;
  @ViewChild('btnAppend', {static: true}) btnAppendFiles: ElementRef;
  @ViewChild('btnReplace', {static: true}) btnReplaceFiles: ElementRef;
  @ViewChild('btnCopySelected' , {static: true}) btnCopySelected: ElementRef;
  toolKits: IWorkCenterToolKit[] = null;
  sourceStations: any[] = null;
  selectedToolKit: IWorkCenterToolKit = null;
  selected = false;

  constructor(private router: ActivatedRoute,
    private utils: Helper,
    private toolService: ToolServiceHttp) { }

  modalToggle() {
    this.kitModal.emit(this.targetStations);
  }



  handleSelectSourceTookKit(elem) {
    console.log('test-kit-management.component.handleSelectSourceToolKit');
    console.log(elem);
    this.sourceStations = null;
    const kit = this.selectedToolKit;

    this.toolService.getWorkCenterToolBags(this.selectedToolKit.id).subscribe(toolBags => {
      console.log(toolBags);
      this.toolService.getWorkCenterToolKitTools(this.selectedToolKit.id).subscribe(toolKitTools => {
        this.sourceStations = this.utils.addToolsToToolBags(toolBags, toolKitTools);
        this.sourceStations.sort(Helper.sort_description);
        this.utils.prepSelectableObject(this.sourceStations);
        console.log(this.sourceStations);
      });
    });
  }

  getToolName(t: ITool): string {
   return this.utils.getToolName(t);
  }

  ngOnInit() {
    console.log('tool-kit.management.component.ngOnInit');
    // this.disableActions(true);
    this.toolService.getWorkCenterAndRouterStepToolSets().subscribe(toolKits => {
      this.toolKits = toolKits || [];
      this.toolKits = toolKits.filter(x => x.id !== this.targetToolKit.id);
      this.toolKits.sort(Helper.sort_description);
    });

  }

  ngAfterContentInit() {

  }

  appendAll(event) {
    console.log('tool-kit.management.component.appendAll');
    console.log(event);

    this.targetToolKit.appendAll(this.selectedToolKit.id).subscribe(idMap => {
      const tmp = this.utils.handleDuplicateToolBags(this.sourceStations, this.targetStations, idMap);
      Array.prototype.push.apply(this.targetStations, tmp);
      this.targetStations.sort(Helper.sort_description);
      this.uncheckSourceStations();
    });
  }

  uncheckSourceStations() {
    if (!this.utils.isEmptyArray(this.sourceStations)) {
      for (const s of this.sourceStations) {
        s.selected = false;
      }
    }
  }

  replaceAll(event) {
    console.log('tool-kit.management.component.replaceAll');
    //console.log(this.file);
    //console.log(this.selectedFile);
    //console.log(event);
      this.targetToolKit.replaceAll(this.selectedToolKit.id).subscribe(idMap => {
        const tmp = this.utils.copyAndSetIds(this.sourceStations, idMap);
        this.targetStations = tmp;
        this.targetStations.sort(Helper.sort_description);
        this.uncheckSourceStations();
      });

  }

  copySelected(event) {
    console.log('tool-kit.management.component.copySelected');
    console.log(this.sourceStations);
    let tmp = this.sourceStations.filter(x => x.selected === true);
    console.log(tmp);
    if (!this.utils.isEmptyArray(tmp)) {
      const ids = this.getIToolBagIds(tmp);
      this.targetToolKit.appendToolBags(ids).subscribe( idMap => {
        tmp = this.utils.handleDuplicateToolBags(tmp, this.targetStations, idMap);
        console.log(tmp);
        Array.prototype.push.apply(this.targetStations, tmp);
        this.targetStations.sort(Helper.sort_description);
        this.uncheckSourceStations();
      });

    }
  }

  getIToolBagIds(toolBags: IToolBag[]) {
    const ret: string[] = [];
    if (!this.utils.isEmptyArray(toolBags)) {
      for (const t of toolBags) {
        ret.push(t.id);
      }
    }
    return ret;
  }

  checkBoxHandler(event, ft: any) {
    console.log('test-kit-management.component.checkBoxHandler');
    console.log(event);
    console.log(ft);

    if (event.target.checked && this.alreadyExists(ft)) {
      event.target.checked = false;
    }

    // ft.selected = event.target.checked;
  }

  alreadyExists(tb: IToolBag) {
    return false; // return this.utils.listContainsItem(this.fileTests, ft, this.cmpFileTestDescription);
  }

  cmpToolBagsDescription(a: IToolBag, b: IToolBag) {
    if (!a) {
      if (!b) {
        return 0;
      } else {
        return -1;
      }
    }

    if (!b) {
      return 1;
    }

    return Helper.cmpStringAlphaNumeric(a.description, b.description);
  }

  get existsStationsToCopy() {
    let ret = false;
    if (!this.utils.isEmptyArray(this.sourceStations)) {
      const tmp = this.sourceStations.filter(x => !this.alreadyExists(x)) || [];
      if (tmp && tmp.length > 0) {
        ret = true;
      }
    }
    return ret;
  }

  get canAppendAll() {
    console.log('test-kit-management.canAppendAll');
    return this.selectedToolKit != null;
    /*
    const ret = this.existsTestsToCopy;
    console.log('...ret:' + ret);
    return ret;
    */
  }

  get canReplaceAll() {
    console.log('test-kit-management.canReplaceAll');
    return this.selectedToolKit != null;

/*
     const ret = this.existsTestsToCopy;
     console.log('...ret:' + ret);
     return ret; */
  }

  get canCopySelected() {
    console.log('test-kit-management.canCopySelected');
    return this.selectedToolKit != null;
    /*
     const ret = this.existsTestsToCopy;
     console.log('...ret:' + ret);
     return ret; */
  }

  get selectFilePlaceholder() {
    return 'Select a file';
  }

}
