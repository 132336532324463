import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterReasonCode'
})
export class FilterReasonCodePipe implements PipeTransform {

  transform(reasonCodes: any, term: any): any {
    // check if term is undefined
    if (term === undefined || term.length == 0 || reasonCodes === null) {
         return reasonCodes;
      }
    // return the updated routers array
    return reasonCodes.filter(function(r){
    return r.type.toLowerCase().includes(term.toLowerCase()) || r.code.toLowerCase().includes(term.toLowerCase()) || r.description.toLowerCase().includes(term.toLowerCase());
    });
  }
}
