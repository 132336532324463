export enum MethodTypes {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}



export class MethodType {
    public Method: MethodTypes;

    public constructor (MethodName: MethodTypes) {
        this.Method = MethodName;
        console.log('MethodType for Gatekeeper API call is set to:' + this.Method);
    }
}
