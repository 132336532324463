import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { WorkCentersComponent } from '../work-centers/work-centers.component';
import { WorkCenterDetailComponent1 } from '../work-centers/work-center-detail/work-center-detail.component1';
import { WorkCenterDetailResolver } from './work-center-detail/work-center-detail-resolver.service';
import { CanDeactivateWorkCenterDetail } from './work-center-detail/work-center-detail.candeactivate';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([
            { 
                path: 'workCenters', 
                component: WorkCentersComponent,
                data: {breadcrumb: 'Work Center List'}
            },
            { 
                path: 'workCenters/new', 
                component: WorkCenterDetailComponent1, 
                canDeactivate: [CanDeactivateWorkCenterDetail],
                resolve: { workStations: WorkCenterDetailResolver},
                data: {breadcrumb: 'Work Center List'}
            },
            { path: 'workCenters/:id',
                component: WorkCenterDetailComponent1, 
                canDeactivate: [CanDeactivateWorkCenterDetail],
                data: {breadcrumb: 'Work Center Details'},
                resolve: { workStations: WorkCenterDetailResolver}}
        ])
    ],
    declarations: []    
})
export class WorkCentersModule { }