import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ComplexOuterSubscriber } from 'rxjs/internal/innerSubscribe';
import { FilesComponent } from '../../files/files.component';
import { Message, MessageType } from '../enums/message';
import { FileType } from '../models/file.model';
import { Helper } from '../services/helper.service';
import { PartServiceHttp } from '../services/part.servicehttp';
import { ValidationResponse1 } from '../shared/validation-response1';


@Injectable({
  providedIn: 'root'
})
export class FileAddValidationService {

  constructor(private formGroup: FormGroup, partService: PartServiceHttp, private fnSourceType: () => string) { 
     // console.log("FileAddValidationService constructor")
    this.reset();
  }


  get title():string {

    var ret = "Add File";
    
    return ret;
  }
 
  get canAutoSetFileName():boolean{ return true; }

  get canAutoSetDescription():boolean { return true; }

  reset()
  {
     // console.log("FileAddValidationService.resetForm");
      this.formGroup.controls.type.enable();    
      //file source 
      this.formGroup.controls.onShapeURL.disable();
      if (this.formGroup.controls.onShapeURL.value != "")
        this.formGroup.controls.onShapeURL.setValue(""); 
      this.formGroup.controls.fileUpload.disable();
      this.formGroup.controls.fileUpload.setValue(null);
      //
      this.formGroup.controls.specificationName.disable();
      if (this.formGroup.controls.specificationName.value != "")
        this.formGroup.controls.specificationName.setValue("");
      this.formGroup.controls.engManCategory.disable();
      this.formGroup.controls.engManCategory.setValue(null);
      this.formGroup.controls.revision.disable();
      this.formGroup.controls.revision.setValue(null);
      this.formGroup.controls.description.disable();
      this.formGroup.controls.description.setValue(null);
      this.formGroup.controls.updateEngineeringManualVersion.disable();
  }

  get fileType():FileType {
    var ret = FileType.UNKNOWN;
    
    if (!Helper.isUndefinedObj(this.formGroup) &&
        !Helper.isUndefinedObj(this.formGroup.controls.type.value)) 
        {
          ret = this.formGroup.controls.type.value;
        }
        return ret;
  }




  get typeValidation():ValidationResponse1 {
      const show = true; 
      const disabled = false;
      var err:string = null;
      const cnt = this.formGroup.controls.type;

      if (cnt.errors != null && cnt.errors != undefined)
        {
            if (cnt.errors.required)
                err = "Required";
        }  

      const valid = cnt.valid; 
      const ret = new ValidationResponse1(show, disabled, err, valid);
      //console.log("FileAddValidationService.typeValidation");
      //console.log(ret);   

      return ret 
  }


get onShapeURLValidation():ValidationResponse1 {
    const show = this.fnSourceType() == "onshape";
    const disabled = !show;
    var err:string = null;
    var cnt = this.formGroup.controls.onShapeURL;

    if (cnt.errors != null && cnt.errors != undefined)
    {
        if (cnt.errors?.required)
            err = "Required";
        else if (cnt.errors?.pattern)
            err = "onShape URL must start with https://diwmsi.onshape.com/";
    }  

    const valid = disabled || cnt.valid;
    const ret = new ValidationResponse1(show, disabled, err, valid);
    //ret.dump("FileAddValidationService.onShapeURLValidation");
    return ret 
}   


    get fileUploadValidation():ValidationResponse1 {
      const show = this.fnSourceType() == "file";
      const disabled = !show;
      var err:string = null;
      var cnt = this.formGroup.controls.fileUpload;

      if (cnt.errors != null && cnt.errors != undefined)
        {
            if (cnt.errors.required)
                err = "Required";
        }  
      const valid = disabled || cnt.valid;

          const ret = new ValidationResponse1(show, disabled, err, valid);
          //ret.dump("FileAddValidationService.fileUploadValidation");
    
          return ret 
  }

  get fileSourceValid():Boolean{
    
    var ret:boolean = this.formGroup.controls.fileUpload.valid ||
    this.formGroup.controls.onShapeURL.valid;

    //console.log("FilesComponent.fileSourceValid:" + ret);

      return ret;
  }

  get sourceTypeValidation():ValidationResponse1{
    const show = this.fileType == FileType.DRAWING || this.fileType == FileType.DESIGN;
    const disabled = !show;
    var err:string = null;
    const valid = true;

    const ret = new ValidationResponse1(show, disabled, err, valid);
    //ret.dump("FileAddValidationService.specificationNameValidation");
    return ret 
}


get sourceValidation():ValidationResponse1{
    const show = this.formGroup.controls.type.valid;
    const disabled = false;
    var err:string = null;
    const valid = true;

    const ret = new ValidationResponse1(show, disabled, err, valid);
    //ret.dump("FileAddValidationService.sourceValidation");

    return ret 
}


    get specificationNameValidation():ValidationResponse1 {
        const show = true;
        const disabled = !this.fileSourceValid;
        const cnt = this.formGroup.controls.specificationName;
        var err:string = null;

        if (cnt.errors != null && cnt.errors != undefined)
            {
                if (cnt.errors.required)
                    err = "Required";
                else if (cnt.errors.uniqueSpecificationName)
                    err = "Active file with this name exists";
                else if (cnt.errors.specNameExistsInEngineeringManual)
                    err = "Specification name not found in Eng Manual";
            }  

        const valid = disabled || cnt.valid;

        const ret = new ValidationResponse1(show, disabled, err, valid);
        //ret.dump("FileAddValidationService.specificationNameValidation");
  
        return ret 
  }


  get engManCategoryValidation():ValidationResponse1{
    const show =  this.fileType == FileType.SPEC;
    const disabled = !show;
    var err:string = null;
    const cnt = this.formGroup.controls.engManCategory;

    if (cnt.errors != null && cnt.errors != undefined && cnt.errors.required)
        err = "Required";
    
    const valid = disabled || cnt.valid;

    const ret = new ValidationResponse1(show, disabled, err, valid);
    //ret.dump("FileAddValidationService.engManCategoryValidation");
    return ret 
}

  get revisionValidation():ValidationResponse1 {
    const show = true;
    const disabled = !this.fileSourceValid;
    var err = null;
    const cnt = this.formGroup.controls.revision;
    
    if (cnt.errors != null && cnt.errors != undefined)
        {
            if (cnt.errors.required)
                err = "Required";
            else if (cnt.errors.uniqueSpecificationNameVersion)
                err = "Active file name + revision exists";
            else if (cnt.errors.specVersionExistsInEngineeringManual)
                err = "File name + revision not in IC Eng Manual";
        }  
    const valid =  disabled || cnt.valid;

        const ret = new ValidationResponse1(show, disabled, err, valid);
        //ret.dump("FileAddValidationService.revisionValidation"); 
    
          return ret 
  }

  get descriptionValidation():ValidationResponse1 {
      const show = true;
      const disabled = !this.fileSourceValid;
      var err:string = null;
      const cnt = this.formGroup.controls.description;

      if (cnt.errors != null && cnt.errors != undefined)
        {
            if (cnt.errors.required)
                err = "Required";
        }  
      const valid = disabled || 
          this.formGroup.controls.description.valid;
          const ret = new ValidationResponse1(show, disabled, err, valid);
          //ret.dump("FileAddValidationService.descriptionValidation");
          return ret 
    }


    get canDoNext():ValidationResponse1 {
        const show = false;
        const disabled = true;
        var err:string = null;
        const valid = true;
        
        const ret = new ValidationResponse1(show, disabled, err, valid);
        //ret.dump("FileAddValidationService.canDoNextValidation");  
        return ret; 
    }

    
   

    get canSubmit():ValidationResponse1 {

        const show = this.formGroup.valid;
        const disabled = show;
        var err:string = null;
        const valid = true;
        
        const ret = new ValidationResponse1(show, disabled, err, valid);
        //ret.dump("FileAddValidation.canSubmitValidation");
        return ret; 



    }


  get formType():string
  {
      var ret = null;

      if (this.formGroup.controls != null &&
        this.formGroup.controls != undefined &&
        this.formGroup.controls.type != null &&
        this.formGroup.controls.type != undefined)
        ret = this.formGroup.controls.type.value

      return ret;
  }  

  get updateEngManualValidation(): ValidationResponse1 {
      const type = (this.formGroup.controls != null &&
                    this.formGroup.controls != undefined &&
                    this.formGroup.controls.type != null &&
                    this.formGroup.controls.type != undefined)
                    ? this.formGroup.controls.type.value
                    : null;

    
      const show = false;
      const disabled = show;
      const msg = null;   
      const valid = true;
      const ret = new ValidationResponse1(show, disabled, msg, valid);
     // console.log("FileAddValidationService.updateEngManualValidation");
     // console.log(ret);

      return ret 
  }



  update()
  {
    //console.log("FileAddValidation.update");

    if (this.typeValidation.disabled)
        this.formGroup.controls.type.disable();
    else
        this.formGroup.controls.type.enable();

    if (this.fileUploadValidation.disabled)
    {
        this.formGroup.controls.fileUpload.disable();
        this.formGroup.controls.fileUpload.setValue(null);
    } else
        this.formGroup.controls.fileUpload.enable(); 

    if (this.onShapeURLValidation.disabled){
        this.formGroup.controls.onShapeURL.disable();
        if (this.formGroup.controls.onShapeURL.value != "")
            this.formGroup.controls.onShapeURL.setValue("");
    } else
        this.formGroup.controls.onShapeURL.enable(); 


    if (this.specificationNameValidation.disabled)
        this.formGroup.controls.specificationName.disable();
    else
        this.formGroup.controls.specificationName.enable(); 

    if (this.engManCategoryValidation.disabled)
        this.formGroup.controls.engManCategory.disable();
    else
        this.formGroup.controls.engManCategory.enable(); 

    if (this.revisionValidation.disabled)
        this.formGroup.controls.revision.disable();
    else
        this.formGroup.controls.revision.enable(); 

    if (this.descriptionValidation.disabled)
        this.formGroup.controls.description.disable();
    else
        this.formGroup.controls.description.enable(); 

    this.formGroup.controls.holdState.disable();

    //this.formGroup.controls.type.updateValueAndValidity();
        
  }


}
