import { Injectable, OnInit, Component } from '@angular/core';

@Injectable()
export class AppHttpClientService implements OnInit {
  // DEVICEID_KEY = 'deviceIdKey';
  // _tokenID: string = null;
  // set tokenId(val: string) {
  //   console.log('app-http-client.set tokenID ' + val);
  //   this._tokenID = val;
  //   // console.log('app-http-client.set tokenID ' + Config.gkParameters.TokenID);
  //   // this._tokenID = Config.gkParameters.TokenID;
  // }

   //forceLogin: any = null;
   //alertEnabled = true;

  // toh = null;

  // showSpinner() {
  //   if (this.toh != null) {
  //     return;
  //   }
  //   this.toh = setTimeout(() => {
  //     Helper.showSpinner = true;
  //   }, 1000);
  // }

  // hideSpinner() {
  //   clearTimeout(this.toh);
  //   Helper.showSpinner = false;
  //   this.toh = null;
  // }

  // get tokenId(): string{
  //   // this._tokenID = '536c007d-55b9-4a3e-a073-31433cfa8c0f';
  //   return this._tokenID;
  // }
  // get deviceId(): string {
  //   let id = localStorage.getItem(this.DEVICEID_KEY);
  //   if (this.utils.isEmptyString(id)) {
  //     const g = Guid.newGuid();
  //     id = g.toString();
  //     localStorage.setItem(this.DEVICEID_KEY, id );
  //   }
  //   return id;
  // }
  // constructor(private http: Http, private utils: Helper,
  //    private alert: AlertService,
  //   private router: Router) {}

    ngOnInit() {
      console.log('http-client.service.ngOnInit');
    }




  // get httpOptions(){
  //     console.log('app-http-client.services.get httpOptions');
  //     console.log(Config.gkParameters.TokenID);
  //     console.log(this.tokenId);
  //     console.log(this.deviceId);
  //     const tid = !this.utils.isEmptyString(this.tokenId) ? this.tokenId : '';
  //     const did = !this.utils.isEmptyString(this.deviceId) ? this.deviceId : '';

  //   const headers = new Headers({'Content-Type' : 'application/json', 'NoMuda-Token': tid,
  //   'NoMuda-Device': did});
  //   const options = new RequestOptions({headers : headers});

  //   return options;
  // }

  // get(url: string, successHandler: Function = null) {
  //   // console.log('app-http-client.service.get');
  //   this.showSpinner();
  //   return this.http.get(Helper.WEBAPIHOST + url, this.httpOptions)
  //   .finally(() =>  this.finallyHandler())
  //   .catch((error) => this.handleError(error) );

  // }

  // finallyHandler() {
  //   // console.log('app-http-client.service.finallyHandler');
  //   this.hideSpinner();
  // }

  // post(url: string, info: any, successHandler: Function = null) {
  //   this.showSpinner();
  //   return this.http.post(Helper.WEBAPIHOST + url, JSON.stringify(info), this.httpOptions)
  //   .finally(() =>  this.finallyHandler())
  //   .catch((error) => this.handleError(error) );

  // }


  // delete1(url: string, successHandler: Function = null) {

  //       this.alert.showYesNo('Are you shure you want to delete this item?', AlertLevel.WARNING,
  //         (res: AlertReponse) => {
  //           if (res === AlertReponse.YES) {
  //             this.showSpinner();
  //             return this.http.delete(Helper.WEBAPIHOST + url, this.httpOptions)
  //             .finally(() =>  this.finallyHandler())
  //             .catch((error) => this.handleError(error) );
  //           }  else {
  //             return Observable.of(null);
  //           }
  //         }
  //      );
  //     }

  //     delete3(url: string, successHandler: Function = null) {
  //       return new Observable(observer => {
  //         this.alert.showYesNo('Are you shure you want to delete this item?', AlertLevel.WARNING,
  //         (res: AlertReponse) => {
  //           if (res === AlertReponse.YES) {
  //             this.showSpinner();
  //             return this.http.delete(Helper.WEBAPIHOST + url, this.httpOptions)
  //             .finally(() =>  this.finallyHandler())
  //             .catch((error) => this.handleError(error) );

  //           }  else {
  //             return Observable.of(null);
  //           }
  //         }
  //      );
  //       });
  //     }

  // delete(url: string, successHandler: Function = null) {
  //   this.showSpinner();
  //   return this.http.delete(Helper.WEBAPIHOST + url, this.httpOptions)
  //   .finally(() =>  this.finallyHandler())
  //     .catch((error) => this.handleError(error) );
  // }


//   handleError(error: Response) {
//     console.log('app-http-client.sevice.handleError: ' + error.statusText + ' text ' + error.text());
//    // console.log(error.);

//     let msg = error.statusText;
//     const isAuthFailure = error && error.statusText && error.statusText.includes('Unauthorized');
//     const doForceLogin = isAuthFailure && this.forceLogin != null && !this.forceLogin.login;
//     const text = error.text();

//     if (text) {
//       msg = text;
//       let i = text.indexOf('{Message:');
//       if (i > -1) {
//         msg = text.substring(11, text.length - 1);
//       } else {
//         i = text.indexOf('{"Message":');
//         if (i > -1) {
//           msg = text.substring(12, text.length - 2);
//         }
//       }
//     }

//     if (msg && doForceLogin) {
//       msg = msg + '. Redirecting to login screen.';
//     }

//     console.log(msg);
//     console.log('isAuthFailure:' + isAuthFailure);
//     if (this.alertEnabled) {
//       this.alert.showOk(msg, AlertLevel.WARNING, (rsp) => {
//         if (doForceLogin) {
//           console.log('app-http-client.service. navigating to /routers');
//           this.alertEnabled = false;
//           this.forceLogin.forceLogin();
//         }
//       });
//     }

//     return Observable.throw(error.statusText);
//   }
// }

// export class ErrorResponse extends Response {
//   constructor(msg: string) {
//     super(new ResponseOptions());
//     this.statusText = msg;
//    }
  }
