import { AlertService } from '../../core/services/alert.service';
import { RouterDomainService } from '../../core/services/router-domain.service';
import { StepTestsComponentBizLogic } from './step-tests.component.bizl';
import * as jquery from 'jquery';
import { UnitType } from '../../core/enums/unittype';
import { Component,
   OnInit,
   Output,
   EventEmitter, ViewChild, ElementRef, Input, AfterContentChecked, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Helper } from '../../core/services/helper.service';
import { ITest, TestDeviceType, TestType, TestTypeUtils, TestDeviceTypeUtils } from '../../core/models/test.model';
import { IRouterStep, IdOrder } from '../../core/models/routerstep.model';
import { IRouterFile } from '../../core/models/file.model';
import { PDFService } from '../../core/services/pdf-service';
import { ResponseOptions } from '@angular/http';
import { AlertLevel, AlertReponse } from '../../core/models/alert.model';
import { TestServiceHttp } from '../../core/services/test.servicehttp';
import { RouterStepServiceHttp } from '../../core/services/routerstep.servicehttp';
import { FileServiceHttp } from '../../core/services/file.servicehttp';
import { SortCriteria} from '../../core/models/sort.model';
import { RouterState } from '../../core/enums/router-state';


declare var jQuery: any;

@Component({
  selector: 'step-tests',
  template: require('./step-tests.component.html'),
  // styles: [require('./step-tests.component.css')]
  styles: [require('./step-tests.component.css').toString()]
})

export class StepTestsComponent implements OnInit, AfterContentChecked, AfterViewInit {
  @ViewChild('StepTestOverlay', {static: true}) testOverlay: ElementRef;
  testDetailsWasVisible = false;
  @ViewChild('testList', {static: true}) testList: ElementRef;
  @ViewChild('tbody', {static: true}) tbody: ElementRef;
  testListWasVisible = false;
  showCheckbox = false;
  private routerStepId: string;
  private routerId: string;
  @Output() createTestDone = new EventEmitter();
  routerSteps: IRouterStep[];
  tests: any[];
  assignedStep = this.firstRouterStep;
  routerFiles: IRouterFile[];
  sortFunc = null;
  testTypes = TestTypeUtils.types;
  testDeviceTypes = TestDeviceTypeUtils.types;
  bizl: StepTestsComponentBizLogic;
  minVal: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.min(Helper.MINTESTVALUE_MINVALUE)]));
  maxVal: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.min(0.0001)]));
  formSubmitted = false;
  selectedTest: ITest = null;
  editMode: Boolean = false;

  get firstRouterStep() {
    return !this.helper.isEmptyArray(this.routerSteps) ? this.routerSteps[0] : null;
  }

  get lastMinValidationMsg(){
    const min = this.TestInfoGroup.controls.minVal;
    const max = this.TestInfoGroup.controls.maxVal;

    if (min.errors) {
      if (min.errors.required) {
        return 'Required';
      } else if (min.errors.min) {
        return 'Min value must be > ' + +Helper.MINTESTVALUE_MINVALUE;
      }
    } else if (min.value >= max.value) {
      return 'Max must be > Min';
    } else {
      return '';
    }
  }

  get lastMaxValidationMsg(){
    const min = this.TestInfoGroup.controls.minVal;
    const max = this.TestInfoGroup.controls.maxVal;
    if (max.errors) {
        if (max.errors.required) {
          return 'Required';
        } else if (max.errors.min) {
          return 'Max value must be > ' + +Helper.MINTESTVALUE_MINVALUE;
        }
      } else if (min.value >= max.value) {
        return 'Max must be > Min';
      } else {
        return '';
      }
  }

  get lastToolCountValidationMsg(){
    const toolCount = this.TestInfoGroup.controls.toolCount;

    if (toolCount.errors) {
        if (toolCount.errors.required) {
          return 'Required';
        } else if (toolCount.errors.min || toolCount.errors.max) {
          return 'Tool count value must be 1-8';
        }
    } else {
      return '';
    }
  }


  public get canDoBatchOps()
  {
    return this.bizl.canEditTest;
  }


  constructor(private route: ActivatedRoute
            , private helper: Helper
            , private testService: TestServiceHttp
            , private stepService: RouterStepServiceHttp
            , private fileService: FileServiceHttp,
              private domain: RouterDomainService,
              private pdfService: PDFService,
              private alert: AlertService) { }


  public TestInfoGroup: FormGroup = new FormGroup({
    testType: new FormControl('', Validators.required),
    routerOperation: new FormControl('', Validators.required),
    testDescription: new FormControl('', Validators.compose([ Validators.required,
      this.makeValidateUniqueDescription()])),
    frequency: new FormControl('', Validators.compose([Validators.required, Validators.min(0)])),
    minVal: this.minVal,
    maxVal: this.maxVal,
    testDeviceType: new FormControl('', Validators.required),
    toolCount: new FormControl('', Validators.compose([Validators.required, Validators.min(1), Validators.max(8)])),
    spec: new FormControl('', Validators.compose([Validators.required]) ),
    instructions: new FormControl('', Validators.required),
    });


    makeValidateUniqueDescription() {
      const _self = this;
      return function(fc: FormControl) {
        // console.log('spec-tool.component.validateUnique');
        const ret = _self.TestInfoGroup && _self.bizl.isUniqueDescription(_self.editMode) ? null : {
          validateUnique: {
            valid: false
          }
        };
        // console.log('spec-tool.component ret ' + ret);
        return ret;
      };
  }

  private get isTestDetailsVisible(): boolean {
    return this.testOverlay.nativeElement.style.display === 'block';
  }

  private get isListVisible(): boolean {
    //console.log(this.testList.nativeElement);
    return this.testList.nativeElement.offsetHeight > 0;
  }

  private cmpSpecNameRev(a: IRouterFile, b: IRouterFile) {
    const ret = Helper.cmpStringAlphaNumeric(a.specificationName, b.specificationName);
    if (ret === 0) {
      return Helper.cmpStringAlphaNumeric(a.revision, b.revision);
    } else {
      return ret;
    }
  }

  private getUniqueFiles(files: IRouterFile[], cmp: Function) {
    // console.log('step-tests.component.getUniqueFiles');
    if (this.helper.isEmptyArray(files)) {
      return [];
    }

    const ret: IRouterFile[] = [];
    for (const f of files) {
     // console.log(ret);
     // console.log(f);
      const tmp = ret.find(x => cmp(f, x) === 0);
      if (tmp) {
        continue;
      }
      ret.push(f);
    }
    return ret;
  }

  ngAfterContentChecked() {
    if (!this.isListVisible && this.testListWasVisible) {
      console.log('step-tests.component.ngAfterContentChecked...test list visible to hidden');
      this.testListWasVisible = false;
      this.selectedTest = null;

    } else if (this.isListVisible && !this.testListWasVisible) {
        console.log('step-tests.component.ngAfterContentChecked...test list hidden to visible');
        this.testListWasVisible = true;
        console.log('..calling getRouterSteps');
        this.stepService.getRouterSteps(this.routerId).subscribe(steps => {
          this.routerSteps = this.helper.arrayOrEmpty(steps);
          this.routerSteps.sort(this.sortStep);
        });
        this.fileService.getFilesForRouter(this.routerId).subscribe(files => {
          console.log('...router files:' + (files ? files.length : 'NONE'));
          this.routerFiles = this.helper.arrayOrEmpty(files);
         // this.routerFiles = this.getUniqueFiles(this.routerFiles, this.cmpSpecNameRev);
          this.routerFiles.sort(this.sortNameRev);

          if (!this.helper.isEmptyString(this.routerStepId)) {
            this.sortFunc = this.sortRouterTests;
            this.testService.getRouterStepTests(this.routerStepId).subscribe(tests => {
              console.log('getRouterStepTests returns count:' + (tests == null || tests.length === 0 ? '0' : tests.length));
              this.helper.resortList(tests, this.sortFunc);
              this.tests = this.helper.arrayOrEmpty(tests);
              this.helper.prepSelectableObject(this.tests);
            });

          } else {
            this.sortFunc = this.sortRouterTests;
            this.testService.getRouterTests(this.routerId).subscribe(tests => {
              this.tests = tests || [];
              this.helper.prepSelectableObject(this.tests);
              this.helper.resortList(tests, this.sortFunc);
              console.log('getRouterTests returns count:' + (tests == null || tests.length === 0 ? '0' : tests.length));
            });
          }
        });
    }

    if (!this.isTestDetailsVisible && this.testDetailsWasVisible) {
      console.log('step-tests.component.ngAfterContentChecked...test details visible to hidden');
      this.testDetailsWasVisible = false;
      this.resetFormValidation();

    } else if (this.isTestDetailsVisible && !this.testDetailsWasVisible) {
      console.log('step-tests.component.ngAfterContentChecked...test details hidden to visible');
      this.testDetailsWasVisible = true;
    }
  }

  sortStep(a: IRouterStep, b: IRouterStep) {
    return Helper.cmpNumber(a.step, b.step);
  }

  onSorted(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'step')
          {
            this.tests.sort(this.sortByStepDesc)
          }
          else if (criteria.sortColumn == 'type')
          {
            this.tests.sort(this.sortByTypeDesc)
          }
          else if (criteria.sortColumn == 'description')
          {
            this.tests.sort(this.sortByDescriptionDesc)
          }
          else
          {
            this.tests.sort(this.sortByFrequencyDesc)
          }
          
        }
        else {
          if(criteria.sortColumn == 'step')
          {
            this.tests.sort(this.sortByStepAsc)
          }
          else if (criteria.sortColumn == 'type')
          {
            this.tests.sort(this.sortByTypeAsc)
          }
          else if (criteria.sortColumn == 'description')
          {
            this.tests.sort(this.sortByDescriptionAsc)
          }
          else
          {
            this.tests.sort(this.sortByFrequencyAsc)
          }
        }
      }
  }

  sortByStepAsc(a1: ITest, a2: ITest) { 
    return Helper.cmpString(a1.step.toString(), a2.step.toString()); 
  }
  
  sortByStepDesc(a1: ITest, a2: ITest) {
    return Helper.cmpStringDesc(a1.step.toString(), a2.step.toString()); 
  }

  sortByTypeAsc(a1: ITest, a2: ITest){ 
    return Helper.cmpString(a1.type, a2.type); 
    //return a1[criteria.sortColumn] < a2[criteria.sortColumn];
  }
  
  sortByTypeDesc(a1: ITest, a2: ITest) { 
    return Helper.cmpStringDesc(a1.type, a2.type); 
  }    

  sortByDescriptionAsc(a1: ITest, a2: ITest){ 
    return Helper.cmpString(a1.description, a2.description); 
    //return a1[criteria.sortColumn] < a2[criteria.sortColumn];
  }
  
  sortByDescriptionDesc(a1: ITest, a2: ITest) { 
    return Helper.cmpStringDesc(a1.description, a2.description); 
  }
  sortByFrequencyAsc(a1: ITest, a2: ITest){ 
    return Helper.cmpString(a1.frequency.toString(), a2.frequency.toString()); 
    //return a1[criteria.sortColumn] < a2[criteria.sortColumn];
  }
  
  sortByFrequencyDesc(a1: ITest, a2: ITest) { 
    return Helper.cmpStringDesc(a1.frequency.toString(), a2.frequency.toString()); 
  }

  ngOnInit() {
     console.log('step-tests.components ngOnit');
    this.routerId = this.route.snapshot.params['id'];
    this.routerStepId = this.route.snapshot.params['stepId'];
    this.bizl = new StepTestsComponentBizLogic(this.domain.selectedRouter, this.routerStepId, this, this.domain);
    this.fileService.getFilesForRouter(this.routerId).subscribe(files => {
      console.log('...router files:' + (files ? files.length : 'NONE'));
      this.routerFiles = this.helper.arrayOrEmpty(files);
      //this.routerFiles = this.getUniqueFiles(this.routerFiles, this.cmpSpecNameRev);
      this.routerFiles.sort(this.sortNameRev);
      if (!this.helper.isEmptyString(this.routerStepId)) {
        this.sortFunc = this.sortRouterTests;
        this.testService.getRouterStepTests(this.routerStepId).subscribe(tests => {
          console.log('getRouterStepTests returns count:' + (tests == null || tests.length === 0 ? '0' : tests.length));
          this.helper.resortList(tests, this.sortFunc);
          this.tests = this.helper.arrayOrEmpty(tests);
          this.helper.prepSelectableObject(this.tests);
        });
      } else {
        this.sortFunc = this.sortRouterTests;
        this.testService.getRouterTests(this.routerId).subscribe(tests => {
          this.tests = tests || [];
          this.helper.prepSelectableObject(this.tests);
          this.helper.resortList(tests, this.sortFunc);
          console.log('getRouterTests returns count:' + (tests == null || tests.length === 0 ? '0' : tests.length));
        });
      }
    });
  }

  sortNameRev(a: IRouterFile, b: IRouterFile) {
    if (a == null) {
      if (b == null) {
        return 0;
      } else {
        return -1;
      }
    }

    if (b == null) {
      return 1;
    }

    return Helper.cmpStringAlphaNumeric(a.name, b.name);
  }

  handleSelectTestType(event) {
    console.log('step-tests.component.handleSelectTestType');
    console.log(event);
    
    if (this.TestInfoGroup.controls.testType.value !== TestType.IPI) {
      this.TestInfoGroup.patchValue({
        minVal: 0.0001,
        maxVal: 0.0001
      });    
    }    
    
    this.updateFormControls();    
    this.resetFormValidation();
    if (this.TestInfoGroup.controls.testType.value === TestType.IPI && this.editMode)
    {
      this.TestInfoGroup.controls.minVal.markAsDirty();
      this.TestInfoGroup.controls.maxVal.markAsDirty();
      this.TestInfoGroup.markAsDirty();
    }
    
    }

  get shouldShowRouterStep(): boolean{
    return this.helper.isEmptyString(this.routerStepId);
  }

  getNominalValue(test: ITest): number {
    if (test.type === TestType.IPI) {
      return (test.min + test.max) / 2;
    } else {
      return 0;
    }
  }

  getTolerance(test: ITest): number {
    // console.log('step-tests.component.getTolerance');
    // console.log(test);
    if (test.type === TestType.IPI) {
      return (test.max - test.min) / 2;
    } else {
      return 0;
    }

  }

  addTest() {
    this.editMode = false;
    this.selectedTest = null;
    this.stepOverlayOpen(this.bizl.newTestDefaultInfo);
  }

  goToTestDetails(test) {
    this.editMode = true;
    this.selectedTest = test;
    this.stepOverlayOpen(test);
  }

  viewFile(test: ITest) {
    const f = this.routerFiles.find(x => x.id === test.specificationId);
    if (f === null) {
      console.log('step-tests.viewFile unexpected null router file for test ' + test.step + ' ' + test.description);
      this.fileService.getFile(test.specificationId).subscribe(file => {
        if (file !== null) {
            this.pdfService.show(file.url);
        } else {
            this.alert.showOk('file not found for step ' + test.step + ' test ' + test.description);
        }
      });
    } else {
      this.pdfService.show(f.url);
    }
  }

  deleteTest(test: ITest) {
    this.alert.showYesNo('Are you sure you want to delete test ' + test.type + ' ' + test.description + '?',
    AlertLevel.WARNING,
    (ar: AlertReponse) => {
      console.log(ar);
      console.log(ar === AlertReponse.OK);
      console.log(AlertReponse.OK);
      if (ar === AlertReponse.YES) {
        this.testService.deleteTest(test.id).subscribe(res => {
          this.tests = this.tests.filter(t => t.id !== test.id);
          this.tests = this.helper.arrayOrEmpty(this.tests);
        });
      }
    });
  }

  stepOverlayOpen(test: ITest) {
    console.log('step-tests.component.stepOverlayOpen');
    this.patchFormGroup(test);
    const resetFormValidation = this.resetFormValidation;

    document.getElementById('StepTestOverlay').style.display = 'block';
    const modal = document.getElementById('StepTestOverlay');
    let selectedTest = this.selectedTest;
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
        if (event.target === modal) {
          console.log('...closing overlay in closure');
            modal.style.display = 'none';
            selectedTest = null;
            resetFormValidation();
        }
    };
  }

  isUnassignedTest(test: ITest) {
    return !test.routerStepId;
  }

  getStepInfo(test: ITest) {
    let ret: string;

    if (this.isUnassignedTest(test)) {
      ret = 'UNASSIGNED test for file ' + test.specificationName;
    } else  {
      ret = test.step.toString() + ' - ' + test.stepDescription;
    }

    return ret;
  }

  fromFormValues(): ITest {
    // console.log('step-tests.components.fromFormValue');
    if (this.TestInfoGroup == null) {
      return null;
    }
    const info = this.TestInfoGroup.getRawValue();
    // console.log(info);

    return {
      id: this.helper.isExistingItem(this.selectedTest) ? this.selectedTest.id : null ,
      description: info.testDescription,
      type: info.testType != null ? info.testType : TestType.UNKNOWN,
      min: info.minVal,
      max: info.maxVal,
      testOrder: this.getNewTestOrder(),
      unitType: UnitType.INCH,
      frequency: info.frequency,
      notes: info.instructions,
      routerStepId: (info.routerOperation != null ? info.routerOperation.id : null),
      step: (info.routerOperation != null ? info.routerOperation.step : null),
      stepDescription: (info.routerOperation != null ? info.routerOperation.stepDescription : null),
      testDeviceType: info.testDeviceType,
      testDeviceCount: info.toolCount,
      specificationId : (info.spec ? info.spec.id : null),
      specificationName: (info.spec ? info.spec.specificationName : null )
    };
  }

  getNewTestOrder() {
    if (!this.helper.isEmptyArray(this.tests)) {
      return this.tests.length + 1;
    }  else {
      return 1;
    }
  }

  closeOverlay() {
      console.log('closeOverlay');
      this.testOverlay.nativeElement.style.display = 'none';
      this.selectedTest = null;
      this.resetFormValidation();
  }

  validateNewTestInfo(): Boolean {
      return this.TestInfoGroup.valid &&
            this.validateMin() &&
            this.validateMax();
  }

  validateTestType() {
    return !this.bizl.isTestTypeEditable ||
    (this.bizl.isNewTest && !this.formSubmitted) ||
    (!this.bizl.isNewTest &&  this.TestInfoGroup.controls.testType.pristine) ||
    this.TestInfoGroup.controls.testType.valid;
  }

  validateDescription() {
      return !this.bizl.isTestDescriptionEditable ||
      (this.bizl.isNewTest && !this.formSubmitted) ||
      (!this.bizl.isNewTest &&  this.TestInfoGroup.controls.testDescription.pristine) ||
              this.TestInfoGroup.controls.testDescription.valid;
  }

  validateFrequency() {
      return !this.bizl.isFrequencyEditable ||
      (this.bizl.isNewTest && !this.formSubmitted) ||
      (!this.bizl.isNewTest &&  this.TestInfoGroup.controls.frequency.pristine) ||
              this.TestInfoGroup.controls.frequency.valid;
  }

  get testDescriptionValdationError( ) {
    if (this.TestInfoGroup.controls.testDescription.errors &&
      this.TestInfoGroup.controls.testDescription.errors.validateUnique) {
      return 'Description must be unique';
    } else {
      return 'Required';
    }
  }

  validateNotes() {
      return !this.bizl.areInstructionsEditable ||
      (this.bizl.isNewTest && !this.formSubmitted) ||
      (!this.bizl.isNewTest &&  this.TestInfoGroup.controls.instructions.pristine) ||
      this.TestInfoGroup.controls.instructions.valid;
  }

  validateSpec() {
      return !this.bizl.isSpecEditable ||
      (this.bizl.isNewTest && !this.formSubmitted) ||
      (!this.bizl.isNewTest &&  this.TestInfoGroup.controls.spec.pristine) ||
      this.TestInfoGroup.controls.spec.valid;
  }

  validateRouterOperation() {
    return !this.bizl.isRouterOperationEditable ||
    (this.bizl.isNewTest && !this.formSubmitted) ||
    (!this.bizl.isNewTest && this.TestInfoGroup.controls.routerOperation.value != null &&  this.TestInfoGroup.controls.routerOperation.pristine) ||
    this.TestInfoGroup.controls.routerOperation.valid;
  }

  validateMin() {
    // console.log('step.tests.component.validateMin');
    // console.log(this.formSubmitted);
    // console.log(this.bizl);
    // console.log(this.TestInfoGroup);
    return !this.bizl.shouldShowMinMax || !this.bizl.isMinMaxEditable ||
    (this.bizl.isNewTest && !this.formSubmitted) ||
    (!this.bizl.isNewTest &&  this.minVal.pristine) ||
    (this.minVal.value >= Helper.MINTESTVALUE_MINVALUE && this.maxVal.value > 0 && this.minVal.value < this.maxVal.value && this.minVal.valid);
  }

  validateMax() {
    return !this.bizl.shouldShowMinMax || !this.bizl.isMinMaxEditable ||
    (this.bizl.isNewTest && !this.formSubmitted) ||
  (!this.bizl.isNewTest &&  this.maxVal.pristine) ||
  (this.minVal.value >= Helper.MINTESTVALUE_MINVALUE && this.maxVal.value > 0 && this.minVal.value < this.maxVal.value && this.minVal.valid);
  }

  validateToolCount() {
    const toolCount = this.TestInfoGroup.controls.toolCount;

    return !this.bizl.isToolCountEditable ||
    (this.bizl.isNewTest && !this.formSubmitted) ||
    (!this.bizl.isNewTest &&  this.TestInfoGroup.controls.toolCount.pristine) ||
    this.TestInfoGroup.controls.toolCount.valid;
  }

  handleSelectRouterStep(e: Event) {
    // upon router step change, validate test description across all tests at that step
    if (this.TestInfoGroup.controls.routerOperation.value != null) {
      console.log('step-tests.component.handleSelectRouterStep ... mark description dirty');
      this.TestInfoGroup.controls.testDescription.markAsDirty();
      this.TestInfoGroup.controls.testDescription.updateValueAndValidity();
    }
  }

  resetFormValidation() {
    console.log('step-tests.component.resetFormValidation');
    this.formSubmitted = false;

    this.TestInfoGroup.markAsPristine();
    this.TestInfoGroup.markAsUntouched();
    this.TestInfoGroup.updateValueAndValidity();
  }

  submit() {
    
    console.log('submit');

      this.formSubmitted = true;

      const t = this.fromFormValues();
      console.log(this.TestInfoGroup);
      if (!this.validateNewTestInfo()) {
        return;
      }


    if (this.helper.isExistingItem(t)) {
      console.log('updating test:' + (t.description));
      this.testService.updateTest(t).subscribe(updated => {
        console.log('updated:' + updated.id);
        this.tests = this.tests.filter(x => x.id !== updated.id);
        this.tests.push(updated);
        this.helper.resortList(this.tests, this.sortFunc);
        this.closeOverlay();
      });
    } else {
      this.testService.createTest(t).subscribe(newTest => {
        console.log('created:' + newTest.id);
        this.tests.push(newTest);
        this.helper.resortList(this.tests, this.sortFunc);
        this.closeOverlay();
      });
    }
  }

  getTitle(): string {
    if (this.bizl.isNewTest) {
        return 'Add New Test';
    } else if (this.bizl.isTestEditable) {
      if (this.bizl.isKitTest) {
        return 'Assign Test Kit Test to Router Step';
      } else {
        return 'Edit Test';
      }
    } else {
      return 'Test Info';
    }
  }

  private patchFormGroup(t: ITest) {
    let step = null;
    let file = null;

    if (!this.helper.isEmptyString( t.routerStepId)) {
      step = this.routerSteps.find(s => s.id === t.routerStepId);
    }

    if (!this.helper.isEmptyString( t.specificationId) && !this.helper.isEmptyArray(this.routerFiles)) {
      file = this.routerFiles.find(s => s.id === t.specificationId);
    }


    this.TestInfoGroup.patchValue({
      testType: t.type !== TestType.UNKNOWN ? t.type : null,
      routerOperation: step,
      testDescription: t.description,
      frequency: t.frequency,
      minVal: t.type === TestType.IPI ? t.min : 0,
      maxVal: t.type === TestType.IPI ? t.max : 0,
      testDeviceType: t.testDeviceType,
      toolCount: t.testDeviceCount,
      spec: file,
      instructions: t.notes,
    });

    this.updateFormControls();
  }

  getNewTest() {
    return this.fromFormValues();
  }

  updateFormControls() {
    console.log('step-tests.component.updateFormControls');
    console.log(this.bizl);

    if (this.bizl.isTestTypeEditable) {
      this.TestInfoGroup.controls.testType.enable();
    }  else {
      this.TestInfoGroup.controls.testType.disable();
    }
    if (this.bizl.isRouterOperationEditable) {
      this.TestInfoGroup.controls.routerOperation.enable();
    }  else {
      this.TestInfoGroup.controls.routerOperation.disable();
    }

    if (this.bizl.isTestDescriptionEditable) {
      this.TestInfoGroup.controls.testDescription.enable();
    }  else {
      this.TestInfoGroup.controls.testDescription.disable();
    }

    if (this.bizl.isFrequencyEditable) {
      this.TestInfoGroup.controls.frequency.enable();
    }  else {
      this.TestInfoGroup.controls.frequency.disable();
    }

    /*
    if (this.bizl.isBaseTestEditable) {
      this.TestInfoGroup.controls.baseTest.enable();
    }  else {
      this.TestInfoGroup.controls.baseTest.disable();
    }*/

    if (this.bizl.isMinMaxEditable) {
      this.TestInfoGroup.controls.minVal.enable();
    }  else {
      this.TestInfoGroup.controls.minVal.disable();
    }

    if (this.bizl.isMinMaxEditable) {
      this.TestInfoGroup.controls.maxVal.enable();
    }  else {
      this.TestInfoGroup.controls.maxVal.disable();
    }

    if (this.bizl.isTestDeviceTypeEditable) {
      this.TestInfoGroup.controls.testDeviceType.enable();
    }  else {
      this.TestInfoGroup.controls.testDeviceType.disable();
    }

    if (this.bizl.isToolCountEditable) {
      this.TestInfoGroup.controls.toolCount.enable();
    }  else {
      this.TestInfoGroup.controls.toolCount.disable();
    }if (this.bizl.isSpecEditable) {
      this.TestInfoGroup.controls.spec.enable();
    }  else {
      this.TestInfoGroup.controls.spec.disable();
    }if (this.bizl.areInstructionsEditable) {
      this.TestInfoGroup.controls.instructions.enable();
    }  else {
      this.TestInfoGroup.controls.instructions.disable();
    }
  }

  sortRouterTests(a: ITest, b: ITest) {
    // console.log('step-tests.component.sortRouterTests');
    // console.log(a);
    // console.log(b);
    if (a.id == null) {
      if (b.id == null) {
        let tmp = Helper.cmpStringAlphaNumeric(a.specificationName, b.specificationName);
        if (tmp === 0) {
          tmp = Helper.cmpNumber(a.testOrder, b.testOrder);
        }
        return tmp;
      } else {
        return -1;
      }
    }

    if (b.id == null) {
      return 1;
    }

    let ret = Helper.cmpNumber(a.step, b.step);
    if (ret === 0) {
      ret = Helper.cmpNumber(a.testOrder, b.testOrder);
      if (ret === 0) {
        ret = Helper.cmpStringAlphaNumeric(a.type, b.type);
        if (ret === 0) {
          ret = Helper.cmpStringAlphaNumeric(a.description, b.description);
        }
      }
    }
    return ret;
  }

  handleAssignRouterStep(event) {
    console.log('step-tests.component.handleAssignRouterStep');
    console.log(event);
    console.log(this.assignedStep);
    // don't need to consider tests already assigned to the target step
    const selectedTests = this.tests.filter(x => x.selected && (x.routerStepId != this.assignedStep.id)) || [];
    if (!this.helper.isEmptyArray(selectedTests)) {
      const testIds = this.getIds(selectedTests);
      const unselectedTests = this.tests.filter(x => !testIds.includes(x.id)) || [];
      const assignedTests = this.tests.filter(x => x.routerStepId == this.assignedStep.id) || [];
      this.testService.updateAssignStepToTests(this.assignedStep.id, testIds).subscribe(ret => {
        this.assignTestsToStep(selectedTests, this.assignedStep);
        const tmp = this.helper.handleDuplicateTests(selectedTests, assignedTests);
        this.tests = unselectedTests.concat(tmp);
        this.helper.resortList(this.tests, this.sortFunc);
        this.closeBatchOverlay();
      });
    }
  }

  getIds(tests: ITest[]): string[] {
    const ret: string[] = [];
    if (!this.helper.isEmptyArray(tests)) {
      for (const t of tests) {
        ret.push(t.id);
      }
    }
    return ret;
  }

  assignTestsToStep(tests: ITest[], step: IRouterStep) {
    if (!this.helper.isEmptyArray(tests)) {
      for (const t of tests) {
        t.routerStepId = step.id;
        t.step = step.step;
      }
    }
  }

  handleSortableUpdate() {
  // console.log('step-tests.comonent.handleSortableUpdate');
    const tableRows = jquery(this.tbody.nativeElement).children();
    const stepOrder = this.helper.getStepOrder(this.routerId, tableRows);
    // console.log(this.tests);

  // console.log(this.tests);

    this.testService.updateOrder(stepOrder).subscribe( res => {
      this.tests = this.updateTestOrder(this.tests, stepOrder.map);
      this.tests.sort(this.sortFunc);
      // console.log(res);
    });
  }

  updateTestOrder(tests: ITest[], map: IdOrder[]) {
    tests = this.helper.arrayOrEmpty(tests);
    map = this.helper.arrayOrEmpty(map);
    const ret: ITest[] = [];
    for (const order of map) {
      const tmp: ITest = <ITest>tests.find(x => x.id === order.id);
      if (tmp) {
        console.log(tmp.description + ' old: ' + tmp.testOrder + ' new:' + order.order );
        tmp.testOrder = order.order;
        ret.push(tmp);
      }
    }
    return ret;
  }

  showSelect() {
    if (this.showCheckbox === false) {
      this.showCheckbox = true;
    } else {
      this.showCheckbox = false;
    }
  }

  closeBatchOverlay() {
    document.getElementById('BatchOverlay').style.display = 'none';
  }

  openBatchOverlay() {
    this.assignedStep = null;
    document.getElementById('BatchOverlay').style.display = 'block';
    const modal = document.getElementById('BatchOverlay');
    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = 'none';
        }
    };
  }

  ngAfterContentInit() {
    $(document).ready(function(){
      $('input[type="radio"]').click(function(){
        var inputValue = $(this).attr("value");
        if (inputValue == "pdf")
        {
          $("#PdfSpec").show();
          $("#OnshapeSpec").hide();
        }
        else 
        {
          $("#PdfSpec").hide();
          $("#OnshapeSpec").show();
        }     
    });
    })
  }

  ngAfterViewInit() {
    $("#PdfSpec").hide();
    $("#OnshapeSpec").hide();
    const thisObj = this;
    
    if (this.domain.isEditable)
    {
      jQuery(this.tbody.nativeElement).sortable({
    // cancel: 'tbody,tr,td',
    start: function(e, ui) {
      $(this).attr('data-previndex', ui.item.index());
    },
      update: function(e: Event, ui) {
        console.log('step-tests.component.ngAfterViewInit.sortable.update');
        console.log(e);
        console.log(ui);
        console.log(ui.item[0].id);
        console.log('new pos:' + ui.item.index() + ' old pos:' + $(this).attr('data-previndex'));
        $(this).removeAttr('data-previndex');
      // (<any>$(this)).sortable('refreshPositions');
        thisObj.handleSortableUpdate();

      },
      sort: function(event: Event, ui) {
        console.log('step-tests.component.ngAfterViewInit.sortable.sort');
        console.log(ui);
        console.log('ui.item[0].id:' + ui.item[0].id);
      },
      stop: function(event, ui) {
        console.log('step-tests.component.ngAfterViewInit.sortable.stop');
        console.log(ui);
        console.log('ui.item[0].id:' + ui.item[0].id);
        if (ui.item[0].id === '') {
          console.log('CANCEL');
          (<any>$(this)).sortable('cancel');
        }
        // (<any>$(this)).sortable("cancel");
      },
      receive: function(event, ui) {
        console.log('step-tests.component.ngAfterViewInit.sortable.receive');
      }
    });
    }
    
    
  }
}
