import { ProgramsComponentBizLogic } from './programs.component.bizl';
import { getOverloadKey } from 'tslint/lib/rules/adjacentOverloadSignaturesRule';
import { Component, OnChanges, OnInit, DoCheck, AfterContentChecked, AfterViewChecked,AfterContentInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, NgModel, Validators, NgControlStatus, ControlContainer, FormBuilder, ValidatorFn } from '@angular/forms';
import { IProgram } from '../core/models/program.model';
import { FilterProgramPipe } from '../core/pipes/filter-program.pipe';
import { Helper } from '../core/services/helper.service';
import { AlertService } from '../core/services/alert.service';
import { AlertLevel, AlertReponse } from '../core/models/alert.model';
import { ProgramServiceHttp } from '../core/services/program.servicehttp';
import { SortCriteria} from '../core/models/sort.model';




@Component({
  selector: 'app-programs',
  template: require('./programs.component.html'),
  styles: [require('./programs.component.css').toString()]
  // styles: [require('./programs.component.css')]
})
export class ProgramsComponent implements OnInit, AfterViewInit, AfterViewChecked, AfterContentChecked, OnDestroy, AfterContentInit {
  programs: IProgram[];
  ProgramFormGroup: FormGroup;
  ProgramDescription: FormControl;
  ProgramNumber: FormControl;
  selectedProgram: IProgram;  
  public term: string;
  bizl: ProgramsComponentBizLogic;
  userSubmitted = false;
  overlayWasVisible = false;
  dir ="asc";  
  editMode: Boolean = false;
  @ViewChild('programForm' , {static: true}) programForm: ElementRef;
  @ViewChild('table' , {static: true}) table: ElementRef;
  @ViewChild('inputNumber' , {static: false}) inputNumber: ElementRef;
  @ViewChild('inputDescription' , {static: false}) inputDescription: ElementRef;

  constructor(private programService: ProgramServiceHttp,    
    private alert: AlertService,   
    private utils: Helper) { }


  ngOnDestroy() {
    console.log('programs.component.ngOnDestroy');
    this.resetFormValidation();
  }

  ngOnInit() {
    console.log('programs.component.ngOnInit');
    console.log('programs.component.ngOnInit bizl component' + this);
    this.bizl = new ProgramsComponentBizLogic(this);
    this.userSubmitted = false; 
    
    this.ProgramDescription = new FormControl('', Validators.compose([
      Validators.required,
      this.makeValidateUniqueDescription()
    ]));
    this.ProgramNumber = new FormControl('', Validators.compose([
      Validators.required,
      this.makeValidateUniqueNumber()
    ]));

    this.ProgramFormGroup = new FormGroup({
      number: this.ProgramNumber,
      description: this.ProgramDescription
    });


    this.programService.getPrograms().subscribe(programs => {
      this.programs = programs;      
      this.programs.sort(this.sortByNumberAsc);
    });
  }

  onSorted(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'number')
          {           
           this.programs.sort(this.sortByNumberDesc)  
          }
          else 
          {
            this.programs.sort(this.sortByDescriptionDesc)
          }
          
        }
        else {
          if(criteria.sortColumn == 'number')
          {
            this.programs.sort(this.sortByNumberAsc)
          }
          else 
          {
            this.programs.sort(this.sortByDescriptionAsc)
          }
        }
      }
      
  }


sortByNumberAsc(a1: IProgram, a2: IProgram){ 
  return Helper.cmpString(a1.number, a2.number); 
 // return Helper.cmpStringAlphaNumeric(a1.number, a2.number); 
}

sortByNumberDesc(a1: IProgram, a2: IProgram) { 
  return Helper.cmpStringDesc(a1.number, a2.number); 
}

sortByDescriptionAsc(a1: IProgram, a2: IProgram) { 
  return Helper.cmpString(a1.description, a2.description); 
}

sortByDescriptionDesc(a1: IProgram, a2: IProgram) {
  return Helper.cmpStringDesc(a1.description, a2.description); 
}


get isOverlayVisible() {
  return this.programForm.nativeElement.offsetParent != null;
}

ngAfterContentChecked() {  

  if (!this.isOverlayVisible && this.overlayWasVisible){
    console.log('programs.component.ngAfterContentChecked...visible to hidden ');
    this.overlayWasVisible = false;
    this.resetFormValidation();
  } else if (this.isOverlayVisible && !this.overlayWasVisible){
    console.log('programs.component.ngAfterContentChecked...hidden to visible');
    this.overlayWasVisible = true;
    this.userSubmitted = false;
    this.resetFormValidation();
  }
}

  toFormGroup(program: IProgram) {
    this.ProgramFormGroup.patchValue({
        number: program.number,
        description: program.description
    });    
    this.ProgramFormGroup.controls.description.enable();
  }

    fromFormGroup(info): IProgram {
    return {
      id : this.utils.isExistingItem(this.selectedProgram) ? this.selectedProgram.id : null,
      number : info.number,
      description: info.description,
      inUse: this.utils.isExistingItem(this.selectedProgram) ? this.selectedProgram.inUse : false,
    };
  }


  addProgram() {  
    this.editMode = false;
    /* this.ProgramFormGroup.controls["number"].setValidators([Validators.required,this.makeValidateUniqueNumber()]);
    this.ProgramFormGroup.controls["description"].setValidators([Validators.required,this.makeValidateUniqueDescription()]);
    //this.ProgramFormGroup.updateValueAndValidity();
    this.ProgramFormGroup.controls["number"].updateValueAndValidity();
    this.ProgramFormGroup.controls["description"].updateValueAndValidity(); */
    this.overlayOpen(this.bizl.newProgramDefaultInfo);
  }

  editProgram(program) {    
    this.editMode = true;     
    /* this.ProgramFormGroup.controls["number"].setValidators([Validators.required, this.makeValidateUniqueNumber()]);
    this.ProgramFormGroup.controls["description"].setValidators([Validators.required,this.makeValidateUniqueDescription()]);
    //this.ProgramFormGroup.updateValueAndValidity(); 
    this.ProgramFormGroup.controls["number"].updateValueAndValidity();
    this.ProgramFormGroup.controls["description"].updateValueAndValidity(); */
    this.overlayOpen(program);
  }

  deleteProgram(p: IProgram) {
  this.alert.showYesNo('Are you sure you want to delete program ' + p.number + ' ' + p.description + '?',
  AlertLevel.WARNING,
  (ar: AlertReponse) => {
    console.log(ar);
    console.log(ar === AlertReponse.OK);
    console.log(AlertReponse.OK);
    if (ar === AlertReponse.YES) {
      this.programService.deleteProgram(p.id).subscribe( res => {
        this.programs = this.programs.filter(x => x.id !== p.id);
      });
    }
  });
}

getTitle() {
  if (this.bizl.isNewProgam) {
    return 'Add a Program';
  } else {
    return 'Edit a Program';
  }
}

getNewProgram() {
  return this.fromFormGroup(this.ProgramFormGroup.getRawValue());
}


validateProgramDescription() {

  return  (!this.bizl.isNewProgam && this.ProgramFormGroup.controls.number.pristine ||
    (this.bizl.isNewProgam && !this.userSubmitted) ||
       this.ProgramFormGroup.controls.description.valid);
}

resetFormValidation() {
  this.userSubmitted = false;
  // yourForm.reset(), yourForm.resetForm() don't work, but this does:
  this.ProgramFormGroup.markAsPristine();
  this.ProgramFormGroup.markAsUntouched();
  this.ProgramFormGroup.updateValueAndValidity();
}

validateProgramNumber() {
  return  (!this.bizl.isNewProgam && this.ProgramFormGroup.controls.number.pristine) ||
              (this.bizl.isNewProgam && !this.userSubmitted) ||
              this.ProgramFormGroup.controls.number.valid;
}

makeValidateUniqueNumber() {
  const _self = this; 
  return function(fc: FormControl) {  
    
    let ret = (_self.ProgramFormGroup && _self.bizl.isUniqueNumber(_self.editMode)) ? null : {validateUnique: {valid: false}};
   
    return ret;
  };
}

makeValidateUniqueDescription() {
  const _self = this;
  return function(fc: FormControl) {
   
    let ret = (_self.ProgramFormGroup && _self.bizl.isUniqueDescription(_self.editMode)) ? null : {
      validateUnique: {
        valid: false
      }};
    
    return ret;
  };
}

get programDescriptionValdationError( ) {
    if (this.ProgramFormGroup.controls.description.errors.validateUnique) 
    {
      return 'Description must be unique';
    } 
    else 
    {
      return 'Required';
    }      
 }

 get programNumberValdationError( ) {  
  
    if (this.ProgramFormGroup.controls.number.errors.validateUnique)
    {
      return 'Number must be unique';
    } else
    {
      return 'Required';
    } 
  }

validateNewProgramInfo() {
  return this.ProgramFormGroup.valid;
}

 submit(formValues) {
   console.log('programs.components.submit');
   console.log(this.ProgramFormGroup);
   console.log(this.bizl);

   let timer:NodeJS.Timer;

   this.userSubmitted = true;
   if (!this.validateNewProgramInfo()) {
      return null;
   }
   const p = this.fromFormGroup(formValues);
   console.log(p);
   if (this.bizl.canCreate) {
     console.log('...create');
     this.programService.createProgram(p).subscribe(added => {
       this.programs.push(added);
       this.programs.sort(this.sortByNumberAsc);  
       //this.sortTable(0);      
       this.closeOverlay();
     });
   } else if (this.bizl.canUpdate) {
    console.log('...post');
    this.programService.updateProgram(p).subscribe(updated =>{
      this.programs = this.programs.filter(x => x.id !== updated.id);
      this.programs.push(updated);
      this.programs.sort(this.sortByNumberAsc);
      //this.sortTable(0)
      this.closeOverlay();
    });
   } else {
     this.closeOverlay();
   }
 }  

  closeOverlay() {    
    this.userResponse(false);
    this.selectedProgram = null;
    // this.sortTable(0);
    document.getElementById('ProgramOverlay').style.display = 'none';
  }
  overlayOpen(program: IProgram) {
    this.selectedProgram = program;
    this.toFormGroup(program);
    const _self = this;
    console.log(this.ProgramFormGroup);
    document.getElementById('ProgramOverlay').style.display = 'block';
    const modal = document.getElementById('ProgramOverlay');
    this.inputNumber.nativeElement.focus();
    // When the user clicks anywhere outside of the modal, this closes it
    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = 'none';
            _self.selectedProgram = null;
        }
    };
  }

////alert-action component use Start
  alertType = '';
// expects "name" of alert from AlertService @alert-action.component
  openError(alertName: string) {
    document.getElementById('ErrorAction').style.display = 'block';
    const modal = document.getElementById('ErrorAction');
    this.alertType = alertName;
// When the user clicks anywhere outside of the modal, this closes it
    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = 'none';
}
    };
  }
  userResponse(data) {
    console.log('Action Was', data);
  }


////alert-action Component End
ngAfterViewChecked() {
  const c1 = $('.col1').width();
  const c2 = $('.col2').width();
  $('#col1').width(c1 + 1);
  $('#col2').width(c2);
  $('.SearchBar').width(c1 + c2 + 2);

  var th = $('thead').width();
  $("#Cover").width(th);

 // $('#number').focus();
}
ngAfterViewInit() {
      console.log('File.Component.AfterViewInit');
      // runs every time the user re-sizes the window
          $(window).resize(function(){
        // gets td column widths
          const c1 = $('.col1').width();
          const c2 = $('.col2').width();
          const th = $('thead').width();
        // sets th column widths to match td's
          $("#Cover").width(th+1);
          $('#col1').width(c1 + 1);
          $('#col2').width(c2);
          $('.SearchBar').width(c1 + c2 + 2);
          console.log ('resize header occured');
          });
    }
    ngAfterContentInit() {
      $(document).ready(function(){
        $('.SearchField').focus();
      })
    }

}
