import { Component, OnInit, AfterContentChecked, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { Helper } from '../../core/services/helper.service';
import { WorkCenterService } from '../../core/services/workcenter.service';
import { IWorkCenter } from '../../core/models/workcenter.model';
import { IRouterStep, PrintOptionUtils, PrintTypeUtils, RouterStepUtils } from '../../core/models/routerstep.model';
import { RouterDetailsValidator } from '../../router-detail/router-details.validator';
import { RouterDomainService } from '../../core/services/router-domain.service';
import { StepInfoComponentBizLogic } from './step-info.component.bizl';
import { IRouter } from '../../core/models/router.model';
import { RouterStepServiceHttp } from '../../core/services/routerstep.servicehttp';
import { RouterDetailComponent } from '../../router-detail/router-detail.component';
import { ShSelectAddNewValComponent } from '../../core/shared/sh-slelectAddNewString.component';
import { NgbTimepickerI18nDefault } from '@ng-bootstrap/ng-bootstrap/timepicker/timepicker-i18n';

import { IPart } from '../../core/models/part.model';
import { PartServiceHttp } from '../../core/services/part.servicehttp';
import { ShSelectComponent } from '../../core/shared/sh-slelect.component';


@Component({
  selector: 'step-info',
  template: require('./step-info.component.html'),
  // styles: [require('./step-info.component.css')]
  styles: [require('./step-info.component.css').toString()]
})

export class StepInfoComponent implements OnInit, AfterContentChecked {
  private utils: Helper = new Helper();
  
  workCenters: IWorkCenter[];
  selectedWorkCenters: IWorkCenter[] = [];
  selectingWorkCenter: boolean = false;

  familyIds: string[] = [];
  selectedFamilyIds: string[] = [];
  selectingFamilyId = false;

  @ViewChild('divOneToMany', {static: true}) divOneToMany: ElementRef;
  @ViewChild('selectFamilyId', {static: true}) selectFamilyId: ShSelectAddNewValComponent;
  @ViewChild('selectWorkCenter', {static: true}) selectWorkCenter: ShSelectComponent;

  infoGroup: FormGroup = new FormGroup( {
    workCenter: new FormControl( '', this.workCenterRequired()), // won't use this form control in template, only to force workcenter validation
    description: new FormControl('', Validators.compose([
        Validators.required,
        this.makeValidateUnique()
        ])
      ),
    familyId: new FormControl('', this.familyIdRequired()), // won't use this form control in template, only to force familyId validation
    fcnDoFgLot: new FormControl('', Validators.required),
    deferTesting: new FormControl('', Validators.required),
    setupTimeH: new FormControl('', Validators.required),
    setupTimeM: new FormControl('', Validators.required),
    setupTimeS: new FormControl('', Validators.required),
    runTimeH: new FormControl('', Validators.required),
    runTimeM: new FormControl('', Validators.required),
    runTimeS: new FormControl('', Validators.required),
    idleTimeH: new FormControl('', Validators.required),
    idleTimeM: new FormControl('', Validators.required),
    idleTimeS: new FormControl('', Validators.required),
    bestTimeH: new FormControl('', Validators.required),
    bestTimeM: new FormControl('', Validators.required),
    bestTimeS: new FormControl('', Validators.required),
    avgTimeH: new FormControl('', Validators.required),
    avgTimeM: new FormControl('', Validators.required),
    avgTimeS: new FormControl('', Validators.required),
    printOption: new FormControl('', Validators.required),
    printType: new FormControl( '', Validators.required)
  });
  @ViewChild('stepInfoForm', {static: true}) stepInfoForm: ElementRef;
  formWasVisible = false;
  printOptions = PrintOptionUtils.options;
  printTypes = PrintTypeUtils.types;
  bizl: StepInfoComponentBizLogic;
  userSubmitted = false;
  
  newStepWasCancelled = false;

  familyIdRequired(): ValidatorFn {
    const _self = this;
    return (control: AbstractControl): {[key: string]: any} | null => {
      const ret =
        ( 
          _self.helper.isEmptyArray(_self.selectedFamilyIds) && 
          !_self.selectingFamilyId
        )
        ? {'familyIdRequired': {valid: false}} 
        : null;    
      //console.log('step-info.component.familyIdRequired ret:' + ret);    
      return ret;    
    };
  }

  workCenterRequired(): ValidatorFn {
    const _self = this;
    return (control: AbstractControl): {[key: string]: any} | null => {
     //console.log('step-info.component.workCenterRequired selectedWorkCenters:' + _self.helper.isEmptyArray(_self.selectedWorkCenters));
    // console.log('step-info.component.workCenterRequired !selectingWorkCenter:' + !_self.selectingWorkCenter);
      var ret = 
        (
        _self.helper.isEmptyArray(_self.selectedWorkCenters) && 
        !_self.selectingWorkCenter
        ) 
        ? {'workCenterRequired': {valid: false}} 
        : null;
     // console.log('step-info.component.workCenterRequired ret:' + ret );
      return ret;
    };
  }


 makeValidateUnique() {
  const _self = this;
  return function(fc: FormControl) {
/*     console.log('step-info.component.makeValidateUnique');
    console.log('...routerSteps:' + 
      (!Helper.sIsEmptyArray(_self.routerSteps)
      ? _self.routerSteps.length:'NONE'));
    console.log(Helper.isUndefinedObj(_self.infoGroup));
    console.log('...description.value:' + (
      !(Helper.isUndefinedObj(_self.infoGroup) || 
        Helper.isUndefinedObj(_self.infoGroup.controls) || 
        Helper.isUndefinedObj(_self.infoGroup.controls.description)
      ) 
      ? _self.infoGroup.get("description").value
      :"NONE")); */
    const ret = 
      _self.infoGroup && 
      _self.infoGroup.controls.description &&
      _self.bizl.isUniqueRouterStepDescription(
                    _self.routerSteps, 
                    _self.infoGroup.controls.description.value,
                    _self.newStep.id)
      ? null 
      : { validateUnique: { valid: false }};
    //console.log('step-info.component.makeValidateUnique ret ' + ret);
    return ret;
  };
}

  get descriptionValidationError(){
    //console.log('step-info.component.descriptionValidationError');
    
    const errors = this.infoGroup.controls.description.errors;
    //console.log(errors);
    if (errors) {
      if (errors.required) {
        return 'Required';
      } else if (errors.validateUnique) {
        return 'Must be unique across router steps';
      } else {
        return 'Error';
      }
    }
  }

 get routerSteps() {
  return this.domain.routerSteps;
 }

  get selectedRouter() {
    return this.domain.selectedRouter;
  }

  set selectedRouter(val: IRouter){
    this.domain.selectedRouter = val;
  }

  get selectedRouterStep() {
    return this.domain.selectedRouterStep;
  }

  set selectedRouterStep(val: IRouterStep){
    this.domain.selectedRouterStep = val;
  }

  constructor(
    private route: ActivatedRoute,
    private helper: Helper,
    private workCenterService: WorkCenterService,
    private partService: PartServiceHttp,
    private stepService: RouterStepServiceHttp,
    private navRouter: Router,  
    private domain: RouterDomainService)  {
 
                                          }

  private patchFormGroup(s: IRouterStep) {
    console.log("step-info.component.patchFormGroup");
    const wc = this.workCenters.find(w => w.id === s.workCenterId);
    
    this.infoGroup.patchValue({
      description: s.description,
      fcnDoFgLot: s.doFgLot,

      deferTesting: s.deferTesting,

      setupTimeH: this.helper.getRemHours( s.setupTime),
      setupTimeM: this.helper.getRemMinutes( s.setupTime),
      setupTimeS: this.helper.getRemSeconds( s.setupTime),

      runTimeH: this.helper.getRemHours( s.runTime),
      runTimeM: this.helper.getRemMinutes( s.runTime),
      runTimeS: this.helper.getRemSeconds( s.runTime),

      idleTimeH: this.helper.getRemHours( s.idleTime),
      idleTimeM: this.helper.getRemMinutes( s.idleTime),
      idleTimeS: this.helper.getRemSeconds( s.idleTime),

      bestTimeH: this.helper.getRemHours( s.bestTime),
      bestTimeM: this.helper.getRemMinutes( s.bestTime),
      bestTimeS: this.helper.getRemSeconds( s.bestTime),

      avgTimeH: this.helper.getRemHours( s.avgTime),
      avgTimeM: this.helper.getRemMinutes( s.avgTime),
      avgTimeS: this.helper.getRemSeconds( s.avgTime),

      printOption: s.printOption,

      printType: s.printType
    });

    var tmp = this.workCenters.find(x=> x.id === s.workCenterId);
    if (tmp != null) {
      this.selectedWorkCenters = [tmp];
    } else {
      this.selectedWorkCenters = [];
    }

    if (this.familyIds.includes(s.familyId)) {
      this.selectedFamilyIds = [s.familyId];
      this.infoGroup.patchValue({familyId: ''});
    } else {
      this.selectedFamilyIds = ['NONE'];
    }

    //enable controls

    if (!this.bizl.isStepEditable) {
      this.infoGroup.disable();
    } else {
      this.infoGroup.enable();
    }

    if (this.bizl.isWorkCenterEditable) {
       console.log("step-info.component.patchFormGroup> enable selectWorkCenter")
       this.selectWorkCenter.disabled = false;
       this.infoGroup.controls.workCenter.enable();
    } else {
        console.log("step-info.component.patchFormGroup> disable selecteWorkCenter");
      this.selectWorkCenter.disabled = true;
      this.infoGroup.controls.workCenter.disable();
    }

    if (this.bizl.isDescriptionEditable) {
      this.infoGroup.controls.description.enable();
    } else {
      this.infoGroup.controls.description.disable();
    }

    if (this.bizl.isFamilyIdEditable) {
      console.log("step-info.component.patchFormGroup> enabling selectFamilyId dropdown");
      this.infoGroup.controls.familyId.enable();
      this.selectFamilyId.disabled = false;
    } else {
      console.log("step-info.component.patchFormGroup> disabling selectFamilyId dropdown");
      this.selectFamilyId.disabled = true;
      this.infoGroup.controls.familyId.disable();
    }

    if (this.bizl.isDoFgLotEditable) {
      this.infoGroup.controls.fcnDoFgLot.enable();
    } else {
      this.infoGroup.controls.fcnDoFgLot.disable();
    }

    if (this.bizl.isDeferTestingEditable) {
      this.infoGroup.controls.deferTesting.enable();
    } else {
      this.infoGroup.controls.deferTesting.disable();
    }

    if (this.bizl.isSetupTimeEditable) {
      this.infoGroup.controls.setupTimeH.enable();
      this.infoGroup.controls.setupTimeM.enable();
      this.infoGroup.controls.setupTimeS.enable();
    } else {
      this.infoGroup.controls.setupTimeH.disable();
      this.infoGroup.controls.setupTimeM.disable();
      this.infoGroup.controls.setupTimeS.disable();
    }

    if (this.bizl.isRunTimeEditable) {
      this.infoGroup.controls.runTimeH.enable();
      this.infoGroup.controls.runTimeM.enable();
      this.infoGroup.controls.runTimeS.enable();
    } else {
      this.infoGroup.controls.runTimeH.disable();
      this.infoGroup.controls.runTimeM.disable();
      this.infoGroup.controls.runTimeS.disable();
    }

    if (this.bizl.isIdleTimeEditable) {
      this.infoGroup.controls.idleTimeH.enable();
      this.infoGroup.controls.idleTimeM.enable();
      this.infoGroup.controls.idleTimeS.enable();
    } else {
      this.infoGroup.controls.idleTimeH.disable();
      this.infoGroup.controls.idleTimeM.disable();
      this.infoGroup.controls.idleTimeS.disable();
    }

    if (this.bizl.isBestTimeEditable) {
      this.infoGroup.controls.bestTimeH.enable();
      this.infoGroup.controls.bestTimeM.enable();
      this.infoGroup.controls.bestTimeS.enable();
    } else {
      this.infoGroup.controls.bestTimeH.disable();
      this.infoGroup.controls.bestTimeM.disable();
      this.infoGroup.controls.bestTimeS.disable();
    }

    if (this.bizl.isAvgTimeEditable) {
      this.infoGroup.controls.avgTimeH.enable();
      this.infoGroup.controls.avgTimeM.enable();
      this.infoGroup.controls.avgTimeS.enable();
    } else {
      this.infoGroup.controls.avgTimeH.disable();
      this.infoGroup.controls.avgTimeM.disable();
      this.infoGroup.controls.avgTimeS.disable();
    }

    if (this.bizl.isPrintOptionEditable) {
      this.infoGroup.controls.printOption.enable();
    } else {
      this.infoGroup.controls.printOption.disable();
    }

    if (this.bizl.isPrintTypeEditable) {
      this.infoGroup.controls.printType.enable();
    } else {
      this.infoGroup.controls.printType.disable();
    }
  }

  private get isVisible(): boolean {
    return this.stepInfoForm.nativeElement.offsetParent != null;
  }

  prepWorkCenters(workCenters:IWorkCenter[])
  {
    if (!Helper.sIsEmptyArray(workCenters))
    {
      for (var i:number = 0; i< workCenters.length; i++)
      {
        var w = workCenters[i];
        w.name = w.warehouse + ": " + w.name;
      }
    }

    return workCenters;
  }

  resetFormValidation() {
    //console.log('step-info.resetFormValidation');
    //this.selectFamilyId.error = false;

    this.userSubmitted = false;
    this.setChkDoFgLot();

    // yourForm.reset(), yourForm.resetForm() don't work, but this does:
    this.infoGroup.markAsPristine();
    this.infoGroup.markAsUntouched();
    this.infoGroup.updateValueAndValidity();
  }

  //ngOnDestroy() {
    //console.log('step-info.ngOnDestroy');
    /*
    if (this.bizl.canUpdate) {
      console.log('step-info.component.ngAfterContentChecked...updating router step:');
      const stepInfo = this.fromFormValues();
      this.resetFormValidation();
      this.stepService.updateRouterStep(stepInfo).subscribe(updated => {

      });
    }*/
  //}

  newStepCancelled(){
    //console.log ("New Step was cancelled");
    this.newStepWasCancelled = true;
  }

  get hasChanges(){
    return this.bizl.canUpdate;
  }

  get newStep(){
    return this.getNewRouterStep();
  }

  ngAfterContentChecked() {
    //console.log('step-info.component.ngAfterContentChecked isVisible:' + String( this.isVisible ? 'true' : 'false') + " formWasVisible:   " + String( this.formWasVisible ? 'true' : 'false') );
    if (!this.isVisible && this.formWasVisible) {
      //console.log('step-info.component.ngAfterContentChecked...visible to hidden');

      this.formWasVisible = false;
      if (this.bizl.canUpdate) {
        //console.log('step-info.component.ngAfterContentChecked...updating router step:');
        const stepInfo = this.fromFormValues();
        this.resetFormValidation();
        //console.log('*****************************this happens when you cancel....step-info.component.ngAfterContentChecked...updating router step:')
        if (!this.newStepWasCancelled){
          //console.log("Step was updated");
          this.stepService.updateRouterStep(stepInfo).subscribe(updated => {
                                                                              RouterStepUtils.copy(this.domain.selectedRouterStep, updated);
                                                                            }
                                                                );
        }        
      }
    } 
    else if (this.isVisible && !this.formWasVisible) {
      //console.log('*****else if*****step-info.component.ngAfterContentChecked...hidden to visible');

      this.resetFormValidation();
      this.infoGroup.disable();
      this.formWasVisible = true;
      this.workCenterService.getWorkCenters().subscribe(centers => {
                                                                      this.workCenters = this.prepWorkCenters(centers);
                                                                      this.workCenters.sort(this.sortWHDescription);
                                                                      this.stepService.getFamilyIds().subscribe(familyIds => {
                                                                                                                                this.familyIds = familyIds;
                                                                                                                                this.familyIds.sort();
                                                                                                                                this.patchFormGroup(this.domain.selectedRouterStep);
                                                                                                                              }
                                                                                                                );
                                                                    }
                                                        );
    }
  }


  selectWorkCenterOnShowHandler(event) {
    console.log('step-info.selectWorkCenterOnShowHandler');
    this.selectingWorkCenter = true;
    this.infoGroup.controls.workCenter.updateValueAndValidity();
  }

  selectWorkCenterOnHideHandler(event) {
    console.log('step-info.selectWorkCenterOnHideHandler');
    this.selectingWorkCenter = false;
    this.infoGroup.controls.workCenter.markAsTouched();
    this.infoGroup.controls.workCenter.updateValueAndValidity();
  }

  selectFamilyIdHandler(event) {
      //console.log('step-info.selectFamilyIdHandler');
      this.selectingFamilyId = true;
      this.infoGroup.controls.familyId.updateValueAndValidity();
  }

  selectFamilyIdOnHideHandler(event) {
    //console.log('step-info.selectFamilyIdOnHideHandler typed value:');
    //console.log(this.selectedFamilyIds);
    this.selectingFamilyId = false;
    this.infoGroup.controls.familyId.markAllAsTouched();
    this.infoGroup.controls.familyId.updateValueAndValidity();
  }

  sortWHDescription(a1: IWorkCenter, a2: IWorkCenter) {
    if (a1 === null) {
      if (a2 !== null) {
        return -1;
      } else {
        return 0;
      }
    }

    if (a2 === null) {
      return 1;
    }

    const ret = Helper.cmpStringAlphaNumeric(a1.warehouse, a2.warehouse);
    if (ret === 0) {
      return Helper.cmpStringAlphaNumeric(a1.description, a2.description);
    } else {
      return ret;
    }
  }

  get valid() {
      return this.infoGroup.disabled || this.infoGroup.valid;
  }

  validateWorkCenter() {
     var ret = !this.bizl.isStepEditable ||
              (this.bizl.isStepEditable && !this.userSubmitted && this.infoGroup.controls.workCenter.untouched) || // data not loaded, yet
              (this.bizl.isNewStep && !this.userSubmitted && this.infoGroup.controls.workCenter.untouched ) || // new step; so, let user try to add data
              this.infoGroup.controls.workCenter.valid;

     /*  console.log('step-info.component.validateWorkCenter returns:' + ret);
      console.log(this.bizl);
      console.log('...userSubmitted:' + this.userSubmitted);
      console.log('...workCenter.valid:' + this.infoGroup.controls.workCenter.valid); 
      console.log('...selectingWorkCenter:' + this.selectingWorkCenter);
      console.log('...selectedWorkCenters:' + this.selectedWorkCenters); */

      return ret;        
  }


  validateDescription() {
/*      console.log('step-info.component.validateDescription');
     console.log(' isStepEditable ' + this.bizl.isStepEditable);
     console.log(' untouched: ' + this.infoGroup.controls.description.untouched);
     console.log(' pristine: ' + this.infoGroup.controls.description.pristine);
     console.log(' isNewStep:' + this.bizl.isNewStep );
     console.log(' userSubmitted:' + this.userSubmitted);
     console.log(' isValid: ' + this.infoGroup.controls.description.valid);
     console.log(' Description contains errors value: ' + this.infoGroup.controls.description.errors);
     console.log(' routerSteps:' + this.routerSteps);
     console.log(' newStep.id:' + this.newStep.id);
     console.log(' isUniqueRouterStepDescription: ' + 
      this.bizl.isUniqueRouterStepDescription(
        this.routerSteps, 
        this.infoGroup.get("description").value,
        this.newStep.id
      )); */

    let valid = !this.bizl.isStepEditable ||
                (
                  this.bizl.isStepEditable &&
                  !this.userSubmitted &&
                  this.infoGroup.controls.description.pristine
                )  ||
                (
                  this.bizl.isNewStep &&
                  !this.userSubmitted && 
                  this.infoGroup.controls.description.pristine
                ) ||                
                this.infoGroup.controls.description.valid
               ;
 
   // console.log('step-info.component.validateDescription returns ' + valid);
    return valid;
  }
  
  validateFamilyId() {
    // console.log('step-info.component.validateFamilyId');
    // console.log(' isStepEditable ' + this.bizl.isStepEditable);
    // console.log(' untouched: ' + this.infoGroup.controls.familyId.untouched);
    // console.log(' pristine: ' + this.infoGroup.controls.familyId.pristine);
    // console.log(' isNewStep:' + this.bizl.isNewStep + ' userSubmitted:' + this.userSubmitted);
    // console.log(' isValid: ' + this.infoGroup.controls.familyId.valid);

    const ret = !this.bizl.isStepEditable ||
                (this.bizl.isStepEditable && !this.userSubmitted && this.infoGroup.controls.familyId.untouched)  ||
                (this.bizl.isNewStep &&  !this.userSubmitted && this.infoGroup.controls.familyId.untouched) ||
                this.infoGroup.controls.familyId.valid;

    // console.log('step-info.component.validateFamilyId returns ' + ret);
    return ret;
  }

  ngOnInit() {
    //console.log('step-info.ngOnInit');
    this.bizl = new StepInfoComponentBizLogic(this, this.domain);

    //-- Set show or hide one to many checkbox, chkDoFgLot
    this.setChkDoFgLot();
  }

  setChkDoFgLot()
  {
    console.log('step-info.setChkDoFgLot');

    if (this.domain.selectedRouterStep != null && this.domain.selectedRouterStep.id != "")
    {
      this.stepService.GetRouterOperationCanSetDoFgLotByRouterOperationID(this.domain.selectedRouterStep.id).subscribe(canSetDoFgLot => {
        if (canSetDoFgLot)
        {
          this.divOneToMany.nativeElement.style.display = 'block';
        }
        else
        {
          this.divOneToMany.nativeElement.style.display = 'none';
        }
      });
    }
    else
    {
      //-- New RouterStep, get DoFgLot from part
      this.partService.getPart(this.domain.selectedRouter.partId).subscribe(part => {
        if (part.fGLot > 1)
        {
          //-- check if an existing router step has DoFgLot set
          let setDoFgLot: boolean = true;
          if (this.routerSteps.length > 0)
          {
            for (var routerStep of this.routerSteps)
            {
              if (routerStep.doFgLot)
              {
                setDoFgLot = false;
                break;
              }
            }
          }

          if (setDoFgLot)
          {
            this.divOneToMany.nativeElement.style.display = 'block';
          }
          else
          {
            this.divOneToMany.nativeElement.style.display = 'none';
          }
        }
        else
        {
          this.divOneToMany.nativeElement.style.display = 'none';
        }
      });
    }
  }

  getNewRouterStep() {
    return this.fromFormValues();
  }

  get validateNewRouterInfo() {
    //console.log('step-info.component.validateNewRouterInfo');
    //console.log(this.infoGroup);
    return this.infoGroup.enabled && this.infoGroup.valid;
  }

  fromFormValues(): IRouterStep {
    const info = this.infoGroup.getRawValue();
    var wc = this.helper.isEmptyArray(this.selectedWorkCenters) ? null : this.selectedWorkCenters[0];

    const s = {
                id: this.helper.isExistingItem(this.domain.selectedRouterStep) ? this.domain.selectedRouterStep.id : null,
                routerId: this.domain.selectedRouter.id,
                step: this.helper.isExistingItem(this.domain.selectedRouterStep) ? this.domain.selectedRouterStep.step : -1,
                description: info.description,
                printOption: info.printOption,
                setupTime: this.helper.calSecs(info.setupTimeH, info.setupTimeM, info.setupTimeS),
                avgTime: this.helper.calSecs(info.avgTimeH, info.avgTimeM, info.avgTimeS),
                familyId: (this.helper.isEmptyArray(this.selectedFamilyIds)) ? info.familyId : this.selectedFamilyIds[0],
                runTime: this.helper.calSecs(info.runTimeH, info.runTimeM, info.runTimeS),
                idleTime: this.helper.calSecs(info.idleTimeH, info.idleTimeM, info.idleTimeS),
                doFgLot: info.fcnDoFgLot,
                deferTesting: info.deferTesting,
                workCenterId: wc != null ? wc.id : null,
                workCenterName: wc != null ? wc.name : "",
                printType: info.printType,
                bestTime: this.helper.calSecs(info.bestTimeH, info.bestTimeM, info.bestTimeS),
              };
    return s;
  }

  submit(formValues) {
    this.userSubmitted = true;
    console.log('step-info.component.submit valid:' + this.infoGroup.valid);
    if (!this.infoGroup.valid) {
      return null;
    } else {
      const s = this.fromFormValues();
      console.log('....calling createRouterStep');
      console.log(s);
      this.stepService.createRouterStep(s).subscribe(newStep => {        
                                                                  RouterStepUtils.copy(this.domain.selectedRouterStep, newStep);
                                                                  this.navRouter.navigate(['/routers', this.domain.selectedRouter.id, 'steps', newStep.id]);
                                                                }
                                                    );
    }
  }
}
