import { Component, OnInit, OnChanges, DoCheck, SimpleChanges,
   AfterViewInit, AfterContentInit,
   AfterViewChecked, AfterContentChecked, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { IEtchingInfo } from '../../../core/models/part.model';
import { ActivatedRoute } from '@angular/router';
 import { ComponentVisibilitySpecification } from '../../../core/shared/ComponentVisibilitySpecification';
import { Helper } from '../../../core/services/helper.service';
import { PartServiceHttp } from '../../../core/services/part.servicehttp';



@Component({
  selector: 'part-etching',
  template: require('./part-etching.component.html'),
  // styles: [require('./part-etching.component.css')]
  styles: [require('./part-etching.component.css').toString()]
})
export class PartEtchingComponent implements  OnChanges, OnInit, AfterContentChecked,
 AfterViewInit, OnDestroy {

  PartEtchingFormGroup: FormGroup;
  @ViewChild('partEtchingInfo', {static:true}) view: ElementRef;
  viewWasVisible = false;
  partId: string;
  private visSpec: ComponentVisibilitySpecification;
  private etchingInfo: IEtchingInfo;

  constructor(private route: ActivatedRoute
    , private helper: Helper
  , private partService: PartServiceHttp) { }

  ngOnChanges(changes: SimpleChanges) {
    console.log('part-etching.component.ngOnChanges');
    console.log(changes);
    for (const propName in changes) {
      const chng = changes[propName];
      const cur  = JSON.stringify(chng.currentValue);
      const prev = JSON.stringify(chng.previousValue);
      console.log(`${propName}: currentValue = ${cur}, previousValue = ${prev}`);
    }


  }


  ngOnInit() {
    console.log('part-etching.component.ngOnInit');

    this.partId = this.route.snapshot.params['id'];

    this.PartEtchingFormGroup = new FormGroup({
        PSI: new FormControl()
      , perfReqLevel: new FormControl()
      , tempClass: new FormControl()
      , retMatClass: new FormControl()
      , API6AMono: new FormControl()
      , minWall: new FormControl()
      , nomSize: new FormControl()
      , endConA: new FormControl()
      , endConB: new FormControl()
      , endConC: new FormControl()
      , endConD: new FormControl()
      , service: new FormControl()
    });

    this.partService.getEtchingInfo(this.partId).subscribe(etching => {
      this.etchingInfo = etching;
      this.updateEtchingForm(etching);
    });
  }

  private get isVisible(): boolean {
    console.log('part-etching.componenet.isVisible offsetHeight:' + this.view.nativeElement.offsetHeight);
    return this.view.nativeElement.offsetHeight > 0;
  }

  ngAfterContentChecked() {
    console.log('part-etching.ngAfterContentChecked');
    if (!this.isVisible && this.viewWasVisible) {
      this.etchingInfo = this.fromPartEtchingInfoForm();
      console.log('part-etching.component ngAfterContentChecked...from visible to hidden');
      console.log('...updating etching info:');
      this.viewWasVisible = false;
      if (this.helper.isEmptyString(this.etchingInfo.id)) {
        this.partService.createEtchingInfo(this.etchingInfo).subscribe(added => {
          this.etchingInfo = added;
        });
      } else {
        this.partService.updateEtchingInfo(this.etchingInfo).subscribe(updated => {

        });
      }

    }  if (this.isVisible && !this.viewWasVisible) {
      console.log('part-etching.component ngAfterContentChecked...from hidden to visible');
      // console.log('...initializing component');
      this.viewWasVisible = true;

    }
  }


  ngAfterViewInit() {
    console.log('part-etching.component.ngAfterViewInit');
  }



  ngOnDestroy() {
    console.log('part-etching.component.ngOnDestroy');
    this.etchingInfo = this.fromPartEtchingInfoForm();
    if (this.helper.isEmptyString(this.etchingInfo.id)) {
      console.log('..create etching info');
      this.partService.createEtchingInfo(this.etchingInfo).subscribe(added => {
        this.etchingInfo = added;
      });
    } else {
      console.log('..update etching info');
      this.partService.updateEtchingInfo(this.etchingInfo).subscribe(updated => {

      });
    }
  }

  fromPartEtchingInfoForm(): IEtchingInfo {
   return {
     id: (this.etchingInfo == null ? null : this.etchingInfo.id),
     partId: this.partId,
     PSI: this.PartEtchingFormGroup.controls.PSI.value,
     perfReqLevel: this.PartEtchingFormGroup.controls.perfReqLevel.value,
     tempClass: this.PartEtchingFormGroup.controls.tempClass.value,
     retMatClass: this.PartEtchingFormGroup.controls.retMatClass.value,
     API6AMono: this.PartEtchingFormGroup.controls.API6AMono.value,
     minWall: this.PartEtchingFormGroup.controls.minWall.value,
     nomSize: this.PartEtchingFormGroup.controls.nomSize.value,
     endConA: this.PartEtchingFormGroup.controls.endConA.value,
     endConB: this.PartEtchingFormGroup.controls.endConB.value,
     endConC: this.PartEtchingFormGroup.controls.endConC.value,
     endConD: this.PartEtchingFormGroup.controls.endConD.value,
     service: this.PartEtchingFormGroup.controls.service.value
   };
  }

  updateEtchingForm(etchingInfo: IEtchingInfo) {
    if (!etchingInfo) {
      return;
    }
   this.PartEtchingFormGroup.patchValue( {
    PSI: etchingInfo.PSI,
    perfReqLevel: etchingInfo.perfReqLevel,
    tempClass: etchingInfo.tempClass,
    retMatClass: etchingInfo.retMatClass,
    API6AMono: etchingInfo.API6AMono,
    minWall: etchingInfo.minWall,
    nomSize: etchingInfo.nomSize,
    endConA: etchingInfo.endConA,
    endConB: etchingInfo.endConB,
    endConC: etchingInfo.endConC,
    endConD: etchingInfo.endConD,
    service: etchingInfo.service
   });
  }




}
