import { AfterContentChecked, AfterContentInit, AfterViewInit, AfterViewChecked, DoCheck, Component, ElementRef, OnDestroy, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { IRouterStepNotes } from '../../core/models/routerstep.model';
import { ActivatedRoute } from '@angular/router';
import { isNumber } from 'util';
import { RouterStepServiceHttp } from '../../core/services/routerstep.servicehttp';
import { StepSpecComponent} from '../step-spec/step-spec.component';
import { StepNotesComponentBizLogic } from './step-notes.component.bizl';
import { RouterDomainService } from '../../core/services/router-domain.service';
import { Helper } from '../../core/services/helper.service';
import { utils } from 'protractor';
import { Utils } from 'tslint';

@Component({
  selector: 'step-notes',
  template: require('./step-notes.component.html'),
  // styles: [require('./step-notes.component.css')]
  styles: [require('./step-notes.component.css').toString()]
})
export class StepNotesComponent implements OnInit, AfterContentChecked, AfterContentInit, DoCheck, OnChanges, AfterViewInit, AfterViewChecked,OnDestroy  {

  setupNotes: FormControl;
  userNotes: FormControl;
  @ViewChild('routerStepNotes', {static: true}) m: ElementRef;
  @ViewChild('stepNotes', {static: true}) stepNotesDiv: ElementRef;
  viewWasVisible = false;
  RouterStepNotesGroup: FormGroup;
  stepId: string;
  originalUserNotes = '';
  originalSetupNotes = '';
  bizl:StepNotesComponentBizLogic;
  
  get newNotes(): IRouterStepNotes{
    return {
      id: this.stepId,
      userNotes: this.userNotes.value !== null ? this.userNotes.value : "",
      setupNotes: this.setupNotes.value != null ? this.setupNotes.value : ""
    };
  }

  setUserNotes(notes:string):void
  {
    this.RouterStepNotesGroup.patchValue({
      userNotes: (notes ? notes : '')
    });
  }

  
  get hasChanges(){
    //console.log('step-notes.hasChanges oSetupNotes:' + this.originalSetupNotes + ' oUserNotes:' + this.originalUserNotes);

    return this.originalSetupNotes !== this.setupNotes.value ||
    this.originalUserNotes !== this.userNotes.value;
  }

    constructor(
      private stepService: RouterStepServiceHttp, 
      private route: ActivatedRoute,
      private domain: RouterDomainService) { }

    ngOnInit() {     
      console.log("StepNotesComponent.ngOnInit");
      this.bizl = new StepNotesComponentBizLogic(this, this.domain );
      
      this.buildNotesFormGroup();
      
      //this.stepNotesDiv.nativeElement.style.backgroundColor = 'yellow';
    }

    buildNotesFormGroup()
    {
      this.setupNotes = new FormControl(
        {disabled: !this.bizl.areSetupNotesEditable},
         Validators.compose([
            Validators.required,
          ]));

      this.userNotes = new FormControl('', Validators.required);

      this.RouterStepNotesGroup = new FormGroup({
        setupNotes: this.setupNotes,
        userNotes: this.userNotes
      });

      this.stepId = this.route.snapshot.params['stepId'];

      this.stepService.getRouterStepNotes(this.stepId).subscribe(notes => {
        this.originalSetupNotes = notes.setupNotes;
        this.originalUserNotes = notes.userNotes;
        this.RouterStepNotesGroup.patchValue({
          userNotes: (notes ? notes.userNotes : ''),
          setupNotes: (notes ? notes.setupNotes : '')
        });
      });
    }

    private get isVisible(): boolean {
      return this.m.nativeElement.offsetParent != null;
    }

    setViewDisplayBlock() {
      this.m.nativeElement.style.display = 'block';
    }

    ngAfterContentChecked() {
      console.log('step-notes.ngAfterContentChecked');
      console.log(this.isVisible);
      console.log(this.viewWasVisible);
      

      if (!this.isVisible && this.viewWasVisible) {
        console.log('step-notes.ngAfterContentChecked...visible to hidden');
        this.viewWasVisible = false;
         const setupNotes = this.setupNotes.value;
         const userNotes = this.userNotes.value;
         console.log('step-notes.ngAfterContentChecked...updating setupNotes:' + setupNotes + ' userNotes:' + userNotes);

         
         const info = {
           id: this.stepId,
           setupNotes: setupNotes,
           userNotes: userNotes
         };
         this.stepService.updateRouterStepNotes(info).subscribe(res => { 
         // console.log('step-notes.ngAfterContentChecked updateRouterStepNotes was updated **************************************************');

         });
      } else if (this.isVisible && !this.viewWasVisible) {
        console.log('step-notes.ngAfterContentChecked...hidden to visible');
        //this.buildNotesFormGroup();
        if (this.bizl.areSetupNotesEditable) {
          console.log('step-notes.ngAfterContentChecked...enable setupNotes');
          this.setupNotes.enable();
        } else {
          console.log('step-notes.ngAfterContentChecked...disable setup notes');
          this.setupNotes.disable();
        }
        this.viewWasVisible = true;
      }
    }
    
    ngDoCheck(){      
      console.log('step-notes.ngDoCheck');
      
      if (this.hasChanges && !this.isVisible && this.viewWasVisible)
      {
        const setupNotes = this.setupNotes.value;
        const userNotes = this.userNotes.value;

        const info = {
          id: this.stepId,
          setupNotes: setupNotes,
          userNotes: userNotes
        };

        console.log('step-notes.ngDoCheck...updating stepId: ' + this.stepId + ' setupNotes:' + setupNotes + ' userNotes:' + userNotes);

        this.stepService.updateRouterStepNotes(info).subscribe(res => { 
        // console.log('step-notes.ngDoCheck: updateRouterStepNotes was updated **************************************************');
        });
      }
    }

    ngOnChanges(){
      
    }
     
    ngAfterViewInit()
    {
      
    }

    ngAfterContentInit(){
     
    }
    
    ngAfterViewChecked(){
      
    }

    ngOnDestroy() {
      
    }
  }
