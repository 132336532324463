import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterToolKit'
})
export class FilterToolKitPipe implements PipeTransform {

  transform(toolkits: any, term: any): any {
    // check if term is undefined
    if (term === undefined || term.length == 0 || toolkits === null) {
      return toolkits;
    }
    // return the updated routers array
    return toolkits.filter(function(t){
    return t.workCenterWarehouse.toLowerCase().includes(term.toLowerCase()) ||  t.workCenterDescription.toLowerCase().includes(term.toLowerCase()) ||  t.description.toLowerCase().includes(term.toLowerCase());   
    });
  }
}