import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UserMaintenanceComponent } from '../user-maintenance/user-maintenance.component';
import { UserDetailComponent } from '../user-maintenance/user-detail/user-detail.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([
            { path: 'userMaintenance', component: UserMaintenanceComponent, data: {breadcrumb: 'User Maintenance'}},
            { path: 'userMaintenance/new', component: UserDetailComponent, data: {breadcrumb: 'User Maintenance'}},
            { path: 'userMaintenance/:id', component: UserDetailComponent, data: {breadcrumb: 'User Maintenance'}}
        ])
    ],
    declarations: []
})
export class UserMaintenanceModule { }