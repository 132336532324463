import { Component, Input } from '@angular/core';

export interface ListItem {
    number?: string;
    description?: string;

}

@Component({
    selector: 'list-item',
    template: `
        <div class="avatar">{{item.number}} {{item.description}}</div>
    `
})
export class ListItemComponent {
    @Input()
    item: ListItem;
}
