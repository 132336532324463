import { AppHttpClientService } from './app-http-client.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { StepOrder } from '../models/routerstep.model';
import { IBOMItem, BOMUtils } from '../models/bom.model';
import { Helper } from './helper.service';
import { Http, Response} from '@angular/http';
import { GateKeeperService} from '../shared/gatekeeper/user/user.service';
import { Config } from '../shared/gatekeeper/config';
import { MethodType, MethodTypes } from '../enums/methodtype';



@Injectable()
export class BOMServiceHttp {


  constructor(private helper: Helper,
    private http: Http,
    //private auth: AuthService,
    private ahttp: AppHttpClientService ,
    private gkauth: GateKeeperService,
    private Config: Config) { }

  createBOMItem(b: IBOMItem): Observable<IBOMItem> {
    //console.log('BOMServiceHttp.createBOMItem');
    const info = BOMUtils.toServerInfo(b);
    info.userId = this.gkauth.currentUser.UserID;

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'bomitems/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       const bomItem =  this.translateToIBOMItem(response);
       return bomItem;
    });

    }


    getBOMItem(id: string): Observable<IBOMItem> {
      console.log('BOMServiceHttp.getBOMItem');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'bomitems/' + id;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         const bomItem =  this.translateToIBOMItem(response);
         return bomItem;
      });

    }

    getRouterBOMItems(routerId: string): Observable<IBOMItem[]> {
      console.log('BOMServiceHttp.getBOMItems');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'bomitems/GetBomItemsForRouter/' + routerId;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         const bomItems =  this.translateToIBOMItems(response);
         return bomItems;
      });

    }

getRouterStepBOMItems(routerStepId: string): Observable<IBOMItem[]> {
  console.log('BOMServiceHttp.getRouterStep');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'bomitems/GetBOMItemsForRouterStep/' + routerStepId;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const bomItems =  this.translateToIBOMItems(response);
     return bomItems;
  });

    }


    updateOrder(stepOrder: StepOrder): Observable<number> {
      console.log('BOMServiceHttp.updateOrder');
      //console.log(stepOrder);

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'bomitems/UpdateOrder';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(stepOrder);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        return Number(response);
      });
    }

    updateBOMItem(b: IBOMItem): Observable<IBOMItem> {
      console.log('BOMServiceHttp.updateBOMItem');
      const info = BOMUtils.toServerInfo(b);
      info.userId = this.gkauth.currentUser.UserID;

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'bomItems/UpdateBomItem/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);
     
      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
          const bomItem =  this.translateToIBOMItem(response);
         return bomItem;
      });
  }

  deleteBOMItem(id: string): Observable<number> {
    console.log('BOMServiceHttp.deleteBOMItem');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 
      'bomItems/' + id + '/' + this.gkauth.currentUser.UserID;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.DELETE);
    Config.gkParameters.JsonParameters = '';

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      return Number(response);
    });
  }

  private handleError(error: Response) {
    return Observable.throw(error.statusText);
  }

  translateToIBOMItem(data: any): IBOMItem {
    if (data) {
     return BOMUtils.toIBOMItem(data);
    } else {
      return null;
    }
  }

  translateToIBOMItems(data): IBOMItem[] {
    const ret: IBOMItem[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIBOMItem(d));
      }
    }
    return ret;
  }

}







