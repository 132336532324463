import { TestUtils } from '../../core/models/test.model';
import { TraceType, PartType } from '../../core/models/part.model';
import { IRouterStep } from '../../core/models/routerstep.model';
import { Helper } from '../../core/services/helper.service';
import { IRouter } from '../../core/models/router.model';
import { RouterDomainService } from '../../core/services/router-domain.service';
import { RouterState } from '../../core/enums/router-state';
import { IBOMItem, BOMUtils } from '../../core/models/bom.model';
import { AuthService } from '../../core/services/auth.service';
import { UnitType } from '../../core/enums/unittype';
import { GateKeeperService } from '../../core/shared/gatekeeper/user/user.service';



export class StepBOMComponentBizLogic {
  private utils: Helper = new Helper();

  get NewBOMItemDefaultInfo(): IBOMItem {
    return {
          id: null,
          routerStepId: (!this.utils.isEmptyString(this.stepId) ? this.stepId : null),
          step: -1,
          stepDescription: '',
          partDescription: '',
          partId: null,
          partNumber: null,
          partType: PartType.UNDEFINED,
          qty: null,
          sizeEach: null,
          partUnitType: UnitType.UNDEFINED,
          partTraceType: TraceType.UNDEFINED,
          autoAlloc: false
        };

  }

  constructor(private router: IRouter,
     private stepId: string,
      private bomProvider: any,
      private gkAuth: GateKeeperService,
      private domain: RouterDomainService) {
    // console.log('StepBOMComponentBizLogic');
  }

  public get bomItem() {
    return this.bomProvider.getNewBOMItem();
  }

  public get canAddNewBOMItems(): boolean {
    return this.domain.isEditable;
  }

  public get canViewBOMItem(): boolean {
    return !this.domain.isEditable;
  }

  public get canEditBOMItem(): boolean {
    return this.domain.isEditable;
  }

  public get canDeleteBOMItem(): boolean {
    return this.domain.isEditable;
  }

 get isNewBOMItem() {
    return !this.utils.isExistingItem(this.bomItem);
  }

  get isBOMItemEditable(){
    return this.isNewBOMItem || this.domain.isEditable;;
  }

  get stepExists(){
    return !this.utils.isEmptyString(this.stepId);
  }

  get partSelected(){
    return this.bomItem != null && !this.utils.isEmptyString(this.bomItem.partId);
  }

  get isPartEditable() {
    return this.isNewBOMItem;
  }

  get isPartDivisible(){
    return this.bomItem != null && this.bomItem.partType === PartType.DIVISIBLE;
  }

  get isRouterOperationEditable() {
    return !this.stepExists &&
      this.partSelected &&
      this.isBOMItemEditable;
  }

  get isQuantityEditable(){
    return this.isBOMItemEditable &&
        this.partSelected;
  }

  get isSizeEachEditable(){
    return this.isBOMItemEditable &&
    this.partSelected &&
    this.isPartDivisible;
  }

  get isUnitTypeEditable(){
    return false;
  }

  get isTraceTypeEditable(){
    return false;
  }

 get isAutoAllocEditable() {
    return this.gkAuth.hasPermission('CanSetBOMAutoAlloc') &&
    this.isBOMItemEditable &&
    this.partSelected &&
    (this.bomItem !== null && this.bomItem.partTraceType === TraceType.NONE);
  }

  get shouldShowRouterOperation(){
    return !this.stepExists;
  }

  get canSubmit() {
    return this.isNewBOMItem ||
   ( this.isBOMItemEditable && this.bomProvider.validateBOMFormInfo &&
    !BOMUtils.areEqual(this.bomProvider.selectedBOMItem, this.bomItem));
  }
}
