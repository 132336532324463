import { AppHttpClientService } from './app-http-client.service';
import { Injectable, EventEmitter, PLATFORM_INITIALIZER } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { IUserMaintenance, IUserInfo, IAuthorizedWorkStation, IManufacturingUser } from '../models/usermaintenance.model';
import { Http, Response } from '@angular/http';
import { Helper } from './helper.service';
// import { AuthService } from '../../core/services/auth.service';
import { GateKeeperService} from '../shared/gatekeeper/user/user.service';
import { Config } from '../shared/gatekeeper/config';
import { MethodType, MethodTypes } from '../enums/methodtype';
import 'rxjs/add/observable/throw';
import { utils } from 'protractor';
import { userInfo } from 'os';
import { Console, time } from 'console';
import { stringify } from 'querystring';



@Injectable()
export class UserMaintenanceServiceHttp {


  constructor(
    private http: Http,
    private helper: Helper,
   // private auth: AuthService,
    private ahttp: AppHttpClientService,
    private gkauth: GateKeeperService ,
    private Config: Config) { }

    getUserMaintenance(): Observable<IUserMaintenance[]> {
      console.log('usermaintenance-servicehttp.getUserMaintenance');

      this.gkauth.currentUserInfo = this.makeEmptyUserInfo();
      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'UserMaintenance/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         const ret =  this.translateToIUsers(response);
         console.log(ret);
         return ret;
      });
    }

    concatLines(addUserTimestamp:boolean,...args: string[]):string
    {

      var timestamp = new Date().toLocaleDateString();
      var fullName = this.gkauth.currentUserInfo.firstName + " " + this.gkauth.currentUserInfo.lastName;
      var linebreak = String.fromCharCode(13) + String.fromCharCode(10);
      var ret = "";
     
      console.log('usermaintenance-service.concatLines START addusertimestamp:' + addUserTimestamp +
        ' ts:' + timestamp +
        ' fullName:' + fullName +
        ' linebreak:' + linebreak,
        ' arguments.length' + arguments.length      
      );


      if (addUserTimestamp)
        ret = ret.concat(timestamp," ",fullName,linebreak);
     
      if (arguments.length > 1)
      {
        for (var i = 1; i < arguments.length; i++)
        {          
            var tmp = arguments[i];
            if (tmp)
            {
              console.log('...concat tmp:' + tmp);
              ret = ret.concat(tmp,linebreak);
            }
        }
      }     
      console.log('usermaintenance-service.concatLines END');
      console.log(ret)

      return ret;
    }


    initUserInfo()
    {
      if (this.gkauth.currentUser == null || this.helper.isEmptyString(this.gkauth.currentUser.UserID))
      {
          console.log('usermaintenance.service.initUserInfo: INVALID Current User');
          return;
      }

      if (this.gkauth.currentUserInfo == null || this.helper.isEmptyString(this.gkauth.currentUserInfo.userName))
      {
        console.log('usermaintenance.service.initUserInfo: calling api to get User info');
        this.getUserInfo(this.gkauth.currentUser.UserID).subscribe(userInfo => {
          console.log('api.getUserInfo returns');
        });
      } 
      else
      {
        console.log("usermaintenance.service.initUserInfo: VALID user info found");
      }    
    }

    /* async getCurrentUserInfo():Promise<IUserInfo>{
      
      var userId:string = this.gkauth.currentUser.UserID;
      var userInfo:IUserInfo = this.gkauth.currentUserInfo;
      if (this.helper.isEmptyString(userId))
      {
        console.log('...invalid Gatekeeper User');
        return userInfo; // Observable.of('NULL').toPromise();
      }
       

      if (userInfo != null && !this.helper.isEmptyString(userInfo.userName))
      {
        console.log('...valid User Info found');
        return userInfo;  //Observable.of(userInfo.firstName + " " + userInfo.lastName).toPromise();
      }
          
      else
      {
        console.log('...calling api to get User Info');
        var info = await this.getUserInfoSynchronous(userId);
        return info;
      }
                           
    } */

    setCurrentUserInfo(info:IUserInfo)
    {
      console.log('usermaintenanceservice.setCurrentUserInfo');
      this.gkauth.currentUserInfo = info? info: this.makeEmptyUserInfo();
    }

   getUserInfoSynchronous(userId:string):Promise<IUserInfo>{
     return this.getUserInfo(userId).toPromise();
   }


    getUserInfo(userId:string): Observable<IUserInfo> {
      console.log('usermaintenance-servicehttp.getUserInfo');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'UserMaintenance/GetUserInfo/' + userId;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         const info  =  this.translateToIUserInfo(response)
         this.setCurrentUserInfo(info);         
         //console.log(ret);
         return info;
      });
    }

    updateUser(user: IUserMaintenance):  Observable<IManufacturingUser> {
      console.log('usermaintenance-servicehttp.updateUser method call');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'UserMaintenance/UpdateUser/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(user);
      
      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        console.log(response);
        const ret = this.translateToIManufacturingUser(response);
        console.log(ret);
        return ret;
     });
    }


    enableUserMaintenance(user: IUserMaintenance): Observable<IManufacturingUser> {
      console.log('usermaintenance-servicehttp.enableUserMaintenance');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'UserMaintenance/EnableUser/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(user);
      
      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        console.log(response);
        const ret = this.translateToIManufacturingUser(response);
        console.log(ret);
        return ret;
     });

    }

    addAllAuthorizedWorkStations(muId: string, ws: string[]): Observable<number> {
      console.log('usermaintenance-servicehttp.addAllAuthorizedWorkStation');
      const info = {
        manufacturingUserId: muId,
        stationIds: ws
      }

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'usermaintenance/AddAllAuthorizedWorkStation/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        console.log(response); 
        return Number(response);
      });
    }


    addSelectedAuthorizedWorkStations(muId: string, ws: string[]): Observable<number> {
      console.log('usermaintenance-servicehttp.addAllAuthorizedWorkStation');
      const info = {
       manufacturingUserId: muId,
       stationIds: ws
      }

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'Usermaintenance/AddSelectedAuthorizedWorkStation/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        console.log(response); 
        return Number(response);
      });
    }

    getAuthorizedWorkStations(manufacturingUserId: string): Observable<IAuthorizedWorkStation[]> {
      console.log('usermaintenance-servicehttp.getWorkStations');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'UserMaintenance/GetAuthorizedWorkStations/' + manufacturingUserId;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         console.log(response);
         const ret = this.translateToIAuthorizedWorkStations(response);
         console.log(ret);
         return ret;
      });

    }


    getAvailableWorkStations(manufacturingUserId: string): Observable<IAuthorizedWorkStation[]> {
      console.log('usermaintenance-servicehttp.getWorkStations');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'UserMaintenance/GetAvailableWorkStations/' + manufacturingUserId;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         console.log(response);
         const ret = this.translateToIAuthorizedWorkStations(response);
         console.log(ret);
         return ret;
      });

    }

    removeAuthorizedWorkStation(manufacturingUserId: string, workStationId: string): Observable<number> {
      console.log('usermaintenance-servicehttp.removeAuthorizedWorkStation');
      const info = {
        manufacturingUserId: manufacturingUserId,
        workStationId: workStationId
      };

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'usermaintenance/RemoveAuthorizedWorkStation/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = '{"manufacturingUserId":"' + info.manufacturingUserId + '"' + ',"workStationId":"' + info.workStationId + '"}';

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         console.log(response);
         const ret = Number(response);
         console.log(ret);
         return ret;
      });
    }

    // getWorkStationNumbers(stations: IAuthorizedWorkStation[]): string {
    //   let ret = '';
    //   let i: number;

    //   if (this.helper.isEmptyArray(stations)) {
    //      return null;
    //    } else {
    //      for (i = 0; i < stations.length; i++) {
    //        if (i === 0) {
    //         ret += '"' + stations[i].workCenterId + '"';
    //        } else {
    //           ret += ',"' + stations[i].workCenterId + '"';
    //           }
    //       }
    //   }
    //     return ret;
    //   }


      getWorkStationNumbers(stations: IAuthorizedWorkStation[]): string {
        //const ret: string[] = [];
        let ret: string = ''
        if (this.helper.isEmptyArray(stations)) {
          return null;
        } else {
          for (const st of stations) {
           // ret.push(st != null && st.workCenterId !== 'NONE' ? st.workCenterId : '');
           ret = ret + st.workCenterId + ',';           
          }
          ret = ret.substring(0, ret.length - 1);
          return ret;
        }
      }


    translateToIUser(data: any): IUserMaintenance {
        if (data) {
          return <IUserMaintenance> data;
        } else {
          return null;
        }
      }

      translateToIUserInfo(data: any): IUserInfo {
        if (data) {
          return <IUserInfo> data;
        } else {
          return this.makeEmptyUserInfo();
        }
      }

      makeEmptyUserInfo():IUserInfo
      {
          var ret:IUserInfo = {
            active: false,
            email: "",
            firstName: "",
            lastName: "",
            middleIntitial: "",
            userId: "",
            userName: ""
          };
          return ret;
      }

    translateToIUsers(data: any): IUserMaintenance[] {
    const ret: IUserMaintenance[] = [];
    if (data && data.length > 0) {
        for (const d of data){
            ret.push(this.translateToIUser(d));
        }
    }
    return ret;
    }

    translateToIManufacturingUser(data: any): IManufacturingUser {
      if (data) {
        return <IManufacturingUser> data;
      } else {
        return null;
      }
    }


    translateToIAuthorizedWorkStation(data: any): IAuthorizedWorkStation {
      if (data) {
        return <IAuthorizedWorkStation> data;
      } else {
        return null;
      }
    }

  translateToIAuthorizedWorkStations(data: any): IAuthorizedWorkStation[] {
  const ret: IAuthorizedWorkStation[] = [];
  if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIAuthorizedWorkStation(d));
      }
  }
  return ret;
  }


private handleError(error: Response) {
  return Observable.throw(error.statusText);
}


}






