import { IProgram, ProgramUtils } from '../core/models/program.model';
import { Helper } from '../core/services/helper.service';



export class ProgramsComponentBizLogic {
  private utils: Helper = new Helper();

  constructor(private programProvider: any) {
    // console.log('StepInfoComponentBizLogic');
    // console.log(router);
  }

  get program(){
    return this.programProvider.getNewProgram();
  }

   get newProgramDefaultInfo(): IProgram {
    return {
      id: '',
      description: '',
      number: '',
      inUse: false
    };
  }

  get isProgramEditable() {
    return this.isNewProgam || !this.programProvider.selectedProgram.inUse;
  }

get isNewProgam() {
   return !this.utils.isExistingItem(this.program);
}

get canCreate() {
  return this.isNewProgam;
}

canDelete(program: IProgram) {
  return !program.inUse;
}


canEdit(program: IProgram) {
  return !program.inUse;
}

get canUpdate(){
  return  !this.isNewProgam && this.programProvider.validateNewProgramInfo && !ProgramUtils.areEqual(this.programProvider.selectedProgram, this.program);
}


get canSubmit(){
  return this.isNewProgam || this.canUpdate;
}

  public get isDescriptionEditable(): boolean {
    return true;
  }

  isUniqueNumber(editMode:Boolean) {  
    let isUnique:Boolean = null; 
    if (editMode)
    {      
      isUnique =  !this.utils.listContainsSimilarDifferentItemEdit(this.programProvider.programs, this.program, this.compNumber);
    }
    else
    {
      isUnique = !this.utils.listContainsItem(this.programProvider.programs, this.program, this.compNumber);
    }
    return isUnique;
  }

  isUniqueDescription(editMode:Boolean) {    
    let isUnique:Boolean = null; 
    if (editMode)
    {      
      isUnique =  !this.utils.listContainsSimilarDifferentItemEdit(this.programProvider.programs, this.program, this.compDescription);
    }
    else
    {
      isUnique = !this.utils.listContainsItem(this.programProvider.programs, this.program, this.compDescription);
    }
    return isUnique;
  }

compNumber(a1: IProgram, a2: IProgram) {
  return Helper.cmpStringAlphaNumeric(a1.number, a2.number);
}

compDescription(a1: IProgram, a2: IProgram) {
  return Helper.cmpStringAlphaNumeric(a1.description, a2.description);
}

}
