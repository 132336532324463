import { RouterStepsComponent } from '../router-steps/router-steps.component';
import { Helper } from '../../core/services/helper.service';
import { IRouter, RouterUtils } from '../../core/models/router.model';
import { RouterDomainService } from '../../core/services/router-domain.service';
import { RouterState } from '../../core/enums/router-state';
import { assert } from 'console';


export class RouterInfoComponentBizLogic {
  private utils: Helper = new Helper();

  constructor(private routerProvider: any, private rtDomain: RouterDomainService) {}

  get router(){
    return this.routerProvider.getNewRouter();
  }

  get isNewRouter() {
    return !this.utils.isExistingItem(this.router);
  }

  get isRouterEditable() {
    return this.rtDomain.isEditable;
  }


  public get isPartEditable(): boolean {
    return this.rtDomain.isEditable;
  }

  public get isRevisionEditable(): boolean {
    return false;
  }


  public get isTargetWIPEditable(): boolean {
    return this.rtDomain.isEditable;
  }

  public get isPSLEditable(): boolean {
    return this.rtDomain.isEditable;
  }
  public get isRouterTypeEditable(): boolean {
    return this.rtDomain.isEditable;
  }

  get canCreateRouter() {
    
    var ret =
      this.isNewRouter && 
      this.routerProvider.isNewRouterValid;

    //console.log('router-info.component.bizl.canCreateRouter ret:' + ret);
/*     console.log(this.isNewRouter);
    console.log(this.routerProvider.isNewRouterValid); */
    return ret;   
  }

  get canUpdateRouter() {
    var ret =  
      this.isRouterEditable &&
      this.routerProvider.isNewRouterValid && 
      this.utils.isExistingItem(this.router) &&
      !RouterUtils.areEqual(this.routerProvider.selectedRouter, this.router);
      //console.log('router-info.component.bizl.canUpdateRouter ret:' + ret);
     // console.log(this.isRouterEditable);
     // console.log(this.routerProvider.isNewRouterValid);
     // console.log(this.utils.isExistingItem(this.router));
     // console.log(!RouterUtils.areEqual(this.routerProvider.selectedRouter, this.router)); 
    return ret;
  
    }
}
