import { IFixture, FixtureUtils } from '../core/models/fixture.model';
import { Helper } from '../core/services/helper.service';
import { Component } from '@angular/core';



export class FixturesComponentBizLogic {
  private utils: Helper = new Helper();

  constructor(private fixtureProvider: any) {
    // console.log('StepInfoComponentBizLogic');
    // console.log(router);
  }

  get fixture(){
    return this.fixtureProvider.getNewFixture();
  }

   get newFixtureDefaultInfo(): IFixture {
    return {
      id: '',
      description: '',
      number: '',
      inUse: false
    };
  }

  get isFixtureEditable() {
    return this.isNewFixture;
  }

get isNewFixture() {
   return !this.utils.isExistingItem(this.fixture);
}

get canCreate() {
  return this.isNewFixture;
}

canDelete(fixture: IFixture) {
  return !fixture.inUse;
}

canEdit(fixture: IFixture) {
  return !fixture.inUse;
}


get canUpdate(){

  return  !this.isNewFixture && this.fixtureProvider.validateNewFixtureInfo &&
   !FixtureUtils.areEqual(this.fixtureProvider.selectedFixture, this.fixture);
}

get canSubmit(){
  return this.isNewFixture || this.canUpdate;
}


  public get isDescriptionEditable(): boolean {
    return true;
  }



  /* get isUniqueNumber() {
    return !this.utils.listContainsSimilarDifferentItem(this.fixtureProvider.fixtures, this.fixture, this.compNumber);
  }

  get isUniqueDescription() {
    return !this.utils.listContainsSimilarDifferentItem(this.fixtureProvider.fixtures, this.fixture, this.compDescription);
  }
 */
  isUniqueNumber(editMode:Boolean) {  
    let isUnique:Boolean = null; 
    if (editMode)
    {      
      isUnique =  !this.utils.listContainsSimilarDifferentItemEdit(this.fixtureProvider.fixtures, this.fixture, this.compNumber);
    }
    else
    {
      isUnique = !this.utils.listContainsItem(this.fixtureProvider.fixtures, this.fixture, this.compNumber);
    }
    return isUnique;
  }

  isUniqueDescription(editMode:Boolean) {    
    let isUnique:Boolean = null; 
    if (editMode)
    {      
      isUnique =  !this.utils.listContainsSimilarDifferentItemEdit(this.fixtureProvider.fixtures, this.fixture, this.compDescription);
    }
    else
    {
      isUnique = !this.utils.listContainsItem(this.fixtureProvider.fixtures, this.fixture, this.compDescription);
    }
    return isUnique;
  }

compNumber(a1: IFixture, a2: IFixture) {
  // console.log('fixtures.component.compNumber a1.number:' + a1.number + ' a2.number:' + a2.number);
  return Helper.cmpStringAlphaNumeric(a1.number, a2.number);
}

compDescription(a1: IFixture, a2: IFixture) {
  return Helper.cmpStringAlphaNumeric(a1.description, a2.description);
}

}
