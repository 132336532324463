import { Injectable} from "@angular/core";
import { observable, Observable } from "rxjs";
import { WorkStationService } from "../../core/services/workstation.service";
import { of as observableOf} from 'rxjs/observable/of'
import {map} from 'rxjs/operators'
import { WorkCenterDataService } from "../../core/services/workcenter-data.service";
import { IWorkCenter } from "../../core/models/workcenter.model";
import { ActivatedRoute, ParamMap, Resolve } from "@angular/router";

@Injectable()
export class WorkCenterDetailResolver implements Resolve<any>{

    constructor(
        private data:WorkCenterDataService,
        private workStationService:WorkStationService,
        private route:ActivatedRoute)
    {

    }

    resolve(){
        const wc:IWorkCenter = this.data.selectedWorkCenter;

        if (wc && wc.id && this.data.doCopy)
        {
            console.log("WorkCenterDetailResolver.resolve COPY id:" + wc.id);
            return this.workStationService.getWorkStationsForWorkCenter(wc.id).pipe(map(workStations => {

                const wcCopy:IWorkCenter = {
                    code : wc.code,
                    description: (wc.description? wc.description.concat(' COPY'): ''),
                    id : null,
                    inUse : wc.inUse,
                    isOutsource : wc.isOutsource,
                    name : '',
                    scheduleType : wc.scheduleType,
                    type : wc.type,
                    warehouse : wc.warehouse
                }
                this.data.selectedWorkCenter = wcCopy;

                if (workStations && Array.isArray(workStations))
                {
                    for (var i =0; i< workStations.length; i++)
                    {
                        workStations[i].id = null;
                    }
                } 
                else
                    workStations = [];      

                return workStations;

            } ));
        } else if (wc && wc.id){
            console.log("WorkCenterDetailResolver.resolve id:" + wc.id);
            return this.workStationService.getWorkStationsForWorkCenter(wc.id).pipe(map(workStations => workStations ))
         }else{
            console.log("WorkCenterDetailResolver.resolve id:" + null);
            return observableOf(null);
         }            
    }



  /*   resolve(){
        var workCenterId:String = null;

        this.route.paramMap.subscribe(
            (params:ParamMap) => {
                workCenterId = params.get('id'); 
                console.log("WorkCenterDetailResolver.resolve id:" + workCenterId);

                const wc:IWorkCenter = this.data.selectedWorkCenter;
                if (wc && wc.id){
                    console.log("...workCenterID:" + wc.id);
                    return this.workStationService.getWorkStationsForWorkCenter(wc.id).pipe(map(workStations => workStations ))
                }
                else
                    return observableOf(null);
            }
        )       
    } */

}