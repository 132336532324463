import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterFiles'
})
export class FilterFilePipe implements PipeTransform {

  transform(files: any, term: any): any {
    // check if term is undefined
    if (term === undefined || term.length == 0 || files === null) {
        return files;
      }
    // return the updated routers array
    return files.filter(function(f){
    return f.name.toLowerCase().includes(term.toLowerCase()) || f.type.toLowerCase().includes(term.toLowerCase()) || f.description.toLowerCase().includes(term.toLowerCase());
    });
  }
}
