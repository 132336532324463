import {bindCallback} from 'rxjs/observable/bindCallback';
import { Component, OnInit, ViewChild, ElementRef,  AfterContentChecked, OnDestroy, Input, Inject } from '@angular/core';
import { WorkCenterDataService } from '../../core/services/workcenter-data.service';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { IWorkCenter, ScheduleTypeUtils, WorkCenterTypeUtils, WorkCenterUtils } from '../../core/models/workcenter.model';
import { WorkCenterService } from '../../core/services/workcenter.service';
import { Helper } from '../../core/services/helper.service';
import { WorkStationService } from '../../core/services/workstation.service';
import { IWorkStation, WorkStationType } from '../../core/models/workstation.model';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkCenterInfoComponent } from './work-center-info.component';
import { WorkCenterDetailComponentBizLogic } from './work-center-detail.component.bizl';
import { AlertLevel, AlertReponse } from '../../core/models/alert.model';
import { AlertService } from '../../core/services/alert.service';
import { SortCriteria} from '../../core/models/sort.model';
import { StepDetailComponent } from '../../step-detail/step-detail.component';
import { NullTemplateVisitor } from '@angular/compiler';
import { WCToolBagOrder } from '../../core/models/tool.model';
import { Observable, Observer } from 'rxjs';
import { isArray } from 'jquery';

@Component({
  selector: 'work-center-detail1',
  template: require('./work-center-detail.component1.html'),
  styles: [require('./work-center-detail.component1.css').toString()]
})
export class WorkCenterDetailComponent1 implements OnInit, AfterContentChecked, OnDestroy {
 WorkStationDetailFormGroup: FormGroup;
 description: FormControl;
 @ViewChild('workCenterDetails', {static: true}) view: ElementRef;
 @ViewChild('workCenterInfo', {static: true}) info: WorkCenterInfoComponent;
 viewWasVisible = false;
 workStations: IWorkStation[];
 workCenterTypes = WorkCenterTypeUtils.types;
 scheduleTypes = ScheduleTypeUtils.types;
 userSubmittedWorkStation = false;
 bizl: WorkCenterDetailComponentBizLogic;
 lastWorkStationDescriptionValidationMsg = '';
 originalWorkStations:IWorkStation[];
 addWorkStationForm:boolean = false;

 get workCenterDetailsTitle(){
   let ret = 'Create A New Work Center';
   if (this.selectedWorkCenter && !this.helper.isEmptyString(this.selectedWorkCenter.id))
      ret = "Update Work Center"
 
      return ret;
  }



 get selectedWorkCenter(){
     // console.log('WorkCenterDetailComponent1.selectedWorkCenter.Name' + this.data.selectedWorkCenter && this.data.selectedWorkCenter.name? this.data.selectedWorkCenter.name:"NULL");
      return this.data.selectedWorkCenter;
 }

set selectedWorkCenter(wc:IWorkCenter)
{
  this.data.selectedWorkCenter = wc;
}

 get selectedWorkStation(){
   //console.log('WorkCenterDetailComponent1.selectedWorkStation.Description:' + String(this.data.selectedWorkStation && this.data.selectedWorkStation.description ? this.data.selectedWorkStation.description:"NULL"));
   return this.data.selectedWorkStation;
 }

 set selectedWorkStation(w:IWorkStation)
 {
   this.data.selectedWorkStation = w;
 }


  constructor(public data: WorkCenterDataService
  , private helper: Helper
  , private centerservice: WorkCenterService
  , private stationService: WorkStationService
  , private navRouter: Router
  , private alert: AlertService
  , private route: ActivatedRoute ) { }


  ngOnInit() {
    console.log('WorkCenterDetailComponent1.ngOnInit');
    this.bizl = new WorkCenterDetailComponentBizLogic(this);

    this.description = new FormControl('', [Validators.required, this.uniqueWorkStationDescription(this)]);
    this.WorkStationDetailFormGroup = new FormGroup({
      description: this.description
    });
    console.log('WorkCenterDetailComponent1.ngOnInit' + this.WorkStationDetailFormGroup);
  }

  private uniqueWorkStationDescription(wcd:WorkCenterDetailComponent1)
  {
      return (control: FormControl):{[key:string]: any} => {
        let stations = wcd.workStations;
        let station = wcd.workStationInfo;

        console.log("WorkCenterDetaulsComponent.uniqueWorkStationDescription stations.len:" + String(stations? stations.length:"NULL") + " station.description:" + String(station? station.description: "NULL"));

        if (this.helper.isEmptyArray(stations) || !station)
          return null;

        return !wcd.bizl.hasUniqueStationDescription(stations, station)
      ? {'uniqueWorkStationDescription':'Must be unique'}
      : null;

      }

      
  }


    updateWorkStationFormGroup(ws: IWorkStation) {
      console.log('WorkCenterDetailComponent1.updateWorkStationFormGroup');
      console.log(ws? ws.description : "NULL");
    this.WorkStationDetailFormGroup.patchValue({
      description: ws.description
    });

  }



  submitWorkStation(formValue) {
    console.log('WorkCenterDetailComponent1.submitWorkStation');
    console.log(this.WorkStationDetailFormGroup);
    console.log(this.bizl);
    console.log(this);
    this.userSubmittedWorkStation = true;

    this.addWorkStationForm = false;
    this.workStations = this.helper.arrayOrEmpty(this.workStations);
    var wsInfo:IWorkStation = this.workStationInfo;
    if (this.helper.isExistingItem(wsInfo))
      this.workStations = this.workStations.filter(x => x.id !== wsInfo.id);         
    this.workStations.push(wsInfo);
    this.selectedWorkStation = wsInfo;
    this.defaultSortWorkStations();
  }

  cancelAddWorkStation()
  {
    this.addWorkStationForm = false;
  }

showWorkStationForm()
{
  this.description.reset();
  this.updateWorkStationFormGroup(this.selectedWorkStation);
  this.addWorkStationForm = true;
    $(document).ready(function(){
      $('#workStationDescription').focus();
    });
}

addWorkStation() {
    console.log("WorkCenterDetailComponent1.addWorkStation");
    this.selectedWorkStation = this.data.newWorkStationTemplate;
    this.showWorkStationForm();
 }

editWorkStation(ws: IWorkStation) {
  console.log("WorkCenterDetailComponent1.editWorkStation");
  this.selectedWorkStation = ws;
  this.showWorkStationForm();

 }

 deleteWorkStation(ws: IWorkStation) {
   this.alert.showYesNo('Are you sure you want to delete work station ' + ws.description   + '?',
   AlertLevel.WARNING,
   (ar: AlertReponse) => {
     console.log(ar);
     console.log(ar === AlertReponse.OK);
     console.log(AlertReponse.OK);
     if (ar === AlertReponse.YES) {
       if (this.helper.isExistingItem(ws))
          this.workStations = this.workStations.filter(x=> x.id != ws.id);
       else
          this.workStations = this.workStations.filter(x => x.id == null && x.description != ws.description);
     }
   });
 }

get workStationInfo() {
  return this.fromWorkStationDetailForm();
}

get workCenterInfo() {
  return this.info.fromFormValues();
}

get isWorkStationInfoValid() {
  return this.WorkStationDetailFormGroup.valid;
}

get isWorkCenterInfoValid()
{
  return this.info.isWorkCenterInfoFormValid;
}

get isWorkCenterInfoEnabled() {
  return this.info.WorkCenterDetails.enabled;

}

getWorkCenterName() {
    return this.info.newWorkCenterInfo.warehouse + ' ' + this.info.newWorkCenterInfo.description;
 }

 fromWorkStationDetailForm(): IWorkStation {

  return {
     id: this.selectedWorkStation? this.selectedWorkStation.id : null,
     number: "",
     description: this.description? String(this.description.value):"",
     workCenterId: this.selectedWorkCenter? this.selectedWorkCenter.id: null,
     type: WorkStationType.PHYSICAL, 
     inUse: this.selectedWorkStation != null ? this.selectedWorkStation.inUse : false,
  };
}

    private get isVisible(): boolean {
      return this.view.nativeElement.offsetParent != null;
    }

    copyWorkStations(stations:IWorkStation[]):IWorkStation[]
    {
      var ret:IWorkStation[] = null;
      
      if (!this.helper.isEmptyArray(stations))
      {
        ret = [];
        for (var i:number = 0; i < stations.length; i++)
        {
          var s:IWorkStation = stations[i];

          ret.push(
             {
                id: s.id,
                number: s.number,
                type: s.type,
                description: s.description,
                workCenterId: s.workCenterId,
                inUse: s.inUse
             } 
          );
        }
      }
        
      return ret;
    }

    private defaultSortWorkStations()
    {
      this.workStations.sort(this.sortStationNumberDescription);
    }

    ngAfterContentChecked() {
      if (!this.isVisible && this.viewWasVisible) {
        this.viewWasVisible = false;
      } else if (this.isVisible && !this.viewWasVisible) {
          console.log('WorkCenterDetailComponent1.ngAfterContentChecked...hidden to visible');
         
          this.viewWasVisible = true;
          this.addWorkStationForm = false;

          if (this.route.snapshot.data['workStations'] && Array.isArray(this.route.snapshot.data['workStations']))
            this.workStations = this.route.snapshot.data['workStations'];
          else
            this.workStations = [];
                    
          this.originalWorkStations = this.workStations.slice(0); //copy array
          this.defaultSortWorkStations();

      } 
      else if (!this.isVisible && this.viewWasVisible) 
      {
        console.log('WorkCenterDetailComponent1.ngAfterContentChecked...visible to hidden');
        this.viewWasVisible = false;
        /* if (this.bizl.canUpdateWorkCenter) {
          this.centerservice.updateWorkCenter(this.workCenterInfo).subscribe(updated =>{ });
        } */
      }
    }

    sortStationNumberDescription(s1: IWorkStation, s2: IWorkStation) {
      const ret = Helper.cmpStringAlphaNumeric(s1.number, s2.number);
      if (ret === 0) {
        return Helper.cmpStringAlphaNumeric(s1.description, s2.description );
      } else {
        return ret;
      }
    }


  ngOnDestroy() {
      console.log('WorkCenterDetailComponent1.ngOnDestroy');
      /* if (this.bizl.canUpdateWorkCenter) {
        this.centerservice.updateWorkCenter(this.workCenterInfo).subscribe(updated => { });
      } */
    }



    getTitle() {
      if (this.bizl.isNewWorkStation) {
        return 'Create a New Station';
      } else if (this.bizl.isWorkStationEditable) {
        return 'Edit Station Info';
      } else {
        return 'Station Info';
      }
    }

/*     
    validateWorkStationDescription() {
        return !this.bizl.isWorkStationEditable ||
          (this.bizl.isNewWorkStation && !this.userSubmittedWorkStation) ||
          this.WorkStationDetailFormGroup.controls.description.valid;
    } */
    

    descriptionKeyupHandler(x)
    {
      console.log("WorkCEnterDetailComponent.descriptionKeyupHander:" + x);

      if (this.helper.isEmptyString(this.description.value))
      {
        console.log("...formGroup.updateValuesandValidity");
        this.WorkStationDetailFormGroup.updateValueAndValidity();
      }
        
    }


    get validateWorkStationDescription():boolean {
      var ret: boolean = this.description.valid || !this.description.dirty;
      console.log("WorkCenterDetailComponent1.validateWorkStationDescription:" + ret );
      return ret;
    }

    onSorted(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'number')
          {
            this.workStations.sort(this.sortByWorkStationNumberDesc)
          }
          else if (criteria.sortColumn == 'description')
          {
            this.workStations.sort(this.sortByDescriptionDesc)
          }          
        }
        else {
          if(criteria.sortColumn == 'number')
          {
            this.workStations.sort(this.sortByWorkStationNumberAsc)
          }
          else if (criteria.sortColumn == 'description')
          {
            this.workStations.sort(this.sortByDescriptionAsc)
          }           
        }
      }      
  } 
  
  
  sortByWorkStationNumberAsc(a1: IWorkStation, a2: IWorkStation) { 
    return Helper.cmpString(a1.number, a2.number); 
  }
  
  sortByWorkStationNumberDesc(a1: IWorkStation, a2: IWorkStation) { 
    return Helper.cmpStringDesc(a1.number, a2.number); 
  }
  
  sortByDescriptionAsc(a1: IWorkStation, a2: IWorkStation) { 
    return Helper.cmpString(a1.description, a2.description); 
  }
  
  sortByDescriptionDesc(a1: IWorkStation, a2: IWorkStation) {
    return Helper.cmpStringDesc(a1.description, a2.description); 
  }

  
  public get workStationsAreValid():boolean
  {
    return !this.helper.isEmptyArray(this.workStations)
  }


  public get areWorkCenterDetailsValid():boolean
  {
    var ret:boolean = this.isWorkCenterInfoValid && this.workStationsAreValid;
    console.log("WorkCenterDetailComponent1.areWorkCenterDetailsValid:" + ret );
    return ret;
  }

  public get canSubmitWorkCenterDetails() : boolean
  {
    var ret:boolean = (this.hasWorkCenterDetailsChanged) && this.areWorkCenterDetailsValid;
    console.log('WorkCenterDetailComponent1.canSubmitWorkCenterDetails:' + ret);
    return  ret;     
  }

  public get hasWorkCenterDetailsChanged():boolean
  {
    var ret:boolean = this.info.hasWorkCenterDataChanged || this.workStationsHaveChanged;     
    console.log('WorkCenterDetailComponent1.hasWorkCenterDetailsChanged:' + ret);
    return  ret;     
  }

  public get workStationsHaveChanged():boolean
  {
    var ret:boolean = !this.areEqualWorkStationLists(this.originalWorkStations, this.workStations);
    console.log('WorkCenterDetailComponent1.haveWorkStationsChanged:' + ret);
    return  ret;  
   
  }



  public areEqualWorkStationLists(w1:IWorkStation[], w2:IWorkStation[]):boolean
  {    
    //console.log('...areEqualWorkStationLists');

    if (this.helper.isEmptyArray(w1))
      return this.helper.isEmptyArray(w2);
    
    if (this.helper.isEmptyArray(w2) || w1.length != w2.length)
      return false;

     w1.sort(this.sortByDescriptionAsc);
     w2.sort(this.sortByDescriptionAsc);

     //this.dumpWorkStations(w1);
     //this.dumpWorkStations(w2);

     for (var i:number = 0; i < w1.length; i++ )
     {
       if (!this.bizl.areEqualWorkStations(w1[i], w2[i]))
        return false;
     }

     return true;
  }


  dumpWorkStation(prefix:string, station:IWorkStation)
  {
    console.log(prefix + "ws: " + String(station.id? station.id: "NULL") + " , " + station.description);
  }

  dumpWorkStations(stations:IWorkStation[]){
    console.log("workstations len:" + this.helper.isEmptyArray(stations)?"NULL":stations.length);
    if (!this.helper.isEmptyArray(stations))
    {
      for (var i:number = 0; i< stations.length; i++)
        this.dumpWorkStation("...",stations[i]);
    }
  }

  public cancelWorkCenterDetails(){
    console.log('WorkCenterDetailComponent1.cancelWorkCenterDetails');

    this.goToWorkCentersList();
  }

  public goToWorkCentersList()
  {
    console.log('WorkCenterDetailComponent1.goToWorkCentersList');
    this.navRouter.navigate(['/workCenters']);
  }

  private isCreateNewMode():boolean
  {
    return !this.selectedWorkCenter || this.helper.isEmptyString(this.selectedWorkCenter.id);
  }

  public submitWorkCenterDetails()
  {
    console.log('WorkCenterDetailComponent1.submitWorkCenterDetails');
    var w:IWorkCenter = this.workCenterInfo;
    var s:IWorkStation[] = this.workStations;
    //console.log(w);
    //this.dumpWorkStations(s);
 
    this.centerservice.updateWorkCenterAndStations(w, s).subscribe( center =>
      {
        this.originalWorkStations = s;
        this.selectedWorkCenter = w;
        this.goToWorkCentersList();
      });
  
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.canSubmitWorkCenterDetails) {
        return new Observable( (observer) =>
        {

          this.alert.showYesNo(
            'Unsubmitted changes will be lost. Do you really want to leave this page?',
            AlertLevel.WARNING,
            (ar: AlertReponse) => {
              var result:boolean = ar === AlertReponse.YES;
              observer.next(result);
            }
          );
        });
      }
      else
        return true;            
    }   


    getNextWorkStationNumber() {
      const nextNum:number = Math.max.apply(null, this.workStations.filter(w=> +w.number < 900).map(w => +w.number));
      let ret = "000" + nextNum.toString();
      return ret.substr(ret.length-4,4);
    }

 }
