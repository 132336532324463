import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPart'
})
export class FilterPartPipe implements PipeTransform {

  transform(parts: any, term: any): any {
    // check if term is undefined
    if (term === undefined || term.length == 0 || parts === null) {
      return parts;
    }
    // return the updated routers array
    return parts.filter(function(part){
    return part.number.toLowerCase().includes(term.toLowerCase()) || part.description.toLowerCase().includes(term.toLowerCase());
    });
  }
}
