import { Helper } from '../../core/services/helper.service';
import { IRouter } from '../../core/models/router.model';
import { RouterDomainService } from '../../core/services/router-domain.service';
import { RouterState } from '../../core/enums/router-state';
import { IRouterStep, PrintOption, PrintType, RouterStepUtils } from '../../core/models/routerstep.model';
import { StepInfoComponent } from './step-info.component';


export class StepInfoComponentBizLogic {
  private utils: Helper = new Helper();
  

  constructor(private stepProvider: any, private domain:RouterDomainService) {
    // console.log('StepInfoComponentBizLogic');
    // console.log(router);
  }

  get router()
  {
    return this.stepProvider.selectedRouter;
  }

  get routerStep(){
    return this.stepProvider.getNewRouterStep();
  }
 
  get newRouterStepDefaultInfo(): IRouterStep {
    return {
      id: '',
      routerId: this.utils.isExistingItem(this.router) ? this.router.id : null,
      description: '',
      step: 1,
      printOption: PrintOption.AUTO,
      setupTime: 0,
      avgTime: 0,
      familyId: '',
      runTime: 0,
      idleTime: 0,
      doFgLot: false,
      deferTesting: true,
      workCenterId: '',
      workCenterName: null,
      printType: PrintType.S1,
      bestTime: 0,
    };
  }

  get isStepEditable() {
    return this.domain.isEditable;
  }

  get isNewStep() {
    return !this.utils.isExistingItem(this.routerStep);
  }

  get canUpdate(){

    var ret =  
      this.isStepEditable && 
      this.stepProvider.validateNewRouterInfo &&
      !RouterStepUtils.areEqual(this.stepProvider.selectedRouterStep, this.routerStep);
        
    console.log('step-info.component.bizl.canUpdate ret:' + ret);
    console.log(this.isStepEditable);
    console.log(this.stepProvider.validateNewRouterInfo); 
    console.log(this.stepProvider.selectedRouterStep);
    console.log(this.routerStep);
    return ret;
  }

  public get isWorkCenterEditable(): boolean {
    return this.domain.isEditable;
  }

  public get isDescriptionEditable(): boolean {
    return this.domain.isEditable;
  }

  public get isFamilyIdEditable(): boolean {
    return this.domain.isEditable;
  }

  public get isDoFgLotEditable(): boolean {
    return this.domain.isEditable;
  }

  public get isDeferTestingEditable(): boolean {
    return this.domain.isEditable;
  }

  public get isSetupTimeEditable(): boolean {
    return this.domain.isEditable;
  }

  public get isRunTimeEditable(): boolean {
    return this.domain.isEditable;
  }

  public get isIdleTimeEditable(): boolean {
    return this.domain.isEditable;
  }

  public get isBestTimeEditable(): boolean {
    return false; 
  }

  public get isAvgTimeEditable(): boolean {
    return false; 
  }

  public get isPrintOptionEditable(): boolean {
    return this.domain.isEditable;
  }

  public get isPrintTypeEditable(): boolean {
    return false;
  }

  isUniqueRouterStepDescription(routerSteps: IRouterStep[], description: string, id:string){
    var ret = true;
    if (!Helper.sIsEmptyArray(routerSteps) && !Helper.isUndefined(description))
    {
      for (const step of routerSteps)
      {    
        if (
          Helper.isUndefined(step.description) ||
          (id != null && step.id == id)
        )
        continue;

        if (step.description.toLowerCase().trim() === description.toLowerCase().trim())
        {
          ret = false;
          break;
        }
      }
    }    
    
    return ret;    
  }
}
