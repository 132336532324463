// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormGroupContainer {\r\n    padding: 25px 20px;\r\n}\r\n.FieldName {\r\n    color: #616161;\r\n    font-weight: 700;\r\n    font-size: 1.2em;\r\n}\r\n.InputField {\r\n    color: #616161;\r\n    border: 1px solid #e0e0e0;\r\n    height: 28px;\r\n    margin-bottom: 30px;\r\n    border-radius: 4px;\r\n    font-weight: 700;\r\n    font-size: .8em;\r\n    overflow: hidden;\r\n}\r\n.InputFieldLong {\r\n    color: #616161;\r\n    border: 1px solid #e0e0e0;\r\n    height: 28px;\r\n    margin-bottom: 30px;\r\n    border-radius: 4px;\r\n    font-weight: 700;\r\n    font-size: .8em;\r\n    overflow: hidden;\r\n    width: 500px;\r\n}\r\n.LabelContainer {\r\n    float: left;\r\n    width: 125px;\r\n}\r\noption {\r\n    padding: 30px;\r\n}\r\n#InputContainer {\r\n    width: 500px;\r\n    text-align: left;\r\n}\r\n.InputContainer{\r\n    width: 250px;\r\n    text-align: left;\r\n}\r\n.Error {\r\n    color: #d12222;    \r\n    font-weight: 700;\r\n    font-size: 1.0em; \r\n    padding-left: 20px; \r\n    width: 10%;\r\n}\r\n#viewFormFileButton > img{\r\n  left: 100px;\r\n  width: 33px;\r\n  height: 33px;\r\n}\r\n#viewFormFileButton {\r\n    background: transparent;\r\n    border: none;\r\n}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();