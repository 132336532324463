import { IToolBag, ToolBagUtils } from '../../../core/models/tool.model';
import { Helper } from '../../../core/services/helper.service';
import { IRouter } from '../../../core/models/router.model';
import { RouterDomainService } from '../../../core/services/router-domain.service';
import { RouterState } from '../../../core/enums/router-state';


export class SpecToolComponentBizLogic {
  private utils: Helper = new Helper();

  constructor(private stationProvider: any, private domain:RouterDomainService) {
    console.log('new SpecToolComponentBizLogic()');
  }

  public get toolBag(){
    return this.stationProvider.getNewToolBag();
  }

  public get router(){
    return this.stationProvider.selectedRouter;
  }

  get isNewToolBag(){
    return !this.utils.isExistingItem(this.toolBag);
  }

  public get isToolBagEditable(): boolean {
    return this.router == null || this.domain.isEditable;
  }

  public get areToolsEditable(): boolean {

      return this.router == null  || this.domain.isEditable;
  }

  public get areNotesEditable(): boolean {
    return this.router == null || this.domain.isEditable;
  }

  get canCreate(){
    //console.log('spec-tool.component.bizl.canCreate');
    //console.log(this);
    return this.isNewToolBag;
  }

  get canUpdate(){
    //console.log('spec-tool.component.bizl.canUpdate');
    //console.log(this);
    //console.log(this.stationProvider);
    return !this.isNewToolBag && this.stationProvider.validateNewToolBag &&
        !ToolBagUtils.areEqual(this.stationProvider.toolBag, this.toolBag);
  }

  get canSubmit(){
    return this.isNewToolBag || this.canUpdate;
  }

  isUnique(editMode:Boolean) {
    let isUnique:Boolean = null; 
    console.log('********************spec-tool bizl.component.isUniqueDescription editMode is : ' + editMode);
    if (editMode)
    {      
      isUnique =  !this.utils.listContainsSimilarDifferentItemEdit(this.stationProvider.toolBags, this.toolBag, this.compByNumber);
    }
    else
    {
      isUnique = !this.utils.listContainsItem(this.stationProvider.toolBags, this.toolBag, this.compByNumber);
    }
    return isUnique; 
  }

  compByNumber(a: IToolBag, b: IToolBag ) {
    // console.log('compByNumber a.description, b.description:' + a.description + ',' + b.description);
    if (b.description !== null)
    {
      return Helper.cmpStringAlphaNumeric(a.description, b.description);
    }
    else
    {
      return 0;
    }
    
  } 
}
