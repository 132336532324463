import { IReport, ReportUtils } from '../core/models/report.model';
import { Helper } from '../core/services/helper.service';



export class ReportsComponentBizLogic {
  private utils: Helper = new Helper();

  constructor(private reportProvider: any) {
    // console.log('StepInfoComponentBizLogic');
    // console.log(router);
  }

  get report(){
    return this.reportProvider.getNewReport();
  }

   get newReportDefaultInfo(): IReport {
    return {
      id: '',
      description: '',
      name: '',
      inUse: false
    };
  }

  get isReportEditable() {
    return this.isNewReport || !this.reportProvider.selectedProgram.inUse;
  }

get isNewReport() {
   return !this.utils.isExistingItem(this.report);
}

get canCreate() {
  return this.isNewReport;
}

canDelete(report: IReport) {
  return !report.inUse;
}


canEdit(report: IReport) {
  return !report.inUse;
}

get canUpdate(){

  return  !this.isNewReport && this.reportProvider.validateNewReportInfo() &&
   !ReportUtils.areEqual(this.reportProvider.selectedReport, this.report);
}


get canSubmit(){
  return this.isNewReport || this.canUpdate;
}

  public get isDescriptionEditable(): boolean {
    return true;
  }

  get isUniqueName() {
    return !this.utils.listContainsSimilarDifferentItem(this.reportProvider.reports, this.report, this.compName);
  }

  get isUniqueDescription() {
    return !this.utils.listContainsSimilarDifferentItem(this.reportProvider.reports, this.report, this.compDescription);
  }

compName(a1: IReport, a2: IReport) {
  return Helper.cmpStringAlphaNumeric(a1.name, a2.name);
}

compDescription(a1: IReport, a2: IReport) {
  return Helper.cmpStringAlphaNumeric(a1.description, a2.description);
}

}
