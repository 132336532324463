
import {Observable ,Subscription as ISubscription } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { FileServiceHttp } from '../../core/services/file.servicehttp';
import { TestServiceHttp } from '../../core/services/test.servicehttp';
import { of as observableOf} from 'rxjs/observable/of'

 @Injectable()
 export class TestKitDetailResolveFileTests implements Resolve<any> {
  constructor(private testService: TestServiceHttp, private fileService: FileServiceHttp) {}

  resolve(router: ActivatedRouteSnapshot) {
    console.log('test-kit-detail-resolveFileTests');
    const fileId = router.params['id'];
    if (fileId !== 'new') {
      return this.testService.getFileTestsForFileId(fileId);
    } else {
      observableOf(null);
    }
  }
}
