import { UserInfoComponent } from './user-detail/user-info.component';
import { Helper } from '../core/services/helper.service';
import { Component, OnChanges, OnInit, DoCheck, AfterContentChecked, AfterViewChecked, AfterViewInit, OnDestroy, AfterContentInit,
 ViewChild, ElementRef } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
 import { WorkCenterService } from '../core/services/workcenter.service';
 import { WorkCenterDataService } from '../core/services/workcenter-data.service';
import { IUserMaintenance, IAuthorizedWorkStation } from '../core/models/usermaintenance.model';
import { FilterUserMaintenancePipe } from '../core/pipes/filter-usermaintenance.pipe';
import { AlertService } from '../core/services/alert.service';
import { AlertLevel, AlertReponse } from '../core/models/alert.model';
import { UserMaintenanceServiceHttp} from '../core/services/usermaintenance.servicehttp';
import { UserMaintenanceDataService } from '../core/services/usermaintenance-data.service';
import { UserDetailComponentBizLogic } from './user-detail/user-detail.component.bizl';
import { SortCriteria} from '../core/models/sort.model';

@Component({
  selector: 'user-maintenance',
  template: require('./user-maintenance.component.html'),
  styles: [require('./user-maintenance.component.css').toString()]
})
export class UserMaintenanceComponent implements OnInit, AfterContentChecked, AfterViewChecked, AfterViewInit, OnDestroy, AfterContentInit {

  isEnabled: boolean;
  isEditable: boolean;
  users: IUserMaintenance[];
  @ViewChild('userMaintenanceList', {static: true}) userMaintenanceList: ElementRef;
  @ViewChild('UserMaintenanceOverlay', {static: true}) userMaintenanceDetails: ElementRef;
  @ViewChild('table', {static: true}) table: ElementRef;
  @ViewChild('workCenterInfo', {static: true}) workCenterInfo: UserInfoComponent;
  viewWasVisible = false;
  public term: string;
  bizl: UserDetailComponentBizLogic;
  dir ='asc';

  get userSubmitted() {
    if (this.workCenterInfo != null) {
      return this.workCenterInfo.userSubmitted;
    }  else {
      return false;
    }
  }
  set userSubmitted(val: boolean) {
    if (this.workCenterInfo != null) {
      this.workCenterInfo.userSubmitted = val;
    }
  }

  constructor(private route: ActivatedRoute
  , public data: UserMaintenanceDataService
  , private router: Router
  , private userMaintenanceService: UserMaintenanceServiceHttp
  , private alert: AlertService
  , private utils: Helper  ) { }


ngOnInit() {
  setInterval(() => {

  console.log('user-maintenance life cycle.component.ngOnInit');
  if (!this.isVisible && this.viewWasVisible) 
  {
    console.log('user-maintenance.component.OnInit...from visible to hidden');
    this.viewWasVisible = false;
    this.userMaintenanceService.getUserMaintenance().subscribe(userMaint => {
      this.users  = userMaint;
      this.users.sort(this.sortByLastNameAsc)      
    });
  } 
  else if (this.isVisible && !this.viewWasVisible) 
  {
    console.log('user-maintenance.component.OnInit...from hidden to visible');
    this.viewWasVisible = true;
    this.userSubmitted = false;
    this.userMaintenanceService.getUserMaintenance().subscribe(userMaint => {
      this.users  = userMaint;
      this.users.sort(this.sortByLastNameAsc)      
    });
  } 
  } , 2000)
}



canDeleteUser(u: IUserMaintenance) {
  // return !u.inUse;
}

canEditUser(u: IUserMaintenance): boolean {
  if (u.userEnabled == true) {
      this.isEditable = true;
  } else {
      this.isEditable = false;
    }
  return this.isEditable;
}

canEnableUser (u: IUserMaintenance): boolean {
  if (u.userEnabled == true) {
    this.isEnabled = false;
  } else {
    this.isEnabled = true;
    }
  return this.isEnabled;
}
private get isVisible(): boolean {
  return this.userMaintenanceList.nativeElement.offsetParent != null;
}


onSorted(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'firstName')
          {
            this.users.sort(this.sortByFirstNameDesc)
          }
          else if (criteria.sortColumn == 'lastName')
          {
            this.users.sort(this.sortByLastNameDesc)
          }
          else if (criteria.sortColumn == 'employeeID')
          {
            this.users.sort(this.sortByEmpNumDesc)
          }
          else if (criteria.sortColumn == 'defaultWarehouse1')
          {
            this.users.sort(this.sortByWarehouseDesc)
          }
          else if (criteria.sortColumn == 'workstationDescription')
          {
            this.users.sort(this.sortByWorkStationDesc)
          }
        }
        else {
          if(criteria.sortColumn == 'firstName')
          {
            this.users.sort(this.sortByFirstNameAsc)
          }
          else if (criteria.sortColumn == 'lastName')
          {
            this.users.sort(this.sortByLastNameAsc)
          }
          else if (criteria.sortColumn == 'employeeID')
          {
            this.users.sort(this.sortByEmpNumAsc)
          }   
          else if (criteria.sortColumn == 'defaultWarehouse1')
          {
            this.users.sort(this.sortByWarehouseAsc)
          }
          else if (criteria.sortColumn == 'workstationDescription')
          {
            this.users.sort(this.sortByWorkStationAsc)
          }      
        }
      }
      
  } 


// sortTable(n, event) {
//   var elementId: string = (event.target as Element).id;
 

//   if (elementId.substring(0,4) == 'col1' && n == 0)
//   {    
//     if (this.dir == 'asc')
//     {
//       this.dir = 'desc'
//       this.users.sort(this.sortByFirstNameAsc)
//     }
//     else 
//     {
//       this.dir = 'asc'
//       this.users.sort(this.sortByFirstNameDesc)
//     }
    
//   }
//   else if (elementId.substring(0,4) == 'col2' && n == 1)
//   {
//     if (this.dir == 'asc')
//         {
//           this.dir = 'desc'
//           this.users.sort(this.sortByLastNameAsc)
//         }
//         else 
//         {
//           this.dir = 'asc'
//           this.users.sort(this.sortByLastNameDesc)
//         }
//   }

//   else if (elementId.substring(0,4) == 'col3' && n == 2)
//   {
//     if (this.dir == 'asc')
//         {
//           this.dir = 'desc'
//           this.users.sort(this.sortByEmpNumAsc)
//         }
//         else 
//         {
//           this.dir = 'asc'
//           this.users.sort(this.sortByEmpNumDesc)
//         }
//   }

//   else if (elementId.substring(0,4) == 'col4' && n == 3)
//   {
//     if (this.dir == 'asc')
//         {
//           this.dir = 'desc'
//           this.users.sort(this.sortByWarehouseAsc)
//         }
//         else 
//         {
//           this.dir = 'asc'
//           this.users.sort(this.sortByWarehouseDesc)
//         }
//   }

//   else if (elementId.substring(0,4) == 'col5' && n == 4)
//   {
//     if (this.dir == 'asc')
//         {
//           this.dir = 'desc'
//           this.users.sort(this.sortByWorkStationAsc)
//         }
//         else 
//         {
//           this.dir = 'asc'
//           this.users.sort(this.sortByWorkStationDesc)
//         }
//   }
 
// }


sortByFirstNameAsc(a1: IUserMaintenance, a2: IUserMaintenance) { 
  return Helper.cmpString(a1.firstName, a2.firstName); 
}

sortByFirstNameDesc(a1: IUserMaintenance, a2: IUserMaintenance) { 
  return Helper.cmpStringDesc(a1.firstName, a2.firstName); 
}

sortByLastNameAsc(a1: IUserMaintenance, a2: IUserMaintenance) { 
  return Helper.cmpString(a1.lastName, a2.lastName); 
}

sortByLastNameDesc(a1: IUserMaintenance, a2: IUserMaintenance) {
  return Helper.cmpStringDesc(a1.lastName, a2.lastName); 
}

sortByEmpNumAsc(a1: IUserMaintenance, a2: IUserMaintenance) { 
  return Helper.cmpString(a1.employeeID, a2.employeeID); 
}

sortByEmpNumDesc(a1: IUserMaintenance, a2: IUserMaintenance) {
  return Helper.cmpStringDesc(a1.employeeID, a2.employeeID); 
}

sortByWarehouseAsc(a1:IUserMaintenance, a2: IUserMaintenance) { 
  return Helper.cmpString(a1.defaultWarehouse1, a2.defaultWarehouse1); 
}

sortByWarehouseDesc(a1: IUserMaintenance, a2: IUserMaintenance) {
  return Helper.cmpStringDesc(a1.defaultWarehouse1, a2.defaultWarehouse1); 
}

sortByWorkStationAsc(a1: IUserMaintenance, a2: IUserMaintenance) { 
  return Helper.cmpString(a1.workstationDescription, a2.workstationDescription); 
}

sortByWorkStationDesc(a1: IUserMaintenance, a2: IUserMaintenance) {
  return Helper.cmpStringDesc(a1.workstationDescription, a2.workstationDescription); 
}

ngAfterContentChecked() {
    console.log('usermaintenance life cyle.component.AfterContentChecked');  
}



// sortCentersWarehouseDescription(wc1: IWorkCenter, wc2: IWorkCenter) {
//   const ret = Helper.cmpStringAlphaNumeric(wc1.warehouse, wc2.warehouse);
//   if (ret === 0) {
//     return Helper.cmpStringAlphaNumeric(wc1.description, wc2.description);
//   } else {
//     return ret;
//   }
// }

ngAfterViewChecked() {
  console.log('usermaintenance life cyle.component.AfterViewChecked');
    const c1 = $('.col1').width();
    const c2 = $('.col2').width();
    const c3 = $('.col3').width();
    const c4 = $('.col4').width();
   // const c5 = $('.col5').width();

    $('#col1').width(c1);
    $('#col2').width(c2);
    $('#col3').width(c3);
    $('#col4').width(c4);
    // $('#col5').width(c5);

    $('.SearchBar').width(c1 + c2 + c3 + c4 + 35);
    // console.log(c2);

    let th = $('thead').width();
    $('#Cover').width(th);
}

ngOnDestroy() {
    console.log('user-maintenance.component.OnDestroy');
}

  // addWorkCenter() {

  //   this.openOverlay(this.data.newWorkCenterTemplate);
  // }

  goToDetails(user: IUserMaintenance = null) {

    this.data.selectedUser = user;
    const str = (user && user.userId ? user.userId : 'new');
    this.router.navigate(['userMaintenance', str]);
  }

  // copyEditWorkCenter(w: IWorkCenter) {
  //   this.centerService.createWorkCenter(w).subscribe(newCenter => {
  //     this.goToDetails(newCenter);
  //   });

  // }

  editUser(u: IUserMaintenance) {
   this.goToDetails(u);
  }

  enableUser(u: IUserMaintenance) {
  this.alert.showYesNo('Are you sure you want to enable user ' + u.firstName + ' ' + u.lastName + '?',
    AlertLevel.WARNING,
    (ar: AlertReponse) => {
      if (ar === AlertReponse.YES) {
        this.userMaintenanceService.enableUserMaintenance(u).subscribe(res => {    
          u.manufacturingUserId = res.manufacturingUserId;      
          this.goToDetails(u);
      });
      }
    });
   }

  // deleteWorkCenter(w: IWorkCenter) {
  //   this.alert.showYesNo('Are you sure you want to delete work center ' + w.warehouse + ' ' + w.description + '?',
  //   AlertLevel.WARNING,
  //   (ar: AlertReponse) => {
  //     console.log(ar);
  //     console.log(ar === AlertReponse.OK);
  //     console.log(AlertReponse.OK);
  //     if (ar === AlertReponse.YES) {
  //       this.centerService.deleteWorkCenter(w.id).subscribe(res => {
  //         this.workCenters = this.workCenters.filter(x => x.id !== w.id);
  //     });
  //     }
  //   });
  // }  

      // createWorkCenter(info: IWorkCenter) {
      //   console.log('work-centers.component.createWorkCenter');
      //   console.log(info);
      //   this.userSubmitted = true;
      //   if (!this.workCenterInfo.isNewWorkCenterValid) {
      //     return;
      //   }

      //   if (!this.utils.isExistingItem(info))  {
      //      console.log('...creating new work center ');
      //      this.centerService.createWorkCenter(info).subscribe(newCenter => {
      //         this.closeOverlay(this, false);
      //         this.goToDetails(newCenter);
      //      });
      //    }
      // }

  cancelUserMaintenance() {
    console.log('cancelCreateWorkCenter');
    this.closeOverlay();
  }




    closeOverlay(_self = this, clearSelectedWorkCenter: boolean = true) {
      _self.userMaintenanceDetails.nativeElement.style.display = 'none';
      _self.userSubmitted = false;
      // if (clearSelectedWorkCenter) {
        // _self.data.selectedWorkCenter = null;
      // }
      //

    }
    openOverlay(wc: IUserMaintenance= null ) {
        // const dataService = this.data;
        // this.data.selectedWorkCenter = wc;
        document.getElementById('UserMaintenanceDetailsOverlay').style.display = 'block';
        const modal = document.getElementById('WorkCenterDetailsOverlay');
        const _self = this;
        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function(event) {
            if (event.target === modal) {
                _self.closeOverlay(_self);
            }
        };
      }

      ngAfterViewInit() {

        console.log('usermaintenance life cyle.component.AfterViewInit');
        // runs every time the user re-sizes the window
            $(window).resize(function(){
          // gets td column widths
          const c1 = $('.col1').width();
          const c2 = $('.col2').width();
          const c3 = $('.col3').width();
          const c4 = $('.col4').width();
          //const c5 = $('.col5').width();
          // sets th column widths to match td's
          $('#col1').width(c1);
          $('#col2').width(c2);
          $('#col3').width(c3);
          $('#col4').width(c4);
          //$('#col5').width(c5);

          $('.SearchBar').width(c1 + c2 + c3 + c4 + 34);
          console.log ('resize header occured');

          let th = $('thead').width();
          $('#Cover').width(th);
          });
      }



      ngAfterContentInit() {
        console.log('usermaintenance life cyle.component.AfterContentInit');
        $(document).ready(function(){
          $('.SearchField').focus();
        });
      }
}
