import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterRouterFiles'
})
export class FilterRouterFilesPipe implements PipeTransform {

  transform(routerFiles: any, term: any): any {
    // check if term is undefined
    if (term === undefined || term.length == 0 || routerFiles === null) {
      return routerFiles;
    }
    // return the updated routers array
    return routerFiles.filter(function(rf){
        return (routerFiles.partNumber.toLowerCase().includes(term.toLowerCase())
        || routerFiles.partDescription.toLowerCase().includes(term.toLowerCase()));
    });
  }
}