//var CryptoJS = require("crypto-js");
var utf8 = require('utf8');
//var path = require('path');
import { FileServiceHttp } from '../../services/file.servicehttp';
import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions, ResponseOptions} from '@angular/http';
import {Helper} from '../../services/helper.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/finally';
import 'rxjs/add/observable/throw';
import { Config} from '../gatekeeper/config';
import { GateKeeperService} from '../../shared/gatekeeper/user/user.service';
import { IOnshapeDocument, IOnshapeVersion} from '../onshape/onshapedocuments.model';
import { MethodType, MethodTypes } from '../../enums/methodtype';


@Injectable()

export class OnshapeService {

    constructor(private helper: Helper, private http: Http, private utils: Helper, private fileService: FileServiceHttp, private config:Config, private gkauth: GateKeeperService) {
      console.log('Onshape.service.contructor');
     }

    toh = null;
    fileUrl: Uint8Array = null;
    //process = spawn('python', ["../../../../python/app.py"]);

    handleNoMudaFileUrl(url: string) {
      console.log('Onshape service.handleNoMudaFileUrl url:' + url);
      return url.replace('nomuda', 'nomudafs');
    }

    show(url: string ) {
      // url = this.handleNoMudaFileUrl(url);
      // url = 'https://diwmsi.onshape.com/documents/85af5da33a9546e5b4df7e6b/v/2f9fc2e695952d88ff328480/e/5318a270daeb8894f59c1d13'
      console.log('Onhsape service.show url:' + url);
      const tmp = url;
     

     window.open(tmp);
     //window.open(url,"_blank","toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");     
    }

    //  Generate a unique ID for the request, 25 chars in length
    //  Returns: string: Cryptographic nonce 
    // createNonce(){
    //   let nonce ='';
    //   let possible:string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    //   let length = 25;

    //   for(var i = 0; i < length; i++) {
    //     nonce += possible.charAt(Math.floor(Math.random() * possible.length));
    //   }
    //   //nonce = 'XfpRjY7bllt2HtsW9VvqrZOTe';
    //   console.log(nonce);
    //   return nonce;
    // }
    
    // //   Create the request signature to authenticate
    // //  Returns: string: Cryptographic nonce 
    // createAuth(method: string, date: Date, nonce: string, path: string, query={}, contentType: string)
    // {
    //   let onshapeAuth = '';
    //   let hmac_string = (method + '\n' + nonce + '\n' + date.toUTCString() + '\n' + contentType + '\n' + path + '\n' + query + '\n').toLowerCase()
    //   //let hmac_string_utf8 = utf8.encode(hmac_string);  
    //   let onshapeKey =Config.onshapeSecretKey;
    //   let signature = CryptoJS.HmacSHA256(hmac_string, onshapeKey);    
    //   var signatureBase64 = CryptoJS.enc.Base64.stringify(signature);
    //   onshapeAuth ='On ' + Config.onshapeAccessKey + ':HmacSHA256:' + signatureBase64;
    //   console.log(date.toUTCString());
    //   console.log(nonce);
    //   console.log(onshapeAuth);

    //   return onshapeAuth
    // }     


    get httpOptions()
    {
      
      // var date =  new Date(); 
      // var method = 'GET';
      // var urlPath = '/api/documents';
      // var urlQuery = '?filter=0&ownerType=1&sortColumn=createdAt&sortOrder=desc&offset=0&limit=20';
      // var contentType = 'application/json';       
      // var nonce = this.createNonce();        
      // var auth = this.createAuth(method, date, nonce, urlPath, urlQuery, contentType);  

      // 'Accept' : 'application/vnd.onshape.v1+json'
      //  const headers = new Headers()
      // {'Content-Type' : 'application/json', 
      // 'Access-Control-Allow-Origin' : '*',
      // 'Date': date.toUTCString(),
      // 'Nonce': nonce,
      // 'Authorization' : auth,
      // 'User-Agent' : 'NoMudaClient',
      // 'Accept' : 'application/json'
      // });
      // headers.append('Content-Type', 'application/json');
      // headers.append('Access-Control-Allow-Origin', 'http://diwmsi.onshape.com');
      // headers.append('Date', date.toUTCString());
      // headers.append('Nonce', nonce);
      // headers.append('Authorization', auth);
      // headers.append('User-Agent', 'NoMudaClent');
      // headers.append('Accept', 'application/json')
      // const options = new RequestOptions({headers : headers});

      // return options;

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'POST,GET, DELETE, PUT, OPTIONS, HEAD');
      const options = new RequestOptions({headers : headers});
    
      return options;
    }

  //   getDocuments(): Observable<IOnshapeDocument> {
  //     console.log('onshapeservice.getdocument');
  //     this.showSpinner();
  //     //return this.http.get('https://jsonplaceholder.typicode.com/todos/1',this.httpOptions).map((response : Response) => {
        
  //       return this.gkauth.do_get('https://diwmsi.onshape.com/api/documents?filter=0&ownerType=1&sortColumn=createdAt&sortOrder=desc&offset=0&limit=20',this.httpOptions).map((response : Response) => {
  //         console.log('...map...GetDocuments from Onshape Server');
  //         console.log(response);
  //         const ret =  this.translateToIOnshapeDocuments(response);
  //       return ret;        
  //     })
      
  // }

  getDocument(id: string ): Observable<IOnshapeDocument> {

    console.log('OnshapeService.getPrograms');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'onshape/documents/' + id;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);     

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       console.log(response);
       const ret =  this.translateToIOnshapeDocuments(response);

       return ret;
    });
        }

  getDocuments( ): Observable<IOnshapeDocument[]> {

    console.log('OnshapeService.getPrograms');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'onshape/documents';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);     

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       console.log(response);
       const ret =  this.translateToIOnshapeDocument(response);

       return ret;
    });
        }

  getVersions(documentId :string): Observable<IOnshapeVersion[]> {

    console.log('OnshapeService.getVersions');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'onshape/versions/' + documentId;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);     

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        console.log(response);
        const ret =  this.translateToIOnshapeVersion(response);

        return ret;
    });
  }

//   getDocuments():any {


//     // Use child_process.spawn method from  
//     // child_process module and assign it 
//     // to variable spawn 
//     //var spawn = require("child_process").spawn; 
      
//     // Parameters passed in spawn - 
//     // 1. type_of_script 
//     // 2. list containing Path of the script 
//     //    and arguments for the script  
      
//     // E.g : http://localhost:3000/name?firstname=Mike&lastname=Will 
//     // so, first name = Mike and last name = Will 
//     //var process = spawn('python',["./hello.py", req.query.firstname, 
//     //                        req.query.lastname] ); 
  
//     // Takes stdout data from script which executed 
//     // with arguments and send this data to res object 
//     //process.stdout.on('data', function(data) { 
//     //    res.send(data.toString()); 
//     //} ) 
      
   
//       // Takes stdout data from script which executed 
//       // with arguments and send this data to res object 

//       // let process = spawn("python", ["'../../../../../python/app.py'"]);

//       // return new Promise((resolve, reject) => {
//       //   process.stdout.on("data", data =>{
//       //     resolve(data.toString()); // <------------ by default converts to utf-8
//       // })
//       // process.stderr.on("data", reject)
// //       })

//       //var process = spawn('python', path.join('python','app.py'));
//       //var pythonPath = path.join('python','app.py');
//             // process.stdout.on('data', function(data: { toString: () => void; }) { 
//             // //res.send(data.toString());  
//             // console.log(data.toString()); 
//           // return data
//       //} ) 

//   }

  translateToIOnshapeDocuments(data: any): IOnshapeDocument {
    if (data) {
      return <IOnshapeDocument> data;
    } else {
      return null;
    }
  }

  translateToIOnshapeDocument(data: any): IOnshapeDocument[] {
    const ret: IOnshapeDocument[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIOnshapeDocuments(d));
      }
    }
    return ret;
  }

  translateToIOnshapeVersions(data: any): IOnshapeVersion {
    if (data) {
      return <IOnshapeVersion> data;
    } else {
      return null;
    }
  }

  translateToIOnshapeVersion(data: any): IOnshapeVersion[] {
    const ret: IOnshapeVersion[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIOnshapeVersions(d));
      }
    }
    return ret;
  }

    
    showSpinner() {
      if (this.toh != null) {
        return;
      }
      this.toh = setTimeout(() => {
        Helper.showSpinner = true;
      }, 1000);
    }

    hideSpinner() {
      clearTimeout(this.toh);
      Helper.showSpinner = false;
      this.toh = null;
    }

    finallyHandler() {
      // console.log('app-http-client.service.finallyHandler');
      this.hideSpinner();
    }


    getResponseMessage(error:Response) {
      console.log('user.service.getResponseMessage');
      console.log(error);

      let ret = 'Unable to connect'; //cannot communicate with Gatekeeper
      try {
          if (error && error.status > 0) {
            ret = error.json().resultMessage; //Gatekeeper or web api error message?
          }
      } catch (e) {
          ret = 'Unknown error'; // no json payload, most likely an http error not returned by Gatekeeper
      } finally {
        return ret;
      }
    }

    handleError(error: Response, errorHandler: Function = null) {
      console.log('user.service.handleError: ');
      console.log(error);
      let msg = this.getResponseMessage(error);
       console.log(msg);
     
       const isAuthFailure = error && msg == 'Could Not Authenticate';
       //const doForceLogin = isAuthFailure && this.forceLogin != null && !this.forceLogin.login;
     
     
       if (msg) {
         msg = msg + '. Redirecting to login screen.';
       }
     
       console.log(msg);
       console.log('isAuthFailure:' + isAuthFailure);
     
       return Observable.throw(msg);
     }



    handleResponse(url: string) {
      this.fileUrl = null;
    }
  }

