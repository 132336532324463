import { WorkCenterInfoComponent } from './work-center-detail/work-center-info.component';
import { Helper } from '../core/services/helper.service';
import { Component, OnChanges, OnInit, DoCheck, AfterContentChecked, AfterViewChecked, AfterViewInit, OnDestroy, AfterContentInit, 
 ViewChild, ElementRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { WorkCenterService } from '../core/services/workcenter.service';
import { WorkCenterDataService } from '../core/services/workcenter-data.service';
import { IWorkCenter, WorkCenterUtils } from '../core/models/workcenter.model';
import { FilterWorkCenterPipe } from '../core/pipes/filter-workcenter.pipe';
import { AlertService } from '../core/services/alert.service';
import { AlertLevel, AlertReponse } from '../core/models/alert.model';
import { SortCriteria} from '../core/models/sort.model';

@Component({
  selector: 'work-centers',
  template: require('./work-centers.component.html'),
  // styles: [require('./work-centers.component.css')]
  styles: [require('./work-centers.component.css').toString()]
})
export class WorkCentersComponent implements OnInit, AfterContentChecked, AfterViewChecked, AfterViewInit, OnDestroy, AfterContentInit {

  workCenters: IWorkCenter[];
  @ViewChild('workCenterList' , {static: true}) workCenterList: ElementRef;
  @ViewChild('WorkCenterOverlay' , {static: true}) workCenterDetails: ElementRef;
  @ViewChild('table' , {static: true}) table: ElementRef;
  @ViewChild('workCenterInfo' , {static: true}) workCenterInfo: WorkCenterInfoComponent;
  viewWasVisible = false;
  public term: string;
  dir = 'asc';

  get userSubmitted() {
    if (this.workCenterInfo != null) {
      return this.workCenterInfo.userSubmitted;
    }  else {
      return false;
    }
  }
  set userSubmitted(val: boolean) {
    if (this.workCenterInfo != null) {
      this.workCenterInfo.userSubmitted = val;
    }
  }

  constructor(private route: ActivatedRoute
  , private router: Router
  , private centerService: WorkCenterService
  , private data: WorkCenterDataService
  , private alert: AlertService,
    private utils: Helper  ) { }


ngOnInit() {
  console.log('work-centers.component.ngOnInit');
}

canDeleteWorkCenter(w: IWorkCenter) {
  return !w.inUse;
}
private get isVisible(): boolean {
  return this.workCenterList.nativeElement.offsetParent != null;
}

onSorted(criteria: SortCriteria)
{    
    var elementId: string = (event.target as Element).id;
    if(elementId.substring(0,3) == 'col')
    {
      if(criteria.sortDirection === 'desc'){          
        if(criteria.sortColumn == 'warehouse')
        {
          this.workCenters.sort(this.sortByWarehouseDescNameCode);        
        }
        else if (criteria.sortColumn == 'description')
        {
          this.workCenters.sort(this.sortByDescriptionDesc)
        }   
        else if (criteria.sortColumn == 'type')
        {
          this.workCenters.sort(this.sortByTypeDescNameCode)
        }   
        else if (criteria.sortColumn == 'name')
        {
          this.workCenters.sort(this.sortByNameDescCode)
        } 
        else if (criteria.sortColumn == 'scheduleType')
        {
          this.workCenters.sort(this.sortByScheduleTypeDescNameCode)
        }
        else if (criteria.sortColumn == 'code')
        {
          this.workCenters.sort(this.sortByCodeDesc)
        }         
      }
      else {
        if(criteria.sortColumn == 'warehouse')
        {
          this.workCenters.sort(this.sortByWarehouseNameCode);
        }
        else if (criteria.sortColumn == 'description')
        {
          this.workCenters.sort(this.sortByDescription)
        }   
        else if (criteria.sortColumn == 'type')
        {
          this.workCenters.sort(this.sortByTypeNameCode)
        }   
        else if (criteria.sortColumn == 'name')
        {
          this.workCenters.sort(this.sortByNameCode)
        }  
        else if (criteria.sortColumn == 'scheduleType')
        {
          this.workCenters.sort(this.sortByScheduleTypeNameCode)
        }
        else if (criteria.sortColumn == 'code')
        {
          this.workCenters.sort(this.sortByCode)
        }             
      }
    }
} 

sortByWarehouseNameCode(a1: IWorkCenter, a2: IWorkCenter) { 
  let cmp = Helper.cmpString(a1.warehouse, a2.warehouse);
  if (cmp == 0)
    cmp = Helper.cmpStringAlphaNumeric(a1.name, a2.name,true);
    if (cmp == 0)
      cmp = Helper.cmpNumber(a1.code, a2.code) 

  return cmp;     
}

sortByWarehouseDescNameCode(a1: IWorkCenter, a2: IWorkCenter) { 
  let cmp = Helper.cmpString(a2.warehouse, a1.warehouse);
  if (cmp == 0)
    cmp = Helper.cmpStringAlphaNumeric(a1.name, a2.name,true);
    if (cmp == 0)
      cmp = Helper.cmpNumber(a1.code, a2.code) 

  return cmp;     
}

sortByDescription(a1: IWorkCenter, a2: IWorkCenter) { 
  return Helper.cmpString(a1.description, a2.description); 
}

sortByDescriptionDesc(a1: IWorkCenter, a2: IWorkCenter) {
  return Helper.cmpString(a2.description, a1.description); 
}

sortByTypeNameCode(a1: IWorkCenter, a2: IWorkCenter) { 
  let cmp = Helper.cmpString(a1.type, a2.type); 
  if (cmp == 0)
    cmp = Helper.cmpStringAlphaNumeric(a1.name, a2.name,true);
    if (cmp == 0)
      cmp = Helper.cmpNumber(a1.code, a2.code);

  return cmp    
}

sortByTypeDescNameCode(a1: IWorkCenter, a2: IWorkCenter) { 
  let cmp = Helper.cmpString(a2.type, a1.type); 
  if (cmp == 0)
    cmp = Helper.cmpStringAlphaNumeric(a1.name, a2.name, true);
    if (cmp == 0)
      cmp = Helper.cmpNumber(a1.code, a2.code);

  return cmp    
}

sortByNameCode(a1:IWorkCenter, a2: IWorkCenter) { 
  let cmp = Helper.cmpStringAlphaNumeric(a1.name, a2.name,true); 
  if (cmp == 0)
      cmp = Helper.cmpNumber(a1.code, a2.code);

  return cmp  
}

sortByNameDescCode(a1:IWorkCenter, a2: IWorkCenter) { 
  let cmp = Helper.cmpStringAlphaNumeric(a2.name, a1.name,true); 
  if (cmp == 0)
      cmp = Helper.cmpNumber(a1.code, a2.code);

  return cmp  
}

sortByScheduleTypeNameCode(a1: IWorkCenter, a2: IWorkCenter) { 
  let cmp = Helper.cmpString(a1.scheduleType, a2.scheduleType); 
  if (cmp == 0)
    cmp = Helper.cmpStringAlphaNumeric(a1.name, a2.name,true);
    if (cmp == 0)
      cmp = Helper.cmpNumber(a1.code, a2.code);

  return cmp    
}

sortByScheduleTypeDescNameCode(a1: IWorkCenter, a2: IWorkCenter) { 
  let cmp = Helper.cmpString(a2.scheduleType, a1.scheduleType); 
  if (cmp == 0)
    cmp = Helper.cmpStringAlphaNumeric(a1.name, a2.name, true);
    if (cmp == 0)
      cmp = Helper.cmpNumber(a1.code, a2.code);

  return cmp    
}

sortByCode(a1:IWorkCenter, a2:IWorkCenter) {
  return Helper.cmpNumber(a1.code, a2.code);
}

sortByCodeDesc(a1:IWorkCenter, a2:IWorkCenter) {
  return Helper.cmpNumber(a2.code, a1.code);
}

ngAfterContentChecked() {
   // console.log('work-centers.component.AfterContentChecked');

   // console.log('...isVisible:' + (this.isVisible ? 'true' : 'false') + ' viewWasVisible:' + (this.viewWasVisible ? 'true' : 'false'));
    if (!this.isVisible && this.viewWasVisible) {
      console.log('work-centers.component.AfterContentChecked...from visible to hidden');
      this.viewWasVisible = false;
    } else if (this.isVisible && !this.viewWasVisible) {
      console.log('work-centers.component.AfterContentChecked...from hidden to visible');
      this.viewWasVisible = true;
      this.userSubmitted = false;
      this.centerService.getWorkCenters().subscribe(centers => {
        this.workCenters  = centers;
        this.workCenters.sort(this.sortCentersWarehouse_HasName_Name);
        this.data.workCenters = this.workCenters;        
      });
    }
}

sortCentersWarehouseDescription(wc1: IWorkCenter, wc2: IWorkCenter) {
  const ret = Helper.cmpString(wc1.warehouse, wc2.warehouse);
  if (ret === 0) {
    return Helper.cmpString(wc1.description, wc2.description);
  } else {
    return ret;
  }
}

sortCentersWarehouse_HasName_Name(wc1: IWorkCenter, wc2: IWorkCenter) {
  let ret = Helper.cmpString(wc1.warehouse, wc2.warehouse);
  if (ret === 0) {
     var hasName1:number = wc1.name? 1 : 2;
     var hasName2:number = wc2.name? 1: 2;

     ret = Helper.cmpNumber(hasName1, hasName2);
     if (ret == 0)
         ret = Helper.cmpStringAlphaNumeric(wc1.name, wc2.name);
  } 
   
  return ret;
 }



sortCentersWarehouseDescriptionDesc(wc1: IWorkCenter, wc2: IWorkCenter) {
  const ret = Helper.cmpStringDesc(wc1.warehouse, wc2.warehouse);
  if (ret === 0) {
    return Helper.cmpStringDesc(wc1.description, wc2.description);
  } else {
    return ret;
  }
}

ngAfterViewChecked() {
   // console.log('work-centers.component.AfterViewChecked');
    const c1 = $('.col1').width();
    const c2 = $('.col2').width();
    const c3 = $('.col3').width();
    const c4 = $('.col4').width();
    const c5 = $('.col5').width();
    const c6 = $('.col6').width();

    $('#col1').width(c1);
    $('#col2').width(c2);
    $('#col3').width(c3);
    $('#col4').width(c4);
    $('#col5').width(c5);
    $('#col6').width(c6);

    $('.SearchBar').width(c1 + c2 + c3 + c4 + c5 + + c6 + 40);
    // console.log(c2);

    let th = $('thead').width();
    $('#Cover').width(th);
}

ngOnDestroy() {
    console.log('work-centers.component.OnDestroy');
}
  addWorkCenter() {

    //this.openOverlay(this.data.newWorkCenterTemplate);
    this.goToDetails(this.data.newWorkCenterTemplate)
  }
 

  goToDetails(wc: IWorkCenter = null) {
    this.data.selectedWorkCenter = wc;
    const str = (wc && wc.id ? wc.id : 'new');
    this.router.navigate(['workCenters', str]);
  }

  copyEditWorkCenter(wc: IWorkCenter) {
    this.data.selectedWorkCenter = wc;
    this.data.doCopy = true;
    this.router.navigate(['workCenters', 'new']);
  }

  editWorkCenter(w: IWorkCenter) {
   this.goToDetails(w);
  }

  deleteWorkCenter(w: IWorkCenter) {
    this.alert.showYesNo('Are you sure you want to delete work center ' + w.warehouse + ' ' + w.description + '?',
    AlertLevel.WARNING,
    (ar: AlertReponse) => {
      console.log(ar);
      console.log(ar === AlertReponse.OK);
      console.log(AlertReponse.OK);
      if (ar === AlertReponse.YES) {
        this.centerService.deleteWorkCenter(w.id).subscribe(res => {
          this.workCenters = this.workCenters.filter(x => x.id !== w.id);
      });
      }
    });
  }
  ngAfterViewInit() {
        // console.log('WorkCenters.Component.AfterViewInit');
        // runs every time the user re-sizes the window
            $(window).resize(function(){
          // gets td column widths
          const c1 = $('.col1').width();
          const c2 = $('.col2').width();
          const c3 = $('.col3').width();
          const c4 = $('.col4').width();
          const c5 = $('.col5').width();
          const c6 = $('.col6').width();       
          // sets th column widths to match td's
          $('#col1').width(c1);
          $('#col2').width(c2);
          $('#col3').width(c3);
          $('#col4').width(c4);
          $('#col5').width(c5);
          $('#col6').width(c6);

          $('.SearchBar').width(c1 + c2 + c3 + c4 + c5 + c6 + 40);
          console.log ('resize header occured');

          let th = $('thead').width();
          $('#Cover').width(th);
          });
      }

      //work-center-info validated the work center properties      
      createWorkCenter(info: IWorkCenter) {
        console.log('work-centers.component.createWorkCenter');
        console.log(info);
        this.userSubmitted = true;
        if (!this.utils.isExistingItem(info))  {
           console.log('...creating new work center ');
           this.centerService.createWorkCenter(info).subscribe(newCenter => {
              //this.workCenters.sort(this.sortCentersWarehouseDescription);
              this.closeOverlay(this, false);
              this.goToDetails(newCenter);
           });
         }        
      }

      cancelCreateWorkCenter() {
        console.log('cancelCreateWorkCenter');

        this.closeOverlay();
        
        this.workCenters.sort(this.sortCentersWarehouseDescription);  
           
      }


              closeOverlay(_self = this, clearSelectedWorkCenter: boolean = true) {
                this.workCenters.sort(this.sortCentersWarehouseDescription);               
                _self.workCenterDetails.nativeElement.style.display = 'none';
                _self.userSubmitted = false;
                if (clearSelectedWorkCenter) {
                  _self.data.selectedWorkCenter = null;
                }
                //

              }
              openOverlay(wc: IWorkCenter= null ) {
                  const dataService = this.data;
                  this.data.selectedWorkCenter = wc;
                  document.getElementById('WorkCenterDetailsOverlay').style.display = 'block';
                  const modal = document.getElementById('WorkCenterDetailsOverlay');
                  const _self = this;
                  // When the user clicks anywhere outside of the modal, close it
                  window.onclick = function(event) {
                      if (event.target === modal) {
                         _self.closeOverlay(_self);
                      }
                  };
                }
  ngAfterContentInit() {
    $(document).ready(function(){
      $('.SearchField').focus();
    });
  }
}
