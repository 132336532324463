import { FileServiceHttp } from './file.servicehttp';
import { Injectable } from '@angular/core';
import {Helper} from './helper.service';

@Injectable()

export class PDFService {

    constructor(private helper: Helper, private fileService: FileServiceHttp) {
      console.log('pdf.service.contructor');
     }

    fileUrl: Uint8Array = null;

    handleNoMudaFileUrl(url: string) {
      //console.log('pdf-service.handleNoMudaFileUrl url:' + url.toString());
      //console.log(typeof url)
      return url.toString().replace('nomuda', 'nomuda');
    }

    show(url: string ) {
      console.log('pdf-service.show url:' + url);
      url = this.handleNoMudaFileUrl(url);
      var tmp ="";
     

      if(url.startsWith("https",0))
      {
        tmp = url;
      }
      else
      {
        tmp = 'http:' + url;
      }
      
     //const tmp = '\cube\nomuda\files\1506906R00_-.pdf '

     window.open(tmp);
     // console.log('pdf-service.show fileUrl:' + this.fileUrl);
     //  this.fileUrl = 'http://mshop-eng1/approved/pdfs/msipump/9/9c0148.pdf'
     // window.open('http://mshop-eng1/approved/pdfs/msipump/9/9c0148.pdf');
     // window.open('http://intracube/eng/engmanual/specs/9-0001.pdf');
     //  window.open('http://itdevserver/nomuda/files/9-0001_g.pdf');
    }


    handleResponse(url: string) {
      this.fileUrl = null;
    }
  }

