import { Component, AfterViewInit, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { AlertService } from '../core/services/alert.service';
import { Alert, AlertReponse, AlertType } from '../core/models/alert.model';

@Component({
  selector: 'alert-action',
  template: require('./alert-action.component.html'),
  // styles: [require('./alert-action.component.css')]
  styles: [require('./alert-action.component.css').toString()]
})

export class AlertActionComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() alert: Alert;
  // @Output() alertResponse = new EventEmitter;

  get showOk(){
    return this.alert != null ? this.alert.type === AlertType.OK : false;
  }

  get showNo(){
    return this.alert != null ? this.alert.type === AlertType.YESNO : false;
  }

  get showYes() {
    return this.alert != null ? this.alert.type === AlertType.YESNO : false;
  }

  handleOkResponse() {
    this.alertService.handleAlertResponse(this.alert, AlertReponse.OK);
  }

  handleYesResponse() {
    this.alertService.handleAlertResponse(this.alert, AlertReponse.YES);
  }

  handleNoResponse() {
    this.alertService.handleAlertResponse(this.alert, AlertReponse.NO);
  }

  constructor(private alertService: AlertService) {
   console.log('Constructor fired');
  }

  ngOnChanges() {
    console.log('OnChange Fired');
    // this.alert = this.alertService.getAlert(this.alertId);
  }
  ngOnInit() {
    console.log('onInit Fired');

  }
  ngAfterViewInit() {
    console.log('afterViewInit Fired');
  }

  actionError(confirm: boolean) {
   // this.alertResponse.emit(confirm);
    // document.getElementById('ErrorAction').style.display = 'none';
  }

}
