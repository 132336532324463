

export enum MessageType {
    INFO = 0,
    WARNING = 1,
    ERROR= 2
}



export class Message {
 constructor(public type: MessageType, public text: string) {}
}
