import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { IRouterStep, IRouterStepNotes, RouterStepUtils, StepOrder, IRouterStepNDE } from '../models/routerstep.model';
import { Helper } from './helper.service';
import { RouterServiceHttp } from './router.servicehttp';
import { Http, Response } from '@angular/http';
// import { AuthService } from '../../core/services/auth.service';
import { GateKeeperService} from '../shared/gatekeeper/user/user.service';
import { Config } from '../shared/gatekeeper/config';
import { MethodType, MethodTypes } from '../enums/methodtype';
import 'rxjs/add/observable/throw';


@Injectable()
export class RouterStepServiceHttp {

  constructor(private helper: Helper,
              private routerService: RouterServiceHttp,
              private http: Http,   
              // private ahttp: AppHttpClientService,
              private gkauth: GateKeeperService,
              private Config: Config) { }

  createRouterStep(s: IRouterStep): Observable<IRouterStep> {
    console.log('routerstep.service.createRouterStep');
    const info = RouterStepUtils.toServerInfo(s);
    info.step = -1; // let the server set the step number to max step + 1
    info.userId = this.gkauth.currentUser.UserID;

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routerOperations/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
  

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       //console.log(response);
       const step =  this.translateToIRouterStep(response);
       //console.log(step);
       return step;
    });
  }

  getRouterSteps(routerId: string): Observable<IRouterStep[]> {
    console.log('routerstep.service.getRouterSteps');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routerOperations/GetOperationsForRouter/' + routerId ;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    Config.gkParameters.JsonParameters = '';

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        const step =  this.translateToIRouterSteps(response);
        return step;
    });
  }

  getRouterStep(id: string): Observable<IRouterStep> {
    console.log('routerstep.service.getRouterStep');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routerOperations/' + id ;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    Config.gkParameters.JsonParameters = '';

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        const step =  this.translateToIRouterStep(response);
        return step;
    });
  }

  getRouterStepNotes(id: string): Observable<IRouterStepNotes> {
    console.log('routerstep.service.getRouterStepNotes');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routerOperations/GetRouterOperationNotes/' + id;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    Config.gkParameters.JsonParameters = '';

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        const notes =  this.translateToIRouterStepNotes(response);
        return notes;
    });
  }

  getFamilyIds(): Observable<string[]> {
    console.log('routerstep.service.getFamilyIds');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routerOperations/FamilyIds';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    Config.gkParameters.JsonParameters = '';

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: string[]) => {
        //console.log('...returns response:');
        //console.log(response);
        return response;
    });
  }

  GetRouterOperationCanSetDoFgLotByRouterOperationID(routerOperationID: string): Observable<boolean>{
    console.log('routerstep.service.GetRouterOperationCanSetDoFgLotByRouterOperationID');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'RouterOperations/CanSetDoFgLot/' + routerOperationID;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    Config.gkParameters.JsonParameters = '';

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const canSetDoFgLot = Boolean(response);
      return canSetDoFgLot;
    });
  }

  getNDE(id: string): Observable<IRouterStepNDE[]> {
    console.log('routerstep.service.getNDE');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routerOperations/GetRouterOperationNDE/' + id;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    Config.gkParameters.JsonParameters = '';

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const nde = this.translateToIRouterStepNDEs(response);
      return nde;
    });
  }

  updateRouterStepNotes(notes: IRouterStepNotes): Observable<number> {
    console.log('routerstep.service.updateRouterStepNotes');
    const info =  {
                    id: notes.id,
                    setupNotes: notes.setupNotes,
                    userNotes: notes.userNotes,
                    userId: this.gkauth.currentUser.UserID
                  };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routerOperations/UpdateRouterOperationNotes';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        return Number(response);
    });
  }

  updateRouterStepNotes1(notes: IRouterStepNotes): Observable<boolean> {
    console.log('routerstep.service.updateRouterStepNotes');
    const info =  {
                    id: notes.id,
                    setupNotes: notes.setupNotes,
                    userNotes: notes.userNotes,
                    userId: this.gkauth.currentUser.UserID
                  };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routerOperations/UpdateRouterOperationNotes';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        console.log('routerstep.service.updateRouterStepNotes returns');
      return true;
    });
  }

  updateRouterStep(s: IRouterStep): Observable<IRouterStep> {
    console.log('routerstep.service.updateRouterStep');
    const info = RouterStepUtils.toServerInfo(s);
    info.userId = this.gkauth.currentUser.UserID;

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routerOperations/UpdateRouterOperation';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        const step =  this.translateToIRouterStep(response);
        return step;
    });
  }

  updateRouterStep1(s: IRouterStep): Observable<boolean> {
    console.log('routerstep.service.updateRouterStep11');
    const info = RouterStepUtils.toServerInfo(s);
    info.userId = this.gkauth.currentUser.UserID;

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routerOperations/UpdateRouterOperation';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       const step =  this.translateToIRouterStep(response);
       return true;
    });
  }

  updateOrder(stepOrder: StepOrder): Observable<number> {
    console.log('routerstep.service.updateOrder');
    // console.log(stepOrder);

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routerOperations/UpdateOrder';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(stepOrder);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        return Number(response);
    });
  }

  deleteRouterStep(id: string): Observable<number> {
    console.log('routerstep.service.deleteRouterStep');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST +
      'routerOperations/' + id + '/' + this.gkauth.currentUser.UserID ;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.DELETE);
    Config.gkParameters.JsonParameters = '';
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       //console.log(response);
       return Number(response);
    });
  }

  private handleError(error: Response) {
    return Observable.throw(error.statusText);
  }

  translateToIRouterStep(data: any): IRouterStep {
    const ret: IRouterStep = null;
    if (data) {
      return RouterStepUtils.toIRouterStep(data);
    } else {
      return null;
    }
  }

  translateToIRouterStepNotes(data: any): IRouterStepNotes {
    if (data) {
      return {
        id: data.id,
        setupNotes: data.setupNotes,
        userNotes: data.userNotes
      };
    } else {
      return null;
    }
  }

  translateToIRouterStepNDE(data: any): IRouterStepNDE {
    if (data) {
      return {
        ndeName: data.ndeName,
        parameter : data.parameter,
        value : data.value,
        specificationName : data.specificationName,
        specificationFileName : data.specificationFileName,
        specificationRevision : data.specificationRevision,
        specificationPath : data.specificationPath
      };
    } else {
      return null;
    }
  }

  translateToIRouterStepNDEs(data): IRouterStepNDE[] {
    const ret: IRouterStepNDE[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIRouterStepNDE(d));
      }
    }
    return ret;
  }

  translateToIRouterSteps(data): IRouterStep[] {
    const ret: IRouterStep[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIRouterStep(d));
      }
    }
    return ret;
  }
}
