import { AppHttpClientService } from './app-http-client.service';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { IFixture } from '../models/fixture.model';
import { Http, Response } from '@angular/http';
import { Helper } from './helper.service';
import { GateKeeperService} from '../shared/gatekeeper/user/user.service';
import { MethodType, MethodTypes } from '../enums/methodtype';
import { Config } from '../shared/gatekeeper/config';
import 'rxjs/add/observable/throw';


@Injectable()
export class FixtureServiceHttp {

  constructor(private http: Http,
    private helper: Helper,
    // private auth: AuthService,
    private ahttp: AppHttpClientService ,
    private gkauth: GateKeeperService) { }

  createFixture(info: IFixture): Observable<IFixture> {
    console.log('FixtureServiceHttp.createFixture');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'fixtures/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       //console.log(response);
       const ret =  this.translateToIFixture(response);
       //console.log(ret);
       return ret;
    });

   }


    getFixture(id: string ): Observable<IFixture> {
      console.log('FixtureServiceHttp.getFixture');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'fixtures/' + id;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         //console.log(response);
         const ret =  this.translateToIFixture(response);
         //console.log(ret);
         return ret;
      });
    }

    getFixtures( ): Observable<IFixture[]> {

      console.log('FixtureServiceHttp.getFixtures');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'fixtures/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);        

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         const ret =  this.translateToIFixtures(response);
         return ret;
      });
          }

    addFixtureToRouterStep(stepId: string, fixture: IFixture): Observable<IFixture> {
      console.log('FixtureServiceHttp.addFixtureToRouterStep');

      const info = {
        stepId: stepId,
        fixtureId: fixture.id,
        userId: this.gkauth.currentUser.UserID
      };

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'fixtures/AddFixtureToRouterStep/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);
     
      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         //console.log(response);
         const ret = Number(response);
         //console.log(ret);
         return fixture;
      });
    }

    removeFixtureFromRouterStep(stepId: string, fixtureId: string): Observable<number> {
      console.log('FixtureServiceHttp.removeFixtureFromRouterStep');
      const info = {
        stepId: stepId,
        fixtureId: fixtureId,
        userId: this.gkauth.currentUser.UserID
      };

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'fixtures/RemoveFixtureFromRouterStep/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);
      
      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        // console.log(response);
         const ret = Number(response);
         //console.log(ret);
         return ret;
      });
    }



    getFixturesForRouterStep(routerStepId: string): Observable<IFixture[]> {

      console.log('FixtureServiceHttp.getFixturesForRouterStep');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'fixtures/GetFixturesForRouterStep/' + routerStepId;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         //console.log(response);
         const ret =  this.translateToIFixtures(response);
         //console.log(ret);
         return ret;
      });

    }

    updateFixture(info: IFixture ): Observable<IFixture> {
      console.log('FixtureServiceHttp.updateFixture');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'fixtures/UpdateFixture/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);
      
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         //console.log(response);
         const ret =  this.translateToIFixture(response);
         //console.log(ret);
         return ret;
      });
          }

    deleteFixture(id: string): Observable<number> {
      console.log('FixtureServiceHttp.deleteFixture');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'fixtures/' + id;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.DELETE);
      Config.gkParameters.JsonParameters = '';

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         //console.log(response);
        return Number(response);
      });
    }


  private handleError(error: Response) {
    return Observable.throw(error.statusText);
  }

  translateToIFixture(data: any): IFixture {
    if (data) {
      return <IFixture> data;
    } else {
      return null;
    }
  }

//   // New way with actual apiResult standard for api calls implemented by Pico, Michaeal
// translateToIFixtures(data): IFixture[] {
//   const ret: IFixture[] = [];
//    for (const key in data) {
//     if (key == 'result') {
//       for ( let i = 0; i < data.result.length; i++) {
//         ret.push(this.translateToIFixture(data.result[i]));
//       }
//     }
//   }
//   return ret;
// }


  translateToIFixtures(data): IFixture[] {
    const ret: IFixture[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIFixture(d));
      }
    }
    return ret;
  }

 }






