import { IRouterFile, MapType } from '../../core/models/file.model';
import { Helper } from '../../core/services/helper.service';
import { IRouter } from '../../core/models/router.model';
import { RouterDomainService } from '../../core/services/router-domain.service';
import { RouterState } from '../../core/enums/router-state';


export class RouterFilesComponentBizLogic {
  private utils: Helper = new Helper();

  constructor(private router: IRouter, private domain:RouterDomainService) {
    // console.log('RouterFilesComponentBizLogic');
    // console.log(router);
  }

  public get canAddNewFiles(): boolean {
    return this.domain.isEditable;
  }

  canDeleteFile(rf: IRouterFile): boolean {
    return (this.domain.isEditable &&
              (rf && rf.mapType === MapType.ROUTER || rf.mapType === MapType.STEP));
  }

}
