import { Helper } from '../../core/services/helper.service';
import { IRouter } from '../../core/models/router.model';
import { RouterDomainService } from '../../core/services/router-domain.service';
import { RouterState } from '../../core/enums/router-state';


export class RouterStepsComponentBizLogic {
  private utils: Helper = new Helper();

  constructor(private router: IRouter, private domain:RouterDomainService) {
    //console.log('RouterStepsComponentBizLogic');
    //console.log(router);
  }


  public get canEditStep(): boolean {
    return this.domain.isEditable;
  }

  public get canViewStep():boolean{
    return !this.domain.isEditable;
  }


  public get canAddNewSteps(): boolean {
    return this.domain.isEditable;
  }

  public get canDeleteStep(): boolean {
    return this.domain.isEditable;
  }

}
