
import {Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ToolKitDetailComponent } from './tool-kit-detail.component';
import { ToolServiceHttp } from '../../../core/services/tool.servicehttp';
import { of as observableOf} from 'rxjs/observable/of'

@Injectable()
export class CanDeactivateToolKitDetail implements CanDeactivate<ToolKitDetailComponent> {
 constructor(private toolService: ToolServiceHttp) {}

 canDeactivate(
   component: ToolKitDetailComponent,
   currentRoute: ActivatedRouteSnapshot,
   currentState: RouterStateSnapshot,
   nextState: RouterStateSnapshot
 ): Observable<boolean> {
  console.log('tool-kit-detail.candeactivate.canDeactivate');
   if (component && component.hasChanges) {
     console.log('tool-kit-detail.candeactivate step ' + component.newToolkit.description);
      return this.toolService.updateWorkCenterToolKit1(component.newToolkit);
   } else {
     return observableOf(true);
   }
 }
}
