import { Injectable } from '@angular/core';
import { Helper } from './helper.service';
import { IWorkCenterToolKit } from '../models/tool.model';




@Injectable()
export class WorkCenterToolKitDomainService {
  selectedWorkCeterToolKit: IWorkCenterToolKit = null;
  workCenterToolKits: IWorkCenterToolKit[] = null;

  constructor(private helper: Helper) {
    console.log('workCenterToolKit-domain.service.constructor');
}



}







