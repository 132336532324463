import { Injectable, EventEmitter } from '@angular/core';
import { IRouter } from '../models/router.model';
import { RouterState } from '../enums/router-state';
import { Helper } from './helper.service';
import { ValidationResponse} from '../shared/validation-response';
import { Message, MessageType } from '../enums/message';
import { RouterDomainService } from './router-domain.service';

@Injectable()
export class RouterValidationService {

public routers: IRouter[] = null;


constructor(private utils: Helper ) {}


  public canCopy(router: IRouter): ValidationResponse {
     return new ValidationResponse(true, false, null);
     }

  public canDelete(router: IRouter): ValidationResponse {
     const show = (router.state === RouterState.PENDING);
     const disabled = false;
     const msg = null;

     return new ValidationResponse(show, disabled, msg);
  }

  public canSearch(term:String):ValidationResponse {
    const show = true;
    const disabled = term == null || term.length == 0;
    const msg = null;
    return new ValidationResponse(show,disabled,msg);
  }


  public canEdit(router: IRouter): ValidationResponse {
    const show = (router.state === RouterState.PENDING);
    const disabled = false;
    const msg = null;

    return new ValidationResponse(show, disabled, msg);
 }

  public canPublish(router: IRouter): ValidationResponse {
     const show = (router.state === RouterState.PENDING);
     const disabled = false;
     const msg = null;

     return new ValidationResponse(show, disabled, msg);
  }

  public canUpdateHolds(router:IRouter): ValidationResponse {
    let show = false;
     let disabled = false;
     let msg = null;

    switch (router.state) {
      case RouterState.INACTIVE:
          show = true; 
      case RouterState.PENDING:
          show = true;                
      case RouterState.ACTIVE:
          show = true;           
      case RouterState.HOLD_NEW_LOCKED:
          show = false;           
      case RouterState.HOLD_ALL_LOCKED:
          show = false;         
      case RouterState.HOLD_NEW_WIP:
          show = false;           
      case RouterState.HOLD_ALL_WIP:
          show = false;           
      default:        
        break;
    }

    return new ValidationResponse(show, disabled, msg);
  }


  public canUpdate(router: IRouter): ValidationResponse {
    const show = this.utils.isExistingItem(router) && !this.utils.isEmptyString(router.partId);
    const disabled = false;
    const msg = null;

    return new ValidationResponse(show, disabled, msg);
 }


  public canCreateNewVersion(router: IRouter): ValidationResponse {
     const show = (router.state === RouterState.ACTIVE);
     let disabled = false;
     let msg = null;

 //console.log("canCreateNewVersion router " + router.partNumber + router.revision + " show:" + (show?"true":"false"));
     if (show) {
        const match = this.routers.find(x => x.state === RouterState.PENDING && x.partNumber === router.partNumber);
   //     console.log("... hasmatch:" + (match?"true":"false"));
       if (match != null) {
         disabled = true;
         msg = new Message(MessageType.WARNING, 'A PENDING router already exists for this part.');
       }
     }



     return new ValidationResponse(show, disabled, msg);
  }

  public canHoldNewWIP(router:  IRouter): ValidationResponse {
     let show = false;
     const disabled = false;
     const msg = null;

    switch (router.state) {
      case RouterState.INACTIVE:
      case RouterState.PENDING:
      case RouterState.HOLD_NEW_WIP:
      case RouterState.HOLD_NEW_LOCKED:
        break;
      case RouterState.ACTIVE:
      case RouterState.HOLD_ALL_WIP:
      case RouterState.HOLD_ALL_LOCKED:
      default:
        show = true;
        break;
    }

    return new ValidationResponse(show, disabled, msg);
  }
  public canHoldAllWIP(router: IRouter): ValidationResponse {
     let show = false;
     const disabled = false;
     const msg = null;

    switch (router.state) {
      case RouterState.INACTIVE:
      case RouterState.PENDING:
      case RouterState.HOLD_ALL_WIP:
      case RouterState.HOLD_ALL_LOCKED:
        break;
      case RouterState.ACTIVE:
      case RouterState.HOLD_NEW_WIP:
      case RouterState.HOLD_NEW_LOCKED:
      default:
        show = true;
        break;
    }
    return new ValidationResponse(show, disabled, msg);
  }

  public canRemoveHold(router: IRouter): ValidationResponse {
     let show = false;
     let disabled = false;
     let msg = null;

    switch (router.state) {
      case RouterState.INACTIVE:
      case RouterState.PENDING:
      case RouterState.ACTIVE:
         break;
      case RouterState.HOLD_NEW_LOCKED:
      case RouterState.HOLD_ALL_LOCKED:
        show = true;
        disabled = true;
        msg = new Message(MessageType.INFO, 'A previous version of a router cannot be removed from hold');
        break;
      case RouterState.HOLD_NEW_WIP:
      case RouterState.HOLD_ALL_WIP:
      default:
        show = true;
        break;
    }
      return new ValidationResponse(show, disabled, msg);
  }

}

