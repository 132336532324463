import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { IReport, IDemandItemNoBOMReport, IDemandItemNoDrawingReport, IDemandItemNoRouterReport, IDemandItemRouterOnHoldReport} from '../models/report.model';
import { Http, Response } from '@angular/http';
import { Helper } from './helper.service';
import { AppHttpClientService } from './app-http-client.service';
import { GateKeeperService} from '../shared/gatekeeper/user/user.service';
import { Config } from '../shared/gatekeeper/config';
import { MethodType, MethodTypes } from '../enums/methodtype';
import { map } from 'rxjs/operators/map';
import 'rxjs/add/observable/throw';



@Injectable()
export class ReportServiceHttp {


  constructor(private http: Http, private helper: Helper,
    // private auth: AuthService, 
    private ahttp: AppHttpClientService , 
    private gkauth: GateKeeperService
    , private Config: Config) { }



    getReports( ): Observable<IReport[]> {

      console.log('ReportsServiceHttp.getReports');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'reports/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);     

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).pipe(
      map((response: Response) => {
         //console.log(response);
         const ret =  this.translateToIReports(response);

         return ret;
      }));
          }


      getDemandItemNoBOMReport(): Observable<IDemandItemNoBOMReport[]> {
        console.log('ReportServiceHttp.getDemandItemNoBOMReport');
    
        Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'reports/DemandItemNoBOM' ;
        Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    
        return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).pipe(
        map((response: Response) => {
              //console.log(response);
              const ret =  this.translateToIDemandItemNoBOMReports(response);
              //console.log(ret);
              return ret;
          }));
        }

      getDemandItemNoDrawingReport(): Observable<IDemandItemNoDrawingReport[]> {
        console.log('ReportServiceHttp.getDemandItemNoDrawingReport');
    
        Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'reports/DemandItemNoDrawing' ;
        Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    
        return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).pipe(
        map((response: Response) => {
              //console.log(response);
              const ret =  this.translateToIDemandItemNoDrawingReports(response);
              //console.log(ret);
              return ret;
          }));
        }

      getDemandItemNoRouterReport(): Observable<IDemandItemNoRouterReport[]> {
        console.log('ReportServiceHttp.getDemandItemNoRouterReport');
    
        Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'reports/DemandItemNoRouter' ;
        Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    
        return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).pipe(
        map((response: Response) => {
              //console.log(response);
              const ret =  this.translateToIDemandItemNoRouterReports(response);
              //console.log(ret);
              return ret;
          }));
        }

        getDemandItemRouterOnHoldReport(): Observable<IDemandItemRouterOnHoldReport[]> {
          console.log('ReportServiceHttp.getDemandItemRouterOnHoldReport');
      
          Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'reports/DemandItemRouterOnHold' ;
          Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
      
          return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).pipe(
          map((response: Response) => {
                //console.log(response);
                const ret =  this.translateToIDemandItemRouterOnHoldReports(response);
                //console.log(ret);
                return ret;
            }));
          }

  private handleError(error: Response) {
    return Observable.throw(error.statusText);
  }

  translateToIReport(data: any): IReport {
    if (data) {
      return <IReport> data;
    } else {
      return null;
    }
  }

  translateToIReports(data: any): IReport[] {
    const ret: IReport[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIReport(d));
      }
    }
    return ret;
  }


  translateToIDemandItemNoBOMReport(data: any): IDemandItemNoBOMReport {
    if (data) {
      return <IDemandItemNoBOMReport> data;
    } else {
      return null;
    }
  }

  translateToIDemandItemNoBOMReports(data: any): IDemandItemNoBOMReport[] {
    const ret: IDemandItemNoBOMReport[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIDemandItemNoBOMReport(d));
      }
    }
    return ret;
  }

  translateToIDemandItemNoDrawingReport(data: any): IDemandItemNoDrawingReport {
    if (data) {
      return <IDemandItemNoDrawingReport> data;
    } else {
      return null;
    }
  }

  translateToIDemandItemNoDrawingReports(data: any): IDemandItemNoDrawingReport[] {
    const ret: IDemandItemNoDrawingReport[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIDemandItemNoDrawingReport(d));
      }
    }
    return ret;
  }


  translateToIDemandItemNoRouterReport(data: any): IDemandItemNoRouterReport {
    if (data) {
      return <IDemandItemNoRouterReport> data;
    } else {
      return null;
    }
  }

  translateToIDemandItemNoRouterReports(data: any): IDemandItemNoRouterReport[] {
    const ret: IDemandItemNoRouterReport[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIDemandItemNoRouterReport(d));
      }
    }
    return ret;
  }


  
  translateToIDemandItemRouterOnHoldReport(data: any): IDemandItemRouterOnHoldReport {
    if (data) {
      return <IDemandItemRouterOnHoldReport> data;
    } else {
      return null;
    }
  }

  translateToIDemandItemRouterOnHoldReports(data: any): IDemandItemRouterOnHoldReport[] {
    const ret: IDemandItemRouterOnHoldReport[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIDemandItemRouterOnHoldReport(d));
      }
    }
    return ret;
  }

}