import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { IFile, IRouterFile, IFileExtended, FileUtils, RouterFileUtils, MapType, IFilePartInfo, IFileExtended1 } from '../models/file.model';
import { IRouterStep } from '../models/routerstep.model';
import { Http, Response } from '@angular/http';
import { Helper } from './helper.service';
import { IFileBase64, FileTypeUtils } from '../models/file.model';
import { AppHttpClientService } from './app-http-client.service';
import { GateKeeperService} from '../shared/gatekeeper/user/user.service';
import { Config } from '../shared/gatekeeper/config';
import { MethodType, MethodTypes } from '../enums/methodtype';
import 'rxjs/add/observable/throw';
import { config } from 'process';
import { runInThisContext } from 'vm';
import { FileCacheService } from './file.cache.service';
import { HAMMER_LOADER } from '@angular/platform-browser';
import { ThisReceiver } from '@angular/compiler';
import { FileCacheService1 } from './file.cache.service1';
import { isFileExcluded } from 'tslint/lib/configuration';
import { utils } from 'protractor';
import { EngManCategoryUtils } from '../enums/engManCategory';


@Injectable()
export class FileServiceHttp {

  //manual search parameters
  public searchedFiles:IFileExtended[] = [];
  private _term:string = "";
  public lastIndex: number = 0;
  public includePastRevisions: boolean = false;
  //public lastScrolledToId:string = "";

  public get term():string {
    return this._term;
  } 

  public set term(val:string) {
    this._term = !(val === undefined || val === null)? val : "";
  }

  private _lastSearchStr:string = "";

  public get lastSearchStr():string {
    return this._lastSearchStr.length > 0
          ?"Last search:" + this._lastSearchStr
          :this._lastSearchStr;
  }

  public set lastSearchStr(val:string)
  {
    if (
      val===null ||
      val === undefined)
      {
        this._lastSearchStr = '';
      }
      else
      {
        this._lastSearchStr = val;
      }
  }
  //manual search parameters

  constructor(
    private http: Http,
    private helper: Helper,
    //private auth: AuthService,
    private ahttp: AppHttpClientService ,
    private gkauth: GateKeeperService,
    private Config: Config,
    private fileCache: FileCacheService1  
  ) {

  }


  public toCreateVersionedFilePayload(f: IFileExtended1): any {
    return {
      id: f.id !== null ? f.id : "",
      name: f.name,
      specificationName: f.specificationName,   
      engManCategory: EngManCategoryUtils.toEngManCategoryService(f.engManCategory),
      revision: f.revision,
      revisionDate: f.revisionDate !== undefined ? f.revisionDate : new Date(),
      description: f.description,
      type: FileTypeUtils.toFileTypeService(f.type),
      url: f.url,
      sourceUrl: f.sourceUrl,
      fileData: f.fileData,
      tokenID: f.tokenID,
      holdState: f.holdState,
      holdStateNotes: f.holdStateNotes,
      updateManualRevision: f.updateManualRevision
    };
  }


  createVersionedFile(f: IFileExtended1): Observable<IFileExtended1> {
    console.log('FileServiceHttp.createVersionedFile');
    const info = this.toCreateVersionedFilePayload(f);
    info.tokenID = Config.gkParameters.TokenID;
    
    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/CreateVersion';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    var jsonParamString = JSON.stringify(info);
    var paramStringLength = jsonParamString.length;
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      //console.log(response);           
      // const returnResponse :any = response;
      // if (returnResponse.result != null){
      const ret =  this.translateToIFileExtended1(response);
      //  }
      // else {
      // this.gkauth.handleError(response);
      // }           
      //console.log(ret);
      return ret;
    });
  }

  getFile(id: string ): Observable<IFile> {
    console.log('FileServiceHttp.getFile');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/' + id;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      //console.log(response);
      const ret =  this.translateToIFile(response);
     // console.log(ret);
      return ret;
    });
  }

  getFilesWithTestKits(): Observable<IFile[]> {
    console.log('file.servicehttp.getFilesWithTestKits');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/filesWithTestKits/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const ret = this.translateToIFiles(response);
      return ret;
    });
  }

  getFilesWithoutTestKits(): Observable<IFile[]> {
    console.log('file.servicehttp.getFilesWithoutTestKits');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/filesWithoutTestKits/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const ret = this.translateToIFiles(response);
      return ret;
    });
  }
/* 
  getFiles(): Observable<IFile[]> {
    console.log('FileServiceHttp.getFiles');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      //console.log(response);
      const ret =  this.translateToIFiles(response);
     // console.log(ret);
      return ret;
    });
  } */

  private getFilesExtSince(timestamp:number): Observable<IFileExtended[]> {
    console.log('file.servicehttp.getFilesExtSince');
    const info = {
      sinceDate: new Date(timestamp).toLocaleString()
    };
    //const t1 = performance.now();
    // this.alert.show('Loading application data ...');
  
    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/GetFilesSinceDate/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      //const t2 = performance.now();
      //TDiff.log("GKPOST-files/GetFilesSinceDate/", t1,t2);
      const ret =  this.translateToIFileExtendeds(response);
      console.log("file.servicehttp.getFilesExtSince ret.length:" + (
        !Helper.sIsEmptyArray(ret)? ret.length:"0"));
      return ret;
    });
  }


  getFilesExt(): Observable<IFileExtended[]> {
    console.log('FileServiceHttp.getFilesExt');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/GetFilesExtended';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const ret =  this.translateToIFileExtendeds(response);
      return ret;
    });
  }  

  searchFiles(term:string, includePastRevisions:boolean): Observable<IFileExtended1[]> {
    console.log('FileServiceHttp.searchFiles:' + (term != null? term: "Null"));
  
    if (term == null)
        term = ''; 
  
      var incl = includePastRevisions? "1" : "0";  
      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/search/' + term + '/'+ incl;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
      Config.gkParameters.JsonParameters = '';
  
      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        var ret: IFileExtended1[] = this.translateToIFileExtended1s(response);
        ret = ret.filter(x => x.replacedby.trim().length == 0);
        console.log("FileServiceHttp.searchFiles post returns");
        console.log(ret);
        this.searchedFiles = ret;
        this.lastSearchStr = term;
        return ret;
     });
  }
  






  getFilesExt1(): Observable<IFileExtended[]> {
    var ret = this.fileCache.getAll() || [];
    if (Helper.sIsEmptyArray(ret))
    {
      var t1 = performance.now();
      var t = new Date();
      return this.getFilesExtSince(this.fileCache.lastUpdate).map(response=>
      {
        var t2 = performance.now();
        TDiff.log("file.servicehttp.getFilesExt1 getFilesExtSince ",t1,t2);
        t1 = performance.now();
        ret = this.fileCache.update(response, t.getTime());
        t2 = performance.now();
        TDiff.log("file.servicehttp.getFilesExt1 fileCache.update ",t1,t2);
        console.log("file.servicehttp.getFilesExt1 ret.len:" + ret.length);
        return ret;
      });
    }
    else
    {
      console.log("file.servicehttp.getFilesExt1 returns cache.len:" + ret.length);
       return Observable.of(ret);
    }
  }


  getReplaceableFiles(): Observable<IFileExtended[]> {
    console.log('FileServiceHttp.getReplaceableFiles');
    return this.getFilesExt1();
    /* Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/ReplaceableFiles';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const ret =  this.translateToIFileExtendeds(response);
      return ret;
    }); */
  }

  getFilesForPart(partId: string): Observable<IFilePartInfo[]> {
    console.log('FileServiceHttp.getFilesForPart');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/GetFilesForPart/' + partId;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      //console.log(response);
      const ret =  this.translateToIFilePartInfos(response);
      
      //console.log(ret);
      return ret;
    });
  }
    
  getFilesForRouter(routerId: string): Observable<IRouterFile[]> {
    console.log('FileServiceHttp.getFilesForRouter');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/GetFilesForRouter/' + routerId;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      var ret =  this.translateToIRouterFiles(response);
      ret = this.getDistinctFiles(ret);      
      return ret;
    });
  }


  getDistinctFiles(list: IRouterFile[]): IRouterFile[] {
    var ret:IRouterFile[] = [];
    
    if (!this.helper.isEmptyArray(list))
    {
      for (const f of list)
      {
        if (f.mapType == MapType.ROUTER)
        {
           var p =list.find(x=> x.id == f.id && x.mapType == MapType.PART);
           if (p)
            continue;
        }

        var tmp = ret.find(x=> x.id == f.id && x.mapType == f.mapType)
        if (!tmp)
          ret.push(f)
      }
    }

    console.log('router-files.component.getDistinctFiles ret.length:' + ret.length);
    return ret;
  }


  getFilesForRouterStep(step: IRouterStep): Observable<IRouterFile[]> {
    console.log('FileServiceHttp.getFilesForRouterStep');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/GetFilesForRouterStep/' + step.id;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      console.log('FileServiceHttp.getFilesForRouterStep server response:');
      //console.log(response);
      const stepId = step.id;
      let ret: IRouterFile[] =  this.translateToIRouterFiles(response);
      ret = this.getDistinctFiles(ret);


      /* const tFiles: IRouterFile[] = ret.filter(f => f.mapType == MapType.TEST);
      ret = ret.filter(f => f.mapType !== MapType.TEST);

      for ( let i = 0; i < ret.length; i++ ) {
        const f1 = ret[i];
        if (f1.mapType == MapType.STEP) {
          const tf  = tFiles.find(x => x.id == f1.id);
          if (tf != null && tf !== undefined) {
            f1.mapType = MapType.TEST;
          }
        }
      } */
      //console.log(ret);
      return ret;
    });
  }

  getEngineeringDrawings(): Observable<IFile[]> {
    console.log('FileServiceHttp.getEngineeringDrawings');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/GetEngineeringDrawings';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const ret =  this.translateToIFiles(response);
      return ret;
    });
  }
 

  addFileToPart(partId: string, fileId: string, itemFileType: number): Observable<number> {
    return this.addFileMap(partId, fileId, itemFileType);
  }

  removeFileFromPart(partId: string, fileId: string): Observable<number> {
    return this.removeFileMap(partId, fileId);
  }

  addFileToRouter(routerId: string, file: IFile): Observable<IRouterFile> {
    console.log('FileServiceHttp.addFileToRouter');
    const info = {
      fileId: file.id,
      itemId: routerId,
      userId: this.gkauth.currentUser.UserID
    };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/AddFileToRouter/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const ret = Number(response);
      return this.makeRouterFile(routerId, null, file);
    });
  }

  addFileToRouterStep(step: IRouterStep, file: IFile): Observable<IRouterFile> {
    console.log('FileServiceHttp.addFileToRouterstep');
    const info = {
      fileId: file.id,
      itemId: step.id,
      userId: this.gkauth.currentUser.UserID
    };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/AddFileToRouterStep/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const ret = Number(response);
      //console.log('...ret ' + ret);
      // if (ret == 1) {
        return this.makeRouterFile(step.routerId, step, file);
      // } else {
      //  return null;
      // }
    });
  }

  makeRouterFile(routerId: string, step: IRouterStep, file: IFile): IRouterFile {
    return {
      mapType: step !== null ? MapType.STEP : MapType.ROUTER,
      id: file.id,
      routerId: routerId,
      routerOperationId: (step !== null ? step.id : null),
      routerOperationDescription: (step !== null ? step.description : null),
      routerOperationNumber: (step !== null ? step.step : null),
      name: file.name,
      specificationName: file.specificationName,
      revision: file.revision,
      description: file.description,
      type: file.type,
      url: file.url,
      sourceUrl: file.sourceUrl,
      revisionDate: file.revisionDate
    };
  }

  removeFileFromRouter(file: IRouterFile): Observable<number> {
    console.log('FileServiceHttp.removeFileFromRouter');
    const info = {
      fileId: file.id,
      itemId: file.routerId,
      userId: this.gkauth.currentUser.UserID
    };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/RemoveFileFromRouter/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      return Number(response);
    });
  }

  removeFileFromRouterStep(file: IRouterFile): Observable<number> {
    console.log('FileServiceHttp.removeFileFromRouterStep');
    const info = {
      fileId: file.id,
      itemId: file.routerOperationId,
      userId: this.gkauth.currentUser.UserID
    };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/RemoveFileFromRouterStep/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      return Number(response);
    });
  }

   addFileUpload(fileName: string, fileData: string): Observable<string> {
    console.log('FileServiceHttp.addFileUpload');

    const info = {
      fileName: fileName, //fileToUpload.name,
      fileData: fileData  //btoa(readerResult.toString())
    };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'file/Upload/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      return response.toString();
    });
  } 

  addFileMap(itemId: string, fileId: string, itemFileType: number): Observable<number> {
    console.log('FileServiceHttp.addFileMap');
    const info = {
      fileId: fileId,
      itemId: itemId,
      userId: this.gkauth.currentUser.UserID
    };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/AddFileMap/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      return Number(response);
    });
  }

  addDefaultPartFile(partId: string, fileId: string): Observable<number> {
    console.log('FileServiceHttp.addDefaultPartFile');
    const info = {
      fileId: fileId,
      partId: partId,
      userId: this.gkauth.currentUser.UserID
    };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/AddDefaultPartFile/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      return Number(response);
    });
  }


  deleteDefaultPartFile(partId: string, fileId: string): Observable<number> {
    console.log('FileServiceHttp.deleteDefaultPartFile');
    const info = {
      fileId: fileId,
      partId: partId,
      userId: this.gkauth.currentUser.UserID
    };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/DeleteDefaultPartFile/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      return Number(response);
    });
  }


  removeFileMap(itemId: string, fileId: string): Observable<number> {
    console.log('FileServiceHttp.removeFileMap');
    const info = {
      fileId: fileId,
      itemId: itemId,
      userId: this.gkauth.currentUser.UserID
    };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/RemoveFileMap/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      return Number(response);
    });
  }

  filesReplace(replacementFileID: string, filesToBeReplacedIDs: string[], routerHoldState: number, routerHoldStateNotes: string, tokenID: string): Observable<number> {
    console.log('FileServiceHttp.filesReplace');
    const info = {
      replacementFileID: replacementFileID,
      filesToBeReplacedIDs: filesToBeReplacedIDs,
      routerHoldState: Number(routerHoldState),
      routerHoldStateNotes: routerHoldStateNotes,
      tokenID: tokenID
    };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/FilesReplace/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      return Number(response);
    });
  }

  deleteFile(id: string): Observable<number> {
    console.log('FileServiceHttp.deleteFile');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/' + id;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.DELETE);
    Config.gkParameters.JsonParameters = '';

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      return Number(response);
    });
  }


  getPhysicalFile(filePath: string): Observable<IFileBase64> {
    const info =  {
        path: filePath
    };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'files/GetPhysicalFile';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const data = response.json();
      const fi: IFileBase64 = {
        path : data.path,
        encoding: data.encoding,
        byteCharacters: atob(data.encoding),
        bytes: null
      };

      const byteNumbers = new Array(fi.byteCharacters.length);
      for (let i = 0; i < fi.byteCharacters.length; i++) {
        byteNumbers[i] = fi.byteCharacters.charCodeAt(i);
      }
      fi.bytes = new Uint8Array(byteNumbers);
      return fi;
    });
  }

  /*
  Use atob to get bytearray. Note: you will have to strip it of the prefix data:;base64,
  var byteCharacters = atob(b64Data);
  var byteNumbers = new Array(byteCharacters.length);
  for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  var byteArray = new Uint8Array(byteNumbers);
  You can get a bytearray from base64string in C#

  var byteArray = Convert.FromBase64String(base64StringFromClient);
  */

  private handleError(error: Response) {
    return Observable.throw(error.statusText);
  }

  translateToIFile(data: any): IFile {
    if (data) {
      return FileUtils.toIFile(data);
    } else {
      return null;
    }
  }

  translateToIFilePartInfo(data: any): IFilePartInfo {
    if (data) {
      return FileUtils.toIFilePartInfo(data);
    } else {
      return null;
    }
  }



  translateToIFiles(data: any): IFile[] {
    const ret: IFile[] = [];
    if (data && data.length > 0) {
      for (const d of data){
        ret.push(this.translateToIFile(d));
      }
    }
    return ret;
  }

  translateToIFilePartInfos(data: any): IFilePartInfo[] {
    const ret: IFilePartInfo[] = [];
    if (data && data.length > 0) {
      for (const d of data){
        ret.push(this.translateToIFilePartInfo(d));
      }
    }
    return ret;
  }


  translateToIFileExtended(data: any): IFileExtended {
    if (data) {
      const ret = <IFileExtended> data;
      ret.name = ret.specificationName + "_______" + ret.description + "";
      ret.type = FileTypeUtils.toFileType(data.type);
      return ret;
    } else {
      return null;
    }
  }


  translateToIFileExtended1(data: any): IFileExtended1 {
    if (data) {
      const ret = <IFileExtended1> data;
      ret.name = ret.specificationName + "_______" + ret.description + "";
      ret.type = FileTypeUtils.toFileType(data.type);
      ret.engManCategory = EngManCategoryUtils.toEngManCategory(data.engManCategory);
      return ret;
    } else {
      return null;
    }
  }

  translateToIFileExtended1s(data: any): IFileExtended1[] {
    const ret: IFileExtended1[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIFileExtended1(d));
      }
    }
    return ret;
  }


  translateToIFileExtendeds(data: any): IFileExtended[] {
    const ret: IFileExtended[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIFileExtended(d));
      }
    }
    return ret;
  }

  translateToIRouterFile(data: any): IRouterFile {
    if (data) {
      return RouterFileUtils.toIRouterFile(data);
    } else {
      return null;
    }
  }

  translateToIRouterFiles(data: any): IRouterFile[] {
    const ret: IRouterFile[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIRouterFile(d));
      }
    }
    return ret;
  }
}


export class TDiff {
  private prev = 0;
  private cur = 0;

  static log(msg: string, t1: number, t2: number) {
    console.log('CLOCK: ' + msg + ':' + (t2 - t1) + ' milliseconds');
  }

  constructor() { }

  mark(msg: string = null) {
    this.prev = this.cur;
    this.cur = Date.now();
    if (msg != null) {
      console.log('CLOCK: ' + msg + ':' + (this.cur - this.prev) + ' milliseconds');
    }
  }
}
