import { Injectable } from '@angular/core';
import { IMyCache } from './imycache';

@Injectable()
export class BrowserCacheService implements IMyCache {

  constructor(
    private _key:string,
    private _lastUpdateKey:string) { 
  }

  clear(): void {
    console.log('browser.cache.service.clear');
    this.lastUpdate = new Date(2010,1,1).getTime();
    localStorage.removeItem(this._key);
  }

  set lastUpdate(val:number){
    console.log('browser.cache.service.lastUpdated set lastUpdateKey: ' + new Date(val).toString());
    localStorage.setItem(this._lastUpdateKey, val.toString());
  }

  get lastUpdate():number {
    var t =  +localStorage.getItem(this._lastUpdateKey);
    if (t == undefined || t == null || t == 0 || t == -1)
      t = new Date(2010,1,1).getTime();

    console.log('browser.cache.service.lastUpdated get lastUpdateKey:' + new Date(t).toString());
    return t;
  }

  getAll(): any[] {    
    const ret = <any[]>JSON.parse(localStorage.getItem(this._key)) || [];
    console.log('browser.cache.service.getAll ret.length:' + ret.length);
    return ret;
  }


  load(data: any[], timestamp:number): void{
    console.log("browser-cache.service.load data.length:" + data.length);
    var ret = data;
    try {
      localStorage.setItem(this._key, JSON.stringify(data));
      this.lastUpdate = timestamp;
    }
    catch(ex)
    {
      //eat the exception to get the same behavior as if this cache does not exist  
      console.log("browser-cache.service.load exception:" + ex);
      this.lastUpdate = new Date(2010,1,1).getTime();
  
    }
  }

 
  update(data: any[], lastUpdateTime: number): any[] {
    throw new Error('Method not implemented.');
  }

  get(id: string): any {
    throw new Error('Method not implemented.');
  }

  set(data: any) {
    throw new Error('Method not implemented.');
  }
}
