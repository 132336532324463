// import { CanDeactivateStepNotes } from './step-detail/step-detail.candeactivateNotes';
// import { CanDeactivateRouterDetail1 } from './router-detail/router-detail.candeactivate.1';
// import { CanDeactivateRouterDetail } from './router-detail/router-detail.candeactivate';
// import { ModuleWithProviders, OnInit } from '@angular/core';
// import { RouterModule, Routes } from '@angular/router';
// import { IRouter } from './core/models/router.model';
// import { RouterDetailComponent } from './router-detail/router-detail.component';
// import { RouterListingComponent } from './router-listing/router-listing.component';
// import { StepDetailComponent } from './step-detail/step-detail.component';
// import { StepInfoComponent } from './step-detail/step-info/step-info.component';
// import { RouterStepsComponent } from './router-detail/router-steps/router-steps.component';
// import { PartInfoComponent } from './part-info/part-info.component';
// import { WorkCentersComponent } from './work-centers/work-centers.component';
// import { WorkCenterDetailComponent } from './work-centers/work-center-detail/work-center-detail.component';
// import { ToolsContainerComponent } from './tools/tools-container.component';
// import { ToolKitDetailComponent } from './tools/tool-kit/tool-kit-detail/tool-kit-detail.component';
// import { TestKitComponent } from './test-kit/test-kit.component';
// import { TestKitDetailComponent } from './test-kit/test-kit-detail/test-kit-detail.component';
// import { TestsComponent } from './tests/tests.component';
// import { ProgramsComponent } from './programs/programs.component';
// import { FixturesComponent } from './fixtures/fixtures.component';
// import { ReasonCodeComponent } from './reason-code/reason-code.component';
// import { FilesComponent } from './files/files.component';
// import { PartInfoTabsComponent } from './part-info/part-info-tabs/part-info-tabs.component';
// import { CanDeactivateStepInfo } from './step-detail/step-detail.candeactivateStep';
// import { TestKitDetailResolveFilesWithoutKits } from './test-kit/test-kit-detail/test-kit-detail-resolveFilesWithoutKits';
// import { TestKitDetailResolveFile } from './test-kit/test-kit-detail/test-kit-detail-resolveFile';
// import { TestKitDetailResolveFileTests } from './test-kit/test-kit-detail/test-kit-detail-resolveFileTests';
// import { CanDeactivateToolKitDetail } from './tools/tool-kit/tool-kit-detail/tool-kit-detail.candeactivate';
// import { UserMaintenanceComponent } from './user-maintenance/user-maintenance.component';
// import { UserDetailComponent } from './user-maintenance/user-detail/user-detail.component';
// import { ReportsComponent } from './reports/reports.component';
// import { DemandItemNoBOMComponent} from './reports/report_demanditemnobom/demanditemnobom.component'
// import { DemandItemNoDrawingComponent} from './reports/report_demanditemnodrawing/demanditemnodrawing.component'
// import { DemandItemNoRouterComponent} from './reports/report_demanditemnorouter/demanditemnorouter.component'
// import { DemandItemRouterOnHoldComponent} from './reports/report_demanditemrouteronhold/demanditemrouteronhold.component'


// export const router: Routes =
// [
//     {
//         path: 'routers',
//         children:
//         [
//             {
//                 path: '',
//                 component: RouterListingComponent               
//             },
//             {
//                 path: ':id',
//                 component: RouterDetailComponent,
//                 pathMatch: 'prefix',
//                 canDeactivate: [CanDeactivateRouterDetail, CanDeactivateRouterDetail1],
//                 data:                 {
//                     breadcrumb: 'Router Detail',
//                     router: '',
//                     step: ''
//                 }
//             },
//             {
//                 path: ':id/steps/:stepId',
//                 component: StepDetailComponent,
//                 pathMatch: 'prefix',
//                 canDeactivate: [CanDeactivateStepInfo, CanDeactivateStepNotes],
//                 data:                 {
//                     breadcrumb: 'Step Detail',
//                     router: '',
//                     step: ''
//                 },
//             },
//         ]
//     },
//     { path: 'parts', component: PartInfoComponent, data: {breadcrumb: 'Part Info Maintenance'}},
//     { path: 'tests', component: TestsComponent, data: {breadcrumb: 'Test Maintenance'}},
//     { path: 'userMaintenance', component: UserMaintenanceComponent, data: {breadcrumb: 'User Maintenance'}},
//     { path: 'userMaintenance/new', component: UserDetailComponent, data: {breadcrumb: 'User Maintenance'}},
//     { path: 'userMaintenance/:id', component: UserDetailComponent, data: {breadcrumb: 'User Maintenance'}},
//     { path: 'workCenters', component: WorkCentersComponent, data: {breadcrumb: 'Work Centers'}},
//     { path: 'workCenters/new', component: WorkCenterDetailComponent, data: {breadcrumb: 'Work Center Maintenance'}},
//     { path: 'workCenters/:id', component: WorkCenterDetailComponent, data: {breadcrumb: 'Work Center Maintenance'}},
//     { path: 'tools', component: ToolsContainerComponent, data: {breadcrumb: 'Tools Maintenance'}},
//     { path: 'toolKits/new', component: ToolKitDetailComponent
//     , data: {breadcrumb: 'Tool Maintenance'}},
//     { path: 'toolKits/:id', component: ToolKitDetailComponent, canDeactivate: [CanDeactivateToolKitDetail]
//       , data: {breadcrumb: 'Tool Maintenance'}},
//     { path: 'parts/:id', component: PartInfoTabsComponent, data: {breadcrumb: 'Part Info Maintenance'}},
//     { path: 'programs', component: ProgramsComponent, data: {breadcrumb: 'Programs Maintenance'}},
//     { path: 'reports', component: ReportsComponent, data: {breadcrumb: 'Reports'}},
//     { path: 'reports/demanditemnobom', component: DemandItemNoBOMComponent, data: {breadcrumb: 'Demand Item No BOM Report'}},
//     { path: 'reports/demanditemnodrawing', component: DemandItemNoDrawingComponent, data: {breadcrumb: 'Demand Item No Drawing Report'}},
//     { path: 'reports/demanditemnorouter', component: DemandItemNoRouterComponent, data: {breadcrumb: 'Demand Item No Router Report'}},
//     { path: 'reports/demanditemrouteronhold', component: DemandItemRouterOnHoldComponent, data: {breadcrumb: 'Demand Item Router On Hold Report'}},
//     { path: 'fixtures', component: FixturesComponent, data: {breadcrumb: 'Fixtures Maintenance'}},
//     { path: 'reasonCode', component: ReasonCodeComponent, data: {breadcrumb: 'Reason Code Maintenance'}},
//     { path: 'files', component: FilesComponent, data: {breadcrumb: 'Files Maintenance'}},
//     { path: 'testKits', component: TestKitComponent
//     , data: {breadcrumb: 'Test Kit Maintenance'}},
//     { path: 'testKits/:id', component: TestKitDetailComponent
//     , resolve: {
//       files: TestKitDetailResolveFilesWithoutKits,
//       file: TestKitDetailResolveFile,
//       fileTests: TestKitDetailResolveFileTests
//     }, data: {breadcrumb: 'Test Kit Maintenance'}},
//     { path: 'testKits/new', component: TestKitDetailComponent, data: {breadcrumb: 'Test Kit Maintenance'}},
//     // wild card match, leave at bottom
//     { path: '**', redirectTo: 'routers', pathMatch: 'full'},
// ];

// export const routes: ModuleWithProviders = RouterModule.forRoot(router, {useHash: true});

import { ModuleWithProviders, OnInit } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
 import { DemandItemNoBOMComponent} from './reports/report_demanditemnobom/demanditemnobom.component'
 import { DemandItemNoDrawingComponent} from './reports/report_demanditemnodrawing/demanditemnodrawing.component'
 import { DemandItemNoRouterComponent} from './reports/report_demanditemnorouter/demanditemnorouter.component'
 import { DemandItemRouterOnHoldComponent} from './reports/report_demanditemrouteronhold/demanditemrouteronhold.component'

export const router: Routes =
[
    { path: 'routers', loadChildren: './router-listing/router-listing.module#RouterListingModule'},   
    { path: 'parts', loadChildren: './part-info/part-info.module#PartsModule', data: {breadcrumb: 'Part Info Maintenance'}},   
    { path: 'tests', loadChildren: './tests/tests.module#TestsModule', data: {breadcrumb: 'Test Maintenance'}},
    { path: 'userMaintenance', loadChildren: './user-maintenance/user-maintenance.module#UserMaintenanceModule', data: {breadcrumb: 'User Maintenance'}},    
    { path: 'workCenters', loadChildren: './work-centers/work-centers.module#WorkCentersModule', data: {breadcrumb: 'Work Centers'}},    
    { path: 'tools', loadChildren: './tools/tools.module#ToolsModule', data: {breadcrumb: 'Tools Maintenance'}},    
    { path: 'programs', loadChildren: './programs/programs.module#ProgramsModule', data: {breadcrumb: 'Programs Maintenance'}},  
    { path: 'fixtures', loadChildren: './fixtures/fixtures.module#FixturesModule', data: {breadcrumb: 'Fixtures Maintenance'}},    
    { path: 'reasonCode', loadChildren: './reason-code/reason-code.module#ReasonCodeModule', data: {breadcrumb: 'Reason Code Maintenance'}},  
    { path: 'reports', loadChildren: './reports/reports.module#ReportsModule', data: {breadcrumb: 'Reports'}},    
    { path: 'files', loadChildren: './files/files.module#FilesModule', data: {breadcrumb: 'Files Maintenance'}},
    { path: 'testKits', loadChildren: './test-kit/test-kit.module#TestKitModule', data: {breadcrumb: 'Test Kit Maintenance'}},    
    { path: '**', redirectTo: 'routers', pathMatch: 'full'},
];

export const routes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(router, 
    {
        useHash: true,
        preloadingStrategy: PreloadAllModules
    });
