import { Component, OnInit, DoCheck, SimpleChanges,
  AfterViewInit, AfterContentInit,
  AfterViewChecked, AfterContentChecked, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FileType, FileUtils, IFile, IFilePartInfo } from '../../../core/models/file.model';
import { ActivatedRoute } from '@angular/router';
import { Helper } from '../../../core/services/helper.service';
import { PDFService } from '../../../core/services/pdf-service';
import { FileServiceHttp } from '../../../core/services/file.servicehttp';


@Component({
  selector: 'part-files',
  template: require('./part-files.component.html'),
  // styles: [require('./part-files.component.css')]
  styles: [require('./part-files.component.css').toString()]
})
export class PartFilesComponent implements OnInit, AfterViewInit, AfterContentInit, AfterContentChecked, OnDestroy {
  partFiles: IFilePartInfo[];
  partId: string;
  @ViewChild('partFilesComponent' , {static: true}) view: ElementRef;
  viewWasVisible: boolean;

  constructor(private fileService: FileServiceHttp, private route: ActivatedRoute, private helper: Helper,
  private pdfService: PDFService) { }


  ngOnInit() {
    console.log('part-files.component.ngOnInit');
    this.partId = this.route.snapshot.params['id'];
    this.fileService.getFilesForPart(this.partId).subscribe(partFiles => {
      console.log(partFiles);
      this.partFiles = partFiles;
   });

  }

  ngAfterViewInit() {
    console.log('part-files.component ngAfterViewInit');
  }

  ngAfterContentInit() {
    console.log('part-files.component ngAfterContentInit');
  }

  private get isVisible(): boolean {
    return this.view.nativeElement.offsetParent != null;
  }

  ngAfterContentChecked() {
    console.log('part-files.component ngAfterContentChecked');
    /*
    console.log('...isVisible:' + (this.isVisible ? 'true' : 'false') + ' viewWasVisible:' + (this.viewWasVisible ? 'true' : 'false'));
    if (!this.isVisible && this.viewWasVisible) {
      this.viewWasVisible = false;
    } else if (this.isVisible && !this.viewWasVisible) {
      console.log('...from hidden to visible');
      console.log('...initializing component');
      this.viewWasVisible = true;

    }*/
  }

  ngOnDestroy() {
    console.log('part-files.component ngOnDestroy');
  }

  addFile() {
    this.overlayOpen();
  }

  viewFile(file: IFile) {
    this.pdfService.show(file.url);
   }

  removeFile(f: IFile) {
    console.log('PartFilesComponent.removeFile');
      this.fileService.removeFileFromPart(this.partId, f.id).subscribe(ret => {
        this.partFiles = this.partFiles.filter(x => x.id !== f.id);
      });
  }

  handleFileSelected(file: IFile) {
    console.log('PartFilesComponent.handelFileSelected');
    this.fileService.addFileToPart(this.partId, file.id, file.itemFileType).subscribe(ret => {
        var tmp:IFilePartInfo = FileUtils.IFileToIFilePartInfo(file);
        this.partFiles.push(tmp);
        this.closeOverlay();

    });
  }

  toggleIsDefault(f:IFilePartInfo) {
    console.log('part-files.component toggleIsDefault:' + f.specificationName + ' curIsDefault:' + f.isDefault);
    if (!f.isDefault) {
      for (var i:number = 0; i < this.partFiles.length; i++)
      {
        var pf:IFilePartInfo = this.partFiles[i];
        if (pf.id != f.id && pf.type == f.type)
          pf.isDefault = false;
      }

      this.fileService.addDefaultPartFile(this.partId, f.id).subscribe( ret => {
          console.log('...addDefaultPartFile returns ' + ret);  
      });
    }
    else
    {
      this.fileService.deleteDefaultPartFile(this.partId,f.id).subscribe( ret => {
        console.log('...deleteDefaultPartFile returns ' + ret);  
    });
    }

  }

  closeOverlay() {
    document.getElementById('PartFileOverlay').style.display = 'none';
  }

  overlayOpen() {
    document.getElementById('PartFileOverlay').style.display = 'block';
    const modal = document.getElementById('PartFileOverlay');

    // When the user clicks anywhere outside of the modal, this closes it
    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = 'none';
        }
    };
  }

  getFileTypeString(f:IFile): string
  {
    var ret:string = "unknown";
    switch(f.type)
    {
      case FileType.DRAWING:
        ret = "drawing";
        break;
      case FileType.SPEC:
        ret = "spec";
        break;
      default:
        break;        
    }

    return ret;
  }

  getPartDrawingSpec(itemFileType:number): string
  {
    var partDrawingSpec:string = "";
    switch(itemFileType)
    {
      case 0:
        partDrawingSpec = "";
        break;
      case 1: //-- part drawing
        partDrawingSpec = " (part drawing)";
        break;
      case 2: //-- part spec
        partDrawingSpec = " (part spec)";
        break;    
    }
    return partDrawingSpec;
  }

}
