import { Helper } from '../services/helper.service';
export interface IFixture {
    id: string;
    number: string;
    description: string;
    inUse: boolean;
  }

export interface IRouterStepFixture {
  fixture: IFixture;
  stepId: string;
}



export class FixtureUtils {
  public static areEqual( a1: IFixture, a2: IFixture) {

    if (a1 == null) {
      return a2 == null;
    }

    if (a2 ==  null) {
      return false;
    }

    return  Helper.areEqualStr(a1.id, a2.id) &&
      Helper.areEqualStr(a1.description,  a2.description) &&
      Helper.areEqualStr(a1.number, a2.number);
    }
}
