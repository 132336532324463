import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { LoginService } from '../core/services/login.service';

@Component({
  selector: 'navigation-header',
  template: require('./navigation-header.component.html'),
  // styles: [require('./navigation-header.component.css')]
  styles: [require('./navigation-header.component.css').toString()]
})
export class NavigationHeaderComponent implements OnInit {
  @Output() onLogout = new EventEmitter();

  get userName() {return 'Rene Eiffert'; }

  constructor(private auth: AuthService, private loginService: LoginService) { }

  ngOnInit() {
  }

/* Set the width of the side navigation to 250px and the left margin of the page content to
   250px and add a black background color to body */
  openNav() {
      document.getElementById('Sidenav').style.width = '150px';
      document.getElementById('main').style.marginLeft = '150px';
      document.getElementById('menuOverlay').style.display = 'block';
      // document.body.style.backgroundColor = "rgba(0,0,0,0.25)";
  }

  /* Set the width of the side navigation to 0 and the left margin of the page content to 0, and the background color of body to white */
  closeNav() {
      document.getElementById('Sidenav').style.width = '0';
      document.getElementById('main').style.marginLeft = '0';
      document.getElementById('menuOverlay').style.display = 'none';
      // document.body.style.backgroundColor = "#fafafa";
  }

  UserSettingsDropdown() {
    document.getElementById('myDropdown').classList.toggle('show');
  }

  logout() {
    console.log('navigation-header.component.logout');

    this.auth.unlockItem(null).subscribe(res1 => {
     
        this.loginService.logoutUser().subscribe(res => {
        this.UserSettingsDropdown();
        this.onLogout.emit();
      });
    }); 
}}
