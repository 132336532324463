
import {Observable, Subscription as ISubscription } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { FileServiceHttp } from '../../core/services/file.servicehttp';
import { Helper } from '../../core/services/helper.service';
import { IFile } from '../../core/models/file.model';
import { of as observableOf} from 'rxjs/observable/of'

 @Injectable()
 export class TestKitDetailResolveFilesWithoutKits implements Resolve<any> {
  constructor(private fileService: FileServiceHttp,
  private utils: Helper) {}

  resolve(router: ActivatedRouteSnapshot) {
    console.log('test-kit-detail-resolveFilesWithoutKits');
    const fileId = router.params['id'];
    if (fileId === 'new') {
      return this.fileService.getFilesWithoutTestKits();
    } else {
      observableOf(null);
    }
  }
}
