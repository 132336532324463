import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterFixtures'
})
export class FilterFixturePipe implements PipeTransform {

  transform(fixtures: any, term: any): any {
    // check if term is undefined
    if (term === undefined || term.length == 0 || fixtures === null) {
        return fixtures;
    }
    // return the updated routers array
    return fixtures.filter(function(f){
    return f.number.toLowerCase().includes(term.toLowerCase()) || f.description.toLowerCase().includes(term.toLowerCase());
    });
  }
}
