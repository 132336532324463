import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { IBreadcrumb } from './bread-crumb.model';
import { RouterDomainService} from '../../core/services/router-domain.service';
import { PartDomainService} from '../../core/services/part-domain.service';
import { filter } from 'rxjs/operators/filter';
import { WorkCenterService } from '../../core/services/workcenter.service';
import { WorkCenterDataService } from '../../core/services/workcenter-data.service';


@Component({
  selector: 'bread-crumb',
  template: require('./bread-crumb.component.html'),
  styles: [require('./bread-crumb.component.css')]  
})
export class BreadCrumbComponent implements OnInit {
 
  public breadcrumbs: IBreadcrumb[];

    constructor(
      private domain: RouterDomainService,
      private partDomain: PartDomainService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private workCenterData: WorkCenterDataService
    ) {
      this.breadcrumbs = [];
    }

    ngOnInit() {
      const ROUTE_DATA_BREADCRUMB = 'breadcrumb';

      // subscribe to the NavigationEnd event
      this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
      ).subscribe(event => {

        // set breadcrumbs
        const root: ActivatedRoute = this.activatedRoute.root;
        this.breadcrumbs = this.getBreadcrumbs(root);      
      });
    }

    private getBreadcrumbs(route: ActivatedRoute, url: string= '', breadcrumbs: IBreadcrumb[]= []): IBreadcrumb[] {
      const ROUTE_DATA_BREADCRUMB = 'breadcrumb';

      // get the child routes
      const children: ActivatedRoute[] = route.children;

      // return if there are no more children
      if (children.length === 0) {
        return breadcrumbs;
      }

      // iterate over each children
      for (const child of children) {
        // verify primary route
        if (child.outlet !== PRIMARY_OUTLET) {
          continue;
        }

        // verify the custom data property "breadcrumb" is specified on the route
        if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
          return this.getBreadcrumbs(child, url, breadcrumbs);
        }

        let routersegment = '';
        let stepsegment = '';
        let reportsegment ='';
        let partsegment = '';
        let isRouter: Boolean = false;
        let isReport: Boolean = false;
        let isReportSelected: Boolean = false;
        let isPart: Boolean = false;
        let isPartSelected: Boolean = false;
        let isWorkCenter: Boolean = false;
        let isDetail:Boolean = false;
        let detail = '';
        // get the route's URL segment
        let baseURL = '';
        let routerURL = ''; 
        let stepURL = '';
        let reportURL = '';
        let partURL = '';
        

          if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] === 'Router Listing') {
            isRouter = true;
            isReport = false;
            isPart = false;
            isPartSelected = false;
            baseURL = 'routers/';           
            stepURL = '';
          } else if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] === 'Router Detail')  {
            isRouter = true;
            isReport = false;
            isPart = false;
            isPartSelected = false;
            routersegment = this.getRouter(); 
            stepsegment = '';
            baseURL = 'routers/' + child.snapshot.url[0].path;            
            stepURL = '';
          } else if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] === 'Step Detail') {
            isRouter = true;
            isReport = false;
            isPart = false;
            isPartSelected = false;
            routersegment = this.getRouter();
            stepsegment = this.getStep(); 
            baseURL = 'routers/' + child.snapshot.url[0].path;            
            stepURL = 'routers/' + child.snapshot.url[0].path + '/steps/' + child.snapshot.url[2].path ;
          }  else if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] === 'Part Info Maintenance') {
            isRouter = false;
            isReport = false;
            isPart = true;
            isPartSelected = false;
            partURL = 'parts/' + child.snapshot.url[0].path;
            baseURL = 'parts/'; 
            partsegment = '';
          } else if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] === 'Part Details') {
            isRouter = false;
            isReport = false;
            isPart = true;
            isPartSelected = true;
            partsegment = this.getPart(); 
            partURL = 'parts/' + child.snapshot.url[0].path;
            baseURL = 'parts/';             
          }  else if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] === 'Work Center Details') {
            isWorkCenter = true;
            isDetail = true;
            detail = this.getWorkCenter(); 
            baseURL = 'workCenters/';             
          } 
           else if (child.snapshot.url[0].path === 'tests') {
            baseURL = 'tests/';            
            isRouter = false;
            isReport = false;
            isPart = false;
            isPartSelected = false;
          }  else if (child.snapshot.url[0].path === 'workCenters') {
            baseURL = 'workCenters/';           
            isRouter = false;
            isReport = false;
            isPart = false;
            isPartSelected = false;
          } else if (child.snapshot.url[0].path === 'testKits') {
            baseURL = 'testKits/';            
            isRouter = false;
            isReport = false;
            isPart = false;
            isPartSelected = false;
          } else if (child.snapshot.url[0].path === 'toolKits') {
            baseURL = 'tools/';            
            isRouter = false;
            isReport = false;
            isPart = false;
            isPartSelected = false;
          } else if (child.snapshot.url[0].path === 'tools') {
            baseURL = 'tools/';            
            isRouter = false;
            stepURL = '';
            isReport = false;
            isPart = false;
            isPartSelected = false;
          } else if (child.snapshot.url[0].path === 'programs') {
            baseURL = 'programs/';           
            isRouter = false;
            isReport = false;
            isPart = false;
            isPartSelected = false;
          } else if (child.snapshot.url[0].path === 'fixtures') {
            baseURL = 'fixtures/';
            routerURL = 'fixtures/';
            isRouter = false;
            isReport = false;
            isPart = false;
            isPartSelected = false;
          } else if (child.snapshot.url[0].path === 'reasonCode') {
            baseURL = 'reasonCode/';            
            isRouter = false;
            isReport = false;
            isPart = false;
            isPartSelected = false;
          } else if (child.snapshot.url[0].path === 'files') {
            baseURL = 'files/';            
            isRouter = false;
            isReport = false;
            isPart = false;
            isPartSelected = false;
          } else if (child.snapshot.url[0].path === 'reports') {
            isRouter = false;            
            isReport = true;
            isPart = false;  
            isPartSelected = false;                    
            baseURL = 'reports/';   
             if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] === 'Demand Item No BOM Report') 
             {
              baseURL = 'reports/'; 
              isReportSelected = true;
              reportURL = 'reports/' + child.snapshot.url[0].path;
              reportsegment = 'Demand Item No BOM Report';    
             }  
             if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] === 'Demand Item No Drawing Report')  {
                 baseURL = 'reports/'; 
                 isReportSelected = true;
                 reportURL = 'reports/' + child.snapshot.url[0].path;
                 reportsegment = 'Demand Item No Drawing Report';            
              }  
              else if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] === 'Demand Item No Router Report')  {  
                  baseURL = 'reports/';                  
                  isReportSelected = true;
                  reportURL = 'reports/' + child.snapshot.url[0].path;
                  reportsegment = 'Demand Item No Router Report';             
                } else if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] === 'Demand Item Router On Hold Report')  { 
                  baseURL = 'reports/';                  
                  isReportSelected = true;
                  reportURL = 'reports/' + child.snapshot.url[0].path;
                  reportsegment = 'Demand Item Router On Hold Report';            
                } 
          } else if (child.snapshot.url[0].path === 'userMaintenance') {
            baseURL ='userMaintenance/';           
            isRouter = false;
            isReport = false;
            isPart = false;
            isPartSelected = false;
          }
        // add breadcrumb
        const breadcrumb: IBreadcrumb = {
          label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
          isrouter: isRouter,
          isreport: isReport, 
          isreportselected: isReportSelected,
          ispart: isPart,
          ispartselected: isPartSelected,         
          router: routersegment,
          step: stepsegment,
          report: reportsegment,
          part: partsegment,
          params: child.snapshot.params,
          baseurl: baseURL,
          routeurl: routerURL,
          stepurl: stepURL,
          reporturl: reportURL,
          isworkcenter: isWorkCenter,
          isdetail: isDetail,
          details: detail
        };
        breadcrumbs.push(breadcrumb);

        // recursive
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      // just in case i guess
      return breadcrumbs;
    }

    private getRouter(){
      if (this.domain == null) {
        return '';
      } else {
        return this.domain.selectedRouter.partNumber + ' v' + this.domain.selectedRouter.revision;
      }

    }   

    private getWorkCenter(){
      let ret = '';
      
      if (this.workCenterData && this.workCenterData.selectedWorkCenter)
      {
         let hasName:boolean = this.workCenterData.selectedWorkCenter.name != undefined && this.workCenterData.selectedWorkCenter.name != null;
         let name = hasName? this.workCenterData.selectedWorkCenter.name: "NULL";
         ret = ret.concat(name);
         if (!hasName)
          ret = ret.concat(' (N',this.workCenterData.selectedWorkCenter.code.toString(),')'); 
      }
      return ret;
    }


    private getStep(){
      if (this.domain == null)        {
          return '';
        }        else {
          // return this.domain.selectedRouterStep.step + '-' + this.domain.selectedRouterStep.description;
          return this.domain.selectedRouterStep.description;
        }
    }

    private getPart(){
      if (this.partDomain == null) {
        return '';
      } else {
        return this.partDomain.selectedPart.number + ' - ' + this.partDomain.selectedPart.description;
      }

    }

    private getReport(){
      if (this.domain == null) 
      {
        return 'Reports/Demand Item No BOM Report';
      }     
       else
      {
        return '';
      }
    }
  }
