import { AlertService } from '../../core/services/alert.service';
import { RouterDomainService } from '../../core/services/router-domain.service';
import { StepBOMComponentBizLogic } from './step-bom.component.bizl';
import * as jquery from 'jquery';
import { Component, OnInit, ViewChild, ElementRef, Input, AfterContentChecked } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { IBOMItem } from '../../core/models/bom.model';
import { Helper } from '../../core/services/helper.service';
import { ActivatedRoute } from '@angular/router';
import { IPart, TraceType, PartType } from '../../core/models/part.model';
import { IRouterStep } from '../../core/models/routerstep.model';
import { ShSelectComponent} from '../../core/shared/sh-slelect.component';
import { UnitType } from '../../core/enums/unittype';
import { AuthService } from '../../core/services/auth.service';
import { AlertLevel, AlertReponse } from '../../core/models/alert.model';
import { BOMServiceHttp } from '../../core/services/bom.servicehttp';
import { PartServiceHttp } from '../../core/services/part.servicehttp';
import { RouterStepServiceHttp } from '../../core/services/routerstep.servicehttp';
import { GateKeeperService} from '../../core/shared/gatekeeper/user/user.service';
import { SortCriteria} from '../../core/models/sort.model';

declare var jQuery: any;
@Component({
  selector: 'step-bom',
  template: require('./step-bom.component.html'),
  // styles: [require('./step-bom.component.css')]
  styles: [require('./step-bom.component.css').toString()]
})
export class StepBomComponent implements OnInit, AfterContentChecked {

  public BOMInfoGroup: FormGroup = new FormGroup({
    // partNumber: new FormControl('', Validators.required),
    routerOperation: new FormControl('', Validators.required),
    quantity: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(1),
      Validators.min(1),
      Validators.pattern('[0-9]+'),
      ])),
      sizeEach: new FormControl({value: '', disabled: false}, Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.min(0.0001),
        Validators.pattern('[0-9]*\.[0-9]*'),
        ])),
      unitType: new FormControl({value: '', disabled: true}, Validators.required),
      traceType: new FormControl({value: '', disabled: true}, Validators.required),
      autoAlloc: new FormControl({value: '', disabled: true}, Validators.required),
    });
  private routerStepId: string;
  private routerId: string;
  // routerStep: IRouterStep;
  BOMItems: IBOMItem[];
  @ViewChild('StepBOMOverlay', {static: true}) bomOverlay: ElementRef;
  @ViewChild('bomList', {static: true}) bomList: ElementRef;
  @ViewChild('selectPart', {static: true}) selectPart: ShSelectComponent;
  @ViewChild('tbody', {static: true}) tbody: ElementRef;
  viewWasVisible = false;
  routerSteps: IRouterStep[];
  parts: IPart[];
  allParts: IPart[];
  sortFunc = null;
  _selectedParts: IPart[];
  get selectedParts(): IPart[] { return this._selectedParts; }
  set selectedParts(parts: IPart[]) {
    this._selectedParts = parts;    
    console.log('step-bom.component.set selectedParts ' + this.helper.isEmptyArray(parts) ? 'NULL' : parts.length);
    if (!this.helper.isEmptyArray(parts)) {
      const p = parts[0];
      //p.unit = UnitType.SQFT;
      console.log(p);
      this.patchFormGroupPart(p);
    }
   }
    bizl: StepBOMComponentBizLogic;
   userSubmitted = false;
   selectedBOMItem: IBOMItem = null;

  constructor(
    private bomService: BOMServiceHttp,
    private helper: Helper,
    private route: ActivatedRoute,
    private stepService: RouterStepServiceHttp,
    private partService: PartServiceHttp,
    private domain: RouterDomainService,
    private alert: AlertService,
  private gkAuth: GateKeeperService) { }

  get getBOMQuantityValidationMsg(){
    if (this.BOMInfoGroup.controls.quantity.errors) {
        return 'Quantity must be a whole number > 0';
    }
  }

  get getBOMSizeEachValidationMsg(){
    if (this.BOMInfoGroup.controls.sizeEach.errors) {
        return 'Size Of Each must be >= 0.0001';
    }
  }


  private get isVisible(): boolean {
    return this.bomList.nativeElement.offsetParent != null;
  }

  ngAfterContentChecked() {
    if (!this.isVisible && this.viewWasVisible) {
      console.log('step-bom.component.ngAfterContentChecked...visible to hidden');
      this.viewWasVisible = false;
      this.selectedBOMItem = null;
      this.resetFormValidation();
    } else if (this.isVisible && !this.viewWasVisible) {
        console.log('step-bom.component.ngAfterContentChecked...hidden to visible');
        this.viewWasVisible = true;
        this.stepService.getRouterSteps(this.routerId).subscribe(steps => {
          this.routerSteps = this.helper.arrayOrEmpty(steps);
          this.routerSteps.sort(this.sortStep);
          this.partService.getParts().subscribe(parts => {
            this.parts = parts;
            if (!this.helper.isEmptyString(this.routerStepId)) {
              this.sortFunc = this.sortRouterBOMItems;

              this.bomService.getRouterStepBOMItems(this.routerStepId).subscribe(bomItems => {
                console.log('step-bom.component.getRouterStepBOMItems returns count:' + (bomItems == null || bomItems.length === 0 ? '0' : bomItems.length));
                this.helper.resortList(bomItems, this.sortFunc);
                this.BOMItems = bomItems;
              });
            } else {
              this.sortFunc = this.sortRouterBOMItems;
             // this.routerStep = null;
              this.bomService.getRouterBOMItems(this.routerId).subscribe(bomItems => {
                console.log('step-bom.component.getRouteBOMItems returns count:' + (bomItems == null || bomItems.length === 0 ? '0' : bomItems.length));
                this.helper.resortList(bomItems, this.sortFunc);
                this.BOMItems = bomItems;
              });
            }
          });
        });
    }
  }

  sortStep(a: IRouterStep, b: IRouterStep) {
    return Helper.cmpNumber(a.step, b.step);
  }


  onSorted(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'step')
          {
            this.BOMItems.sort(this.sortByStepDesc)
          }
          else if (criteria.sortColumn == 'partNumber')
          {
            this.BOMItems.sort(this.sortByPartNumberDesc)
          }
          else if (criteria.sortColumn == 'partDescription')
          {
            this.BOMItems.sort(this.sortByPartDescriptionDesc)
          }
          else if (criteria.sortColumn == 'qty')
          {
            this.BOMItems.sort(this.sortByQuantityDesc)
          }

          else
          {
            this.BOMItems.sort(this.sortBySizeDesc)
          }
          
        }
        else {
          if(criteria.sortColumn == 'step')
          {
            this.BOMItems.sort(this.sortByStepAsc)
          }
          else if (criteria.sortColumn == 'partNumber')
          {
            this.BOMItems.sort(this.sortByPartNumberAsc)
          }
          else if (criteria.sortColumn == 'partDescription')
          {
            this.BOMItems.sort(this.sortByPartDescriptionAsc)
          }
          else if (criteria.sortColumn == 'qty')
          {
            this.BOMItems.sort(this.sortByQuantityAsc)
          }
          else
          {
            this.BOMItems.sort(this.sortBySizeAsc)
          }
        }
      }
      
  }

  sortByStepAsc(a1: IBOMItem, a2: IBOMItem) { 
    return Helper.cmpString(a1.step.toString(), a2.step.toString()); 
  }
  
  sortByStepDesc(a1: IBOMItem, a2: IBOMItem) {
    return Helper.cmpStringDesc(a1.step.toString(), a2.step.toString()); 
  }

  sortByPartNumberAsc(a1: IBOMItem, a2: IBOMItem){ 
    return Helper.cmpString(a1.partNumber, a2.partNumber); 
    //return a1[criteria.sortColumn] < a2[criteria.sortColumn];
  }
  
  sortByPartNumberDesc(a1: IBOMItem, a2: IBOMItem) { 
    return Helper.cmpStringDesc(a1.partNumber, a2.partNumber); 
  }  
  
  sortByPartDescriptionAsc(a1: IBOMItem, a2: IBOMItem){ 
    return Helper.cmpString(a1.partDescription, a2.partDescription); 
    //return a1[criteria.sortColumn] < a2[criteria.sortColumn];
  }
  
  sortByPartDescriptionDesc(a1: IBOMItem, a2: IBOMItem) { 
    return Helper.cmpStringDesc(a1.partDescription, a2.partDescription); 
  }    

  sortByQuantityAsc(a1: IBOMItem, a2: IBOMItem){ 
    return Helper.cmpString(a1.qty.toString(), a2.qty.toString()); 
    //return a1[criteria.sortColumn] < a2[criteria.sortColumn];
  }
  
  sortByQuantityDesc(a1: IBOMItem, a2: IBOMItem) { 
    return Helper.cmpStringDesc(a1.qty.toString(), a2.qty.toString()); 
  }
  sortBySizeAsc(a1: IBOMItem, a2: IBOMItem){ 
    return Helper.cmpString(a1.sizeEach.toString(), a2.sizeEach.toString()); 
    //return a1[criteria.sortColumn] < a2[criteria.sortColumn];
  }
  
  sortBySizeDesc(a1: IBOMItem, a2: IBOMItem) { 
    return Helper.cmpStringDesc(a1.sizeEach.toString(), a2.sizeEach.toString()); 
  }



  ngOnInit() {
     console.log('step-bom.components ngOnit');   
    this.routerId = this.route.snapshot.params['id'];
    this.routerStepId = this.route.snapshot.params['stepId'];
    this.bizl = new StepBOMComponentBizLogic(this.domain.selectedRouter, this.routerStepId, this, this.gkAuth, this.domain);
  }


  addBOMItem() {
    this.selectedBOMItem = null;
    this.stepOverlayOpen(this.bizl.NewBOMItemDefaultInfo);
  }

  goToBOMItemInfo(bomItem) {
    this.selectedBOMItem = bomItem;
    this.stepOverlayOpen(bomItem);
  }

  deleteBomItem(bomItem: IBOMItem) {
    this.alert.showYesNo('Are you sure you want to delete bom item ' + bomItem.partNumber + ' qty:' + bomItem.qty + '?',
    AlertLevel.WARNING,
    (ar: AlertReponse) => {
      console.log(ar);
      console.log(ar === AlertReponse.OK);
      console.log(AlertReponse.OK);
      if (ar === AlertReponse.YES) {
        this.bomService.deleteBOMItem(bomItem.id).subscribe(res => {
          this.BOMItems = this.BOMItems.filter(bi => bi.id !== bomItem.id);
          if (this.BOMItems == null) {
            this.BOMItems = [];
          }
        });
      }
    });
  }

  getNewBOMItem() {
    return this.fromFormValues();
  }

  stepOverlayOpen(bomItem: IBOMItem= null) {
    console.log('step-bom.component.stepOerlayOpen');
    this.patchFormGroup(bomItem);

    document.getElementById('StepBOMOverlay').style.display = 'block';
    const modal = document.getElementById('StepBOMOverlay');
    let selectedBOMItem = this.selectedBOMItem;

    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = 'none';
            selectedBOMItem = null;
        }
    };
  }

closeDetailsView() {
  // console.log('step-bom.component.closeDetailsView');
  this.bomOverlay.nativeElement.style.display = 'none';
  this.selectedBOMItem = null;
}

fromFormValues(): IBOMItem {
  const info =  this.BOMInfoGroup.getRawValue();
  const p: IPart = (!this.helper.isEmptyArray(this.selectedParts) ?  this.selectedParts[0] : null);

  return {
    id : this.selectedBOMItem != null ? this.selectedBOMItem.id : null,
    routerStepId : (info.routerOperation != null ? info.routerOperation.id : null),
    step : (info.routerOperation != null ? info.routerOperation.step : 0),
    stepDescription: (info.routerOperation != null ? info.routerOperation.stepDescription : ''),
    partNumber : (p != null ? p.number : null),
    partId : (p != null ? p.id : null),
    partDescription : (p != null ? p.description : null),
    partUnitType : (p != null ? p.unit : null),
    partTraceType : (p != null ? p.trace : null),
    partType : (p != null ? p.type : null),
    qty: info.quantity,
    sizeEach : info.sizeEach,
    autoAlloc : info.autoAlloc
  };
}

get shouldShowRouterStep(): boolean{
  return this.helper.isEmptyString(this.routerStepId);
}

validateQuantity() {
  return !this.bizl.isQuantityEditable ||
  (this.bizl.isNewBOMItem && !this.userSubmitted) ||
  (!this.bizl.isNewBOMItem && this.BOMInfoGroup.controls.quantity.pristine) ||
   this.BOMInfoGroup.controls.quantity.valid;
}

validateSizeEach() {
  return  !this.bizl.isSizeEachEditable ||
  (this.bizl.isNewBOMItem && !this.userSubmitted) ||
  (!this.bizl.isNewBOMItem && this.BOMInfoGroup.controls.sizeEach.pristine) ||
   this.BOMInfoGroup.controls.sizeEach.valid;
}

validateRouterOperation() {
  return !this.bizl.isRouterOperationEditable ||
  (this.bizl.isNewBOMItem && !this.userSubmitted) ||
  (!this.bizl.isNewBOMItem && this.BOMInfoGroup.controls.routerOperation.pristine) ||
   this.BOMInfoGroup.controls.routerOperation.valid;
}

selectPartClickHandler(event) {
  console.log('router-info.selectPartClickHandler');
  this.selectPart.error = false;
}

validatePart() {
  console.log('router-info.component.validatePart');
  console.log(this.selectedParts);
  console.log(this.helper.isEmptyArray(this.selectedParts));
  return  !this.helper.isEmptyArray(this.selectedParts);
}

validateBOMFormInfo() {
  if (this.validatePart()) {
    this.selectPart.error = false;
  } else {
    this.selectPart.error = true;
  }
  console.log('step-bom.component.validateBOMInfo valid:' + this.BOMInfoGroup.valid + ' error:' + this.selectPart.error);
  console.log(this.BOMInfoGroup);
  return this.BOMInfoGroup.valid && !this.selectPart.error;
}

resetFormValidation() {
  this.userSubmitted = false;
  this.selectPart.error = false;
  // yourForm.reset(), yourForm.resetForm() don't work, but this does:
  this.BOMInfoGroup.markAsPristine();
  this.BOMInfoGroup.markAsUntouched();
  this.BOMInfoGroup.updateValueAndValidity();
}

submit() {
  console.log('step-bom.component.onSubmit');
  this.userSubmitted = true;
  const b: IBOMItem = this.fromFormValues();

  if (!this.validateBOMFormInfo()) {
    return null;
  }

  if (this.helper.isExistingItem(b)) {
    console.log('step-bom.component.onSubmit...updating bomitem:');
    console.log(b);
    this.bomService.updateBOMItem(b).subscribe(updatedItem => {
      console.log('step-bom.component.onSubmit....updated:' + updatedItem.id);
      // this.helper.replaceItemInList(this.BOMItems, updatedItem);
      this.BOMItems = this.BOMItems.filter(x => x.id !== updatedItem.id);
      this.BOMItems.push(updatedItem);
      this.helper.resortList(this.BOMItems, this.sortFunc);
      this.closeDetailsView();
    });
  } else {
    console.log('step-bom.component.onSubmit....creating bomitem');
    console.log(b);
    this.bomService.createBOMItem(b).subscribe(newBOM => {
      console.log('step-bom.component.onSubmit....created:' + b.partNumber);
      if (!this.BOMItems) {
        this.BOMItems = [];
      }
      this.BOMItems.push(newBOM);
      this.helper.resortList(this.BOMItems, this.sortFunc);
      this.closeDetailsView();
    });
  }
}

getTitle(): string {
  if (this.bizl.isNewBOMItem) {
    return 'Add BOM Item';
  } else if (this.bizl.isBOMItemEditable) {
    return 'Edit BOM Item';
  } else {
    return 'BOM Info';
  }
}

private patchFormGroupPart(p: IPart) {
  console.log('step-bom-components.patchFormGroup');

  this.BOMInfoGroup.patchValue({
    unitType: p.unit,
    traceType: p.trace
  });

  if (p.type === PartType.DISCRETE) {

    this.BOMInfoGroup.patchValue({
     sizeEach: 1    
    });
  } else {    
  }

  const b = this.fromFormValues();

  this.updateFormControls();
}

private getRouterOperation(b: IBOMItem): IRouterStep {
  if (b != null && !this.helper.isEmptyString(b.routerStepId) && !this.helper.isEmptyArray(this.routerSteps)) {
    return this.routerSteps.find(x => x.id === b.routerStepId);
  } else {
    return null;
  }
}

updateFormPart(id: string) {
  if (!this.helper.isEmptyString(id) && !this.helper.isEmptyArray(this.parts)) {
    const p = this.parts.find(x => x.id === id);
    this.selectedParts = [p];
  } else {
    this.selectedParts = [];
  }
}

private patchFormGroup(b: IBOMItem) {
  console.log('step-bom-components.patchFormGroup');

  this.BOMInfoGroup.patchValue({
    routerOperation: this.getRouterOperation(b),
    quantity: b.qty,
    sizeEach: b.sizeEach,
    unitType: b.partUnitType,
    traceType: b.partTraceType,
    autoAlloc: b.autoAlloc
  });

  this.updateFormPart(b.partId);

  this.updateFormControls();
}

updateFormControls() {
  console.log('step-bom.component.updateFormControls');
   console.log(this.bizl);

  if (this.bizl.isPartEditable) {
    this.selectPart.disabled = false;
  } else {
    this.selectPart.disabled = true;
  }

  if (this.bizl.isRouterOperationEditable) {
    this.BOMInfoGroup.controls.routerOperation.enable();
  } else {
    this.BOMInfoGroup.controls.routerOperation.disable();
  }

  if (this.bizl.isQuantityEditable) {
    this.BOMInfoGroup.controls.quantity.enable();
  } else {
    this.BOMInfoGroup.controls.quantity.disable();
  }

  if (this.bizl.isSizeEachEditable) {
    this.BOMInfoGroup.controls.sizeEach.enable();
  } else {
    this.BOMInfoGroup.controls.sizeEach.disable();
  }

  if (this.bizl.isUnitTypeEditable) {
    this.BOMInfoGroup.controls.unitType.enable();
  } else {
    this.BOMInfoGroup.controls.unitType.disable();
  }

  if (this.bizl.isTraceTypeEditable) {
    this.BOMInfoGroup.controls.traceType.enable();
  } else {
    this.BOMInfoGroup.controls.traceType.disable();
  }

  if (this.bizl.isAutoAllocEditable) {
    this.BOMInfoGroup.controls.autoAlloc.enable();
  } else {
    this.BOMInfoGroup.controls.autoAlloc.disable();
  }
}

sortRouterBOMItems(x1, x2) {
  if (x1.step < x2.step) {
    return -1;
  } else if (x1.step > x2.step) {
    return 1;
  } else {
    if (x1.partNumber < x2.partNumber) {
      return -1;
    } else if ( x1.partNumber > x2.partNumber) {
      return 1;
    } else {
      return 0;
    }
  }
}

sortParts(x1, x2) {

    if (x1.number < x2.number) {
      return -1;
    } else if ( x1.number > x2.number) {
      return 1;
    } else {
      return 0;
    }
}

sortStepBOMItems(x1, x2) {
    if (x1.order < x2.order) {
      return -1;
    } else if (x1.order > x2.order) {
      return 1;
    } else {
      return 0;
    }
  }
}
