import { Injectable } from '@angular/core';
import "rxjs/add/observable/of";
import { Helper } from './helper.service';
import 'rxjs/add/observable/throw';
import { IFile, IFileExtended } from '../models/file.model';
import { IMyCache } from '../../imycache';
import { BrowserCacheService } from '../../browser-cache.service.service';
import { MemoryCacheService } from './memory.cache.service';
import { TDiff } from './file.cache.service';
import { isFileExcluded } from 'tslint/lib/configuration';
import internal = require('assert');

@Injectable()
export class FileCacheService1 implements IMyCache {

  CACHE_KEY = 'lsFilesKey';
  LASTUPDATE_KEY = 'lsFilesLastUpdateKey';
  //LASTRESET_TIMESTAMP_KEY = 'lsFilesLastResetTimestampKey';

  //GETCHANGES_INTERVAL = 1000 * 60 * 5; // milliseconds
  //RESET_INTERVAL = 1000 * 60 * 60 * 24 * 25; // retrieve all parts 

  private td: TDiff = new TDiff();
  private _cache:IMyCache = null;
  private _data:IFileExtended[] = [];

  constructor(
    private utils: Helper
    ) {
    //console.log('file.cache.service.CONSTRUCTOR');
    this._cache = 
      new MemoryCacheService(
        utils, 
        new BrowserCacheService(this.CACHE_KEY, this.LASTUPDATE_KEY));
  }
  
  // data will always contain the latest active file versions and, if relevant,
  //active = false files that indicate to delete all versions for that spec name
  update(data: IFileExtended[], lastUpdateTime: number): IFileExtended[] {
    console.log('FileCacheService.update');
    //console.log(data);

    var updates:IFileExtended[] = [];
    
    // we want to delete active previous versions of the files we just pulled in
    if ( !this.utils.isEmptyArray(data))
    {
      var files = <IFileExtended[]>this._cache.getAll();
      for (const u of data)
      {
        var t = files.filter(x => Helper.cmpString(x.specificationName,u.specificationName) == 0);
        if (!this.utils.isEmptyArray(t)){
          for (var i = 0; i< t.length; i++)
              t[i].active = false;
          updates = updates.concat(t);
        }
        
        if (!u.active)
          continue;
         
        updates.push(u);
      }
    }
    return <IFileExtended[]>this._cache.update(updates, lastUpdateTime);
  }

 
  set lastUpdate(val: number) {
    this._cache.lastUpdate = val;
  }

  get lastUpdate():number {
    return this._cache.lastUpdate;
  }

  getAll(): IFileExtended[] {
    this._cache.getAll();
    //returning empty tells client to call API to get files since cache.lastTimeStamp
    //the biz policy is for the client to do this every time it wants the files
    return [];
  }

  clear(): void {
    throw new Error('Method not implemented.');
  }

  load(data: IFileExtended[]): void {
    throw new Error('Method not implemented.');
  }

  get(id: string): IFileExtended {
    throw new Error('Method not implemented.');
  }
  set(data: any) {
    throw new Error('Method not implemented.');
  }
}




