import { RouterDomainService } from '../../core/services/router-domain.service';
import { Component, OnInit, OnDestroy, AfterContentChecked, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators, FormsModule } from '@angular/forms';
import { GateKeeperService} from '../../core/shared/gatekeeper/user/user.service';
import { ActivatedRoute } from '@angular/router';
import { RouterNotesComponentBizLogic } from './router-notes.component.bizl';
import { RouterServiceHttp } from '../../core/services/router.servicehttp';
import { UserMaintenanceServiceHttp } from '../../core/services/usermaintenance.servicehttp';


@Component({
  selector: 'router-notes',
  template: require('./router-notes.component.html'),
  // styles: [require('./router-notes.component.css')]
  styles: [require('./router-notes.component.css').toString()]
})

export class RouterNotesComponent implements OnInit, OnDestroy, AfterContentChecked {
  RouterNotesGroup: FormGroup;
  routerNotes: FormControl;
  routerId: string;
  @ViewChild('routerNotesContainer' , {static: true}) m;
  notesViewWasVisible = false;
  bizl: RouterNotesComponentBizLogic;
  //originalNotes: string = '';
  currentNotes: string = '';
  
  constructor(private routerService: RouterServiceHttp, 
              private route: ActivatedRoute,
              private domain: RouterDomainService,
              private gkauth: GateKeeperService,
              private userMaintenanceService: UserMaintenanceServiceHttp) { }

  get hasChanges(){
    //return this.originalNotes !== this.newNotes;
    return this.currentNotes !== this.newNotes;
  }

  get newNotes(): string {
    return this.routerNotes != null ? this.routerNotes.value : '';
  }

  ngOnInit() {
    console.log('router-notes.ngOnInit');

    this.routerId = this.route.snapshot.params['id'];
    this.bizl = new RouterNotesComponentBizLogic(this.domain.selectedRouter, this.domain );

    this.routerNotes = new FormControl({disabled: !this.bizl.areNotesEditable}, Validators.compose([
      Validators.required,
    ]));

    this.RouterNotesGroup = new FormGroup({
      routerNotes: this.routerNotes
    });

    this.userMaintenanceService.initUserInfo();
    
    this.routerService.getRouterNotes(this.routerId).subscribe(notes => {
      // this.originalNotes = notes;
      // this.RouterNotesGroup.patchValue({
      //   routerNotes: notes
      // });
      this.currentNotes = notes;
    });

    this.RouterNotesGroup.setValue({
      routerNotes: ''
    });

    
  }

  submit(routerNotes) {
  }

  ngOnDestroy() {
    console.log('router-notes.ngOnDestroy');
    /*
    const notes = this.newNotes;
    console.log('...updating notes:' + notes);
    this.routerService.updateRouterNotes(this.routerId, notes ).subscribe(res => {
      console.log(res);
    });*/
  }

  private get isVisible(): boolean {
    return this.m.nativeElement.offsetParent != null;
  }

  ngAfterContentChecked() {
    var addNotes: string = this.newNotes;
    if (!this.isVisible && this.notesViewWasVisible) {
      console.log('router-notes.ngAfterContentChecked...visible to hidden');
      this.notesViewWasVisible = false;
      //if (this.hasChanges) {
      if (addNotes.trim() != '') {
        console.log('router-notes.ngAfterContentChecked...updating notes:' + this.newNotes);
        addNotes = this.userMaintenanceService.concatLines(true, addNotes);
        this.routerService.updateRouterNotes(this.routerId, addNotes ).subscribe(res => {          
          console.log(res);
        });
        this.RouterNotesGroup.setValue({
          routerNotes: ''
        });
        this.currentNotes = this.userMaintenanceService.concatLines(false, addNotes, this.currentNotes);
      }
    } else if (this.isVisible && !this.notesViewWasVisible) {
      console.log('router-notes.ngAfterContentChecked...hidden to visible');
      this.bizl = new RouterNotesComponentBizLogic(this.domain.selectedRouter, this.domain );
      if (this.bizl.areNotesEditable) {
        this.routerNotes.enable();
      } else {
        this.routerNotes.disable();
      }
      this.notesViewWasVisible = true;
    }
  }
 
}
