import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FilesComponent } from '../files/files.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([
            {path: 'files', component: FilesComponent, data: {breadcrumb: 'Files Maintenance'}}
        ])
    ],
    declarations: []
})
export class FilesModule { }