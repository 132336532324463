import { UnitType, UnitTypeService, UnitTypeUtils } from '../enums/unittype';


export interface ITest {
    id: string;
    routerStepId: string;
    step: number;
    stepDescription: string;
    specificationId: string;
    specificationName: string;
    description: string;
    type: TestType;
    min: number;
    max: number;
    frequency: number;
    notes: string;
    unitType: UnitType;
    testOrder: number;
    testDeviceType: TestDeviceType;
    testDeviceCount: number;
}

export interface IFileTest {
  specificationName: string;
  id: string;
  description: string;
  type: TestType;
  min: number;
  max: number;
  frequency: number;
  notes: string;
  unitType: UnitType;
  testOrder: number;
  testDeviceType: TestDeviceType;
  testDeviceCount: number;
}

export class IFileTest {
  specificationName: string;
  id: string;
  description: string;
  type: TestType;
  min: number;
  max: number;
  frequency: number;
  notes: string;
  unitType: UnitType;
  testOrder: number;
  testDeviceType: TestDeviceType;
  testDeviceCount: number;
}

enum TestTypeService {
  UNKNOWN = 0,
  IPI = 1,
  NDE = 2,
  HYDROSTATIC = 3
}

export enum TestType {
  UNKNOWN = 'unknown',
  IPI = 'ipi',
  NDE = 'nde',
  HYDROSTATIC = 'hydrostatic'
}

export class TestUtils {
  public static toServerInfo(t: ITest): any {
    return {
      id: t.id, // !== null ? t.id : "",
      routerStepId: t.routerStepId,
      step: t.step,
      stepDescription: t.stepDescription,
      specificationId: t.specificationId,
      description: t.description,
      type: TestTypeUtils.toTestTypeService(t.type),
      min: t.min,
      max: t.max,
      frequency: t.frequency,
      notes: t.notes,
      unitType: UnitTypeUtils.toUnitTypeService(t.unitType),
      testOrder: t.testOrder,
      testDeviceType: TestDeviceTypeUtils.toTestDeviceTypeService(t.testDeviceType),
      testDeviceCount: t.testDeviceCount,
      userId: null
    };
  }

  public static toServerInfo1(si: any, t: ITest): any {
    si.id = t.id !== null ? t.id : "";
    si.routerStepId = t.routerStepId;
    si.step = t.step;
    si.stepDescription = t.stepDescription;
    si.specificationId = t.specificationId;
    si.description = t.description;
    si.type = TestTypeUtils.toTestTypeService(t.type);
    si.min = t.min;
    si.max = t.max;
    si.frequency = t.frequency;
    si.notes = t.notes;
    si.unitType = UnitTypeUtils.toUnitTypeService(t.unitType);
    si.testOrder = t.testOrder;
    si.testDeviceType = TestDeviceTypeUtils.toTestDeviceTypeService(t.testDeviceType);
    si.testDeviceCount = t.testDeviceCount;

    return si;
  }

  public static areTestsEqual(t1: ITest, t2: ITest) {
    //console.log('test.model areTestsEqual');
    //console.log(t1);
    //console.log(t2);
    if (t1 == null) {
      return t2 == null;
    }

    if (t2 == null) {
      return false;
    }

    const ret = t1.id == t2.id &&
      t1.routerStepId == t2.routerStepId &&
      t1.step == t2.step &&
      t1.specificationId == t2.specificationId &&
      t1.description == t2.description &&
      t1.type == t2.type &&
      t1.min == t2.min &&
      t1.max == t2.max &&
      t1.frequency == t2.frequency &&
      t1.notes == t2.notes &&
      t1.unitType == t2.unitType &&
      t1.testDeviceType == t2.testDeviceType &&
      t1.testDeviceCount == t2.testDeviceCount;

      console.log('test.model.areEqual:' + ret);
      return ret;
 }

  public static toITest(info: any): ITest {
    info.type = TestTypeUtils.toTestType(info.type);
    info.unitType = UnitTypeUtils.toUnitType(info.unitType);
    info.testDeviceType = TestDeviceTypeUtils.toTestDeviceType(info.testDeviceType);
    return <ITest> info;
  }
}

export class FileTestUtils {
  public static toServerInfo(si: any, t: IFileTest): any {
    si.id =  t.id !== null ? t.id : "";
    si.specificationName = t.specificationName;
    si.description = t.description;
    si.type = TestTypeUtils.toTestTypeService(t.type);
    si.min = t.min;
    si.max = t.max;
    si.frequency = t.frequency;
    si.notes = t.notes;
    si.unitType = UnitTypeUtils.toUnitTypeService(t.unitType);
    si.testOrder =  t.testOrder;
    si.testDeviceType = TestDeviceTypeUtils.toTestDeviceTypeService(t.testDeviceType);
    si.testDeviceCount = t.testDeviceCount;
    return si;
  }

  public static toServerInfos(tests: IFileTest[]): any[] {
    const ret: any[] = [];

    for (const test of tests) {
      ret.push(FileTestUtils.toServerInfo({}, test));
    }
    return ret;
  }

  public static areEqual(a: IFileTest, b: IFileTest) {
    const ret = a.id == b.id &&
    a.specificationName == b.specificationName &&
    a.description == b.description &&
    a.type == b.type &&
    a.min == b.min &&
    a.max == b.max &&
    a.frequency == b.frequency &&
    a.notes == b.notes &&
    a.unitType == b.unitType &&
    a.testDeviceType == b.testDeviceType &&
    a.testDeviceCount == b.testDeviceCount;
    return ret;
  }

  public static toIFileTest(info: any): IFileTest {
    info.type = TestTypeUtils.toTestType(info.type);
    info.unitType = UnitTypeUtils.toUnitType(info.unitType);
    info.testDeviceType = TestDeviceTypeUtils.toTestDeviceType(info.testDeviceType);
    return <IFileTest> info;
  }
}

export class TestTypeUtils {

  public static get types(): TestType[] {
    const ret: TestType[] = [];
    ret.push(TestType.IPI);
    ret.push(TestType.NDE);
    ret.push(TestType.HYDROSTATIC);
    return ret;
  }

  public static toTestType(type: TestTypeService): TestType {
    switch (type) {
      case TestTypeService.IPI:
        return TestType.IPI;
      case TestTypeService.NDE:
        return TestType.NDE;
      case TestTypeService.HYDROSTATIC:
        return TestType.HYDROSTATIC;
      default:
        return TestType.UNKNOWN;
    }
  }

  public static toTestTypeService(type: TestType): TestTypeService {
    switch (type) {
      case TestType.IPI:
        return TestTypeService.IPI;
      case TestType.NDE:
        return TestTypeService.NDE;
        case TestType.HYDROSTATIC:
        return TestTypeService.HYDROSTATIC;
      default:
        return TestTypeService.UNKNOWN;
    }
  }
}

enum TestDeviceTypeService {
  UNKNOWN = -1,
  NONE = 0,
  SERIALIZED = 1
}

export enum TestDeviceType {
  UNKNOWN = 'unknown',
  NONE = 'none',
  SERIALIZED = 'serialized'
}

export class TestDeviceTypeUtils {

  public static get types(): TestDeviceType[] {
    const ret: TestDeviceType[] = [];
    ret.push(TestDeviceType.SERIALIZED);
    ret.push(TestDeviceType.NONE);
    return ret;
  }

  public static toTestDeviceType(type: TestDeviceTypeService): TestDeviceType {
    switch (type) {
      case TestDeviceTypeService.NONE:
        return TestDeviceType.NONE;
      case TestDeviceTypeService.SERIALIZED:
        return TestDeviceType.SERIALIZED;
      default:
        return TestDeviceType.UNKNOWN;
    }
  }

  public static toTestDeviceTypeService(type: TestDeviceType): TestDeviceTypeService {
    switch (type) {
      case TestDeviceType.NONE:
        return TestDeviceTypeService.NONE;
      case TestDeviceType.SERIALIZED:
        return TestDeviceTypeService.SERIALIZED;
      default:
        return TestDeviceTypeService.UNKNOWN;
    }
  }
}
