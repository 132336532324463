import { FormControl } from "@angular/forms";
import { Helper } from "../services/helper.service";

  export function restrictedCharacters(characters:string[]) {
    return (control: FormControl): {[key: string]: any} => {
      if (!characters || !control.value)
        return null;
      
      var invalidCharacters = characters
      .map(w => control.value.includes(w) ? w : null)
      .filter(w => w != null);
        
      return !Helper.sIsEmptyArray(invalidCharacters) ? {'restrictedCharacters': invalidCharacters.join(', ') } : null;
    }
  }