import { Component, OnInit, AfterViewChecked, AfterViewInit, AfterContentInit, ViewChild, ElementRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IPart, IEtchingInfo } from '../core/models/part.model';
import { PartServiceHttp } from '../core/services/part.servicehttp';
import { Helper } from '../core/services/helper.service';
import { SortCriteria} from '../core/models/sort.model';
import {PartDomainService} from '../core/services/part-domain.service'
import { FormGroup } from '@angular/forms';
import { UserMaintenanceServiceHttp } from '../core/services/usermaintenance.servicehttp';

@Component({
  selector: 'part-info',
  template: require('./part-info.component.html'),
  // styles: [require('./part-info.component.css')]
  styles: [require('./part-info.component.css').toString()]
})
export class PartInfoComponent implements OnInit, AfterViewInit, AfterViewChecked, AfterContentInit {

  @ViewChild('table',{static: true}) table: ElementRef;
  parts: IPart[];
  fullParts: Array<IPart> = [];
  public term: string;
  dir ='asc';
  PartEtchingFormGroup: FormGroup;
  private etchingInfo: IEtchingInfo;

  get selectedPart() {
    return this.partDomain.selectedPart;
  }

  set selectedPart(val: IPart){
    this.partDomain.selectedPart = val;        
  }

  constructor(
      private userMaintenance:UserMaintenanceServiceHttp,
      private partService: PartServiceHttp, 
      private partDomain: PartDomainService, 
      private route: ActivatedRoute, 
      private Helper: Helper, 
      private navPart: Router) 
      { }

  ngOnInit() {

    this.userMaintenance.initUserInfo();

    this.partService.getParts().subscribe(parts => {
      this.parts = parts;
      this.fullParts = this.parts;
     });
  }  

   onSorted(e: Event) {  
  
    var elementId: string = (event.target as Element).id;

    if(elementId == 'col1')
    {   
        if ( this.dir == 'asc')   
        {
          this.dir = 'desc';
          this.parts = [...this.fullParts.sort(this.sortByNumberAsc)]; 
          //this.parts.sort(this.sortByNumberAsc)    
        }   
        else 
        {
          this.dir = 'asc';
          this.parts = [...this.fullParts.sort(this.sortByNumberDesc)]; 
          //this.parts.sort(this.sortByNumberDesc)              
        } 
    }    
    
    if(elementId == 'col2')
    {   
        if ( this.dir == 'asc')   
        {
          this.dir = 'desc';
          this.parts = [...this.fullParts.sort(this.sortByDescriptionAsc)]; 
          //this.parts.sort(this.sortByDescriptionAsc)    
        }   
        else 
        {
          this.dir = 'asc';
          this.parts = [...this.fullParts.sort(this.sortByDescriptionDesc)]; 
          //this.parts.sort(this.sortByDescriptionDesc)            
        } 
    }     

    if(elementId == 'col3')
    {   
        if ( this.dir == 'asc')   
        {
          this.dir = 'desc';
          this.parts = [...this.fullParts.sort(this.sortByTypeAsc)]; 
          //this.parts.sort(this.sortByTypeAsc)    
        }   
        else 
        {
          this.dir = 'asc';
          this.parts = [...this.fullParts.sort(this.sortByTypeDesc)]; 
          //this.parts.sort(this.sortByTypeDesc)            
        } 
    }     

    if(elementId == 'col4')
    {   
        if ( this.dir == 'asc')   
        {
          this.dir = 'desc';
          this.parts = [...this.fullParts.sort(this.sortByTraceAsc)]; 
          //this.parts.sort(this.sortByTraceAsc)    
        }   
        else 
        {
          this.dir = 'asc';
          this.parts = [...this.fullParts.sort(this.sortByTraceDesc)]; 
          //this.parts.sort(this.sortByTraceDesc)            
        } 
    }    
   }
  
  
  sortByNumberAsc(a1: IPart, a2: IPart) { 
    return Helper.cmpString(a1.number, a2.number); 
  }
  
  sortByNumberDesc(a1: IPart, a2: IPart) { 
    return Helper.cmpStringDesc(a1.number, a2.number); 
  }

  
  sortByDescriptionAsc(a1: IPart, a2: IPart) { 
    return Helper.cmpString(a1.description, a2.description); 
  }
  
  sortByDescriptionDesc(a1: IPart, a2: IPart) { 
    return Helper.cmpStringDesc(a1.description, a2.description); 
  }
  
  sortByTypeAsc(a1: IPart, a2: IPart) { 
    return Helper.cmpString(a1.type, a2.type); 
  }
  
  sortByTypeDesc(a1: IPart, a2: IPart) {
    return Helper.cmpStringDesc(a1.type, a2.type); 
  } 

  sortByTraceAsc(a1: IPart, a2: IPart) { 
    return Helper.cmpString(a1.trace, a2.trace); 
  }
  
  sortByTraceDesc(a1: IPart, a2: IPart) {
    return Helper.cmpStringDesc(a1.trace, a2.trace); 
  }  

  editPart(part: IPart) {
    // go to edit part nav
    // add children later    ([''],{relativeTo: this.route})
    this.partDomain.selectedPart = part;
    this.navPart.navigate(['/parts', part.id]);
  }

  copyPart(part: IPart)
  {   
   this.overlayOpen(part);
  }

  closeOverlay() {    
    //this.userResponse(false);
    //this.selectedProgram = null;
    // this.sortTable(0);
    document.getElementById('PartCopyOverlay').style.display = 'none';
  }
  overlayOpen(part: IPart) {
    this.selectedPart = part;
    this.partDomain.selectedPart = part;
    //this.toFormGroup(part);
    const _self = this;
    //console.log(this.ProgramFormGroup);
    document.getElementById('PartCopyOverlay').style.display = 'block';
    const modal = document.getElementById('PartCopyOverlay');
    //this.inputNumber.nativeElement.focus();
    // When the user clicks anywhere outside of the modal, this closes it
    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = 'none';
            //_self.selectedProgram = null;
        }
    };
  }

  // toFormGroup(part: IPart) {
  //   this.PartEtchingFormGroup.patchValue({
  //       number: program.number,
  //       description: program.description
  //   });    
  //   this.PartEtchingFormGroup.controls.description.enable();
  // }

  ngAfterViewChecked() {
    //console.log('part-info.component.AfterViewChecked');
    const c1 = $('.col1').width();
    //console.log(c1);
    const c2 = $('.col2').width();
    const c3 = $('.col3').width();
    const c4 = $('.col4').width();

    $('#col1').width(c1 + 1);
    $('#col2').width(c2);
    $('#col3').width(c3);
    $('#col4').width(c4);
    $('.SearchBar').width(c1 + c2 + c3 + c4 + 24);

    var th = $('thead').width();
    $('#Cover').width(th);
  }
  ngAfterViewInit() {

    console.log('part-info.Component.AfterViewInit');
    // runs every time the user re-sizes the window
        $(window).resize(function(){
      // gets td column widths
        const c1 = $('.col1').width();
        const c2 = $('.col2').width();
        const c3 = $('.col3').width();
        const c4 = $('.col4').width();
        var th = $('thead').width();
      // sets th column widths to match td's
        $('#Cover').width(th + 7);
        $('#col1').width(c1 + 1);
        $('#col2').width(c2);
        $('#col3').width(c3);
        $('#col4').width(c4);
        $('.SearchBar').width(c1 + c2 + c3 + c4 + 24);
        console.log ('resize header occured');
        });
  }
  ngAfterContentInit() {
    $(document).ready(function(){
      $('.SearchField').focus();
    });
  }
}
