import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import "rxjs/add/observable/of";
import { IWorkStation} from '../models/workstation.model';
import { MockDataService} from './mock-data.service';




@Injectable()
export class WorkStationService {


  constructor(private data: MockDataService) { }


    createWorkStation(workStation: IWorkStation): Observable<IWorkStation> {
      const ws = this.data.createWorkStation(workStation);
      return Observable.of(ws);
    }

    updateWorkStation(workStation: IWorkStation): Observable<IWorkStation> {
      const ws = this.data.updateWorkStation(workStation);
      return Observable.of(ws);
    }

    getWorkStationsForWorkCenter(workCenterId: string): Observable<IWorkStation[]> {

      const stations = this.data.getWorkStationsForWorkCenter(workCenterId);

      return Observable.of(stations);

    }
    deleteWorkStation(workStationId: string): Observable<number> {
      const ret = this.data.deleteWorkStation(workStationId);
      return Observable.of(ret);
    }

}






