import { Component, OnInit } from '@angular/core';
import { IPart, IEtchingInfo } from '../../core/models/part.model';
import {PartDomainService} from '../../core/services/part-domain.service'
import { PartServiceHttp } from '../../core/services/part.servicehttp';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'part-info-tabs',
  template: require('./part-info-tabs.component.html'),
  // styles: [require('./part-info-tabs.component.css')]
  styles: [require('./part-info-tabs.component.css').toString()]
})
export class PartInfoTabsComponent implements OnInit {
  partNDERequired: boolean;
  selectedPart: IPart;

  constructor(private partService: PartServiceHttp, private partDomain: PartDomainService, private route: ActivatedRoute) { }

  ngOnInit() {
    //-- Get Part NDE Required
    this.partService.GetPartNDERequired(this.route.snapshot.params['id']).subscribe(partNDERequired => {
      this.partNDERequired = partNDERequired;
    });

    this.selectedPart = this.partDomain.selectedPart;
  }

  tabSwitch(evt, tabName) {
    let i, x, tablinks;
    x = document.getElementsByClassName('tab');
    for ( i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
    }
    tablinks = document.getElementsByClassName('tabLink');
    for (i = 0; i < x.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' tabColor', '');
    }
    document.getElementById(tabName).style.display = 'block';
    evt.currentTarget.className += ' tabColor';
  }
}
