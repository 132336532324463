import { Helper } from '../services/helper.service';
export interface IReport {
    id: string;
    name: string;
    description: string;
    inUse: boolean;
  }

export interface IDemandItemNoBOMReport {
    itemnumber: string;    
    itemdescription: string;
    reported: Date;
  }

  export interface IDemandItemNoDrawingReport {
    itemnumber: string;    
    itemdescription: string;
    reported: Date;
  }

  export interface IDemandItemNoRouterReport {
    itemnumber: string;    
    itemdescription: string;
    reported: Date;
  }

  export interface IDemandItemRouterOnHoldReport {
    itemnumber: string;    
    itemdescription: string;
    code: string,
    reported: Date;
    datetype: string
  }



export class ReportUtils {
  public static areEqual( p1: IReport, p2: IReport) {
    if (p1 == null) {
      return p2 == null;
    }

    if (p2 ==  null) {
      return false;
    }

    return  Helper.areEqualStr(p1.id, p2.id) &&
      Helper.areEqualStr(p1.description, p2.description) &&
      Helper.areEqualStr(p1.name, p2.name);
  }
}
