import { arch } from 'os';
import { AlertLevel, AlertReponse } from '../core/models/alert.model';
import { AlertService } from '../core/services/alert.service';
import { RouterInfoComponent } from '../router-detail/router-info/router-info.component';
import {
        AfterContentInit,
        Component,
        ElementRef,
        EventEmitter,
        Input,
        OnDestroy,
        OnInit,
        Output,
        ViewChild,
        HostListener,
        NgZone,
        Renderer2,
       } from '@angular/core';
import {NgModel} from '@angular/forms';
import { RouterServiceHttp } from '../core/services/router.servicehttp';
import { IRouter, IRouterVersionInfo } from '../core/models/router.model';
import { FilterPipe } from '../core/pipes/filter.pipe';
import { RouterValidationService } from '../core/services/router-validation.service';
import { Router } from '@angular/router';
import { RouterState } from '../core/enums/router-state';
import { Helper} from '../core/services/helper.service';
import { RouterDomainService } from '../core/services/router-domain.service';
import { RouterDetailsValidator } from '../router-detail/router-details.validator';
import { AuthService } from '../core/services/auth.service';
import { PartServiceHttp } from '../core/services/part.servicehttp';
import { GateKeeperService} from '../core/shared/gatekeeper/user/user.service';
import { SortCriteria} from '../core/models/sort.model';
import { Observable } from 'rxjs';
import { UserMaintenanceServiceHttp } from '../core/services/usermaintenance.servicehttp';
import { IReasonCode } from '../core/models/reasoncode.model';
import { ReasonCodeServiceHttp } from '../core/services/reasoncode.servicehttp';
import { DDLDelegate } from '../core/ngbootstrap/ngbdropdown.component';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { IUserInfo } from '../core/models/usermaintenance.model';

@Component({
    selector: 'router-listing',
    template: require('./router-listing.component.html'),
    // styles: [require('./router-listing.component.css')]
    styles: [require('./router-listing.component.css').toString()]    
})

export class RouterListingComponent implements DDLDelegate,OnInit, OnDestroy, AfterContentInit {
  @ViewChild('RouterListingOverlay', {static: true}) routerView: ElementRef;
  @ViewChild('NotesOverlay', {static: true}) notesView: ElementRef;
  @ViewChild('CurrentNotesDiv', {static: true}) currentNotesDiv: ElementRef;
  @ViewChild('routerInfo', {static: true}) routerInfo: RouterInfoComponent;
  @ViewChild('table', {static: true}) table: ElementRef;
  @ViewChild('newNotesArea', {static: true}) newNotes: ElementRef;
  @ViewChild('reasonDDL',{static:true}) reasonDDL:ElementRef;
  @ViewChild('reasonCodeValidationLbl', {static: false}) reasonCodeValidationLbl: ElementRef;
  @ViewChild(CdkVirtualScrollViewport) private readonly viewPort: CdkVirtualScrollViewport;
  public includeInactive:boolean = false;
  private _userSubmitted = false;
  routers: IRouter[];
  //fullRouters: IRouter[];
  fullRouters: Array<IRouter> = [];        
  @Output() router = new EventEmitter();
  @Output() ddlName:string ;
  dir = 'asc';
  currentNotes = '';
  editedRouter:IRouter = null;
  userName:string = '';
  notesArray: string[] = null;
  notesAdded: Boolean = false; 
  reasonCodes: IReasonCode[]; 
  reasons: string[]; 
  items: string[];  
  requiredReasonAdded:Boolean = false;
  notesChanged:Boolean = false;
  isOnHold:Boolean = false;
  copyRouterId = null;
  reasonCodeValidationMsg: string = '';
  currentDate: any = new Date().toLocaleDateString();


  get userSubmitted()
  {
    return this.userSubmitted;
  }

  set userSubmitted(val:boolean)
  {
    this._userSubmitted = val;
    this.routerInfo.userSubmitted = val;
  }

  toggleIncludeInactive()
  {
    this.includeInactive = !this.includeInactive;
  }


  get term(){
    return this.routerService.term;
  }

  set term(val:string)
  {
    this.routerService.term = val;
  }


  get lastTerm(){
    return this.routerService.lastSearchStr;
  }


  get selectedRouter() {
    return this.domain.selectedRouter;
  }

  set selectedRouter(val: IRouter){
    this.domain.selectedRouter = val;        
  }

  set routerUseMode(val: string)
  {
    this.domain.routerUseMode = val;
  }

  set latestVersionInfos(val: IRouterVersionInfo[])
  {
    this.domain.latestRouterVersionInfos = val;
  }

  set excludePartIds(val: string[])
  {
    this.domain.excludePartIds = val;
  }

  get currentUserName(){
    return this.gkauth.currentUser.UserName;
  }

  scrollChangeHandler(e:number)
  {
    //console.log("scrollChangeHandler:" + e);
    this.routerService.lastIndex = e;
  }

  constructor(private routerService: RouterServiceHttp,
    private domain: RouterDomainService,
    public helper: Helper,
    public validator: RouterValidationService,
    private navRouter: Router,
    private userMaintenanceService: UserMaintenanceServiceHttp,
    public renderer: Renderer2,
    private utils: Helper,
    private reasonCodeService: ReasonCodeServiceHttp,
    //private loginService : LoginService,
    private partService: PartServiceHttp,
    private alert: AlertService, 
    private zone: NgZone,          
    private gkauth: GateKeeperService,
    private auth: AuthService) {
    this.zone = zone;
  }

  sortRouters( r: IRouter, r1: IRouter): number {
    const ret = Helper.cmpStringAlphaNumeric(r.partNumber, r1.partNumber);

    if (ret == 0) {
      if (r.revision < r1.revision) {
        return -1;
      } else if (r.revision > r1.revision) {
        return 1;
      } else {
        return 0;
      }
    } else {
      return ret;
    }
  }

  pushRouter(r) {
    this.routers = this.helper.arrayOrEmpty(this.routers);
    this.routers.push(r);
    this.validator.routers = this.routers;
    this.routers = this.routers.sort(this.sortRouters);
  }


  searchRouters(e:Event)
  {    
    this.routerService.searchRouters(this.term, this.includeInactive).subscribe(response => 
      {
        this.setRouterList(response)  
        this.includeInactive = false;   
      });
  }

  onSorted(e: Event)
  {     
    //console.log("Sorted Header was clicked- sort operation taking place")
    //this.viewport.detach();

    var elementId: string = (event.target as Element).id;
    if(elementId == 'col1')
    {   
      if ( this.dir == 'asc')   
      {
        this.dir = 'desc';
        this.routers = [...this.fullRouters.sort(this.sortByNumberDesc)];    
      }   
      else 
      {
        this.dir = 'asc';
        this.routers = [...this.fullRouters.sort(this.sortByNumberAsc)];              
      } 
    }        
    else if (elementId == 'col2')
    {
      if ( this.dir == 'asc')   
      {
        this.dir = 'desc';
        this.routers = [...this.fullRouters.sort(this.sortByRevisionDesc)];
      }   
      else 
      {
        this.dir = 'asc';
        this.routers = [...this.fullRouters.sort(this.sortByRevisionAsc)];
      }   
    }   
    else if (elementId == 'col3')
    {
      if ( this.dir == 'asc')   
      {
        this.dir = 'desc';
        this.routers = [...this.fullRouters.sort(this.sortByStatusDesc)];
      }   
      else 
      {
        this.dir = 'asc';
        this.routers = [...this.fullRouters.sort(this.sortByStatusAsc)];
      }           
    }  
    else if (elementId == 'col4')
    {
      if ( this.dir == 'asc')   
      {
        this.dir = 'desc';
        this.routers = [...this.fullRouters.sort(this.sortByTimeStampDesc)];
      }   
      else 
      {
        this.dir = 'asc';
        this.routers = [...this.fullRouters.sort(this.sortByTimeStampAsc)];
      }       
    } 
    //console.log("onSorted-Viewport object state is " + this.viewport.getDataLength());
    //this.scrollStrategy.attach(this.viewport);
    //console.log("onSorted-Viewport scrollTop  before is " + this.viewport.elementRef.nativeElement.scrollTop);
    //console.log("onSorted-Viewport scrollTop  height is " + this.viewport.elementRef.nativeElement.scrollHeight);
    //this.viewport.elementRef.nativeElement.scrollTop = 3000; //(this.viewport.elementRef.nativeElement.scrollHeight / 16);
    //console.log("onSorted-Viewport scrollTop  after is " + this.viewport.elementRef.nativeElement.scrollTop);
    //this.viewport.scrollToOffset(1, 'smooth');
    

    //let start: number = 0;
    //let end :number = 30;
    //console.log("Called callScroll after column was sorted");
    //this.viewport.setRenderedContentOffset(0);
    //this.viewport.setRenderedRange({start, end});
    //this.scrollStrategy.setScrollHeight(this.rowHeight, 0);
    //this.viewport.scrollToIndex(2);
    //this.scrollStrategy.attach(this.viewport);
    //this.scrollStrategy.updateContent(this.viewport);
    
    //this.fetchDataChunk();
  }

  sortByNumberAsc(a1: IRouter, a2: IRouter) { 
    return Helper.cmpString(a1.partNumber, a2.partNumber); 
  }

  sortByNumberDesc(a1: IRouter, a2: IRouter) { 
    return Helper.cmpStringDesc(a1.partNumber, a2.partNumber); 
  }

  sortByRevisionAsc(a1: IRouter, a2: IRouter) { 
    return Helper.cmpStringAlphaNumeric(a1.revision.toString(), a2.revision.toString()); 
  }

  sortByRevisionDesc(a1: IRouter, a2: IRouter) { 
    return Helper.cmpStringAlphaNumericDesc(a1.revision.toString(), a2.revision.toString()); 
  }

  sortByStatusAsc(a1: IRouter, a2: IRouter) { 
    return Helper.cmpString(a1.state.toString(), a2.state.toString()); 
  }

  sortByStatusDesc(a1: IRouter, a2: IRouter) {
    return Helper.cmpStringDesc(a1.state.toString(), a2.state.toString()); 
  } 

  sortByTimeStampAsc(a1: IRouter, a2: IRouter) { 
    return Helper.cmpString(a1.lastUpdate.toString(), a2.lastUpdate.toString()); 
  }

  sortByTimeStampDesc(a1: IRouter, a2: IRouter) {
    return Helper.cmpStringDesc(a1.lastUpdate.toString(), a2.lastUpdate.toString()); 
  } 

  updateRouterFromList(r) {
    this.routers = this.helper.arrayOrEmpty(this.routers.filter(x => x.id !== r.id));
    this.routers.push(r);
    this.validator.routers = this.routers;
    this.routers = this.routers.sort(this.sortRouters);
  }

  removeRouterFromList(r) {
    this.routers = this.helper.arrayOrEmpty(this.routers.filter(x => x.id !== r.id));
    this.validator.routers = this.routers;
    this.routers = this.routers.sort(this.sortRouters);
  }

  setRouterList(routerList) {
    this.routers = [];
    if (!this.helper.isEmptyArray(routerList)) {
      this.routers = this.helper.arrayOrEmpty(routerList);
    }

    this.validator.routers = this.routers;
    this.routers = this.routers.sort(this.sortRouters);
    this.fullRouters = this.routers;

    setTimeout(()=>{     
          this.viewPort.scrollToIndex(0);
        },0);

  }

  ngOnDestroy() {
    console.log('router-listing.component ngOnDestroy');
    console.log(this.navRouter.url);

    // leaving Router Maintenance?
  }

  ngOnInit() {
    console.log('router-listing.component.ngOnInit');

    this.helper.infoTabStatus(true);
    /*this.routerService.getRouters().subscribe(routers => {
      this.setRouterList(routers);
    });*/
    this.includeInactive = false;
    this.initRouters();
    console.log('..router-listing.component.ngOnInfo.api.initUserInfo()');
    this.userMaintenanceService.initUserInfo();
    console.log('..router-listing.component.ngOnInfo.getReasonCodes()');
    this.getReasonCodes();  
    this.ddlName = 'Reason';  
  }
  
  initRouters() {
    this.routers = this.routerService.routers;   
    var scrollToIndex = this.routerService.lastIndex; 

    if (!(this.routers === null || this.routers === undefined || this.routers.length == 0))
    {      
      setTimeout(()=>{     
          this.viewPort.scrollToIndex(scrollToIndex);
          console.log("scrolled to Index:" + scrollToIndex);   
        },0);
    }
  }


  getVersionInfos( callback: Function)
  {
    this.routerService.getAllLatestVersionInfo().subscribe( 
        versionInfos =>{  
            if (callback)
              callback(versionInfos);
        });
  }

  copy(router: IRouter) {
    var _self = this;
    const res = this.validator.canCopy(router);
    if (res.actionable) {
      this.getVersionInfos((versionInfos) => {
          _self.latestVersionInfos = versionInfos;
          _self.excludePartIds = [];
          _self.selectedRouter = RouterDetailsValidator.newRouterDefault;
          _self.selectedRouter.psl = router.psl;
          _self.selectedRouter.type = router.type;
          _self.routerUseMode = "Edit";
          _self.copyRouterId = router.id;
          _self.routerListingOverlayOpen();
      });     
    }
  }

  getReasonCodes(){
    this.reasonCodeService.getReasonCodes().subscribe(reasonCodes => {
      this.reasonCodes = reasonCodes;
      if (!this.utils.isEmptyArray(this.reasonCodes)) {
        this.reasons = this.translateToIReasonCodes(this.reasonCodes)    
      }    
    });
  }

  translateToIReasonCodes(data: any): string[] {
    const ret: string[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(d.type + '-' + d.description);
      }
    }
    //console.log("Reason for dropdownlist is " + ret);
    return ret;
  }

  viewNotes(router:IRouter){
    this.routerService.getRouterNotes(router.id).subscribe(notes => { 
      if (notes === null || notes === '') 
      {
        this.currentNotes = "No Router Notes";
      }
      else
      {
        this.currentNotes = notes;
      }
    });   
  } 

  editNotes(router:IRouter){
    this.routerService.getRouterNotes(router.id).subscribe(notes => {
      var i: number;
      this.notesArray = notes.split(String.fromCharCode(10));
      /* var splitNotes: string;
      for (i=0; i< this.notesArray.length; i++)
      {
        splitNotes += this.notesArray[i] + '<br />'
      }      */

      this.currentNotes = notes;
    });  
    this.notesOverlayOpen(router,'editNotes'); 
  }

  checkRequiredNotes(){
    if (this.requiredReasonAdded)
    {
      this.addNotes();
    }
    else
    {
      
    }
  }

  validateReasonCode() {
    let valid = this.requiredReasonAdded;
    console.log('router-listing.validateReasonCode returns ' + valid);

    if (!valid) 
    { 
      this.reasonCodeValidationMsg = 'Please select a reason code!';      
    }
    return valid;
  }

  addNotes(){ 
    let allNotes:string;
    if ((this.reasonCodeValidationMsg == 'Please select a reason code!') || (this.reasonCodeValidationMsg == 'PLEASE SELECT A REASON CODE!'))
    {
      this.reasonCodeValidationMsg = 'PLEASE SELECT A REASON CODE!';
    }
    else 
    {  

      var nn:string = this.newNotes.nativeElement.value;
      console.log("router-listing.addNotes rc?:" + this.requiredReasonAdded);
      console.log(nn);

      if (this.requiredReasonAdded)
      {
        allNotes = this.userMaintenanceService.concatLines(true, this.reasonCodeValidationMsg, nn);
      }
      else
      {
        allNotes = this.userMaintenanceService.concatLines(true, nn);
      }

      this.routerService.updateRouterNotes(this.editedRouter.id, allNotes).subscribe(res => {
        console.log(res);
      });
      
      this.notesAdded = true;
      this.newNotes.nativeElement.value = '';
      this.reasonCodeValidationMsg = 'Please select a reason code!' 
      this.reasonCodeValidationLbl.nativeElement.style.color = 'red';
      this.requiredReasonAdded = false;
      this.closeNotesOverlay();
    }
  }

  selectedItemFromDDL(selectedItem:any) : Observable<any>
  {
    this.reasonCodeValidationMsg = selectedItem;
    this.reasonCodeValidationLbl.nativeElement.style.color = 'green';
    this.requiredReasonAdded = true;
    /* if(event.target) 
    {
      console.log('######################################################################Target id: ' + reason);
      this.newNotes.nativeElement.value = reason;    
    } */
    return Observable.of(this.newNotes.nativeElement.value);
  }

  emptyCurrentNotes() {
    this.currentNotes = "";
  }

  createNewVersion(router: IRouter) {
  // this.viewNotes(router);
  // this.notesOverlayOpen(router);
  //  if (this.notesAdded)
  //  {
    const res = this.validator.canCreateNewVersion(router);
    if (res.actionable)    {
      this.routerService.createNewRouterVersion(router.id).subscribe(newVersion => {
        console.log('new version:' + newVersion.id);
        this.pushRouter(newVersion);
      });
    }
  //  }
  }

  publish(router: IRouter) {
    const res = this.validator.canPublish(router);
    if (res.actionable)    {
      this.auth.lockItem(router.id).subscribe(sysLock => {
        this.routerService.publishRouter(router).subscribe(r => {
          router.state = RouterState.ACTIVE;
          router.lastUpdate = r.lastUpdate;
          router.updatedBy = r.updatedBy;

          const last = this.routers.find(rt => rt.partNumber == router.partNumber && rt.revision == router.revision - 1);
          if (last) {
            last.state = RouterState.HOLD_NEW_LOCKED;
          }
          this.auth.unlockItem(this.auth.systemLock.id).subscribe(ret => { });
        });
      });
    }
  }

  holdAll(router: IRouter) {
    this.isOnHold = true;
    this.reasonCodeValidationMsg = "Please select a reason code!";
    this.reasonCodeValidationLbl.nativeElement.style.color = 'red';
    this.viewNotes(router);  
    this.notesOverlayOpen(router,"HoldAll");
    console.log('****************************Hold All was submitted and this.notesAdded equals : ' + this.reasonCodeValidationMsg);
    //if (this.reasonCodeValidationMsg != "Please select a reason code!" )
    /*  if (this.requiredReasonAdded)
    {
      const res = this.validator.canHoldAllWIP(router);
      console.log('****************************Hold All was submitted and res.actionable equals : ' + res.actionable);
      if (res.actionable) {
            if (router.state == RouterState.HOLDONLY_NEW) {
              router.state = RouterState.HOLDONLY_ALL;
            }  else {
              router.state = RouterState.HOLD_ALL_WIP;
            }
            this.routerService.updateRouter(router).subscribe(updated => {
              console.log('updated:' + updated.id);
            });
        }
    }    */
  }

  holdNew(router: IRouter) {
    this.isOnHold = true;
    this.reasonCodeValidationMsg = "Please select a reason code!";
    this.reasonCodeValidationLbl.nativeElement.style.color = 'red';
    this.viewNotes(router);  
    this.notesOverlayOpen(router,"HoldNew");
    console.log('****************************Hold New was submitted and this.notesAdded equals : ' + this.reasonCodeValidationMsg);
    /* if (this.reasonCodeValidationMsg != "Please select a reason code!")
    {
      const res = this.validator.canHoldNewWIP(router);
      console.log('****************************Hold All was submitted and res.actionable equals : ' + res.actionable);
      if (res.actionable)   {
                if (router.state == RouterState.HOLDONLY_ALL) {
                  router.state = RouterState.HOLDONLY_NEW;
                }  else {
                    router.state = RouterState.HOLD_NEW_WIP;
                }
                        this.routerService.updateRouter(router).subscribe(updated => {
              console.log('updated:' + updated.id);
            });
        }
    } */
  }

  unHold(router: IRouter) {
    this.viewNotes(router);  
    this.notesOverlayOpen(router,"UnHold");
    /* if (this.notesAdded)
    {
      const res = this.validator.canRemoveHold(router);
      if (res.actionable) {
          router.state = RouterState.ACTIVE;
          this.routerService.updateRouter(router).subscribe(updated => {
            console.log('updated:' + updated.id);
          });
        }
    } */
  }

  createNewRouter(info: IRouter) {
    console.log('router-listing.component.submitRouter');
    console.log(info);
    this.userSubmitted = true;

    if (this.routerInfo.bizl.canCreateRouter) {
      if (!this.helper.isEmptyString(this.copyRouterId)) {         
        this.routerService.createRouterFromRouter(this.copyRouterId, info.partId).subscribe(copied => {
          console.log('...createRouterFromRouter returned');
          console.log(copied);
          this.copyRouterId = null;
          this.pushRouter(copied);
          this.editRouter(copied);
          this.closeOverlay();
        });
      } else {
        console.log('...creating new router ' + info.partNumber);
        this.routerService.createRouter(info).subscribe(newRouter => {
          console.log('createRouter returned created:' + newRouter.partNumber + newRouter.revision);
          console.log(newRouter);
          this.pushRouter(newRouter);
          this.editRouter(newRouter);
          this.closeOverlay();
        });
      }
    } else {
      return;
    }
  }

  cancelCreateRouter() {
    console.log('cancelCreateRouter');
    this.selectedRouter = null;
    this.closeOverlay();
  }

  cancelNotes() {
    console.log('cancelNotes');
    this.reasonCodeValidationMsg = "";
    this.newNotes.nativeElement.value = '';
    this.notesAdded = false;
    this.closeNotesOverlay();
  }

  delete(router: IRouter) {
    const  res = this.validator.canDelete(router);
    if (res.actionable) {
      this.alert.showYesNo('Are you sure you want to delete router ' + router.partNumber + ' v' + router.revision + '?',
      AlertLevel.WARNING,
      (ar: AlertReponse) => {
        console.log(ar);
        console.log(ar == AlertReponse.OK);
        console.log(AlertReponse.OK);
        if (ar == AlertReponse.YES) {
          this.routerService.deleteRouter(router.id).subscribe(ret => {
            console.log('router-listing.component.delete call returns');
            console.log(ret);
            this.removeRouterFromList(router);
          });
        }
      });
    }
  }

  addRouter() {
    this.getVersionInfos((versionInfos) => {
        this.latestVersionInfos = null;
        this.excludePartIds = this.helper.getRouterVersionInfoPartIds(versionInfos); //exclude parts that already have at least one router
        this.selectedRouter = RouterDetailsValidator.newRouterDefault;
        this.domain.routerUseMode = "Edit";
        this.copyRouterId = null;
        this.routerListingOverlayOpen();   
    });
  }

  editRouter(router: IRouter) {
    console.log('router-listing.component.editRouter');
    this.auth.lockItem(router.id).subscribe (res => {
      console.log('router-listing.component.editRouter lockItem returns');
      console.log(res);
      if (res != null) {
          this.routerService.getRouter(router).subscribe(r => {
            this.goToRouterDetails(r,"Edit");
          });
      }
    });
  }

  goToRouterDetails(rt:IRouter, useMode:string)
  {
    this.selectedRouter = rt;
    this.routerUseMode = useMode;
    this.navRouter.navigate(['/routers', rt.id]);
  }

  viewRouter(router: IRouter) {
    console.log('router-listing.component.viewRouter');
    if (router.state == RouterState.PENDING ) {  //get latest router info since PENDING routers are editable
      this.routerService.getRouter(router).subscribe(r => {
        this.goToRouterDetails(r, "View");
      });
    } else {
      this.goToRouterDetails(router, "View");
    }
  }

  closeOverlay() {
    this.copyRouterId = null;
    this.routerView.nativeElement.style.display = 'none';
  }

  closeNotesOverlay() {
    this.isOnHold = false;  
    this.reasonCodeValidationMsg = "";  
    this.reasonCodeValidationLbl.nativeElement.style.color = 'red';     
    this.notesView.nativeElement.style.display = 'none';
  }

  routerListingOverlayOpen() {
    const THIS = this;
    document.getElementById('RouterListingOverlay').style.display = 'block';
    const modal = document.getElementById('RouterListingOverlay');

    // When the user clicks anywhere outside of the modal, close it
    /* window.onclick = function(event) {
        if (event.target == modal) {
            modal.style.display = 'none';
            THIS.closeOverlay();
        }
    }; */
  }

  notesOverlayOpen(router:IRouter, holdType: string) {
    const THIS = this;
    document.getElementById('NotesOverlay').style.display = 'block';
    const modal = document.getElementById('NotesOverlay');
    this.editedRouter = router;

    //switch (holdType) {
    //  case "UnHold":
        if (holdType == 'UnHold')
        {               
        const res2 = this.validator.canRemoveHold(this.editedRouter);
        if (res2.actionable) {
            router.state = RouterState.ACTIVE;
            this.routerService.updateRouter(this.editedRouter).subscribe(updated => {
              console.log('updated:' + updated.id);
            });
          }  
        }

      // case "HoldAll":
      if (holdType == 'HoldAll')
      {
        const res = this.validator.canHoldAllWIP(this.editedRouter);
        console.log('****************************Hold All was submitted and res.actionable equals : ' + res.actionable);
        if (res.actionable) {
          if (router.state == RouterState.HOLD_NEW_LOCKED) {
            router.state = RouterState.HOLD_ALL_LOCKED;
          }  else {
            router.state = RouterState.HOLD_ALL_WIP;
          }
          this.routerService.updateRouter(this.editedRouter).subscribe(updated => {
            console.log('updated:' + updated.id);
          });
        }
      }
      //case "HoldNew":
      if (holdType == 'HoldNew')
      {              
        const res1 = this.validator.canHoldNewWIP(this.editedRouter);
        console.log('##############################################Hold NEW was submitted and res1.actionable equals : ' + res1.actionable);
        if (res1.actionable)
        {
          console.log("(88888888888888888888888888888888888 -----router state is : " + router.state)
            if (router.state == RouterState.HOLD_ALL_LOCKED) {
              router.state = RouterState.HOLD_NEW_LOCKED;
            }  else {
                router.state = RouterState.HOLD_NEW_WIP;
            }
            console.log("(99999999999999999999999999999999999 -----changed router state is : " + router.state)
                    this.routerService.updateRouter(this.editedRouter).subscribe(updated => {
                console.log('updated:' + updated.id);
              });
        }                                
      }
        // default:        
        // break;
              
    // When the user clicks anywhere outside of the modal, close it
    /* window.onclick = function(event) {
        if (event.target == modal) {
            modal.style.display = 'none';
            THIS.closeNotesOverlay();
        }
    }; */
  }

  ngAfterContentInit() {
    $(document).ready(function(){
      $('.SearchField').focus();
    });
  }
}


