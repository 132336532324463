import { Config } from '../../../core/shared/gatekeeper/config';
import { PDFService } from '../../../core/services/pdf-service';
import {formatDate} from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, 
AfterContentChecked, SimpleChanges, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { IFile, IRouterFile } from '../../../core/models/file.model';
import { Helper } from '../../../core/services/helper.service';
import { FileServiceHttp } from '../../../core/services/file.servicehttp';
import { RouterServiceHttp } from '../../../core/services/router.servicehttp';
import { SortCriteria} from '../../../core/models/sort.model';
import { Observable } from 'rxjs';
import { IRouter } from '../../../core/models/router.model';

@Component({
  selector: 'spec-file',
  template: require('./spec-file.component.html'),
  // styles: [require('./spec-file.component.css')]
  styles: [require('./spec-file.component.css').toString()]
})
export class SpecFileComponent implements OnInit, AfterContentChecked {
  @Input() partId: string;
  @Input() stepFiles: IFile[];
  @Input() routerId: string;
  @Input() stepId: string;
  @Input() isOverlay = false;
  @Input() refreshFileComponent: number;
  @Input() showPartDrawingSelection: boolean;
  @Output() fileSelected = new EventEmitter();
  @Output() ddlName:string ;
  @ViewChild('submitButton', {static: true}) submitButton: ElementRef;
  @ViewChild("holdState", {static: false}) holdState: ElementRef;
  @ViewChild("holdStateNotes", {static: false}) holdStateNotes: ElementRef;


  files: IFile[];
  //partDrawing: string[] = ["Yes","No"];
  partFileTypes: string[] = ["General","PartDrawing","PartSpec"];
  public term: string;
  private allFiles: IFile[];
  @ViewChild('selectFilesView', {static: true}) selectFilesView: ElementRef;
  @ViewChild('imgFilesView', {static: true}) imgFilesView: ElementRef;
  @ViewChild('partDrawingDDL',{static:true}) partDrawingDDL:ElementRef;
  @ViewChild('lblItemFileType',{static:true}) lblItemFileType:ElementRef;
  viewWasVisible = false;
  isPartDrawing:Boolean = false;
  itemFileTypeMsg:string = '';
  fileAssociatedRouters: IRouter[] = null;
  routerActionFormGroup: FormGroup;
  selectedFile:IFile;

  constructor(
    private Config: Config,
    private fileService: FileServiceHttp, 
    private routerService: RouterServiceHttp,
    private helper: Helper,
    private pdfService: PDFService) {   }

   filterFiles(removeFiles: IFile[]): void {
     console.log('filterFiles');

     let ret: IFile[] = [];
     if (!this.helper.isEmptyArray(this.allFiles) && !this.helper.isEmptyArray(removeFiles)) {
      console.log('removeFiles length ' + (!this.helper.isEmptyArray(removeFiles.length) ? removeFiles.length : 'NULL'));
      console.log('all files length ' + (!this.helper.isEmptyArray(this.allFiles) ? this.allFiles.length.toString() : 'NULL'));
        for (const p of this.allFiles){
          const stepP = removeFiles.find(x => x.id === p.id);
         // console.log('...search for ' + p.name + '...stepP:' + (!stepP ? 'NULL' : stepP.id) );
          if (!stepP) {
            ret.push(p);
          }
        }
      } else {
        ret = this.allFiles;
      }
      this.files = ret;
      this.helper.resortList(this.files, this.sortName);
      console.log('..post filter files length:' + (!this.helper.isEmptyArray(this.files) ? this.files.length : '0'));
   }

    private get isVisible(): boolean {
    return this.selectFilesView.nativeElement.offsetParent != null;
  }

  ngAfterContentChecked() {
    console.log('step-file.component.ngAfterContentChecked');
    if (!this.isVisible && this.viewWasVisible) {
      console.log('step-file.component.ngAfterContentChecked...visible to hidden');
      this.viewWasVisible = false;
    } else if (this.isVisible && !this.viewWasVisible) {
        console.log('step-file.component.ngAfterContentChecked...hidden to visible');
        this.viewWasVisible = true;
        this.filterFiles(this.stepFiles);
       // this.helper.resortList(this.files, sortFiles);
      this.ddlName = "Part FileType";
      this.itemFileTypeMsg = "General";
    }
  }

  ngOnInit() {
    console.log('spec.file.component.ngOnInit routerID:' + this.routerId + ' stepId:' + this.stepId);

    this.routerActionFormGroup = new FormGroup({
      holdState: new FormControl('', Validators.required),
      holdStateNotes: new FormControl()
    });

    this.routerActionFormGroup.controls.holdState.setValue("2");
    this.routerActionFormGroup.controls.holdStateNotes.setValue("");
    this.routerActionFormGroup.controls.holdState.disable();

    this.fileService.getFilesExt().subscribe(allFiles => {
      console.log('....all files count:' + allFiles.length);
      this.allFiles = allFiles;
      this.files = allFiles;
      this.filterFiles(this.stepFiles);   
      //this.ddlName = "Is Part Drawing?";
      //this.itemFileTypeMsg = "No";
      this.ddlName = "Part FileType";
      this.itemFileTypeMsg = "General";
      //console.log("Part DRawing DDL " + this.partDrawing);
      
      // console.log('....pre filter all files count:' + this.files.length);
      // console.log('....step file count:' + this.stepFiles.length);

      // console.log('....filtered files count:' + this.files.length);
    });
  }

  onSorted(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'name')
          {
            this.files.sort(this.sortByNameDesc)
          }
          else if (criteria.sortColumn == 'type')
          {
            this.files.sort(this.sortByTypeDesc)
          }   
          else if (criteria.sortColumn == 'revision')
          {
            this.files.sort(this.sortByRevisionDesc)
          }   
          else if (criteria.sortColumn == 'description')
          {
            this.files.sort(this.sortByDescriptionDesc)
          }
          else if (criteria.sortColumn == 'revisionDate')
          {
            this.files.sort(this.sortByRevisionDateDesc)
          }
        }
        else {
          if(criteria.sortColumn == 'name')
          {
            this.files.sort(this.sortByNameAsc)
          }
          else if (criteria.sortColumn == 'type')
          {
            this.files.sort(this.sortByTypeAsc)
          }    
          else if (criteria.sortColumn == 'revision')
          {
            this.files.sort(this.sortByRevisionAsc)
          }  
          else if (criteria.sortColumn == 'description')
          {
            this.files.sort(this.sortByDescriptionAsc)
          }
          else if (criteria.sortColumn == 'revisionDate')
          {
            this.files.sort(this.sortByRevisionDateAsc)
          }
        }
      }      
  }
  
  sortByNameAsc(a1: IFile, a2: IFile) { 
    return Helper.cmpString(a1.name, a2.name); 
  }
  
  sortByNameDesc(a1: IFile, a2: IFile) { 
    return Helper.cmpStringDesc(a1.name, a2.name); 
  }
  
  sortByDescriptionAsc(a1: IFile, a2: IFile) { 
    return Helper.cmpString(a1.description, a2.description); 
  }
  
  sortByDescriptionDesc(a1: IFile, a2: IFile) {
    return Helper.cmpStringDesc(a1.description, a2.description); 
  }

  sortByRevisionAsc(a1: IFile, a2: IFile) { 
    return Helper.cmpString(a1.revision, a2.revision); 
  }
  
  sortByRevisionDesc(a1: IFile, a2: IFile) {
    return Helper.cmpStringDesc(a1.revision, a2.revision); 
  }

  sortByRevisionDateAsc(a1: IFile, a2: IFile) {
    return Helper.cmpDate(a1.revisionDate, a2.revisionDate); 
  }

  sortByRevisionDateDesc(a1: IFile, a2: IFile) {
    return Helper.cmpDateDesc(a1.revisionDate, a2.revisionDate); 
  }

  sortByTypeAsc(a1: IFile, a2: IFile) { 
    return Helper.cmpString(a1.type, a2.type); 
  }
  
  sortByTypeDesc(a1: IFile, a2: IFile) {
    return Helper.cmpStringDesc(a1.type, a2.type); 
  }

  handleSelectFile(e: Event, file: IFile): void {
    console.log('spec-file.component.handleSelectFile file.id:');
    console.log(e);
    this.selectedFile = file;
    var elementId: string = (event.target as Element).id;
    if (e.srcElement && elementId === 'imgViewFile' ) { //&& e.srcElement.id === 'imgViewFile'
      return;
    }
    this.files = this.files.filter(x => x.id !== file.id);
    //file.itemFileType = this.isPartDrawing ? 1 : 0;
    file.itemFileType = this.setItemFileType(this.itemFileTypeMsg);

    //-- Check to see if adding a PartDrawing
    if (this.itemFileTypeMsg === 'PartDrawing')
    {
      //-- check/get Router Associated with the current part
      this.checkRouterAssociation(this.partId);
    }
    else
    {
      this.fileSelected.emit(file);
    }
  }

  setItemFileType(partFileType:string): number
  {
    var itemFileType:number = 0;

    switch(partFileType)
    {
      case "General":
        itemFileType = 0;
        break;
      case "PartDrawing":
        itemFileType = 1;
        break;
      case "PartSpec":
        itemFileType = 2;
        break;
    }

    return itemFileType;
  }

  viewFile1(file: string) {
    console.log('spec-file.component.viewFile file :' + file);
    this.pdfService.show(file);
  }

  selectedItemFromDDL(selectedItem:any) : Observable<any>
  {
    console.log('spec-file.component.selectedItemFromDDL');
    this.itemFileTypeMsg = selectedItem;
    //this.lblItemFileType.nativeElement.style.fontWeight = "bold";
    // if (selectedItem === "Yes")
    // {
    //   this.isPartDrawing = true;
    // }
    // else
    // {
    //   this.isPartDrawing = false;
    // }

    //return  Observable.of(this.isPartDrawing); 
    return  Observable.of(this.itemFileTypeMsg); 
  }

  private sortName(a: IFile, b: IFile): number {
    return Helper.cmpStringAlphaNumeric(a.name, b.name);
  }

  checkRouterAssociation(fileName: string) {
    console.log('spec-file.component.checkRouterAssociation');

    this.routerService.getRoutersAssociatedWithPart(this.partId).subscribe(routers => {
      this.fileAssociatedRouters = routers;

      if (this.fileAssociatedRouters.length)
      {
        //-- Associated Routers exists, display Router Action form
        this.overlayOpen();
      }
      else
      {
        //-- no associted routers then no action on routers required; don't display Router Action form
        this.closeOverlay
        this.fileSelected.emit(this.selectedFile);
      }
    });
  }

  overlayOpen() {
    document.getElementById('RouterActionOverlay').style.display = 'block';
    const modal = document.getElementById('RouterActionOverlay');

    // When the user clicks anywhere outside of the modal, this closes it
    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = 'none';
        }
    };
  }

  submitOnClick()
  {
    this.selectedFile.holdState = this.routerActionFormGroup.controls.holdState.value;
    this.selectedFile.holdStateNotes = this.routerActionFormGroup.controls.holdStateNotes.value;
    this.selectedFile.tokenID = Config.gkParameters.TokenID;

    //-- Apply hold state and notes to associated routers
    this.routerService.updateRoutersHoldByPart(this.partId, this.selectedFile).subscribe(ret => {
        this.closeOverlay;
        this.fileSelected.emit(this.selectedFile);
    });
  }

  closeOverlay() {
    document.getElementById('RouterActionOverlay').style.display = 'none';
  }
}
