import { Component, OnInit, Input, Output, EventEmitter, AfterContentChecked, AfterViewInit, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { IProgram } from '../../../core/models/program.model';
import { Helper } from '../../../core/services/helper.service';
import { FilterPartPipe } from '../../../core/pipes/filter-part.pipe';
import { ProgramServiceHttp } from '../../../core/services/program.servicehttp';
import { SortCriteria} from '../../../core/models/sort.model';

@Component({
  selector: 'spec-program',
  template: require('./spec-program.component.html'),
  // styles: [require('./spec-program.component.css')]
  styles: [require('./spec-program.component.css').toString()]
})
export class SpecProgramComponent implements OnInit, AfterContentChecked, AfterViewInit {
  private _stepPrograms: IProgram[] = null;  public term: string;
  @Input() set stepPrograms(val: IProgram[]){
    console.log('spec-program.component set stepPrograms');
    this._stepPrograms = val;
  }

  get stepPrograms(): IProgram[] { return this._stepPrograms; }

  @Output() programSelected = new EventEmitter();
  programDescription: FormControl;
  programs: IProgram[];
  @ViewChild('allProgramsList', {static: true}) allProgramsList: ElementRef;
  allProgramsListWasVisible = false;

  constructor(private programService: ProgramServiceHttp, private helper: Helper) {   }

   filterPrograms(removePrograms): void {
      if (!this.helper.isEmptyArray(this.programs) && !this.helper.isEmptyArray(this.stepPrograms)) {
        const ret: IProgram[] = [];
        for (const p of this.programs){
          const stepP = this.stepPrograms.find(x => x.id === p.id);
          if (!stepP) {
            ret.push(p);
          }
        }
        this.programs = ret;
      }
   }

   private get isVisible(): boolean {
    return this.allProgramsList.nativeElement.offsetParent != null;
  }

  ngAfterContentChecked() {
    console.log('step-program.component.ngAfterContentChecked isVisible:' + String( this.isVisible ? 'true' : 'false'));
    if (!this.isVisible && this.allProgramsListWasVisible ) {
      console.log('...visible to hidden');
      this.allProgramsListWasVisible = false;
    } else if (this.isVisible && !this.allProgramsListWasVisible ) {
        console.log('...hidden to visible');
        this.allProgramsListWasVisible = true;
        this.programService.getPrograms().subscribe(allPrograms => {
          this.programs = allPrograms;
          console.log('....pre filter programs count:' + this.programs.length);
          console.log('....step program count:' + this.stepPrograms.length);
          this.filterPrograms(this.stepPrograms);
          console.log('....filtered programs count:' + this.programs.length);
        });

    }
  }
  ngOnInit() {
    console.log('spec.program.component.ngOnInit');
    this.programDescription = new FormControl('', Validators.required);
  }

  handleSelectProgram(program): void {
    this.programs = this.helper.arrayOrEmpty(this.programs);
    this.programs = this.programs.filter(x => x.id !== program.id);
    this.programSelected.emit(program);
  }

  onSorted(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'number')
          {
            this.programs.sort(this.sortByNumberDesc)
          }         
          else if (criteria.sortColumn == 'description')
          {
            this.programs.sort(this.sortByDescriptionDesc)
          }          
        }
        else {
          if(criteria.sortColumn == 'number')
          {
            this.programs.sort(this.sortByNumberAsc)
          }          
          else if (criteria.sortColumn == 'description')
          {
            this.programs.sort(this.sortByDescriptionAsc)
          }         
        }
      }      
  } 
  
  
  sortByNumberAsc(a1: IProgram, a2: IProgram) { 
    return Helper.cmpString(a1.number, a2.number); 
  }
  
  sortByNumberDesc(a1: IProgram, a2: IProgram) { 
    return Helper.cmpStringDesc(a1.number, a2.number); 
  }
  
  sortByDescriptionAsc(a1: IProgram, a2: IProgram) { 
    return Helper.cmpString(a1.description, a2.description); 
  }
  
  sortByDescriptionDesc(a1: IProgram, a2: IProgram) {
    return Helper.cmpStringDesc(a1.description, a2.description); 
  }

  ngAfterViewInit() {

     console.log('File.Component.AfterViewInit');
    // Step Table Fixed Headers initial run
        const c1 = $('.col1').width();
        const c2 = $('.col2').width();
        const c3 = $('.col3').width();
        const c4 = $('.col4').width();

        $('#col1').width(c1 + 1);
        $('#col2').width(c2);
        $('#col3').width(c3);
        $('#col4').width(c4);

    // runs every time the user re-sizes the window
        $(window).resize(function(){
      // gets td column widths
        const c11 = $('.col1').width();
        const c22 = $('.col2').width();
        const c33 = $('.col3').width();
        const c44 = $('.col4').width();
      // sets th column widths to match td's
        $('#col1').width(c11 + 1);
        $('#col2').width(c22);
        $('#col3').width(c33);
        $('#col4').width(c44);
        console.log ('resize header occured')
        });
      }

}
