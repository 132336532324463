import { Pipe, PipeTransform } from '@angular/core';
import { isNull } from 'util';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(routers: any, term: any): any {
    // check if term is undefined
    if (term === undefined || term.length == 0 || routers === null) {
      return routers;
    }
    
    return routers.filter(
      function(router) {   
        if (
          router.partNumber === undefined || 
          router.partNumber === null ||
          router.partNumber.length == 0)
        return false;

        return router.partNumber.toLowerCase().substr(0,term.length) == term.toLowerCase();
      }
    );


    // return the updated routers array
    /*return routers.filter(
      function(router){   
        return (router.partNumber.toLowerCase().includes(term.toLowerCase())  || router.updatedBy.toLowerCase().includes(term.toLowerCase()) || router.partDescription.toLowerCase().includes(term.toLowerCase()));
      });*/
  }
}
