import { ReasonCodeComponentBizLogic } from './reason-code.component.bizl';
import {
    AfterContentChecked,
    AfterContentInit,
    AfterViewChecked,
    AfterViewInit,
    Component,
    DoCheck,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IReasonCode, ReasonCodeType, ReasonCodeTypeUtils } from '../core/models/reasoncode.model';
import { ReasonCodeServiceHttp } from '../core/services/reasoncode.servicehttp';
import { FilterReasonCodePipe } from '../core/pipes/filter-code.pipe';
import { Helper } from '../core/services/helper.service';
import { ElementRef } from '@angular/core';
import { AlertService } from '../core/services/alert.service';
import { AlertLevel, AlertReponse } from '../core/models/alert.model';
import { SortCriteria} from '../core/models/sort.model';

@Component({
  selector: 'app-reason-code',
  template: require('./reason-code.component.html'),
  // styles: [require('./reason-code.component.css')]
  styles: [require('./reason-code.component.css').toString()]
})
export class ReasonCodeComponent implements OnChanges, OnInit, DoCheck, AfterContentChecked, AfterViewChecked, AfterViewInit, OnDestroy, AfterContentInit {
  reasonCodes: IReasonCode[];
  ReasonCodeFormGroup: FormGroup;
  selectedReasonCode: IReasonCode;
  types= ReasonCodeTypeUtils.types;
  public term: string;
  @ViewChild('table' , {static: true}) table: ElementRef;
  @ViewChild('reasonCodeForm' , {static: true}) reasonCodeForm: ElementRef;
  userSubmitted = false;
  overlayWasVisible = false;
  bizl: ReasonCodeComponentBizLogic = null;
  lastReasonCodeNumberValidationMsg = '';
  dir ="asc"; 
  editMode: Boolean = false;

  constructor(
      private reasonCodeService: ReasonCodeServiceHttp,
      private alert: AlertService,
      private utils: Helper) { }

  ngOnChanges() {
    console.log('reasonCodes.component.OnChanges');
  }

  ngOnInit() {
    console.log('reasonCodes.component.OnInit');

    this.ReasonCodeFormGroup = new FormGroup({
      type : new FormControl('', Validators.required),
      code: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required)
    });


    this.reasonCodeService.getReasonCodes().subscribe(reasonCodes => {
      this.reasonCodes = reasonCodes;
      if (!this.utils.isEmptyArray(this.reasonCodes)) {
        this.reasonCodes.sort(this.sortByTypeAsc);       
      }
      // this.sortTable(0);
    });

    this.bizl = new ReasonCodeComponentBizLogic(this);
  }

  // private sortByTypeCode(r1: IReasonCode, r2: IReasonCode) {
  //   if (r1 == null ) {
  //      if (r2 == null) {
  //         return 0;
  //     } else {
  //       return -1;
  //     }
  //   }
  //   if (r2 == null ) { return 1; }

  //   if (r1.type < r2.type) {
  //     return -1;
  //   } else if (r1.type > r2.type) {
  //     return 1;
  //   } else {
  //     return Helper.cmpStringAlphaNumeric(r1.code, r2.code);
  //   }
  // }


  toFormGroup(reasonCode: IReasonCode) {
    this.ReasonCodeFormGroup.patchValue({
        type: reasonCode.type,
        code: reasonCode.code,
        description: reasonCode.description
    });
  }


  fromFormGroup(info): IReasonCode {
    return {
      id: this.utils.isExistingItem(this.selectedReasonCode) ? this.selectedReasonCode.id : null,
      type: info.type,
      code: info.code,
      description: info.description,
      inUse: this.utils.isExistingItem(this.selectedReasonCode) ? this.selectedReasonCode.inUse : false
    };
  }

  ngDoCheck() {
    console.log('reasonCodes.component.DoCheck');
  }

  resetFormValidation() {
    this.userSubmitted = false;
    // yourForm.reset(), yourForm.resetForm() don't work, but this does:
    this.ReasonCodeFormGroup.markAsPristine();
    this.ReasonCodeFormGroup.markAsUntouched();
    this.ReasonCodeFormGroup.updateValueAndValidity();
  }

  get isOverlayVisible() {
    return this.reasonCodeForm.nativeElement.offsetParent != null;
  }

  getTitle() {
    if (this.bizl.isNewReasonCode) {
      return 'Add a Reason Code';
    } else {
      return 'Edit a Reason Code';
    }
  }

  ngAfterContentChecked() {
    if (!this.isOverlayVisible && this.overlayWasVisible) {
      console.log('reasonCodes.component.ngAfterContentChecked...visible to hidden ');
      this.overlayWasVisible = false;
      this.resetFormValidation();
    } else if (this.isOverlayVisible && !this.overlayWasVisible) {
      console.log('reasonCodes.component.ngAfterContentChecked...hidden to visible');
      this.overlayWasVisible = true;
      this.userSubmitted = false;
      this.resetFormValidation();
    }
  }

  validateCode() {
    let valid =  (!this.bizl.isNewReasonCode && this.ReasonCodeFormGroup.controls.code.pristine ||
                (this.bizl.isNewReasonCode && !this.userSubmitted));

     if (!valid) {
        if (!this.ReasonCodeFormGroup.controls.code.valid) {
          this.lastReasonCodeNumberValidationMsg = 'Required';
        } else if (!this.bizl.isUniqueTypeCode(this.editMode)) {
          this.lastReasonCodeNumberValidationMsg = 'Type & code combination must be unique';
        } else {
          valid = true;
        }
     }

     return valid;
  }

  getNewReasonCode() {
    return this.fromFormGroup(this.ReasonCodeFormGroup.getRawValue());
  }

  validateNewReasonCodeInfo() {
    return this.ReasonCodeFormGroup.valid &&  this.validateDescription() && this.validateCode();
  }


  validateDescription() {

      return  (!this.bizl.isNewReasonCode && this.ReasonCodeFormGroup.controls.code.pristine) ||
        (this.bizl.isNewReasonCode && !this.userSubmitted) ||
           this.ReasonCodeFormGroup.controls.description.valid;
    }


  ngOnDestroy() {
    console.log('reasonCodes.component.OnDestroy');
  }

  addReasonCode() {
    this.editMode = false;
    this.overlayOpen(this.bizl.newReasonCodeDefaultInfo);
  }

  deleteReasonCode(p: IReasonCode) {
    this.alert.showYesNo('Are you sure you want to delete reasoncode ' + p.code + ' ' + p.description + '?',
    AlertLevel.WARNING,
    (ar: AlertReponse) => {
      console.log(ar);
      console.log(ar === AlertReponse.OK);
      console.log(AlertReponse.OK);
      if (ar === AlertReponse.YES) {
        this.reasonCodeService.deleteReasonCode(p.id).subscribe( res => {
          this.reasonCodes = this.reasonCodes.filter(x => x.id !== p.id);
        });
      }
    });
  }


 submit(formValues) {
   console.log('reasonCodes.components.submit');
   console.log(this.ReasonCodeFormGroup);
   console.log(this.bizl);
   this.userSubmitted = true;
    if (!this.validateNewReasonCodeInfo()) {
      return;
    }


   const p = this.fromFormGroup(formValues);
   console.log(p);
   if (this.bizl.canCreate) {
     console.log('...create');
     this.reasonCodeService.createReasonCode(p).subscribe(added => {
       this.reasonCodes.push(added);
       this.reasonCodes.sort(this.sortByTypeAsc);
       // this.sortTable(0);
       this.closeOverlay();
     });
   } else if (this.bizl.canUpdate) {
    console.log('...update id:' + p.id);
    this.reasonCodeService.updateReasonCode(p).subscribe(updated => {
      console.log('...updated id:' + updated.id);

      const tmp = this.reasonCodes;
      this.reasonCodes = null;
      this.reasonCodes = tmp.filter(x => x.id !== updated.id);
      this.reasonCodes.push(updated);
      this.reasonCodes.sort(this.sortByTypeAsc);
      // this.sortTable(0);
      this.closeOverlay();
    });
   } else {
     this.closeOverlay();
   }
 }

  editReasonCode(reasonCode) {
    this.editMode = true;
    this.overlayOpen(reasonCode);
  }

  closeOverlay() {
    this.selectedReasonCode = null;
    // this.sortTable(0);
    document.getElementById('ReasonCodeOverlay').style.display = 'none';
  }
  overlayOpen(reasonCode: IReasonCode) {
    this.selectedReasonCode = reasonCode;
    this.toFormGroup(reasonCode);
    const _self = this;

    document.getElementById('ReasonCodeOverlay').style.display = 'block';
    const modal = document.getElementById('ReasonCodeOverlay');

    // When the user clicks anywhere outside of the modal, this closes it
    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = 'none';
            _self.selectedReasonCode = null;
        }
    };
  }


  onSorted(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'type')
          {
            this.reasonCodes.sort(this.sortByTypeDesc)
          }
          else if (criteria.sortColumn == 'code')
          {
            this.reasonCodes.sort(this.sortByCodeDesc)
          }
          else 
          {
            this.reasonCodes.sort(this.sortByDescriptionDesc)
          }          
        }
        else {
          if(criteria.sortColumn == 'type')
          {
            this.reasonCodes.sort(this.sortByTypeAsc)
          }
          else if (criteria.sortColumn == 'code')
          {
            this.reasonCodes.sort(this.sortByCodeAsc)
          }
          else 
          {
            this.reasonCodes.sort(this.sortByDescriptionAsc)
          }
        }
      }
      
  }
  
  // sortTable(n, event) {
  //   var elementId: string = (event.target as Element).id;
   
  
  //   if (elementId.substring(0,4) == 'col1' && n == 0)
  //   {    
  //     if (this.dir == 'asc')
  //     {
  //       this.dir = 'desc'
  //       this.reasonCodes.sort(this.sortByTypeAsc)
  //     }
  //     else 
  //     {
  //       this.dir = 'asc'
  //       this.reasonCodes.sort(this.sortByTypeDesc)
  //     }
      
  //   }
  //   if (elementId.substring(0,4) == 'col2' && n == 1)
  //   {
  //   if (this.dir == 'asc')
  //     {
  //       this.dir = 'desc'
  //       this.reasonCodes.sort(this.sortByCodeAsc)
  //     }
  //     else 
  //     {
  //       this.dir = 'asc'
  //       this.reasonCodes.sort(this.sortByCodeDesc)
  //     }
  //   }

  //   if (elementId.substring(0,4) == 'col3' && n == 2)
  //   {
  //   if (this.dir == 'asc')
  //     {
  //       this.dir = 'desc'
  //       this.reasonCodes.sort(this.sortByDescriptionAsc)
  //     }
  //     else 
  //     {
  //       this.dir = 'asc'
  //       this.reasonCodes.sort(this.sortByDescriptionDesc)
  //     }
  //   }
  // }
  
  
  sortByTypeAsc(a1: IReasonCode, a2: IReasonCode) { 
    return Helper.cmpString(a1.type, a2.type); 
  }
  
  sortByTypeDesc(a1: IReasonCode, a2: IReasonCode) { 
    return Helper.cmpStringDesc(a1.type, a2.type); 
  }

  
  sortByCodeAsc(a1: IReasonCode, a2: IReasonCode) { 
    return Helper.cmpString(a1.code, a2.code); 
  }
  
  sortByCodeDesc(a1: IReasonCode, a2: IReasonCode) { 
    return Helper.cmpStringDesc(a1.code, a2.code); 
  }
  
  sortByDescriptionAsc(a1: IReasonCode, a2: IReasonCode) { 
    return Helper.cmpString(a1.description, a2.description); 
  }
  
  sortByDescriptionDesc(a1: IReasonCode, a2: IReasonCode) {
    return Helper.cmpStringDesc(a1.description, a2.description); 
  }

  ngAfterViewChecked() {
    console.log('reasonCodes.component.AfterViewChecked');

    const c1 = $('.col1').width();
    const c2 = $('.col2').width();
    const c3 = $('.col3').width();

    $('#col1').width(c1 + 1);
    $('#col2').width(c2);
    $('#col3').width(c3);

    $('.SearchBar').width(c1 + c2 + c3 + 13);

    const th = $('thead').width();
    $('#Cover').width(th);
  }
  ngAfterViewInit() {

        console.log('File.Component.AfterViewInit');
        // runs every time the user re-sizes the window
            $(window).resize(function(){
          // gets td column widths
            const c1 = $('.col1').width();
            const c2 = $('.col2').width();
            const c3 = $('.col3').width();
            const th = $('thead').width();

          // sets th column widths to match td's
            $('#Cover').width(th);
            $('#col1').width(c1 + 1);
            $('#col2').width(c2);
            $('#col3').width(c3);

            $('.SearchBar').width(c1 + c2 + c3 + 2);
            console.log ('resize header occured');
            });
      }
ngAfterContentInit() {
  $(document).ready(function(){
    $('.SearchField').focus();
  });
}
}
