import { Pipe, PipeTransform } from '@angular/core';
import { IWorkCenter } from '../models/workcenter.model';

@Pipe({
  name: 'filterWorkCenter'
})
export class FilterWorkCenterPipe implements PipeTransform {

  transform(workCenters: any, term: any): any {
    // check if term is undefined
    if (term === undefined || term.length == 0 || workCenters === null) {
      return workCenters;
    }
    // return the updated routers array
    return workCenters.filter(function(w:IWorkCenter){
      let lterm = term.toLowerCase(); 
      //console.log('FilterWorkCenterPipe term:' + lterm);
      return (w.warehouse && w.warehouse.toLowerCase().includes(lterm)) || (w.name && w.name.toLowerCase().includes(lterm)) || (w.description && w.description.toLowerCase().includes(lterm)) || (w.scheduleType && w.scheduleType.toLowerCase().includes(lterm)) || (w.code && "n".concat(w.code.toString()).includes(lterm));
    });
  }
}
// || w.description.toLowerCase().includes(term.toLowerCase())
