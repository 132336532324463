import {Component,Input,OnInit} from '@angular/core';
import { Observable } from 'rxjs';

export interface DDLDelegate {
  //reasons: string[];
  selectedItemFromDDL(selectedItem:string): Observable<any>;  
}

@Component({
  selector: 'ngbd-dropdown',
  template: require('./ngbdropdown.component.html'),
  styles: [require('./ngbdropdown.component.css').toString()]
})

export class NgbdDropdown implements OnInit {

  //@Input() items: string[]; 
  @Input() delegate: DDLDelegate; 
  @Input() items: string[];
  @Input() name: string;
 

  constructor(){ 
   // this.name = name;   
  }

  ngOnInit() {   
    
  }

  selectedItem(e:any)
  {      
     this.delegate
          .selectedItemFromDDL(e)
          .subscribe( 
              selected => {
              //Do something or check something;
              console.log('ngbdropdown.component.selectedItem delegated to parent'); 
              if (selected != null || selected != '')
              {
                console.log('*********************************************************ngbdropdown.component.selectedItem value was written to database');
              }
              },
              error => {
                console.log('ngbdropdown.component.selectedItem returned error'); 
              }
          );
  }

}