// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*Details Container*/\r\n/**********************************************************************/\r\n.detailContainer {\r\n    border: 1px solid #dfdfdf;\r\n    background-color: #fafafa;\r\n    border-radius: 5px;\r\n    width: 100%;\r\n    margin: auto; \r\n    box-shadow: 0px 2px 3px 1px rgba(158,158,158,.38);\r\n}\r\n/*Tab Navigation Bar*/\r\n/**********************************************************************/\r\n.tabNavigationBar {\r\n    background-color: #78909c;\r\n    height: 36px;\r\n    border-radius: 5px 5px 0px 0px;\r\n    width: auto;\r\n    padding: 5px;\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n.tabLink {\r\n    padding: 5px;\r\n    border-style: none;\r\n    color: #fafafa;\r\n    font-weight: 700;\r\n    font-size: 1em;\r\n    background-color: transparent;\r\n    width: 150px;\r\n    height: 35px;\r\n    border-radius: 5px;\r\n    margin: 0px 5px;\r\n    transition-duration: 50ms;\r\n}\r\n.tabLink:hover {\r\n    color: #CFD8DC;\r\n    transition-duration: 200ms;\r\n}\r\n.tabColor {\r\n    color: #616161;\r\n    border: 1px solid #e0e0e0;\r\n    background-color: #fafafa;\r\n}\r\n.isVisible {\r\n    display: block;\r\n}\r\n.notVisible {\r\n    display: none;\r\n}\r\n/**********************************************************************/", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();