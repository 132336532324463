import { AppHttpClientService } from './app-http-client.service';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { IReasonCode, ReasonCodeUtils } from '../models/reasoncode.model';
import { Http, Response } from '@angular/http';
import { Helper } from './helper.service';
// import { AuthService } from '../../core/services/auth.service';
import { Config } from '../shared/gatekeeper/config';
import { MethodType, MethodTypes } from '../enums/methodtype';
import { GateKeeperService } from '../shared/gatekeeper/user/user.service';
import 'rxjs/add/observable/throw';



@Injectable()
export class ReasonCodeServiceHttp {


  constructor(private http: Http, private helper: Helper,
    private gkauth: GateKeeperService,
    // private auth: AuthService,
  private ahttp: AppHttpClientService) { }


   createReasonCode(r: IReasonCode): Observable<IReasonCode> {
    console.log('ReasonCodeServiceHttp.createReasonCode');
    const info = ReasonCodeUtils.toServerInfo(r);

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'reasonCodes/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
    //return this.ahttp.post('reasonCodes/', info).map( (response: Response) => {
       //console.log(response.json());
       const ret =  this.translateToIReasonCode(response);
       //console.log(ret);
       return ret;
    });
   }
   getReasonCode(id: string ): Observable<IReasonCode> {
    console.log('ReasonCodeServiceHttp.getReasonCode');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'reasonCodes/' + id;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
   
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
    //return this.ahttp.get('reasonCodes/' + id).map( (response: Response) => {
       //console.log(response.json());
       const ret =  this.translateToIReasonCode(response);
       //console.log(ret);
       return ret;
    });
  }
   getReasonCodes(): Observable<IReasonCode[]> {

    console.log('ReasonCodeServiceHttp.getReasonCodes');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'reasonCodes/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
   
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
    //return this.ahttp.get('reasonCodes/').map( (response: Response) => {
       //console.log(response);
       const ret =  this.translateToIReasonCodes(response);
       //console.log(ret);
       return ret;
    });
   }
    updateReasonCode(r: IReasonCode ): Observable<IReasonCode> {
      console.log('ReasonCodeServiceHttp.updateReasonCode');
      const info = ReasonCodeUtils.toServerInfo(r);

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'reasonCodes/UpdateReasonCode/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        // console.log(response.json);
         const ret =  this.translateToIReasonCode(response);
        // console.log(ret);
         return ret;
      });
    }

    deleteReasonCode(id: string): Observable<number> {
      console.log('ReasonCodeServiceHttp.deleteReasonCode');


      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'reasonCodes/' + id;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.DELETE);
      Config.gkParameters.JsonParameters = "";

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         return Number(response)
      });
    }


private handleError(error: Response) {
  return Observable.throw(error.statusText);
}
translateToIReasonCode(data: any): IReasonCode {
  if (data) {
    return ReasonCodeUtils.toIReasonCode(data);
  } else {
    return null;
  }
}

translateToIReasonCodes(data: any): IReasonCode[] {
  const ret: IReasonCode[] = [];
  if (data && data.length > 0) {
    for (const d of data){
        ret.push(this.translateToIReasonCode(d));
    }
  }
  return ret;
}




}






