import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReasonCodeComponent } from '../reason-code/reason-code.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([
            {path: 'reasonCode', component: ReasonCodeComponent, data: {breadcrumb: 'Reason Code Maintenance'}}
        ])
    ],
    declarations: []
})
export class ReasonCodeModule { }