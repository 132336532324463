import * as process from 'process';
import { Component, HostListener } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { GateKeeperService} from './core/shared/gatekeeper/user/user.service';
import { LoginService } from './core/services/login.service';
import { Helper} from './core/services/helper.service';


@Component({
  selector: 'app-root',
  template: require('./app.component.html'),
  styles: [require('./app.component.css')]
  //styles: [require('./app.component.css').toString()]
})
export class AppComponent {

constructor(private auth: AuthService, private loginService: LoginService) { }

  @HostListener('window:unload', [ '$event' ])
  unloadHandler(event) {
  // this.auth.loginScan('/66NB');
  // this.auth.unlockItem(null).subscribe(res1 => {
  //  this.auth.logout().subscribe(res2 => { } );
  // });

   this.auth.unlockItem('').subscribe(ret => { });    
   this.loginService.logoutUser().subscribe(res => {});
  }

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHander(event) {
    // this.auth.loginScan('/66NB');
    this.auth.unlockItem('').subscribe(ret => { });
    this.loginService.logoutUser().subscribe(res => {});
  }


}
