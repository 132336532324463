import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterWorkStationAvailable'
})
export class FilterWorkStationPipe implements PipeTransform {

  transform(workStations: any, term: any): any {
    console.log('FilterWorkStationPipe.transform');
    console.log(term);
    // check if term is undefined
    if (term === undefined || term.length == 0 || workStations === null) {
      return workStations;
    }
    // return the updated routers array
    return workStations.filter(
        function(w){
          return w.workCenterDescription.toLowerCase().includes(term.toLowerCase()) 
          || w.workStationNumber.toLowerCase().includes(term.toLowerCase())
          || w.description.toLowerCase().includes(term.toLowerCase());
    });
  }
}
// || w.description.toLowerCase().includes(term.toLowerCase())
