import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReportsComponent } from './reports.component';
import { DemandItemNoBOMComponent} from '../reports/report_demanditemnobom/demanditemnobom.component';
import { DemandItemNoDrawingComponent} from '../reports/report_demanditemnodrawing/demanditemnodrawing.component';
import { DemandItemNoRouterComponent} from '../reports/report_demanditemnorouter/demanditemnorouter.component';
import { DemandItemRouterOnHoldComponent} from '../reports/report_demanditemrouteronhold/demanditemrouteronhold.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([
            {path: 'reports', component: ReportsComponent, data: {breadcrumb: 'Reports'}            },
            { path: 'reports/demanditemnobom', component: DemandItemNoBOMComponent, data: {breadcrumb: 'Demand Item No BOM Report'}},
            { path: 'reports/demanditemnodrawing', component: DemandItemNoDrawingComponent, data: {breadcrumb: 'Demand Item No Drawing Report'}},
            { path: 'reports/demanditemnorouter', component: DemandItemNoRouterComponent, data: {breadcrumb: 'Demand Item No Router Report'}},
            { path: 'reports/demanditemrouteronhold', component: DemandItemRouterOnHoldComponent, data: {breadcrumb: 'Demand Item Router On Hold Report'}}, 
        
        
        
        
        ])
    ],
    declarations: []
})
export class ReportsModule { }