// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CurrentNotes{\r\n    color:#161313;\r\n    background-color:#f2f2f2;\r\n    font-family:'Open Sans', sans-serif;\r\n    font-size:14px; \r\n    height:200px; \r\n    width: 98%; \r\n    overflow-y:auto;\r\n    padding-left: 10px;\r\n}\r\n.FormGroupContainer {\r\n    padding: 25px 20px;\r\n    width: 90%;\r\n    height: 98%;\r\n    margin: auto;\r\n}\r\n.LabelContainer {\r\n    height: 40px;\r\n    background-color: #e5e5e5;\r\n    padding: 0px 5px;\r\n}\r\ndiv.AddNotes {\r\n    line-height: 2;\r\n    font-size: 1.2em;\r\n    color: black;\r\n}\r\ndiv.FieldName {\r\n    line-height: 2;\r\n    font-size: 1.2em;\r\n    color: #7b8d93;\r\n}\r\n.InputField {\r\n    width: 98%;\r\n    height: 200px;\r\n    resize: vertical;\r\n    /* min-height: 200px; */\r\n    overflow-y: scroll;\r\n    padding: 0;\r\n    margin: 1px -1px;\r\n    white-space: pre-line;\r\n    font-size: 1em;\r\n    font-family: inherit;\r\n}\r\n.NewNotes{\r\n    color:#161313;\r\n    background-color: white;\r\n    font-family:'Open Sans', sans-serif;\r\n    font-size:14px; \r\n    height:200px; \r\n    width: 98%; \r\n    overflow-y:auto;\r\n    padding-left: 10px;\r\n}\r\n/* .textArea {\r\n    max-height: 70vh;\r\n} */\r\n", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();