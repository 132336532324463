import { Component, OnInit, Output, EventEmitter, AfterContentChecked, OnDestroy } from '@angular/core';

@Component({
  selector: 'tool-kit-management',
  template: require('./tool-kit-management.component.html'),
  // styles: [require('./tool-kit-management.component.css')]
  styles: [require('./tool-kit-management.component.css').toString()]
})
export class ToolKitManagementComponent implements OnInit, OnDestroy, AfterContentChecked {

  @Output() kitModal = new EventEmitter;
  modified = false;
  selected = false;
  overlayWasVisible = false;

  constructor() { }

  ngOnInit() {
    console.log('tool-kit-management.component.ngOnInit');
  }

  ngOnDestroy() {
    console.log('tool-kit-management.component.ngOnDestroy');
  }

  get isOverlayVisible() {
    return false; // this.reasonCodeForm.nativeElement.offsetParent != null;
  }

  ngAfterContentChecked() {
    console.log('tool-kit-management.component.ngAfterContentChecked');
    if (!this.isOverlayVisible && this.overlayWasVisible) {
      console.log('tool-kit-management.component.ngAfterContentChecked...visible to hidden ');
      this.overlayWasVisible = false;
      // this.resetFormValidation();
    } else if (this.isOverlayVisible && !this.overlayWasVisible) {
      console.log('tool-kit-management.component.ngAfterContentChecked...hidden to visible');
      this.overlayWasVisible = true;
     // this.userSubmitted = false;
      // this.resetFormValidation();
    }
  }

  modalToggle() {
    this.kitModal.emit(false);
  }

}
