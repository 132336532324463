import { Injectable, OnInit } from '@angular/core';
import { Http, Headers, Response, RequestOptions, ResponseOptions} from '@angular/http';
import { IUser} from './user.response';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/finally';
import 'rxjs/add/observable/throw';
import { AlertService} from '../../../services/alert.service';
import { AlertLevel } from '../../../models/alert.model';
import { Helper } from '../../../services/helper.service';
import { Config } from '../config';
import {IUserInfo} from '../../../models/usermaintenance.model'




@Injectable()
export class GateKeeperService implements OnInit {

  public currentUserInfo: IUserInfo =  null;

  public currentUser: IUser = {
    resultType: true,
    resultMessage: null,
    result: null,
    TokenID: '',
    UserID: '',
    UserName: '',
    EmpID: 0,
    Location: '',
    PermissionList: ['None', 'None']
  };

  userPermissions: string[] = ['None', 'None'];
  DEVICEID_KEY = 'deviceIdKey';
  TOKEN_ID = 'tokenID';
  tokenId = '';

  systemLock = null;

 // forceLogin: any = null;

  // set forceLogin(val: any){
  //   this.forceLoginUser = val;
  //   // this.login = true;
  //   this.router.navigate(['/login']);
  // }


  //alertEnabled = true;

  toh = null;

constructor(
  private http: Http,
  private utils: Helper, private router: Router,  
  private alert: AlertService) {}


ngOnInit() {
  console.log('user auth service authenticating against GateKeeper');
}

showSpinner() {
  if (this.toh != null) {
    return;
  }
  this.toh = setTimeout(() => {
    Helper.showSpinner = true;
  }, 1000);
}

hideSpinner() {
  clearTimeout(this.toh);
  Helper.showSpinner = false;
  this.toh = null;
}

finallyHandler() {
  // console.log('app-http-client.service.finallyHandler');
  this.hideSpinner();
}

get httpOptions() {

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('Cache-Control','max-age=3600, no-cache, public');
  headers.append('Access-Control-Allow-Methods', 'POST,GET, DELETE, PUT, OPTIONS, HEAD');
  const options = new RequestOptions({headers : headers});

  return options;
}



  post(url: string, info: any, errorHandler: Function = null) {
    console.log("user_service post:" + info.UriDestination);
    console.log(" body:" + info.JsonParameters); 
    return this.do_post(Config.gateKeeperUrl + 'api/Authenticate',
    JSON.stringify(
    {
      'TokenID': info.TokenID,
      'DeviceID': info.DeviceID,
      'MethodType': info.MethodType,
      'UriDestination': info.UriDestination,
      'JsonParameters': info.JsonParameters,
      'GateKeeperUri': info.GateKeeperUri,
      'BaseURI': info.BaseURI,
      'ApplicationName': info.ApplicationName
    }), errorHandler,info.UriDestination);

  }

  private _forceLogin: any;

  set forceLogin(val: any){
    this._forceLogin = val;
  }

  get forceLogin(): any {return this._forceLogin}
  alertEnabled = true;

  do_get(url: string, info: any, errorHandler: Function = null) {
    console.log('user.service.do_get url:' + url);
    this.showSpinner();
    return this.http.get(url,
    info)
    .map(response => {
      console.log('...map');
      console.log(response);
      const ret = response != null ? response.json() : response;
      if (ret.resultType == false || // Gatekeeper generic error?
        (ret.resultType == true &&  !this.utils.isEmptyString(ret.resultMessage))) { //web api actionable error?
        //throw new ErrorResponse(ret.resultMessage, response.status); // send error through catch() error handler
      }
     else {
        return ret;
      }

      }).finally(()=> this.finallyHandler())
      .catch((error) => this.handleOnshapeError(error, errorHandler));
  }


  do_post(url: string, info: any, errorHandler: Function = null, ref:any = null) {
        
        this.showSpinner();
        return this.http.post(url,info,
        this.httpOptions)
        .map(response => {
          if (ref) console.log('do_post rsp ref:' + ref);
    
          const ret = response != null ? response.json() : response;
          console.log(ret);
          if (
            ret != null && 
            !this.utils.isUndefined(ret.resultType) &&
            (
              ret.resultType == false || // Gatekeeper generic error?
              (ret.resultType == true &&  !this.utils.isEmptyString(ret.resultMessage))) 
          ){ //web api actionable error?
            throw new ErrorResponse(ret.resultMessage, response.status); // send error through catch() error handler
          }
         else {
            return ret;
          }

          }).finally(()=> this.finallyHandler())
          .catch((error) => this.handleError(error, errorHandler) );

      }

  logout(url: string, info: any, successHandler: Function = null) {

    return this.http.delete(url, this.httpOptions).map(response => {
        const ret = response != null ? response.json() : response;
        if (ret.resultType == false) {
          throw new ErrorResponse(ret.resultMessage, response.status);
        }
        else {
          this.currentUser = null;
          this.currentUserInfo = null;
         return ret;
        }
        // if (ret.resultMessage === 'Could Not Authenticate') {
        //   alert('Unfortunately we could not log you out.');
        //   return successHandler(ret);
        // } else {
        //     // this.setCurrentUser(ret);
        //     this.currentUser = null;
        //     return ret;
        // }
    }).finally(()=> this.finallyHandler())
    .catch((error) => this.handleError(error) );
  }


  setCurrentUser(data: any) {
    this.currentUser = <IUser> data;
    this.userPermissions = [];
    if (this.currentUser != null) {
      this.userPermissions = this.currentUser.PermissionList;
      this.tokenId = this.currentUser.TokenID;      
      Config.gkParameters.TokenID = this.currentUser.TokenID;
    }
    }

  getCurrentUser(): IUser {
    return this.currentUser;
  }

  hasPermission(perm: string): boolean {
    let ret = false;

    if (this.currentUser != null && !this.isEmptyArray(this.currentUser.PermissionList)) {
      const i = this.userPermissions.indexOf(perm);
      ret = i > -1;
    }
    return ret;
  }

  isEmptyArray(a): boolean {
    return (a == null || a.length === 0);
}


// delete1(url: string, successHandler: Function = null) {

//   this.alert.showYesNo('Are you sure you want to delete this item?', AlertLevel.WARNING,
//     (res: AlertReponse) => {
//       if (res === AlertReponse.YES) {
//         this.showSpinner();
//         return this.http.delete(Helper.WEBAPIHOST + url, this.httpOptions)
//         .finally(() =>  this.finallyHandler())
//         .catch((error) => this.handleError(error) );
//       }  else {
//         return Observable.of(null);
//       }
//     }
//  );
// }

// delete3(url: string, successHandler: Function = null) {
//   return new Observable(observer => {
//     this.alert.showYesNo('Are you sure you want to delete this item?', AlertLevel.WARNING,
//     (res: AlertReponse) => {
//       if (res === AlertReponse.YES) {
//         this.showSpinner();
//         return this.http.delete(Helper.WEBAPIHOST + url, this.httpOptions)
//         .finally(() =>  this.finallyHandler())
//         .catch((error) => this.handleError(error) );

//       }  else {
//         return Observable.of(null);
//       }
//     }
//  );
//   });
// }

delete(url: string, successHandler: Function = null) {
this.showSpinner();
return this.http.delete(Helper.WEBAPIHOST + url, this.httpOptions)
.finally(() =>  this.finallyHandler())
.catch((error) => this.handleError(error) );
}

//   handleErrors(error: Response) {
//     console.log(JSON.stringify(error.json()));
//     if (error.json().resultMessage = 'Could Not Authenticate') {
//       return Observable.throw('Unauthorized');
//     }  else { return Observable.throw(error); }
//   }
// }


// lockItem(url: string, info: any, successHandler: Function = null): Observable<number> {
//   // const info = {
//   //   tokenId: this.currentUser.TokenID,
//   //   itemId: itemId
//   // };

//   this.systemLock = null;

//   return this.http.post(url, info, this.httpOptions).map(response => {
//     const ret = response != null ? response.json() : response;
//     if (ret.resultMessage === 'Could Not Authenticate') {
//       alert('Unfortunately we could not log you in.');
//       return successHandler(ret);
//     } else {
//       this.systemLock = ret;
//       return ret;
//     }
// }).do(data => {return data;
// }).catch((error) => this.handleError(error) );







//   return this.http.post('auth/CreateSystemLock', info).map(
//     (resp: Response ) => {
//       console.log('auth.service.CreateSystemLock returns');
//       console.log(resp);
//       this.systemLock = resp.json();
//         return 1;
//     });


// }


//  unlockItem(url: string, info: any, successHandler: Function = null): Observable<number> {
//   // const info = {
//   //   tokenId: this.currentUser.TokenID,
//   //   itemId: itemId
//   // };

//   this.systemLock = null;

//   return this.http.delete(url, this.httpOptions).map(response => {
//     const ret = response != null ? response.json() : response;
//     if (ret.resultMessage === 'Could Not Authenticate') {
//       alert('Unfortunately we could not log you in.');
//       return successHandler(ret);
//     } else {
//         this.systemLock = null;
//         return ret;
//     }
// }).do(data => {return data;
// }).catch((error) => this.handleError(error) );
//  }


// unlockItem(itemId: string): Observable<number> {
//   console.log('auth.service.unlockItem');
//   if (this.systemLock != null) {

//     return this.http.delete('auth/DeleteSystemLock/' + this.systemLock.id)
//     .map((resp: Response) => {
//      this.systemLock = null;
//       return 1;
//     });
//   } else  {
//     return Observable.of(1);
//   }
// }

isJson(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}


getResponseMessage(error:Response) {
  console.log('user.service.getResponseMessage');
  console.log(error);

  let ret = 'Unable to connect'; //cannot communicate with Gatekeeper
  try {
      if (error && error.status > 0) {
        ret = error.json().resultMessage; //Gatekeeper or web api error message?
      }
  } catch (e) {
      ret = 'Unknown error'; // no json payload, most likely an http error not returned by Gatekeeper
  } finally {
    return ret;
  }
}


handleError(error: Response, errorHandler: Function = null) {
 console.log('user.service.handleError: ');
 console.log(error);
 let msg = this.getResponseMessage(error);
  console.log(msg);

  const isAuthFailure = error && msg == 'Could Not Authenticate';
  const doForceLogin = isAuthFailure && this.forceLogin != null && !this.forceLogin.login;


  if (msg && doForceLogin) {
    msg = msg + '. Redirecting to login screen.';
  }

  console.log(msg);
  console.log('isAuthFailure:' + isAuthFailure);
  console.log('alertEnabled:' + this.alertEnabled);
  console.log('forceLogin:' + this.forceLogin);
  if (this.alertEnabled) {
    this.alert.showOk(msg, AlertLevel.WARNING, (rsp) => {
      if (errorHandler) {
        errorHandler();
      }

      if (doForceLogin) {
        console.log('app-http-client.service. navigating to /routers');
        this.alertEnabled = false;
        this.forceLogin.forceLogin();
      }
    });
  }

  return Observable.throw(msg);
}

handleOnshapeError(error: Response, errorHandler: Function = null) {

  console.log('user.service.handleOnshapeError: ');
  let msg = this.getResponseMessage(error);
  console.log(msg);

  if (this.alertEnabled) {
    this.alert.showOk(msg, AlertLevel.WARNING, (rsp) => {
      if (errorHandler) {
        errorHandler();
      }      
    });
  }

    return Observable.throw(msg);
}

/*

handleGKError(error: Response) {

 let errorMsg:any = '';
 let msg = '';

    errorMsg = error
    if (errorMsg.resultMessage == '') {
    msg = '';
    } else  {
      msg = errorMsg.resultMessage;
      }

  const isAuthFailure = error && errorMsg.resultMessage == 'Could Not Authenticate'
  const doForceLogin = isAuthFailure  && this.forceLogin != null && !this.forceLogin.login;

    if (msg && doForceLogin) {
    msg = msg + '. Redirecting to login screen.';
  }

  console.log(msg);
  console.log('isAuthFailure:' + isAuthFailure);

  if (this.alertEnabled) {
    this.alert.showOk(msg, AlertLevel.WARNING, (rsp) => {
      if (doForceLogin) {

        this.alertEnabled = false;
        this.forceLogin.forceLogin();
      }
    });
  }
  this.finallyHandler();
  return Observable.throw(msg);
}
*/
}




export class ErrorResponse extends Response {
constructor(msg: string, status: number) {
  var options = new ResponseOptions({
  body: '{"resultType":false, "resultMessage":"' + msg + '", "result":null}'
});
  super(options);
  this.statusText = msg;
  this.status = status;
 }
}






