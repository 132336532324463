import { RouterDomainService } from '../../core/services/router-domain.service';

export class StepNotesComponentBizLogic {
  
  constructor(private stepNotes: any, private domain:RouterDomainService) { }

  get areSetupNotesEditable(){
    return this.domain.isEditable;
  }

}
