import { AppHttpClientService } from './app-http-client.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import "rxjs/add/observable/of";
import 'rxjs/add/observable/throw';
import { ITool, IToolBag } from '../models/tool.model';
import { Http, Response } from '@angular/http';
import { Helper } from './helper.service';
// import { AuthService } from '../../core/services/auth.service';
import { IWorkCenterToolKit, WCToolBagOrder, IToolBagTool, ToolUtils, IToolBagServer, IFromToIds } from '../models/tool.model';
import { GateKeeperService} from '../shared/gatekeeper/user/user.service';
import { Config } from '../shared/gatekeeper/config';
import { MethodType, MethodTypes } from '../enums/methodtype';





@Injectable()
export class ToolServiceHttp {
  allTools: ITool[];

  constructor(private http: Http, private helper: Helper, 
    private ahttp: AppHttpClientService, 
    private gkauth: GateKeeperService , private Config: Config) {
    this.getTools().subscribe(tools => {
      this.allTools = tools;
    });
   }

   getToolIds(tools: ITool[]): string[] {
    const ret: string[] = [];

    if (!this.helper.isEmptyArray(tools)) {
      for (const t of tools) {
        ret.push(t.id);
      }
    }
    return ret;
   }

   cacheAddTool(t: ITool) {
    if (t && !this.helper.isEmptyArray(this.allTools)) {
      this.allTools = this.allTools.filter(x => x.id !== t.id);
      this.allTools.push(t);
    }
   }

   cacheRemoveTool(id: string) {
     console.log('tool.servicehttp.cacheRemoveTool id:' + id + ' len:' + this.allTools.length);
     this.allTools = this.allTools.filter(x => x.id !== id);
     console.log('...len:' + this.allTools.length);
   }

// TOOLS


   createTool(info: ITool): Observable<ITool> {
    console.log('ToolServiceHttp.createTool');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'tools/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
   
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       //console.log(response);
       const ret =  this.translateToITool(response);
       //console.log(ret);
       this.cacheAddTool(ret);
       return ret;
    });
   }
   getTool(id: string ): Observable<ITool> {
    console.log('ToolServiceHttp.getTool');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'tools/' + id;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       //console.log(response);
       const ret =  this.translateToITool(response);
       //console.log(ret);
       return ret;
    });
  }
   getTools(): Observable<ITool[]> {
    console.log('ToolServiceHttp.getTools');
    if (!this.helper.isEmptyArray(this.allTools)) {
      return Observable.of<ITool[]>(this.allTools);
    } else {

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'tools/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
               const ret =  this.translateToITools(response);
               return ret;
            });
      }
   }
    updateTool(info: ITool ): Observable<ITool> {
      console.log('ToolServiceHttp.updateTool');
      //console.log(info);

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'tools/UpdateTool/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);         

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         //console.log(response);
         const ret =  this.translateToITool(response);
         //console.log(ret);
         this.cacheAddTool(ret);
         return ret;
      });
    }

    deleteTool(id: string): Observable<number> {
      console.log('ToolServiceHttp.deleteTool');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'tools/' + id;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.DELETE);
      Config.gkParameters.JsonParameters = '';

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        this.cacheRemoveTool(id);
        return Number(response);
      });
    }



  updateWorkCenterToolBagsOrder(toolBagOrder: WCToolBagOrder): Observable<number> {
    console.log('TestServiceHttp.updateWorkCenterToolBagsOrder');
    console.log(toolBagOrder);

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'tools/updateWorkCentetrToolBagsOrder';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(toolBagOrder);
   
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        return Number(response);
    });
  }


createWorkCenterToolKit(wcId: string, description): Observable<IWorkCenterToolKit> {
  console.log('ToolServiceHttp.createWorkCentetToolKit');
  const info = {
    workCenterId: wcId,
    description: description
  };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'WorkCenterToolKits/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
   
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     //console.log(response);
     const ret =  this.translateToIWorkCenterToolKit(response);
     //console.log(ret);
     return ret;
  });
 }

 getWorkCenterToolKit(id: string): Observable<IWorkCenterToolKit> {
  console.log('ToolServiceHttp.getWorkCenterToolKit');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'WorkCenterToolKits/' + id;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const ret =  this.translateToIWorkCenterToolKit(response);
     return ret;
  });
 }

 getWorkCenterToolKits(): Observable<IWorkCenterToolKit[]> {
  console.log('ToolServiceHttp.getWorkCenterToolKits');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'WorkCenterToolKits/';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const ret =  this.translateToIWorkCenterToolKits(response);
     //console.log(ret);
     return ret;
  });
 }


 getWorkCenterAndRouterStepToolSets(): Observable<IWorkCenterToolKit[]> {
  console.log('ToolServiceHttp.getWorkCenterAndRouterStepToolSets');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'WorkCenterToolKits/RetrieveAllPlusRouterStepToolSets';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const ret =  this.translateToIWorkCenterToolKits(response);
     //console.log(ret);
     return ret;
  });
 }

  updateWorkCenterToolKit(info: IWorkCenterToolKit ): Observable<IWorkCenterToolKit> {
    console.log('ToolServiceHttp.updateWorkCenterToolKit');
    //console.log(info);
    
    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'WorkCenterToolKits/Update';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       //console.log(response);
       const ret =  this.translateToIWorkCenterToolKit(response);
       //console.log(ret);
       return ret;
    });
  }

  updateWorkCenterToolKit1(info: IWorkCenterToolKit): Observable<boolean> {
    console.log('ToolServiceHttp.updateWorkCenterToolKit1');
    //console.log(info);

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'WorkCenterToolKits/Update';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       //console.log(response);
       return true;
    });
  }

  deleteWorkCenterToolKit(id: string): Observable<number> {
    console.log('ToolServiceHttp.deleteWorkCenterToolKit');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'WorkCenterToolKits/' + id;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.DELETE);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      return Number(response);
    });
  }


  addWorkCenterToolKitToolBag(id: string, toolBagId: string): Observable<number> {
    console.log('ToolServiceHttp.addWorkCenterToolKitToolBag');
    const info = {
      workCenterToolKitId: id,
      toolBagId: toolBagId
    };
    //console.log(info);
    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'WorkCenterToolKits/AddWorkCenterToolKitToolBag/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       const ret = Number(response);
       //console.log(ret);
       return ret;
    });
  }

  removeWorkCenterToolKitToolBag(id: string, toolBagId: string): Observable<number> {
    console.log('ToolServiceHttp.removeWorkCenterToolKitToolBag');
    const info = {
      workCenterToolKitId: id,
      toolBagId: toolBagId
    };
    console.log(info);

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'WorkCenterToolKits/RemoveWorkCenterToolKitToolBag/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       const ret = Number(response);
       console.log(ret);
       return ret;
    });
  }



  appendAllToolBagsToWorkCenterToolKit(routerStepId: string, workCenterToolKitId: string): Observable<IFromToIds[]> {
    console.log('ToolServiceHttp.appendAllToolBagsToWorkCenterToolKit');
    const info = {
      targetId: routerStepId,
      sourceId: workCenterToolKitId
    };
    console.log(info);
    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'WorkCenterToolKits/AppendAllToolBags/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
   
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const ret = this.translateToIFromToIdsList(response);
       console.log(ret);
       return ret;
    });
  }

  replaceAllToolBagsToWorkCenterToolKit(routerStepId: string, workCenterToolKitId: string): Observable<IFromToIds[]> {
    console.log('ToolServiceHttp.replaceAllToolBagsToWorkCenterToolKit');
    const info = {
      targetId: routerStepId,
      sourceId: workCenterToolKitId
    };
    console.log(info);

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'WorkCenterToolKits/ReplaceAllToolBags/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
   
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const ret = this.translateToIFromToIdsList(response);
       console.log(ret);
       return ret;
    });
  }

  copyToolBagsToWorkCenterToolKit(routerStepId: string, ids: string[]): Observable<IFromToIds[]> {
    console.log('ToolServiceHttp.copyToolBagsToWorkCenterToolKit');
    const info = {
      targetId: routerStepId,
      sourceId: null,
      sourceIds: ids
    };
    console.log(info);

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'WorkCenterToolKits/AppendToolBags/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const ret = this.translateToIFromToIdsList(response);
       console.log(ret);
       return ret;
    });
  }




  getWorkCenterToolKitTools( wcToolKitCenterId: string): Observable<IToolBagTool[]> {
    console.log('ToolServiceHttp.getWorkCenterToolKitTools');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'WorkCenterToolKits/GetTools/' + wcToolKitCenterId;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        const ret =  this.translateToIToolBagTools(response);
      return ret;
    });
  }
// ROUTER OPERATION TOOL SET

addRouterOperationToolBag(routerStepId: string, toolBagId: string): Observable<number> {
  console.log('ToolServiceHttp.addRouterOperationToolBag');
  const info = {
    routerOperationId: routerStepId,
    toolBagId: toolBagId,
    userId: this.gkauth.currentUser.UserID
  };
  console.log(info);

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'RouterOperationToolSet/AddToolBag/' ;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);
  
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const ret = Number(response);
      console.log(ret);
     return ret;
  });
}

removeRouterOperationToolBag(routerStepId: string, toolBagId: string): Observable<number> {
  console.log('ToolServiceHttp.removeRouterOperationToolBag');
  const info = {
    routerOperationId: routerStepId,
    toolBagId: toolBagId,
    userId: this.gkauth.currentUser.UserID
  };
  console.log(info);

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'RouterOperationToolSet/RemoveToolBag/' ;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);
  
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const ret = Number(response);
     console.log(ret);
     return ret;
  });
}


appendAllToolBagsToRouterStep(routerStepId: string, workCenterToolKitId: string): Observable<IFromToIds[]> {
  console.log('ToolServiceHttp.appendAllToolBagsToRouterStep');
  const info = {
    targetId: routerStepId,
    sourceId: workCenterToolKitId,
    userId: this.gkauth.currentUser.UserID
  };
  console.log(info);

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'RouterOperationToolSet/AppendAllToolBags/' ;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);
  
  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const ret = this.translateToIFromToIdsList(response);
     console.log(ret);
     return ret;
  });
}

replaceAllToolBagsToRouterStep(routerStepId: string, workCenterToolKitId: string): Observable<IFromToIds[]> {
  console.log('ToolServiceHttp.replaceAllToolBagsToRouterStep');
  const info = {
    targetId: routerStepId,
    sourceId: workCenterToolKitId,
    userId: this.gkauth.currentUser.UserID
  };
  console.log(info);

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'RouterOperationToolSet/ReplaceAllToolBags/' ;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);
 
  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
    const ret = this.translateToIFromToIdsList(response);
     console.log(ret);
     return ret;
  });
}

copyToolBagsToRouterStep(routerStepId: string, ids: string[]): Observable<IFromToIds[]> {
  console.log('ToolServiceHttp.copyToolBagsToRouterStep');
  const info = {
    targetId: routerStepId,
    sourceId: null,
    sourceIds: ids,
    userId: this.gkauth.currentUser.UserID
  };
  console.log(info);

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'RouterOperationToolSet/AppendToolBags/' ;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);
 
  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const ret = this.translateToIFromToIdsList(response);
     console.log(ret);
     return ret;
  });
}

getRouterStepTools( routerStepId: string): Observable<IToolBagTool[]> {
  console.log('ToolServiceHttp.getRouterStepTools');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'RouterOperationToolSet/GetTools/' + routerStepId ;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
    const ret =  this.translateToIToolBagTools(response);
    return ret;
  });
}



// TOOL BAGS

getRouterStepToolBags(routerStepId: string): Observable<IToolBag[]> {
  console.log('ToolServiceHttp.getRouterStepTools');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'ToolBags/GetByRouterOperationId/' + routerStepId ;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
    const ret =  this.translateToIToolBags(response);
    return ret;
  });
}

getWorkCenterToolBags( wcToolKitCenterId: string): Observable<IToolBag[]> {
  console.log('ToolServiceHttp.getWorkCenterToolKitToolBags');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'ToolBags/GetByWorkCenterToolKitId/' + wcToolKitCenterId ;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const ret =  this.translateToIToolBags(response);
      return ret;
  });
}

  createToolBag(toolBag: IToolBag): Observable<string> {
    console.log('ToolServiceHttp.createToolBag');
    const flatToolBag = this.flattenToolBag(toolBag);
    // let toolNumbers: string;
    // toolNumbers = this.getToolNumbers(toolBag.tools);

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'toolBags/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(flatToolBag);
    // Config.gkParameters.JsonParameters = '{"id":"' + btoa(toolBag.id)
    // + '"' + ',"description":"' +btoa(toolBag.description)
    // + '","notes":"' + btoa(toolBag.notes)
    // + '","toolNumbers":[' + toolNumbers + ']}';

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      return String(response);
    });
  }

  updateToolBag(toolBag: IToolBag): Observable<string> {
    console.log('ToolServiceHttp.updateToolBag');
    const flatToolBag = this.flattenToolBag(toolBag);    

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'toolBags/update';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(flatToolBag);
    // Config.gkParameters.JsonParameters = '{"id":"' + Helper.replaceDoubleQuote(toolBag.id)
    // + '"' + ',"description":"' + Helper.replaceDoubleQuote(toolBag.description)
    // + '","notes":"' + Helper.replaceDoubleQuote(toolBag.notes)
    // + '","toolNumbers":[' + toolNumbers + ']}';

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        return String(response);
    });
  }

  flattenToolBag(toolBag: IToolBag): any {
    return {
      id: toolBag.id,
      description: toolBag.description,
      notes: toolBag.notes,
      toolNumbers: this.getToolNumbers(toolBag.tools)
    };
  }

getToolNumbers(tools: ITool[]): string[] {
  const ret: string[] = [];
  if (this.helper.isEmptyArray(tools)) {
    return null;
  } else {
    for (const t of tools) {
      ret.push(t != null && t.number !== ToolUtils.TOOL_NONE_STR ? t.number : '');
    }
    return ret;
  }
}

// getToolNumbers(tools: ITool[]): string {
//   let ret = '';
//   let i: number;

//   if (this.helper.isEmptyArray(tools)) {
//      return null;
//    } else {
//      for (i = 0; i < tools.length; i++) {
//        ret += btoa(tools[i].number) + ','
//        //if (i === 0) {
//         // ret += '"' + tools[i].number + '"';
//        //} else {
//           // ret += ',"' + tools[i].number + '"';
//       //    }
//       }
//       ret = ret.substring(0, ret.length - 1);
//   }
//     return ret;
//   }


addToolBagTool(toolBagId: string, toolNumber: string, order: number) {
  console.log('ToolServiceHttp.addToolBagTool');
  const info = {
    toolBagId: toolBagId,
    number: toolNumber,
    order: order
  };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'toolbags/AddToolBagTool';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
    return 1;
  });
}
getToolBagTools(toolBagId: string) {
  console.log('ToolServiceHttp.getToolBagTools');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'toolbags/GetTools/' + toolBagId;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
    return 1;
  });
}

deleteToolBagTool(toolBagId: string, toolNumber: string) {
  console.log('ToolServiceHttp.deleteToolBagTool');
  const info = {
    toolBagId: toolBagId,
    number: toolNumber
  };

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'toolbags/DeleteTool';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);
 
  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
    return 1;
  });
}



private handleError(error: Response) {
  return Observable.throw(error.statusText);
}
translateToITool(data: any): ITool {
  if (data) {
    data.name = (<ITool>data).number + ' ' + (<ITool>data).description;
    return <ITool> data;
  } else {
    return null;
  }
}

translateToIToolBagTool(data: any): IToolBagTool {
  if (data) {
    return <IToolBagTool> data;
  } else {
    return null;
  }
}

translateToIToolBagTools(data): IToolBagTool[] {
  const ret: IToolBagTool[] = [];
  if (data && data.length > 0) {
    for (const d of data){
        ret.push(this.translateToIToolBagTool(d));
    }
  }
  return ret;
}


translateToIWorkCenterToolKit(data: any): IWorkCenterToolKit {
  if (data) {
    return <IWorkCenterToolKit> data;
  } else {
    return null;
  }
}

translateToIWorkCenterToolKits(data): IWorkCenterToolKit[] {
  const ret: IWorkCenterToolKit[] = [];
  if (data && data.length > 0) {
    for (const d of data){
        ret.push(this.translateToIWorkCenterToolKit(d));
    }
  }
  return ret;
}

translateToITools(data): ITool[] {
  const ret: ITool[] = [];
  if (data && data.length > 0) {
    for (const d of data){
        ret.push(this.translateToITool(d));
    }
  }
  return ret;
}

translateToIFromToIds(data: any): IFromToIds {
  if (data) {
    return <IFromToIds> data;
  } else {
    return null;
  }
}

translateToIFromToIdsList(data): IFromToIds [] {
  const ret: IFromToIds[] = [];
  if (data && data.length > 0) {
    for (const d of data){
        ret.push(this.translateToIFromToIds(d));
    }
  }
  return ret;
}


findToolByNumber(allTools: ITool[], number: string): ITool {
  console.log('tool.servicehttp.findToolByNumber ' + number);
  console.log(allTools);
   if (this.helper.isEmptyArray(allTools ) || this.helper.isEmptyString(number)) {
     return null;
   }

   return allTools.find(x => x.number.trim().toLowerCase() == number.trim().toLowerCase());
}

findToolById(allTools: ITool[], id: string): ITool {
  console.log('tool.servicehttp.findToolById ' + id);
  console.log(allTools);
   if (this.helper.isEmptyArray(allTools ) || this.helper.isEmptyString(id)) {
     return null;
   }

   return allTools.find(x => x.id == id);
}

findAllTools(tools: ITool[], numbers: string[]) {
  const ret: ITool[] = [];
  if (this.helper.isEmptyArray(tools) || this.helper.isEmptyArray(numbers)) {
    for (const number of numbers) {
      ret.push(this.findToolByNumber(this.allTools, number));
    }
  }

  return ret;
}

translateToIToolBag(data: IToolBagServer): IToolBag {
console.log('tool.servicehttp.translateToIToolBag START');
  let ret: IToolBag = null;
  if (data) {
    ret = <IToolBag>{};
    ret.id = data.id;
    ret.description = data.description;
    ret.notes = data.notes;
    ret.tools = [];
  }
  console.log(ret);
  console.log('tool.servicehttp.translateToIToolBag DONE');
  return ret;
}

translateToIToolBags(data): IToolBag[] {
  console.log('tool.servicehttp.translatetoIToolBags');
  console.log(data);

  const ret: IToolBag[] = [];
  if (data && data.length > 0) {
    for (const d of data){
        ret.push(this.translateToIToolBag(d));
    }
  }
  return ret;
}

}






