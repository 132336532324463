import { Helper } from '../services/helper.service';
import { ToolServiceHttp } from '../services/tool.servicehttp';
import { Observable } from 'rxjs/Observable';
import { Guid } from './guid.model';

export interface ITool {
    id: string;
    number: string;
    description: string;
    order: number;
    inUse: boolean;
    userDefined: boolean;
  }

  export interface IToolBagServer {
    id: string;
    description: string;
    toolNumbers: string[];
    notes: string;
  }

export interface IToolBag {
  id: string;
  description: string;
  tools: ITool[];
  notes: string;
}

export class IToolBag {
  id: string;
  description: string;
  tools: ITool[];
  notes: string;
}

export interface IToolBagTool {
  id: string;
  toolBagID: string;
  toolBagDescription: string;
  number: string;
  description: string;
  order: number;
  inUse: boolean;
  userDefined: boolean;
  notes: string;
}

export interface IFromToIds {
  fromId: string;
  toId: string;
}


export interface IWorkCenterToolKit {
  id: string;
  workCenterId: string;
  workCenterWarehouse: string;
  workCenterDescription: string;
  description: string;
  toolBags: IToolBag[];
}

export class IdOrder {
  constructor(public id: string, public order: number) {}
}

export class WCToolBagOrder {
  constructor(public wcId: string, public map: IdOrder[]) {}
}



export class ToolUtils {

  public static TOOL_NONE_STR = 'NONE';

  public static TOOL_NONE: any = {
    id: Guid.Empty,
    number: ToolUtils.TOOL_NONE_STR,
    description: ToolUtils.TOOL_NONE_STR,
    order: 0,
    inUse: false,
    userDefined: false,
    name: 'NONE'
  };

    public static TEST_TOOLS: ITool[] = [
    {
      id: Guid.Empty,
      number: '1',
      description: '1 description',
      order: 0,
      inUse: false,
      userDefined: false
    },
    {
      id: Guid.Empty,
      number: '2',
      description: '2 description',
      order: 0,
      inUse: false,
      userDefined: false
    },
    {
      id: Guid.Empty,
      number: '3',
      description: '3 description',
      order: 0,
      inUse: false,
      userDefined: false
    },
    {
      id: Guid.Empty,
      number: '4',
      description: '4 description',
      order: 0,
      inUse: false,
      userDefined: false
    },
    {
      id: Guid.Empty,
      number: '5',
      description: '5 description',
      order: 0,
      inUse: false,
      userDefined: false
    },
    {
      id: Guid.Empty,
      number: '6',
      description: '6 description',
      order: 0,
      inUse: false,
      userDefined: false
    },
    {
      id: Guid.Empty,
      number: '7',
      description: '7 description',
      order: 0,
      inUse: false,
      userDefined: false
    },
    {
      id: Guid.Empty,
      number: '8',
      description: '8 description',
      order: 0,
      inUse: false,
      userDefined: false
    },
    {
      id: Guid.Empty,
      number: '9',
      description: '9 description',
      order: 0,
      inUse: false,
      userDefined: false
    },
    {
      id: Guid.Empty,
      number: '10',
      description: '10 description',
      order: 0,
      inUse: false,
      userDefined: false
    },
    {
      id: Guid.Empty,
      number: '11',
      description: '11 description',
      order: 0,
      inUse: false,
      userDefined: false
    },
    {
      id: Guid.Empty,
      number: '12',
      description: '12 description',
      order: 0,
      inUse: false,
      userDefined: false
    }
  ];


  public static isUserDefined(t: ITool): boolean {
    return t.id == Guid.Empty;
  }
public static areEqualToolLists(list1: ITool[], list2: ITool[]) {
  // console.log('ToolUtils.areEqualToolLists');
  // console.log(list1);
  // console.log(list2);
  if (list1 == null) {
    return list2 == null;
  }

  if (list2 == null || (list1.length != list2.length)) {
    return false;
  }



  for (let i = 0; i < list1.length; i++) {
    if (!ToolUtils.areEqual(list1[i], list2[i])) {
      return false;
    }
  }

  return true;
}

  public static areEqual(t1: ITool, t2: ITool) {
    // console.log('ToolUtils.areEqual');
    // console.log(t1);
    // console.log(t2);
    if (t1 == null) {
      return t2 == null;
    }
    if (t2 == null) {
      return false;
    }
    return Helper.areEqualStr(t1.id, t2.id) &&
     Helper.areEqualStr(t1.number, t2.number) &&
     Helper.areEqualStr(t1.description, t2.description) &&
     (t1.order === t2.order);
  }


  public static copyTool(from: ITool): ITool {
    if (from) {
      return {
        id: from.id,
        description: from.description,
        number: from.number,
        inUse: from.inUse,
        userDefined: from.userDefined ,
        order: from.order
      };
    } else {
      return null;
    }
  }

  public static copyTools(tools: ITool[]): ITool[] {
    const ret: ITool[] = [];
    for (const t of tools) {
      ret.push(this.copyTool(t));
    }
    return ret;
  }


}


export class ToolBagUtils {
  public static areEqual(t1: IToolBag, t2: IToolBag) {
    // console.log('ToolBagUtils.areEqual');
    // console.log(t1);
    // console.log(t2);
    if (t1 == null) {
      return t2 == null;
    }
    if (t2 == null) {
      return false;
    }
    if (t1.id !== t2.id || t1.description !== t2.description ||
        t1.notes !== t2.notes) {
      return false;
        }

    return ToolUtils.areEqualToolLists(t1.tools, t2.tools);


  }
}

export class Tool implements ITool {
  id: string;
  number: string;
  description: string;
  order: number;
  inUse: boolean;
  userDefined: boolean;
}

export interface IToolKit {
  id: string;
  description: string;
  toolBags: IToolBag[];
  appendAll(sourceKitId: string): Observable<IFromToIds[]>;
  replaceAll(sourceKitId: string): Observable<IFromToIds[]>;
  appendToolBags(ids: string[]): Observable<IFromToIds[]>;
  getToolBags(): Observable<IToolBag[]>;
  getTools(): Observable<IToolBagTool[]>;
}

export class IToolKit {
  id: string;
  description: string;
  toolBags: IToolBag[];
}

export class RouterStepToolSet implements IToolKit {

  description: string;
  id: string;
  toolBags: IToolBag[];


  constructor(private stepId: string, private toolService: ToolServiceHttp) {
    this.id = stepId;
    this.description = '';
    this.toolBags = [];
  }

  appendAll(sourceKitId: string): Observable<IFromToIds[]> {
    return this.toolService.appendAllToolBagsToRouterStep(this.id, sourceKitId);
  }
  replaceAll(sourceKitId: string): Observable<IFromToIds[]> {
    return this.toolService.replaceAllToolBagsToRouterStep(this.id, sourceKitId);
  }
  appendToolBags(ids: string[]): Observable<IFromToIds[]> {
    return this.toolService.copyToolBagsToRouterStep(this.id, ids);
  }
  getToolBags(): Observable<IToolBag[]> {
    return this.toolService.getRouterStepToolBags(this.id);
  }
  getTools(): Observable<IToolBagTool[]> {
    return this.toolService.getRouterStepTools(this.id);
  }
}

export class WorkCenterToolKit implements IToolKit {

  description: string;
  id: string;
  toolBags: IToolBag[];

  constructor(wctk: IWorkCenterToolKit, private toolService: ToolServiceHttp) {
    this.id = wctk.id;
    this.description = wctk.description;
    this.toolBags = wctk.toolBags;
  }
  public static areEqual(a: IWorkCenterToolKit, b: IWorkCenterToolKit) {
    // console.log('ToolBagUtils.areEqual');
    // console.log(t1);
    // console.log(t2);

    const utils = new Helper();
    if (a == null) {
      return b == null;
    }
    if (b == null) {
      return false;
    }

    const descA = utils.isEmptyString(a.description) ? '' : a.description.trim().toLowerCase();
    const descB = utils.isEmptyString(b.description) ? '' : b.description.trim().toLowerCase();
    const wcIdA = utils.isEmptyString(a.workCenterId) ? '' : a.workCenterId.trim().toLowerCase();
    const wcIdB = utils.isEmptyString(b.workCenterId) ? '' : b.workCenterId.trim().toLowerCase();

    return (descA == descB && wcIdA == wcIdB);
  }

  appendAll(sourceKitId: string): Observable<IFromToIds[]> {
    return this.toolService.appendAllToolBagsToWorkCenterToolKit(this.id, sourceKitId);
  }
  replaceAll(sourceKitId: string): Observable<IFromToIds[]> {
    return this.toolService.replaceAllToolBagsToWorkCenterToolKit(this.id, sourceKitId);
  }
  appendToolBags(ids: string[]): Observable<IFromToIds[]> {
    return this.toolService.copyToolBagsToWorkCenterToolKit(this.id, ids);
  }
  getToolBags(): Observable<IToolBag[]> {
    return this.toolService.getWorkCenterToolBags(this.id);
  }
  getTools(): Observable<IToolBagTool[]> {
    return this.toolService.getWorkCenterToolKitTools(this.id);
  }


}
