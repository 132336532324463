import { Helper } from '../../core/services/helper.service';
import { IWorkStation, WorkStationUtils } from '../../core/models/workstation.model';
import { IWorkCenter, WorkCenterUtils } from '../../core/models/workcenter.model';
import { isNumber } from 'util';
import { ToolsComponentBizLogic } from '../../tools/tools.component.bizl';


export class WorkCenterDetailComponentBizLogic {
  private utils: Helper = new Helper();

  constructor(private detailsProvider: any) {}

  get workStation(){
    return this.detailsProvider.workStationInfo;
  }

  get workStations(){
    return this.detailsProvider.workStations;
  }

  get originalWorkCenter(){
    return this.detailsProvider.selectedWorkCenter;
  }

  get originalWorkStation(){
    return this.detailsProvider.selectedWorkStation;
  }
  get isWorkStationValid(){
    return this.detailsProvider.isWorkStationInfoValid;
  }

  get isNewWorkStation() {
    return !this.utils.isExistingItem(this.workStation);
  }

  get workCenter(){
    return this.detailsProvider.workCenterInfo;
  }

  get isWorkCenterValid(){
    return this.detailsProvider.isWorkCenterInfoValid;
  }

  get isWorkCenterEditable():boolean {
    return true;
  }

  get isWorkStationEditable() {
    return true;
  }

  get canSubmitWorkStation(){
    return this.isWorkStationValid && this.workStationDataChanged;
  }

  get workStationDataChanged():boolean
  {
    return !this.areEqualWorkStations(this.originalWorkStation, this.workStation);
  }


  get canAddWorkStation() {
   console.log('work-center-detail.component.canAddWorkStation');
   console.log(this);
    return !this.detailsProvider.isWorkCenterInfoEnabled || this.isWorkCenterValid; // && this.isWorkCenterEditable;
  }

  canDeleteWorkStation(ws:IWorkStation):Boolean {
    var ret:boolean = ws !== null && !ws.inUse;
    console.log("work-center-this.detailsProvider.component.bizl.canDeleteWorkStation:" + ret);
    return ret;
  }

  get canCreateWorkStation() {
    return this.isNewWorkStation && this.isWorkStationValid;
  }

  get canUpdateWorkCenter() {
    return this.isWorkCenterEditable && this.isWorkCenterValid &&
     !WorkCenterUtils.areEqual(this.originalWorkCenter, this.workCenter);
  }

  get canUpdateWorkStation() {
    
    return true;
  }

  get isUniqueNumber(){
    return !this.utils.listContainsSimilarDifferentItem(this.workStations, this.workStation, this.cmpNumber);
  }

/*   get isUniqueStationDescription():boolean{
    var ret = !this.utils.listContainsItem(this.workStations, this.workStation, this.cmpDescription);
    console.log('workcenter-detail-component.bizl.isUniqueDescription:' + ret);
    return ret;
  } */


  hasUniqueStationDescription(stations:IWorkStation[], station:IWorkStation):boolean{
    var ret = !this.utils.listContainsItem(stations, station, this.cmpDescription);
    console.log('WorkCenterDetailsComponent.bizl.hasUniqueStationDescription:' + ret);
    return ret;
  }

  cmpNumber(a: IWorkStation, b: IWorkStation) {
    return Helper.cmpStringAlphaNumeric(a.number, b.number);
  }

  cmpDescription(w1: IWorkStation, w2: IWorkStation) {
    var a:string = (!w1 || !w1.description? "" : w1.description.trim().toLowerCase());
    var b:string = (!w2 || !w2.description? "": w2.description.trim().toLowerCase());
   // console.log('bizl.cmpDescription: a:' + a + " b:" + b);
    var ret:number = Helper.cmpStringAlphaNumeric(a, b);
    console.log('bizl.cmpDescription:' + ret);
    return ret;
  }
  
  areEqualWorkStations(w1:IWorkStation, w2:IWorkStation):boolean
  {
    if (!w1)
      return !w2;

    if (!w2)
      return false;

      var ret:boolean =  Helper.cmpString(w1.description, w2.description) == 0 &&  (w1.id == w2.id);
     // console.log("workcenter-StepDetailComponent.bizl.areEqualWorkStations:" + ret);
      return ret;

  }

  


}
