import { Helper } from '../../core/services/helper.service';
import { IRouter } from '../../core/models/router.model';
import { RouterDomainService } from '../../core/services/router-domain.service';
import { RouterState } from '../../core/enums/router-state';
import { IToolBag } from '../../core/models/tool.model';


export class StepSpecComponentBizLogic {
  private utils: Helper = new Helper();

  constructor(private router: IRouter, private stepId: string, private domain:RouterDomainService) {
    console.log('StepSpecComponentBizLogic');
    console.log(router);
    console.log(stepId);
  }

  public get canAddProgram(): boolean {
    return this.domain.isEditable;
  }

  public get canDeleteProgram(): boolean {
    return this.domain.isEditable;
  }

  public get canAddFixture(): boolean {
    return this.domain.isEditable;
  }

  public get canDeleteFixture(): boolean {
    return this.domain.isEditable;
  }

  public get canAddFile(): boolean {
    return this.domain.isEditable;
  }

  public get canDeleteFile(): boolean {
    return this.domain.isEditable;
  }

  public get canAddTool(): boolean {
    return this.domain.isEditable;
  }
  public get canDeleteTool(): boolean {
    return this.domain.isEditable;
  }

  public isNewTool(t: IToolBag): boolean {
      return !this.utils.isExistingItem(t);
  }

  public isToolEditable(t: IToolBag) {
    return !this.isNewTool(t) && this.domain.isEditable;;
  }

  get newToolBagDefaultInfo(): IToolBag {
    return {
        id: '',
        description: '',
        tools: null,
        notes:''
      };
  }
}
