import { UnitType } from '../../core/enums/unittype';
import { ITest, TestDeviceType, TestType, TestUtils } from '../../core/models/test.model';
import { Helper } from '../../core/services/helper.service';
import { IRouter } from '../../core/models/router.model';
import { RouterDomainService } from '../../core/services/router-domain.service';
import { RouterState } from '../../core/enums/router-state';


export class StepTestsComponentBizLogic {
  private utils: Helper = new Helper();
  private _test: ITest = this.newTestDefaultInfo;


  constructor(private router: IRouter, private stepId: string,  private testProvider: any,
    private domain:RouterDomainService) {
    //console.log('StepTestsComponentBizLogic');

  }

  public get test(): ITest{
    return this.testProvider.getNewTest();
  }

  public get canEditTest(): boolean {
    return this.domain.isEditable;
  }

  public get canViewTest(): boolean {
    return !this.domain.isEditable;
  }

  public get canAddNewTests(): boolean {
    return this.canEditTest;
  }

  public get canDeleteTest(): boolean {
    return this.canEditTest;
  }

 get isNewTest() {
    const ret = !this.utils.isExistingItem(this.test);
   // console.log('step-tests.component.bizl isNewTest:' + ret);
    return ret;
  }

  get isKitTest() {
    return this.utils.isExistingItem(this.test) && !this.test.routerStepId;
  }

get isTestEditable(){
  return this.isNewTest || this.canEditTest;;
}

 get isTestTypeEditable() {

    return this.isTestEditable;
 }

get testIsForRouter() {
  return this.utils.isEmptyString(this.stepId);
}

get testTypeIsValid() {
  return this.test != null &&  this.test.type !== TestType.UNKNOWN;
}

 get isRouterOperationEditable() {
    return  this.testIsForRouter &&
            this.testTypeIsValid &&
            this.isTestEditable;
  }

 get isFrequencyEditable() {
  return this.canEditTest && this.testTypeIsValid;
 }

 get isBaseTestEditable() {
  return this.canEditTest && this.testTypeIsValid;
}

get isTestDescriptionEditable() {
  return this.canEditTest && this.testTypeIsValid;
}


get isTestDeviceTypeEditable() {
  return this.canEditTest && this.testTypeIsValid;
}

get isMinMaxEditable() {
  return this.shouldShowMinMax && this.canEditTest && this.testTypeIsValid;
}

get isToolCountEditable() {
  return this.canEditTest && this.testTypeIsValid;
}

get isSpecEditable() {
  return this.canEditTest &&  this.testTypeIsValid;
}

get areInstructionsEditable() {
  return this.canEditTest &&  this.testTypeIsValid;
}

get shouldShowRouterOperation(){
  return this.utils.isEmptyString(this.stepId);
}

get shouldShowMinMax() {
  return this.test != null &&  this.test.type === TestType.IPI;
}

 get canUpdate() {
  console.log('step-tests.component.canUpdate');
 // console.log(this);
 // console.log(this.testProvider.selectedTest);
 // console.log(this.test);  
  return this.isTestEditable && this.testProvider.validateNewTestInfo &&
  !TestUtils.areTestsEqual(this.testProvider.selectedTest, this.test);
}

  
get canSubmit() {
  return this.isNewTest || this.canUpdate;
}

isUniqueDescription(editMode:Boolean) {
  let isUnique:Boolean = null; 
  const stepTests = !this.utils.isEmptyArray(this.testProvider.tests)
  ? this.testProvider.tests.filter(x => x.routerStepId == this.test.routerStepId) || []
  : [];

  if (editMode)
    {      
      isUnique =  !this.utils.listContainsSimilarDifferentItemEdit(stepTests, this.test, this.compByTypeCode);
    }
    else
    {
      isUnique = !this.utils.listContainsItem(stepTests, this.test, this.compByTypeCode);
    }
    return isUnique;
  //const ret =  !this.utils.listContainsSimilarDifferentItem(stepTests, this.test, this.compDescription);
  //console.log('step-tests.component.isUniqueDescription ret:' + ret);
  //return ret;
}

compDescription(a: ITest, b: ITest) {
return Helper.cmpStringAlphaNumeric(a.description, b.description);
}

compByTypeCode(a1: ITest, a2: ITest) {
  
  if (a1.type < a2.type) {
    return -1;
  } else if (a1.type > a2.type) {
    return 1;
  } else {
    if (a2.description !== null)
    {
      return Helper.cmpStringAlphaNumeric(a1.description, a2.description);
    }
    else
    {
      return 0;
    }
    
  }
}


get newTestDefaultInfo(): ITest {
  return {
          id: null,
          routerStepId: (!this.utils.isEmptyString(this.stepId) ? this.stepId : null),
          step: 0,
          stepDescription: '',
          specificationId: null,
          specificationName: null,
          description: null,
          type: TestType.UNKNOWN,
          min: 0.0001,
          max: 0.0001,
          frequency: 1,
          notes: null,
          unitType: UnitType.INCH,
          testOrder: 0,
          testDeviceType: TestDeviceType.SERIALIZED,
          testDeviceCount: 1
        };
    }
  }
