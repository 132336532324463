// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BreadCrumbContainer {\r\n  position: absolute;\r\n  top: 33px;\r\n  left: -20px;\r\n}\r\nol.breadcrumbs {\r\n    list-style: none;\r\n} \r\n\r\nol.breadcrumbs li {\r\n    display: inline;\r\n}\r\n\r\nol.breadcrumbs li+li:before {\r\n    color: gray;\r\n    content: \"/\\00a0\"\r\n}\r\n\r\nol.breadcrumbs li a {\r\n    color: #616161;\r\n    text-decoration: none;\r\n}\r\nol.breadcrumbs li a:hover {\r\n    cursor: pointer;\r\n    color: purple;\r\n    text-decoration: underline;\r\n}\r\n", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();