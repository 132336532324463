import { Injectable, EventEmitter } from '@angular/core';
import {IRouter, IRouterVersionInfo } from '../models/router.model';
import { IRouterStep } from '../models/routerstep.model';
import { Helper } from './helper.service';




@Injectable()
export class RouterDomainService {
  selectedRouter: IRouter = null;
  routerUseMode: string = "View"; //View, Edit
  selectedRouterStep: IRouterStep = null;
  excludePartIds: string[] = null;
  private _routerSteps: IRouterStep[] = [];
  latestRouterVersionInfos:IRouterVersionInfo[];

  get routerSteps(){return this._routerSteps;}

  set routerSteps(val: IRouterStep[])
  {
    if (Helper.sIsEmptyArray(val))
      this._routerSteps = [];
    else
      this._routerSteps = val;
    
  }

  get isEditable(){return this.routerUseMode == "Edit";} 

  constructor(private helper: Helper) { }



}







