
export enum UnitTypeService {
  UNKNOWN = -1,
  EACH = 0,
  INCH = 2,
  BAG = 3,
  BOX = 4,
  BUNDLE = 5,
  C = 6,
  C12 = 7,
  CART250 = 8,
  CART30 = 9,
  CART40 = 10,
  CART50 = 11,
  CASE = 12,
  CASE12 = 13,
  CASE40 = 14,
  DAY = 15,
  DOLLAR = 16,
  DOZEN = 17,
  DRUM = 18,
  DRUM20 = 19,
  DRUM54 = 20,
  DRUM55 = 21,
  FOOT = 22,
  GALLON = 24,
  LB = 25,
  M = 26,
  PAIL5GAL = 27,
  PAIR = 28,
  PKG10 = 29,
  PKG250 = 30,
  PKG5 = 31,
  PKG100 = 32,
  PKG25 = 33,
  POUND = 34,
  ROLL = 35,
  ROLL1000 = 36,
  SET = 37,
  SQFT = 38,
  TON = 39,
  PKG6 = 40,
  FT = 41,
  GRAM = 42,
  PINT = 43,
  OUNCE = 44  
}

export enum UnitType {
  UNDEFINED = '',
  UNKNOWN = 'UNKNOWN',
  EACH = 'each',
  INCH = 'inch',
  BAG = 'bag',
  BOX = 'box',
  BUNDLE = 'bundle',
  C = 'c',
  C12 = 'c12',
  CART250 = 'cart250',
  CART30 = 'cart30',
  CART40 = 'cart40',
  CART50 = 'cart50',
  CASE = 'case',
  CASE12 = 'case12',
  CASE40 = 'case40',
  DAY = 'day',
  DOLLAR = 'dollar',
  DOZEN = 'dozen',
  DRUM = 'drum',
  DRUM20 = 'drum20',
  DRUM54 = 'drum54',
  DRUM55 = 'drum55',
  FOOT = 'foot',
  GALLON = 'gallon',
  LB = 'lb',
  M = 'm',
  PAIL5GAL = 'pail5gal',
  PAIR = 'pair',
  PKG10 = 'pkg10',
  PKG250 = 'pkg250',
  PKG5 = 'pkg5',
  PKG100 = 'pkg100',
  PKG25 = 'pkg25',
  POUND = 'pound',
  ROLL = 'roll',
  ROLL1000 = 'roll1000',
  SET = 'set',
  SQFT = 'sqft',
  TON = 'ton',
  PKG6 = 'pkg6',
  FT = 'ft',
  GRAM = 'gm',
  PINT = 'pt',
  OUNCE = 'oz'
}


export class UnitTypeUtils {
  public static toUnitType(type: UnitTypeService): UnitType {
    switch (type) {
      case UnitTypeService.EACH:
        return UnitType.EACH;
      case UnitTypeService.INCH:
        return UnitType.INCH;
      case UnitTypeService.BAG:
        return UnitType.BAG;
      case UnitTypeService.BOX:
        return UnitType.BOX;
      case UnitTypeService.BUNDLE:
        return UnitType.BUNDLE;
      case UnitTypeService.C:
        return UnitType.C;
      case UnitTypeService.C12:
        return UnitType.C12;
      case UnitTypeService.CART250:
        return UnitType.CART250;
      case UnitTypeService.CART30:
        return UnitType.CART30;
      case UnitTypeService.CART40:
        return UnitType.CART40;
      case UnitTypeService.CART50:
        return UnitType.CART50;
      case UnitTypeService.CASE:
        return UnitType.CASE;
      case UnitTypeService.CASE12:
        return UnitType.CASE12;
      case UnitTypeService.CASE40:
        return UnitType.CASE40;
      case UnitTypeService.DAY:
        return UnitType.DAY;
      case UnitTypeService.DOLLAR:
        return UnitType.DOLLAR;
      case UnitTypeService.DOZEN:
        return UnitType.DOZEN;
      case UnitTypeService.DRUM:
        return UnitType.DRUM;
      case UnitTypeService.DRUM20:
        return UnitType.DRUM20;
      case UnitTypeService.DRUM54:
        return UnitType.DRUM54;
      case UnitTypeService.DRUM55:
        return UnitType.DRUM55;
      case UnitTypeService.FOOT:
        return UnitType.FOOT;
      case UnitTypeService.GALLON:
        return UnitType.GALLON;
      case UnitTypeService.LB:
        return UnitType.LB;
      case UnitTypeService.M:
        return UnitType.M;
      case UnitTypeService.PAIL5GAL:
        return UnitType.PAIL5GAL;
      case UnitTypeService.PAIR:
        return UnitType.PAIR;
      case UnitTypeService.PKG10:
        return UnitType.PKG10;
      case UnitTypeService.PKG250:
        return UnitType.PKG250;
      case UnitTypeService.PKG5:
        return UnitType.PKG5;
      case UnitTypeService.PKG100:
        return UnitType.PKG100;
      case UnitTypeService.PKG25:
        return UnitType.PKG25;
      case UnitTypeService.POUND:
        return UnitType.POUND;
      case UnitTypeService.ROLL:
        return UnitType.ROLL;
      case UnitTypeService.ROLL1000:
        return UnitType.ROLL1000;
      case UnitTypeService.SET:
        return UnitType.SET;
      case UnitTypeService.SQFT:
        return UnitType.SQFT;
      case UnitTypeService.TON:
        return UnitType.TON;
      case UnitTypeService.PKG6:
        return UnitType.PKG6;
      case UnitTypeService.FT:
        return UnitType.FT;
      case UnitTypeService.GRAM:
        return UnitType.GRAM;
      case UnitTypeService.PINT:
        return UnitType.PINT;
      case UnitTypeService.OUNCE:
        return UnitType.OUNCE;        
      default:
        return UnitType.UNKNOWN;
    }
  }

  public static toUnitTypeService(type: UnitType): UnitTypeService {
    switch (type) {
      case UnitType.EACH:
        return UnitTypeService.EACH;
      case UnitType.INCH:
        return UnitTypeService.INCH;
      case UnitType.BAG:
        return UnitTypeService.BAG;
      case UnitType.BOX:
        return UnitTypeService.BOX;
      case UnitType.BUNDLE:
        return UnitTypeService.BUNDLE;
      case UnitType.C:
        return UnitTypeService.C;
      case UnitType.C12:
        return UnitTypeService.C12;
      case UnitType.CART250:
        return UnitTypeService.CART250;
      case UnitType.CART30:
        return UnitTypeService.CART30;
      case UnitType.CART40:
        return UnitTypeService.CART40;
      case UnitType.CART50:
        return UnitTypeService.CART50;
      case UnitType.CASE:
        return UnitTypeService.CASE;
      case UnitType.CASE12:
        return UnitTypeService.CASE12;
      case UnitType.CASE40:
        return UnitTypeService.CASE40;
      case UnitType.DAY:
        return UnitTypeService.DAY;
      case UnitType.DOLLAR:
        return UnitTypeService.DOLLAR;
      case UnitType.DOZEN:
        return UnitTypeService.DOZEN;
      case UnitType.DRUM:
        return UnitTypeService.DRUM;
      case UnitType.DRUM20:
        return UnitTypeService.DRUM20;
      case UnitType.DRUM54:
        return UnitTypeService.DRUM54;
      case UnitType.DRUM55:
        return UnitTypeService.DRUM55;
      case UnitType.FOOT:
        return UnitTypeService.FOOT;
      case UnitType.GALLON:
        return UnitTypeService.GALLON;
      case UnitType.LB:
        return UnitTypeService.LB;
      case UnitType.M:
        return UnitTypeService.M;
      case UnitType.PAIL5GAL:
        return UnitTypeService.PAIL5GAL;
      case UnitType.PAIR:
        return UnitTypeService.PAIR;
      case UnitType.PKG10:
        return UnitTypeService.PKG10;
      case UnitType.PKG250:
        return UnitTypeService.PKG250;
      case UnitType.PKG5:
        return UnitTypeService.PKG5;
      case UnitType.PKG100:
        return UnitTypeService.PKG100;
      case UnitType.PKG25:
        return UnitTypeService.PKG25;
      case UnitType.POUND:
        return UnitTypeService.POUND;
      case UnitType.ROLL:
        return UnitTypeService.ROLL;
      case UnitType.ROLL1000:
        return UnitTypeService.ROLL1000;
      case UnitType.SET:
        return UnitTypeService.SET;
      case UnitType.SQFT:
        return UnitTypeService.SQFT;
      case UnitType.TON:
        return UnitTypeService.TON;
      case UnitType.PKG6:
        return UnitTypeService.PKG6;
      case UnitType.FT:
        return UnitTypeService.FT;
      case UnitType.GRAM:
        return UnitTypeService.GRAM;
      case UnitType.PINT:
        return UnitTypeService.PINT;
      case UnitType.OUNCE:
        return UnitTypeService.OUNCE;
      default:
        return UnitTypeService.UNKNOWN;
    }
  }

  public static toUnitTypeID(unitType: UnitType): number {
    switch (unitType) {
      case UnitType.EACH:
        return 0;
      case UnitType.INCH:
        return 2;
      case UnitType.BAG:
        return 3;
      case UnitType.BOX:
        return 4;
      case UnitType.BUNDLE:
        return 5;
      case UnitType.C:
        return 6;
      case UnitType.C12:
        return 7;
      case UnitType.CART250:
        return 8;
      case UnitType.CART30:
        return 9;
      case UnitType.CART40:
        return 10;
      case UnitType.CART50:
        return 11;
      case UnitType.CASE:
        return 12;
      case UnitType.CASE12:
        return 13;
      case UnitType.CASE40:
        return 14;
      case UnitType.DAY:
        return 15;
      case UnitType.DOLLAR:
        return 16;
      case UnitType.DOZEN:
        return 17;
      case UnitType.DRUM:
        return 18;
      case UnitType.DRUM20:
        return 19;
      case UnitType.DRUM54:
        return 20;
      case UnitType.DRUM55:
        return 21;
      case UnitType.FOOT:
        return 22;
      case UnitType.GALLON:
        return 24;
      case UnitType.LB:
        return 25;
      case UnitType.M:
        return 26;
      case UnitType.PAIL5GAL:
        return 27;
      case UnitType.PAIR:
        return 28;
      case UnitType.PKG10:
        return 29;
      case UnitType.PKG250:
        return 30;
      case UnitType.PKG5:
        return 31;
      case UnitType.PKG100:
        return 32;
      case UnitType.PKG25:
        return 33;
      case UnitType.POUND:
        return 34;
      case UnitType.ROLL:
        return 35;
      case UnitType.ROLL1000:
        return 36;
      case UnitType.SET:
        return 37;
      case UnitType.SQFT:
        return 38;
      case UnitType.TON:
        return 39;
      case UnitType.PKG6:
        return 40;
      case UnitType.FT:
        return 41;
      case UnitType.GRAM:
        return 42;
      case UnitType.PINT:
        return 43;
      case UnitType.OUNCE:
        return 44;
      default:
        return 0; //-- EACH
    }
  }
}
