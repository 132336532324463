import { Helper } from '../../core/services/helper.service';
import { IRouter } from '../../core/models/router.model';
import { RouterDomainService } from '../../core/services/router-domain.service';
import { RouterState } from '../../core/enums/router-state';


export class RouterNotesComponentBizLogic {
  private utils: Helper = new Helper();

  constructor(private router: IRouter, private domain: RouterDomainService) {
    //console.log('RouterNotesComponentBizLogic');
    //console.log(router);
  }

  public get areNotesEditable(): boolean {
    return this.domain.isEditable;
  }

}
