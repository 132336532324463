import { VirtualScrollerModule } from 'ngx-virtual-scroller';
// import { AutofocusDirective } from './core/shared/auto-focus.directive';
import { CanDeactivateStepNotes } from './step-detail/step-detail.candeactivateNotes';
import { CanDeactivateStepInfo } from './step-detail/step-detail.candeactivateStep';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import * as $ from 'jquery';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { NavigationHeaderComponent } from './navigation-header/navigation-header.component';
import { RouterListingComponent } from './router-listing/router-listing.component';
import { routes } from './app.router';
import { RouterValidationService } from './core/services/router-validation.service';
import { Helper } from './core/services/helper.service';
import { RouterThumbnailComponent } from './router-detail/router-thumbnail.component';
import { FilterPipe } from './core/pipes/filter.pipe';
import { FilterPartPipe } from './core/pipes/filter-part.pipe';
import { FilterToolPipe } from './core/pipes/filter-tool.pipe';
import { FilterReasonCodePipe } from './core/pipes/filter-code.pipe';
import { FilterFilePipe } from './core/pipes/filter-file.pipe';
import { FilterTypePipe } from './core/pipes/filter-type.pipe';
import { FilterFixturePipe } from './core/pipes/filter-fixture.pipe';
import { FilterProgramPipe } from './core/pipes/filter-program.pipe';
import { FilterReportPipe } from './core/pipes/filter-report.pipe';
import { FilterDemandItemsNoBOMPipe} from './core/pipes/filter-demanditemnobom.pipe';
import { FilterDemandItemsNoDrawingPipe} from './core/pipes/filter-demanditemnodrawing.pipe';
import { FilterDemandItemsNoRouterPipe} from './core/pipes/filter-demanditemnorouter.pipe';
import { FilterDemandItemsRouterOnHoldPipe} from './core/pipes/filter-demanditemrouteronhold.pipe';
import { FilterWorkCenterPipe } from './core/pipes/filter-workcenter.pipe';
import { FilterToolKitPipe} from './core/pipes/filter-toolkit.pipe';
import { FilterRouterFilesPipe} from './core/pipes/filter-routerfiles.pipe';
import { FilterWorkStationPipe} from './core/pipes/filter-workstation.pipe';
import { FilterWorkStationAvailablePipe} from './core/pipes/filter-workstationavailable.pipe';
import { FilterWorkStationAuthorizedPipe} from './core/pipes/filter-workstationauthorized.pipe';
import { RouterDetailComponent } from './router-detail/router-detail.component';
import { StepDetailComponent } from './step-detail/step-detail.component';
import { RouterInfoComponent } from './router-detail/router-info/router-info.component';
import { RouterStepsComponent } from './router-detail/router-steps/router-steps.component';
import { RouterFilesComponent } from './router-detail/router-files/router-files.component';
import { RouterNotesComponent } from './router-detail/router-notes/router-notes.component';
import { StepInfoComponent } from './step-detail/step-info/step-info.component';
import { StepSpecComponent } from './step-detail/step-spec/step-spec.component';
import { StepBomComponent } from './step-detail/step-bom/step-bom.component';
import { StepTestsComponent } from './step-detail/step-tests/step-tests.component';
import { StepNotesComponent } from './step-detail/step-notes/step-notes.component';
import { MockDataService} from './core/services/mock-data.service';
import { AuthService } from './core/services/auth.service';
import { WorkCenterService } from './core/services/workcenter.service';
import { WorkStationService } from './core/services/workstation.service';
import { WorkCenterDataService } from './core/services/workcenter-data.service';
import { BreadCrumbComponent } from './navigation-header/bread-crumb/bread-crumb.component';
import { SpecProgramComponent } from './step-detail/step-spec/spec-program/spec-program.component';
import { SpecToolComponent } from './step-detail/step-spec/spec-tool/spec-tool.component';
import { SpecFixtureComponent } from './step-detail/step-spec/spec-fixture/spec-fixture.component';
import { SpecFileComponent } from './step-detail/step-spec/spec-file/spec-file.component';
import { PartInfoComponent } from './part-info/part-info.component';
import { TestsComponent } from './tests/tests.component';
import { WorkCentersComponent } from './work-centers/work-centers.component';
import { ToolsComponent } from './tools/tools.component';
import { ToolsContainerComponent } from './tools/tools-container.component';
import { NdeTestsComponent } from './tests/nde-tests/nde-tests.component';
import { HydTestsComponent } from './tests/hyd-tests/hyd-tests.component';
import { PartInfoTabsComponent } from './part-info/part-info-tabs/part-info-tabs.component';
import { PartEtchingComponent } from './part-info/part-info-tabs/part-etching/part-etching.component';
import { PartFilesComponent } from './part-info/part-info-tabs/part-files/part-files.component';
import { PartNdeComponent } from './part-info/part-info-tabs/part-nde/part-nde.component';
import { PartExtInfoComponent } from './part-info/part-info-tabs/part-ext-info/part-ext-info.component';
import { WorkCenterDetailComponent1 } from './work-centers/work-center-detail/work-center-detail.component1';
import { ProgramsComponent } from './programs/programs.component';
import { FixturesComponent } from './fixtures/fixtures.component';
import { ReasonCodeComponent } from './reason-code/reason-code.component';
import { FilesComponent } from './files/files.component';
import { ReportsComponent} from './reports/reports.component';
import { DemandItemNoBOMComponent} from './reports/report_demanditemnobom/demanditemnobom.component';
import { DemandItemNoDrawingComponent} from './reports/report_demanditemnodrawing/demanditemnodrawing.component';
import { DemandItemNoRouterComponent} from './reports/report_demanditemnorouter/demanditemnorouter.component';
import { DemandItemRouterOnHoldComponent} from './reports/report_demanditemrouteronhold/demanditemrouteronhold.component';
import { AlertActionComponent } from './alert-action/alert-action.component';
import { UserInfoComponent} from './user-maintenance/user-detail/user-info.component';
import { AlertService } from './core/services/alert.service';
import { SortService} from './core/services/sort.service';
import { LoginComponent } from './login/login.component';
import { BOMServiceHttp } from './core/services/bom.servicehttp';
import { TestServiceHttp } from './core/services/test.servicehttp';
import { PartServiceHttp } from './core/services/part.servicehttp';
import { WorkCenterServiceHttp } from './core/services/workcenter.servicehttp';
import { WorkStationServiceHttp } from './core/services/workstation.servicehttp';
import { ProgramServiceHttp } from './core/services/program.servicehttp';
import { ToolServiceHttp } from './core/services/tool.servicehttp';
import { FixtureServiceHttp } from './core/services/fixture.servicehttp';
import { FileServiceHttp } from './core/services/file.servicehttp';
import { RouterServiceHttp } from './core/services/router.servicehttp';
import { RouterStepServiceHttp} from './core/services/routerstep.servicehttp';
import { ReasonCodeServiceHttp } from './core/services/reasoncode.servicehttp';
import { ReportServiceHttp} from './core/services/report.servicehttp';
import { RouterDomainService } from './core/services/router-domain.service';
import { PartDomainService } from './core/services/part-domain.service';
import { ListItemComponent} from './router-detail/router-info/list-item';
import {ShSelectModule1} from './core/shared/sh-select.module';
import {SortableColumnComponent} from './sortable-column.component';
import { KeysPipe } from './core/pipes/keys.pipe';
import { Ng2PdfViewerComponent } from './ng2-pdf-viewer/ng2-pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
// import { PDFService } from './pdf-viewer/pdf.service';
import { AppHttpClientService } from './core/services/app-http-client.service';
import { PDFService } from './core/services/pdf-service';
import { OnshapeService } from './core/shared/onshape/onshape.service';
import { WorkCenterInfoComponent } from './work-centers/work-center-detail/work-center-info.component';
import { LocationStrategy, HashLocationStrategy} from '@angular/common';
import { CanDeactivateRouterDetail } from './router-detail/router-detail.candeactivate';
import { CanDeactivateRouterDetail1 } from './router-detail/router-detail.candeactivate.1';
import { TestKitComponent } from './test-kit/test-kit.component';
import { TestKitDetailComponent } from './test-kit/test-kit-detail/test-kit-detail.component';
import { TestKitCreateComponent } from './test-kit/test-kit-detail/test-kit-create/test-kit-create.component';
import { TestKitManagementComponent } from './test-kit/test-kit-detail/test-kit-management/test-kit-management.component';
import { ToolKitComponent } from './tools/tool-kit/tool-kit.component';
import { ToolKitDetailComponent } from './tools/tool-kit/tool-kit-detail/tool-kit-detail.component';
import { ToolKitManagementComponent } from './tools/tool-kit/tool-kit-detail/tool-kit-management/tool-kit-management.component';
import { TestKitDetailResolveFile } from './test-kit/test-kit-detail/test-kit-detail-resolveFile';
import { TestKitDetailResolveFileTests } from './test-kit/test-kit-detail/test-kit-detail-resolveFileTests';
import { TestKitDetailResolveFilesWithoutKits } from './test-kit/test-kit-detail/test-kit-detail-resolveFilesWithoutKits';
import { FilterTestKitPipe } from './core/pipes/filter-test-kit.pipe';
import { DebugService } from './core/services/debug.service';
import { ToolKitCreateComponent } from './tools/tool-kit/tool-kit-detail/tool-kit-create/tool-kit-create.component';
import { WorkCenterToolKitDomainService } from './core/services/workCenterToolKit-domain.service';
import { ToolKitManagement1Component } from './tools/tool-kit/tool-kit-detail/tool-kit-management/tool-kit-management1.component';
import { CanDeactivateToolKitDetail } from './tools/tool-kit/tool-kit-detail/tool-kit-detail.candeactivate';
import { NgxLoadingModule } from 'ngx-loading';
import { LoginService} from './core/services/login.service';
import { GateKeeperService } from './core/shared/gatekeeper/user/user.service';
import { Config } from './core/shared/gatekeeper/config';
import { UserMaintenanceComponent } from './user-maintenance/user-maintenance.component';
import { UserDetailComponent } from './user-maintenance/user-detail/user-detail.component';
import { UserMaintenanceServiceHttp } from './core/services/usermaintenance.servicehttp';
import { FilterUserMaintenancePipe } from './core/pipes/filter-usermaintenance.pipe';
import { UserMaintenanceDataService} from './core/services/usermaintenance-data.service';
import { SortableTableDirective} from './core/shared/sortable-table.component';
import { LoginModule } from './login/login.module';
import { ReportsModule} from './reports/reports.module';
import { FilesModule } from './files/files.module';
import { FixturesModule } from './fixtures/fixtures.module';
import { ProgramsModule } from './programs/programs.module';
import { ReasonCodeModule } from './reason-code/reason-code.module';
import { PartsInfoModule } from './part-info/part-info.module';
import { TestsModule } from './tests/tests.module';
import { UserMaintenanceModule} from './user-maintenance/user-maintenance.module';
import { WorkCentersModule} from './work-centers/work-centers.module';
import { TestKitModule} from './test-kit/test-kit.module';
import { ToolsModule} from './tools/tools.module';
import { RouterListingModule} from './router-listing/router-listing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule} from '@angular/cdk/scrolling';
import { ProgramsComponentBizLogic} from './programs/programs.component.bizl';
import { OverDirective } from './core/directives/overlay-on-hover.directive';
import { HesitateDirective } from './core/directives/hesitate.directive';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgbdDropdown } from './core/ngbootstrap/ngbdropdown.component';
import { WorkCenterDetailResolver } from './work-centers/work-center-detail/work-center-detail-resolver.service';
import { CanDeactivateWorkCenterDetail } from './work-centers/work-center-detail/work-center-detail.candeactivate';
import { FileCacheService } from './core/services/file.cache.service';
import { FileCacheService1 } from './core/services/file.cache.service1';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule} from '@angular/material/radio'






@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    RouterListingComponent,
    NavigationHeaderComponent,
    RouterThumbnailComponent,
    FilterPipe,
    FilterPartPipe,
    FilterProgramPipe,
    FilterReportPipe,  
    FilterDemandItemsNoBOMPipe,
    FilterDemandItemsNoDrawingPipe,
    FilterDemandItemsNoRouterPipe,
    FilterDemandItemsRouterOnHoldPipe,
    FilterReasonCodePipe,
    FilterFilePipe,
    FilterFixturePipe,
    FilterToolPipe,
    FilterWorkCenterPipe,
    FilterTypePipe,
    FilterTestKitPipe,
    FilterUserMaintenancePipe,
    FilterToolKitPipe,
    FilterRouterFilesPipe,
    FilterWorkStationPipe,
    FilterWorkStationAvailablePipe,
    FilterWorkStationAuthorizedPipe,
    RouterDetailComponent,
    StepDetailComponent,
    RouterInfoComponent,
    RouterStepsComponent,
    RouterFilesComponent,
    RouterNotesComponent,
    StepInfoComponent,
    StepSpecComponent,
    StepBomComponent,
    StepTestsComponent,
    StepNotesComponent,
    BreadCrumbComponent,
    SpecProgramComponent,
    SpecToolComponent,
    SpecFixtureComponent,
    SpecFileComponent,
    PartInfoComponent,
    TestsComponent,
    WorkCentersComponent,
    ToolsComponent,
    ToolsContainerComponent,
    NdeTestsComponent,
    HydTestsComponent,
    PartInfoTabsComponent,
    PartEtchingComponent,
    PartFilesComponent,
    PartNdeComponent,
    PartExtInfoComponent,
    WorkCenterDetailComponent1,
    ProgramsComponent,
    FixturesComponent,
    ReasonCodeComponent,
    FilesComponent,
    AlertActionComponent,
    LoginComponent,
    ListItemComponent,
    ReportsComponent,
    DemandItemNoBOMComponent,
    DemandItemNoDrawingComponent,
    DemandItemNoRouterComponent,
    DemandItemRouterOnHoldComponent,
    KeysPipe,
    Ng2PdfViewerComponent,
    WorkCenterInfoComponent,
    TestKitComponent,
    TestKitDetailComponent,
    TestKitCreateComponent,
    TestKitManagementComponent,
    ToolKitComponent,
    ToolKitDetailComponent,
    ToolKitManagementComponent,
    ToolKitManagement1Component,
    ToolKitCreateComponent,
    UserMaintenanceComponent,
    UserDetailComponent,
    UserInfoComponent,
    SortableColumnComponent,
    SortableTableDirective,
    OverDirective,
    HesitateDirective,
    NgbdDropdown
    // PdfViewerComponent
  ],
  imports: [
    BrowserModule,
    //LoginModule,
    RouterListingModule,
    ReportsModule,
    PartsInfoModule,
    FilesModule,
    FixturesModule,
    ProgramsModule,
    ReasonCodeModule,
    TestsModule,
    UserMaintenanceModule,
    WorkCentersModule,
    TestKitModule,
    ToolsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    HttpModule,
    ReactiveFormsModule,
    routes,
    PdfViewerModule,    
    VirtualScrollerModule,
    ShSelectModule1.forRoot(),
    NgxLoadingModule,
    BrowserAnimationsModule,
    ScrollingModule,
    NgbModule
  ],

// uncomment LocalStrategy provider when building distribution build so browser routes app urls instead of trying to find resources at those urls and failing
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    DebugService,
    PDFService,
    AppHttpClientService,
    RouterServiceHttp,
    Helper,
    RouterValidationService,
    RouterStepServiceHttp,
    MockDataService,
    BOMServiceHttp,
    TestServiceHttp,
    AuthService, // comment this line, uncomment next to use mock login. Uncomment login button in login component to have a way to click login
    // { provide: AuthService, useClass: AuthServiceMock},
    PartServiceHttp,
    { provide: WorkCenterService, useClass: WorkCenterServiceHttp},
    { provide: WorkStationService, useClass: WorkStationServiceHttp},
    WorkCenterServiceHttp,
    WorkStationServiceHttp,
    WorkCenterDataService,
    FileServiceHttp,
/*     FileCacheService, */
    FileCacheService1,
    FixtureServiceHttp,
    ProgramServiceHttp,
    ToolServiceHttp,
    RouterServiceHttp,
    ReportServiceHttp,
    ReasonCodeServiceHttp,
    RouterDomainService,
    PartDomainService,
    AlertService,
    CanDeactivateRouterDetail,
    CanDeactivateRouterDetail1,
    CanDeactivateStepInfo,
    CanDeactivateStepNotes,
    CanDeactivateWorkCenterDetail,
    TestKitDetailResolveFile,
    TestKitDetailResolveFileTests,
    TestKitDetailResolveFilesWithoutKits,
    WorkCenterToolKitDomainService,
    CanDeactivateToolKitDetail,
    LoginService,
    GateKeeperService,
    Config,
    UserMaintenanceServiceHttp,
    UserMaintenanceDataService,
    SortService,
    OnshapeService,
    WorkCenterDetailResolver   
    
    // PDFService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// { provide: PartService, useClass: PartServiceHttp},
