/**
 * Created by yonifarin on 12/3/16.
 */
import {
    Component, OnInit, ElementRef, Input, forwardRef, ViewChild, Renderer2, Output,
    EventEmitter
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { ShSelectComponent } from './sh-slelect.component';


@ Component({
    selector: 'sh-selectAddNewVal',
    template: `<div class="header" [class.sh-select-disabled]="disabled" [class.sh-select-error]="error" [class.inline]="mode==='inline'"
     (click)="show()" [class.open]="isOpen">
    <input type="text"
           #inputFilter
           [hidden]="!isOpen"
           (click)="show()"
           [placeholder]="placeholder"
           [(ngModel)]="filter"
           (ngModelChange)="updateFilter($event)">


    <div (click)="show(); $event.stopPropagation()"

         *ngIf="!isOpen">
        {{selectedValues?.length ?
        (isMultiselect ?
        selectedValues?.length + ' Selected'
        : (selectedValues[0].name || selectedValues))
        : placeholder}}


    </div>
    <i class="close icon clear"
       *ngIf="showClear && selectedValues.length > 0"
       (click)="clear(); $event.stopPropagation()"></i>

       <i class="arrow" [class.down]="isOpen"></i>

</div>
<sh-select-menu [isOpen]="isOpen"
                [rows]="rows"
                [selectedValues]="selectedValues"
                (noToggleClick)="toggleSelected($event)"></sh-select-menu>`,

    styles:[`:host{
    display: block;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-style: normal;
    font-weight: 500;
    font-size: .9em;
    line-height: 1.1em;
    letter-spacing: 0.01rem;
    color: #757575;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}
input::placeholder {
    color: #9e9e9e;
}
.down {
    border: solid #616161;
    transform: rotate(-135deg) !important;
    -webkit-transform: rotate(-135deg) !important;
}
i.arrow {
    float: right;
    position: relative;
    right: -23px;
    top: -16px;
    border: solid #9e9e9e;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.header{
    width: auto;
    margin: 0 !important;
    padding: 6px 40px 0px 10px;
    box-sizing: border-box;
    background-color: white;
    font-size: 1.0em;
    float:left;
    border-radius: 3px;
    border: 1px solid rgba(34,36,38,.15);
    vertical-align: middle;
    cursor: pointer;
}

.header.inline{
    background-color: transparent;
    width: max-content;
    border: none;
}

.header.open{
    border-radius: 2px 2px 0 0;
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
    border-bottom: none;
}

div{
    display: block;
}

input[type="text"]{
    border: none !important;
    vertical-align: middle !important;
    width: 100%;
    margin: 0 !important;
    padding: 0px !important;
    box-sizing: border-box;
    background-color: white;
    font-size: 1.0rem !important;
    line-height: 2rem !important;
    letter-spacing: 0.01rem !important;
    font-family: 'RobotoDraft', 'Roboto', 'Helvetica Neue, Helvetica, Arial', sans-serif;
    font-style: normal !important;
    font-weight: 300 !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;;
    text-rendering: optimizeLegibility !important;;
}

input[type="text"] { outline: none; }


[hidden]{
    display: none;
}

// i.close.icon.clear::after {
//     content: "";
//     padding-right: 8px;
//     font-weight: 800;
//     color: gray;
// }

// i.close.icon.clear:hover::after {
//     color: red;
// }

.clear{
    position: absolute;
    right: 2px;
    padding-left: 2px;
    padding-right: 2px;
    top:3px;
    cursor: pointer;
}
.sh-select-disabled{
    background-color: rgb(235,235,228);
    color: #616161;
    cursor: not-allowed;
}

em {
  float: right;
  color: #E05C65;
  padding-left: 10px;
}

.sh-select-error {
  background-color: #E3C3C5;
}

.sh-select-error ::-webkit-input-placeholder {
  color: #999;
}

`],
    host: {
        '(window:mouseup)': 'onDocumentClick($event)'
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ShSelectProvider(),
            multi: true
        }
    ]
})
export class ShSelectAddNewValComponent extends ShSelectComponent {
    readonly ADD_NEW = 'Add New';

    get canAddNewItem() {
      return this.filter && this.filter.length > 0 &&
         !this.filteredData.includes(this.filter);
    }

    updateFilter(filter){
        const lowercaseFilter = filter.toLocaleLowerCase();
        this.filteredData = this._options.filter(item =>
        !lowercaseFilter || (item.name || item).toLowerCase().indexOf(lowercaseFilter) !== -1);

        if (this.canAddNewItem) {
          this.filteredData.splice(0, 0, this.ADD_NEW);
        }
        this.updateRows(this.filteredData);
    }



    toggleSelected(item){
        if(!item) return;

        if (item === this.ADD_NEW) {
            this.options.push(this.filter);
            this.options.sort();
            item = this.filter;
        }

        super.toggleSelected(item);
    }

    }

export function ShSelectProvider(): any {
    return forwardRef(() => ShSelectAddNewValComponent);
}
