import { inherits } from 'util';
import { AlertLevel } from '../models/alert.model';
import { AlertService} from './alert.service';
import { Component, Injectable, OnInit } from '@angular/core';
//import { IUser } from '../../core/models/user.model';
import { Headers, Http, RequestOptions, Response, ResponseOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Helper } from './helper.service';
// import { AppHttpClientService, ErrorResponse } from './app-http-client.service';
import { AuthService } from './auth.service';



@Injectable()
export class DebugService implements OnInit {

  constructor(private http: Http, private utils: Helper, private alert: AlertService
   // private auth: AuthService
    //private ahttp: AppHttpClientService
  ) {}

    ngOnInit() {
      console.log('debug.service.ngOnInit');
    }

    expireUserSession(tokenId: string): Observable<number> {
      console.log('DebugService.expireUserSession tokenId:' + tokenId);
      const info = {
        tokenId: tokenId
      };
      return this.http.post('Debug/ExpireUserSession', info).map(
      (resp: Response) => {
        return <number>resp.json();
      });
  }


}

