import { StepInfoComponent } from '../../step-detail/step-info/step-info.component';
import { StepInfoComponentBizLogic } from '../../step-detail/step-info/step-info.component.bizl';
import * as jquery from 'jquery';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, AfterContentChecked, OnChanges, DoCheck, AfterContentInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IRouterStep, IdOrder, StepOrder } from '../../core/models/routerstep.model';
import {Helper} from '../../core/services/helper.service';
import { RouterDomainService } from '../../core/services/router-domain.service';
import { RouterDetailsValidator } from '../router-details.validator';
import { AlertLevel, AlertReponse } from '../../core/models/alert.model';
import { AlertService } from '../../core/services/alert.service';
import { RouterStepServiceHttp } from '../../core/services/routerstep.servicehttp';
import { SortCriteria} from '../../core/models/sort.model';
import { RouterState } from '../../core/enums/router-state';
import { RouterStepsComponentBizLogic } from './router-steps.component.bizl';

declare var jQuery: any;

@Component({
  selector: 'router-steps',
  template: require('./router-steps.component.html'),
  // styles: [require('./router-steps.component.css')]
  styles: [require('./router-steps.component.css').toString()]
})
export class RouterStepsComponent implements OnInit, AfterViewInit, AfterContentChecked, OnChanges, DoCheck, AfterContentInit, AfterViewChecked, OnDestroy {

  @ViewChild('tbody', {static: true}) tbody: ElementRef;
  @ViewChild('routerStepsContainer', {static: true}) view: ElementRef;
  @ViewChild('stepInfo', {static: true}) stepInfo: StepInfoComponent;
  tableWasVisible = false;
  bizl: RouterStepsComponentBizLogic;

  updatedOrder: boolean = false;

  private routerId: string;
  routerSteps: IRouterStep[] = [];
  newRouterSteps: IRouterStep[] = [];
  @Output() stepClick = new EventEmitter();
  @Output() sortChange = new EventEmitter();

  constructor(private navRouter: Router,
    private domain: RouterDomainService,
    private alert: AlertService,
    private route: ActivatedRoute, private stepService: RouterStepServiceHttp, private helper: Helper) { }

    private get isVisible(): boolean {
      return this.view.nativeElement.offsetParent != null;
    }

    ngOnChanges() {
      //console.log('#1 Onchanges lifecyle hook');
      this.routerSteps.sort(this.sortByStep);
    }

    ngAfterContentChecked() {
      //console.log('#5 ngAfterContentChecked lifecyle hook');

      if (!this.isVisible && this.tableWasVisible) {
        console.log('router-steps.component.ngAfterContentChecked...visible to hidden');
        this.tableWasVisible = false;
      } else if (this.isVisible && !this.tableWasVisible) {
          console.log('router-steps.component.ngAfterContentChecked...hidden to visible');
          this.bizl = new RouterStepsComponentBizLogic(this.domain.selectedRouter, this.domain);
          this.tableWasVisible = true;
         // console.log(this.routerSteps);
          if (!this.helper.isEmptyString(this.routerId)) {
            this.stepService.getRouterSteps(this.routerId).subscribe(steps => {
            this.routerSteps = steps;            
            this.routerSteps.sort(this.sortByStep);
          });
        }
      }
  }

  sortByStep(s1: IRouterStep, s2: IRouterStep) {
    if (s1.step < s2.step) {
      return -1;
    } else if (s1.step > s2.step) {
      return 1;
    } else {
      return 0;
    }
  }

  ngOnInit() {
    //console.log('#2 ngOnInit lifecyle hook');
    this.routerId = this.route.snapshot.params['id'];
    this.bizl = new RouterStepsComponentBizLogic(this.domain.selectedRouter, this.domain);
    this.routerSteps.sort(this.sortByStep);
  }

  ngDoCheck(){
    //console.log('#3 ngDoCheck lifecyle hook');
  }

  ngAfterContentInit(){
    //console.log('#4 ngAfterContentInit lifecyle hook');
  }

  onSorted(criteria: SortCriteria)
  {    
    var elementId: string = (event.target as Element).id;
    if(elementId.substring(0,3) == 'col')
    {
      if(criteria.sortDirection === 'desc'){
        if(criteria.sortColumn == 'workCenter')
        {
          this.routerSteps.sort(this.sortByWorkCenterDesc)
        }
        else if(criteria.sortColumn == 'description')
        {
          this.routerSteps.sort(this.sortByDescriptionDesc)
        }
        else if (criteria.sortColumn == 'setupTime')
        {
          this.routerSteps.sort(this.sortBySetupTimeDesc)
        }
        else
        {
          this.routerSteps.sort(this.sortByRunTimeDesc)
        }
        
      }
      else {
        if(criteria.sortColumn == 'workCenter')
        {
          this.routerSteps.sort(this.sortByWorkCenterAsc)
        }
        else if(criteria.sortColumn == 'description')
        {
          this.routerSteps.sort(this.sortByDescriptionAsc)
        }
        else if (criteria.sortColumn == 'setupTime')
        {
          this.routerSteps.sort(this.sortBySetupTimeAsc)
        }
        else
        {
          this.routerSteps.sort(this.sortByRunTimeAsc)
        }
      }
    }
  }

  sortByWorkCenterAsc(a1: IRouterStep, a2: IRouterStep) { 
    return Helper.cmpString(a1.workCenterName.toString(), a2.workCenterName.toString()); 
  }

  sortByWorkCenterDesc(a1: IRouterStep, a2: IRouterStep) {
    return Helper.cmpStringDesc(a1.workCenterName.toString(), a2.workCenterName.toString()); 
  }

  sortByDescriptionAsc(a1: IRouterStep, a2: IRouterStep) { 
    return Helper.cmpString(a1.description, a2.description); 
  }
  
  sortByDescriptionDesc(a1: IRouterStep, a2: IRouterStep) {
    return Helper.cmpStringDesc(a1.description, a2.description); 
  }

  sortBySetupTimeAsc(a1: IRouterStep, a2: IRouterStep){ 
    return Helper.cmpString(a1.setupTime.toString(), a2.setupTime.toString()); 
    //return a1[criteria.sortColumn] < a2[criteria.sortColumn];
  }
  
  sortBySetupTimeDesc(a1: IRouterStep, a2: IRouterStep) { 
    return Helper.cmpStringDesc(a1.setupTime.toString(), a2.setupTime.toString()); 
  }    

  sortByRunTimeAsc(a1: IRouterStep, a2: IRouterStep){ 
    return Helper.cmpString(a1.runTime.toString(), a2.runTime.toString()); 
    //return a1[criteria.sortColumn] < a2[criteria.sortColumn];
  }
  
  sortByRunTimeDesc(a1: IRouterStep, a2: IRouterStep) { 
    return Helper.cmpStringDesc(a1.runTime.toString(), a2.runTime.toString()); 
  }

  addRouterStep() {
    this.domain.routerSteps = this.routerSteps;
    this.stepOverlayOpen(this.stepInfo.bizl.newRouterStepDefaultInfo);
  }

  goToRouterStep(routerStep: IRouterStep){
    console.log('selectRouterStep');
    // this.navRouter.navigate(['steps', routerStep.id], {relativeTo: this.route});
    this.domain.selectedRouterStep = routerStep;
    this.domain.routerSteps = this.routerSteps;
    this.navRouter.navigate(['/routers', this.routerId, 'steps', routerStep.id]);
  }

  deleteRouterStep(step: IRouterStep) {
    this.alert.showYesNo('Are you sure you want to delete step ' + step.description + '?',
    AlertLevel.WARNING,
    (ar: AlertReponse) => {
      console.log(ar);
      console.log(ar === AlertReponse.OK);
      console.log(AlertReponse.OK);
      if (ar === AlertReponse.YES) {
        this.stepService.deleteRouterStep(step.id).subscribe(s => {
          this.routerSteps = this.helper.arrayOrEmpty(this.routerSteps.filter(x => x.id !== step.id));
          this.stepClick.emit();
        });
      }
    });
  }

  cancelCreateRouterStep() {
    this.domain.selectedRouterStep = null;  
    this.stepInfo.newStepCancelled();
    this.closeOverlay();
  }

  closeOverlay() {    
    document.getElementById('StepOverlay').style.display = 'none';
  }

  stepOverlayOpen(s: IRouterStep) {
    this.domain.selectedRouterStep = s;
    const thisDomain = this.domain;
    document.getElementById('StepOverlay').style.display = 'block';
    const modal = document.getElementById('StepOverlay');

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      if (event.target === modal) {
        modal.style.display = 'none';
        thisDomain.selectedRouterStep = null;
      }
    };
  }

  handleSortableUpdate(){
    console.log('router-step.comonent.handleSortableUpdate');
    console.log(this.routerSteps);
    const tableRows = jquery(this.tbody.nativeElement).children();
    const stepOrder = this.helper.getStepOrder(this.routerId, tableRows);

    if (this.domain.isEditable)
    {
      this.stepService.updateOrder(stepOrder).subscribe( res => {
      console.log(res);
    });
    }

    console.log('router-step.comonent.handleSortableUpdate.refreshStepListing');

    //-- refresh step listing
    this.updatedOrder = true;
  }

  ngAfterViewInit() {
    //console.log('#6 ngAfterViewInit lifecyle');
    this.routerSteps.sort(this.sortByStep);
    const thisObj = this;
    if (this.domain.isEditable)
    {
      jQuery(this.tbody.nativeElement).sortable({
        update: function(e, ui) {
          console.log('router-steps.component.DRAG AND DROP UPDATE');
          console.log(e);
          console.log(ui);
          thisObj.handleSortableUpdate();
        }
      });
    console.log('JQUERY Move USED');
    }
    
  }

  ngAfterViewChecked() {
    //console.log('#7 ngAfterViewChecked lifecyle');

    //-- refresh step listing on reorder
    if (this.updatedOrder)
    {
      this.refreshStepListing();
      this.updatedOrder = false;
    }
  }

  ngOnDestroy() {
    //console.log('#8 ngOnDestroy lifecyle');
  }

  refreshStepListing(): void
  {
    this.stepService.getRouterSteps(this.routerId).subscribe(steps => {
      this.routerSteps = steps;            
      this.routerSteps.sort(this.sortByStep);
    });
  }

  FormatSecondsToTime(secondsTime: number): string
  {
    let formatedTime: string = "";

    let hrsTime: number = this.helper.getRemHours(secondsTime);
    let minsTime: number = this.helper.getRemMinutes(secondsTime);
    let secsTime: number = this.helper.getRemSeconds(secondsTime);

    return formatedTime = hrsTime.toString().padStart(2,'0') + ':' + minsTime.toString().padStart(2,'0') + ':' + secsTime.toString().padStart(2,'0');
  }
}
