import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProgramsComponent } from '../programs/programs.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([
            {path: 'programs', component: ProgramsComponent, data: {breadcrumb: 'Programs'}}
        ])
    ],
    declarations: []
})
export class ProgramsModule { }