import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterType'
})

export class FilterTypePipe implements PipeTransform {
// create feed of items, link to checkbox filter, mod for re-use
// filter pipe
// @Pipe({name: 'fabricType'})
// export class FabricTypePipe implements PipeTransform {
//   transform(fabrics: any[], fabricTypes: string[]): any[] {
//     if (!fabricTypes || fabricTypes.length === 0) return fabrics;
//     return fabrics.filter(fabric => fabricTypes.includes(fabric.type));
//   }
// }
//
// HTML TEMPLATE
// <div *ngFor="let colour of fabricColours">
// <input type="checkbox" [(ngModel)]="colour.selected" />{{fabrics | fabricType: types | countColour: colour.name}} {{colour.name}}
// </div>
//
// <div *ngFor="let fabric of fabrics | fabricType: types">{{fabric.name}}</div>
//
// count pipe
// @Pipe({name: 'countColour'})
// export class CountColourPipe implements PipeTransform {
//   transform(fabrics: any[], colour: string): number {
//     if (!fabrics || fabrics.length === 0) return 0;
//     return fabrics.reduce((count, fabric) => fabric.colour === colour ? count + 1 : count, 0);
//   }
// }

  transform(routers: any, term: any): any {
    // check if term is undefined
    if (term === undefined || term.length == 0 || routers === null) { return routers; }
    // return the updated routers array
    return routers.filter(function(router){
    return router.state.toLowerCase().includes(term.toLowerCase());
    });
  }
}
