import { Component, DoCheck, OnChanges, OnInit, AfterContentInit, AfterViewChecked, AfterViewInit, ViewChild, ElementRef, OnDestroy, AfterContentChecked } from '@angular/core';
import { FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { ITool } from '../core/models/tool.model';
import { ToolsComponentBizLogic } from './tools.component.bizl';
import { Helper } from '../core/services/helper.service';
import { AlertService } from '../core/services/alert.service';
import { AlertLevel, AlertReponse } from '../core/models/alert.model';
import { isNumber } from 'util';
import { ToolServiceHttp } from '../core/services/tool.servicehttp';
import { SortCriteria} from '../core/models/sort.model';

@Component({
selector: 'tools',
template: require('./tools.component.html'),
// styles: [require('./tools.component.css')]
styles: [require('./tools.component.css').toString()]
})
export class ToolsComponent implements  OnInit, DoCheck, AfterContentChecked, AfterViewChecked, AfterViewInit, AfterContentInit {
tools: ITool[];
ToolFormGroup: FormGroup;
ToolNumber: FormControl;
ToolDescription: FormControl;
selectedTool: ITool;
public term: string;
@ViewChild('toolForm', {static: true}) toolForm: ElementRef;
@ViewChild('table', {static: false}) table: ElementRef;
overlayWasVisible = false;
userSubmitted = false;
bizl: ToolsComponentBizLogic = null;
lastToolNumberValidationMsg = '';
lastToolDescriptionValidationMsg = '';
dir ='asc';
editMode: Boolean = false;

constructor(private toolService: ToolServiceHttp,
  private alert: AlertService,
private utils: Helper) { }

ngOnInit() {
  console.log('tools.component.OnInit');

  this.ToolNumber = new FormControl('', Validators.compose([Validators.required, this.makeValidateUniqueToolNumber()]));
  this.ToolDescription = new FormControl('', Validators.compose([Validators.required, this.makeValidateUniqueDescription()]));

  this.ToolFormGroup = new FormGroup({
    number: this.ToolNumber,
    description: this.ToolDescription
  });

  this.toolService.getTools().subscribe(tools => {
    this.tools = tools;
    this.tools.sort(this.sortTools);
  });
/*
  this.toolService.getWorkCenterToolKits().subscribe(kits => {
    this.toolKits = kits;
    this.toolKits.sort(this.sortWarehouseWorkCenterDescription);
  });
*/
  this.bizl = new ToolsComponentBizLogic(this);
}


makeValidateUniqueToolNumber() {
  const _self = this;
  return function(fc: FormControl) {
   // console.log('spec-tool.component.validateUnique');
    const ret = _self.ToolFormGroup && _self.bizl.isUniqueToolNumber(_self.editMode) ? null : {
      validateUnique: {
        valid: false
      }
    };
    // console.log('spec-tool.component ret ' + ret);
    return ret;
  };
}

makeValidateUniqueDescription() {
  const _self = this;
  return function(fc: FormControl) {
   // console.log('spec-tool.component.validateUnique');
    const ret = _self.ToolFormGroup && _self.bizl.isUniqueDescription(_self.editMode) ? null : {
      validateUnique: {
        valid: false
      }
    };
    // console.log('spec-tool.component ret ' + ret);
    return ret;
  };
}



toFormGroup(tool: ITool) {
  console.log('tools.component.toFormGroup');
  console.log(this.bizl);
  this.ToolFormGroup.patchValue({
      number: tool.number,
      description: tool.description
  });

  if (!this.bizl.isToolEditable) {
    this.ToolNumber.disable();
    this.ToolDescription.disable();
  } else {
    this.ToolNumber.enable();
    this.ToolDescription.enable();
  }

  console.log('...toFormGroup DONE');
}

onSorted(criteria: SortCriteria)
{    
    var elementId: string = (event.target as Element).id;
    if(elementId.substring(0,3) == 'col')
    {
      if(criteria.sortDirection === 'desc'){          
        if(criteria.sortColumn == 'number')
        {
          this.tools.sort(this.sortByNumberDesc)
        }
        else if (criteria.sortColumn == 'description')
        {
          this.tools.sort(this.sortByDescriptionDesc)
        }        
      }
      else {
        if(criteria.sortColumn == 'number')
        {
          this.tools.sort(this.sortByNumberAsc)
        }
        else if (criteria.sortColumn == 'description')
        {
          this.tools.sort(this.sortByDescriptionAsc)
        }               
      }
    }
    
} 

// sortTable(n, event) {
//   var elementId: string = (event.target as Element).id;
 

//   if (elementId.substring(0,4) == 'col1' && n == 0)
//   {    
//     if (this.dir == 'asc')
//     {
//       this.dir = 'desc'
//       this.tools.sort(this.sortByNumberAsc)
//     }
//     else 
//     {
//       this.dir = 'asc'
//       this.tools.sort(this.sortByNumberDesc)
//     }
    
//   }
//   if (elementId.substring(0,4) == 'col2' && n == 1)
//   if (this.dir == 'asc')
//     {
//       this.dir = 'desc'
//       this.tools.sort(this.sortByDescriptionAsc)
//     }
//     else 
//     {
//       this.dir = 'asc'
//       this.tools.sort(this.sortByDescriptionDesc)
//     }
// }


sortByNumberAsc(a1: ITool, a2: ITool) { 
  return Helper.cmpString(a1.number, a2.number); 
}

sortByNumberDesc(a1: ITool, a2: ITool) { 
  return Helper.cmpStringDesc(a1.number, a2.number); 
}

sortByDescriptionAsc(a1: ITool, a2: ITool) { 
  return Helper.cmpString(a1.description, a2.description); 
}

sortByDescriptionDesc(a1: ITool, a2: ITool) {
  return Helper.cmpStringDesc(a1.description, a2.description); 
}


fromFormGroup(info: ITool): ITool {
  return {
    id: this.utils.isExistingItem(this.selectedTool) ? this.selectedTool.id : null,
    number: info.number,
    description: info.description,
    order: -1,
    inUse: this.utils.isExistingItem(this.selectedTool) ? this.selectedTool.inUse : false,
    userDefined: this.selectedTool ? this.selectedTool.userDefined : false    };
}

ngDoCheck() {
 // console.log('tools.component.DoCheck');
}

getTitle() {
  if (this.bizl.isNewTool) {
    return 'Add a Tool';
  } else if (this.bizl.isToolEditable) {
    return 'Edit a Tool';
  } else {
    return 'Tool Info';
  }
}

resetFormValidation() {
  this.userSubmitted = false;
  // yourForm.reset(), yourForm.resetForm() don't work, but this does:
  this.ToolFormGroup.markAsPristine();
  this.ToolFormGroup.markAsUntouched();
  this.ToolFormGroup.updateValueAndValidity();
}

get isOverlayVisible() {
  return this.toolForm.nativeElement.offsetParent != null;
}

ngAfterContentChecked() {
  console.log('tools.component.ngAfterContentChecked');

  if (!this.isOverlayVisible && this.overlayWasVisible) {
    console.log('tools.component.ngAfterContentChecked...visible to hidden ');
    this.overlayWasVisible = false;
    this.resetFormValidation();
  } else if (this.isOverlayVisible && !this.overlayWasVisible) {
    console.log('tools.component.ngAfterContentChecked...hidden to visible');
    this.overlayWasVisible = true;
    this.userSubmitted = false;
    this.resetFormValidation();
  }
}

validateDescription() {
  const valid =  (!this.bizl.isNewTool && this.ToolFormGroup.controls.description.pristine) ||
              (this.bizl.isNewTool && !this.userSubmitted) ||
              this.ToolDescription.valid;

   if (this.ToolDescription.errors) {
      if (this.ToolDescription.errors.validateUnique) {
        this.lastToolDescriptionValidationMsg = 'Description must be unique';
      } else {
        this.lastToolDescriptionValidationMsg = 'Required';
      }
   }

   return valid;
}

validateToolNumber() {
  const valid =  (!this.bizl.isNewTool && this.ToolFormGroup.controls.description.pristine) ||
              (this.bizl.isNewTool && !this.userSubmitted) ||
              this.ToolNumber.valid;

   if (this.ToolNumber.errors) {
      if (this.ToolNumber.errors.validateUnique) {
        this.lastToolNumberValidationMsg = 'Tool number must be unique';
      } else {
        this.lastToolNumberValidationMsg = 'Required';
      }
   }

   return valid;
}

validateNewToolInfo() {
  return this.ToolFormGroup.valid &&  this.validateDescription() && this.validateToolNumber();
}

getNewTool() {
 return this.fromFormGroup(this.ToolFormGroup.getRawValue());
}

addTool() {
  this.editMode=false;
  this.overlayOpen(this.bizl.newToolDefaultInfo);
}

deleteTool(p: ITool) {
  this.alert.showYesNo('Are you sure you want to delete tool ' + p.description + '?',
  AlertLevel.WARNING,
  (ar: AlertReponse) => {
    console.log(ar);
    console.log(ar === AlertReponse.OK);
    console.log(AlertReponse.OK);
    if (ar === AlertReponse.YES) {
      this.toolService.deleteTool(p.id).subscribe( res => {
        this.tools = this.tools.filter(x => x.id !== p.id);
      });
    }
  });
}


submit(formValues) {
 console.log('tools.components.submit');
 console.log(this.ToolFormGroup);
 console.log(this.bizl);
 this.userSubmitted = true;
 if (!this.validateNewToolInfo()) {
   return;
 }
 const p = this.fromFormGroup(formValues);
 console.log(p);
 if (this.bizl.canCreate) {
   console.log('...create');
   this.toolService.createTool(p).subscribe(added => {
     this.tools.push(added);
     this.tools.sort(this.sortTools);
     //this.sortTable(0);
     this.closeOverlay();
   });
 } else if (this.bizl.canUpdate) {
  console.log('...update id:' + p.id);
  this.toolService.updateTool(p).subscribe(updated => {
    console.log('...updated id:' + updated.id);
    const tmp = this.tools;
    this.tools = null;
    this.tools = tmp.filter(x => x.id !== updated.id);
    this.tools.push(updated);
    this.tools.sort(this.sortTools);
    //this.sortTable(0);
    this.closeOverlay();
  });
 } else {
   this.closeOverlay();
 }
}

sortTools(t1: ITool, t2: ITool) {
let ret = Helper.cmpString(t1.number, t2.number);
if (ret == 0) {
  ret = Helper.cmpString(t1.description, t2.description);
}
return ret;
}


viewDetails(tool: ITool) {
  this.editMode = true;
  this.overlayOpen(tool);
}

closeOverlay() {
  this.selectedTool = null;
  //this.sortTable(0);
  document.getElementById('ToolOverlay').style.display = 'none';
}
overlayOpen(tool: ITool) {
  console.log('tools.component.overlayOpen');
  console.log(tool);
  const _self = this;
  this.selectedTool = tool;
  this.toFormGroup(tool);

  console.log('launching overlay');
  document.getElementById('ToolOverlay').style.display = 'block';
  const modal = document.getElementById('ToolOverlay');

  // When the user clicks anywhere outside of the modal, this closes it
  window.onclick = function(event) {
      if (event.target === modal) {
          modal.style.display = 'none';
          _self.selectedTool = null;
      }
  };
}

ngAfterViewChecked() {
  console.log('Tool Component.AfterViewChecked');
  let c1 = $('.col1').width();
  let c2 = $('.col2').width();    
  let th = $('#tableHeader').width();

  // console.log(c1);
  $('#col1').width(c1+ 1);
  $('#col2').width(c2); 
  $('.SearchBar').width(c1 + c2 +2);
  $('#Cover').width(th);
 }
 ngAfterViewInit() {
      console.log('Tool Component.AfterViewInit');
       // runs every time the user re-sizes the window
      
       $(window).resize(function(){
        // gets td column widths
          let c1 = $('.col1').width();
          let c2 = $('.col2').width();                   
          let th = $('#tableHeader').width();
          // sets th column widths to match td's
          $('#Cover').width(th + 1);
          $('#col1').width(c1 + 1);
          $('#col2').width(c2);              
          $('.SearchBar').width(c1 + c2 + 2);
          console.log ('resize header occured');
          });
     }
 ngAfterContentInit()
  {
    console.log('Tool Component.AfterContentInit');
    const c1 = $('.col1').width();
    const c2 = $('.col2').width();
    let th = $('#tableHeader').width();
  
    $('#Cover').width(th + 1);
    $('#col1').width(c1);
    $('#col2').width(c2);

    $('.SearchBar').width(th - 20); 
 }
}
