import { ISubscription } from 'rxjs/Subscription';
import { Component, OnInit, OnChanges, AfterContentChecked,
   Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { IRouter, RouterTypeUtils, RouterType, RouterUtils } from '../../core/models/router.model';
import { RouterDetailsValidator} from '../router-details.validator';
import {Router, ActivatedRoute} from '@angular/router';
import {Helper} from '../../core/services/helper.service';
import {IPart} from '../../core/models/part.model';
import {RouterState} from '../../core/enums/router-state';
import {RouterDomainService} from '../../core/services/router-domain.service';
import {RouterValidationService } from '../../core/services/router-validation.service';
import {ShSelectComponent} from '../../core/shared/sh-slelect.component';
import { RouterInfoComponentBizLogic } from './router-info.component.bizl';
import { AuthService } from '../../core/services/auth.service';
import { PartServiceHttp } from '../../core/services/part.servicehttp';
import { RouterServiceHttp } from '../../core/services/router.servicehttp';
import 'rxjs/add/operator/takeWhile';
import { _MatSelectBase } from '@angular/material/select';


@Component({
  selector: 'router-info',
  template: require('./router-info.component.html'),
  // styles: [require('./router-info.component.css')]
  styles: [require('./router-info.component.css').toString()]
})

export class RouterInfoComponent implements OnInit, AfterContentChecked, OnDestroy {
     @ViewChild('routerInfo', {static: true}) view: ElementRef;
     @ViewChild('selectPart', {static: true}) selectPart: ShSelectComponent;
     //@ViewChild('selectPartGroup', {static: true}) selectPartGroup: ElementRef;
      viewWasVisible = false;
      allParts: IPart[] = null;
      parts: IPart[] = [];
      public RouterInfoGroup: FormGroup;
      partNumber: FormControl;
      revision: FormControl;
      targetWIPH: FormControl;
      targetWIPM: FormControl;
      targetWIPS: FormControl;
      PSLevel: FormControl;
      selectedParts: IPart[];
      selectingParts:boolean = false;
      subscription: ISubscription;
      alive = true;
      bizl: RouterInfoComponentBizLogic;
      userSubmitted:boolean = false;

      get selectedRouter() {
        return this.domain.selectedRouter;
      }

      set selectedRouter(val: IRouter){
        this.domain.selectedRouter = val;
      }

      constructor(private route: ActivatedRoute
        , private partService: PartServiceHttp
        , private routerService: RouterServiceHttp
        , private navRouter: Router
        , private helper: Helper
        , private validator: RouterValidationService
        , private domain: RouterDomainService,
          private auth: AuthService) {  }

    ngOnDestroy() {
      console.log('router-info.compoent.onDestroy');
   }

get hasChanges(){
  return this.bizl != null && this.bizl.canUpdateRouter;
}

      ngOnInit() {
        console.log('RouterinfoComponent.ngOnInit');
        this.bizl = new RouterInfoComponentBizLogic(this, this.domain);
        const item = RouterType.CELL;
        this.alive = true;
        this.partNumber = new FormControl('', 
          Validators.compose([
            this.makeValidatePartRequired(),
            this.makeValidatePartDoesNotHavePendingRouter()
          ])        
        );
        this.revision = new FormControl({value: '', disabled: true}, Validators.required);
        this.targetWIPH = new FormControl('', Validators.required);
        this.targetWIPM = new FormControl('', Validators.required);
        this.targetWIPS = new FormControl('', Validators.required);
        this.PSLevel = new FormControl('', Validators.required);

        this.RouterInfoGroup = new FormGroup({
          partNumber: this.partNumber,
          revision: this.revision,
          targetWIPH: this.targetWIPH,
          targetWIPM: this.targetWIPM,
          targetWIPS: this.targetWIPS,
          PSLevel: this.PSLevel
        });
      }



  makeValidatePartDoesNotHavePendingRouter(): ValidatorFn {
    const _self = this;
    return (control: AbstractControl): {[key: string]: any} | null => {
    // console.log('router-info.component.makeValidatePartDoesNotHavePendingRouter');
    // console.log(_self.selectedPart);
    // console.log(!_self.selectingParts);
    // console.log(_self.latestRouterVersionIsPending());
      var ret = 
        ( 
          _self.selectedPart != null && 
         !_self.selectingParts &&
          _self.latestRouterVersionIsPending()       
        ) 
        ? {'partHasPendingRouter': {valid: false}} 
        : null;
      //console.log('router-info.component.makeValidatePartDoesNotHavePendingRouter ret:' + ret );
      return ret;
    };
  }

  latestRouterVersionIsPending() {
    var ret = false;    
    //console.log('router-info.component.latestRouterVersionIsPending start');
    //console.log(this.selectedPart);
    if (!Helper.sIsEmptyArray(this.domain.latestRouterVersionInfos) && this.selectedPart != null)
    {
         //console.log('router-info.component.latestRouterVersionIsPending checking latestVersion');
         var info = this.domain.latestRouterVersionInfos.find(x=>x.partId == this.selectedPart.id );
         //console.log(info);
         ret = info && info != null && RouterState.getState(info.latestRouterState) == RouterState.PENDING;
    }
    //console.log('router-info.component.latestRouterVersionIsPending ret:' + ret);        
    return ret;
  }


makeValidatePartRequired(): ValidatorFn {
    const _self = this;
    return (control: AbstractControl): {[key: string]: any} | null => {

/*      console.log(this.selectedPart);
     console.log(!this.selectingParts); */
      var ret = 
        (
          _self.selectedPart == null && 
          !_self.selectingParts
        ) 
        ? {'partRequired': {valid: false}} 
        : null;
      //console.log('router-info.component.makeValidatePartRequired ret:' + ret );
      return ret;
    };
  }

clearInfo() {
  const r = RouterDetailsValidator.newRouterDefault;
  this.RouterInfoGroup.patchValue({
    partNumber: r.partNumber,
    revision: r.revision,
    targetWIPH: this.helper.getRemHours(r.targetWIP),
    targetWIPM: this.helper.getRemMinutes(r.targetWIP),
    targetWIPS: this.helper.getRemSeconds(r.targetWIP),
    PSLevel: r.psl
  });
}

private patchFormGroup(r: IRouter) {

  const p = this.allParts.find( n => n.id === r.partId);
  //console.log('router-info.compoents.patchFormGroup');
  //console.log(r);
  //console.log(p);
  this.RouterInfoGroup.patchValue({
    partNumber: p,
    revision: r.revision,
    targetWIPH: this.helper.getRemHours(r.targetWIP),
    targetWIPM: this.helper.getRemMinutes(r.targetWIP),
    targetWIPS: this.helper.getRemSeconds(r.targetWIP),
    PSLevel: r.psl
  });

  if (p != null) {
    this.selectedParts = [p];
    this.selectedPart = p;
  } else {
    this.selectedParts = [];
    this.selectedPart = null;
  }

  if (this.bizl.isPartEditable) {
    this.selectPart.disabled = false;
  } else {
    this.selectPart.disabled = true;
  }

  if (this.bizl.isRevisionEditable) {
    this.revision.enable();
  } else {
    this.revision.disable();
  }
  if (this.bizl.isTargetWIPEditable) {
    this.targetWIPH.enable();
    this.targetWIPM.enable();
    this.targetWIPS.enable();
  } else {
    this.targetWIPH.disable();
    this.targetWIPM.disable();
    this.targetWIPS.disable();
  }
  if (this.bizl.isPSLEditable) {
    this.PSLevel.enable();
  } else {
    this.PSLevel.disable();
  }
}

  private fromFormValues(): IRouter {
/*     console.log('router-info.component.fromFormValue');
    console.log(this.selectedParts);
    console.log(this.selectedPart); */
     // console.log(this.partNumber.value.number);
   let p: IPart = this.selectedPart;
     return {
        id: (this.selectedRouter != null ? this.selectedRouter.id : ''),
        partId: p != null ? p.id : null,
        partNumber: p != null ? p.number : '',
        partDescription: p != null ? p.description : '',
        revision: this.revision.value,
        state:  (this.selectedRouter != null ? this.selectedRouter.state : RouterState.PENDING),
        type: RouterType.NONCELL,
        lastUpdate: new Date(),
        updatedBy: 'A. Sanchez',
        userId: null,
        activeWIPTotal: 0,
        targetWIP: this.helper.calSecs(this.targetWIPH.value, this.targetWIPM.value, this.targetWIPS.value),
        psl: this.PSLevel.value
     };
  }

  selectPartClickHandler(event) {
    //console.log('router-info.selectPartClickHandler');
    this.selectingParts = true;
    this.RouterInfoGroup.controls.partNumber.updateValueAndValidity();
  }

  selectPartOnHide(event) {
    //console.log('router-info.selectPartOnHide');
    this.selectingParts = false;
    this.RouterInfoGroup.controls.partNumber.markAsTouched();    
    this.RouterInfoGroup.controls.partNumber.updateValueAndValidity();
  }
  
  private _selectedPart:IPart = null;

  set selectedPart(val:IPart)
  {
    this._selectedPart = val;
  }

  get selectedPart(): IPart {
    return this._selectedPart;
  }

  validatePart() {
     var ret = !this.bizl.isPartEditable ||
              (this.bizl.isPartEditable && !this.userSubmitted && !this.RouterInfoGroup.controls.partNumber.touched) || // data not loaded, yet
              (this.bizl.isNewRouter && !this.userSubmitted && !this.RouterInfoGroup.controls.partNumber.touched ) || // new step; so, let user try to add data
              this.RouterInfoGroup.controls.partNumber.valid;

/*      console.log('router-info.component.validatePart returns:' + ret);
       console.log(this.bizl);
      console.log(this.RouterInfoGroup.controls.partNumber); 
      console.log('...partNumber.valid:' + this.RouterInfoGroup.controls.partNumber.valid);
      console.log('...partNumber.touched:' + this.RouterInfoGroup.controls.partNumber.touched);        
      console.log('...partNumber.errors:' + this.RouterInfoGroup.controls.partNumber.errors);  
      console.log('...selectingParts:' + this.selectingParts);
      console.log('...selectedParts:' + this.selectedParts);   */
      return ret;        
  }


/* 
  validatePart() {
    const ret =  this.selectedPart != null && this.partNumberError == null;
    console.log('router-info.component.validatePart ret:' + ret);
    return ret;
  } */

  get partNumberError(): ValidationErrors
  {
    var ret:ValidationErrors = null;
    
    if (
      this.RouterInfoGroup &&
      this.RouterInfoGroup.controls &&
      this.RouterInfoGroup.controls.partNumber
     )
     ret = this.RouterInfoGroup.controls.partNumber.errors;
  
     return ret; 
  }

  get partValidationError(){
    console.log('rotuer-info.component.partValidationError');
    var err = 'Error';

    const errors = this.RouterInfoGroup.controls.partNumber.errors;
    console.log(errors);
    if (errors) {
      if (errors.partRequired) {
        err =  'Required';
      } else if (errors.partHasPendingRouter) {
        err = 'This part already has a PENDING router';
      } 
    }

    return err;
  }



  private get isVisible(): boolean {
    return this.view.nativeElement.offsetParent != null;
  }

resetFormValidation() {

  // yourForm.reset(), yourForm.resetForm() don't work, but this does:
  this.RouterInfoGroup.markAsPristine();
  this.RouterInfoGroup.markAsUntouched();
  this.RouterInfoGroup.updateValueAndValidity();
}

  ngAfterContentChecked() {
    //console.log('router-info.component.ngAfterContentChecked isVisible:' + String( this.isVisible ? 'true' : 'false'));
  
    let subscription: ISubscription;

   // this.virtualScroll.refresh();
    if (!this.isVisible && this.viewWasVisible) {
      console.log('router-info.component.ngAfterContentChecked...visible to hidden');
      this.viewWasVisible = false;

      if (this.bizl.canUpdateRouter) {
        console.log('router-info.component.ngAfterContentChecked...updating router');
        subscription = this.routerService.updateRouter(this.fromFormValues())
        .takeWhile(() => this.alive)
        .subscribe(updated => {
          RouterUtils.copy(this.selectedRouter, updated);
        });
      }
    } else if (this.isVisible && !this.viewWasVisible) {
        console.log('router-info.component.ngAfterContentChecked...hidden to visible');
        this.viewWasVisible = true;
        this.resetFormValidation();
        if (this.helper.isEmptyArray(this.allParts)) {
          this.partService.getParts()
          .takeWhile(() => this.alive)
          .subscribe(parts => {
            console.log('...getParts returned parts length: ' + parts.length);
            this.allParts = parts;
            this.parts = this.prepParts(this.allParts);
            this.patchFormGroup(this.selectedRouter);
          });
        } else {
          this.parts = this.prepParts(this.allParts);
          this.patchFormGroup(this.selectedRouter);
        }
    }
  }


get isNewRouterValid() {
  var ret = this.RouterInfoGroup.valid;
  //console.log('router-info.component.bizl.isNewRouterValid ret:' + ret);
  //console.log(this.RouterInfoGroup);
  return ret;
}

getNewRouter() {
  return this.fromFormValues();
}

  prepParts(allParts: IPart[]): IPart[] {
    console.log('router-info.omponent.prepParts');
    var ret: IPart[] = [];

    if (
      !this.helper.isEmptyArray(this.domain.excludePartIds) &&
      !this.helper.isEmptyArray(this.allParts))
    {
      for (const p of allParts){
        const t = this.domain.excludePartIds.find( x => x === p.id);
        if (!t) {ret.push(p);}
      }    
    }
    else
    {
      ret = !this.helper.isEmptyArray(this.allParts)? this.allParts.slice(): [ ];
    }
    return ret.sort(this.sortParts);
  }

  sortParts(p: IPart, p1: IPart): number {
    return Helper.cmpString(p.number, p1.number);

  }

  onSelectHandler(selectedParts:any[]) {
    console.log('router-info.component.onSelectHandler');
    console.log(selectedParts);
    if (!this.helper.isEmptyArray(selectedParts))
    {
      this.selectedPart = selectedParts[0];
      if (!this.helper.isEmptyArray(this.domain.latestRouterVersionInfos)){
        var t = this.domain.latestRouterVersionInfos.find(x => x.partId == this.selectedPart.id );
        if (t != null)
        {
          var newRevision = t.latestRouterRevision + 1;
          console.log('... newRevision:' + newRevision);
          this.RouterInfoGroup.patchValue({ revision: newRevision });
        }        
      } 
    }   

    this.RouterInfoGroup.controls.partNumber.updateValueAndValidity();
  }
}



