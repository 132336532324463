import { Component, OnInit, AfterContentChecked, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { IFixture } from '../../../core/models/fixture.model';
import { Helper } from '../../../core/services/helper.service';
import { FilterFixturePipe } from '../../../core/pipes/filter-fixture.pipe';
import { FixtureServiceHttp } from '../../../core/services/fixture.servicehttp';
import { SortCriteria} from '../../../core/models/sort.model';

@Component({
  selector: 'spec-fixture',
  template: require('./spec-fixture.component.html'),
  // styles: [require('./spec-fixture.component.css')]
  styles: [require('./spec-fixture.component.css').toString()]
})
export class SpecFixtureComponent implements OnInit, AfterContentChecked {
  @Input() stepFixtures: IFixture[];
  @Output() fixtureSelected = new EventEmitter();
  fixtures: IFixture[];   public term: string;
  @ViewChild('allFixtureList', {static: true}) allFixtureList: ElementRef;
  allFixtureLIstWasVisible = false;

  constructor(private fixtureService: FixtureServiceHttp, private helper: Helper) {   }

   filterFixtures(removeFixtures): void {
      if (!this.helper.isEmptyArray(this.fixtures) && !this.helper.isEmptyArray(this.stepFixtures)) {
        const ret: IFixture[] = [];
        for (const p of this.fixtures){
          const stepP = this.stepFixtures.find(x => x.id === p.id);
          if (!stepP) {
            ret.push(p);
          }
        }
        this.fixtures = ret;
      }
   }

   private get isVisible(): boolean {
    return this.allFixtureList.nativeElement.offsetParent != null;
  }

  ngAfterContentChecked() {
    console.log('step-fixture.component.ngAfterContentChecked isVisible:' + String( this.isVisible ? 'true' : 'false'));
    if (!this.isVisible && this.allFixtureLIstWasVisible ) {
      console.log('...visible to hidden');
      this.allFixtureLIstWasVisible = false;
    } else if (this.isVisible && !this.allFixtureLIstWasVisible ) {
        console.log('...hidden to visible');
        this.allFixtureLIstWasVisible  = true;
        this.fixtureService.getFixtures().subscribe(allFixtures => {
          this.fixtures = allFixtures;
          console.log('....pre filter fixtures count:' + this.fixtures.length);
          console.log('....step fixture count:' + this.stepFixtures.length);
          this.filterFixtures(this.stepFixtures);
          console.log('....filtered fixtures count:' + this.fixtures.length);
        });

    }
  }



  ngOnInit() {
    console.log('spec.fixture.component.ngOnInit');
  }

  onSorted(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'number')
          {
            this.fixtures.sort(this.sortByNumberDesc)
          }         
          else if (criteria.sortColumn == 'description')
          {
            this.fixtures.sort(this.sortByDescriptionDesc)
          }          
        }
        else {
          if(criteria.sortColumn == 'number')
          {
            this.fixtures.sort(this.sortByNumberAsc)
          }          
          else if (criteria.sortColumn == 'description')
          {
            this.fixtures.sort(this.sortByDescriptionAsc)
          }         
        }
      }      
  } 
  
  
  sortByNumberAsc(a1: IFixture, a2: IFixture) { 
    return Helper.cmpString(a1.number, a2.number); 
  }
  
  sortByNumberDesc(a1: IFixture, a2: IFixture) { 
    return Helper.cmpStringDesc(a1.number, a2.number); 
  }
  
  sortByDescriptionAsc(a1: IFixture, a2: IFixture) { 
    return Helper.cmpString(a1.description, a2.description); 
  }
  
  sortByDescriptionDesc(a1: IFixture, a2: IFixture) {
    return Helper.cmpStringDesc(a1.description, a2.description); 
  }

  handleSelectFixture(fixture): void {
    this.fixtures = this.helper.arrayOrEmpty(this.fixtures);
    this.fixtures = this.fixtures.filter(x => x.id !== fixture.id);
    this.fixtureSelected.emit(fixture);
  }

}
