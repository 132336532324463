import {Component, Input} from '@angular/core';
import { IRouter } from '../core/models/router.model';


@Component(
{
selector: 'router-thumbnail',
template: `
<div>{{router.partNumber}}
<span class='pad-left'>{{router.partDescription}}</span>
<span class='pad-left'>{{router.revision}}</span>
<span class='pad-left'>{{router.state}} [{{router.activeWIPTotal}}]</span>
<span class='pad-left'>{{router.lastUpdate}}</span>
<span class='pad-left'>{{router.updatedBy}}</span>

</div>

`,
styles: [`
.pad-left {margin-left:20px;}
`]
})
export class RouterThumbnailComponent {

    @Input() router: IRouter;


}

