import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TestKitComponent } from '../test-kit/test-kit.component';
import { TestKitDetailComponent} from '../test-kit/test-kit-detail/test-kit-detail.component';
import { TestKitDetailResolveFilesWithoutKits } from '../test-kit/test-kit-detail/test-kit-detail-resolveFilesWithoutKits';
import { TestKitDetailResolveFile } from '../test-kit/test-kit-detail/test-kit-detail-resolveFile';
import { TestKitDetailResolveFileTests } from '../test-kit/test-kit-detail/test-kit-detail-resolveFileTests';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([
            { path: 'testKits', component: TestKitComponent, data: {breadcrumb: 'Test Kit Maintenance'}},
            { path: 'testKits/:id', component: TestKitDetailComponent , 
                resolve: 
                { files: TestKitDetailResolveFilesWithoutKits, 
                    file: TestKitDetailResolveFile, 
                    fileTests: TestKitDetailResolveFileTests }, 
                    data: {breadcrumb: 'Test Kit Maintenance'}},
            { path: 'testKits/new', component: TestKitDetailComponent, data: {breadcrumb: 'Test Kit Maintenance'}},
        ])
    ],
    declarations: []
})
export class TestKitModule { }