
import {observable, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { of as observableOf} from 'rxjs/observable/of'
import { WorkCenterDetailComponent } from './work-center-detail.component';
import { WorkCenterDetailComponent1 } from './work-center-detail.component1';
import { WorkCenterService } from '../../core/services/workcenter.service';
import { AlertService } from '../../core/services/alert.service';
import { AlertLevel, AlertReponse } from '../../core/models/alert.model';

@Injectable()
export class CanDeactivateWorkCenterDetail implements CanDeactivate<WorkCenterDetailComponent1> {
 constructor(private workCenterService: WorkCenterService,
  private alert:AlertService) {}
/*   
  canDeactivate(component: WorkCenterDetailComponent1, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    throw new Error('Method not implemented.');
  
  
  
  } */

  canDeactivate(
    component: WorkCenterDetailComponent1, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot
   ):Observable<boolean> | boolean {
     console.log('CanDeactivateWorkCenterDetail.canDeactivate');
     return component.canDeactivate? component.canDeactivate() : true
   } 



 canDeactivate3(
  component: WorkCenterDetailComponent1, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot
 ):Observable<boolean> | boolean {
   console.log('CanDeactivateWorkCenterDetail.canDeactivate');
   if (component && component.canSubmitWorkCenterDetails) {
        this.alert.showYesNo('You have not submitted your changes. Do you really want to leave this page?',
        AlertLevel.WARNING,
        (ar: AlertReponse) => {
          console.log(ar);
          if (ar === AlertReponse.YES){
            console.log('CanDeactivateWorkCenterDetail...return true')
            return observableOf(true);
          }        
          else{
            console.log('CanDeactivateWorkCenterDetail...return false');
            return observableOf(false);
          }
        });   
   }

   return true
 } 
}


/*

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private auth: AngularFireAuth, private router: Router) {}

    canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean>|boolean {
        return this.auth.map((auth) => {
            if (auth) {
                console.log('authenticated');
                return true;
            }
            console.log('not authenticated');
            this.router.navigateByUrl('/login');
            return false;
        }).first(); // this might not be necessary - ensure `first` is imported if you use it
    }
}
*/