import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nde-tests',
  template: require('./nde-tests.component.html'),
  // styles: [require('./nde-tests.component.css')]
  styles: [require('./nde-tests.component.css').toString()]
})
export class NdeTestsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  closeOverlay() {
    document.getElementById('NdeOverlay').style.display = 'none';
  }
  overlayOpen() {
    document.getElementById('NdeOverlay').style.display = 'block';
    const modal = document.getElementById('NdeOverlay');
    // When the user clicks anywhere outside of the modal, this closes it
    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = 'none';
        }
    };
  }

}
