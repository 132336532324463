import { Component, OnInit, OnDestroy, AfterContentChecked } from '@angular/core';

@Component({
  selector: 'tools-container',
  template: require('./tools-container.component.html'),
  // styles: [require('./tools-container.component.css')]
  styles: [require('./tools-container.component.css').toString()]
})
export class ToolsContainerComponent implements OnInit, OnDestroy, AfterContentChecked {

  constructor() { }

  ngOnInit() {
    console.log('tools-container.component.ngOnInit');
  }

  ngOnDestroy() {
    console.log('tools-container.component.ngOnDestroy');
  }

  ngAfterContentChecked() {
    console.log('tools-container.component.ngAfterContentCheceked');
  }

  tabSwitch(evt, tabName) {
    let i, x, tablinks;
    x = document.getElementsByClassName('tab');
    for (i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
    }
    tablinks = document.getElementsByClassName('tabLink');
    for (i = 0; i < x.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' tabColor', '');
    }
    document.getElementById(tabName).style.display = 'block';
    evt.currentTarget.className += ' tabColor';
  }


}
