import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ToolsContainerComponent } from '../tools/tools-container.component';
import { ToolKitDetailComponent} from '../tools/tool-kit/tool-kit-detail/tool-kit-detail.component';
import { CanDeactivateToolKitDetail } from '../tools/tool-kit/tool-kit-detail/tool-kit-detail.candeactivate';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([
            { path: 'tools', component: ToolsContainerComponent, data: {breadcrumb: 'Tools Maintenance'}},
            { path: 'toolKits/new', component: ToolKitDetailComponent, data: {breadcrumb: 'Tool Maintenance'}},
            { path: 'toolKits/:id', component: ToolKitDetailComponent, canDeactivate: [CanDeactivateToolKitDetail], data: {breadcrumb: 'Tool Maintenance'}},
        ])
    ],
    declarations: []
})
export class ToolsModule { }