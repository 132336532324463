import { AlertService} from './alert.service';
import { Injectable, OnInit } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import "rxjs/add/observable/of";
import { Helper } from './helper.service';
import { AppHttpClientService } from './app-http-client.service';
import { Config } from '../shared/gatekeeper/config';
import { GateKeeperService } from '../shared/gatekeeper/user/user.service';
import {MethodType, MethodTypes} from '../enums/methodtype';


@Injectable()
export class AuthService implements OnInit {
  // currentUser: IUser =  {
  //     tokenId: Guid.newGuid(),
  //     guardianUserId: Guid.newGuid(),
  //     userName: 'bsmith',
  //     firstName: 'Bob',
  //     lastName: 'Smith',
  //     permissions: [
  //       {name: 'CanSetBOMAutoAlloc'},
  //       {name: 'CanAccessMaintenance'}]
  //   };

    // permissions: string[] = ['CanSetBOMAutoAlloc'];

    systemLock = null;



  constructor(private http: Http, private utils: Helper, private alert: AlertService, private gkauth: GateKeeperService,
  private ahttp: AppHttpClientService) {}

    ngOnInit() {
      console.log('auth.service.ngOnInit');
    }

//     setCurrentUser(data: any ) {
//       this.currentUser = <IUser> data;

//       console.log('auth.service.setCurrentUser');
//       console.log(this.currentUser);
//       this.permissions = [];
//       if (this.currentUser != null) {
//         this.permissions = this.getPermissions(this.currentUser);
//         this.ahttp.tokenId = this.currentUser.tokenId;
//       }
//       console.log(this.permissions);
//     }

//     hasPermission(perm: string): boolean {
//       // console.log('auth.service.hasPermission perm:' + perm )
//       // console.log(this.currentUser);
//       // console.log(this.permissions);
//       let ret = false;

//       if (this.currentUser != null && !this.utils.isEmptyArray(this.currentUser.permissions)) {
//        const i = this.permissions.indexOf(perm);
//        ret = i > -1;
//       }
//       // console.log(ret);
//       return ret;
//     }

//   get httpOptions(){

//       const tid = this.currentUser !== null ? this.currentUser.tokenId : '';
//       const did = this.deviceId !== null ? this.deviceId : '';

//     const headers = new Headers({'Content-Type' : 'application/json', 'NoMuda-Token': tid,
//     'NoMuda-Device': did});
//     const options = new RequestOptions({headers : headers});

//     return options;
//   }


// getPermissions(u: IUser): string[] {
//   const r: string[] = [];
//   if (u != null && !this.utils.isEmptyArray(u.permissions)) {
//     for (const s of u.permissions){
//       r.push(s.name);
//     }
//   }

//   return r;
// }

// get deviceId(): string {
//   return this.ahttp.deviceId;
// }



// get tokenId(){
//   if (this.currentUser != null) {
//     return this.currentUser.tokenId;
//   } else {
//     return null;
//   }
// }

// get userId(){
//   if (this.currentUser != null) {
//     return this.currentUser.guardianUserId;
//   } else {
//     return null;
//   }
// }

// loginScan(userScan: string): Observable<IUser> {
//   const loginInfo = {
//     userScan: userScan,
//     deviceId: this.deviceId
//   };

//   this.ahttp.alertEnabled = true;
//     return this.ahttp.post('auth/LoginScan', loginInfo).map( (resp: Response) => {
//         if (resp != null) {
//           this.setCurrentUser(resp.json());
//           if (this.hasPermission('CanAccessMaintenance')) {

//             return this.currentUser;
//           } else {
//             this.setCurrentUser(null);
//             throw new ErrorResponse('Unauthorized: Insufficient permssion');
//           }
//         }
//       });
//   }
//   logout(): Observable<number> {
//       console.log('AuthServiceHttp.logout');
//       const info = {
//         tokenId: this.currentUser.tokenId
//       };
//       return this.ahttp.post('auth/LogoutUser', info).map(
//       (resp: Response) => {
//         console.log('auth.service.logout returns');
//         this.currentUser = null;
//         return <number>resp.json();
//       });
//   }

  lockItem(itemId: string): Observable<number> {
    const info = {
      tokenId: Config.gkParameters.TokenID,
      itemId: itemId
    };

    this.systemLock = null;


  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'auth/CreateSystemLock';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
    //return this.ahttp.post('auth/CreateSystemLock', info).map((resp: Response ) => {
        //console.log('auth.service.CreateSystemLock returns');
        //console.log(response);
        this.systemLock = response; //response.json();
          return 1;
      });
  }

  unlockItem(itemId: string): Observable<number> {
    //console.log('auth.service.unlockItem');
    if (this.systemLock != null) {

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'auth/DeleteSystemLock/' + this.systemLock.id;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.DELETE);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        this.systemLock = null;
        return 1;
      });
    } else  {
      return Observable.of(1);
    }
  }
}

