import { Injectable, OnDestroy } from '@angular/core';
import {IRouter, IRouterVersionInfo, RouterType } from '../models/router.model';
import { IRouterStep, PrintType, PrintOption, StepOrder, IdOrder } from '../models/routerstep.model';
import {RouterState} from '../enums/router-state';
import { IBOMItem} from '../models/bom.model';
import { ITest, TestType, TestDeviceType } from '../models/test.model';
import { IFile, IRouterFile, MapType } from '../models/file.model';
import { IEtchingInfo, IPart, IPartInfo, TraceType } from '../models/part.model';
import {IProgram, IRouterStepProgram} from '../models/program.model';
import {ITool, IToolBag} from '../models/tool.model';
import {IWorkCenter, IWorkCenterExtended, WorkCenterType, ScheduleType} from '../models/workcenter.model';
import {IWorkStation, WorkStationType } from '../models/workstation.model';
import {IRouterStepExtended} from '../models/mock-data.model';
import {IFixture, IRouterStepFixture} from '../models/fixture.model';
import { UnitType } from '../enums/unittype';
import { PartType } from '../models/part.model';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { IToolBagTool, ToolUtils, IFromToIds } from '../models/tool.model';
import { Guid } from '../models/guid.model';
import { info } from 'console';



@Injectable()
export class Helper {
  public static readonly MINTESTVALUE_MINVALUE = 0;

  // Must send through https if out on azure
  // New SSL web api 
  public static WEBAPIHOST = 'https://nomudamaintapi.diwmsiapps.com/';
  //public static WEBAPIHOST = 'https://nomudamaintapi-dev.diwmsiapps.com/';

  static showSpinner = false;

  constructor() {}

  // infoTab load to be shown first
  private routerInfoTab = new BehaviorSubject<boolean>(true);
  currentInfoTabStatus = this.routerInfoTab.asObservable();

  static replaceSpecialCharacterInURL(s: string)  {
    let i: any;
    const word: any = s;
    let newWord = '';

    for (i in word) {
        if (word[i] === '"' ) {
            console.log(word[i]);
            newWord = newWord + '\\' + '"';
        } else if (word[i] === '\\') {
            console.log(word[i]);
            newWord = newWord + '\\';
        } else {
            newWord = newWord + word[i];
            console.log(word[i]);
        }
    }

    return newWord;
  }

  static replaceDoubleQuote(s: string)  {
    let i: any;
    const word: any = s;
    let newWord = '';

    for (i in word) {
        if (word[i] === '"' ) {
            console.log(word[i]);
            newWord = newWord + '\\' + '"';
        } else if (word[i] === '/') {
            console.log(word[i]);
            newWord = newWord + '\\' + '/';
        } else {
            newWord = newWord + word[i];
            console.log(word[i]);
        }
    }

    console.log(newWord);
    return newWord;
  }

  static sArrayOrEmpty(ar) {
    if (ar == null || ar.length == 0) {
      return [];
    } else {
      return ar;
    }
  }

  static areEqualStr(a: string, b: string) {
    return this.cmpStringAlphaNumeric(a, b) == 0;
  }


  static isUndefinedObj(obj: any)
  {
    return obj === undefined || obj == undefined || obj === null || obj == null || obj.toString() == 'undefined';
  }
  static isUndefined(s: string) {
    return !s || s === null || s== null || s===undefined;
  }

  static cmpNumber( a: number, b: number) {
    if (!a) {
      if (!b) {
        return 0;
      } else {
        return -1;
      }
    }

    if (!b) {
      return 1;
    }

    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }

  static cmpString(s1: string, s2: string) {
    const a = s1? s1.toString().trim().toLowerCase(): s1;
    const b = s2? s2.toString().trim().toLowerCase(): s2;

    if (!a) {
      if (!b) {
        return 0;
      } else {
        return -1;
      }
    }

    if (!b) {
      return 1;
    }

    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }

  static cmpStringDesc(s1: string, s2: string) {
    const a = s1.toString().trim().toLowerCase();
    const b = s2.toString().trim().toLowerCase();

    if (!a) {
      if (!b) {
        return 0;
      } else {
        return -1;
      }
    }

    if (!b) {
      return 1;
    }

    if (a > b) {
      return -1;
    } else if (a < b) {
      return 1;
    } else {
      return 0;
    }
  }

  static cmpDateTime( a: Date, b: Date) {
    if (!a) {
      if (!b) {
        return 0;
      } else {
        return -1;
      }
    }
 
    if (!b) {
      return 1;
    }
 
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }   

  static cmpDateDesc(a: Date, b: Date) {
    if (!a) {
      if (!b) {
        return 0;
      } else {
        return -1;
      }
    }

    if (!b) {
      return 1;
    }

    if (a > b) {
      return -1;
    } else if (a < b) {
      return 1;
    } else {
      return 0;
    }
  }

  static cmpDate( a: Date, b: Date) {
    if (!a) {
      if (!b) {
        return 0;
      } else {
        return -1;
      }
    }
 
    if (!b) {
      return 1;
    }
 
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  }   


  
  static cmpDateTimeDesc(a: Date, b: Date) {
    if (!a) {
      if (!b) {
        return 0;
      } else {
        return -1;
      }
    }

    if (!b) {
      return 1;
    }

    if (a > b) {
      return -1;
    } else if (a < b) {
      return 1;
    } else {
      return 0;
    }
  }

  static cmpStringAlphaNumeric(s1: string, s2: string, nullsLast:boolean = false) {
    // console.log('helper.service.cmpStringAlphaNumeric');
    // console.log(s1);
    // console.log(s2);
    if (!s1) {
      if (!s2) {
        return 0;
      } else {
        if (!nullsLast)
          return -1;
        else
          return 1;
      }
    }

    if (!s2) {
      if (!nullsLast)
        return 1;
      else
        return -1;
    }

    const a = s1.toString().trim().toLowerCase();
    const b = s2.toString().trim().toLowerCase();

    if (!isNaN(+a) && !isNaN(+b)) {
      const c = +a;
      const d = +b;
      if (c < d) 
      {
        return -1;
      } 
      else if (c > d) 
      {
        return 1;
      } 
      else 
      {
        return 0;
      }
    } 

    if (isNaN(+a) && isNaN(+b)) 
    {
      if (a < b) 
      {
        return -1;
      } 
      else if (a > b) 
      {
        return 1;
      } 
      else 
      {
        return 0;
      }
    } 
    else
    {
      if (isNaN(+a)) 
      {
        return 1;
      } 
      else 
      {
        return -1;
      }
    }
  }

  static cmpFileRevision(a:string, b:string) {
    console.log('HelperService.cmpFileRevision:' + a + " " + b);
    if (!a) {
      if (!b) {
        return 0;
      } else {
        return -1;
      }
    }

    if (!b) {
      return 1;
    }

    const s1 = a.trim().toLowerCase().charCodeAt(0);
    const s2 = b.trim().toLowerCase().charCodeAt(0);
    
    if (s1 > s2) {
      return 1;
    } else if (s1 < s2) {
      return -1;
    } else {
      return 0;
    }
  } 


  // static cmpStringAlphaNumeric(a: string, b: string) {
  //   if (typeof a === "number" && typeof b === "number")
  //           return a - b;

  //       if (a === null || b === null)
  //           return NaN;
  //       if (a === undefined || b === undefined)
  //           return NaN;
  //       if (Array.isArray(a) || Array.isArray(b))
  //           return NaN;        

  //       if (a.length === 0 || b.length === 0)
  //           return a.length - b.length;

  //       var s1: string = a,
  //           s2: string = b,
  //           indexA = 0, indexB = 0,
  //           numberA, numberB,
  //           charA, charB,
  //           difference;

  //       do {
  //           numberA = 0;
  //           while (indexA < s1.length) {
  //               charA = s1[indexA];
  //               indexA++;
  //               if (!(charA >= "0" && charA <= "9")) break;

  //               numberA = numberA * 10 + parseInt(charA);
  //           }

  //           numberB = 0;
  //           while (indexB < s2.length) {
  //               charB = s2[indexB];
  //               indexB++;
  //               if (!(charB >= "0" && charB <= "9")) break;

  //               numberB = numberB * 10 + parseInt(charB);
  //           }

  //           if (charA === " " && charB === " ") {
  //               while (indexA < s1.length && s1[indexA] === " ") {
  //                   indexA++;
  //               }
  //               while (indexB < s2.length && s2[indexB] === " ") {
  //                   indexB++;
  //               }
  //           }

  //           difference = numberA - numberB;
  //           if (difference !== 0) return difference;
  //           if (charA !== charB) {
  //               if (charA === ".") return -1;
  //               if (charB === ".") return 1;
  //               return charA.toUpperCase().charCodeAt(0) - charB.toUpperCase().charCodeAt(0);
  //           }
  //       } while (indexA < s1.length && indexB < s2.length);

  //       return (indexB - s2.length) - (indexA - s1.length);
  //   }
   

  static cmpStringAlphaNumericDesc(s1: string, s2: string, nullsLast:boolean = false) {
    // console.log('helper.service.cmpStringAlphaNumeric');
    // console.log(s1);
    // console.log(s2);
    if (!s1) {
      if (!s2) {
        return 0;
      } else {
        if (!nullsLast)
          return -1;
        else
          return 1;
      }
    }
     
    if (!s2) {
      if (!nullsLast)
        return 1;
      else
        return -1;
    }
 
    const a = s1.toString().trim().toLowerCase();
    const b = s2.toString().trim().toLowerCase();
 
    if (!isNaN(+a) && !isNaN(+b)) {
      const c = +a;
      const d = +b;
      if (c > d) {
        return -1;
      } else if (c < d) {
        return 1;
      } else {
        return 0;
      }
    } else if (isNaN(+a) && isNaN(+b)) {
      if (a > b) {
        return -1;
      } else if (a < b) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (isNaN(+a)) {
        return -1;
      } else {
        return 1;
      }
    }
  }

  

  static sIsEmptyArray(a): boolean {
    return (a === undefined || a === null || a == undefined || a == null || a.length == 0);
  }

  static sort_description(a: any, b: any) {
    return Helper.cmpStringAlphaNumeric(a.description, b.description);
  }

  static sortITestOrderTypeDescription(a: any, b: any) {
    let ret = Helper.cmpStringAlphaNumeric(a.testOrder.toString(), b.testOrder.toString());
    if (ret == 0) {
      ret = Helper.cmpStringAlphaNumeric(a.type, b.type);
      if (ret == 0) {
        ret = Helper.cmpStringAlphaNumeric(a.description, b.description);
      }
    }
    return ret;
  }

  static sortITestTypeDescription(a: any, b: any) {
    let ret = Helper.cmpStringAlphaNumeric(a.type, b.type);
    if (ret == 0) {
      ret = Helper.cmpStringAlphaNumeric(a.description, b.description);
    }

    return ret;
  }

  static makeValidateUniqueNumber(formGroup: FormGroup, isUnique: Function) {
    return function(fc: FormControl) {
      // console.log('spec-tool.component.validateUnique');
      const ret = formGroup && isUnique ? null : {validateUnique: {valid: false}};
      // console.log('spec-tool.component ret ' + ret);
      return ret;
    };
  }

  static sortIFileNameRevision(f1: IFile, f2: IFile) {
    const ret = Helper.cmpStringAlphaNumeric(f1.name, f2.name);
    if (ret == 0) {
      return Helper.cmpStringAlphaNumeric(f1.revision, f2.revision);
    } else {
      return ret;
    }
  }

  static sortIFileSpecificationNameRevision(f1: IFile, f2: IFile) {
    const ret = Helper.cmpStringAlphaNumeric(f1.specificationName, f2.specificationName);
    if (ret == 0) {
      return Helper.cmpStringAlphaNumeric(f1.revision, f2.revision);
    } else {
      return ret;
    }
  }


  static sortNumberDescription(a: ITool, b: ITool) {
    let ret = Helper.cmpStringAlphaNumeric(a.number, b.number);
    if (ret === 0) {
      ret = Helper.cmpStringAlphaNumeric(a.description, b.description);
    }
    return ret;
  }

  static sortOrderNumber(a: ITool, b: ITool) {
    let ret = Helper.cmpNumber( a.order, b.order);
    if (ret === 0) {
      ret = Helper.cmpStringAlphaNumeric(a.number, b.number);
    }
    return ret;
  }

  ////// instance methods

  addToolsToToolBags(toolBags: IToolBag[], toolKitTools: IToolBagTool[]): IToolBag[] {
    console.log('helper.addToolsToToolBags');
    const ret: IToolBag[] = [];
    let toolBag: IToolBag = null;

    if (this.isEmptyArray(toolKitTools) || this.isEmptyArray(toolBags)) {
      return ret;
    }

    for (const bagTool of toolKitTools) {
      const tool: ITool = this.getITool(bagTool);
      if (toolBag == null || bagTool.toolBagID !== toolBag.id) {
        toolBag = ret.find(x => x.id == bagTool.toolBagID);
        if (toolBag == null) {
          toolBag = toolBags.find(x => x.id == bagTool.toolBagID);
          ret.push(toolBag);
        }
        toolBag.tools = toolBag.tools || [];
      }
      toolBag.tools.push(tool);
    }

    this.prepToolBags(ret);
    return ret;
  }

  makeToolBags(toolKitTools: IToolBagTool[]): IToolBag[] {
    console.log('tool-kit-detail.component.makeToolBags');
    const ret: IToolBag[] = [];
    let toolBag: IToolBag = null;

    if (this.isEmptyArray(toolKitTools)) {
      return ret;
    }

    for (const bagTool of toolKitTools) {
      const tool: ITool = this.getITool(bagTool);
      if (toolBag != null && bagTool.toolBagID == toolBag.id) {
        toolBag.tools.push(tool);
      } else {
        toolBag = {
                    id: bagTool.toolBagID,
                    description: bagTool.toolBagDescription,
                    tools: [tool],
                    notes: bagTool.notes
                  };
        ret.push(toolBag);
      }

    }

    this.prepToolBags(ret);
    return ret;
  }

  prepToolBags(bags: IToolBag[]) {
    console.log('Helper.prepToolBags');
    if (!this.isEmptyArray(bags)) {
      for (const bag of bags) {
        //console.log(bag);
        bag.tools = this.prepToolBagTools(bag.tools);
        //console.log('...after prep bag')
        //console.log(bag);
      }
    }

    //console.log(bags);
  }

  prepToolBagTools(tools: ITool[]): ITool[] {
    //console.log('Helper.prepToolBagTools');
    const ret: ITool[] = [];
    if (!this.isEmptyArray(tools)) {
      tools.sort(Helper.sortOrderNumber);
      let prevOrder = 0;
      for (const tool of tools) {
        while (tool.order > prevOrder) {
          const tmp = ToolUtils.copyTool(ToolUtils.TOOL_NONE);
          tmp.order = prevOrder;
          ret.push(tmp);
          prevOrder++;
        }
        ret.push(tool);
        prevOrder++;
      }
    }
    // add null insert if the toolbag has only the holder
    if (ret && ret.length === 1) {
      const t = ToolUtils.copyTool(ToolUtils.TOOL_NONE);
      t.order = 1;
      ret.push(t);
    }

    return ret;
  }

  getITool(bt: IToolBagTool) {
    // console.log('helper.getITool');
    const ret: ITool =  {
                          id: bt.id,
                          number: bt.number,
                          order: bt.order,
                          description: bt.description,
                          inUse: bt.inUse,
                          userDefined: bt.userDefined
                        };
    //console.log(ret);
    return ret;
  }

  getToolName(t: ITool): string {
    // console.log('helper.service.getToolName');
    // console.log(t);
    let tmp = '';
    if (t.number != ToolUtils.TOOL_NONE_STR) {
      tmp = tmp.concat(t.number, ' : ', t.description);
    } else {
      tmp = t.number;
    }

    if (t.order == 0) {
      tmp = 'HOLDER>' + tmp;
    } else if (t.order == 1) {
      tmp = 'INSERT>' + tmp;
    }
    return tmp;
  }

  //////
  getBaseDescription(descr: string): string {
    if (this.isEmptyString(descr)) {
      console.log('getBaseDescription d: null' );
      return null;
    }
    const tmp: string = descr.trim();
    const index: number = tmp.indexOf('-copy');
    const ret = tmp.substring(0, index > 0 ? index : tmp.length );
    console.log('getBaseDescription d:' + descr + ' b:' + ret);
    return ret;
  }

  getBaseDescriptionOrig(descr: string): string {
    if (this.isEmptyString(descr)) {
      console.log('getBaseDescriptionOrig d: null' );
      return null;
    }
    const index: number = descr.toLowerCase().indexOf('-copy');
    const ret = descr.substring(0, index > 0 ? index : descr.length );
    console.log('getBaseDescriptionOrig d:' + descr + ' b:' + ret);
    return ret;
  }

  copyAndSetIds(sources: any[], idMap: IFromToIds[]) {
    if (!this.isEmptyArray(sources)) {
      const ret = [];
      for (const src of sources) {
        const tmp = idMap.find(x => x.fromId == src.id);
        if (tmp) {
          const c = {...src};
          c.id = tmp.toId;
          ret.push(c);
        }
      }
        return ret;
    }
  }

  handleDuplicateTests(fromTests: any[], toTests: any[]): any[] {
    console.log('helper.service handleDuplicateTests');
    //console.log(fromTests);
    //console.log(toTests);
    const ret: any[] = [];

    if (this.isEmptyArray(fromTests) || this.isEmptyArray(toTests)) {
      return fromTests;
    }

    for (const fromTest of fromTests) {
      const baseDesc: string = this.getBaseDescription(fromTest.description);
      //console.log(baseDesc);
      const matches: any[] = ret.filter(x => this.getBaseDescription(x.description) == baseDesc) || [];
      const matches1: any[] = toTests.filter(x => this.getBaseDescription(x.description) == baseDesc) || [];
      const existingCount = matches.length + matches1.length;
      //console.log('...existingCount:' + existingCount);
      const copy = {...fromTest}; // shallow copy
      if (existingCount !== 0) {
        copy.description = baseDesc + '-COPY(' + existingCount + ')';
      }
      //console.log('push copy');
      //console.log(copy);
      ret.push(copy);
    }
    console.log('helper.service handleDuplicateTests DONE');
    //console.log(ret);
    return ret;
  }

    handleDuplicateToolBags(fromToolBags: any[], toToolBags: any[], idMap: IFromToIds[]): any[] {
      console.log('helper.service handleDuplicateToolBags');
      //console.log(fromToolBags);
      //console.log(toToolBags);
      const ret: any[] = [];

      if (this.isEmptyArray(fromToolBags)) {
        return fromToolBags;
      }

      if (this.isEmptyArray(toToolBags)) {
        toToolBags = [];
      }

      for (const fromToolBag of fromToolBags) {
        const baseDesc: string = this.getBaseDescription(fromToolBag.description);
        //console.log(baseDesc);
        const matches: any[] = ret.filter(x => this.getBaseDescription(x.description) == baseDesc) || [];
        const matches1: any[] = toToolBags.filter(x => this.getBaseDescription(x.description) == baseDesc) || [];
        const existingCount = matches.length + matches1.length;
        //console.log('...existingCount:' + existingCount);
        const copy = {...fromToolBag}; // shallow copy
        const map = idMap.find(x => x.fromId == fromToolBag.id);
        //console.log(map);
        if (map) {
          copy.id = map.toId;
        }
        if (existingCount !== 0) {
         copy.description = baseDesc + '-COPY(' + existingCount + ')';
        }
        //console.log('push copy');
        //console.log(copy);
        ret.push(copy);
      }
      console.log('helper.service handleDuplicateToolBags DONE');
      //console.log(ret);
      return ret;
    }


    filterIFiles(allFiles: IFile[], removeFiles: IFile[]): IFile[] {
      console.log('helper.filterFiles');

      let ret: IFile[] = [];
      if (!this.isEmptyArray(allFiles) && !this.isEmptyArray(removeFiles)) {
       //console.log('...removeFiles length ' + (!this.isEmptyArray(removeFiles.length) ? removeFiles.length : 'NULL'));
       //console.log('...all files length ' + (!this.isEmptyArray(allFiles) ? allFiles.length.toString() : 'NULL'));
         for (const p of allFiles){
           const stepP = removeFiles.find(x => x.id == p.id);
          // console.log('...search for ' + p.name + '...stepP:' + (!stepP ? 'NULL' : stepP.id) );
           if (!stepP) {
             ret.push(p);
           }
         }
       } else {
         ret = allFiles;
       }

       ret = ret || [];
       //console.log('..post filter files length:' + ret.length);
       return ret.sort(Helper.sortIFileNameRevision);
    }

    prepSelectableObject(objs: any[]) {
      if (objs) {
        for (const obj of objs) {
          obj.selected = false;
        }
      }
    }

    isUndefined(obj: any) {
      return obj == null || obj == undefined || obj === null || obj === undefined || obj.toString() == 'undefined';
    }

    arrayOrEmpty(ar) {
      if (this.isUndefined(ar)) {
        return [];
      } else {
        return ar;
      }
    }

    isEmptyArray(a): boolean {
          return this.isUndefined(a) || a.length == 0;
      }

    isEmptyString(s: string): boolean {
        return (s == undefined || s == null || s == '' || s == "" || s.length == 0);
    }

    getObjectPropValue(object: Object, field: string, def: string): string {
        return (object == null ? def : object[field]);
    }

    getArrayLengthStr(a): string {
      if ( this.isEmptyArray(a) ) {
        return 'NONE';
      } else {
        return a.length.toString();
      }
    }

  getRemHours(secs: number): number {
    return Math.floor(secs / (60 * 60));
  }

  getRemMinutes(secs: number): number {
    return Math.floor(secs / 60) % 60;
  }

  getRemSeconds(secs: number): number {
    return secs % 60;
  }

  calSecs(hours: number, minutes: number, seconds: number) {
    return hours * 3600 + minutes * 60 + seconds;
  }

  getStrValue(s: string): string {
    return (!this.isEmptyString(s) ? s : 'Empty');
  }

  dumpRouterStep(s: IRouterStep, prefix: string= ''): void {
    if (s)  {
      console.log(prefix + 'id:' + (s.id == null ? 'NULL' : s.id));
      console.log(prefix + 'routerId:' + s.routerId);
      console.log(prefix + 'step:' + s.step);
      console.log(prefix + 'description:' + s.description);
    } else {
      console.log(prefix + 'NULLL router step');
    }
  }

  getTestPart(): IPart {
      return  {
                id: 'A194D720-FA56-4287-9CEA-A380134C5B73',
                number:  'VC0276',
                unit: UnitType.EACH,
                trace: TraceType.NONE,
                type: PartType.DISCRETE,
                description: 'test part',
                name: 'part name',
                fGLot: 1
              };
  }

  getTestRouter(part: IPart, rev: number, state: RouterState): IRouter {
      if (part == null ) {
        part = this.getTestPart();
      }

      return {
        id: Guid.newGuid(),
        partId: part.id,
        partNumber: part.number,
        partDescription: part.description + ' router description' ,
        revision: rev,
        state: state,
        type: RouterType.CELL,
        lastUpdate: new Date(),
        updatedBy: 'R. Eiffert',
        userId: Guid.newGuid(),
        activeWIPTotal: 0,
        targetWIP: 0,
        psl: 1
      };

  }

  getRemoteRouter(r: IRouter): any {
      return {
        id: r.id,
        partId: r.partId,
        partNumber: r.partNumber,
        partDescription: r.partDescription ,
        revision: r.revision,
        state: r.state.id,
        type: r.type,
        lastUpdate: r.lastUpdate,
        updatedBy: r.updatedBy,
        activeWIPTotal: r.activeWIPTotal,
        targetWIP: r.targetWIP,
        psl: r.psl
      };
  }

  getRouterVersionInfoPartIds(infos: IRouterVersionInfo[]):string[] {
    const ret: string[] = [];
    if (!this.isEmptyArray(this.infoTabStatus)) {
      for (const r of infos){
        ret.push(r.partId);
      }
    }
    return ret;
  }

  getRouterPartIds(routers: IRouter[]): string[] {
    const ret: string[] = [];
    if (!this.isEmptyArray(routers)) {
      for (const r of routers){
        ret.push(r.partId);
      }
    }
    return ret;
  }

  dumpRouter(r: IRouter, prefix: string= ''): void {
    if (r != null) {
      console.log(prefix + 'id:' + (r.id == null ? 'NULL' : r.id));
      console.log(prefix + 'partNum:' + r.partNumber);
      console.log(prefix + 'partDesc:' + r.partDescription);
      console.log(prefix + 'rev:' + r.revision);
      console.log(prefix + 'state:' + r.state);
      console.log(prefix + 'type:' + r.type);
      console.log(prefix + 'lastUpdate:' + r.lastUpdate);
      console.log(prefix + 'updatedBy:' + r.updatedBy);
      console.log(prefix + 'activeWIPTotal:' + r.activeWIPTotal);
      console.log(prefix + 'targetWIP:' + r.targetWIP);
      console.log(prefix + 'psl:' + r.psl);
    }
  }

  dumpTest(t: ITest, prefix: string= ''): void {
    if (t != null) {
      console.log(prefix + 'id:' + (t.id == null ? 'NULL' : t.id));
      console.log(prefix + 'type:' + t.type);
      console.log(prefix + 'description:' + t.description);
      console.log(prefix + 'router step:');
      // this.dumpRouterStep(t.ste, prefix + '..');
    }
  }

  getRouterStep(routerId: string, stepNum: number): IRouterStep {
      return  {
                id: null,
                routerId: routerId,
                step: stepNum,
                description: 'step ' + stepNum,
                printOption: PrintOption.AUTO,
                printType: PrintType.S1,
                setupTime: 100,
                avgTime: 100,
                familyId: 'familyID',
                runTime: 100,
                idleTime: 100,
                doFgLot: false,
                deferTesting: false,
                workCenterId: '',
                workCenterName: '',
                bestTime: 400
              };
  }

  copyBOMItem(info: IBOMItem): IBOMItem {

      return {
        id: info.id,
        routerStepId: info.routerStepId,
        step: info.step,
        stepDescription: info.stepDescription,
        partDescription: info.partDescription,
        partId: info.partId,
        partNumber: info.partNumber,
        partType: info.partType,
        qty: info.qty,
        sizeEach: info.sizeEach,
        partUnitType: info.partUnitType,
        partTraceType: info.partTraceType,
        autoAlloc: info.autoAlloc
      };
  }

  copyIWorkCenter(info: IWorkCenter): IWorkCenter {
      return {
        id: info.id,
        name: info.name,
        type: info.type,
        code: info.code,
        description: info.description,
        isOutsource: info.isOutsource,
        scheduleType: info.scheduleType,
        warehouse: info.warehouse,
        inUse: info.inUse
      };
  }

  copyIWorkStation(info: IWorkStation): IWorkStation {
    return{
      id : info.id,
      number : info.number,
      description : info.description,
      workCenterId : info.workCenterId,
      type: info.type,
      inUse: info.inUse
    };
  }

  copyIWorkStations(stations: IWorkStation[]): IWorkStation[] {
    const ret: IWorkStation[] = [];

    if (stations) {
      for (const ws of stations) {
        ret.push(this.copyIWorkStation(ws));
      }
    }
    return ret;
  }

  makeIWorkCenterExtended(info: IWorkCenter): IWorkCenterExtended {
      return {
        workCenter: {
          id: info.id,
          name: info.name,
          type: info.type,
          code: info.code,
          description: info.description,
          isOutsource: info.isOutsource,
          scheduleType: info.scheduleType,
          warehouse: info.warehouse,
          inUse: false
        },
        workStations: []
      };
  }

  extractIWorkCenter(info: IWorkCenterExtended): IWorkCenter {
      if (info) {
        return this.copyIWorkCenter(info.workCenter);
      } else {
        return null;
      }
  }

  makeEmptyIRouterStep(): IRouterStep {
    return  {
              id: null,
              routerId: null,
              step: 0,
              description: null,
              printOption: PrintOption.UNKNOWN,
              setupTime: 0,
              avgTime: 0,
              familyId: null,
              runTime: 0,
              idleTime: 0,
              doFgLot: false,
              deferTesting: false,
              workCenterId: null,
              workCenterName: null,
              printType: PrintType.UNKNOWN,
              bestTime: 0
            };
  }

  copyIRouterStep(info: IRouterStep): IRouterStep {
    return  {
              id: info.id,
              routerId: info.routerId,
              step: info.step,
              description: info.description,
              printOption: info.printOption,
              setupTime: info.setupTime,
              avgTime: info.avgTime,
              familyId: info.familyId,
              runTime: info.runTime,
              idleTime: info.idleTime,
              doFgLot: info.doFgLot,
              deferTesting: info.deferTesting,
              workCenterId: info.workCenterId,
              workCenterName: info.workCenterName,
              printType: info.printType,
              bestTime: info.bestTime
            };
  }

  extractIRouterStep(info: IRouterStepExtended): IRouterStep {
    if (info == null) {
      return null;
    }

    return  {
              id: info.id,
              routerId: info.routerId,
              step: info.step,
              description: info.description,
              printOption: info.printOption,
              setupTime: info.setupTime,
              avgTime: info.avgTime,
              familyId: info.familyId,
              runTime: info.runTime,
              idleTime: info.idleTime,
              doFgLot: info.doFgLot,
              deferTesting: info.deferTesting,
              workCenterId: info.workCenterId,
              workCenterName: info.workCenterName,
              printType: info.printType,
              bestTime: info.bestTime
            };
  }

  copyProgram(info: IProgram): IProgram {
    return {
      id: info.id,
      number: info.number,
      description: info.description,
      inUse: info.inUse
    };
  }

  extractIProgram(info: IRouterStepProgram): IProgram {
    if (info) {
      return this.copyProgram(info.program);
    } else {
      return null;
    }
  }

  makeIRouterStepProgram(info: IProgram): IRouterStepProgram {
    return {
      program: this.copyProgram(info),
      stepId: null
    };
  }

  copyFixture(info: IFixture): IFixture {
    return {
      id: info.id,
      number: info.number,
      description: info.description,
      inUse : info.inUse
    };
  }

  extractIFixture(info: IRouterStepFixture): IFixture {
    return this.copyFixture(info.fixture);
  }

  makeIRouterStepFixture(info: IFixture): IRouterStepFixture {
    return {
      fixture: this.copyFixture(info),
      stepId: null
    };
  }

  copyTools(tools: ITool[]): ITool[] {
    const ret: ITool[] = [];
    for (const t of tools){
      ret.push(this.copyTool(t));
    }
    return ret;
  }

  copyTool(info: ITool): ITool {
    return {
      id: info.id,
      number: info.number,
      description: info.description,
      order: info.order,
      inUse: info.inUse,
      userDefined: info.userDefined
    };
  }

  getTool(tools: ITool[], i: number): ITool {
    if (tools.length > i) {
      return tools[i];
    } else {
      return null;
    }
  }

  makeIToolBag(tools: ITool[]): IToolBag {
    return {
      id : null,
      description: null,
      tools: tools,
      notes: null
    };
  }

  copyIToolBag(tool: IToolBag): IToolBag {
    return {
      id : tool.id,
      description: tool.description,
      tools: tool.tools,
      notes: tool.notes
    };
  }

  copyIToolBags(tools: IToolBag[]): IToolBag[] {
    const ret: IToolBag[] = [];
    if (!this.isEmptyArray(tools)) {
      for (const t of tools){
        ret.push(this.copyIToolBag(t));
      }
    }
    return ret;
  }

  extractIFile(info: IRouterFile): IFile {
    return {
      id: info.id,
      name: info.name,
      specificationName: info.name,
      revision: info.revision,
      description: info.description,
      type: info.type,
      url: info.url,
      sourceUrl: info.sourceUrl,
      revisionDate: info.revisionDate
    };
  }

  extractIFiles(rfList: IRouterFile[]): IFile[] {
    const ret: IFile[] = [];
    if (!this.isEmptyArray(rfList)) {
      for (const info of rfList) {
        ret.push(this.extractIFile(info));
      }
    }
    return ret;
  }

  /*
  copyIPartFile(info): IPartFile {
      return {
        file: this.copyFile(info.file),
        partId: info.partId
      };
    }
  */

  copyIRouterFile(info): IRouterFile {
    return {
      mapType: info.mapType,
      routerId: info.routerId,
      routerOperationId: info.routerOperationId,
      routerOperationDescription: info.RouterOperationDescription,
      routerOperationNumber: info.RouterOperationNumber,
      id: info.id,
      name: info.name,
      specificationName: info.specificationName,
      revision: info.revision,
      description: info.description,
      type: info.type,
      url: info.url,
      sourceUrl: info.sourceUrl,
      revisionDate: info.revisionDate


    };
  }


  getSourceUrl(engSpecDrawUrl: string) {
    const tmp = engSpecDrawUrl.toLowerCase();
    let i = tmp.indexOf('cube');
    if ( i == -1) {
    //i = tmp.indexOf('mshop-eng1');
      return '\\\\' + engSpecDrawUrl.substr(i);
    }
    else {
      return  engSpecDrawUrl;
    }     
  }

  filterFilesBySpecificationName(allFiles: IFile[], removeFiles: IFile[]): IFile[] {
    console.log('filterFiles');

    let ret: IFile[] = [];
    if (!this.isEmptyArray(allFiles) && !this.isEmptyArray(removeFiles)) {
     console.log('removeFiles length ' + (!this.isEmptyArray(removeFiles.length) ? removeFiles.length : 'NULL'));
     console.log('all files length ' + (!this.isEmptyArray(allFiles) ? allFiles.length.toString() : 'NULL'));
       for (const p of allFiles){
         const stepP = removeFiles.find(x => x.specificationName.toLowerCase() == p.specificationName.toLowerCase());
        // console.log('...search for ' + p.name + '...stepP:' + (!stepP ? 'NULL' : stepP.id) );
         if (!stepP) {
           ret.push(p);
         }
       }
     } else {
       ret = allFiles;
     }

     console.log('..post filter files length:' + ret.length);

     return ret;
  }

/*
   makeIPartFile(info: IFile): IPartFile {
            return {
              file: this.copyFile(info),
              partId: null
            };
          }
*/
  copyFile(info: IFile): IFile {
    return {
      id: info.id,
      name: info.name,
      specificationName: info.specificationName,
      revision: info.revision,
      description: info.description,
      type: info.type,
      url: info.url,
      sourceUrl: info.sourceUrl,
      revisionDate: info.revisionDate
    };
  }

  makeIRouterFile(routerId: string, step: IRouterStep, info: IFile): IRouterFile {
    const ret = {
      mapType: MapType.ROUTER,
      routerId: routerId,
      routerOperationId: (step != null ? step.id : null),
      routerOperationDescription: (step != null ? step.description : null),
      routerOperationNumber: (step != null ? step.step : -1),
      id: info.id,
      name: info.name,
      specificationName: info.specificationName,
      revision: info.revision,
      revisionDate: info.revisionDate,
      description: info.description,
      type: info.type,
      url: info.url,
      sourceUrl: info.sourceUrl
    };

    if (step == null) {
      ret.mapType = MapType.ROUTER;
    } else {
      ret.mapType = MapType.STEP;
    }

    return ret;
  }

  copyIEtchingInfo(info: IEtchingInfo) {
    return {
      id: info.id,
      partId: info.partId,
      PSI: info.PSI,
      perfReqLevel: info.perfReqLevel,
      tempClass: info.tempClass,
      retMatClass: info.retMatClass,
      API6AMono: info.API6AMono,
      minWall: info.minWall,
      nomSize: info.nomSize,
      endConA: info.endConA,
      endConB: info.endConB,
      endConC: info.endConC,
      endConD: info.endConD,
      service: info.service
    };
  }

  makeIPartInfo(info: IEtchingInfo): IPartInfo {
    return {
      etchingInfo: this.copyIEtchingInfo(info),
      partId: null
    };
  }

  copyIPartInfo(info: IPartInfo): IPartInfo {
    const pi = this.makeIPartInfo(info.etchingInfo);
    pi.partId = info.partId;
    return pi;
  }

  getTestBOMItem(routerStep: IRouterStep, qty: number): IBOMItem {
    return {
      id: null,
      routerStepId: routerStep.id,
      step: routerStep.step,
      stepDescription: routerStep.description,
      partDescription: 'description',
      partId: Guid.newGuid(),
      partNumber: 'MOCK003',
      partType: PartType.DISCRETE,
      qty: qty,
      sizeEach: 1,
      partUnitType: UnitType.EACH,
      partTraceType: TraceType.SERIAL,
      autoAlloc: false
    };
  }

  makeTest(routerStep: IRouterStep): ITest {
    return {
      id: Guid.newGuid(),
      step: routerStep.step,
      stepDescription: routerStep.description,
      routerStepId: routerStep.id,
      specificationId: null,
      specificationName: null,
      description: 'Test description',
      type: TestType.IPI,
      min: 0,
      max: 0,
      frequency: 1,
      notes: 'Test notes',
      unitType: UnitType.INCH,
      testOrder: 1,
      testDeviceType: TestDeviceType.SERIALIZED,
      testDeviceCount: 1
    };
  }

  copyTest(info: ITest): ITest {
    return {
      id: info.id,
      routerStepId: info.routerStepId,
      specificationId: info.specificationId,
      specificationName: info.specificationName,
      step: info.step,
      stepDescription: info.stepDescription,
      description: info.description,
      type: info.type,
      min: info.min,
      max: info.max,
      frequency: info.frequency,
      notes: info.notes,
      unitType: info.unitType,
      testOrder: info.testOrder,
      testDeviceType: info.testDeviceType,
      testDeviceCount: info.testDeviceCount
    };
  }

  getIPITest(routerStep: IRouterStep, min: number, max: number): ITest {
    const t = this.makeTest(routerStep);
    t.min = min;
    t.max = max;
    t.type = TestType.IPI;
    return t;
  }

  getNDETest(routerStep: IRouterStep): ITest {
    const t = this.makeTest(routerStep);
    t.type = TestType.NDE;
    return t;
  }

  getId(item: any): string {
    if (this.isExistingItem(item)) {
      return item.id;
    } 
    else {
      return null;
    }
  }

  isEmptyGuid(guid: string): boolean {
    return guid == Guid.Empty;
  }

  isExistingItem(item, id: string = null): boolean {
    let ret = false;
    if (item && !this.isEmptyString(item.id) && !this.isEmptyGuid(item.id)) {
      if (!this.isEmptyString(id)) {
        ret = item.id == id;
      } 
      else {
        ret = true;
      }
    }
    return ret;
  }

  isExistingVersionedItem(item, id: string = null): boolean {
    console.log('helper.service.isExistingVersionedItem');

    let ret = false;
    if (item && !this.isEmptyString(item.description) && !this.isEmptyString(item.revision)) {
      if (!this.isEmptyString(id)) {
        ret = item.id == id;
      } else {
        ret = true;
      }
    }
    return ret;
  }

  replaceItemInList(ar: any [], item: any) {
    const tmp = ar.find( x => x.id == item.id);
    const i = ar.indexOf(tmp);
    ar.splice(i, 1, item);
  }

  resortList(ar: any [], sortFunc) {
    if (!this.isEmptyArray(ar)) {
      ar.sort(sortFunc);
    }
  }

  getStepOrder(routerId: string, tableRows: any): StepOrder {
    const map: IdOrder[] = this.getIdOrderList(tableRows);
    const so = new StepOrder(routerId, map);
    return so;
  }

  getIdOrderList(tableRows: any): IdOrder[] {
    // console.log(tableRows);

    const ret: IdOrder[] = [];
    const ids = this.getTableRowIds(tableRows);
    // console.log(ids);
    for (let i = 1; i <= ids.length; i++) {
      const id = ids[i - 1];
      if (this.isEmptyString(id)) {
        continue;
      }
      ret.push(new IdOrder(id, i));
    }
    return ret;
  }

  getTableRowIds(tableRows) {
    const order = [];
    for (const r of tableRows){
      order.push(r.id);
    }
    return order;
  }

  getToolIds(tools: ITool[]) {
    const ret: string[] = [];
    if (!this.isEmptyArray(tools)) {
      for (const t of tools){
        ret.push(t.id);
      }
    }
    return ret;
  }

  listContainsSimilarDifferentItem(list: any[], val: any, cmp: Function) {
  //console.log('listContainstSimilarDifferentitem any length:' + (list || []).length);
  //console.log(val);

    if (this.isEmptyArray(list)) {
      return false;
    }

    if (val == null) {
      return false;
    }   

    for (const o of list){            
      if (o.id == val.id) {           
      
        if (cmp(o, val) == 0) {
          
          return true;
        }
      }
    }         
    return false;
  }

  listContainsSimilarDifferentItemEdit(list: any[], val: any, cmp: Function) {
    //console.log('listContainstSimilarDifferentitem any length:' + (list || []).length);
    //console.log(val);

    if (this.isEmptyArray(list)) {
      return false;
    }

    if (val == null) {
      return false;
    }   
          
    for (const o of list){            
      if (o.id !== val.id) {           
      
        if (cmp(o, val) == 0) {
          
          return true;
        }
      }
    }         
    return false;
  }

  listContainsItem(list: any[], val: any, cmp: Function) {
    if (this.isEmptyArray(list)) {
      return false;
    }

    if (val == null) {
      return false;
    }

    for (const o of list){
      //console.log('listContainsItem ******************************' + o.description + '*********************************');
        if (cmp(o, val) == 0) {
          return true;
        }
    }

    return false;
  }

  listContainsString(list:string[], val:string)
  {
    if (this.isEmptyArray(list) || this.isEmptyString(val)) {
      return false;
    }

    for (const s of list){
        if (s == val) {
          return true;
        }
    }

    return false;
  }

  listFindItemById(list: any[], id: string): object {
    var ret:object = null;
    if (this.isEmptyArray(list) || this.isEmptyString(id))
      return ret;
    
    for (const o of list){
        if (o.id == id) {
          ret = o;
          break;
        }
    }

    return ret;
  }

  listContainsItemById(list: any[], id: string) {
    if (this.isEmptyArray(list)) {
      return false;
    }

    if (this.isEmptyString(id)) {
      return false;
    }

    for (const o of list){
        if (o.id == id) {
          return true;
        }
    }

    return false;
  }

  listContainsItemByDescription(list: any[], description: string) {
    if (this.isEmptyArray(list)) {
      return false;
    }

    if (this.isEmptyString(description)) {
      return false;
    }

    for (const o of list){
        if (o.description == description) {
          return true;
        }
    }

    return false;
  }

  existsMatchingItem(items: any[], item: any, cmp: Function) {
    if (this.isEmptyArray(items)) {
      return false;
    }

    for (const o of items){
        if (cmp(o, item) == 0) {
          return true;
        }
    }

    return false;
  }

  setActiveStepTab() {
    return false;
  }
  
  infoTabStatus(infoActive: boolean) {
    this.routerInfoTab.next(infoActive);
  }

//////

}
