import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { IWorkStation, WorkStationUtils } from '../models/workstation.model';
import { Http, Response } from '@angular/http';
import { Helper } from './helper.service';
import { AppHttpClientService } from './app-http-client.service';
import { GateKeeperService} from '../shared/gatekeeper/user/user.service';
import { Config } from '../shared/gatekeeper/config';
import { MethodType, MethodTypes } from '../enums/methodtype';
import 'rxjs/add/observable/throw';




@Injectable()
export class WorkStationServiceHttp {


  constructor(
    private http: Http,
    private helper: Helper,
    // private auth: AuthService,
    private ahttp: AppHttpClientService ,
    private gkauth: GateKeeperService ,
    private Config: Config) { }


  createWorkStation(ws: IWorkStation): Observable<IWorkStation> {
    console.log('WorkStationServiceHttp.createWorkstation');
    const info = WorkStationUtils.toServerInfo(ws);

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'workStations/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       const ret =  this.translateToIWorkStation(response);
       return ret;
    });
  }

  updateWorkStation(ws: IWorkStation): Observable<IWorkStation> {
    console.log('WorkStationServiceHttp.updateWorkStation');
    const info = WorkStationUtils.toServerInfo(ws);

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'workStations/updateWorkStation';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       const ret =  this.translateToIWorkStation(response);
       return ret;
    });
  }


  getWorkStation(id: string): Observable<IWorkStation> {
    console.log('RouterServiceHttp.getWorkStations');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'workStations/' + id;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       const router =  this.translateToIWorkStation(response);
       return router;
    });
  }


  getWorkStations(): Observable<IWorkStation[]> {
    console.log('RouterServiceHttp.getWorkStations');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'workStations/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       const ret =  this.translateToIWorkStations(response);
       return ret;
    });
  }

  getWorkStationsForWorkCenter(workCenterId: string): Observable<IWorkStation[]> {
    console.log('RouterServiceHttp.getWorkStations');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'workStations/GetWorkStationsForWorkCenter/' + workCenterId;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       console.log(response);
       const ret =  this.translateToIWorkStations(response);
       console.log(ret);
       return ret;
    });

  }

  deleteWorkStation(id: string): Observable<number> {
    console.log('RouterServiceHttp.getWorkStations');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'workStations/' + id;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.DELETE);
    Config.gkParameters.JsonParameters = '';

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       return Number(response);
    });
  }

  translateToIWorkStation(data: any): IWorkStation {
    const ret: IWorkStation = null;
    if (data) {
      // data.state = RouterState.getState(<number>data.state);
      return WorkStationUtils.toIWorkStation(data);
    } else {
      return null;
    }
  }

  translateToIWorkStations(data): IWorkStation[] {
    const ret: IWorkStation[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIWorkStation(d));
      }
    }
    return ret;
  }


private handleError(error: Response) {
return Observable.throw(error.statusText);
}   


}






