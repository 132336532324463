import { Component, OnInit, Input, Output, EventEmitter, AfterContentChecked, OnDestroy } from '@angular/core';
import { IToolBag } from '../../../../core/models/tool.model';

@Component({
  selector: 'tool-kit-create',
  template: require('./tool-kit-create.component.html'),
  // styles: [require('./tool-kit-create.component.css')]
  styles: [require('./tool-kit-create.component.css').toString()]
})
export class ToolKitCreateComponent implements OnInit, OnDestroy, AfterContentChecked {


  @Output() kitModal = new EventEmitter;
  @Output() toolBagDone = new EventEmitter;
  @Input() toolBags;
  @Input() editMode;
  modified = false;
  selected = false;
  overlayWasVisible = false;
  defaultToolBag: IToolBag = {
    id: null,
    description: null,
    tools: [],
    notes: null
  };
 @Input() selectedToolBag: IToolBag = this.defaultToolBag;

 onToolBagDone(toolBag: IToolBag) {
  this.toolBagDone.emit(toolBag);
 }

  constructor() { }

  ngOnInit() {
    console.log('tool-kit-create.component.ngOnInit');
  }

  ngOnDestroy() {
    console.log('tool-kit-create.component.ngOnDestroy');
  }

  get isOverlayVisible() {
    return false; //this.reasonCodeForm.nativeElement.offsetParent != null;
  }

  ngAfterContentChecked() {
    console.log('tool-kit-create.component.ngAfterContentChecked');
    if (!this.isOverlayVisible && this.overlayWasVisible) {
      console.log('tool-kit-create.component.ngAfterContentChecked...visible to hidden ');
      this.overlayWasVisible = false;
      // this.resetFormValidation();
    } else if (this.isOverlayVisible && !this.overlayWasVisible) {
      console.log('tool-kit-create.component.ngAfterContentChecked...hidden to visible');
      this.overlayWasVisible = true;
     // this.userSubmitted = false;
      // this.resetFormValidation();
    }
  }

  modalToggle() {
    this.kitModal.emit(false);
  }

}
