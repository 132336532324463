import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTestKit'
})
export class FilterTestKitPipe implements PipeTransform {

  transform(files: any, term: any): any {
    // check if term is undefined
    if (term === undefined || term.length == 0 || files === null) {
       return files;
    }
    // return the updated routers array
    return files.filter(function(p){
    return p.specificationName.toLowerCase().includes(term.toLowerCase()) || p.specificationName.toLowerCase().includes(term.toLowerCase());
    });
  }
}
