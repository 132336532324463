import { Injectable, EventEmitter } from '@angular/core';
import {IPart, IEtchingInfo } from '../models/part.model';
import { Helper } from './helper.service';




@Injectable()
export class PartDomainService {
  selectedPart: IPart = null;
  etchingInfo: IEtchingInfo = null;
  
  constructor(private helper: Helper) { }



}