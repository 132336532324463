import { Helper } from '../services/helper.service';

export interface IWorkStation {
    id: string;
    number: string;
    description: string;
    workCenterId: string;
    type: WorkStationType;
    inUse: boolean;
}


enum WorkStationTypeService {
  UNKNOWN = -1,
  PHYSICAL = 0,
  PEOPLE = 1,
  DEBUG = 2
}

export enum WorkStationType {
  UNKNOWN = 'unknown',
  PHYSICAL = 'physical',
  PEOPLE = 'people',
  DEBUG = 'debug'
}

export class WorkStationUtils {
  public static toServerInfo(w: IWorkStation): any {
    return {
      id: w.id, // !== null ? w.id : "",
      number: w.number,
      description: w.description,
      workCenterId: w.workCenterId,
      type: WorkStationTypeUtils.toWorkStationTypeService(w.type)
    };
  }

    public static toServerInfoMin(w: IWorkStation): any {
      return {
        id: w.id, // !== null ? w.id : "",
        description: w.description
      };
    }

  public static toServerInfoMinArray(stations:IWorkStation[]): any
  {
     var ret = [];

     if (stations && stations.length>0)
     {
        ret = [];

        for (var i:number = 0; i< stations.length; i++)
            ret.push(this.toServerInfoMin(stations[i]));
     }

     return ret;
  }

  public static toIWorkStation(info: any): IWorkStation {
    info.type = WorkStationTypeUtils.toWorkStationType(info.type);
    return <IWorkStation> info;
  }

  public static areEqual(a: IWorkStation, b: IWorkStation) {
    if (a == null) {
      return b == null;
    }

    if (b == null ) {
      return false;
    }

    return Helper.areEqualStr(a.id, b.id) &&
          Helper.areEqualStr(a.number, b.number) &&
           a.type === b.type &&
           Helper.areEqualStr(a.description, b.description);
  }

  public static copy(a: IWorkStation, b: IWorkStation) {
    a.id = b.id;
    a.type = b.type;
    a.description = b.description;
    a.inUse = b.inUse;
  }
}

export class WorkStationTypeUtils {
  public static toWorkStationType(type: WorkStationTypeService): WorkStationType {
    switch (type) {
      case WorkStationTypeService.PHYSICAL:
        return WorkStationType.PHYSICAL;
      case WorkStationTypeService.PEOPLE:
        return WorkStationType.PEOPLE;
        case WorkStationTypeService.DEBUG:
        return WorkStationType.DEBUG;
      default:
        return WorkStationType.UNKNOWN;
    }
  }

  public static toWorkStationTypeService(type: WorkStationType): WorkStationTypeService {
    switch (type) {
      case WorkStationType.PHYSICAL:
        return WorkStationTypeService.PHYSICAL;
      case WorkStationType.PEOPLE:
        return WorkStationTypeService.PEOPLE;
        case WorkStationType.DEBUG:
        return WorkStationTypeService.DEBUG;

      default:
        return WorkStationTypeService.UNKNOWN;
    }
  }
}




