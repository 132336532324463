import { Helper } from '../services/helper.service';
export interface IReasonCode {
    id: string;
    type: ReasonCodeType;
    code: string;
    description: string;
    inUse: boolean ;
  }

  enum ReasonCodeTypeService {
    UNKNOWN = 0,
    SCRAP_WIP = 1,
    SCRAP_FINISHED_GOOD = 2,
    SCRAP_BOMITEM = 3,
    SCRAP_PARTINSTANCE = 4,
    SKIP_WIP = 5,
    CANCEL_WIP = 6,
    REWORK_WIP = 7,
    SEND_TO_DISPOSITION = 8,
    ADJUST_PARTINSTANCE_SIZE = 9,
    UNSCRAP_PARTINSTANCE = 10,
    UNSCRAP_FINISHED_GOOD = 11,
    CANCEL_RECEIVED_PARTINSTANCE = 12,
    MODIFY_TEST = 13, // internal use only, no reason codes
    CORRECT_PARTINSTANCE_SIZE = 14, // Database change only, no accounting consequences
    UNALLOC_TO_NEW_PARTINSTANCE = 15, // Create a new serialized part from a chunk of previously allocated material.
    HOLD_PART = 16, // internal use only, no reason codes
    HOLD_TRACECODE = 17, // internal use only, no reason codes
    UNHOLD_PART = 18, // internal use only, no reason codes
    UNHOLD_TRACECODE = 19, // internal use only, no reason codes
    HOLD_SERIALNUMBER = 20,
    UNHOLD_SERIALNUMBER = 21,
    UNAVAILABLE_PART_ALLOCATED = 22,
    PARTSIZE_ADJUSTED_BEYOND_THRESHOLD = 23,
    UNSCRAP_BOMITEM = 24,
    UNSCRAP_BOMITEM_TO_INVENTORY = 25,
    UNSCRAP_WIP = 26,
    HOLD_ROUTER = 27,
    MODIFY_ROUTER = 28
  }

  export enum ReasonCodeType {
    ADJUST_PARTINSTANCE_SIZE = 'Adjust Part Size',
    CANCEL_RECEIVED_PARTINSTANCE = 'Cancel Received Part',
    CANCEL_WIP = 'Cancel WIP',
    CORRECT_PARTINSTANCE_SIZE = 'Correct Part Size', // Database change only, no accounting consequences
    HOLD_PART = 'Hold Part', // internal use only, no reason codes
    HOLD_ROUTER = 'Hold Router', // internal use only, no reason codes
    HOLD_SERIALNUMBER = 'Hold Serial Number',
    HOLD_TRACECODE = 'Hold Trace Code', // internal use only, no reason codes
    MODIFY_ROUTER = 'Modify Router',
    MODIFY_TEST = 'Modify Test', // internal use only, no reason codes
    PARTSIZE_ADJUSTED_BEYOND_THRESHOLD = 'Part Size Adjusted Beyond Threshold',
    REWORK_WIP = 'Rework WIP',
    SCRAP_BOMITEM = 'Scrap BOM Item',
    SCRAP_FINISHED_GOOD = 'Scrap Finished Good',
    SCRAP_PARTINSTANCE = 'Scrap Part',
    SCRAP_WIP = 'Scrap WIP',
    SEND_TO_DISPOSITION = 'Send To Disposition',
    SKIP_WIP = 'Scrap WIP',
    UNALLOC_TO_NEW_PARTINSTANCE = 'Unallocate To New Part', // Create a new serialized part from a chunk of previously allocated material.
    UNAVAILABLE_PART_ALLOCATED = 'Unavailable Part Allocated',
    UNSCRAP_BOMITEM = 'Unscrap BOM Item',
    UNSCRAP_BOMITEM_TO_INVENTORY = 'Unscrap BOM Item To Inventory',   
    UNSCRAP_FINISHED_GOOD = 'Unscrap Finished Good',
    UNSCRAP_PARTINSTANCE = 'Unscrap Part',
    UNSCRAP_WIP = 'Unscrap WIP',
    UNHOLD_PART = 'Unhold Part', // internal use only, no reason codes
    UNHOLD_SERIALNUMBER = 'Unhold Serial Number',
    UNHOLD_TRACECODE = 'Unhold Trace Code', // internal use only, no reason codes
    UNKNOWN = 'Unknown',
  }

  export class ReasonCodeUtils {

    public static areEqual(n1: IReasonCode, n2: IReasonCode) {
      if (n1 == null) {
        return n2 == null;
      }

      if (n2 == null) {
        return false;
      }

      return Helper.areEqualStr(n1.id, n2.id) &&
        Helper.areEqualStr(n1.code, n2.code) &&
          Helper.areEqualStr(n1.description, n2.description) &&
            n1.type === n2.type;
    }
    public static toServerInfo(r: IReasonCode): any {
      return {
        id: r.id,
        type: ReasonCodeTypeUtils.toReasonCodeTypeService(r.type),
        code: r.code,
        description: r.description
      };
    }

    public static toIReasonCode(info: any): IReasonCode {
      info.type = ReasonCodeTypeUtils.toReasonCodeType(info.type);
      return <IReasonCode> info;
    }
  }

  export class ReasonCodeTypeUtils {



    public static get types(): ReasonCodeType[] {
      const ret: ReasonCodeType[] = [];
      ret.push(ReasonCodeType.ADJUST_PARTINSTANCE_SIZE);
      ret.push(ReasonCodeType.CANCEL_RECEIVED_PARTINSTANCE);
      ret.push(ReasonCodeType.CANCEL_WIP);
      ret.push(ReasonCodeType.CORRECT_PARTINSTANCE_SIZE);
      ret.push(ReasonCodeType.HOLD_PART);
      ret.push(ReasonCodeType.HOLD_ROUTER);
      ret.push(ReasonCodeType.HOLD_SERIALNUMBER);
      ret.push(ReasonCodeType.HOLD_TRACECODE);
      ret.push(ReasonCodeType.UNHOLD_PART);
      ret.push(ReasonCodeType.MODIFY_TEST);
      ret.push(ReasonCodeType.MODIFY_ROUTER);
      ret.push(ReasonCodeType.PARTSIZE_ADJUSTED_BEYOND_THRESHOLD);
      ret.push(ReasonCodeType.REWORK_WIP);
      ret.push(ReasonCodeType.SCRAP_WIP);
      ret.push(ReasonCodeType.SCRAP_FINISHED_GOOD);
      ret.push(ReasonCodeType.SCRAP_BOMITEM);
      ret.push(ReasonCodeType.SCRAP_PARTINSTANCE);
      ret.push(ReasonCodeType.SEND_TO_DISPOSITION);
      ret.push(ReasonCodeType.SKIP_WIP);
      ret.push(ReasonCodeType.UNALLOC_TO_NEW_PARTINSTANCE);
      ret.push(ReasonCodeType.UNAVAILABLE_PART_ALLOCATED);
      ret.push(ReasonCodeType.UNHOLD_SERIALNUMBER);
      ret.push(ReasonCodeType.UNHOLD_TRACECODE);
      ret.push(ReasonCodeType.UNSCRAP_BOMITEM);
      ret.push(ReasonCodeType.UNSCRAP_BOMITEM_TO_INVENTORY);
      ret.push(ReasonCodeType.UNSCRAP_FINISHED_GOOD);
      ret.push(ReasonCodeType.UNSCRAP_PARTINSTANCE);
      ret.push(ReasonCodeType.UNSCRAP_WIP);

                return ret;
    }

    private static sortByType(r1: ReasonCodeType, r2: ReasonCodeType) {
      if (r1 == null ) {
         if (r2 == null) {
            return 0;
        } else {
          return -1;
        }
      }
      if (r2 == null ) { return 1; }

      if (r1 < r2) {
        return -1;
      } else if (r1 > r2) {
        return 1;
      } else {
        return 0;
      }
    }

    public static toReasonCodeType(type: ReasonCodeTypeService): ReasonCodeType {
      switch (type) {
        case ReasonCodeTypeService.ADJUST_PARTINSTANCE_SIZE:
          return ReasonCodeType.ADJUST_PARTINSTANCE_SIZE;
        case ReasonCodeTypeService.CANCEL_RECEIVED_PARTINSTANCE:
          return ReasonCodeType.CANCEL_RECEIVED_PARTINSTANCE;
        case ReasonCodeTypeService.CANCEL_WIP:
          return ReasonCodeType.CANCEL_WIP;
        case ReasonCodeTypeService.CORRECT_PARTINSTANCE_SIZE:
          return ReasonCodeType.CORRECT_PARTINSTANCE_SIZE;
        case ReasonCodeTypeService.HOLD_PART:
          return ReasonCodeType.HOLD_PART;
        case ReasonCodeTypeService.HOLD_ROUTER:
          return ReasonCodeType.HOLD_ROUTER;
        case ReasonCodeTypeService.HOLD_SERIALNUMBER:
          return ReasonCodeType.HOLD_SERIALNUMBER;
        case ReasonCodeTypeService.HOLD_TRACECODE:
          return ReasonCodeType.HOLD_TRACECODE;
        case ReasonCodeTypeService.MODIFY_TEST:
          return ReasonCodeType.MODIFY_TEST;
          case ReasonCodeTypeService.MODIFY_ROUTER:
          return ReasonCodeType.MODIFY_ROUTER;
        case ReasonCodeTypeService.PARTSIZE_ADJUSTED_BEYOND_THRESHOLD:
          return ReasonCodeType.PARTSIZE_ADJUSTED_BEYOND_THRESHOLD;
        case ReasonCodeTypeService.REWORK_WIP:
          return ReasonCodeType.REWORK_WIP;
        case ReasonCodeTypeService.SCRAP_BOMITEM:
          return ReasonCodeType.SCRAP_BOMITEM;
        case ReasonCodeTypeService.SCRAP_FINISHED_GOOD:
          return ReasonCodeType.SCRAP_FINISHED_GOOD;
        case ReasonCodeTypeService.SCRAP_PARTINSTANCE:
          return ReasonCodeType.SCRAP_PARTINSTANCE;
        case ReasonCodeTypeService.SCRAP_WIP:
          return ReasonCodeType.SCRAP_WIP;
        case ReasonCodeTypeService.SEND_TO_DISPOSITION:
          return ReasonCodeType.SEND_TO_DISPOSITION;
        case ReasonCodeTypeService.SKIP_WIP:
          return ReasonCodeType.SKIP_WIP;
        case ReasonCodeTypeService.UNALLOC_TO_NEW_PARTINSTANCE:
          return ReasonCodeType.UNALLOC_TO_NEW_PARTINSTANCE;
        case ReasonCodeTypeService.UNAVAILABLE_PART_ALLOCATED:
          return ReasonCodeType.UNAVAILABLE_PART_ALLOCATED;
        case ReasonCodeTypeService.UNHOLD_PART:
          return ReasonCodeType.UNHOLD_PART;
        case ReasonCodeTypeService.UNHOLD_SERIALNUMBER:
          return ReasonCodeType.UNHOLD_SERIALNUMBER;
        case ReasonCodeTypeService.UNHOLD_TRACECODE:
          return ReasonCodeType.UNHOLD_TRACECODE;
        case ReasonCodeTypeService.UNSCRAP_BOMITEM:
          return ReasonCodeType.UNSCRAP_BOMITEM;
        case ReasonCodeTypeService.UNSCRAP_BOMITEM_TO_INVENTORY:
          return ReasonCodeType.UNSCRAP_BOMITEM_TO_INVENTORY;
        case ReasonCodeTypeService.UNSCRAP_FINISHED_GOOD:
          return ReasonCodeType.UNSCRAP_FINISHED_GOOD;
        case ReasonCodeTypeService.UNSCRAP_PARTINSTANCE:
          return ReasonCodeType.UNSCRAP_PARTINSTANCE;
        case ReasonCodeTypeService.UNSCRAP_WIP:
          return ReasonCodeType.UNSCRAP_WIP;
        default:
          return ReasonCodeType.UNKNOWN;
      }
    }

    public static toReasonCodeTypeService(type: ReasonCodeType): ReasonCodeTypeService {
      switch (type) {
        case ReasonCodeType.ADJUST_PARTINSTANCE_SIZE:
          return ReasonCodeTypeService.ADJUST_PARTINSTANCE_SIZE;
        case ReasonCodeType.CANCEL_RECEIVED_PARTINSTANCE:
          return ReasonCodeTypeService.CANCEL_RECEIVED_PARTINSTANCE;
        case ReasonCodeType.CANCEL_WIP:
          return ReasonCodeTypeService.CANCEL_WIP;
        case ReasonCodeType.CORRECT_PARTINSTANCE_SIZE:
          return ReasonCodeTypeService.CORRECT_PARTINSTANCE_SIZE;
        case ReasonCodeType.HOLD_PART:
          return ReasonCodeTypeService.HOLD_PART;
        case ReasonCodeType.HOLD_SERIALNUMBER:
          return ReasonCodeTypeService.HOLD_SERIALNUMBER;
        case ReasonCodeType.HOLD_TRACECODE:
          return ReasonCodeTypeService.HOLD_TRACECODE;
          case ReasonCodeType.MODIFY_ROUTER:
          return ReasonCodeTypeService.MODIFY_ROUTER;
        case ReasonCodeType.MODIFY_TEST:
          return ReasonCodeTypeService.MODIFY_TEST;
        case ReasonCodeType.PARTSIZE_ADJUSTED_BEYOND_THRESHOLD:
          return ReasonCodeTypeService.PARTSIZE_ADJUSTED_BEYOND_THRESHOLD;
        case ReasonCodeType.REWORK_WIP:
          return ReasonCodeTypeService.REWORK_WIP;
        case ReasonCodeType.SCRAP_BOMITEM:
          return ReasonCodeTypeService.SCRAP_BOMITEM;
        case ReasonCodeType.SCRAP_FINISHED_GOOD:
          return ReasonCodeTypeService.SCRAP_FINISHED_GOOD;
        case ReasonCodeType.SCRAP_PARTINSTANCE:
          return ReasonCodeTypeService.SCRAP_PARTINSTANCE;
        case ReasonCodeType.SCRAP_WIP:
          return ReasonCodeTypeService.SCRAP_WIP;
        case ReasonCodeType.SEND_TO_DISPOSITION:
          return ReasonCodeTypeService.SEND_TO_DISPOSITION;
        case ReasonCodeType.SKIP_WIP:
          return ReasonCodeTypeService.SKIP_WIP;
        case ReasonCodeType.UNALLOC_TO_NEW_PARTINSTANCE:
          return ReasonCodeTypeService.UNALLOC_TO_NEW_PARTINSTANCE;
        case ReasonCodeType.UNAVAILABLE_PART_ALLOCATED:
          return ReasonCodeTypeService.UNAVAILABLE_PART_ALLOCATED;
        case ReasonCodeType.UNHOLD_PART:
          return ReasonCodeTypeService.UNHOLD_PART;
        case ReasonCodeType.UNHOLD_SERIALNUMBER:
          return ReasonCodeTypeService.UNHOLD_SERIALNUMBER;
        case ReasonCodeType.UNHOLD_TRACECODE:
          return ReasonCodeTypeService.UNHOLD_TRACECODE;
        case ReasonCodeType.UNSCRAP_BOMITEM:
          return ReasonCodeTypeService.UNSCRAP_BOMITEM;
        case ReasonCodeType.UNSCRAP_BOMITEM_TO_INVENTORY:
          return ReasonCodeTypeService.UNSCRAP_BOMITEM_TO_INVENTORY;
        case ReasonCodeType.UNSCRAP_FINISHED_GOOD:
          return ReasonCodeTypeService.UNSCRAP_FINISHED_GOOD;
        case ReasonCodeType.UNSCRAP_PARTINSTANCE:
          return ReasonCodeTypeService.UNSCRAP_PARTINSTANCE;
        case ReasonCodeType.UNSCRAP_WIP:
          return ReasonCodeTypeService.UNSCRAP_WIP;
        default:
          return ReasonCodeTypeService.UNKNOWN;
      }
    }
  }

