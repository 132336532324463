
import {map, filter} from 'rxjs/operators';
import { IRouter } from '../core/models/router.model';
import { RouterInfoComponent } from './router-info/router-info.component';
import { AuthService } from '../core/services/auth.service';
import { Component, OnInit, OnChanges, SimpleChanges, OnDestroy, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart, GuardsCheckEnd, RoutesRecognized, NavigationCancel, NavigationError } from '@angular/router';
import { PartServiceHttp } from '../core/services/part.servicehttp';
import { Helper } from '../core/services/helper.service';
import { RouterDomainService } from '../core/services/router-domain.service';
import { RouterNotesComponent } from './router-notes/router-notes.component';
import { GateKeeperService} from '../core/shared/gatekeeper/user/user.service';
// import {  } from '@angular/router/src/events';

@Component({
  selector: 'app-router-detail',
  template: require('./router-detail.component.html'),
  // styles: [require('./router-detail.component.css')]
  styles: [require('./router-detail.component.css').toString()]
})
export class RouterDetailComponent implements OnInit, OnDestroy {
  routerId: string;
  @ViewChild('routerInfo' , {static: true}) routerInfo: RouterInfoComponent;
  @ViewChild('routerNotes' , {static: false}) routerNotes: RouterNotesComponent;

  // setting *infoActive* to 'true' makes router-details -> Info the first visible tab
  // setting *infoActive* to 'false' makes router-details -> Steps the first visible tab
  infoActive: boolean;

  constructor(private partService: PartServiceHttp, private route: ActivatedRoute,
    private utils: Helper,
    private domain: RouterDomainService,
    private router: Router,
    private auth: AuthService ) {}

 ngOnDestroy() {
    console.log('router-detail.components.ngOnDestroy');
    console.log(this.router.url);
    if (!this.router.url.includes('routers/')) {
      if(this.auth.systemLock != null)  
      {
          this.auth.unlockItem(this.auth.systemLock.id).subscribe(res => {
          console.log('router-listing component unlockItem returns:' + res);
        });
      } 
    }
 }

 get hasRouterChanges(){
   return this.routerInfo.hasChanges;
 }

 get newRouter(): IRouter {
    return this.routerInfo.getNewRouter();
 }

 get hasNotesChanges() {
   return this.routerNotes.hasChanges;
 }

 get newNotes(){
   return this.routerNotes.newNotes;
 }
previousUrl: string;
  ngOnInit() {
    console.log('router-detail.components.ngOnInit');
   this.routerId = this.route.snapshot.params['id'];
   if (this.utils.isEmptyString(this.routerId) ||
    !this.utils.isExistingItem(this.domain.selectedRouter, this.routerId)) {
    this.router.navigate(['/routers']);
    }

    this.utils.currentInfoTabStatus.subscribe(infoActive => this.infoActive = infoActive);
    this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => this.route),).subscribe((e) => {
      console.log('NAVIGATION_EVENT: ', e);
    });
  }

  handleStepClick(data) {
    console.log('handleStepClick');

  }

  tabSwitch(evt, tabName) {
    let i, x, tablinks;
    x = document.getElementsByClassName('tab');
    for (i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
    }
    tablinks = document.getElementsByClassName('tabLink');
    for (i = 0; i < x.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' tabColor', '');
    }
    document.getElementById(tabName).style.display = 'block';
    evt.currentTarget.className += ' tabColor';
  }

}
