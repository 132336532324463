import { Injectable, EventEmitter } from '@angular/core';
import { Observable} from 'rxjs/Observable';
import { GateKeeperService} from '../shared/gatekeeper/user/user.service';
import { Http, Response, RequestOptions, Headers, ResponseOptions } from '@angular/http';
//import { IUser} from '../../core/models/user.model';
import { ILoginParameters} from '../shared/gatekeeper/user/loginparameters.model';
import { Config } from '../shared/gatekeeper/config';
import { IUser } from '../shared/gatekeeper/user/user.response';
import { AlertService } from './alert.service';
import { AlertLevel } from '../models/alert.model';
import { UserMaintenanceServiceHttp } from './usermaintenance.servicehttp';
import { ConfigurableFocusTrap } from '@angular/cdk/a11y';


@Injectable()
export class LoginService {
  loginParameters: ILoginParameters = {
                                        ApplicationID: '03E0E72D-859F-4EA9-AB01-AD33F6884750',
                                        UserName: '',
                                        PasswordHash: '',
                                        DeviceID: Config.DeviceID,
                                        AppVersion: Config.AppVersion, 
                                        UseBadgeScan: true
                                      };
  token = '';
  systemLock = null;

  constructor(
    private auth: GateKeeperService, 
    private alert:AlertService, 
    private userMaintenanceService: UserMaintenanceServiceHttp) {}

  get httpOptions() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'POST,GET, DELETE, PUT, OPTIONS, HEAD');
    const options = new RequestOptions({headers : headers});

    return options;
  }

  loginUser(scan: string, errorHandler: Function = null): Observable<IUser> {
    console.log('login.service.loginUser scan:' + scan);
    this.auth.setCurrentUser(null);
    this.auth.alertEnabled = true;
    this.loginParameters.PasswordHash = scan;
    console.log('login.service.loginUser loginParameters:' + this.loginParameters);
    return this.auth.do_post(Config.gateKeeperUrl + 'api/login', this.loginParameters, errorHandler).map( (resp: Response) => {
      console.log('...login request returns');
      //console.log(resp);        

      this.auth.setCurrentUser(resp);
      if (resp == null || !this.auth.hasPermission('CanAccessMaintenance')) {
        this.auth.setCurrentUser(null);
        this.alert.showOk('Unauthorized: Insufficient permssion', AlertLevel.WARNING, (rsp) => { });
      }      
      
      return this.auth.currentUser;
    });
  }

  logoutUser(): Observable<Response> {
    return this.auth.logout(Config.gateKeeperUrl + 'api/logout/' + Config.gkParameters.TokenID, null, ).map((resp: Response) => {
      const ret  = resp != null ? resp : resp;
      if (resp != null) {
        return ret;
      } else {
        return  ret;
      }
    }).catch((error) => this.auth.handleError(error));
  }
}
