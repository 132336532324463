import { UnitType } from '../enums/unittype';


export interface IPart {
  id: string;
  number: string;
  description: string;
  unit: UnitType;
  trace: TraceType;
  type: PartType;
  name: string;
  fGLot: number;
}

export interface IEtchingInfo {
  id: string;
  partId: string;
  PSI: string;
  perfReqLevel: string;
  tempClass: string;
  retMatClass: string;
  API6AMono: string;
  minWall: string;
  nomSize: string;
  endConA: string;
  endConB: string;
  endConC: string;
  endConD: string;
  service: string;
}

export interface IPartInfo {
  partId: string;
  etchingInfo: IEtchingInfo;
}

enum TraceTypeService {
  UNKNOWN = 0,
  SERIAL = 1,
  HEAT_OR_SERIAL = 2,
  NONE = 3
}

export enum TraceType {
  UNDEFINED = '',
  UNKNOWN = 'UNKNOWN',
  SERIAL = 'Serial',
  HEAT_OR_SERIAL = 'Heat or Serial',
  NONE = 'None'
}

export class TraceTypeUtils {
  public static toTraceType(type: TraceTypeService): TraceType {
    switch (type) {
      case TraceTypeService.SERIAL:
        return TraceType.SERIAL;
      case TraceTypeService.HEAT_OR_SERIAL:
        return TraceType.HEAT_OR_SERIAL;
        case TraceTypeService.NONE:
        return TraceType.NONE;
      default:
        return TraceType.UNKNOWN;
    }
  }

  public static toTraceTypeID(traceType: TraceType): number {
    switch (traceType) {
      case TraceType.SERIAL:
        return 1;
      case TraceType.HEAT_OR_SERIAL:
        return 2;
        case TraceType.NONE:
        return 3;
      default:
        return 3; //-- NONE
    }
  }

  public static toTraceTypeService(type: TraceType): TraceTypeService {
    switch (type) {
      case TraceType.SERIAL:
        return TraceTypeService.SERIAL;
      case TraceType.HEAT_OR_SERIAL:
        return TraceTypeService.HEAT_OR_SERIAL;
        case TraceType.NONE:
        return TraceTypeService.NONE;
      default:
        return TraceTypeService.UNKNOWN;
    }
  }
}

enum PartTypeService {
  UNDEFINED = -2,
  UNKNOWN = -1,
  DIVISIBLE = 0,
  DISCRETE = 1
}

export enum PartType {
  UNDEFINED = '',
  UNKNOWN = 'Unknown',
  DIVISIBLE = 'Divisible',
  DISCRETE = 'Discrete'
}

export class PartTypeUtils {
  public static toPartType(type: PartTypeService): PartType {
    switch (type) {
      case PartTypeService.DIVISIBLE:
        return PartType.DIVISIBLE;
      case PartTypeService.DISCRETE:
        return PartType.DISCRETE;
      default:
      console.log('unknown server part type:' + type.toString());
        return PartType.UNKNOWN;
    }
  }

  public static toPartTypeService(type: PartType): PartTypeService {
    switch (type) {
      case PartType.DIVISIBLE:
        return PartTypeService.DIVISIBLE;
      case PartType.DISCRETE:
        return PartTypeService.DISCRETE;

      default:
        return PartTypeService.UNKNOWN;
    }
  }

  public static toPartTypeID(partType: PartType): number {
    switch (partType) {
      case PartType.DIVISIBLE:
        return 0;
      case PartType.DISCRETE:
        return 1;
      default:
        return 1; //-- DISCRETE
    }
  }
}
