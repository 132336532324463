import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterProgram'
})
export class FilterProgramPipe implements PipeTransform {

  transform(programs: any, term: any): any {
    // check if term is undefined
    if (term === undefined || term.length == 0 || programs === null || term.length == 0) {
       return programs;
    }
    // return the updated routers array
    return programs.filter(function(p){
    return p.number.toLowerCase().includes(term.toLowerCase()) || p.description.toLowerCase().includes(term.toLowerCase());
    });
  }
}
