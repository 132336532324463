import { Helper } from '../services/helper.service';
import {IWorkStation, WorkStationUtils} from './workstation.model';

export interface IWorkCenter {
    id: string;
    name: string;
    type: WorkCenterType;
    description: string;
    code: number;
    isOutsource: boolean;
    scheduleType: ScheduleType;
    warehouse: string;
    inUse: boolean;
}


export interface IWorkCenterExtended {
  workCenter: IWorkCenter;
  workStations: IWorkStation[];
}

enum WorkCenterTypeService {
  UNKNOWN = 0,
  MACHINING = 2,
  INSPECTION = 3,
  DISPOSITION = 4
}

export enum WorkCenterType {
  UNKNOWN = 'UNKNOWN',
  MACHINING = 'machining',
  INSPECTION = 'inspection',
  DISPOSITION = 'disposition'
}

export class WorkCenterUtils {

  public static toServerInfo(w: IWorkCenter): any {
    return {
      id: w.id !== null ? w.id : "",
      name: w.name,
      type: WorkCenterTypeUtils.toWorkCenterTypeService(w.type),
      code: w.code,
      description: w.description,
      isOutsource:  w.isOutsource,
      scheduleType: ScheduleTypeUtils.toScheduleTypeService(w.scheduleType),
      warehouse: w.warehouse,
      inUse: w.inUse
    };
  }

  public static toServerCenterAndStationsInfo(w: IWorkCenter, stations:IWorkStation[]): any {
    return {
      id: w.id !== null ? w.id : "",
      name: w.name,
      type: WorkCenterTypeUtils.toWorkCenterTypeService(w.type),
      code: w.code,
      description: w.description,
      isOutsource:  w.isOutsource,
      scheduleType: ScheduleTypeUtils.toScheduleTypeService(w.scheduleType),
      warehouse: w.warehouse,
      inUse: w.inUse,
      workStations: WorkStationUtils.toServerInfoMinArray(stations)
    };
  }

  public static toIWorkCenter(info: any): IWorkCenter {
    info.type = WorkCenterTypeUtils.toWorkCenterType(info.type);
    info.scheduleType = ScheduleTypeUtils.toScheduleType(info.scheduleType);
    return <IWorkCenter> info;
  }


  public static areEqual(a: IWorkCenter, b: IWorkCenter) {
    if (a == null) {
      return b == null;
    }

    if (b == null ) {
      return false;
    }

    return Helper.areEqualStr(a.id, b.id) &&
                              a.type === b.type &&
        Helper.areEqualStr(a.description, b.description) &&
          a.isOutsource === b.isOutsource &&
            a.scheduleType === b.scheduleType &&
              Helper.areEqualStr(a.warehouse, b.warehouse);
  }

  public static copy(a: IWorkCenter, b: IWorkCenter) {
    a.id = b.id;
    a.name = b.name;
    a.type = b.type;
    a.description = b.description;
    a.isOutsource = b.isOutsource;
    a.scheduleType = b.scheduleType;
    a.warehouse = b.warehouse;
    a.inUse = b.inUse;
  }

}

export class WorkCenterTypeUtils {

  public static get types(): WorkCenterType[] {
    const ret: WorkCenterType[] = [];
    ret.push(WorkCenterType.MACHINING);
    ret.push(WorkCenterType.INSPECTION);
    ret.push(WorkCenterType.DISPOSITION);

    return ret;
  }


  public static toWorkCenterType(type: WorkCenterTypeService): WorkCenterType {
    switch (type) {
      case WorkCenterTypeService.MACHINING:
        return WorkCenterType.MACHINING;
      case WorkCenterTypeService.INSPECTION:
        return WorkCenterType.INSPECTION;
      case WorkCenterTypeService.DISPOSITION:
        return WorkCenterType.DISPOSITION;
      default:
        return WorkCenterType.UNKNOWN;
    }
  }

  public static toWorkCenterTypeService(type: WorkCenterType): WorkCenterTypeService {
    switch (type) {
      case WorkCenterType.MACHINING:
        return WorkCenterTypeService.MACHINING;
      case WorkCenterType.INSPECTION:
        return WorkCenterTypeService.INSPECTION;
        case WorkCenterType.DISPOSITION:
        return WorkCenterTypeService.DISPOSITION;
      default:
        return WorkCenterTypeService.UNKNOWN;
    }
  }
}



enum ScheduleTypeService {
  UNKNOWN = -1,
  STANDARD = 0,
  ONDEMAND = 1
}

export enum ScheduleType {
  UNKNOWN = 'unknown',
  STANDARD = 'standard',
  ONDEMAND = 'on demand'
}


export class ScheduleTypeUtils {
  public static get types(): ScheduleType[] {
    const ret: ScheduleType[] = [];
    ret.push(ScheduleType.STANDARD  );
    ret.push(ScheduleType.ONDEMAND);

    return ret;
  }

  public static toScheduleType(type: ScheduleTypeService): ScheduleType {
    switch (type) {
      case ScheduleTypeService.STANDARD:
        return ScheduleType.STANDARD;
      case ScheduleTypeService.ONDEMAND:
        return ScheduleType.ONDEMAND;
      default:
        return ScheduleType.UNKNOWN;
    }
  }

  public static toScheduleTypeService(type: ScheduleType): ScheduleTypeService {
    switch (type) {
      case ScheduleType.STANDARD:
        return ScheduleTypeService.STANDARD;
      case ScheduleType.ONDEMAND:
        return ScheduleTypeService.ONDEMAND;
      default:
        return ScheduleTypeService.UNKNOWN;
    }
  }
}



