import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CanDeactivateStepNotes } from '../step-detail/step-detail.candeactivateNotes';
import { CanDeactivateRouterDetail1 } from '../router-detail/router-detail.candeactivate.1';
import { CanDeactivateRouterDetail } from '../router-detail/router-detail.candeactivate';
import { RouterDetailComponent } from '../router-detail/router-detail.component';
import { RouterListingComponent } from '../router-listing/router-listing.component';
import { StepDetailComponent } from '../step-detail/step-detail.component';
import { CanDeactivateStepInfo } from '../step-detail/step-detail.candeactivateStep';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: 'routers',
                children:
                [
                    {
                        path: '',
                        component: RouterListingComponent               
                    },
                    {
                        path: ':id',
                        component: RouterDetailComponent,
                        pathMatch: 'prefix',
                        canDeactivate: [CanDeactivateRouterDetail, CanDeactivateRouterDetail1],
                        data:                 {
                            breadcrumb: 'Router Detail',
                            router: '',
                            step: ''
                        }
                    },
                    {
                        path: ':id/steps/:stepId',
                        component: StepDetailComponent,
                        pathMatch: 'prefix',
                        canDeactivate: [CanDeactivateStepInfo, CanDeactivateStepNotes],
                        data:                 {
                            breadcrumb: 'Step Detail',
                            router: '',
                            step: ''
                        },
                    },
                ]
            },            
        ])
    ],
    declarations: []
})
export class RouterListingModule { }