import { ActivatedRoute } from '@angular/router';
import { RouterDomainService } from '../../../core/services/router-domain.service';
import { Component, OnInit, AfterContentChecked, Input, Output, EventEmitter, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { ITool, IToolBag, ToolUtils, IdOrder } from '../../../core/models/tool.model';
import { Helper } from '../../../core/services/helper.service';
import { SpecToolComponentBizLogic } from './spec-tool.component.bizl';
import { IRouter } from '../../../core/models/router.model';
import { ToolServiceHttp } from '../../../core/services/tool.servicehttp';
import { Subscription as ISubscription } from 'rxjs';
import { emit } from 'cluster';
import { ShSelectComponent } from '../../../core/shared/sh-slelect.component';


@Component({
  selector: 'spec-tool',
  template: require('./spec-tool.component.html'),
  styles: [require('./spec-tool.component.css')]
})
export class SpecToolComponent implements OnInit, AfterContentChecked {



  @ViewChild('selectHolderTool', {static: true}) selectHolderTool: ShSelectComponent;
  @ViewChild('selectInsertTool', {static: true}) selectInsertTool: ShSelectComponent;
  @ViewChild('selectAdditionalTool', {static: true}) selectAdditionalTool: ShSelectComponent;

  
  @Input() toolBag: IToolBag;
  @Input() editMode: Boolean;
  @Input() toolBags: IToolBag[]; // used to validate uniqueness
  @Output() toolBagDone: EventEmitter<IToolBag> = new EventEmitter();
  @ViewChild('toolBagFormGroup', {static: true}) formGroup: ElementRef;
  overlayWasVisible = false;
  tools: ITool[];
  moreTools: ITool[];
  ToolBagFormGroup: FormGroup;
  bizl: SpecToolComponentBizLogic;
  selectedHolderTools: ITool[];
  selectedInsertTools: ITool[];
  selectedAdditionalTools: ITool[];
  stepId: string;
  userSubmitted = false;
  lastBaseHolderInsertValidationMsg = '';
  additionalTools: ITool[] = [];
  selectAdditionalToolPlaceholder = 'Select a tool and click Add';
  

  constructor(private toolService: ToolServiceHttp, private helper: Helper,
    private domain: RouterDomainService,
    private route: ActivatedRoute ) { }

  ngOnInit() {
   console.log('spec-tool.component ngOnInit');
   this.bizl = new SpecToolComponentBizLogic(this, this.domain);

   this.ToolBagFormGroup = new FormGroup({
      description: new FormControl('', Validators.compose([Validators.required, this.makeValidateUnique() ]) ),
      notes: new FormControl('', Validators.required),
      });


      }
      get isOverlayVisible() {
        return this.formGroup.nativeElement.offsetParent != null;
      }

      ngAfterContentChecked() {
        //console.log('spec-tool.component.ngAfterContentChecked---isOverlayVisible' + this.isOverlayVisible + '--- overlayWasVisible: ' + this.overlayWasVisible);
        if (!this.isOverlayVisible && this.overlayWasVisible) {
          //console.log('spec-tool.component.ngAfterContentChecked...visible to hidden ');
          this.overlayWasVisible = false;
          this.resetFormValidation();
        } else if (this.isOverlayVisible && !this.overlayWasVisible) {
          //console.log('spec-tool.component.ngAfterContentChecked...hidden to visible');
          this.overlayWasVisible = true;
          this.userSubmitted = false;
          this.resetFormValidation();
          this.toolService.getTools().subscribe(allTools => {
            allTools.sort(this.sortNumberDescription);
            this.tools = allTools;
            this.moreTools = allTools.slice();
            this.tools.splice(0, 0, ToolUtils.TOOL_NONE);
            this.patchFormGroup(this.toolBag);
          });
        }
      }

      selectHolderToolHandler(event) {
        console.log('spec-tool.selectHolderToolHandler');
        console.log(event);
        this.selectHolderTool.error = false;
      }

      selectInsertToolHandler(event) {
        console.log('spec-tool.selectInsertToolHandler');
        console.log(event);
        this.selectInsertTool.error = false;
      }

      selectAdditionalToolHandler(event) {
        console.log('spec-tool.selectAdditionalToolHandler');
        //console.log(event);
        this.selectAdditionalTool.error = false;
      }

addAdditionalTool() {
  console.log('spec-tool.component.addAdditionalTool');
 // console.log(this.selectedAdditionalTools);  
  const addTool = !this.helper.isEmptyArray(this.selectedAdditionalTools) ? this.selectedAdditionalTools[0] : null;
  if (addTool) {
    const tmp = {...addTool};
    this.additionalTools.push(tmp);
  }
}

sortNumberDescription(a: ITool, b: ITool) {
  let ret = Helper.cmpStringAlphaNumeric(a.number, b.number);
  if (ret === 0) {
    ret = Helper.cmpStringAlphaNumeric(a.description, b.description);
  }
  return ret;
}


sortOrder(a: ITool, b: ITool) {
  return Helper.cmpNumber(a.order, b.order);
}


removeTool(t: ITool) {
  this.additionalTools = this.additionalTools.filter(x => x !== t);
}

getNewToolBag() {
  // console.log('spec-tool.component.getNewToolBag');  
  return this.fromFormValues(this.ToolBagFormGroup.getRawValue());
}

  fromFormValues(info ): IToolBag {
    // console.log('spec-tool.component.fromFormValues');
    const tmp = this.makeToolBagTools();
    // console.log(tmp);
    return {
      id : this.helper.isExistingItem(this.toolBag) ? this.toolBag.id : null,
      description: info.description,
      tools : tmp,
      notes : info.notes
    };
  }

  getHolderTool(): ITool {
    if (!this.helper.isEmptyArray(this.selectedHolderTools)) {
      return this.selectedHolderTools[0];
    } else {
      return ToolUtils.TOOL_NONE;
    }
  }

  getInsertTool(): ITool {
    if (!this.helper.isEmptyArray(this.selectedInsertTools)) {
      return this.selectedInsertTools[0];
    } else {
      return ToolUtils.TOOL_NONE;
    }
  }

  makeToolBagTools(): ITool[] {
    const ret: ITool[] = [];
    // console.log('makeToolBagTools START');

    let holder: ITool = this.getHolderTool();
    let insert: ITool = this.getInsertTool();
    holder = {...holder};
    holder.order = 0;
    insert = {...insert};
    insert.order = 1;
    ret.push(holder);
    ret.push(insert);

    if (!this.helper.isEmptyArray(this.additionalTools)) {
      const other: ITool[] = [];
      let i = 2;
      for (const t of this.additionalTools) {
        const tmp = {...t};
        tmp.order = i;
        i++;
        other.push(tmp);
      }

      Array.prototype.push.apply(ret, other);
      ret.sort(this.sortOrder);
    }

    // console.log(ret);
    // console.log('makeToolBagTools END');
    return ret;

  }

  getFormTool(tools: any[], i: number): any {
    let ret: any = ToolUtils.TOOL_NONE;
    if (tools && tools.length > i) {
      ret = this.tools.find(n => n.number === tools[i].number) || ToolUtils.TOOL_NONE;
    }
    return ret;

  }

  private  patchFormGroup(toolBag: IToolBag) {
     console.log('spec.tool.component.patchFormGroup....');
     console.log(toolBag);
     console.log(this.ToolBagFormGroup);
     
    //this.editMode = true;

    if (toolBag) {
      this.ToolBagFormGroup.patchValue({
        description: toolBag.description,
        notes: toolBag.notes
      });


      const holder = this.getFormTool(toolBag.tools, 0);
      const insert = this.getFormTool(toolBag.tools, 1);
      console.log(holder);
      console.log(insert);

      this.selectedHolderTools = [this.getFormTool(toolBag.tools, 0)];
      this.selectedInsertTools = [this.getFormTool(toolBag.tools, 1)];

      // console.log(this.ToolBagFormGroup.getRawValue());
      this.additionalTools = toolBag.tools && toolBag.tools.length > 2 ? toolBag.tools.slice(2) : [];
    }

    // console.log(this.bizl);
      if (this.bizl.isToolBagEditable) {
        this.ToolBagFormGroup.controls.description.enable();
      } else {
        this.ToolBagFormGroup.controls.description.disable();
      }
      if (this.bizl.areToolsEditable) {
        this.selectHolderTool.disabled = false;
        this.selectInsertTool.disabled = false;
      } else {
        this.selectHolderTool.disabled = true;
        this.selectInsertTool.disabled = true;
      }

      if (this.bizl.areNotesEditable) {
        this.ToolBagFormGroup.controls.notes.enable();
      } else {
        this.ToolBagFormGroup.controls.notes.disable();
      }
    }

  getTitle() {
    if (this.bizl.isNewToolBag) {
      return 'Add a Station Tool';
    } else {
      return 'Edit a Station Tool';
    }
  }

  resetFormValidation() {
    this.userSubmitted = false;
    // yourForm.reset(), yourForm.resetForm() don't work, but this does:
    this.ToolBagFormGroup.markAsPristine();
    this.ToolBagFormGroup.markAsUntouched();
    this.ToolBagFormGroup.updateValueAndValidity();
  }

  get descriptionValidationError(){
    const errors = this.ToolBagFormGroup.controls.description.errors;
    if (errors) {
      if (errors.required) {
        return 'Required';
      } else if (errors.validateUnique) {
        return 'Description must be unique';
      } else {
        return 'Error';
      }
    }
  }
  validateDescription() {
    return !this.bizl.isToolBagEditable ||
           ( this.bizl.isNewToolBag && !this.userSubmitted) ||
           ( !this.bizl.isNewToolBag && this.ToolBagFormGroup.controls.description.pristine) ||
            this.ToolBagFormGroup.controls.description.valid;
  }

makeValidateUnique() {
  const _self = this;
  return function(fc: FormControl) {
   // console.log('spec-tool.component.validateUnique');
    const ret = _self.ToolBagFormGroup && _self.bizl.isUnique(_self.editMode) ? null : {
      validateUnique: {
        valid: false
      }
    };
   // console.log('spec-tool.component ret ' + ret);
    return ret;
  };
}

  validateNewToolBag() {
   // console.log('spec-tool.components.validateNewToolBag');
   // console.log(this);
   // console.log(this.ToolBagFormGroup);
   // console.log(this.bizl);
    return this.ToolBagFormGroup.valid && this.validateBaseHolderInsert();
  }

  validateBaseHolderInsert() {
    let ret = !this.bizl.areToolsEditable ||
    (this.bizl.isNewToolBag && !this.userSubmitted);

    if (ret) {
      return true;
    }
    const holder = this.getHolderTool();
    const insert = this.getInsertTool();

    if (holder === ToolUtils.TOOL_NONE && insert === ToolUtils.TOOL_NONE) {
      this.lastBaseHolderInsertValidationMsg = 'Base holder and insert cannot both be NONE';
    } else {
      ret = true;
    }

    return ret;
  }



  validateInstructions() {
    return !this.bizl.areNotesEditable ||
    (this.bizl.isNewToolBag && !this.userSubmitted) ||
    (!this.bizl.isNewToolBag && this.ToolBagFormGroup.controls.notes.pristine) ||
    this.ToolBagFormGroup.controls.notes.valid;
  }

  submitToolBag(): void {
    console.log('step-spec.submitToolBag');
    this.userSubmitted = true;
    // console.log(this);
    // console.log(this.bizl);
    if (!this.validateNewToolBag()) {
      return;
    }
    const tb = this.getNewToolBag();
    console.log(tb);

    if (this.bizl.canUpdate) {
      console.log('updating station tool');
      this.toolService.updateToolBag(tb).subscribe( updatedId => {
        console.log('...updated stationId:' + updatedId);
        this.toolBagDone.emit(tb);
      });
    } else if (this.bizl.canCreate) {
      console.log('...add new tool bag');
      this.toolService.createToolBag(tb).subscribe( addedId => {
        console.log('...added stationId:' + addedId);
        tb.id = addedId;
        this.toolBagDone.emit(tb);
      });
    }
    }
}
