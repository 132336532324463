import { main } from '@angular/compiler-cli/src/main';
import { ITool, ToolUtils } from '../core/models/tool.model';
import { Helper } from '../core/services/helper.service';



export class ToolsComponentBizLogic {
  private utils: Helper = new Helper();

  constructor(private toolProvider: any) {
    // console.log('StepInfoComponentBizLogic');
    // console.log(router);
  }

  get tool(): ITool  {
    return this.toolProvider.getNewTool();

  }

   get newToolDefaultInfo(): ITool {
    return {
      id: '',
      number: '',
      description: '',
      order: 0,
      inUse: false,
      userDefined: true
    };
  }

  get isToolEditable() {
    return this.tool.userDefined;
  }

get isNewTool() {
   return this.tool.userDefined && !this.utils.isExistingItem(this.tool) ;
}

get canCreate() {
  return this.isNewTool;
}

get canUpdate(){

  return this.isToolEditable && this.toolProvider.validateNewToolInfo &&
   !ToolUtils.areEqual(this.toolProvider.selectedTool, this.tool);
}


canViewDetails(tool: ITool) {
  return true;
}

canDelete(tool: ITool) {
  return tool.userDefined && !tool.inUse;
}

get canSubmit(){
  //console.log('tools.compoent.bizl.canSubmit');
  //console.log(this);
  //console.log(this.toolProvider.ToolFormGroup);
  return this.isNewTool || this.canUpdate;
}


  public get isDescriptionEditable(): boolean {
    return true;
  }

  isUniqueToolNumber(editMode:Boolean) {
    let isUnique:Boolean = null; 
    if (editMode)
    {      
      isUnique =  !this.utils.listContainsSimilarDifferentItemEdit(this.toolProvider.tools, this.tool, this.compToolNumber);
    }
    else
    {
      isUnique = !this.utils.listContainsItem(this.toolProvider.tools, this.tool, this.compToolNumber);
    }
    return isUnique;   
  }

  isUniqueDescription(editMode:Boolean) {
    let isUnique:Boolean = null; 
    if (editMode)
    {      
      isUnique =  !this.utils.listContainsSimilarDifferentItemEdit(this.toolProvider.tools, this.tool, this.compDescription);
    }
    else
    {
      isUnique = !this.utils.listContainsItem(this.toolProvider.tools, this.tool, this.compDescription);
    }
    return isUnique;    
  }


  compToolNumber(a1: ITool, a2: ITool) {
    return Helper.cmpStringAlphaNumeric(a1.number, a2.number);

  }

  compDescription(a1: ITool, a2: ITool) {
    return Helper.cmpStringAlphaNumeric(a1.description, a2.description);

  }

}
