import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterWorkStationAvailable'
})
export class FilterWorkStationAvailablePipe implements PipeTransform {

  transform(workStationsAvailable: any, term: any): any {
    // check if term is undefined
    if (term === undefined || term.length == 0 || workStationsAvailable === null) {
      return workStationsAvailable;
    }
    // return the updated routers array
    return workStationsAvailable.filter(function(w){
    return w.workCenterDescription.toLowerCase().includes(term.toLowerCase()) || w.workStationNumber.toLowerCase().includes(term.toLowerCase()) || w.description.toLowerCase().includes(term.toLowerCase());
    });
  }
}
// || w.description.toLowerCase().includes(term.toLowerCase())
