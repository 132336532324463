import { PartType, PartTypeUtils, TraceType, TraceTypeUtils } from './part.model';
import { UnitTypeUtils, UnitType, UnitTypeService } from '../enums/unittype';
import { Helper } from '../services/helper.service';

export interface IBOMItem {
    id: string;
    routerStepId: string;
    step: number;
    stepDescription: string;
    partId: string;
    partNumber: string;
    partDescription: string;
    partType: PartType;
    partUnitType: UnitType;
    partTraceType: TraceType;
    qty: number;
    sizeEach: number;
    autoAlloc: boolean;
}


export class BOMUtils {
  public static toServerInfo(b: IBOMItem): any {
    return {
      id:  b.id, //!== null ? b.id : "",
      routerStepId: b.routerStepId,
      step: b.step,
      stepDescription: b.stepDescription,
      partId: b.partId,
      partNumber: b.partNumber,
      partDescription: b.partDescription,
      partType: PartTypeUtils.toPartTypeService(b.partType),
      partUnitType: UnitTypeUtils.toUnitTypeService( b.partUnitType ),
      partDescriptionType: TraceTypeUtils.toTraceTypeService(b.partTraceType),
      qty: b.qty,
      sizeEach: b.sizeEach,
      autoAlloc: b.autoAlloc,
      userId: null
    };
  }

  public static areEqual(b1: IBOMItem, b2: IBOMItem) {
    if (b1 == null) {
      return b2 == null;
    }
    if (b2 == null) {
      return null;
    }

    return Helper.areEqualStr(b1.id, b2.id) &&
      Helper.areEqualStr(b1.routerStepId, b2.routerStepId) &&
      Helper.areEqualStr( b1.partId , b2.partId) &&
      b1.qty === b2.qty &&
      b1.sizeEach === b2.sizeEach &&
      b1.autoAlloc === b2.autoAlloc;
  }

  public static toIBOMItem(info: any): IBOMItem {
    info.partUnitType = UnitTypeUtils.toUnitType(info.partUnitType);
    info.partTraceType = TraceTypeUtils.toTraceType(info.partTraceType);
    info.PartType = PartTypeUtils.toPartType(info.partType);
    return <IBOMItem> info;
  }
}
