import { BrowserStack } from 'protractor/built/driverProviders';
import { RouterDomainService } from '../../core/services/router-domain.service';
import { AfterContentChecked, AfterContentInit, AfterViewChecked, DoCheck, Component, ElementRef, OnDestroy, OnChanges, OnInit, ViewChild } from '@angular/core';
import { IRouterStepProgram, IProgram } from '../../core/models/program.model';
import { IFixture } from '../../core/models/fixture.model';
import { IFile, IRouterFile, MapType } from '../../core/models/file.model';
import { ITool, IToolBag, IToolKit, RouterStepToolSet } from '../../core/models/tool.model';
import { IRouterStep, IRouterStepNDE, IRouterStepNotes } from '../../core/models/routerstep.model';
import { ActivatedRoute } from '@angular/router';
import { Helper } from '../../core/services/helper.service';
import { SpecToolComponent } from './spec-tool/spec-tool.component';
import { StepSpecComponentBizLogic } from './step-spec.component.bizl';
import { BOMUtils } from '../../core/models/bom.model';
import { FormGroup, FormControl } from '@angular/forms';
import { PDFService } from '../../core/services/pdf-service';
import { AlertLevel, AlertReponse } from '../../core/models/alert.model';
import { AlertService } from '../../core/services/alert.service';
import { ToolServiceHttp } from '../../core/services/tool.servicehttp';
import { FixtureServiceHttp } from '../../core/services/fixture.servicehttp';
import { FileServiceHttp } from '../../core/services/file.servicehttp';
import { ProgramServiceHttp } from '../../core/services/program.servicehttp';
import { RouterStepServiceHttp } from '../../core/services/routerstep.servicehttp';
import { getNameOfJSDocTypedef, forEachChild } from 'typescript';
import { StepNotesComponent } from '../step-notes/step-notes.component';
import { domain } from 'process';

@Component({
  selector: 'step-spec',
  template: require('./step-spec.component.html'),
  // styles: [require('./step-spec.component.css')]
  styles: [require('./step-spec.component.css').toString()]
})
export class StepSpecComponent implements OnInit, AfterContentChecked, AfterContentInit, AfterViewChecked, OnDestroy{
  getToolName: Function = this.helper.getToolName;
  value = '';
  formTitle: string;
  stepPrograms: IProgram[];
  stepFixtures: IFixture[];
  stepFiles: IRouterFile[];
  ndeData: IRouterStepNDE[];
  private _stepTools: IToolBag[];

  set stepTools(val: IToolBag[]) {
    this._stepTools = val;
    this._stepTools.sort(this.sortToolBagByStation);
  }
  get stepTools(): IToolBag[] {return this._stepTools; }
  routerId: string;
  stepId: string;
  selectedToolBag: IToolBag;
  @ViewChild('ToolBagInfo', {static: false}) stationToolInfo: SpecToolComponent;
  @ViewChild('specView', {static: true}) view: ElementRef;
  @ViewChild('toolNotes', {static: false}) toolNotes: ElementRef;  
  viewWasVisible = false;
  step: IRouterStep;
  kitModal = false;
  ndeTestExist = false;
  refreshFileComponent: number;
  bizl: StepSpecComponentBizLogic;
  currentNotes: string = null;
  ToolNotesFormGroup: FormGroup;
  SetupNotesFormGroup: FormGroup;
  UserNotesFormGroup: FormGroup;
  NDEType: string = null;
  NDETypeLabel: string = null;
  isMiddleDivVisible: boolean = false;
  editMode : Boolean = false;
  set submittedToolBag(val: boolean) {
    if (this.stationToolInfo) {
      this.stationToolInfo.userSubmitted = val;
    }
  }

  constructor(private programService: ProgramServiceHttp
    , private toolService: ToolServiceHttp
    , private fileService: FileServiceHttp
    , private fixtureService: FixtureServiceHttp
    , private route: ActivatedRoute
    , private helper: Helper
    , private stepService: RouterStepServiceHttp
    , private domain: RouterDomainService
    , private alert: AlertService
    , private pdfService: PDFService ) { }

    private get isVisible(): boolean {
      return this.view.nativeElement.offsetParent != null;
    }

    private sortToolBagByStation(s1: IToolBag, s2: IToolBag): number {
      return Helper.cmpStringAlphaNumeric(s1.description, s2.description);
     }

    ngAfterContentChecked() {
      if (!this.isVisible && this.viewWasVisible) {
        console.log('step-spec.component.ngAfterContentChecked...visible to hidden');
        this.viewWasVisible = false;
      } else if (this.isVisible && !this.viewWasVisible) {
          console.log('step-spec.component.ngAfterContentChecked...hidden to visible');
          this.viewWasVisible = true;


          setTimeout(() => {
              this.stepService.getRouterStepNotes(this.stepId).subscribe(stepNotes => {
                this.submittedToolBag = false;
                if (stepNotes != null) {
                  this.UserNotesFormGroup.patchValue({
                    userNotes: stepNotes.userNotes
                  });

                  this.SetupNotesFormGroup.patchValue({
                    setupNotes: stepNotes.setupNotes
                  });
                  
                  this.patchNotes(stepNotes);
                }            
              });
          }, 2000);

          
          this.fileService.getFilesForRouterStep(this.step).subscribe( files => {
            console.log('...router step ' + this.stepId + ' file count:' + (files ? files.length : 'null'));
            this.stepFiles = this.helper.arrayOrEmpty(files);
          });
      }
    }


getToolDescription(t: ITool): string {
  return (t != null ? t.description : '');
}
  ngOnInit() {

    console.log('step-spec.components ngOnit');

    this.ToolNotesFormGroup = new FormGroup({
      toolNotes: new FormControl({value: '', disabled: true})
    });
    this.UserNotesFormGroup = new FormGroup({
      userNotes: new FormControl({value: '', disabled: true})
    });
    this.SetupNotesFormGroup = new FormGroup({
      setupNotes: new FormControl({value: '', disabled: true})
    });

    this.routerId = this.route.snapshot.params['id'];
    this.stepId = this.route.snapshot.params['stepId'];
    this.bizl = new StepSpecComponentBizLogic(this.domain.selectedRouter, this.stepId, this.domain);
    this.stepService.getRouterStep(this.stepId).subscribe(step => {
      this.step = step;

      // Change to stepId in production.  6ADE8E2AEA384B77BF4FE6B9305EF8AA
      this.stepService.getNDE(this.stepId).subscribe(ndeData => {
        this.ndeData = ndeData;  
       
      }); 

      this.fileService.getFilesForRouterStep(this.step).subscribe( files => {
        console.log('...router step ' + this.stepId + ' file count:' + (files ? files.length : 'null'));
        this.stepFiles = this.helper.arrayOrEmpty(files);
        this.stepFiles.sort(this.sortByName);
      });
    });

      this.programService.getProgramsForRouterStep(this.stepId).subscribe( programs => {
        console.log('...router step ' + this.stepId + ' program count:' + (programs ? programs.length : 'null'));
        this.stepPrograms = programs;
        this.stepPrograms.sort(this.sortByNumber);
      });

      this.fixtureService.getFixturesForRouterStep(this.stepId).subscribe( fixtures => {
        this.stepFixtures = fixtures;
        this.stepFixtures.sort(this.sortByNumber);
      });

      this.toolService.getRouterStepToolBags(this.stepId).subscribe( toolBags => {
        //console.log('...tool bags');
        toolBags.sort(Helper.sort_description);
        //console.log(toolBags);
        this.toolService.getRouterStepTools(this.stepId).subscribe(tools => {
         // console.log('...getting router step tools');

         // console.log(tools);
          this.stepTools = this.helper.addToolsToToolBags(toolBags, tools);
          this.stepTools.sort(Helper.sort_description);

          if (toolBags != null) {
            this.patchToolInstructions(toolBags[0]);
          }
         // console.log('...sorted stations');
         // console.log(this.stepTools);
        });
      });

      this.stepService.getRouterStepNotes(this.stepId).subscribe(stepNotes => {
            this.submittedToolBag = false;
            if (stepNotes != null) {
              this.UserNotesFormGroup.patchValue({
                userNotes: stepNotes.userNotes
              });

              this.SetupNotesFormGroup.patchValue({
                setupNotes: stepNotes.setupNotes
              });
              this.patchNotes(stepNotes);
            }
            else
            {
              this.UserNotesFormGroup.patchValue({
                userNotes: ''
              });

              this.SetupNotesFormGroup.patchValue({
                setupNotes: ''
              });
            }

            
          });

     
  }

  getNDEType(){
    //console.log('***********************************************...NDEdata array value ' + this.ndeData );
      if (this.ndeData.length > 0)
        {
          this.ndeTestExist = true;
          this.isMiddleDivVisible = true;
          document.getElementById("MiddleContainer").style.display = "block";
          
        }
        else
        {
          this.isMiddleDivVisible = false;
          document.getElementById("MiddleContainer").style.display = "none";
          document.getElementById("TopContainer").style.height = "485px";
        }
      
      if (this.ndeTestExist)
      {
        this.NDEType = this.ndeData[0].ndeName;
        this.NDETypeLabel = this.NDEType + " Test Parameters:";
      }
      
  }

  sortByStation(a: IToolBag, b: IToolBag) {
    return Helper.cmpStringAlphaNumeric(a.description, b.description);
  }

  sortByName(a: IFile, b: IFile) {
    return Helper.cmpStringAlphaNumeric(a.name, b.name);
  }


sortByNumber(a: any, b: any) {
  return Helper.cmpStringAlphaNumeric(a.number, b.number);
}

  ngOnDestroy() {
    console.log('step-spec.ngOnDestroy');
    this.ToolNotesFormGroup.patchValue({
      toolNotes: ''
    });
    //console.log('***********************************************...NDEType Value ' + this.NDEType );
    this.NDEType = null; 
    //console.log('***********************************************...NDEType Value ' + this.NDEType );   
  }

  handleProgramSelected(program: IProgram): void {
    this.programService.addProgramToRouterStep(this.stepId, program).subscribe( added => {
      this.stepPrograms.push(added);
      this.stepPrograms.sort(this.sortByNumber);
      this.closeOverlay();
    });
  }
  deleteProgram(program: IProgram) {
    this.alert.showYesNo('Are you sure you want to delete program ' + program.number + ' ' + program.description + '?',
    AlertLevel.WARNING,
    (ar: AlertReponse) => {
      console.log(ar);
      console.log(ar === AlertReponse.OK);
      console.log(AlertReponse.OK);
      if (ar === AlertReponse.YES) {
        this.programService.removeProgramFromRouterStep(this.stepId, program.id).subscribe(ret => {
          this.stepPrograms = this.helper.arrayOrEmpty(this.stepPrograms.filter(x => x.id !== program.id));
        });
      }
    });

  }

  handleFixtureSelected(fixture: IFixture): void {
    this.fixtureService.addFixtureToRouterStep(this.stepId, fixture).subscribe( added => {
      this.stepFixtures.push(added);
      this.stepFixtures.sort(this.sortByNumber);
      this.closeOverlay();
    });
  }


  deleteFixture(fixture) {
    this.alert.showYesNo('Are you sure you want to delete fixture ' + fixture.number + ' ' + fixture.description + '?',
    AlertLevel.WARNING,
    (ar: AlertReponse) => {
      console.log(ar);
      console.log(ar === AlertReponse.OK);
      console.log(AlertReponse.OK);
      if (ar === AlertReponse.YES) {
        this.fixtureService.removeFixtureFromRouterStep(this.stepId, fixture.id).subscribe( ret => {
          this.stepFixtures = this.helper.arrayOrEmpty(this.stepFixtures.filter(x => x.id !== fixture.id));
        });
      }
    });
  }

  handleToolBagSubmitted(toolBag: IToolBag): void {
    console.log('step-spec.component/handleToolBagSubmitted');
    console.log(toolBag);

    if (this.helper.isEmptyArray(this.stepTools)) {
      this.stepTools = [];
    }

    const tb = this.stepTools.find(x => x.id === toolBag.id);
    console.log(tb);
    if (!tb) {
      this.toolService.addRouterOperationToolBag(this.stepId, toolBag.id).subscribe( ret => {
        this.stepTools.push(toolBag);
        this.stepTools.sort(this.sortByStation);
        this.closeOverlay();
      });
    } else {
      this.helper.replaceItemInList(this.stepTools, toolBag );
      this.stepTools.sort(this.sortByStation);
      this.closeOverlay();
    }


    this.closeOverlay();
  }

  submitToolBag(): void {
    console.log('step-spec.submitToolBag');
    this.submittedToolBag = true;
    this.stationToolInfo.submitToolBag();
    }

get canSubmit(){
  return this.stationToolInfo != null &&
   this.stationToolInfo.bizl.canSubmit &&
   this.isToolBagMode;
}

get isToolBagMode() {
  return this.value === 'Tool';
}

get isProgramMode() {
  return this.value === 'Program';
}

get isFixtureMode() {
  return this.value === 'Fixture';
}

get isFileMode(){
  return this.value === 'File';
}

addToolBag() {
  console.log('Step-Spec clicked on adding a new Tool Bag....editMode is false')
  this.selectedToolBag = this.bizl.newToolBagDefaultInfo;
  this.editMode = false;
  this.overlayOpen('Tool');
}

clickTool(st: IToolBag) {
  console.log('step-spec.component.clickTool ');
  console.log(st);
  if (st != null) {
    this.patchToolInstructions(st);
  }

}

clearToolInstructions() {
  this.ToolNotesFormGroup.patchValue({
    toolNotes: ''
  });
}

patchToolInstructions(st: IToolBag) {
  if (st && st != null && st != undefined && !Helper.isUndefined(st.description))
  {
    this.ToolNotesFormGroup.patchValue({
      toolNotes: 'STATION #' + st.description + ' INSTRUCTIONS\n\n' + st.notes
    });
  }
}

patchNotes(st: IRouterStepNotes) {
  //console.log('####################################step-spec.component.patchNotes was called ' + st.setupNotes + 'User Notes: ' + st.userNotes);
  this.UserNotesFormGroup.patchValue({
    userNotes: st.userNotes
  });

  this.SetupNotesFormGroup.patchValue({
    setupNotes: st.setupNotes
  });
}

editToolBag(st: IToolBag) {
  //console.log('...editToolBag:' + st.description);
  this.editMode = true;
  this.selectedToolBag = st;
  this.overlayOpen('Tool');
}

deleteToolBag(st: IToolBag) {
  this.alert.showYesNo('Are you sure you want to delete tool for station ' + st.description + '?',
  AlertLevel.WARNING,
  (ar: AlertReponse) => {
    console.log(ar);
    console.log(ar === AlertReponse.OK);
    console.log(AlertReponse.OK);
    if (ar === AlertReponse.YES) {
      this.toolService.removeRouterOperationToolBag(this.stepId, st.id).subscribe(ret => {
        this.stepTools = this.helper.arrayOrEmpty(this.stepTools.filter(x => x.id !== st.id));
        this.clearToolInstructions();
      });
    }
  });
}

viewFile(url:string) {
 this.pdfService.show(url);
}

deleteFile(rf: IRouterFile) {
  if (rf.mapType === MapType.STEP) {
    this.alert.showYesNo('Are you sure you want to delete file ' + rf.name + '?',
    AlertLevel.WARNING,
    (ar: AlertReponse) => {
      console.log(ar);
      console.log(ar === AlertReponse.OK);
      console.log(AlertReponse.OK);
      if (ar === AlertReponse.YES) {
       this.fileService.removeFileFromRouterStep(rf).subscribe(ret => {
         this.stepFiles = this.stepFiles.filter(x => x.id !== rf.id);
       });
      }
    });
   }
}

canDeleteFile(f: IRouterFile): boolean {
  return f.mapType === MapType.STEP;
}


  handleFileSelected(file: IFile) {
    this.fileService.addFileToRouterStep(this.step, file).subscribe( added => {
      this.stepFiles.push(added);
      this.stepFiles.sort(this.sortByName);
      this.closeOverlay();
    });
  }

  submit() {
    console.log('step-spec.components submit value:' + this.value);
    if (this.value = 'Tool') {
      this.submitToolBag();
    }
  }
  

  getTitle(type: string): string {
    let t = 'Add a ';

    switch (this.value) {
      case 'Program':
        t = t.concat('Program');
        break;
      case 'Fixture':
        t = t.concat('Fixture');
        break;
      case 'File':
       t = t.concat('File');
        break;
      case 'Tool':
      if (this.bizl.isNewTool(this.selectedToolBag)) {
        t = t.concat('Tool');
      } else if (this.bizl.isToolEditable(this.selectedToolBag)) {
        t = 'Edit a Tool';
      } else {
        t = 'Tool Info';
      }
        break;
      default:
          t = 'UNKNOWN value ' + this.value;
        break;

    }

   return t;

  }


  closeOverlay(_self = this) {
    document.getElementById('SpecOverlay').style.display = 'none';
    _self.selectedToolBag = null;
    _self.submittedToolBag = false;

      // Refresh the toolNotes section
      if(this.value ='Tool')
      {
        this.toolService.getRouterStepToolBags(this.stepId).subscribe( toolBags => {          
          toolBags.sort(Helper.sort_description);          
          this.toolService.getRouterStepTools(this.stepId).subscribe(tools => {        

            this.stepTools = this.helper.addToolsToToolBags(toolBags, tools);
            this.stepTools.sort(Helper.sort_description);

            if (toolBags != null) {
              this.patchToolInstructions(toolBags[0]);
            }          
          });
        });
      }

      this.value = '';

  }
  overlayOpen(type: string) {
    this.value = type;
    this.formTitle = this.getTitle(type);
    const _self = this;

    if (type === 'File') {
      this.refreshFileComponent = this.refreshFileComponent + 1;
    }

    document.getElementById('SpecOverlay').style.display = 'block';
    const modal = document.getElementById('SpecOverlay');

    // When the user clicks anywhere outside of the modal, this closes it
    window.onclick = function(event) {
        if (event.target === modal) {
            _self.closeOverlay(_self);
        }
    };
    }

    getTargetToolKit(): IToolKit {
      const tmp: RouterStepToolSet = new RouterStepToolSet(this.stepId, this.toolService);
      return tmp;
    }

    get canManageKit() {
      return true;
    }

    launchKitManage() {
        this.kitModal = true;
    }

    kitToggle() {
      if (this.kitModal === false) {
        this.kitModal = true;
      } else {
        this.kitModal = false;
      }
    }
    handleKit(data) {
      console.log('step-spec.component.handleKit');
      console.log(data);
      

      //-- get stepTools to refresh what was added
      this.toolService.getRouterStepToolBags(this.stepId).subscribe( toolBags => {
        //console.log('...tool bags');
        toolBags.sort(Helper.sort_description);
        //console.log(toolBags);
        this.toolService.getRouterStepTools(this.stepId).subscribe(tools => {
         // console.log('...getting router step tools');

         // console.log(tools);
          this.stepTools = this.helper.addToolsToToolBags(toolBags, tools);
          this.stepTools.sort(Helper.sort_description);

          if (toolBags != null) {
            this.patchToolInstructions(toolBags[0]);
          }
         // console.log('...sorted stations');
         // console.log(this.stepTools);
        });
      });

      //this.stepTools = <IToolBag[]>data || [];
      //this.stepTools.sort(Helper.sort_description);

      this.kitModal = false;
    }    

    ngAfterContentInit() {      
    //Put this call on a timer
    //if (this.ndeTestExist == true)
    //{
      setTimeout(()=>{
        this.getNDEType();
      },3000);
    //}
     
    }


    ngAfterViewChecked() {
      

      var fcol1 = $('.fcol1').width();
      var fcol2 = $('.fcol2').width();
      var fcol3 = $('.fcol3').width();
      var fcol4 = $('.fcol4').width();
      $('#fcol1').width(fcol1);
      $('#fcol2').width(fcol2);
      $('#fcol3').width(fcol3);
      $('#fcol4').width(fcol4);
    //Fixtures
      var fixcol1 = $('.fixcol1').width();
      var fixcol2 = $('.fixcol2').width();

      $('#fixcol1').width(fixcol1);
      $('#fixcol2').width(fixcol2);

    //Programs
      var pcol1 = $('.pcol1').width();
      var pcol2 = $('.pcol2').width();
      $('#pcol1').width(pcol1);
      $('#pcol2').width(pcol2);
    //Tools
      var tcol1 = $('.tcol1').width();
      $('#tcol1').width(tcol1);

      //NDE 1
      var nde1col1 = $('nde1col1').width();
      var nde1col2 = $('nde1col2').width();
      $('#nde1col1').width(nde1col1);
      $('#nde1col2').width(nde1col2);

      //NDE 2
      var nde2col1 = $('nde2col1').width();
      var nde2col2 = $('nde2col2').width();
      $('#nde2col1').width(nde2col1);
      $('#nde2col2').width(nde2col2);

      //NDE 3
      var nde3col1 = $('nde3col1').width();
      var nde3col2 = $('nde3col2').width();
      $('#nde3col1').width(nde3col1);
      $('#nde3col2').width(nde3col2);

      //NDE 4
      var nde4col1 = $('nde4col1').width();
      var nde4col2 = $('nde4col2').width();
      $('#nde4col1').width(nde4col1);
      $('#nde4col2').width(nde4col2);

      
      //NDE 5
      var nde5col1 = $('nde5col1').width();
      var nde5col2 = $('nde5col2').width();
      $('#nde5col1').width(nde5col1);
      $('#nde5col2').width(nde5col2);



// Fixed Headers
const thp = pcol1+pcol2+22;
const thfix = fixcol1 + fixcol2+22;
const thf = fcol1 + fcol2+ fcol3+ fcol4+45;
const tht = tcol1+11;
$('#tableHeaderP').width(thp);
$('#tableHeaderT').width(tht);
$('#tableHeaderF').width(thf);
$('#tableHeaderFix').width(thfix);

//Resize function
    $(window).resize(function(){
    //Files
      var fcol1 = $('.fcol1').width();
      var fcol2 = $('.fcol2').width();
      var fcol3 = $('.fcol3').width();
      var fcol4 = $('.fcol4').width();
      $('#fcol1').width(fcol1);
      $('#fcol2').width(fcol2);
      $('#fcol3').width(fcol3);
      $('#fcol4').width(fcol4);
    //Fixtures
      let fixcol1 = $('.fixcol1').width();
      let fixcol2 = $('.fixcol2').width();

      $('#fixcol1').width(fixcol1);
      $('#fixcol2').width(fixcol2);

    // Programs
      let pcol1 = $('.pcol1').width();
      let pcol2 = $('.pcol2').width();
      $('#pcol1').width(pcol1);
      $('#pcol2').width(pcol2);
    //Tools
      var tcol1 = $('.tcol1').width();
      $('#tcol1').width(tcol1);

       //NDE 1
       var nde1col1 = $('nde1col1').width();
       var nde1col2 = $('nde1col2').width();
       $('#nde1col1').width(nde1col1);
       $('#nde1col2').width(nde1col2);
 
       //NDE 2
       var nde2col1 = $('nde2col1').width();
       var nde2col2 = $('nde2col2').width();
       $('#nde2col1').width(nde2col1);
       $('#nde2col2').width(nde2col2);
 
       //NDE 3
       var nde3col1 = $('nde3col1').width();
       var nde3col2 = $('nde3col2').width();
       $('#nde3col1').width(nde3col1);
       $('#nde3col2').width(nde3col2);
 
       //NDE 4
       var nde4col1 = $('nde4col1').width();
       var nde4col2 = $('nde4col2').width();
       $('#nde4col1').width(nde4col1);
       $('#nde4col2').width(nde4col2);

        //NDE 5
        var nde5col1 = $('nde5col1').width();
        var nde5col2 = $('nde5col2').width();
        $('#nde5col1').width(nde5col1);
        $('#nde5col2').width(nde5col2);
 

// Fixed Headers
      const thp = pcol1+pcol2 +22;
      const thfix = fixcol1 + fixcol2+22;
      const thf = fcol1 + fcol2+ fcol3+ fcol4+45;
      const tht = tcol1 + 11;
      $('#tableHeaderP').width(thp);
      $('#tableHeaderT').width(tht);
      $('#tableHeaderF').width(thf);
      $('#tableHeaderFix').width(thfix);
    });
    }


  }
