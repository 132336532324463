import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterContentInit, Input } from '@angular/core';
import { emit } from 'cluster';
import { AlertLevel, AlertReponse } from '../../../core/models/alert.model';
import { AlertService } from '../../../core/services/alert.service';
import { ActivatedRoute } from '@angular/router';
import { IFile } from '../../../core/models/file.model';
import { Helper } from '../../../core/services/helper.service';
import { FileServiceHttp } from '../../../core/services/file.servicehttp';
import { TestServiceHttp } from '../../../core/services/test.servicehttp';
import { IFileTest } from '../../../core/models/test.model';

@Component({
  selector: 'test-kit-management',
  template: require('./test-kit-management.component.html'),
  // styles: [require('./test-kit-management.component.css')]
  styles: [require('./test-kit-management.component.css').toString()]
})
export class TestKitManagementComponent implements OnInit, AfterContentInit {

  @Output() kitModal = new EventEmitter;
  @Input() file: IFile = null;
  @Input() fileTests: IFileTest[] = null;
  @ViewChild('orgTable', {static: true}) Table: ElementRef;
  @ViewChild('selectFile', {static: true}) selectFile: ElementRef;
  @ViewChild('btnAppend', {static: true}) btnAppendFiles: ElementRef;
  @ViewChild('btnReplace', {static: true}) btnReplaceFiles: ElementRef;
  @ViewChild('btnCopySelected', {static: true} ) btnCopySelected: ElementRef;
  files: IFile[] = null;
  fromFileTests: any[] = null;
  selectedFile: IFile = null;

  constructor(private router: ActivatedRoute,
  private utils: Helper,
  private fileService: FileServiceHttp,
  private alert: AlertService,
  private testService: TestServiceHttp) { }

  modalToggle() {    
    this.kitModal.emit(false);
  }

  handleSelectFile(elem) {
    console.log('test-kit-management.component.handleSelectFile');
    console.log(elem);
    this.fromFileTests = null;
    if (this.selectedFile != null)
    {
      const f = this.selectedFile;
      this.testService.getFileTests(f.specificationName).subscribe(fromFileTests =>  {
        this.utils.prepSelectableObject(fromFileTests);
        this.fromFileTests = fromFileTests || [];
        this.fromFileTests.sort(Helper.sort_description);
      });
    }    
  }


  ngOnInit() {
    console.log('test-kit-management.component.ngOnInit');
    // this.disableActions(true);
    this.fileService.getFilesWithTestKits().subscribe(files => {
      this.files = files || [];
      this.files = files.filter(x => x.specificationName !== this.file.specificationName);
      this.files.sort(Helper.sortIFileNameRevision);
    });

  }
  getNominalValue(test: IFileTest): number {
    if (test) {
      return (test.min + test.max) / 2;
    } else {
      return 0;
    }

  }

  ngAfterContentInit() {

  }

  appendAll(event) {
    //console.log('test-kit.management.component.appendAll');
    //console.log(event);

      this.testService.appendAllFileTests(this.file.specificationName, this.selectedFile.specificationName).subscribe(ret => {
      //console.log('test-kit.management.component.appendAll returned ret' + ret);
      const tmp = this.utils.handleDuplicateTests(this.fromFileTests, this.fileTests);
      Array.prototype.push.apply(this.fileTests, tmp);
      this.fileTests.sort(Helper.sort_description);
      this.uncheckFromFileTests(); 
    }); 
  }

  uncheckFromFileTests() {
    if (!this.utils.isEmptyArray(this.fromFileTests)) {
      for (const ft of this.fromFileTests) {
        ft.selected = false;
      }
    }
  }

  replaceAll(event) {
    console.log('test-kit.management.component.replaceAll');
    console.log(this.file);
    console.log(this.selectedFile);
    console.log(event);
      this.testService.replaceFileTests(this.file.specificationName, this.selectedFile.specificationName).subscribe(res => {
        this.fileTests = [];
        Array.prototype.push.apply(this.fileTests, this.fromFileTests);
        this.fileTests.sort(Helper.sort_description);
        this.uncheckFromFileTests();
      });

  }

  copySelected(event) {
    console.log('test-kit.management.component.copySelected');
    console.log(this.fromFileTests);
    let tmp = this.fromFileTests.filter(x => x.selected === true);
    console.log(tmp);
    if (!this.utils.isEmptyArray(tmp)) {
      const ids = this.getIFileTestIds(tmp);
      this.testService.appendFileTestsToFile(this.file.id, ids).subscribe( res1 => {
        tmp = this.utils.handleDuplicateTests(tmp, this.fileTests);
        Array.prototype.push.apply(this.fileTests, tmp);
        this.fileTests.sort(Helper.sort_description);
        this.uncheckFromFileTests();
      });

    }
  }

/*

  handleDuplicateTests(fromTests: IFileTest[], toTests: IFileTest[]): IFileTest[] {
    console.log('test-kit-management.component handleDuplicateTests');
    const ret: IFileTest[] = [];

    if (this.utils.isEmptyArray(fromTests) || this.utils.isEmptyArray(toTests)) {
      return fromTests;
    }

    for (const fromTest of fromTests) {
      const baseDesc: string = this.utils.getBaseDescription(fromTest.description);
      console.log(baseDesc);
      const matches: IFileTest[] = ret.filter(x => this.utils.getBaseDescription(x.description) == baseDesc) || [];
      const matches1: IFileTest[] = toTests.filter(x => this.utils.getBaseDescription(x.description) == baseDesc) || [];
      const existingCount = matches.length + matches1.length;
      const copy = {...fromTest}; // shallow copy
      if (existingCount !== 0) {
       copy.description = baseDesc + '-Copy(' + existingCount + ')';
      }
      ret.push(copy);
    }
    return ret;
  }
*/
  getIFileTestIds(fileTests: IFileTest[]) {
    const ret: string[] = [];
    if (!this.utils.isEmptyArray(fileTests)) {
      for (const ft of fileTests) {
        ret.push(ft.id);
      }
    }
    return ret;
  }

  checkBoxHandler(event, ft: any) {
    console.log('test-kit-management.component.checkBoxHandler');
    console.log(event);
    console.log(ft);

    if (event.target.checked && this.alreadyExists(ft)) {
      event.target.checked = false;
    }

    // ft.selected = event.target.checked;
  }

  alreadyExists(ft: IFileTest) {
    return false; // return this.utils.listContainsItem(this.fileTests, ft, this.cmpFileTestDescription);
  }

  cmpFileTestDescription(a: IFileTest, b: IFileTest) {
    if (!a) {
      if (!b) {
        return 0;
      } else {
        return -1;
      }
    }

    if (!b) {
      return 1;
    }

    return Helper.cmpStringAlphaNumeric(a.description, b.description);
  }

  get existsTestsToCopy() {
    let ret = false;
    if (!this.utils.isEmptyArray(this.fromFileTests)) {
      const tmp = this.fromFileTests.filter(x => !this.alreadyExists(x)) || [];
      if (tmp && tmp.length > 0) {
        ret = true;
      }
    }
    return ret;
  }

  get canAppendAll() {
    console.log('test-kit-management.canAppendAll');
    return this.selectedFile != null;
    /*
    const ret = this.existsTestsToCopy;
    console.log('...ret:' + ret);
    return ret;
    */
  }

  get canReplaceAll() {
    console.log('test-kit-management.canReplaceAll');
    return this.selectedFile != null;

/*
     const ret = this.existsTestsToCopy;
     console.log('...ret:' + ret);
     return ret; */
  }

  get canCopySelected() {
    console.log('test-kit-management.canCopySelected');
    return this.selectedFile != null;
    /*
     const ret = this.existsTestsToCopy;
     console.log('...ret:' + ret);
     return ret; */
  }

  get selectFilePlaceholder() {
    return 'Select a file';
  }

}
