import { Helper } from '../services/helper.service';
import {RouterState} from '../enums/router-state';
import { Guid } from './guid.model';
import internal = require('assert');

export interface IRouter {
    id: string;
    partId: string;
    partNumber: string;
    partDescription: string;
    revision: number;
    state: RouterState;
    type: RouterType;
    lastUpdate: Date;
    updatedBy: string;  // full user name
    userId: string; // GUID
    activeWIPTotal: number;
    targetWIP: number;
    psl: number;
}

 export class IRouterMin{
  routerId: string;
  partId: string;
  partNumber: string;
  partDescription: string;
  revision: number;
  state: number;
  lastUpdated: Date;
  updatedBy: string;  // full user name
  activeWIPTotal: number;
  targetWIP:number;
  psl:number;
 }


 export class IRouter {
    id: string;
    partId: string;
    partNumber: string;
    partDescription: string;
    revision: number;
    state: RouterState;
    type: RouterType;
    lastUpdate: Date;
    updatedBy: string;  // full user name
    userId: string; // GUID
    activeWIPTotal: number;
    targetWIP: number;
    psl: number;
 }

 export interface IRouterVersionInfo {
  partId: string;
  latestRouterRevision: number;
  latestRouterState: number;
 }

export interface IValidationResponse {
    result: boolean;
    errMsg: string;
}

enum RouterTypeService {
  UNKNOWN = -1,
  NONCELL = 0,
  CELL = 1
}

export enum RouterType {
  UNKNOWN = 'Unknown',
  NONCELL = 'non cell',
  CELL = 'cell'
}



export class RouterUtils {
  public static toServerInfo(r: IRouter): any {
    return {
      id:  r.id !== null ? r.id : "",
      partId: r.partId,
      partNumber: r.partNumber,
      partDescription: r.partDescription,
      revision: r.revision,
      state: r.state.id,
      type: RouterTypeUtils.toRouterTypeService(r.type),
      lastUpdate: r.lastUpdate,
      updatedBy: r.updatedBy,
      userId: r.userId,
      activeWIPTotal: r.activeWIPTotal,
      targetWIP: r.targetWIP,
      psl: r.psl
    };
  }


  public static RouterMinToRouterInfo(r: IRouterMin): any {
    return {
      id: r.routerId,
      partId: r.partId,
      partNumber: r.partNumber,
      partDescription: r.partDescription,
      revision: r.revision,
      state: RouterState.getState(r.state),
      type: RouterType.NONCELL,
      lastUpdate: r.lastUpdated,
      updatedBy: r.updatedBy,  // full user name
      activeWIPTotal: r.activeWIPTotal,
      targetWIP: r.targetWIP,
      psl: r.psl
    };
  }


  public static areEqual(r1: IRouter, r2: IRouter) {
    if (r1 == null) {
      return r2 == null;
    }

    if (r2 == null ) {
      return false;
    }

    return Helper.areEqualStr(r1.id, r2.id) &&
      Helper.areEqualStr(r1.partId , r2.partId) &&
        r1.revision === r2.revision &&
          Helper.areEqualStr(r1.type, r2.type) &&
            r1.targetWIP === r2.targetWIP &&
             r1.psl === r2.psl;
  }


  // static replaceDoubleQuotes(s: string)  {

  //   let i: any;
  //   let word: any = s;
  //   let newWord = '';

  //   for (i in word) {
  //       if (word[i] === '"') {
  //         console.log(word[i]);
  //         newWord = newWord + '\\' + '"';
  //       } else {
  //         newWord = newWord + word[i];
  //         console.log(word[i]);
  //       }
  //     }
  //    return newWord;
  // }

  public static copy(r1: IRouter, r2: IRouter) {
    r1.id = r2.id;
    r1.partId = r2.partId;
    r1.partNumber = r2.partNumber;
    r1.partDescription = r2.partDescription;
    r1.revision = r2.revision;
    r1.state = r2.state;
    r1.type = r2.type;
    r1.lastUpdate = r2.lastUpdate;
    r1.updatedBy = r2.updatedBy;  // full user name
    r1.activeWIPTotal = r2.activeWIPTotal;
    r1.targetWIP = r2.targetWIP;
    r1.psl = r2.psl;
    }

  public static toIRouter(info: any): IRouter {
    info.type = RouterTypeUtils.toRouterType(info.type);
    info.state = RouterState.getState(info.state);
    return <IRouter> info;
  }

  public static toIRouterVersionInfo(info: any): IRouterVersionInfo {
    return <IRouterVersionInfo> info;
  }


}



export class RouterTypeUtils {

  public static get types(): RouterType[] {
    const ret: RouterType[] = [];
    ret.push(RouterType.CELL);
    ret.push(RouterType.NONCELL);
    return ret;
  }


  public static toRouterType(type: RouterTypeService): RouterType {
    switch (type) {
      case RouterTypeService.NONCELL:
        return RouterType.NONCELL;
      case RouterTypeService.CELL:
        return RouterType.CELL;
      default:
        return RouterType.UNKNOWN;
    }
  }

  public static toRouterTypeService(type: RouterType): RouterTypeService {
    switch (type) {
      case RouterType.NONCELL:
        return RouterTypeService.NONCELL;
      case RouterType.CELL:
        return RouterTypeService.CELL;
      default:
        return RouterTypeService.UNKNOWN;
    }
  }
}

