import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterUserMaintenance'
})
export class FilterUserMaintenancePipe implements PipeTransform {

  transform(users: any, term: any): any {
    // check if term is undefined
    if (term === undefined || term.length == 0 || users === null) {
      return users;
    }
    // return the updated routers array
    return users.filter(function(u){
    return u.firstName.toLowerCase().includes(term.toLowerCase()) || u.lastName.toLowerCase().includes(term.toLowerCase()) ;
    });
  }
}
