import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDemandItemsNoBOM'
})
export class FilterDemandItemsNoBOMPipe implements PipeTransform {

  transform(reports: any, term: any): any {
    // check if term is undefined
    if (term === undefined || term.length == 0 || reports === null || term.length == 0) {
       return reports;
    }
    // return the updated reports array
    return reports.filter(function(r){
    return r.itemnumber.toLowerCase().includes(term.toLowerCase()) || r.itemdescription.toLowerCase().includes(term.toLowerCase()) || r.reported.toLowerCase().includes(term.toLowerCase());
    });
  }
}