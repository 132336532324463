import { AlertLevel, AlertReponse } from '../core/models/alert.model';
import { AlertService } from '../core/services/alert.service';
import { Helper } from '../core/services/helper.service';
import { Component, OnInit, AfterViewChecked, AfterViewInit, OnDestroy , AfterContentInit} from '@angular/core';
import { Router } from '@angular/router';
import { IFile } from '../core/models/file.model';
import { FileServiceHttp } from '../core/services/file.servicehttp';
import { TestServiceHttp } from '../core/services/test.servicehttp';
import { SortCriteria} from '../core/models/sort.model';

@Component({
  selector: 'test-kit',
  template: require('./test-kit.component.html'),
  // styles: [require('./test-kit.component.css')]
  styles: [require('./test-kit.component.css').toString()]
})
export class TestKitComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy, AfterContentInit {

  files: IFile[];
  public term: string;
  dir ="asc";

  constructor(
    private navRouter: Router,
    private utils: Helper,
    private fileService: FileServiceHttp,
    private testService: TestServiceHttp,
    private alert: AlertService
  ) { }

  ngOnInit() {
    console.log('test-kit.component.ngOnInit');
    this.fileService.getFilesWithTestKits().subscribe(files => {
      this.files = files;
      this.files.sort(Helper.sortIFileNameRevision);
    });
  }


  addTestKit() {
    this.navRouter.navigate(['/testKits/new']);
  }


  editTestKit(f: IFile) {
    this.navRouter.navigate(['/testKits', f.id]);
  }

  deleteTestKit(f: IFile) {
    this.alert.showYesNo('Are you sure you want to delete all tests for file ' + f.specificationName + '?',
    AlertLevel.WARNING,
    (ar: AlertReponse) => {
      console.log(ar);
      console.log(ar === AlertReponse.OK);
      console.log(AlertReponse.OK);
      if (ar === AlertReponse.YES) {
        this.testService.deleteFileTestsForFile(f.specificationName).subscribe( res => {
          this.files = this.files.filter(x => x.id !== f.id) || [];
       });
      }
    });
  }

  onSorted(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'specificationName')
          {
            this.files.sort(this.sortByFileNameDesc)
          }
          else if (criteria.sortColumn == 'type')
          {
            this.files.sort(this.sortByTypeDesc)
          }
          else if (criteria.sortColumn == 'revision')
          {
            this.files.sort(this.sortByRevisionDesc)
          }
        }
        else {
          if(criteria.sortColumn == 'specificationName')
          {
            this.files.sort(this.sortByFileNameAsc)
          }
          else if (criteria.sortColumn == 'type')
          {
            this.files.sort(this.sortByTypeAsc)
          }
          else if (criteria.sortColumn == 'revision')
          {
            this.files.sort(this.sortByRevisionAsc)
          }         
        }
      }
      
  } 


  // sortTable(n, event) {
  //   var elementId: string = (event.target as Element).id;
   
  
  //   if (elementId.substring(0,4) == 'col1' && n == 0)
  //   {    
  //     if (this.dir == 'asc')
  //     {
  //       this.dir = 'desc'
  //       this.files.sort(this.sortByFileNameAsc)
  //     }
  //     else 
  //     {
  //       this.dir = 'asc'
  //       this.files.sort(this.sortByFileNameDesc)
  //     }
      
  //   }
  //   else if (elementId.substring(0,4) == 'col2' && n == 1)
  //   {
  //     if (this.dir == 'asc')
  //         {
  //           this.dir = 'desc'
  //           this.files.sort(this.sortByTypeAsc)
  //         }
  //         else 
  //         {
  //           this.dir = 'asc'
  //           this.files.sort(this.sortByTypeDesc)
  //         }
  //   }
  
  //   else if (elementId.substring(0,4) == 'col3' && n == 2)
  //   {
  //     if (this.dir == 'asc')
  //         {
  //           this.dir = 'desc'
  //           this.files.sort(this.sortByRevisionAsc)
  //         }
  //         else 
  //         {
  //           this.dir = 'asc'
  //           this.files.sort(this.sortByRevisionDesc)
  //         }
  //   }   
  // }
  
  
  sortByFileNameAsc(a1: IFile, a2: IFile) { 
    return Helper.cmpString(a1.name, a2.name); 
  }
  
  sortByFileNameDesc(a1: IFile, a2: IFile) { 
    return Helper.cmpStringDesc(a1.name, a2.name); 
  }  
  
  sortByTypeAsc(a1: IFile, a2: IFile) { 
    return Helper.cmpString(a1.type, a2.type); 
  }
  
  sortByTypeDesc(a1: IFile, a2: IFile) {
    return Helper.cmpStringDesc(a1.type, a2.type); 
  }
  
  sortByRevisionAsc(a1:IFile, a2: IFile) { 
    return Helper.cmpString(a1.revision.toString(), a2.revision.toString()); 
  }
  
  sortByRevisionDesc(a1: IFile, a2: IFile) {
    return Helper.cmpStringDesc(a1.revision.toString(), a2.revision.toString()); 
  }


  ngAfterViewChecked() {
    // console.log('test-kit.component.AfterViewChecked');
     const c1 = $('.col1').width();
     const c2 = $('.col2').width();
     const c3 = $('.col3').width();
     // console.log(c1);
     $('#col1').width(c1 + 1);
     $('#col2').width(c2 + 1);
     $('#col3').width(c3 + 1);
     $('.SearchBar').width(c1 + c2 + c3 + 15);

     let th = $('thead').width();
     $('#Cover').width(th + 7);
     $('#description').focus();
   }

   ngOnDestroy() {
     console.log('test-kit.component.OnDestroy');
   }
   ngAfterViewInit() {
        // console.log('File.Component.AfterViewInit');
         // runs every time the user re-sizes the window
             $(window).resize(function(){
           // gets td column widths
             const c1 = $('.col1').width();
             const c2 = $('.col2').width();
             const c3 = $('.col3').width();
             let th = $('thead').width();
             // sets th column widths to match td's
             $('#Cover').width(th + 7);
             $('#col1').width(c1);
             $('#col2').width(c2);
             $('#col3').width(c3 + 1);
             $('.SearchBar').width(c1 + c2 + c3 + 15);
             console.log ('resize header occured');
             });
       }
  ngAfterContentInit() {
    $(document).ready(function(){
      $('.SearchField').focus();
    });
  }
}
