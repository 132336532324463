import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ng2-pdf-viewer',
  template: require('./ng2-pdf-viewer.component.html'),
  // styles: [require('./ng2-pdf-viewer.component.css')]
  styles: [require('./ng2-pdf-viewer.component.html').toString()]
})
export class Ng2PdfViewerComponent implements OnChanges, OnInit {
  _fileUrl: string = null;

  @Input()
  set fileUrl(val: string) {
    this._fileUrl = (val);
  }

  get fileUrl(): string {
    return this._fileUrl;
  }
  @Output() onCloseDocument = new EventEmitter;

  // pdfSrc = 'https://vadimdez.github.io/ng2-pdf-viewer/pdf-test.pdf';
  // pdfSrc = 'http://cube/nomuda/files/8v0007_b.pdf';f
  constructor(
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    console.log('ng2-pdf-viewer.ngOnChanges fileUrl:' + this.fileUrl == null ? 'null' : this.fileUrl);
    console.log(changes);
  }

  openNewTab() {
    window.open('https://vadimdez.github.io/ng2-pdf-viewer/pdf-test.pdf');
  }
  ngOnInit() {

  }

  // function from passed Input fileName service that sets pdfSrc

  closeView(confirm: boolean) {
    this.onCloseDocument.emit(confirm);
    document.getElementById('PdfViewer').style.display = 'none';
  }

}
