import { AppHttpClientService } from './app-http-client.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { IWorkCenter, WorkCenterUtils } from '../models/workcenter.model';
import { Http, Response } from '@angular/http';
import { Helper } from './helper.service';
// import { AuthService } from '../../core/services/auth.service';
import { GateKeeperService} from '../shared/gatekeeper/user/user.service';
import { Config } from '../shared/gatekeeper/config';
import { MethodType, MethodTypes } from '../enums/methodtype';
import 'rxjs/add/observable/throw';
import { IWorkStation } from '../models/workstation.model';



@Injectable()
export class WorkCenterServiceHttp {


  constructor(
    private http: Http,
    private helper: Helper,
    // private auth: AuthService,
    private ahttp: AppHttpClientService,
    private gkauth: GateKeeperService ,
    private Config: Config) { }


    createWorkCenter(w: IWorkCenter): Observable<IWorkCenter> {
      console.log('WorkCenterServiceHttp.createWorkCenter');
      console.log(w);
      const info = WorkCenterUtils.toServerInfo(w);

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'workCenters/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);
   
      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         console.log(response);
         const ret =  this.translateToIWorkCenter(response);
         console.log(ret);
         return ret;
      });
    }

    updateWorkCenter(w: IWorkCenter): Observable<IWorkCenter> {
      console.log('WorkCenterServiceHttp.updateWorkCenter');
      const info = WorkCenterUtils.toServerInfo(w);

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'workCenters/updateWorkCenter';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);      

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         console.log(response);
         const ret =  this.translateToIWorkCenter(response);
         console.log(ret);
         return ret;
      });
    }

    updateWorkCenterAndStations(w: IWorkCenter, stations:IWorkStation[]): Observable<IWorkCenter> {
      console.log('WorkCenterServiceHttp.updateWorkCenterAndStations');
      const info = WorkCenterUtils.toServerCenterAndStationsInfo(w,stations);

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'WorkCenters/UpdateWorkCenterAndStations';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);      

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         console.log(response);
         const ret =  this.translateToIWorkCenter(response);
         console.log(ret);
         return ret;
      });
    }


    getWorkCenter(id: string): Observable<IWorkCenter> {
      console.log('RouterServiceHttp.getWorkCenters');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'workCenters/' + id;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
      Config.gkParameters.JsonParameters = null;

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         console.log(response);
         const router =  this.translateToIWorkCenter(response);
         console.log(router);
         return router;
      });
    }


    getWorkCenters(): Observable<IWorkCenter[]> {
      console.log('RouterServiceHttp.getWorkCenters');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'workCenters/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
      Config.gkParameters.JsonParameters = null;

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         const ret =  this.translateToIWorkCenters(response);
         console.log(ret);
         return ret;
      });
    }

    deleteWorkCenter(id: string): Observable<number> {
      console.log('RouterServiceHttp.getWorkCenters');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'workCenters/' + id;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.DELETE);
      Config.gkParameters.JsonParameters = '';

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         return Number(response);
      });
    }

    translateToIWorkCenter(data: any): IWorkCenter {
      const ret: IWorkCenter = null;
      if (data) {
        // data.state = RouterState.getState(<number>data.state);
        return WorkCenterUtils.toIWorkCenter(data);
      } else {
        return null;
      }
    }

    translateToIWorkCenters(data): IWorkCenter[] {
      const ret: IWorkCenter[] = [];
      if (data && data.length > 0) {
        for (const d of data){
            ret.push(this.translateToIWorkCenter(d));
        }
      }
      return ret;
    }


private handleError(error: Response) {
  return Observable.throw(error.statusText);
}


}






