import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import "rxjs/add/observable/of";
import { IEtchingInfo, IPart, PartTypeUtils, TraceTypeUtils } from '../models/part.model';
import { Http, Response } from '@angular/http';
import { Helper } from './helper.service';
import { UnitTypeUtils } from '../enums/unittype';
import { AlertService } from './alert.service';
import { GateKeeperService} from '../shared/gatekeeper/user/user.service';
import { Config } from '../shared/gatekeeper/config';
import { MethodType, MethodTypes } from '../enums/methodtype';
import 'rxjs/add/observable/throw';
import { INDEType_RetrieveSpecs, NDEType, INDEType_RetrieveInspectionParametersByGroup, NDEType_RetrieveAllPartNDEMappings, PartNDEMappingNew } from '../models/nde.model'


@Injectable()
export class PartServiceHttp {

  LS_PARTS_KEY = 'lsPartsKey';
  LS_PARTS_TIMESTAMP_KEY = 'lsPartTimeStampKey';
  LS_PARTS_RESET_TIMESTAMP_KEY = 'lsPartsResetTimestampKey';
  LS_PARTS_TIMEOUT_INTERVAL = 1000 * 60 * 5; // milliseconds
  LS_PARTS_RESET_INTERVAL = 1000 * 60 * 60 * 24 * 25; // retrieve all parts 

  private cachedParts: IPart[] = [];
  td: TDiff = new TDiff();

  constructor(private http: Http, private utils: Helper,
    // private auth: AuthService,
    private alert: AlertService,
    private gkauth: GateKeeperService,
    private Config: Config) {
    console.log('part.servicehttp.CONSTRUCTOR');
  }

set cacheResetTimeStamp(timestamp: number){
  console.log('parts.servicehttp.setCacheReset ' + new Date(timestamp).toLocaleString());
  localStorage.setItem(this.LS_PARTS_RESET_TIMESTAMP_KEY, timestamp.toString());
}

get cacheResetTimeStamp(): number{
  const t =  +localStorage.getItem(this.LS_PARTS_RESET_TIMESTAMP_KEY);
  console.log('part.servicehttp.getCacheResetTime ' + new Date(t).toLocaleString());
  return t;
}

set cacheTimeStamp(timestamp: number){
  console.log('parts.servicehttp.setCacheTime timeStamp:' + new Date(timestamp).toString() + ' timeStampLocal:' + new Date(timestamp).toLocaleString());
  localStorage.setItem(this.LS_PARTS_TIMESTAMP_KEY, timestamp.toString());
}

get cacheTimeStamp(): number{
  const t =  +localStorage.getItem(this.LS_PARTS_TIMESTAMP_KEY);
  console.log('part.servicehttp.getCacheTime timeStamp:' + new Date(t).toString() + ' timeStampLocal:' + new Date(t).toLocaleString());
  return t;
}

get isCacheExpired(): boolean {
  const ret = (Date.now() - this.cacheTimeStamp > this.LS_PARTS_TIMEOUT_INTERVAL);
  console.log('part.servicehttp.isCacheExpired:' + (ret ? 'YES' : 'NO'));
  return ret;
}

get shouldResetCache(): boolean {
  const ret = (Date.now() - this.cacheResetTimeStamp > this.LS_PARTS_RESET_INTERVAL);
  console.log('part.servicehttp.shouldResetCache:' + (ret ? 'YES' : 'NO'));
  return ret;
}

setLocalStorage(key: string, data: any[]) {
  console.log('part.servicehttp.setLocalStorage length:' + (!this.utils.isEmptyArray(data) ? data.length : 'NULL'));
 // this.td.mark();
  localStorage.setItem(key, JSON.stringify(data));
  //this.td.mark('SET local storage key:' + key);
}

getLocalStorage(key: string): IPart[] {
  console.log('part.servicehttp.setLocalStorage');
  //this.td.mark();
  const ret = <IPart[]>JSON.parse(localStorage.getItem(key));
  //this.td.mark('GET local storage key:' + key);
  //console.log(ret);
  return ret;
}

getPart(id: string ): Observable<IPart> {
  console.log('ProgramServiceHttp.getPart');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/' + id;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const ret =  this.translateToIPart(response);
     return ret;
  });
}

/* getPartInfo(part: IPart ): Observable<IPart> {
  console.log('ProgramServiceHttp.getPart');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/' + part.id;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const ret =  this.translateToIPart(response);
     return ret;
  });
} */

getPartsAppPartAssociations(fileID: string ): Observable<IPart[]> {
  console.log('PartServiceHttp.getPartsAppPartAssociations');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/GetPartsAppPartAssociations/' + fileID;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
    const parts: IPart[] = this.translateToIParts(response);
     return parts;
  });
}

getPartByNumber(partNumber: string ): Observable<IPart> {
  console.log('ProgramServiceHttp.getPartByNumber');
  if (this.utils.isUndefined(partNumber))
    partNumber = '';

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/ByNumber//' + partNumber + '/';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const ret =  this.translateToIPart(response);
     return ret;
  });
}

private getPartIds(parts: IPart[]): string[] {
  const ret = [];
  if (!this.utils.isEmptyArray(parts)) {
    for (const p of parts) {
      ret.push(p.id);
    }
  }
  return ret;
}

private cachePartsResponse(parts: IPart[], replaceCache: boolean = false): IPart[] {
  console.log('part.servicehttp.cachePartsResponse START data.length:' +
   (!this.utils.isEmptyArray(parts) ? parts.length : 'NULL'));

   if (!this.utils.isEmptyArray(parts)) {
    if (replaceCache) {
      this.cachedParts = parts;
    } else {
      const partIds = this.getPartIds(parts);
      this.cachedParts = this.getLocalStorage(this.LS_PARTS_KEY) || [];

       console.log('...from storage cachedParts.length:' +
   (!this.utils.isEmptyArray(this.cachedParts) ? this.cachedParts.length : 0));
      this.cachedParts = this.cachedParts.filter(x => !partIds.includes(x.id) );
      console.log('...filtered cachedParts.length:' +
      (!this.utils.isEmptyArray(this.cachedParts) ? this.cachedParts.length : 0));
      this.cachedParts = this.cachedParts.concat(parts);
      console.log('...to storage cachedParts.length:' +
      (!this.utils.isEmptyArray(this.cachedParts) ? this.cachedParts.length : 0));
    }

    this.setLocalStorage(this.LS_PARTS_KEY, this.cachedParts);
  }

  this.cacheTimeStamp = Date.now();

  console.log('part.servicehttp.cachePartsResponse END cachedParts.length:' +
   (!this.utils.arrayOrEmpty(this.cachedParts) ? this.cachedParts.length : 'NULL'));

  return this.cachedParts;
}



 private retrieveAllParts(): Observable<IPart[]> {
  console.log('part.servicehttp.retrieveAllParts');
  //const t1 = performance.now();
  // this.alert.show('Loading application data ...');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
    this.alert.hide();
    //const t2 = performance.now();
    //TDiff.log('http.get.GetAllParts', t1, t2);
    // guard against race condition whereby multiple async responses trying to set cache. Assumes single client process.
    if (this.shouldResetCache) {
      this.cachedParts = [];
      this.cacheResetTimeStamp = Date.now();
      const parts: IPart[] = this.translateToIParts(response);
      this.cachedParts = this.cachePartsResponse(parts, true);
    }
    console.log('part.servicehttp.retrieveAllParts cachedParts.length:' +
      (!this.utils.isEmptyArray(this.cachedParts) ? this.cachedParts.length : 'null'));
    return this.cachedParts;
  });
 }

private retreiveUpdatedPartsSinceLastCached(): Observable<IPart[]> {
  console.log('part.servicehttp.retreiveUpdatedPartsSinceLastCached');
  const info = {
    sinceDate: new Date(this.cacheTimeStamp).toLocaleString()
  };
  //const t1 = performance.now();
  // this.alert.show('Loading application data ...');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/GetPartsSinceDate/';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);
  
  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     this.alert.hide();
    //const t2 = performance.now();
    //TDiff.log('http.put.GetPartsSinceDate', t1, t2);
     // again check expired again to guard against race condition whereby redundant calls to retieve parts
  // try to upate cache. Only one should. Checking like this assumes one active client process at a time.
    if (this.isCacheExpired) {
      const parts: IPart[] = this.translateToIParts(response);
      console.log('part.servicehttp.retreiveUpdatedPartsSinceLastCached returned length:' + (parts != null ? parts.length : 'null'));
      this.cachedParts = this.cachePartsResponse(parts);
    }
    console.log('part.servicehttp.retreiveUpdatedPartsSinceLastCached cachedParts.length:' +
    (!this.utils.isEmptyArray(this.cachedParts) ? this.cachedParts.length : 'null'));
    return this.cachedParts;
 });
}


  getParts(): Observable<IPart[]> {
    console.log('part.servicehttp.getParts START');

    if (this.shouldResetCache) {
      console.log('..cache reset.');
      return this.retrieveAllParts();
    }

    if (this.isCacheExpired) {
      console.log('..cache expired...retrieving parts since last cached');
      return this.retreiveUpdatedPartsSinceLastCached();
    }

    if (this.utils.isEmptyArray(this.cachedParts)) {
        console.log('...memory cache empty...retrieving from local storage');
        this.cachedParts = this.getLocalStorage(this.LS_PARTS_KEY);

    }
      console.log('part.servicehttp.getParts >>> returning cached parts length ' +
      (this.cachedParts != null ? this.cachedParts.length : 'null'));

    return Observable.of(this.cachedParts);

  }

  updatePart(part: IPart): Observable<IPart> {
    console.log('part.servicehttp.updatePart');

    const info = {
      partID: part.id,
      partNumber: part.number,
      description: part.description,
      traceType: TraceTypeUtils.toTraceTypeID(part.trace),
      unitType: UnitTypeUtils.toUnitTypeID(part.unit),
      type: PartTypeUtils.toPartTypeID(part.type),
      fGLot: part.fGLot
    };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
     
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const ret =  this.translateToIPart(response);
      return ret;
    });
  }

    createEtchingInfo(etchingInfo: IEtchingInfo): Observable<IEtchingInfo> {
      console.log('ProgramServiceHttp.createEtchingInfo');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/CreateEtchingInfo/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(etchingInfo);
     
  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        // console.log(response);
         const ret = this.translateToIEtchingInfo(response);
         //console.log(ret);
         return ret;
      });
    }

    updateEtchingInfo(etchingInfo: IEtchingInfo): Observable<IEtchingInfo> {
      console.log('PartServiceHttp.updateEtchingInfo');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/UpdateEtchingInfo';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(etchingInfo);
     
  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         //console.log(response);
         const ret = this.translateToIEtchingInfo(response);
         //console.log(ret);
         return ret;
      });
    }

    getEtchingInfo(partId: string): Observable<IEtchingInfo> {
      console.log('PartServiceHttp.getEtchingInfo');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/GetEtchingInfoForPart/' + partId;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         //console.log(response);
         const ret = this.translateToIEtchingInfo(response);
         //console.log(ret);
         return ret;
      });
    }

    CreatePartNDEMapping(partNDEMappingNew: PartNDEMappingNew): Observable<number> {
      //console.log('PartServiceHttp.CreatePartNDEMapping');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/CreatePartNDEMapping/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(partNDEMappingNew);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {         
        return Number(response);
      });
    }

    RemovePartNDEMapping(id: string): Observable<number> {
      //console.log('RouterServiceHttp.RemovePartNDEMapping');
  
      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/PartNDEMapping/' + id;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.DELETE);
      Config.gkParameters.JsonParameters = '';
  
      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         return Number(response);
      });
    }

    GetPartNDERequired(partID: string): Observable<boolean> {
      //console.log('PartServiceHttp.GetPartNDERequired');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/GetPartNDERequired/' + partID;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {         
         const ret = this.translateToPartNDERequired(response);         
         return ret;
      });
    }

    getSpecsByNDEType(typeId: number): Observable<INDEType_RetrieveSpecs[]> {
      //console.log('PartServiceHttp.GetSpecsByNDEType');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/GetSpecsByNDEType/' + typeId;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {         
         const ret = this.translateToSpecs(response);         
         return ret;
      });
    }

    GetGroupsBySpec(ndeType: NDEType): Observable<number[]>
    {
      //console.log('PartServiceHttp.GetGroupsBySpec');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/GetGroupsBySpec/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(ndeType);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {         
         const ret = this.translateToGroups(response);         
         return ret;
      });
    }

    GetInspectionParametersByGroup(ndeType: NDEType): Observable<INDEType_RetrieveInspectionParametersByGroup[]> {
      //console.log('PartServiceHttp.GetInspectionParametersByGroup');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/GetInspectionParametersByGroup/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(ndeType);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {         
         const ret = this.translateToInspectionParameters(response);         
         return ret;
      });
    }

    GetPartNDEMappings(partID: string): Observable<NDEType_RetrieveAllPartNDEMappings[]> {
      //console.log('PartServiceHttp.GetSpecsByNDEType');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'parts/GetPartNDEMappings/' + partID;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {         
         const ret = this.translateToPartNDEMappings(response);         
         return ret;
      });
    }

    translateToIPart(data: any): IPart {
     // console.log('part.servicehttp.translateToIPart');
      // console.log(data);
      var ret:IPart = null;

      if (!this.utils.isUndefined(data))
      {
        data.unit = UnitTypeUtils.toUnitType(data.unit);
        data.trace = TraceTypeUtils.toTraceType(data.trace);
        data.type = PartTypeUtils.toPartType(data.type);
        data.name = data.number + ' ' +  data.description;
        ret = <IPart> data;
      }

       // console.log('part.servicehttp.translateToIPart returns');
     return ret;
    }

    translateToIParts(data): IPart[] {
      // console.log('part-servicehttp.translateToIParts');

      const ret: IPart[] = [];
      if (!this.utils.isEmptyArray(data)) {
        for (const d of data){
            ret.push(this.translateToIPart(d));
        }
      }
      return ret;
    }

    translateToIEtchingInfo(data: any): IEtchingInfo {
      const ret: IEtchingInfo = null;
      if (data) {
        // data.state = RouterState.getState(<number>data.state);
        return <IEtchingInfo> data;
      } else {
        return null;
      }
    }

    translateToIEtchingInfos(data): IEtchingInfo[] {
      const ret: IEtchingInfo[] = [];
      if (data && data.length > 0) {
        for (const d of data){
            ret.push(this.translateToIEtchingInfo(d));
        }
      }
      return ret;
    }

    //translateToPartNDERequired
    translateToPartNDERequired(data: any): boolean {
      if (data) {
        return <boolean> data;
      } else {
        return null;
      }
    }

    translateToSpec(data: any): INDEType_RetrieveSpecs {
      if (data) {
        return <INDEType_RetrieveSpecs> data;
      } else {
        return null;
      }
    }

    translateToSpecs(data: any): INDEType_RetrieveSpecs[] {
      const ret: INDEType_RetrieveSpecs[] = [];
      if (data && data.length > 0) {
        for (const d of data){
            ret.push(this.translateToSpec(d));
        }
      }
      return ret;
    }

    translateToPartNDEMapping(data: any): NDEType_RetrieveAllPartNDEMappings {
      if (data) {
        return <NDEType_RetrieveAllPartNDEMappings> data;
      } else {
        return null;
      }
    }

    translateToPartNDEMappings(data: any): NDEType_RetrieveAllPartNDEMappings[] {
      const ret: NDEType_RetrieveAllPartNDEMappings[] = [];
      if (data && data.length > 0) {
        for (const d of data){
            ret.push(this.translateToPartNDEMapping(d));
        }
      }
      return ret;
    }

    translateToInspectionParameter(data: any): INDEType_RetrieveInspectionParametersByGroup {
      if (data) {
        return <INDEType_RetrieveInspectionParametersByGroup> data;
      } else {
        return null;
      }
    }

    translateToInspectionParameters(data: any): INDEType_RetrieveInspectionParametersByGroup[] {
      const ret: INDEType_RetrieveInspectionParametersByGroup[] = [];
      if (data && data.length > 0) {
        for (const d of data){
            ret.push(this.translateToInspectionParameter(d));
        }
      }
      return ret;
    }    

    translateToGroups(data: any): number[]
    {
      const ret: number[] = [];
      if (data && data.length > 0) {
        for (const d of data){
            ret.push(d);
        }
      }
      return ret;
    }


  private handleError(error: Response) {
    console.log('part.servicehttp.handleError ' + error.statusText);
    return Observable.throw(error.statusText);
  }


}


export class TDiff {
  private prev = 0;
  private cur = 0;

  static log(msg: string, t1: number, t2: number) {
    console.log('CLOCK: ' + msg + ':' + (t2 - t1) + ' milliseconds');
  }

  constructor() { }

  mark(msg: string = null) {
    this.prev = this.cur;
    this.cur = Date.now();
    if (msg != null) {
      console.log('CLOCK: ' + msg + ':' + (this.cur - this.prev) + ' milliseconds');
    }
  }
}






