import { Component, DoCheck, OnChanges, OnInit, AfterContentInit, AfterViewChecked, AfterViewInit, ViewChild, ElementRef, OnDestroy, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
import { Helper } from '../../core/services/helper.service';
import { IWorkCenterToolKit } from '../../core/models/tool.model';
import { ToolServiceHttp } from '../../core/services/tool.servicehttp';
import { AlertService } from '../../core/services/alert.service';
import { AlertLevel, AlertReponse } from '../../core/models/alert.model';
import { WorkCenterToolKitDomainService } from '../../core/services/workCenterToolKit-domain.service';

@Component({
  selector: 'tool-kit',
  template: require('./tool-kit.component.html'),
  // styles: [require('./tool-kit.component.css')]
  styles: [require('./tool-kit.component.css').toString()]
})
export class ToolKitComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy, AfterContentChecked, AfterContentInit {
  term: string;
  dir ='asc';

  public get wcToolKits(): IWorkCenterToolKit[] {
    return this.domain.workCenterToolKits;
  }

  public set wcToolKits(val: IWorkCenterToolKit[]) {
    this.domain.workCenterToolKits = val;
  }

  overlayWasVisible = false;
  @ViewChild('table', {static: true}) table: ElementRef;

  constructor(private navRouter: Router, private toolService: ToolServiceHttp, private utils: Helper
  , private alert: AlertService
  , private domain: WorkCenterToolKitDomainService) { }

  ngOnInit() {
    console.log('tool-kit.component.ngOnInit');
    this.toolService.getWorkCenterToolKits().subscribe(toolKits => {
      this.wcToolKits = toolKits;
      this.wcToolKits.sort(this.sortWarehouseWorkCenterDescriptionKitDescription);
    });
  }

  sortWarehouseWorkCenterDescriptionKitDescription(a: IWorkCenterToolKit, b: IWorkCenterToolKit) {
    let ret = Helper.cmpStringAlphaNumeric(a.workCenterWarehouse, b.workCenterWarehouse);
    if (ret === 0) {
      ret = Helper.cmpStringAlphaNumeric(a.workCenterDescription, b.workCenterDescription );
    }
    if (ret === 0) {
      ret = Helper.cmpStringAlphaNumeric(a.description, b.description);
    }
    return ret;
  }

  ngOnDestroy() {
    console.log('tool-kit.component.ngOnDestroy');
  }

  get isOverlayVisible() {
    return false; // this.reasonCodeForm.nativeElement.offsetParent != null;
  }

  ngAfterContentChecked() {
    console.log('tool-kit.component.ngAfterContentChecked');
    if (!this.isOverlayVisible && this.overlayWasVisible) {
      console.log('tool-kit.component.ngAfterContentChecked...visible to hidden ');
      this.overlayWasVisible = false;
      // this.resetFormValidation();
    } else if (this.isOverlayVisible && !this.overlayWasVisible) {
      console.log('tool-kit.component.ngAfterContentChecked...hidden to visible');
      this.overlayWasVisible = true;
      // this.userSubmitted = false;
      // this.resetFormValidation();
    }
  }

  addToolKit() {
    this.navRouter.navigate(['/toolKits/new']);
  }


  editToolKit(kit: IWorkCenterToolKit) {
    console.log('tool-kit.components.editToolKit');
    console.log(kit);
    this.navRouter.navigate(['/toolKits', kit.id]);
  }

  deleteToolKit(kit: IWorkCenterToolKit) {
    this.alert.showYesNo('Are you sure you want to delete work center tool kit ' + kit.description + '?',
    AlertLevel.WARNING,
    (ar: AlertReponse) => {
      console.log(ar);
      console.log(ar === AlertReponse.OK);
      console.log(AlertReponse.OK);
      if (ar === AlertReponse.YES) {
        this.toolService.deleteWorkCenterToolKit(kit.id).subscribe( res => {
          this.wcToolKits = this.wcToolKits.filter(x => x.id !== kit.id) || [];
       });
      }
    });
  }

  sortTable(n, event) {
    var elementId: string = (event.target as Element).id;
   
  
    if (elementId.substring(0,5) == 'kcol1' && n == 0)
    {    
      if (this.dir == 'asc')
      {
        this.dir = 'desc'
        this.wcToolKits.sort(this.sortByWarehouseAsc)
      }
      else 
      {
        this.dir = 'asc'
        this.wcToolKits.sort(this.sortByWarehouseDesc)
      }      
    }
    else if (elementId.substring(0,5) == 'kcol2' && n == 1) 
    {
      if (this.dir == 'asc')
      {
        this.dir = 'desc'
        this.wcToolKits.sort(this.sortByWorkCenterAsc)
      }
      else 
      {
        this.dir = 'asc'
        this.wcToolKits.sort(this.sortByWorkCenterDesc)
      }
    }
    else if (elementId.substring(0,5) == 'kcol3' && n == 2) 
    {
      if (this.dir == 'asc')
      {
        this.dir = 'desc'
        this.wcToolKits.sort(this.sortByDescriptionAsc)
      }
      else 
      {
        this.dir = 'asc'
        this.wcToolKits.sort(this.sortByDescriptionDesc)
      }
    }
    
  }
  
  
  sortByWarehouseAsc(a1: IWorkCenterToolKit, a2: IWorkCenterToolKit) { 
    return Helper.cmpString(a1.workCenterWarehouse, a2.workCenterWarehouse); 
  }
  
  sortByWarehouseDesc(a1: IWorkCenterToolKit, a2: IWorkCenterToolKit) { 
    return Helper.cmpStringDesc(a1.workCenterWarehouse, a2.workCenterWarehouse); 
  }
    
  sortByWorkCenterAsc(a1: IWorkCenterToolKit, a2: IWorkCenterToolKit) { 
    return Helper.cmpString(a1.workCenterDescription, a2.workCenterDescription); 
  }
  
  sortByWorkCenterDesc(a1: IWorkCenterToolKit, a2: IWorkCenterToolKit) { 
    return Helper.cmpStringDesc(a1.workCenterDescription, a2.workCenterDescription); 
  }
  
  sortByDescriptionAsc(a1: IWorkCenterToolKit, a2: IWorkCenterToolKit) { 
    return Helper.cmpString(a1.description, a2.description); 
  }
  
  sortByDescriptionDesc(a1: IWorkCenterToolKit, a2: IWorkCenterToolKit) {
    return Helper.cmpStringDesc(a1.description, a2.description); 
  }


  ngAfterViewChecked() {
    // console.log('tools.component.AfterViewChecked');
     let c1 = $('.kcol1').width();
     let c2 = $('.kcol2').width();
     let c3 = $('.kcol3').width();     
     let th = $('#KtableHeader').width();

     // console.log(c1);
     $('#kcol1').width(c1);
     $('#kcol2').width(c2);
     $('#kcol3').width(c3);     
     $('.KSearchBar').width(c1 + c2 + c3 + 11);
     $('#Cover').width(th);
   }

   ngAfterViewInit() 
   {
      // console.log('File.Component.AfterViewInit');
      // runs every time the user re-sizes the window
      $(window).resize(function(){
     // gets td column widths
      let c1 = $('.kcol1').width();
      let c2 = $('.kcol2').width();
      let c3 = $('.kcol3').width();            
      let th = $('#KtableHeader').width();
      // sets th column widths to match td's
      $('#Cover').width(th + 6);
      $('#kcol1').width(c1);
      $('#kcol2').width(c2);
      $('#kcol3').width(c3);           
      $('.KSearchBar').width(c1 + c2 + c3 + 11);
      console.log ('resize header occured');
      });
    }

    ngAfterContentInit()
   {
      const c1 = $('.kcol1').width();
      const c2 = $('.kcol2').width();
      const c3 = $('.kcol3').width();
      let th = $('#KtableHeader').width();
 
      $('#Cover').width(th + 6);
      $('#kcol1').width(c1);
      $('#kcol2').width(c2);
      $('#kcol3').width(c3);

      $('.KSearchBar').width(c1 + c2 + c3 + 11); 
   }

}
