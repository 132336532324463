import { ReportsComponentBizLogic } from '../reports/reports.component.bizl';
import { Component, OnChanges, OnInit, DoCheck, AfterContentChecked, AfterContentInit, AfterViewChecked, AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl, NgModel, Validators } from '@angular/forms';
import { IReport } from '../core/models/report.model';
import { Helper } from '../core/services/helper.service';
import { AlertService } from '../core/services/alert.service';
import { ReportServiceHttp } from '../core/services/report.servicehttp';
import { Router } from '@angular/router';
import { SortCriteria} from '../core/models/sort.model';

// imports for using Angular Material CDK
// import { CdkScrollable, ScrollDispatcher, ScrollDispatchModule } from '@angular/cdk/scrolling';
// import { MatSort, MatSortable, MatTable, MatTableDataSource, MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material';
// import { MatIconRegistry} from '@angular/material';
// import { DomSanitizer} from '@angular/platform-browser';

/** Custom options the configure the tooltip's default show/hide delays. */
//export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
//  showDelay: 300,
//  hideDelay: 300,
//  touchendHideDelay: 300,
//};


@Component({
  selector: 'app-reports',
  template: require('./reports.component.html'), 
  styles: [require('./reports.component.css')],
  providers: 
  []
    
})
export class ReportsComponent implements OnInit, AfterViewInit, AfterViewChecked, AfterContentChecked, OnDestroy, AfterContentInit {
  reports: IReport[];
  ReportFormGroup: FormGroup;
  ReportDescription: FormControl;
  ReportName: FormControl;
  selectedReport: IReport;
  public term: string;
  bizl: ReportsComponentBizLogic = null;
  userSubmitted = false;
  overlayWasVisible = false;
  dir ="asc";  
  @ViewChild('reportForm', {static: true}) reportForm: ElementRef;  
  @ViewChild('inputName', {static: true}) inputName;

  
  
  
  isLoading = true;

  constructor(
    private reportService: ReportServiceHttp,
    private alert: AlertService,
    private navRouter: Router,
    private utils: Helper,
    //private scroll: ScrollDispatcher,
    ) { }


  ngOnDestroy() {
      console.log('reports.component.ngOnDestroy');
      this.resetFormValidation();
    }

  ngOnInit() {
    console.log('reports.component.ngOnInit');
    this.bizl = new ReportsComponentBizLogic(this);
    this.userSubmitted = false;    

    // this.ReportFormGroup = new FormGroup({
    //   name: this.ReportName,
    //   description: this.ReportDescription
    // });


    this.reportService.getReports().subscribe(reports => {
      console.log('reports.component- Get Reports call to report service');
      //this.isLoading = false;
      this.reports = reports;
    })

   

    }

  onSorted(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'name')
          {           
           this.reports.sort(this.sortByNameDesc)  
          }
          else 
          {
            this.reports.sort(this.sortByDescriptionDesc)
          }
          
        }
        else {
          if(criteria.sortColumn == 'name')
          {
            this.reports.sort(this.sortByNameAsc)
          }
          else 
          {
            this.reports.sort(this.sortByDescriptionAsc)
          }
        }
      }
      
  }



//   sortTable(n, event) {
//   var elementId: string = (event.target as Element).id;
 

//   if (elementId.substring(0,4) == 'col1' && n == 0)
//   {    
//     if (this.dir == 'asc')
//     {
//       this.dir = 'desc'
//       this.reports.sort(this.sortByNameAsc)
//     }
//     else 
//     {
//       this.dir = 'asc'
//       this.reports.sort(this.sortByNameDesc)
//     }
    
//   }
//   if (elementId.substring(0,4) == 'col2' && n == 1)
//   if (this.dir == 'asc')
//     {
//       this.dir = 'desc'
//       this.reports.sort(this.sortByDescriptionAsc)
//     }
//     else 
//     {
//       this.dir = 'asc'
//       this.reports.sort(this.sortByDescriptionDesc)
//     }
// }


sortByNameAsc(a1: IReport, a2: IReport){ 
  return Helper.cmpString(a1.name, a2.name); 
 // return Helper.cmpStringAlphaNumeric(a1.number, a2.number); 
}

sortByNameDesc(a1: IReport, a2: IReport) { 
  return Helper.cmpStringDesc(a1.name, a2.name); 
}

sortByDescriptionAsc(a1: IReport, a2: IReport) { 
  return Helper.cmpString(a1.description, a2.description); 
}

sortByDescriptionDesc(a1: IReport, a2: IReport) {
  return Helper.cmpStringDesc(a1.description, a2.description); 
}

viewReport(report: IReport) {
  //console.log('Route to report that was requested----*****************************************************************');
  //this.navRouter.navigate(['/reports/DemandItemNoBOM']);//, report.name]) ;

  if (report.name == 'Demand No BOM')
  {
    this.navRouter.navigate(['/reports/demanditemnobom']);
  }
  else if (report.name == 'Demand No Drawing')
  {
    this.navRouter.navigate(['/reports/demanditemnodrawing']);
  }
  else if (report.name == 'Demand No Router')
  {
    this.navRouter.navigate(['/reports/demanditemnorouter']);
  }
  else if (report.name == 'Demand Router On Hold')
  {
    this.navRouter.navigate(['/reports/demanditemrouteronhold']);
  }  
 }

get isOverlayVisible() {
  return this.reportForm.nativeElement.offsetParent != null;
}

ngAfterContentChecked() {
  // if (!this.isOverlayVisible && this.overlayWasVisible){
  //   console.log('reports.component.ngAfterContentChecked...visible to hidden ');
  //   this.overlayWasVisible = false;
  //   this.resetFormValidation();
  // } else if (this.isOverlayVisible && !this.overlayWasVisible){
  //   console.log('reports.component.ngAfterContentChecked...hidden to visible');
  //   this.overlayWasVisible = true;
  //   this.userSubmitted = false;
  //   this.resetFormValidation();
  // }
}

  toFormGroup(report: IReport) {
    this.ReportFormGroup.patchValue({
        name: report.name,
        description: report.description
    });

    this.ReportFormGroup.controls.description.enable();
  }

    fromFormGroup(info): IReport {
    return {
      id : this.utils.isExistingItem(this.selectedReport) ? this.selectedReport.id : null,
      name : info.name,
      description: info.description,
      inUse: this.utils.isExistingItem(this.selectedReport) ? this.selectedReport.inUse : false,
    };
  }




getTitle() {
  if (this.bizl.isNewReport) {
    return 'Add a Report';
  } else {
    return 'Edit a Report';
  }
}


resetFormValidation() {
  // this.userSubmitted = false;
  // // yourForm.reset(), yourForm.resetForm() don't work, but this does:
  // this.ReportFormGroup.markAsPristine();
  // this.ReportFormGroup.markAsUntouched();
  // this.ReportFormGroup.updateValueAndValidity();
}




 submit(formValues) {
   console.log('reports.components.submit');
   console.log(this.ReportFormGroup);
   console.log(this.bizl);

  //  let timer:NodeJS.Timer;

  //  this.userSubmitted = true;
   
  //  const p = this.fromFormGroup(formValues);
  //  console.log(p);

 }

  

  

////alert-action component use Start
  alertType = '';
// expects "name" of alert from AlertService @alert-action.component
  openError(alertName: string) {
    document.getElementById('ErrorAction').style.display = 'block';
    const modal = document.getElementById('ErrorAction');
    this.alertType = alertName;
// When the user clicks anywhere outside of the modal, this closes it
    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = 'none';
}
    };
  }
  userResponse(data) {
    console.log('Action Was', data);
  }
////alert-action Component End
ngAfterViewChecked() {
  const c1 = $('.col1').width();
  const c2 = $('.col2').width();
  $('#col1').width(c1 + 1);
  $('#col2').width(c2);
  $('.SearchBar').width(c1 + c2 + 2);

  var th = $('thead').width();
  $("#Cover").width(th);

 // $('#number').focus();
}
ngAfterViewInit() {
      console.log('Report.Component.AfterViewInit');      
      
      // runs every time the user re-sizes the window
          $(window).resize(function(){
        // gets td column widths
          const c1 = $('.col1').width();
          const c2 = $('.col2').width();
          const th = $('thead').width();
        // sets th column widths to match td's
          $("#Cover").width(th+1);
          $('#col1').width(c1 + 1);
          $('#col2').width(c2);
          $('.SearchBar').width(c1 + c2 + 2);
          console.log ('resize header occured');
          });
    }
    ngAfterContentInit() {
      // $(document).ready(function(){
      //   $('.SearchField').focus();
      // })
    }

}
