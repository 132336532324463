import {Injectable} from '@angular/core';
import { IGateKeeperParameters} from './user/gatekeeperparameters.model';
import { MethodType, MethodTypes} from '../../enums/methodtype';

@Injectable()
export class Config {
  // SSL site for gatekeeper
  static gateKeeperUrl = 'https://gatekeeper.diwmsiapps.com/'; 
  //static gateKeeperUrl = 'https://gatekeeper-dev.diwmsiapps.com/';  
  //static gateKeeperUrl = 'https://gatekeeper-test.diwmsiapps.com/';  
  static onshapeUrl = "https://diwmsi.onshape.com";
  //static onshapeAccessKey ='dtrZ8txspnPILKG96O9ibvkW';
  //static onshapeSecretKey = 'eXqw8L3pCJGiIOOXzvkKeBmxVuCjOz002NXfWryvNci5Zys9';
  
  static AppVersion = "1.19.31";
  static DeviceID = 'nomudaMaint_' +  Config.AppVersion;

  static token = '';

  // setGKParameters(methodType:string, uriDestination:string, jsonParameters:string) {
  //  if (methodType == "POST")
  //  this.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  //  this.gkParameters.JsonParameters = jsonParameters;
  //  this.gkParameters.UriDestination = uriDestination;
  //  return
  // }

  // URI for SSL site.
  //  GateKeeperUri: 'https://gatekeeper-test.diwmsiapps.com/api/',

  public static gkParameters: IGateKeeperParameters = {
    TokenID : '',
    DeviceID: Config.DeviceID,
    MethodType: new MethodType(MethodTypes.POST),
    UriDestination: '',
    JsonParameters: '',
    GateKeeperUri: Config.gateKeeperUrl +  'api/',
    BaseURI: 'usa',
    ApplicationName: 'NoMuda'
  };

}
