import { Observable } from 'rxjs';
import { Component, NgZone, OnInit, ViewChild, ElementRef,  AfterContentChecked, AfterViewInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { WorkCenterDataService } from '../../core/services/workcenter-data.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IWorkCenter, ScheduleTypeUtils, WorkCenterTypeUtils, WorkCenterUtils } from '../../core/models/workcenter.model';
import { UserMaintenanceDataService } from '../../core/services/usermaintenance-data.service';
import { Helper } from '../../core/services/helper.service';
import { WorkStationService } from '../../core/services/workstation.service';
import { UserMaintenanceServiceHttp} from '../../core/services/usermaintenance.servicehttp';
import { IWorkStation, WorkStationType } from '../../core/models/workstation.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserInfoComponent } from './user-info.component';
import { UserDetailComponentBizLogic } from './user-detail.component.bizl';
import { AlertLevel, AlertReponse } from '../../core/models/alert.model';
import { AlertService } from '../../core/services/alert.service';
import { IUserMaintenance, IAuthorizedWorkStation } from '../../core/models/usermaintenance.model';
import { SortCriteria} from '../../core/models/sort.model';
import {bindCallback} from 'rxjs/observable/bindCallback';


@Component({
  selector: 'user-detail',
  template: require('./user-detail.component.html'),
  styles: [require('./user-detail.component.css').toString()]
})
export class UserDetailComponent implements OnInit, AfterContentChecked, AfterViewInit, AfterViewChecked, OnDestroy {
 UserDetails: FormGroup;
 UserDetailFormGroup: FormGroup;
 name: FormControl;
 employee: FormControl;
 warehouse: FormControl;
 defaultWorkStation: FormControl;
 @ViewChild('userDetails', {static: true}) view: ElementRef;
 @ViewChild('userInfo', {static: true}) info: UserInfoComponent;
 @ViewChild('searchInputAuthorized', {static: false}) searchInputAuthorized: ElementRef;
 @ViewChild('searchInputAvailable', {static: false}) searchInputAvailable: ElementRef;
 viewWasVisible = false;
 workStations: IAuthorizedWorkStation[];
 selectedStationArray: IAuthorizedWorkStation[] = [];
 workStationsAvailable: IAuthorizedWorkStation[];
 userId: string;
 userSubmittedWorkStation = false;
 bizl: UserDetailComponentBizLogic;
 lastWorkStationDescriptionValidationMsg = '';
 isWorkStationSelected: boolean = false;
 dir = 'asc';
 selectedRow: Number;
 public term: string;
 
 get selectedUser(){
  return this.data.selectedUser;
  }

 get selectedWorkStation(){
   return this.data.selectedWorkStation;
 }

  constructor(public data: UserMaintenanceDataService
  , private utils: Helper
  , private userMaintenanceService: UserMaintenanceServiceHttp
  , private navRouter: Router
  , private alert: AlertService
  , private zone: NgZone
  , private route: ActivatedRoute ) { }


  ngOnInit() {
    console.log('user-details.component.ngOnInit');
    this.bizl = new UserDetailComponentBizLogic(this);
    this.userId = this.route.snapshot.params['id'];
    this.UserDetailFormGroup = new FormGroup({
      number: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
    });
  } 
   

  //   updateWorkStationFormGroup(u: IUserMaintenance) {
  //   this.UserDetailFormGroup.patchValue({
  //     name: u.userName,
  //     employee: u.employeeID,
  //     warehouse: u.defaultWarehouse,
  //     workstation: u.workstationDescription
  //     //defaultWarehouse: u.defaultWarehouse,
  //     //defaultWorkStation: u.workstationDescription
  //   });

  //     if (this.bizl.isWorkStationEditable) {
  //      this.UserDetailFormGroup.enable();
  //   } else {
  //      this.UserDetailFormGroup.disable();
  //   }
  // }

  

 addAuthorizedWorkStation() {
     this.getAvailableWorkStations();
     this.overlayOpen();

 }

 getAvailableWorkStations() {
    this.userMaintenanceService.getAvailableWorkStations(this.selectedUser.manufacturingUserId).subscribe( stations => {
    this.workStationsAvailable  = stations;
    this.workStationsAvailable.sort(this.sortByWorkCenterDescriptionAsc)
    this.viewWasVisible = true;
  });
 }

  // editWorkStation(ws: IAuthorizedWorkStation) {
  //   this.overlayOpen(ws);
  // }

 deleteWorkStation(ws: IAuthorizedWorkStation) {
   this.alert.showYesNo('Are you sure you want to delete work station ' + ws.workStationNumber + ws.description   + '?',
   AlertLevel.WARNING,
   (ar: AlertReponse) => {
      if (ar === AlertReponse.YES) {
        this.userMaintenanceService.removeAuthorizedWorkStation(this.selectedUser.manufacturingUserId, ws.workStationId).subscribe(ret => {
        this.workStations = this.workStations.filter(x => x.workStationId !== ws.workStationId);
      });
     }
   });
 }

get workStationInfo() {
  return this.fromUserDetailForm();
}

get userInfo() {
  return this.info.fromFormValues();
}


onSorted(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'workCenterDescription')
          {
            this.workStations.sort(this.sortByWorkCenterDescriptionDesc)
          }
          else if (criteria.sortColumn == 'workStationNumber')
          {
            this.workStations.sort(this.sortByWorkStationNumberDesc)
          }
          else if (criteria.sortColumn == 'description')
          {
            this.workStations.sort(this.sortByDescriptionDesc)
          }
        }
        else {
          if(criteria.sortColumn == 'workCenterDescription')
          {
            this.workStations.sort(this.sortByWorkCenterDescriptionAsc)
          }
          else if (criteria.sortColumn == 'workStationNumber')
          {
            this.workStations.sort(this.sortByWorkStationNumberAsc)
          }
          else if (criteria.sortColumn == 'description')
          {
            this.workStations.sort(this.sortByDescriptionAsc)
          }         
        }
      }
      
  } 

  onSortedAvailable(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'workCenterDescription')
          {
            this.workStationsAvailable.sort(this.sortByWorkCenterDescriptionDesc)
          }
          else if (criteria.sortColumn == 'workStationNumber')
          {
            this.workStationsAvailable.sort(this.sortByWorkStationNumberDesc)
          }
          else if (criteria.sortColumn == 'description')
          {
            this.workStationsAvailable.sort(this.sortByDescriptionDesc)
          }
        }
        else {
          if(criteria.sortColumn == 'workCenterDescription')
          {
            this.workStationsAvailable.sort(this.sortByWorkCenterDescriptionAsc)
          }
          else if (criteria.sortColumn == 'workStationNumber')
          {
            this.workStationsAvailable.sort(this.sortByWorkStationNumberAsc)
          }
          else if (criteria.sortColumn == 'description')
          {
            this.workStationsAvailable.sort(this.sortByDescriptionAsc)
          }         
        }
      }
      
  } 


// sortTable(n, event) {
//   var elementId: string = (event.target as Element).id;
 

//   if (elementId.substring(0,4) == 'col1' && n == 0)
//   {    
//     if (this.dir == 'asc')
//     {
//       this.dir = 'desc'
//       this.workStations.sort(this.sortByWorkCenterDescriptionAsc)
//     }
//     else 
//     {
//       this.dir = 'asc'
//       this.workStations.sort(this.sortByWorkCenterDescriptionDesc)
//     }
    
//   }
//   else if (elementId.substring(0,4) == 'col2' && n == 1)
//   {

  
//     if (this.dir == 'asc')
//       {
//         this.dir = 'desc'
//         this.workStations.sort(this.sortByWorkStationNumberAsc)
//       }
//     else 
//       {
//         this.dir = 'asc'
//         this.workStations.sort(this.sortByWorkStationNumberDesc)
//       }
//   }
//   else if (elementId.substring(0,4) == 'col3' && n == 2)
//   {
//     if (this.dir == 'asc')
//       {
//         this.dir = 'desc'
//         this.workStations.sort(this.sortByDescriptionAsc)
//       }
//     else 
//       {
//         this.dir = 'asc'
//         this.workStations.sort(this.sortByDescriptionDesc)
//       }
//     }
//   else if (elementId.substring(0,4) == 'col4' && n == 0)
//   {    
//     if (this.dir == 'asc')
//     {
//       this.dir = 'desc'
//       this.workStationsAvailable.sort(this.sortByWorkCenterDescriptionAsc)
//     }
//     else 
//     {
//       this.dir = 'asc'
//       this.workStationsAvailable.sort(this.sortByWorkCenterDescriptionDesc)
//     }
    
//   }
//   else if (elementId.substring(0,4) == 'col5' && n == 1)
//   {

  
//     if (this.dir == 'asc')
//       {
//         this.dir = 'desc'
//         this.workStationsAvailable.sort(this.sortByWorkStationNumberAsc)
//       }
//     else 
//       {
//         this.dir = 'asc'
//         this.workStationsAvailable.sort(this.sortByWorkStationNumberDesc)
//       }
//   }
//   else if (elementId.substring(0,4) == 'col6' && n == 2)
//   {
//     if (this.dir == 'asc')
//       {
//         this.dir = 'desc'
//         this.workStationsAvailable.sort(this.sortByDescriptionAsc)
//       }
//     else 
//       {
//         this.dir = 'asc'
//         this.workStationsAvailable.sort(this.sortByDescriptionDesc)
//       }
//     }
    
// }


sortByWorkCenterDescriptionAsc(a1: IAuthorizedWorkStation, a2: IAuthorizedWorkStation) { 
  //console.log ('Sorting Ascending:  '+ a1.workCenterDescription + '---' + a2.workCenterDescription)
  return Helper.cmpStringAlphaNumeric(a1.workCenterDescription, a2.workCenterDescription); 
}

sortByWorkCenterDescriptionDesc(a1: IAuthorizedWorkStation, a2: IAuthorizedWorkStation) {
  //console.log ('Sorting Descending:  '+ a1.workCenterDescription + '---' + a2.workCenterDescription) 
  return Helper.cmpStringDesc(a1.workCenterDescription, a2.workCenterDescription); 
}

sortByWorkStationNumberAsc(a1: IAuthorizedWorkStation, a2: IAuthorizedWorkStation) { 
  return Helper.cmpString(a1.workStationNumber, a2.workStationNumber); 
}

sortByWorkStationNumberDesc(a1: IAuthorizedWorkStation, a2: IAuthorizedWorkStation) { 
  return Helper.cmpStringDesc(a1.workStationNumber, a2.workStationNumber); 
}

sortByDescriptionAsc(a1: IAuthorizedWorkStation, a2: IAuthorizedWorkStation) { 
  return Helper.cmpString(a1.description, a2.description); 
}

sortByDescriptionDesc(a1: IAuthorizedWorkStation, a2: IAuthorizedWorkStation) {
  return Helper.cmpStringDesc(a1.description, a2.description); 
}

 fromUserDetailForm(): IUserMaintenance {
  return {
     userId: '',
     manufacturingUserId: '',
     userName: <string>this.UserDetailFormGroup.controls.userName.value,
     firstName: '',
     lastName: '',
     middleInitial: '',
     employeeID: <string>this.UserDetailFormGroup.controls.employeeID.value,
     empNum: '',
     email: '',
     defaultWarehouse: <string>this.UserDetailFormGroup.controls.defaultWarehouse.value,
     defaultWarehouse1: <string>this.UserDetailFormGroup.controls.defaultWarehouse.value,
     defaultWorkStation: <string>this.UserDetailFormGroup.controls.defaultWorkStation.value,
     workstationDescription: <string>this.UserDetailFormGroup.controls.workstationDescription.value,
     active: true,
     userEnabled: false,
  };
}

    private get isVisible(): boolean {
      return this.view.nativeElement.offsetParent != null;
    }

    ngAfterContentChecked() 
    {           
        if (this.userId && (this.selectedUser === null || this.selectedUser.userId === null) ) {
          this.navRouter.navigate(['/routers']);
          return;
        }

        if (!this.isVisible && this.viewWasVisible) 
        {       
          this.viewWasVisible = false;
        } 
        else if (this.isVisible && !this.viewWasVisible) 
        {
          console.log('user-details.component.ngAfterContentChecked...from hidden to visible');
          this.viewWasVisible = true;
          //if (this.utils.isExistingItem(this.selectedUser)) {
            this.userMaintenanceService.getAuthorizedWorkStations(this.selectedUser.manufacturingUserId).subscribe( stations => {
              this.workStations  = stations;
              this.workStations.sort(this.sortByWorkCenterDescriptionAsc)
              this.viewWasVisible = true;
            });
            this.getAvailableWorkStations();
          //}
        } 
        else if (!this.isVisible && this.viewWasVisible) 
        {
          console.log('user-details.component.ngAfterContentChecked...visible to hidden');
          this.viewWasVisible = false;
          if (this.bizl.canUpdateUser) {
            this.userMaintenanceService.updateUser(this.selectedUser).subscribe(updated =>{

             });
          }
        }            
      }
    


    sortStationNumberDescription(s1: IWorkStation, s2: IWorkStation) {
      const ret = Helper.cmpStringAlphaNumeric(s1.number, s2.number);
      if (ret === 0) {
        return Helper.cmpStringAlphaNumeric(s1.description, s2.description );
      } else {
        return ret;
      }
    }

    
  ngOnDestroy() {
    console.log('user-details.component.ngOnDestroy');  
    if (this.bizl.canUpdateUser) {
      this.userMaintenanceService.updateUser(this.userInfo).subscribe(updated =>{ 
        return updated;      
      });
    }    
  }

    submit(formValue) {
      console.log('user-detail.component.submit');
      console.log(this.UserDetailFormGroup);
      console.log(this.bizl);
      console.log(this);
      this.userSubmittedWorkStation = true;
      if (!this.bizl.isWorkStationValid) {
        return;
      }
    }

     addSelectedAuthorizedWorkstation(workStation: IAuthorizedWorkStation, event) {     
 
      var isSelected: number 
      isSelected = this.toggleItemInArr(this.selectedStationArray, workStation);
      var tdCount = event.currentTarget.childElementCount;

      if (isSelected == -1)
      {
        for (var i=0; i < tdCount; i++)
        {
          event.currentTarget.cells[i].classList.add("highlightRow");
        }   
      }
      else
      {
        for (var i=0; i < tdCount; i++)
        {
          event.currentTarget.cells[i].classList.remove("highlightRow");
        }   
      } 
     }

   

     toggleItemInArr(arr, item): number {
       const index = arr.indexOf(item);
       index == -1 ? arr.push(item) : arr.splice(index, 1);
       // index == -1 ? this.isWorkStationSelected = false : this.isWorkStationSelected = true;    
       return index;
     }


     submitAllWorkStations() {
      console.log('user-detail.component.submitAllWorkStations');
      this.alert.showYesNo('Are you sure you want to submit all available work stations?',
      AlertLevel.WARNING,
      (ar: AlertReponse) => {
         if (ar === AlertReponse.YES) {
          this.selectedStationArray = this.workStationsAvailable;
          const ws = this.getIAuthorizedWorkstationsIds(this.selectedStationArray);
          this.userMaintenanceService.addAllAuthorizedWorkStations(this.selectedUser.manufacturingUserId, ws).subscribe(ret => {
            if (ret == -1) {
                this.userMaintenanceService.getAuthorizedWorkStations(this.selectedUser.manufacturingUserId).subscribe( stations => {
                  this.workStations  = stations;
                  this.viewWasVisible = true;
                  this.closeOverlay();
                });
            } 
          });
        }
      });
     }

     submitSelectedWorkStations() 
     {
      this.alert.showYesNo('Are you sure you want to submit the selected available work stations?',
      AlertLevel.WARNING,
      (ar: AlertReponse) => 
      {
         if (ar === AlertReponse.YES) 
         {
           
            const ws = this.getIAuthorizedWorkstationsIds(this.selectedStationArray);
            if (ws.length != 0){
                this.userMaintenanceService.addSelectedAuthorizedWorkStations(this.selectedUser.manufacturingUserId, ws).subscribe(ret => 
                {
                  if (ret == -1) 
                  {
                      this.userMaintenanceService.getAuthorizedWorkStations(this.selectedUser.manufacturingUserId).subscribe( stations => {
                        this.workStations  = stations;
                        this.viewWasVisible = true;
                        this.closeOverlay();
                      });
                  }        
                });
            }
            else 
           {
             this.alert.showOk('Please select available work stations to add!', AlertLevel.INFO,
             (ar: AlertReponse) => 
             { 
               if (ar === AlertReponse.OK)
               {
                 // this.closeOverlay();
               }
             });
           }
        }
      });
    }

    getIAuthorizedWorkstationsIds(workStations: IAuthorizedWorkStation[]) {
      const ret: string[] = [];
      if (!this.utils.isEmptyArray(workStations)) {
        for (const ws of workStations) {
          ret.push(ws.workStationId);
        }
      }
      return ret;
    }  


  closeOverlay(_self = this) {
    this.selectedStationArray = [];
   

    if (this.searchInputAvailable.nativeElement.value == '')
    {     
      this.searchInputAvailable.nativeElement.value = '';
      this.searchInputAuthorized.nativeElement.value = '';
      this.workStations.sort(this.sortByWorkCenterDescriptionAsc);
      this.workStationsAvailable.sort(this.sortByWorkCenterDescriptionAsc);    
      document.getElementById('AvailableStationOverlay').style.display = 'none';
      _self.data.selectedWorkStation = null;
      _self.userSubmittedWorkStation = false;
    }/* 
    else 
    {
      this.alert.showOk('Please clear search filter!', AlertLevel.WARNING, (rsp) => { });
    } */
    
  }
  overlayOpen() {

      const _self = this;

      if (this.searchInputAuthorized.nativeElement.value == '')
      {
        this.searchInputAvailable.nativeElement.value = '';
        this.searchInputAuthorized.nativeElement.value = '';
        document.getElementById('AvailableStationOverlay').style.display = 'block';
        const modal = document.getElementById('AvailableStationOverlay');

                // When the user clicks anywhere outside of the modal, this closes it
        window.onclick = function(event) {
          if (event.target === modal) {
          _self.closeOverlay(_self);
          }
        };
      }
/*       else 
      {
        this.alert.showOk('Please clear search filter!', AlertLevel.WARNING, (rsp) => { });
      } */
  }

  ngAfterViewChecked() {
  //   console.log('User Detail.Component.AfterViewChecked');
     const c1 = $('.col1').width();
     const c2 = $('.col2').width();
     const c3 = $('.col3').width();
     const c4 = $('.col4').width();
     const c5 = $('.col5').width();
     const c6 = $('.col6').width();
     const th = $('thead').width();

  // // sets th column widths to match td's
  //   $('#Cover').width(th);
  //   $('#col1').width(c1 + 1);
  //   $('#col2').width(c2 + 2);
  //   $('#col3').width(c3);
  //   $('#col4').width(c4);
  //   $('#col5').width(c5);
  //   $('#col6').width(c6);

     $('wsAuthorizedStations').css({position: "fixed"})

     $('.SearchBar').width(c1 + c2 + c3 + 13);
     $('.SearchBar1').width(c4 + c5 + c6 + 32);
  }

  ngAfterViewInit() {

        console.log('User-Detail.Component.AfterViewInit');
      
        // runs every time the user re-sizes the window
            $(window).resize(function(){
          // gets td column widths
            const c1 = $('.col1').width();
            const c2 = $('.col2').width();
            const c3 = $('.col3').width();
            const c4 = $('.col4').width();
            const c5 = $('.col5').width();
            const c6 = $('.col6').width();
            const th = $('thead').width();

          // sets th column widths to match td's
            $('#Cover').width(th);
            $('#col1').width(c1 + 1);
            $('#col2').width(c2 + 2);
            $('#col3').width(c3);
            $('#col4').width(c4);
            $('#col5').width(c5);
            $('#col6').width(c6);

            $('wsAuthorizedStations').css({position: "fixed"})

            $('.SearchBar').width(c1 + c2 + c3 + 13);
            $('.SearchBar1').width(c4 + c5 + c6 + 32);
            console.log ('resize header occured');
            });
      }
ngAfterContentInit() {
  console.log('User-Detail.Component.AfterContentInit()');
  $(document).ready(function(){
    $('.SearchField').focus();
  });
}

}
