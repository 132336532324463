import { FileTestUtils, IFileTest, TestType, TestDeviceType } from '../../../core/models/test.model';
import { Helper } from '../../../core/services/helper.service';
import { UnitType } from '../../../core/enums/unittype';

export class TestKitCreateComponentBizLogic {
  private utils: Helper = new Helper();

  constructor(private testProvider: any) { }

  public get test(){
    return this.testProvider.newFileTestInfo;
  }

 get isNewTest() {
    const ret = !this.utils.isExistingItem(this.test);
   // console.log('step-tests.component.bizl isNewTest:' + ret);
    return ret;
  }

get isTestEditable(){
  return true;
}

 get isTestTypeEditable() {

    return this.isTestEditable;
 }

get testTypeIsValid() {
  return this.test != null &&  this.test.type !== TestType.UNKNOWN;
}

 get isFrequencyEditable() {
    return this.testTypeIsValid;
 }

 get isBaseTestEditable() {
  return this.testTypeIsValid;
}

get isTestDescriptionEditable() {
  return this.testTypeIsValid;
}


get isTestDeviceTypeEditable() {
  return this.testTypeIsValid;
}

get isMinMaxEditable() {
  return this.shouldShowMinMax && this.testTypeIsValid;
}

get isToolCountEditable() {
  return this.testTypeIsValid;
}

get areInstructionsEditable() {
  return this.testTypeIsValid;
}

get shouldShowMinMax() {
  return this.test != null &&  this.test.type === TestType.IPI;
}

get canUpdate() {
  console.log('test-kit-create.component.canUpdate');
  console.log(this.testProvider);
  console.log(this.test);
  const ret = this.isTestEditable && this.testProvider.validateNewTestInfo &&
   !FileTestUtils.areEqual(this.testProvider.selectedFileTest, this.test);

   console.log('...ret:' + ret);
   return ret;
}

get canSubmit() {
  return this.isNewTest || this.canUpdate;
}


isUniqueDescription(editMode:Boolean) {
  let isUnique:Boolean = null; 
  //console.log('********************test-kit-create.component.isUniqueDescription editMode is : ' + editMode);
    if (editMode)
    {      
      isUnique =  !this.utils.listContainsSimilarDifferentItemEdit(this.testProvider.fileTests, this.test, this.compDescription);
    }
    else
    {
      isUnique = !this.utils.listContainsItem(this.testProvider.fileTests, this.test, this.compDescription);
    }
    return isUnique;  
}

compDescription(a: IFileTest, b: IFileTest) {
  if (b.description !== null)
    {
      return Helper.cmpStringAlphaNumeric(a.description, b.description);
    }
    else
    {
      return 0;
    }
  }
}
