import { UnitType } from '../../../core/enums/unittype';
import { TestKitCreateComponentBizLogic } from './test-kit-create.component.bizl';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TestTypeUtils, TestDeviceTypeUtils, TestType, IFileTest } from '../../../core/models/test.model';
import { FormControl, Validator, Validators, FormGroup } from '@angular/forms';
import { Helper } from '../../../core/services/helper.service';

@Component({
  selector: 'test-kit-create',
  template: require('./test-kit-create.component.html'),
  // styles: [require('./test-kit-create.component.css')]
  styles: [require('./test-kit-create.component.css').toString()]
})
export class TestKitCreateComponent implements OnInit {
  @Input() editMode:Boolean;
  @Input() fileTests: IFileTest[] = null;
  @Input() selectedFileTest: IFileTest = null;
  @Output() userSubmit = new EventEmitter();
  @Output() createModal = new EventEmitter();
  testDetailsWasVisible = false;
  testTypes = TestTypeUtils.types;
  testDeviceTypes = TestDeviceTypeUtils.types;
  typeSwitchedToIPI = false;
  bizl: TestKitCreateComponentBizLogic;
  minVal: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.min(Helper.MINTESTVALUE_MINVALUE)]));
  maxVal: FormControl = new FormControl('', Validators.compose([Validators.required, Validators.min(0.0001)]));
  formSubmitted = false;
  //editMode : Boolean = false;

  public TestInfoGroup: FormGroup = new FormGroup({
    testType: new FormControl('', Validators.required),
    testDescription: new FormControl('', Validators.compose([Validators.required, this.makeValidateUniqueDescription()]) ),
    frequency: new FormControl('', Validators.compose([Validators.required, Validators.min(0)])),
    minVal: this.minVal,
    maxVal: this.maxVal,
    testDeviceType: new FormControl('', Validators.required),
    toolCount: new FormControl('', Validators.compose([Validators.required, Validators.min(1), Validators.max(8)])),
    instructions: new FormControl('', Validators.required),
    });

  modalToggle() {
    this.createModal.emit(false);
  }


  get lastMinValidationMsg() {
    const min = this.TestInfoGroup.controls.minVal;
    const max = this.TestInfoGroup.controls.maxVal;

    if (min.errors) {
      if (min.errors.required) {
        return 'Required';
      } else if (min.errors.min) {
        return 'Min value must be >= ' + +Helper.MINTESTVALUE_MINVALUE;
      }
    } else if (min.value >= max.value) {
      return 'Max must be > Min';
    } else {
      return '';
    }
  }
  get lastMaxValidationMsg() {
    const min = this.TestInfoGroup.controls.minVal;
    const max = this.TestInfoGroup.controls.maxVal;
    if (max.errors) {
        if (max.errors.required) {
          return 'Required';
        } else if (max.errors.min) {
          return 'Min value must be >= ' + +Helper.MINTESTVALUE_MINVALUE;
        }
      } else if (min.value >= max.value) {
        return 'Max must be > Min';
      } else {
        return '';
      }
    }

    get lastToolCountValidationMsg() {
      const toolCount = this.TestInfoGroup.controls.toolCount;

      if (toolCount.errors) {
          if (toolCount.errors.required) {
            return 'Required';
          } else if (toolCount.errors.min || toolCount.errors.max) {
            return 'Tool count value must be 1-8';
          }
        } else {
          return '';
        }
      }

  constructor(
    private helper: Helper) { }



  ngOnInit() {
     console.log('test-kit-create.components ngOnit');

    this.bizl = new TestKitCreateComponentBizLogic(this);
    this.formSubmitted = false;

    this.patchFormGroup(this.selectedFileTest);
    this.resetFormValidation();
  }

  handleSelectTestType(event) {
    console.log('test-kit-create.component.handleSelectTestType');
    console.log(event);
    // this.resetFormValidation();
    if (this.TestInfoGroup.controls.testType.value !== TestType.IPI) {
      this.typeSwitchedToIPI = false;
      this.TestInfoGroup.patchValue({
        minVal: 0.0000,
        maxVal: 0.0000
      });
    } else {
      this.typeSwitchedToIPI = true;
    }

    this.updateFormControls();
    }


  getNominalValue(test: IFileTest): number {
    if (test.type === TestType.IPI) {
      return (test.min + test.max) / 2;
    } else {
      return 0;
    }
  }

  getTolerance(test: IFileTest): number {
    if (test.type === TestType.IPI) {
      return (test.max - test.min) / 2;
    } else {
      return 0;
    }

  }

    fromFormValues(): IFileTest {
      // console.log('step-tests.components.fromFormValue');
      if (this.TestInfoGroup == null) {
        return null;
      }
      const info = this.TestInfoGroup.getRawValue();
      // console.log(info);

      return {
        id: this.helper.isExistingItem(this.selectedFileTest) ? this.selectedFileTest.id : null ,
        specificationName: this.selectedFileTest.specificationName,
        description: info.testDescription,
        type: info.testType != null ? info.testType : TestType.UNKNOWN,
        min: info.minVal,
        max: info.maxVal,
        unitType: UnitType.INCH,
        frequency: info.frequency,
        notes: info.instructions,
        testOrder: this.getNewTestOrder(),
        testDeviceType: info.testDeviceType,
        testDeviceCount: info.toolCount
      };
    }

    getNewTestOrder() {
      if (!this.helper.isEmptyArray(this.fileTests)) {
        return this.fileTests.length + 1;
      }  else {
        return 1;
      }
    }

  validateNewTestInfo() {
    console.log('test-kit-create.component.validateNewTestInfo');
    const ret = this.TestInfoGroup.valid &&
           this.validateMin() &&
           this.validateMax();
    console.log('...ret:' + ret);
    return ret;
  }

  validateTestType() {
    return !this.bizl.isTestTypeEditable ||
    (this.bizl.isNewTest && !this.formSubmitted) ||
    (!this.bizl.isNewTest &&  this.TestInfoGroup.controls.testType.pristine) ||
    this.TestInfoGroup.controls.testType.valid;
  }

  validateDescription() {
    return !this.bizl.isTestDescriptionEditable ||
    (this.bizl.isNewTest && !this.formSubmitted) ||
    (!this.bizl.isNewTest &&  this.TestInfoGroup.controls.testDescription.pristine) ||
            this.TestInfoGroup.controls.testDescription.valid;
  }

  validateFrequency() {
    return !this.bizl.isFrequencyEditable ||
    (this.bizl.isNewTest && !this.formSubmitted) ||
    (!this.bizl.isNewTest &&  this.TestInfoGroup.controls.frequency.pristine) ||
            this.TestInfoGroup.controls.frequency.valid;
  }

  makeValidateUniqueDescription() {
    const _self = this;
    return function(fc: FormControl) {
     // console.log('spec-tool.component.validateUnique');
      const ret = _self.TestInfoGroup && _self.bizl.isUniqueDescription(_self.editMode) ? null : {
        validateUnique: {
          valid: false
        }
      };
      // console.log('spec-tool.component ret ' + ret);
      return ret;
    };
  }

  get testDescriptionValdationError( ) {
    if (this.TestInfoGroup.controls.testDescription.errors &&
      this.TestInfoGroup.controls.testDescription.errors.validateUnique) {
      return 'Description must be unique';
    } else {
      return 'Required';
    }
   }

  validateNotes() {
    return !this.bizl.areInstructionsEditable ||
    (this.bizl.isNewTest && !this.formSubmitted) ||
    (!this.bizl.isNewTest &&  this.TestInfoGroup.controls.instructions.pristine) ||
    this.TestInfoGroup.controls.instructions.valid;
  }

validateMin() {
   console.log('test-kit-create.component.validateMin');
   // console.log(this.formSubmitted);
   console.log(this.bizl);
   console.log(this.TestInfoGroup);
   const ret = !this.bizl.shouldShowMinMax || !this.bizl.isMinMaxEditable ||
   (this.bizl.isNewTest && !this.formSubmitted) ||
  (!this.bizl.isNewTest && !this.typeSwitchedToIPI && this.minVal.pristine) ||
  (this.minVal.value >= Helper.MINTESTVALUE_MINVALUE && this.maxVal.value > 0 && this.minVal.value < this.maxVal.value && this.minVal.valid);
  console.log('...ret:' + ret);
  return ret;
}

validateMax() {
  return !this.bizl.shouldShowMinMax || !this.bizl.isMinMaxEditable ||
  (this.bizl.isNewTest && !this.formSubmitted) ||
 (!this.bizl.isNewTest && !this.typeSwitchedToIPI &&  this.maxVal.pristine) ||
 (this.minVal.value >= Helper.MINTESTVALUE_MINVALUE && this.maxVal.value > 0 && this.minVal.value < this.maxVal.value && this.minVal.valid);
}

validateToolCount() {
  const toolCount = this.TestInfoGroup.controls.toolCount;

  return !this.bizl.isToolCountEditable ||
   (this.bizl.isNewTest && !this.formSubmitted) ||
   (!this.bizl.isNewTest &&  this.minVal.pristine) ||
   this.TestInfoGroup.controls.toolCount.valid;
}

resetFormValidation() {
  console.log('test-kit-create.component.resetFormValidation');
  this.formSubmitted = false;

  this.TestInfoGroup.markAsPristine();
  this.TestInfoGroup.markAsUntouched();
  this.TestInfoGroup.updateValueAndValidity();
}

submit() {
    console.log('submit');

    this.formSubmitted = true;

    const t = this.fromFormValues();
    // console.log(this.TestInfoGroup);
    if (!this.validateNewTestInfo()) {
      return;
    }

    this.userSubmit.emit(this.newFileTestInfo);

  }


  get title(): string {
    if (this.bizl.isNewTest) {
      return 'Add New Test';
    } else if (this.bizl.isTestEditable) {
      return 'Edit Test';
    } else {
      return 'Test Info';
    }
  }

private patchFormGroup(t: IFileTest) {

  this.TestInfoGroup.patchValue({
    testType: t.type !== TestType.UNKNOWN ? t.type : null,
    testDescription: t.description,
    frequency: t.frequency,
    minVal: t.type === TestType.IPI ? t.min : 0,
    maxVal: t.type === TestType.IPI ? t.max : 0,
    testDeviceType: t.testDeviceType,
    toolCount: t.testDeviceCount,
    instructions: t.notes,
  });

   this.updateFormControls();
}

get newFileTestInfo() {
  return this.fromFormValues();
}

updateFormControls() {
  console.log('test-kit-create.component.updateFormControls');
console.log(this.bizl);

  if (this.bizl.isTestTypeEditable) {
    this.TestInfoGroup.controls.testType.enable();
  }  else {
    this.TestInfoGroup.controls.testType.disable();
  }

  if (this.bizl.isTestDescriptionEditable) {
    this.TestInfoGroup.controls.testDescription.enable();
  }  else {
    this.TestInfoGroup.controls.testDescription.disable();
  }

  if (this.bizl.isFrequencyEditable) {
    this.TestInfoGroup.controls.frequency.enable();
  }  else {
    this.TestInfoGroup.controls.frequency.disable();
  }

  if (this.bizl.isMinMaxEditable) {
    this.TestInfoGroup.controls.minVal.enable();
  }  else {
    this.TestInfoGroup.controls.minVal.disable();
  }

  if (this.bizl.isMinMaxEditable) {
    this.TestInfoGroup.controls.maxVal.enable();
  }  else {
    this.TestInfoGroup.controls.maxVal.disable();
  }

  if (this.bizl.isTestDeviceTypeEditable) {
    this.TestInfoGroup.controls.testDeviceType.enable();
  }  else {
    this.TestInfoGroup.controls.testDeviceType.disable();
  }

  if (this.bizl.isToolCountEditable) {
    this.TestInfoGroup.controls.toolCount.enable();
  }  else {
    this.TestInfoGroup.controls.toolCount.disable();
  }

  if (this.bizl.areInstructionsEditable) {
    this.TestInfoGroup.controls.instructions.enable();
  }  else {
    this.TestInfoGroup.controls.instructions.disable();
  }

}
}
