import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import "rxjs/add/observable/of";
import { IWorkCenter } from '../models/workcenter.model';
import { MockDataService} from './mock-data.service';
import { IWorkStation } from '../models/workstation.model';



@Injectable()
export class WorkCenterService {


  constructor(private data: MockDataService) { }


    createWorkCenter(info: IWorkCenter): Observable<IWorkCenter> {
      const wc = this.data.createWorkCenter(info);
      return Observable.of(wc);
    }

    updateWorkCenter(info: IWorkCenter): Observable<IWorkCenter> {
      const wc = this.data.updateWorkCenter(info);
      return Observable.of(wc);
    }

    
    updateWorkCenterAndStations(info:IWorkCenter, stations: IWorkStation[]): Observable<IWorkCenter> {
      const wc = this.data.updateWorkCenterAndStations(info, stations);
      return Observable.of(wc);
    }


    getWorkCenter(id: string): Observable<IWorkCenter> {

       const w = this.data.getWorkCenter(id);
       return Observable.of(w);
    }


    getWorkCenters(): Observable<IWorkCenter[]> {

      const centers = this.data.getWorkCenters();
      return Observable.of(centers);
    }

    deleteWorkCenter(id: string): Observable<number> {
      const ret = this.data.deleteWorkCenter(id);
      return Observable.of(ret);
    }


}






