import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { IProgram } from '../models/program.model';
import { Http, Response } from '@angular/http';
import { Helper } from './helper.service';
// import { AuthService } from '../../core/services/auth.service';
import { AppHttpClientService } from './app-http-client.service';
import { GateKeeperService} from '../shared/gatekeeper/user/user.service';
import { Config } from '../shared/gatekeeper/config';
import { MethodType, MethodTypes } from '../enums/methodtype';
import 'rxjs/add/observable/throw';



@Injectable()
export class ProgramServiceHttp {


  constructor(private http: Http, private helper: Helper,
    // private auth: AuthService, 
    private ahttp: AppHttpClientService , 
    private gkauth: GateKeeperService
    , private Config: Config) { }


   createProgram(info: IProgram): Observable<IProgram> {
    console.log('ProgramServiceHttp.createProgram');


    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'programs/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       const ret =  this.translateToIProgram(response);
       return ret;
    });

   }


    getProgram(id: string ): Observable<IProgram> {
    console.log('ProgramServiceHttp.getProgram');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'programs/' + id;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         //console.log(response);
         const ret =  this.translateToIProgram(response);
         //console.log(ret);
         return ret;
      });
    }

    getPrograms( ): Observable<IProgram[]> {

      console.log('ProgramServiceHttp.getPrograms');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'programs/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);     

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         //console.log(response);
         const ret =  this.translateToIPrograms(response);

         return ret;
      });
          }

    addProgramToRouterStep(stepId: string, program: IProgram): Observable<IProgram> {
      console.log('ProgramServiceHttp.addProgramToRouterStep');

      const info = {
        stepId: stepId,
        programId: program.id,
        userId: this.gkauth.currentUser.UserID
      };

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'programs/AddProgramToRouterStep/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);
      
      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         //console.log(response);
         const ret = Number(response);
         //console.log(ret);
         return program;
      });
    }

    removeProgramFromRouterStep(stepId: string, programId: string): Observable<number> {
      console.log('ProgramServiceHttp.removeProgramFromRouterStep');
      const info = {
        stepId: stepId,
        programId: programId,
        userId: this.gkauth.currentUser.UserID
      };

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'programs/RemoveProgramFromRouterStep/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);
     
      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        // console.log(response);
         const ret = Number(response);
         //console.log(ret);
         return ret;
      });
    }



    getProgramsForRouterStep(routerStepId: string): Observable<IProgram[]> {

      console.log('ProgramServiceHttp.getProgramsForRouterStep');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'programs/GetProgramsForRouterStep/' + routerStepId;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         const ret =  this.translateToIPrograms(response);
         //console.log(ret);
         return ret;
      });

    }

    updateProgram(info: IProgram ): Observable<IProgram> {
      console.log('ProgramServiceHttp.updateProgram');
      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'programs/UpdateProgram/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);
      
      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        // console.log(response);
         const ret =  this.translateToIProgram(response);
        // console.log(ret);
         return ret;
      });
          }

    deleteProgram(id: string): Observable<number> {
      console.log('ProgramServiceHttp.deleteProgram');

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'programs/' + id;
      Config.gkParameters.MethodType = new MethodType(MethodTypes.DELETE);
      Config.gkParameters.JsonParameters = '';

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        //console.log(response);
        return Number(response);
      });
    }


  private handleError(error: Response) {
    return Observable.throw(error.statusText);
  }

  translateToIProgram(data: any): IProgram {
    if (data) {
      return <IProgram> data;
    } else {
      return null;
    }
  }

  translateToIPrograms(data: any): IProgram[] {
    const ret: IProgram[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIProgram(d));
      }
    }
    return ret;
  }
}






