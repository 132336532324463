import { RouterTestingModule } from '@angular/router/testing';
import {RouterState} from '../core/enums/router-state';
import {IRouter, RouterType } from '../core/models/router.model';


export class RouterDetailsValidator {

   static get newRouterDefault(): IRouter{

      return {
        id: '',
        partId: '',
       partNumber: '',
       partDescription: '',
       revision: 1,
       state: RouterState.PENDING,
       type: RouterType.CELL,
       lastUpdate: new Date(),
       updatedBy: 'Current user',
       userId: null,
       activeWIPTotal: 0,
       targetWIP: 0,
       psl: 1
      };
   }

}
