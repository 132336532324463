// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/********************************************************************************************/\r\n/*Note Boxes Start*/\r\n/********************************************************************************************/\r\n.NotesGroupContainer {\r\n    padding: 25px 20px;\r\n    width: 90%;\r\n    margin: auto;\r\n}\r\n/*Junk Content Clearfix for floats*/\r\n.NotesGroupContainer:after {\r\n    content: \"\";\r\n    display: table;\r\n    clear: both;\r\n}\r\n.LabelContainer {\r\n    height: 40px;\r\n    background-color: #e5e5e5;\r\n    padding: 0px 5px;\r\n    width: 100%;\r\n}\r\ndiv.FieldName {\r\n    line-height: 2;\r\n    font-size: 1.2em;\r\n    color: #7b8d93;\r\n}\r\n.InputField {\r\n    width: 100%;\r\n    height: 100%;\r\n    resize: vertical;\r\n    min-height: 300px;\r\n    overflow-y: scroll;\r\n    padding: 0px 5px 0px 2px;\r\n    margin: 0px;\r\n    white-space: pre-line;\r\n    font-size: 1em;\r\n    font-family: inherit;\r\n}\r\n#Setup {\r\n    width: 48%;\r\n    float: left;\r\n}\r\n#User {\r\n    width: 48%;\r\n    float: right;\r\n}\r\ntextArea {\r\n    max-height: 70vh;\r\n}\r\n/********************************************************************************************/\r\n/*Note Boxes End*/\r\n/********************************************************************************************/\r\n\r\n\r\n\r\n\r\n", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();