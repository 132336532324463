import { AppHttpClientService } from './app-http-client.service';
import { RouterState } from '../enums/router-state';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import { IRouter, IRouterVersionInfo, RouterUtils } from '../models/router.model';
import { Helper } from './helper.service';
import { MockDataService} from './mock-data.service';
import { Http, Response } from '@angular/http';
import { GateKeeperService} from '../shared/gatekeeper/user/user.service';
import { Config } from '../shared/gatekeeper/config';
import { MethodType, MethodTypes } from '../enums/methodtype';
import { IFile } from '../models/file.model';


@Injectable()
export class RouterServiceHttp {

  // manual search parameters
  public routers:IRouter[] = [];
  public lastIndex: number = 0;
  
  private _term:string = "";

  public get term():string {
    return this._term;
  }

  public set term(val:string)
  {
    if (
      val===null ||
      val === undefined)
      {
        this._term = '';
      }
      else
      {
        this._term = val;
      }
  }

  private _lastSearchStr:string = "";

  public get lastSearchStr():string {
    return this._lastSearchStr.length > 0
          ?"Last search:" + this._lastSearchStr
          :this._lastSearchStr;
  }

  public set lastSearchStr(val:string)
  {
    if (
      val===null ||
      val === undefined)
      {
        this._lastSearchStr = '';
      }
      else
      {
        this._lastSearchStr = val;
      }
  }
  //manual search parameters
   
  constructor(private helper: Helper, private data: MockDataService
  , private http: Http
  // , private auth: AuthService
  , private ahttp: AppHttpClientService
  , private gkauth: GateKeeperService
  , private Config: Config) {
    console.log('router.servicehttp.constructor');
  }

publishRouter(r: IRouter): Observable<IRouter> {
  console.log('router.servicehttp.publishRouter');
  const info = {
    routerId : r.id,
    userId : this.currentUserId
  };

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/publishrouter/';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     //console.log(response);
     const router =  this.translateToIRouter(response);
     //console.log(router);
     return router;
  });
}

get currentUserId(){
  return this.gkauth.currentUser.UserID
}

createRouter(r: IRouter): Observable<IRouter> {
  console.log('router.servicehttp.createRouter');
  const info = RouterUtils.toServerInfo(r);
  info.userId = this.currentUserId;

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const router =  this.translateToIRouter(response);
     return router;
  });

 }


createRouterFromRouter(routerId: string, partId: string): Observable<IRouter> {
  console.log('router.servicehttp.createRouterFromRouter');
  const info = {
    routerId: routerId,
    partId: partId,
    userId: this.currentUserId
  };

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/createRouterFromRouter';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const router =  this.translateToIRouter(response);
     return router;
  });

}

createNewRouterVersion(routerId: string): Observable<IRouter> {
    console.log('router.servicehttp.createNewRouterVersion');
    const info = {
      routerId: routerId,
      userId: this.currentUserId
    };

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/createNewRouterVersion/';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       const router =  this.translateToIRouter(response);
       return router;
    });
 }


updateRouter(r: IRouter): Observable<IRouter> {
  console.log('router.servicehttp.updateRouter');
  const info = RouterUtils.toServerInfo(r);
  info.userId = this.currentUserId;

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/updateRouter';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const router =  this.translateToIRouter(response);
     return router;
  });

 }



 updateRouter1(r: IRouter): Observable<boolean> {
  console.log('router.servicehttp.updateRouter1');
  const info = RouterUtils.toServerInfo(r);
  info.userId = this.currentUserId;

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/updateRouter';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const router =  this.translateToIRouter(response);
     console.log('router.service.updateRouter1 returns true');
     return true;
  });

 }
 getRouterNotes(routerId: string): Observable<string> {
  console.log('router.servicehttp.getRouterNotes');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/GetRouterNotes/' + routerId;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
  Config.gkParameters.JsonParameters = '';

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     //console.log('router notes:');
     //console.log(response);
     return String(response);
  });
}

updateRouterNotes(routerId: string, notes: string): Observable<number> {
  console.log('router.servicehttp.updateRouterNotes');
  const info = {
    id: routerId,
    notes: notes,
    userId: this.currentUserId
  };

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/updateRouterNotes';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      return Number(response);
  });
}

updateRouterNotes1(routerId: string, notes: string): Observable<boolean> {
  console.log('router.servicehttp.updateRouterNotes1');
  const info = {
    id: routerId,
    notes: notes,
    userId: this.currentUserId
  };

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/updateRouterNotes';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
    console.log('router.servicehttp.updateRouterNotes1 returns');
      return true;
  });
}

updateRoutersHoldByPart(partId: string, file: IFile): Observable<boolean> {
  console.log('router.servicehttp.updateRoutersHoldByPart');
  const info = {
    tokenId: file.tokenID,
    partId: partId,
    fileId: file.id,
    holdState: file.holdState,
    holdStateNotes: file.holdStateNotes
  };

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/UpdateRoutersHoldByPart';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(info);

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
    console.log('router.servicehttp.updateRoutersHoldByPart returns');
      return true;
  });
}

getRouter(router:IRouter): Observable<IRouter> {
  console.log('RouterServiceHttp.getRouter');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/' + router.id;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
  Config.gkParameters.JsonParameters = '';

   return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const router =  this.translateToIRouter(response);
     //console.log(router);
     return router;
  });
}

getRouters(): Observable<IRouter[]> {
  console.log('RouterServiceHttp.getRouters');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    Config.gkParameters.JsonParameters = '';

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const routers = this.translateToIRouters(response);
      return routers;
   });
}

getAllLatestVersionInfo(): Observable<IRouterVersionInfo[]> {
  console.log('RouterServiceHttp.getAllLatestVersionInfo:');

   Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'Routers/GetLatestVersionInfo/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    Config.gkParameters.JsonParameters = '';

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const infos = this.translateToIRouterVersionInfos(response);
      //console.log(infos);
      return infos;
   });
}

searchRouters(term:string, includeInactive:boolean): Observable<IRouter[]> {
  console.log('RouterServiceHttp.searchRouters:' + (term != null? term: "Null"));

  if (term == null)
      term = ''; 

    var incl = includeInactive? "1" : "0";  
    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/search/' + term + '/'+ incl;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    Config.gkParameters.JsonParameters = '';

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const routers = this.translateIRouterMinToIRouters(response);
      this.routers = !(routers === null || routers === undefined)? routers: [];
      this.lastSearchStr = term;
      //console.log(routers);
      return routers;
   });
}


getRoutersAssociatedWithFileName(fileName: string): Observable<IRouter[]> {
  console.log('RouterServiceHttp.getRoutersAssociatedWithFilename');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/GetRoutersAssociatedWithFilename/' + fileName;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
  Config.gkParameters.JsonParameters = '';

   return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const routers = this.translateToIRouters(response);
      return routers;
   });
}

getRoutersAssociatedWithFile(fileId: string): Observable<IRouter[]> {
  console.log('RouterServiceHttp.getRoutersAssociatedWithFile');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/GetRoutersAssociatedWithFile/' + fileId;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
  Config.gkParameters.JsonParameters = '';

   return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const routers = this.translateToIRouters(response);
      return routers;
   });
}

getRoutersAssociatedWithFiles(fileIDs: string[]): Observable<IRouter[]> {
  console.log('RouterServiceHttp.getRoutersAssociatedWithFiles');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/GetRoutersAssociatedWithFiles/';
  Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
  Config.gkParameters.JsonParameters = JSON.stringify(fileIDs);

   return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const routers = this.translateToIRouters(response);
      return routers;
   });
}

getRoutersAssociatedWithPart(partId: string): Observable<IRouter[]> {
  console.log('RouterServiceHttp.getRoutersAssociatedWithPart');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/GetRoutersAssociatedWithPart/' + partId;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
  Config.gkParameters.JsonParameters = '';

   return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const routers = this.translateToIRouters(response);
      return routers;
   });
}

translateToIRouter(data: any): IRouter {
  const ret: IRouter = null;
  if (data) {
   return RouterUtils.toIRouter(data);
  } else {
    return null;
  }  
}


translateToIRouterVersionInfo(data: any): IRouterVersionInfo {
  const ret: IRouter = null;
  if (data) {
   return RouterUtils.toIRouterVersionInfo(data);
  } else {
    return null;
  }  
}


translateIRouterMinToIRouter(data: any): IRouter {
  const ret: IRouter = null;
  if (data) {
   return RouterUtils.RouterMinToRouterInfo(data);
  } else {
    return null;
  }
}

// New way with actual apiResult standard for api calls implemented by Pico, Michaeal
// translateToIRouters(data): IRouter[] {
//   const ret: IRouter[] = [];
//    for (const key in data) {
//     if (key == 'result') {
//       for ( let i = 0; i < data.result.length; i++) {
//         ret.push(this.translateToIRouter(data.result[i]));
//       }
//     }
//   }
//   return ret;
// }

translateIRouterMinToIRouters(data): IRouter[] {
  const ret: IRouter[] = [];
  if (data && data.length > 0) {
    for (const d of data){
        ret.push(this.translateIRouterMinToIRouter(d));
    }
  }
  return ret;
}

// Old way with actual response body implemented by Rene Eiffert
translateToIRouters(data): IRouter[] {
  const ret: IRouter[] = [];
  if (data && data.length > 0) {
    for (const d of data){
        ret.push(this.translateToIRouter(d));
    }
  }
  return ret;
}


translateToIRouterVersionInfos(data): IRouterVersionInfo[] {
  const ret: IRouterVersionInfo[] = [];
  if (data && data.length > 0) {
    for (const d of data){
        ret.push(this.translateToIRouterVersionInfo(d));
    }
  }
  return ret;
}




deleteRouter(routerId: string): Observable<number> {
  console.log('RouterServiceHttp.getRouter');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'routers/' + routerId;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.DELETE);
  Config.gkParameters.JsonParameters = '';

  return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
    //console.log('router.servicehttp.deleteRouter returns');
    //console.log(response);
    return Number(response.statusText);
  });
}

private handleError(error: Response) {
  return Observable.throw(error.statusText);
}


}


