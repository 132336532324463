import { convertRuleOptions } from 'tslint/lib/configuration';
import { IWorkCenter, WorkCenterUtils } from '../../core/models/workcenter.model';
import { Helper } from '../../core/services/helper.service';


export class WorkCenterInfoComponentBizLogic {
  private utils: Helper = new Helper();

  constructor(private workCenterProvider: any) {}

  get workCenter(){
    return this.workCenterProvider.newWorkCenterInfo;
  }

  get originalWorkCenter(){
    return this.workCenterProvider.selectedWorkCenter;
  }

  get isNewWorkCenter() {
    return !this.utils.isExistingItem(this.workCenter);
  }

  canDeleteWorkCenter() {
    const inUse = this.originalWorkCenter != null ? this.originalWorkCenter.inUse : false;
    return !inUse;
  }

  get canCreateWorkCenter() {
    return this.isNewWorkCenter;
  }

  get canUpdateWorkCenter() {
    return  this.workCenterProvider.isNewWorkCenterValid && this.utils.isExistingItem(this.workCenter) &&
     !WorkCenterUtils.areEqual(this.originalWorkCenter, this.workCenter);
  }

  get isWorkCenterEditable(){
    return  this.originalWorkCenter != null ? !this.originalWorkCenter.inUse : true;
  }

  get isUniqueWHDescription(){
    return !this.utils.listContainsSimilarDifferentItem(this.workCenterProvider.workCenters, this.workCenter, this.compWHDescription);
  }

  compWHDescription(wc1: IWorkCenter, wc2: IWorkCenter) {
    const ret = Helper.cmpStringAlphaNumeric(wc1.warehouse, wc2.warehouse);
    if (ret === 0) 
    {
      return Helper.cmpStringAlphaNumeric(wc1.description, wc2.description);
    } 
    else 
    {
      return ret;
    }
  }


areEqualWorkCenters(wc1:IWorkCenter, wc2:IWorkCenter):boolean
  {    
    if (!wc1)
      return !wc2;

    if (!wc2)
      return false;

      this.dumpWorkCenter(wc1);
      this.dumpWorkCenter(wc2);

    var ret:boolean = Helper.cmpString(wc1.name, wc2.name) ==  0 &&
       Helper.cmpString(wc1.description, wc2.description) == 0 &&
       wc1.warehouse == wc2.warehouse &&
       wc1.code == wc2.code &&
       wc1.id == wc2.id &&
       wc1.isOutsource == wc2.isOutsource &&
       wc1.scheduleType == wc2.scheduleType &&
       wc1.type == wc2.type;
       console.log("areEqualWorkCenters:" + ret);

       return ret;

  }

  private getStr(s:String):String
  {
    return String(s? s : "NULL");
  }

  private dumpWorkCenter(wc:IWorkCenter)
  {
    console.log("wc name:" + this.getStr(wc.name) + " descr:" + this.getStr(wc.description) + " wh:" + wc.warehouse )
    console.log("...code:" + wc.code);
    console.log(".....id:" + wc.id);
    console.log("..isOut:" + wc.isOutsource);
    console.log("..sched:" + wc.scheduleType);
    console.log("...type:" + wc.type);
  }

  hasUniqueCenterName(centers:IWorkCenter[], center:IWorkCenter):boolean{
    if (Helper.sIsEmptyArray(centers) || !center)
      return true;     


    var ret = !this.utils.listContainsSimilarDifferentItemEdit(centers, center, this.cmpName);
    console.log('WorkCenterInfoComponent.bizl.hasUniqueCenterName:' + ret);
    return ret;
  }

  cmpName(w1: IWorkCenter, w2: IWorkCenter):number {

    var a:string = (!w1 || !w1.name? "" : w1.name.trim().toLowerCase());
    var b:string = (!w2 || !w2.name?"": w2.name.trim().toLowerCase());
   // console.log('bizl.cmpDescription: a:' + a + " b:" + b);
    var ret:number = Helper.cmpStringAlphaNumeric(a, b);
    console.log('bizl.cmpDescription:' + ret);
    return ret;
  }

}
