
enum RouterStates {
    ACTIVE = 0,
    PENDING = 1,
    HOLD_NEW_WIP = 2,
    HOLD_ALL_WIP = 3,
    INACTIVE = 4,
    HOLD_NEW_LOCKED = 5,
    HOLD_ALL_LOCKED = 6
}



export class RouterState {



 private static ROUTER_STATES = [
           new RouterState('ACTIVE', RouterStates.ACTIVE),
           new RouterState('PENDING', RouterStates.PENDING),
           new RouterState('HOLD NEW', RouterStates.HOLD_NEW_WIP),
           new RouterState('HOLD ALL', RouterStates.HOLD_ALL_WIP),
           new RouterState('INACTIVE', RouterStates.INACTIVE),
           new RouterState('HOLD NEW', RouterStates.HOLD_NEW_LOCKED),
           new RouterState('HOLD ALL', RouterStates.HOLD_ALL_LOCKED)
      ];

      protected constructor(public name: string, public id: RouterStates) { }


      public static getState(id: number): RouterState {
        return RouterState.ROUTER_STATES[id];
      }
      public toString = (): string => this.name;

public static get ACTIVE(): RouterState { return this.ROUTER_STATES[RouterStates.ACTIVE]; }
public static get PENDING(): RouterState { return this.ROUTER_STATES[RouterStates.PENDING]; }
public static get HOLD_NEW_WIP(): RouterState { return this.ROUTER_STATES[RouterStates.HOLD_NEW_WIP]; }
public static get HOLD_ALL_WIP(): RouterState { return this.ROUTER_STATES[RouterStates.HOLD_ALL_WIP]; }
public static get INACTIVE(): RouterState { return this.ROUTER_STATES[RouterStates.INACTIVE]; }
public static get HOLD_NEW_LOCKED(): RouterState { return this.ROUTER_STATES[RouterStates.HOLD_NEW_LOCKED]; }
public static get HOLD_ALL_LOCKED(): RouterState { return this.ROUTER_STATES[RouterStates.HOLD_ALL_LOCKED]; }





}

