import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PartInfoComponent } from '../part-info/part-info.component';
import { PartInfoTabsComponent } from '../part-info/part-info-tabs/part-info-tabs.component';
import { partition } from 'rxjs';


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([
            { path: 'parts', component: PartInfoComponent, data: {breadcrumb: 'Part Info Maintenance'}},
            { path: 'parts/:id', component: PartInfoTabsComponent, data: {breadcrumb: 'Part Details', part: ''}}
        ])
    ],
    declarations: []
})
export class PartsInfoModule { }