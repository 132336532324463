export enum AlertReponse {
  OK = 'Ok',
  YES = 'Yes',
  NO = 'No'
}

export enum AlertType {
  NORESPONSE = 'NoResponse',
  OK = 'Ok',
  YESNO = 'YesNo'
}

export enum AlertLevel {
  INFO = 'Information',
  WARNING = 'Warning',
  ERROR = 'Error',
}

export class Alert {
    type: AlertType;
    level: AlertLevel;
    name: string;
    title: string;
    message: string;
    callback: Function;

    constructor(type: AlertType, message: string, level: AlertLevel, callback: Function = null) {
      this.type = type;
      this.level = level;
      this.name = type;
      this.title = level;
      this.message = message;
      this.callback = callback;
    }
}
