import {NgModule, ModuleWithProviders} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import {ShSelectMenuComponent} from './sh-select-menu.component';
import {ShSelectComponent} from './sh-slelect.component';
import {CommonModule} from '@angular/common';
import { ShSelectAddNewValComponent } from './sh-slelectAddNewString.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        VirtualScrollerModule
    ],
    declarations: [
        ShSelectComponent,
        ShSelectAddNewValComponent,
        ShSelectMenuComponent
    ],
    exports: [ShSelectComponent, ShSelectAddNewValComponent]
})
export class ShSelectRootModule1 { }

export class ShSelectModule1 {
    static forRoot(): ModuleWithProviders<ShSelectModule1> {
        return {
            ngModule: ShSelectRootModule1
        };
    }
}
