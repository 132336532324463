import { UnitType } from '../../core/enums/unittype';
import { TestKitCreateComponent } from './test-kit-create/test-kit-create.component';
import { AlertLevel, AlertReponse } from '../../core/models/alert.model';
import { AlertService } from '../../core/services/alert.service';
import { IFileTest, TestDeviceType, TestType } from '../../core/models/test.model';
import { TestServiceHttp } from '../../core/services/test.servicehttp';
import { AfterViewChecked, AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ElementRef} from '@angular/core';
import { IFile, FileUtils } from '../../core/models/file.model';
import { FileServiceHttp } from '../../core/services/file.servicehttp';
import { Helper } from '../../core/services/helper.service';
import { ActivatedRoute } from '@angular/router';
import { ShSelectComponent } from '../../core/shared/sh-slelect.component';

@Component({
  selector: 'test-kit-detail',
  template: require('./test-kit-detail.component.html'),
  // styles: [require('./test-kit-detail.component.css')]
  styles: [require('./test-kit-detail.component.css').toString()]
})
export class TestKitDetailComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {
  files: IFile[] = [];
  file: IFile = null;
  fileTests: IFileTest[] = [];
  selectedFiles: IFile[];
  selectedFileTest: IFileTest = null;
  @ViewChild('selectFile', {static: true}) selectFile: ElementRef;
  @Input() removeFiles: IFile[] = [];
  kitModal = false;
  createModal = false;
  placeholderText = 'Select a file';
  selectedFile: IFile = null;
  editMode : Boolean = false;

  constructor(
    private utils: Helper,
    private fileService: FileServiceHttp,
    private testService: TestServiceHttp,
    private route: ActivatedRoute,
    private alert: AlertService
  ) { }

  get selectFilePlaceholder() {
    return this.placeholderText;
  }

  get canAddTests() {
    //console.log('test-kit-detail.component.canAddTests');
    //console.log(this.selectedFile);
    return this.selectedFile !== null;
  }

  selectFileHandler(event) {
    console.log('test-kit-detail.component');
    console.log(event);
  }

  handleUserSubmit(ft: IFileTest) {
    if (!this.utils.isExistingItem(ft)) {
      this.testService.createFileTest(ft).subscribe(added => {
        this.fileTests.push(added);
        this.fileTests.sort(Helper.sort_description);
        this.createToggle();
      });
    } else {
      this.testService.updateFileTest(ft).subscribe(updated => {
        this.fileTests = this.fileTests.filter(x => x.id !== updated.id) || [];
        this.fileTests.push(updated);
        this.fileTests.sort(Helper.sort_description);
        this.createToggle();
      });
    }
  }

  getNominalValue(test: IFileTest): number {
    let ret = null;
    if (test) {
      ret = (test.min + test.max) / 2;
    }
    return ret;
  }

  getTolerance(test: IFileTest): number {
    let ret = null;
    if (test) {
      ret = (test.max - test.min) / 2;
    }
    return ret;
  }

  ngOnInit() {
    console.log('test-kit-detail.component.ngOnInit');

    this.selectFile.nativeElement.disabled = false;
    this.file = this.route.snapshot.data['file'];
    this.files = this.route.snapshot.data['files'] || [];
    this.fileTests = this.route.snapshot.data['fileTests'] || [];
    console.log(this.file);
    console.log(this.files);
    console.log(this.fileTests);

    if (this.file) {  // editing an existing kit?
      this.selectedFile = this.file;
      this.selectFile.nativeElement.disabled = true;
      this.editMode = true;
      this.files.push(this.file);
      this.fileTests.sort(Helper.sort_description);
    } else {
      this.selectedFile = null;
      this.files.sort(Helper.sortIFileNameRevision);
    }
  }


  addFileTest() {
    this.editMode = false;
    this.selectedFileTest = this.newTestDefaultInfo;
    this.selectedFileTest.specificationName = this.selectedFile.specificationName;
    this.createToggle();
  }

  editFileTest(ft: IFileTest) {
    this.editMode = true;
    this.selectedFileTest = ft;
    this.createToggle();
  }

  deleteFileTest(ft: IFileTest) {
    this.alert.showYesNo('Are you sure you want to delete kit test ' + ft.description + '?',
    AlertLevel.WARNING,
    (ar: AlertReponse) => {
      if (ar === AlertReponse.YES) {
        this.testService.deleteFileTests([ft.id]).subscribe( res => {
          this.testService.getFileTestsForFileId(this.selectedFile.id).subscribe( tests => {
            this.fileTests = tests;
            this.fileTests.sort(Helper.sort_description);
          });
       });
      }
    });
  }

  selectHandler(elem) {
    console.log('test-kit-detail.component.selectHandler');
    this.fileTests = [];
   /* console.log(elem.value);
    const f = this.filteredFiles.find(x => x.specificationName === elem.value);
    if (f) {
      this.testService.getFileTests(f.id).subscribe(tests => {
        this.fileTests = tests;
        this.fileTests.sort(this.sortIFileTestTypeDescription);
      });
    } */

    this.testService.getFileTests(this.selectedFile.specificationName).subscribe(tests => {
      this.fileTests = tests || [];
      this.fileTests.sort(Helper.sort_description);
    });
  }

  sortIFileTestTypeDescription(a: IFileTest, b: IFileTest) {
    let ret = Helper.cmpStringAlphaNumeric(a.type, b.type);
    if (ret === 0) {
      ret = Helper.cmpStringAlphaNumeric(a.description, b.description);
    }
    return ret;
  }

  createToggle() {
    if (this.createModal === false) {
      this.createModal = true;
    } else {
      this.selectedFileTest = null;
      this.createModal = false;
    }
  }
  handleCreate(data) {
    this.createModal = data;
  }

  kitToggle() {
    if (this.selectedFile != null) 
    {
        if (this.kitModal === false) {
        this.kitModal = true;
      } else {
        this.kitModal = false;
      }
    }
    else
    {
      this.alert.showOk('Please select a file to manage.',
      AlertLevel.WARNING,
      (ar: AlertReponse) => {
        if (ar === AlertReponse.OK) { 
        
        }
      });
    }
   
  }
  handleKit(data) {
    this.kitModal = data;
    if (!data) {
      this.testService.getFileTestsForFileId(this.selectedFile.id).subscribe(tests => {
        this.fileTests = tests;
        this.fileTests.sort(Helper.sort_description);
      });
    }
  }

  ngAfterViewChecked() {
    // console.log('tools.component.AfterViewChecked');
     const c1 = $('.col1').width();
     const c2 = $('.col2').width();
     const c3 = $('.col3').width();
     const c4 = $('.col4').width();
     const c5 = $('.col5').width();
    // console.log(c1);
     $('#col1').width(c1);
     $('#col2').width(c2);
     $('#col3').width(c3);
     $('#col4').width(c4);
     $('#col5').width(c5);
     $('.SearchBar').width(c1 + c2 + c3 + c4 + c5);

     const th = $('thead').width();
     $('#Cover').width(th + 7);
     $('#description').focus();
   }

   ngOnDestroy() {
     console.log('tools.component.OnDestroy');
   }
   ngAfterViewInit() {
        // console.log('File.Component.AfterViewInit');
         // runs every time the user re-sizes the window
             $(window).resize(function(){
           // gets td column widths
             const c1 = $('.col1').width();
             const c2 = $('.col2').width();
             const c3 = $('.col3').width();
             const c4 = $('.col4').width();
             const c5 = $('.col5').width();
             const th = $('thead').width();
             // sets th column widths to match td's
             $('#Cover').width(th + 7);
             $('#col1').width(c1);
             $('#col2').width(c2);
             $('#col3').width(c3);
             $('#col4').width(c4);
             $('#col5').width(c5);
             $('.SearchBar').width(c1 + c2 + c3 + c4 + c5);
             console.log ('resize header occured');
             });

       }

       get newTestDefaultInfo(): IFileTest {
        return {
                id: null,
                specificationName: this.selectedFile ? this.selectedFile.specificationName : null,
                description: null,
                type: TestType.UNKNOWN,
                min: 0.0001,
                max: 0.0001,
                frequency: 1,
                notes: null,
                unitType: UnitType.INCH,
                testOrder: 0,
                testDeviceType: TestDeviceType.SERIALIZED,
                testDeviceCount: 1
              };
          }


}
