import { RouterFilesComponentBizLogic } from './router-files.component.bizl';
import { Component, OnInit, AfterContentChecked, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IRouterFile, IFile, MapType, FileType } from '../../core/models/file.model';
import { Helper } from '../../core/services/helper.service';
import { IRouterStep } from '../../core/models/routerstep.model';
import { FormGroup, FormControl } from '@angular/forms';
import { RouterDomainService } from '../../core/services/router-domain.service';
import { PDFService } from '../../core/services/pdf-service';
import { AlertService } from '../../core/services/alert.service';
import { AlertLevel, AlertReponse } from '../../core/models/alert.model';
import { FileServiceHttp } from '../../core/services/file.servicehttp';
import { RouterStepServiceHttp } from '../../core/services/routerstep.servicehttp';
import { SortCriteria} from '../../core/models/sort.model';
import { IRouter } from '../../core/models/router.model';
import { retry } from 'rxjs-compat/operator/retry';

@Component({
  selector: 'router-files',
  template: require('./router-files.component.html'),
  // styles: [require('./router-files.component.css')]
  styles: [require('./router-files.component.css').toString()]
})
export class RouterFilesComponent implements OnInit, AfterContentChecked, OnChanges {
  ROUTER_FILE_STRING: 'ADD TO ROUTER';
  stepId: string = null;
  routerId: string = null;
  routerFiles1: IRouterFile[];
  routerSteps: IRouterStep[];
  FileInfoGroup: FormGroup;
  @ViewChild('filesList', {static: true}) filesList: ElementRef;
  filesListWasVisible = false;
  bizl: RouterFilesComponentBizLogic;

  constructor(private route: ActivatedRoute
    , private fileService: FileServiceHttp
    , private helper: Helper
    , private stepService: RouterStepServiceHttp
    , private domain: RouterDomainService
    , private alert: AlertService
    , private pdfService: PDFService) { }
  ngOnChanges(changes) {
    console.log('router-files.component.ngOnChanges');
    console.log(changes);
  }

  private setRouterFiles(list: IRouterFile[]) {
    this.routerFiles1 = list;
    this.helper.resortList(this.routerFiles1, this.sortRouterFiles);
  }

  

  private addRouterFile(routerFile: IRouterFile) {
    this.routerFiles1.push(routerFile);
    this.helper.resortList(this.routerFiles1, this.sortRouterFiles);
  }

  private removeRouterFile(routerFile: IRouterFile) {
    this.routerFiles1 = this.helper.arrayOrEmpty(this.routerFiles1.filter(x => !(x.id == routerFile.id && x.mapType == routerFile.mapType)));
  }

  private get isVisible(): boolean {
    return this.filesList.nativeElement.offsetParent != null;
  }

  getStepType(file: IRouterFile) {
    if (file.mapType === MapType.ROUTER || file.mapType === MapType.PART) {
      return '(router)';
    } else {
      return file.routerOperationDescription;
    }
  }

  getSpecName(f: IRouterFile) {
     
    var ret:string = "";
    if (!this.helper.isUndefined(f))
    {
      ret = f.specificationName + ' (' + f.mapType.toUpperCase()  + ')';
    }
    return ret;
  }


  ngAfterContentChecked() {
    // console.log('router-files.component.ngAfterContentChecked');
    if (!this.isVisible && this.filesListWasVisible) {
      console.log('router-files.component.ngAfterContentChecked...visible to hidden');
      this.filesListWasVisible = false;
    } else if (this.isVisible && !this.filesListWasVisible) {
        console.log('router-files.component.ngAfterContentChecked...hidden to visible');
        this.filesListWasVisible = true;
        this.stepService.getRouterSteps(this.routerId).subscribe(steps => {
          this.routerSteps = this.helper.arrayOrEmpty(steps);
          this.FileInfoGroup.patchValue({
            routerOperation: this.ROUTER_FILE_STRING
          });
        });

    }
  }

  ngOnInit() {
    console.log('router-files.components.ngOnInit');
    this.FileInfoGroup = new FormGroup({
      routerOperation: new FormControl()
    });

    this.routerId = this.route.snapshot.params['id'];
    this.bizl = new RouterFilesComponentBizLogic(this.domain.selectedRouter, this.domain);

    if (!this.helper.isEmptyString(this.routerId)) {
      this.fileService.getFilesForRouter(this.routerId).subscribe(files => {
        this.setRouterFiles(this.helper.arrayOrEmpty(files));
        this.helper.resortList(this.routerFiles1, this.sortRouterFiles);
      });
    }
  }


  onSorted(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'name')
          {
            this.routerFiles1.sort(this.sortByDescriptionDesc)
          }
          else if (criteria.sortColumn == 'name')
          {
            this.routerFiles1.sort(this.sortByNameDesc)
          }
          else if (criteria.sortColumn == 'revision')
          {
            this.routerFiles1.sort(this.sortByRevisionDesc)
          }
          else
          {
            this.routerFiles1.sort(this.sortByDescriptionDesc)
          }
          
        }
        else {
          if(criteria.sortColumn == 'name')
          {
            this.routerFiles1.sort(this.sortByDescriptionAsc)
          }
          else if (criteria.sortColumn == 'name')
          {
            this.routerFiles1.sort(this.sortByNameAsc)
          }
          else if (criteria.sortColumn == 'revision')
          {
            this.routerFiles1.sort(this.sortByRevisionAsc)
          }
          else
          {
            this.routerFiles1.sort(this.sortByDescriptionAsc)
          }
        }
      }
      
  }

  sortByDescriptionAsc(a1: IRouterFile, a2: IRouterFile) { 
    return Helper.cmpString(a1.description, a2.description); 
  }
  
  sortByDescriptionDesc(a1: IRouterFile, a2: IRouterFile) {
    return Helper.cmpStringDesc(a1.description, a2.description); 
  }

  sortByNameAsc(a1: IRouterFile, a2: IRouterFile){ 
    return Helper.cmpString(a1.name, a2.name); 
    //return a1[criteria.sortColumn] < a2[criteria.sortColumn];
  }
  
  sortByNameDesc(a1: IRouterFile, a2: IRouterFile) { 
    return Helper.cmpStringDesc(a1.name, a2.name); 
  }    

  sortByRevisionAsc(a1: IRouterFile, a2: IRouterFile){ 
    return Helper.cmpString(a1.revision, a2.revision); 
    //return a1[criteria.sortColumn] < a2[criteria.sortColumn];
  }
  
  sortByRevisionDesc(a1: IRouterFile, a2: IRouterFile) { 
    return Helper.cmpStringDesc(a1.revision, a2.revision); 
  }

canDeleteFile(rf: IRouterFile): boolean {
  return (rf && rf.mapType === MapType.ROUTER || rf.mapType === MapType.STEP);
}

selectRouterFile() {
  this.overlayOpen();
}

viewFile(file: IFile) {
  this.pdfService.show(file.url);
 }

  deleteRouterFile(rf: IRouterFile) {
    this.alert.showYesNo('Are you sure you want to delete file ' + rf.name + '?',
    AlertLevel.WARNING,
    (ar: AlertReponse) => {
      console.log(ar);
      console.log(ar === AlertReponse.OK);
      console.log(AlertReponse.OK);
      if (ar === AlertReponse.YES) {
        this.fileService.removeFileFromRouter(rf).subscribe(ret => {
          console.log('removeFitleFromRouter ret:' + ret);
          this.removeRouterFile(rf);
        });

      }
    });




  }

  handleFileSelected(file: IFile) {
    console.log('router-files.component.handleFileSelected file:' + file.id);
    const step = this.FileInfoGroup.controls.routerOperation.value;
    console.log('......step:' + step);
    if (step === this.ROUTER_FILE_STRING) {
      this.fileService.addFileToRouter(this.routerId, file).subscribe(added => {
       console.log('...added to router:' + added.id);
       console.log(added);
       this.addRouterFile(added);
        this.closeOverlay();
      });
    } else if (step != null) {
      this.fileService.addFileToRouterStep(step, file).subscribe(added => {
        console.log('...added to router step:' + step.id);
        console.log(added);
        this.addRouterFile(added);
        this.closeOverlay();
      });
    }


  }

  closeOverlay() {
    document.getElementById('SpecOverlay').style.display = 'none';
  }
  overlayOpen() {
    document.getElementById('SpecOverlay').style.display = 'block';
    const modal = document.getElementById('SpecOverlay');

    // When the user clicks anywhere outside of the modal, this closes it
    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = 'none';
        }
    };
  }


  private sortRouterFiles(x1: IRouterFile, x2: IRouterFile) {
    if (x1.name < x2.name)
          return -1;
    else if (x1.name > x2.name) 
      return 1;
    else 
      return 0;       
  }

 /*  private sortRouterFiles(x1: IRouterFile, x2: IRouterFile) {

    if (x1.routerOperationNumber < x2.routerOperationNumber) {
      return -1;
    } else if (x1.routerOperationNumber > x2.routerOperationNumber) {
      return 1;
    } else {

      if (x1.mapType < x2.mapType) {
        return -1;
      } else if (x1.mapType > x2.mapType) {
        return 1;
      } else {
        if (x1.name < x2.name) {
          return -1;
        } else if (x1.name > x2.name) {
          return 1;
        } else {
          return 0;
        }
      }
    } 
  } */


}
