import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTool'
})
export class FilterToolPipe implements PipeTransform {

  transform(tools: any, term: any): any {
    // check if term is undefined
    if (term === undefined || term.length == 0 || tools === null) {
      return tools;
    }
    // return the updated routers array
    return tools.filter(function(t){
    return t.description.toLowerCase().includes(term.toLowerCase());
    });
  }
}
