import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import "rxjs/add/observable/of";
import { IEtchingInfo, IPart, PartTypeUtils, TraceTypeUtils } from '../models/part.model';
import { Http, Response } from '@angular/http';
import { Helper } from './helper.service';
import { UnitTypeUtils } from '../enums/unittype';
import { AlertService } from './alert.service';
import { GateKeeperService} from '../shared/gatekeeper/user/user.service';
import { Config } from '../shared/gatekeeper/config';
import { MethodType, MethodTypes } from '../enums/methodtype';
import 'rxjs/add/observable/throw';
import { INDEType_RetrieveSpecs, NDEType, INDEType_RetrieveInspectionParametersByGroup, NDEType_RetrieveAllPartNDEMappings, PartNDEMappingNew } from '../models/nde.model'
import { IFileExtended } from '../models/file.model';


@Injectable()
export class FileCacheService {

  CACHE_KEY = 'lsFilesKey';
  LASTCACHED_TIMESTAMP_KEY = 'lsFilesLastCachedTimestampKey';
  LASTRESET_TIMESTAMP_KEY = 'lsFilesLastResetTimestampKey';

  GETCHANGES_INTERVAL = 1000 * 60 * 5; // milliseconds
  RESET_INTERVAL = 1000 * 60 * 60 * 24 * 25; // retrieve all parts 

  td: TDiff = new TDiff();

  constructor(
    private utils: Helper) {
    //console.log('file.cache.service.CONSTRUCTOR');
    //this.cacheTimeStamp = new Date('1990-01-01:00:00:000').getTime();
  }

set cacheResetTimestamp(timestamp: number){
  //console.log('files.cache.service.setCacheResetTimestamp ' + new Date(timestamp).toLocaleString());
  localStorage.setItem(this.LASTRESET_TIMESTAMP_KEY, timestamp.toString());
}

get cacheResetTimestamp(): number{
  const t =  +localStorage.getItem(this.LASTRESET_TIMESTAMP_KEY);
  //console.log('file.cache.service.getCacheResetTimestamp ' + new Date(t).toLocaleString());
  return t;
}

set cacheTimeStamp(timestamp: number){
  //console.log('file.cache.service.setCacheTime timeStamp:' + new Date(timestamp).toString() + ' timeStampLocal:' + new Date(timestamp).toLocaleString());
  localStorage.setItem(this.LASTCACHED_TIMESTAMP_KEY, timestamp.toString());
}

get cacheTimeStamp(): number{
  const t =  +localStorage.getItem(this.LASTCACHED_TIMESTAMP_KEY);
  //console.log('file.cache.service.getCacheTime timeStamp:' + new Date(t).toString() + ' timeStampLocal:' + new Date(t).toLocaleString());
  return t;
}

get shouldGetChanges(): boolean {
  return true;
  /*const ret = (Date.now() - this.cacheTimeStamp > this.GETCHANGES_INTERVAL);
  console.log('file.cache.service.isCacheExpired:' + (ret ? 'YES' : 'NO'));
  return ret;*/
}

get shouldResetCache(): boolean {
  const ret = (Date.now() - this.cacheResetTimestamp > this.RESET_INTERVAL);
  //console.log('file.cache.service.shouldResetCache:' + (ret ? 'YES' : 'NO'));
  return ret;
}

setLocalStorage(key: string, data: any[]) {
  //console.log('file.cache.service.setLocalStorage length:' + (!this.utils.isEmptyArray(data) ? data.length : 'NULL'));
  this.td.mark();
  localStorage.setItem(key, JSON.stringify(data));
  this.td.mark('SET local storage key:' + key);
}

getLocalStorage(key: string): IFileExtended[] {
  console.log('file.cache.service.setLocalStorage');
  this.td.mark();
  const ret = <IFileExtended[]>JSON.parse(localStorage.getItem(key));
  this.td.mark('GET local storage key:' + key);
 // console.log(ret);
  return ret;
}

private getIds(files: IFileExtended[]): string[] {
  const ret = [];
  if (!this.utils.isEmptyArray(files)) {
    for (const f of files) {
      ret.push(f.id);
    }
  }
  return ret;
}

/*
private getInActiveIds(files: IFileExtended[]): string[] {
  const ret = [];
  if (!this.utils.isEmptyArray(files)) {
    for (const f of files) {
      if (!f.active)
        ret.push(f.id);
    }
  }
  return ret;
}
*/

public update(files: IFileExtended[]) {
  console.log('file.cache.service.update:');

  if (!this.utils.isEmptyArray(files)) {
    const fileIds = this.getIds(files);
    const activeFiles = files.filter(x=>x.active == true) || [];
    var cachedFiles = this.getLocalStorage(this.CACHE_KEY) || [];
    //removes updated and deleted and replaced files
    console.log('file.cache.service.update remove file count:' + this.utils.getArrayLengthStr(fileIds));
    cachedFiles = cachedFiles.filter(x => !fileIds.includes(x.id)) || [];
    //adds updated and new files
    console.log('file.cache.service.update adding active file count:' + this.utils.getArrayLengthStr(activeFiles));

    cachedFiles = cachedFiles.concat(activeFiles);
    this.setLocalStorage(this.CACHE_KEY, cachedFiles);
    this.cacheTimeStamp = Date.now();
  }
}


  public load(files:IFileExtended[]) {
    console.log('file.cache.service.load' +
    (!this.utils.isEmptyArray(files) ? files.length : 'NULL'));

    if (!this.utils.isEmptyArray(files)) {
      this.setLocalStorage(this.CACHE_KEY, files);
      this.cacheTimeStamp = Date.now();
    }
  }

  public getAll(): IFileExtended[] {
    console.log("file.cache.service.getAll");
    var ret = this.getLocalStorage(this.CACHE_KEY) || [];
    return ret;
    }

}




export class TDiff {
  private prev = 0;
  private cur = 0;

  static log(msg: string, t1: number, t2: number) {
    console.log('CLOCK: ' + msg + ':' + (t2 - t1) + ' milliseconds');
  }

  constructor() { }

  mark(msg: string = null) {
    this.prev = this.cur;
    this.cur = Date.now();
    if (msg != null) {
      console.log('CLOCK: ' + msg + ':' + (this.cur - this.prev) + ' milliseconds');
    }
  }
}