// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#NavigationHeaderComponent {\r\n    position: fixed;\r\n    width: 100%;\r\n    /* height: 50px; */\r\n    top: 10px;\r\n    left: 0;\r\n    z-index: 1000;\r\n}\r\n#RouterListingComponent {\r\n    margin-top: 15px;\r\n    /* scrolling adjustment */\r\n    /* position:fixed;\r\n    top:70px;\r\n    width: 99.5%; */\r\n}\r\n\r\n/*Remove after BreadCrumb Component is built*/\r\n#BreadCrumbComponent {\r\n    width: 100%;\r\n    height: 36px;\r\n    background-color: #bbdefb;\r\n    padding: 0px 8px;\r\n    margin: 40px 0px -4px -8px;\r\n}\r\nh3 {\r\n    color: #616161;\r\n    font-weight: 300;\r\n    position: relative;\r\n    top: 5px;\r\n    left: 5px;\r\n}\r\nh3>span {\r\n    font-weight: 900;\r\n}\r\n/*Remove After BreadCrumb Component is Built*/\r\n", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();