import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterWorkStationAuthorized'
})
export class FilterWorkStationAuthorizedPipe implements PipeTransform {

  transform(workStationsAuthorized: any, term: any): any {
    // check if term is undefined
    if (term === undefined || term.length == 0 || workStationsAuthorized === null) {
      return workStationsAuthorized;
    }
    // return the updated routers array
    return workStationsAuthorized.filter(function(w){
    return w.workCenterDescription.toLowerCase().includes(term.toLowerCase()) || w.workStationNumber.toLowerCase().includes(term.toLowerCase()) || w.description.toLowerCase().includes(term.toLowerCase());
    });
  }
}