import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import "rxjs/add/observable/of";
import { StepOrder } from '../models/routerstep.model';
import { IFileTest, ITest, TestUtils } from '../models/test.model';
import { Http, Response } from '@angular/http';
import { Helper } from './helper.service';
// import { AuthService } from '../../core/services/auth.service';
import { AppHttpClientService } from './app-http-client.service';
import { FileTestUtils } from '../models/test.model';
import { GateKeeperService} from '../shared/gatekeeper/user/user.service';
import { Config } from '../shared/gatekeeper/config';
import { MethodType, MethodTypes } from '../enums/methodtype';


@Injectable()
export class TestServiceHttp {


  constructor(private helper: Helper,
    private http: Http,
    // private auth: AuthService,
    private ahttp: AppHttpClientService,
    private gkauth: GateKeeperService,
    private Config: Config) { }

  createTest(t: ITest): Observable<ITest> {
    console.log('TestServiceHttp.createTest');
    const info = TestUtils.toServerInfo(t);
    info.userId = this.gkauth.currentUser.UserID;
 

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'tests/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       //console.log(response);
       const test =  this.translateToITest(response);
       //console.log(test);
       return test;
    });

    }


    getTest(id: string): Observable<ITest> {

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'tests/' + id;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      const test =  this.translateToITest(response);
         return test;
      });

    }

    getRouterTests(routerId: string): Observable<ITest[]> {
      // console.log('TestServiceHttp.getRouterTests');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'tests/GetTestsForRouter/' + routerId;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         const tests =  this.translateToITests(response);
         return tests;
      });

    }

getRouterStepTests(routerStepId: string): Observable<ITest[]> {
  // console.log('TestServiceHttp.getRouterStep');

  Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'tests/GetTestsForRouterStep/' + routerStepId;
  Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
     const tests =  this.translateToITests(response);
     return tests;
  });

    }


    updateOrder(stepOrder: StepOrder): Observable<number> {
      console.log('TestServiceHttp.updateOrder');

    stepOrder.userId = this.gkauth.currentUser.UserID;
    //console.log(stepOrder);

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'tests/UpdateOrder/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(stepOrder);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        return Number(response);
      });
    }

    updateTest(t: ITest): Observable<ITest> {
      console.log('TestServiceHttp.updateTest');
      const info = TestUtils.toServerInfo(t);
      info.userId = this.gkauth.currentUser.UserID;

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'tests/UpdateTest/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         //console.log(response);
         const test =  this.translateToITest(response);
         //console.log(test);
         return test;
      });
    }

    updateAssignStepToTests(stepId: string, testIds: string[]): Observable<number> {
      console.log('TestServiceHttp.updateTest');
      const info = {
        stepId: stepId,
        ids: testIds
      };

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'tests/UpdateAssignStep/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         //console.log(response);
        return Number(response);
      });
    }

  deleteTest(id: string): Observable<number> {
    console.log('TestServiceHttp.deleteTest');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'tests/' + id + '/' +
      this.gkauth.currentUser.UserID;

    Config.gkParameters.MethodType = new MethodType(MethodTypes.DELETE);
    Config.gkParameters.JsonParameters = '';

    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
      //console.log(response);
      return Number(response);
    });
  }

  translateToITest(data: any): ITest {
    if (data) {
      return TestUtils.toITest(data);
    } else {
      return null;
    }
  }

  translateToITests(data): ITest[] {
    const ret: ITest[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToITest(d));
      }
    }
    return ret;
  }


   /* File tests */

   createFileTest(t: IFileTest): Observable<IFileTest> {
      console.log('TestServiceHttp.createFileTest');
      const info = FileTestUtils.toServerInfo({}, t);

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'fileTests/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        //console.log(response);
        const test =  this.translateToIFileTest(response);
        //console.log(test);
        return test;
      });
    }

    getFileTest(id: string): Observable<IFileTest> {
     // console.log('TestServiceHttp.getFileTest');
    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'fileTests/' + id;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         const test =  this.translateToIFileTest(response);
         return test;
      });
    }

    getFileTests(specificationName: string): Observable<IFileTest[]> {
      // console.log('TestServiceHttp.getFileTests');
    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'FileTests/File/' + specificationName;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         const tests =  this.translateToIFileTests(response);
         return tests;
      });
    }

    getFileTestsForFileId(fileId: string): Observable<IFileTest[]> {
      // console.log('TestServiceHttp.getFileTests');
    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'FileTests/ForFileId/' + fileId;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         const tests =  this.translateToIFileTests(response);
         return tests;
      });
    }

    getRouterKitTests(routerId: string): Observable<ITest[]> {
      // console.log('TestServiceHttp.getFileTests');
    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'tests/KitTestsForRouter/' + routerId;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.GET);
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         const tests =  this.translateToITests(response);
         return tests;
      });
    }

    // update a template test
    updateFileTest(t: IFileTest): Observable<IFileTest> {
      console.log('TestServiceHttp.updateFileTest');
      const info = FileTestUtils.toServerInfo({}, t);
      
      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'FileTests/Update/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);
     
   return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
         //console.log(response);
         const test =  this.translateToIFileTest(response);
         //console.log(test);
         return test;
      });
    }

    // update a set of template tests
     updateFileTests(tests: IFileTest[]): Observable<number> {
      console.log('TestServiceHttp.updateFileTests');
      if (this.helper.isEmptyArray(tests)) {
        return Observable.of(1);
      }

      const info = {
        specificationName : tests[0].specificationName,
        tests: FileTestUtils.toServerInfos(tests)
      };
      
      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'fileTests/UpdateTests/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);
      
    return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        //console.log(response);
        return Number(response);
      });
    }
    replaceFileTests(toSpecName: string, fromSpecName: string): Observable<number> {
      console.log('TestServiceHttp.replaceFileTests');
      const info = {
        toSpecName: toSpecName,
        fromSpecName: fromSpecName
      };

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'fileTests/replace/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);
      
      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        //console.log(response);
        return Number(response);
      });
    }


    appendAllFileTests(toSpecName: string, fromSpecName: string): Observable<number> {
      console.log('TestServiceHttp.appendAllFileTests');
      const info = {
        toSpecName: toSpecName,
        fromSpecName: fromSpecName
      };

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'fileTests/AppendAll/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);
     
      console.log('TestServiceHttp.appendAllFileTests call out to API');
      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        //console.log(response);
        return Number(response);
      });
    }

    appendFileTestsToFile(fileId: string, ids: string[]): Observable<number> {
      console.log('TestServiceHttp.appendFileTestsToFIle');
      const info = {
        fileId: fileId,
        ids: ids
      };

      Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'FileTests/AppendByIds/';
      Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
      Config.gkParameters.JsonParameters = JSON.stringify(info);
     
      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        //console.log(response);
        return Number(response);
      });
    }


  deleteFileTests(ids: string[]): Observable<number> {
    console.log('TestServiceHttp.deleteFileTests');
    const info = {
      ids: ids
    };

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'FileTests/DeleteMulti/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.POST);
    Config.gkParameters.JsonParameters = JSON.stringify(info);
    
     return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
       //console.log(response);
      return Number(response);
    });
  }

  deleteFileTestsForFile(specificationName: string): Observable<number> {
    console.log('TestServiceHttp.deleteFileTestsForFile');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'FileTests/DeleteForFile/' + specificationName;
    Config.gkParameters.MethodType = new MethodType(MethodTypes.DELETE);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        //console.log(response);
        return Number(response);
    });
  }

  deleteAllFileTests(): Observable<number> {
    console.log('TestServiceHttp.deleteAllFileTests');

    Config.gkParameters.UriDestination = Helper.WEBAPIHOST + 'FileTests/DeleteAll/';
    Config.gkParameters.MethodType = new MethodType(MethodTypes.DELETE);

      return this.gkauth.post(Config.gateKeeperUrl, Config.gkParameters).map((response: Response) => {
        //console.log(response);
      return Number(response);
    });
  }


  translateToIFileTest(data: any): IFileTest {
    if (data) {
      return FileTestUtils.toIFileTest(data);
    } else {
      return null;
    }
  }

  translateToIFileTests(data): IFileTest[] {
    const ret: IFileTest[] = [];
    if (data && data.length > 0) {
      for (const d of data){
          ret.push(this.translateToIFileTest(d));
      }
    }
    return ret;
  }
}







