import { Injectable } from '@angular/core';
import { IWorkCenter, WorkCenterType, ScheduleType } from '../models/workcenter.model';
import { IWorkStation, WorkStationType } from '../models/workstation.model';




@Injectable()
export class WorkCenterDataService {
  private _selectedWorkCenter: IWorkCenter = null;
  doCopy:Boolean = false;
  selectedWorkStation: IWorkStation = null;
  workCenters: IWorkCenter[] = null;

  workCenterTypes: string[] = ['MACHINING', 'INSPECTION', 'DISPOSITION'];
  scheduleTypes: string[] = ['STANDARD', 'ON DEMAND'];
  warehouses: string[] = ['MAIN', 'MFGSCT', 'MPWTX', 'MPSTX' ];

  set selectedWorkCenter(w:IWorkCenter)
  {
    this.doCopy = false;
    this._selectedWorkCenter = w;
  }

  get selectedWorkCenter(): IWorkCenter
  {
    return this._selectedWorkCenter;
  }

  get newWorkCenterTemplate(): IWorkCenter  {
    return{
    id: null,
    name: '',
    code: 0,
    type: WorkCenterType.MACHINING,
    description: null,
    isOutsource: false,
    scheduleType: ScheduleType.STANDARD,
    warehouse: 'MAIN',
    inUse : false
    };
  }
  get newWorkStationTemplate(): IWorkStation  {
    return {
    id: null,
    number: null,
    description: null,
    workCenterId: null,
    type: WorkStationType.PHYSICAL,
    inUse : false
    };
  }

  constructor() { }



}






