import { AuthService } from '../core/services/auth.service';
import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterDomainService } from '../core/services/router-domain.service';
import { Helper } from '../core/services/helper.service';
import { StepNotesComponent } from './step-notes/step-notes.component';
import { StepInfoComponent } from './step-info/step-info.component';
import { IRouterStep, IRouterStepNotes } from '../core/models/routerstep.model';
import { GateKeeperService} from '../core/shared/gatekeeper/user/user.service';

@Component({
  selector: 'app-step-detail',
  template: require('./step-detail.component.html'),
  // styles: [require('./step-detail.component.css')]
  styles: [require('./step-detail.component.css').toString()]
})
export class StepDetailComponent implements OnInit, OnDestroy {
  routerId: string;
  stepId: string;
  @ViewChild('stepNotes', {static: true}) stepNotes: StepNotesComponent;
  @ViewChild('stepInfo', {static: true}) stepInfo: StepInfoComponent;


  get hasNotesChanges() {
    return this.stepNotes.hasChanges;
  }

  get newNotes(): IRouterStepNotes {
    return this.stepNotes.newNotes;
  }

  setUserNotes(notes:string):void {
    this.stepNotes.setUserNotes(notes);
  }

  get hasStepChanges(){
    return this.stepInfo.hasChanges;
  }

  get newStep(): IRouterStep{
    return this.stepInfo.newStep;
  }



  constructor(private route: ActivatedRoute,
    private router: Router,
    private utils: Helper,
    private domain: RouterDomainService,
    private auth: AuthService) { }

  ngOnDestroy() {
    console.log('step-detail.component.ngOnDestroy');
    console.log(this.router.url);
    if (!this.router.url.includes('routers/')) {     
      if(this.auth.systemLock != null)  
      {
         this.auth.unlockItem(this.auth.systemLock.id).subscribe(res => {
          console.log('router-listing component unlockItem returns:' + res);
        });
      } 
    }
  }

  ngOnInit() {
    console.log('step-detail.component.ngOnInit');
    this.routerId = this.route.snapshot.params['id'];
    this.stepId = this.route.snapshot.params['stepId'];
    
    this.utils.infoTabStatus(false);

    // redirect to login screen if user tries to manually enter or copy/past step details url
    if (!this.utils.isExistingItem(this.domain.selectedRouter, this.routerId) ||
        !this.utils.isExistingItem(this.domain.selectedRouterStep, this.stepId)
  ) {
      this.router.navigate(['/routers']);
    }
  }



  tabSwitch(evt, tabName) {    
    let i, x, tablinks;
    x = document.getElementsByClassName('tab');
    for (i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
    }
    tablinks = document.getElementsByClassName('tabLink');    
    for (i = 0; i < x.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' tabColor', '');
    }    
    document.getElementById(tabName).style.display = 'block';
    evt.currentTarget.className += ' tabColor';
  }
}
