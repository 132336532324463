import {bindCallback} from 'rxjs/observable/bindCallback';
import { Component, Input, OnInit, ViewChild, ElementRef, AfterContentChecked, OnDestroy, EventEmitter, Output } from '@angular/core';
import { WorkCenterDataService } from '../../core/services/workcenter-data.service';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { IWorkCenter, ScheduleTypeUtils, WorkCenterTypeUtils, WorkCenterUtils, ScheduleType, WorkCenterType } from '../../core/models/workcenter.model';
import { WorkCenterService } from '../../core/services/workcenter.service';
import { WorkCenterServiceHttp } from '../../core/services/workcenter.servicehttp';
import { Helper } from '../../core/services/helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkCenterInfoComponentBizLogic } from './work-center-info.component.bizl';
import { restrictedCharacters } from '../../core/validators';

@Component({
  selector: 'work-center-info',
  template: require('./work-center-info.component.html'),
  styles: [require('./work-center-info.component.css').toString()]
})

export class WorkCenterInfoComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Input() cancelSubmitEnabled = true;
  @Output() saveNewWorkCenter = new EventEmitter();
  @Output() cancelComponent = new EventEmitter();
  WorkCenterDetails: FormGroup;
  name: FormControl;
  type: FormControl;
  scheduleType: FormControl;
  location: FormControl;
  description: FormControl;
  isOutsource: FormControl;
  @ViewChild('workCenterDetails', {static: true}) view: ElementRef;
  @ViewChild("saveBtn", {static: true}) saveBtn: ElementRef;
  viewWasVisible = false;
  workCenterId: string;
  workCenterTypes = WorkCenterTypeUtils.types;
  scheduleTypes = ScheduleTypeUtils.types;
  bizl: WorkCenterInfoComponentBizLogic = null;
  lastWorkCenterDescriptionValidationMsg: string = null;
  get workCenters(): IWorkCenter[] { return this.data.workCenters; }
  userSubmitted = false;
  editMode: Boolean = false;

  constructor(public data: WorkCenterDataService
            , private centerService: WorkCenterService
            , private workCenterService: WorkCenterServiceHttp
            , private helper: Helper
            , private navRouter: Router
            , private route: ActivatedRoute ) { }

  get selectedWorkCenter(){
    return this.data.selectedWorkCenter;
  }

  ngOnInit() {
    console.log('work-center-info.component.ngOnInit');
    this.bizl = new WorkCenterInfoComponentBizLogic(this);

    this.workCenterId = this.route.snapshot.params['id'];

    this.name = new FormControl('', [Validators.required, restrictedCharacters([';']), this.uniqueWorkCenterName(this)]);
    this.type = new FormControl('', Validators.required );
    this.scheduleType = new FormControl('', Validators.required);
    this.location = new FormControl('', Validators.required);
    this.description = new FormControl('', [Validators.required, restrictedCharacters([';']) ]);
    this.isOutsource = new FormControl('', Validators.required);

    this.WorkCenterDetails = new FormGroup({
      name: this.name,
      type: this.type,
      scheduleType: this.scheduleType,
      location: this.location,
      description: this.description,
      isOutsource: this.isOutsource
    });
    console.log(this.WorkCenterDetails);

    
  }

  uniqueWorkCenterName(info:WorkCenterInfoComponent)
  {
      return (control: FormControl):{[key:string]: any} => {
        let centers = info.workCenters;
        let center = info.newWorkCenterInfo;

        console.log("WorkCenterInfoComponent.uniqueWorkCenterName centers.len:" + String(centers? centers.length:"NULL") + " center name:" + String(center? center.name: "NULL"));

        if (this.helper.isEmptyArray(centers) || !center)
          return null;

        return !info.bizl.hasUniqueCenterName(centers, center)
      ? {'uniqueWorkCenterName':'Must be unique'}
      : null;

      }      
  }






  updateWorkCenterFormGroup(wc: IWorkCenter) {
    console.log('work-center-info.updateWorkCenterFormGroup');
    console.log(this.bizl);
    this.WorkCenterDetails.reset();
    this.WorkCenterDetails.patchValue({
      name: wc.name,
      type: wc.type,
      scheduleType: wc.scheduleType,
      location: wc.warehouse,
      description: wc.description,
      isOutsource: wc.isOutsource
    });
/* 
    //-- Always allow editing
    //if (this.bizl.isWorkCenterEditable) {
      this.WorkCenterDetails.enable();
    //} else {
    //  this.WorkCenterDetails.disable();
    //} */

  }

 fromFormValues(): IWorkCenter {
    
   return {
      id: this.selectedWorkCenter != null ? this.selectedWorkCenter.id : null,
      name: this.name? this.name.value: '',
      type: this.type? this.type.value: WorkCenterType.MACHINING,
      code: this.selectedWorkCenter != null ? this.selectedWorkCenter.code : 0,
      scheduleType: this.scheduleType? this.scheduleType.value: ScheduleType.ONDEMAND,
      warehouse: this.location? this.location.value: "MAIN",
      description: this.description? this.description.value: "",
      isOutsource: this.isOutsource? this.isOutsource.value: false,
      inUse: this.selectedWorkCenter != null ? this.selectedWorkCenter.inUse : false
   };
 }

  get newWorkCenterInfo() {
    return this.fromFormValues();
  }

  get isWorkCenterInfoFormValid() {
    console.log('WorkCenterInfoComponent.isWorkCenterInfoFormValid');
    console.log(this.userSubmitted);
    console.log(this.WorkCenterDetails);
    console.log(this.bizl);
    const ret =  this.WorkCenterDetails.valid;
    console.log('WorkCenterInfoComponent.isWorkCenterInfoFormValid returns ' + ret);
    return ret;
  }

  get hasWorkCenterDataChanged():boolean
  {    
    return !this.bizl.areEqualWorkCenters(this.selectedWorkCenter, this.newWorkCenterInfo);
  }

  private get isVisible(): boolean {
    return this.view.nativeElement.offsetParent != null;
  }

  ngAfterContentChecked() {
    // console.log('work-center-info.component.ngAfterContentChecked isVisible:' + String( this.isVisible ? 'true' : 'false'));

    if (!this.isVisible && this.viewWasVisible) {
      this.viewWasVisible = false;
        if (this.helper.isExistingItem(this.newWorkCenterInfo)) {
          console.log('work-center-info.component.ngAfterContentChecked...visible to hidden> updating workCenter');
          /* if (this.bizl.canUpdateWorkCenter) {
            this.centerService.updateWorkCenter(this.newWorkCenterInfo).subscribe(updated => {
            });
          } */
        }
    } else if (this.isVisible && !this.viewWasVisible) {
        console.log('work-center-info.component.ngAfterContentChecked...hidden to visible');
        this.viewWasVisible = true;
        this.userSubmitted = false;
        console.log('work-center-info.component.updateWorkCenterFormGroup' + this.selectedWorkCenter);
        this.updateWorkCenterFormGroup(this.selectedWorkCenter);
    }
  }

  ngOnDestroy() {
      console.log('work-center-info.component.ngOnDestroy');
  }

  validateName()
  {
    return this.name.valid || !this.name.dirty;
  }

  validateDescription()
  {
    return this.description.valid || !this.description.dirty;
  }

  cancel()
  {
    console.log('work-center-info.component.cancel');
    this.cancelComponent.emit();

  }

  saveWorkCenter(formValues){
    console.log('work-center-info.component.saveWorkCenter formValid:' + this.WorkCenterDetails.valid);
    console.log('formValues:' + formValues.toString());
    if (this.WorkCenterDetails.valid)
    {
      let workCenter:IWorkCenter = {
        id: undefined,
        name: formValues.name,
        type: formValues.type,
        description: formValues.description,
        code: undefined,
        isOutsource: formValues.isOutsource,
        scheduleType: formValues.scheduleType,
        warehouse: formValues.location,
        inUse: formValues.inUse
      }
  
      this.saveNewWorkCenter.emit(workCenter);

      
    }

   /*  this.workCenterService.updateWorkCenter(formValues).subscribe(updated => {
      this.saveBtn.nativeElement.blur();
    }); */
  }

    ngAfterContentInit() {
      console.log("WorkCenterInfoComponent.ngAfterContentInit");
       $(document).ready(function(){
         $('#workCenterName').focus();
       })
    }

   


}
