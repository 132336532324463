import internal = require("assert");
import { EngManCategory, EngManCategoryUtils } from "../enums/engManCategory";

export interface IFile {
    id: string;
    name: string;
    specificationName: string;
    revision: string;
    description: string;
    type: FileType;
    url: string;
    sourceUrl: string;
    revisionDate: Date;
    itemFileType?: number;
    fileData?: string;
    tokenID?: string;
    holdState?: number;
    holdStateNotes?: string;
    updateManualRevision?: boolean;
}

export interface IFilePartInfo {
  id: string;
  name: string;
  specificationName: string;
  revision: string;
  description: string;
  type: FileType;
  url: string;
  sourceUrl: string;
  revisionDate: Date;
  itemFileType?: number;
  fileData?: string;
  tokenID?: string;
  holdState?: number;
  holdStateNotes?: string;
  updateManualRevision?: boolean;
  isDefault:boolean;
}



export class IFile {
  id: string;
  name: string;
  specificationName: string;
  revision: string;
  description: string;
  type: FileType;
  url: string;
  sourceUrl: string;
  revisionDate: Date;
  itemFileType?: number;
  fileData?: string;
  tokenID?: string;
  holdState?: number;
  holdStateNotes?: string;
  updateManualRevision?: boolean;
}

export interface IRouterFile extends IFile {
    mapType: MapType;
    routerId: string;
    routerOperationId: string;
    routerOperationDescription: string;
    routerOperationNumber: number;
}

export interface IFileExtended extends IFile {
  inUse: boolean;
  active: boolean;
}

export interface IFileExtended1 extends IFileExtended {
  replacedby: string;
  latestRevision: string;
  engManCategory:EngManCategory;
}

export interface IFileBase64 {
  path: string;
  encoding: string;
  byteCharacters: string;
  bytes: Uint8Array;
}

enum FileTypeService {
  UNKNOWN = 0,
  SPEC = 1,
  DRAWING = 2,
  DESIGN = 3
}

export enum FileType {
  UNKNOWN = 'UNKNOWN',
  SPEC = 'spec',
  DRAWING = 'drawing',
  DESIGN = 'design'
}

export class FileUtils {
 

  public static toIFile(info: any): IFile {
    info.type = FileTypeUtils.toFileType(info.type);
    return <IFile> info;
  }


  public static IFileToIFilePartInfo(f:IFile): IFilePartInfo
  {
    var ret = 
    {
      id: f.id,
      name: f.name,
      specificationName:f.specificationName,
      revision: f.revision,
      description: f.description,
      type: f.type,
      url: f.url,
      sourceUrl: f.sourceUrl,
      revisionDate: f.revisionDate,
      itemFileType: f.itemFileType,
      fileData: f.fileData,
      tokenID: f.tokenID,
      holdState: f.holdState,
      holdStateNotes: f.holdStateNotes,
      updateManualRevision: f.updateManualRevision,
      isDefault: false
    };

    return ret;
  }

  public static toIFilePartInfo(info: any): IFilePartInfo {
    info.type = FileTypeUtils.toFileType(info.type);
    info.isDefault= Boolean(info.itemFileType > 0);
    return <IFilePartInfo> info;
  }

}

export class RouterFileUtils {
  public static toServerInfo(f: IRouterFile): any {
    return {
      id: f.id !== null ? f.id : "",
      name: f.name,
      specificationName: f.specificationName,
      revision: f.revision,
      description: f.description,
      type: FileTypeUtils.toFileTypeService(f.type),
      url: f.url,
      sourceUrl: f.sourceUrl,
      mapType: MapTypeUtils.toMapTypeService(f.mapType),
      routerId: f.routerId,
      routerOperationId: f.routerOperationId,
      routerOperationDescription: f.routerOperationDescription,
      routerOperationNumber: f.routerOperationNumber
    };
  }

  public static toIRouterFile(info: any): IRouterFile {
    info.type = FileTypeUtils.toFileType(info.type);
    info.mapType = MapTypeUtils.toMapType(info.mapType);
    return <IRouterFile> info;
  }
}

export class FileTypeUtils {
  public static get types(): FileType[] {
    const ret: FileType[] = [];
    ret.push(FileType.DRAWING);
    ret.push(FileType.SPEC);
    ret.push(FileType.DESIGN);
    return ret;
  }

  public static toFileType(type: FileTypeService): FileType {
    switch (type) {
      case FileTypeService.SPEC:
        return FileType.SPEC;
      case FileTypeService.DRAWING:
        return FileType.DRAWING;
      case FileTypeService.DESIGN:
        return FileType.DESIGN;
      default:
        return FileType.UNKNOWN;
    }
  }

  public static toFileTypeService(type: FileType): FileTypeService {
    switch (type) {
      case FileType.SPEC:
        return FileTypeService.SPEC;
      case FileType.DRAWING:
        return FileTypeService.DRAWING;
      case FileType.DESIGN:
        return FileTypeService.DESIGN;
      default:
        return FileTypeService.UNKNOWN;
    }
  }
}

enum MapTypeService {
  UNKNOWN = -1,
  PART = 2,
  ROUTER = 3,
  STEP = 4,
  TEST = 5
}

export enum MapType {
  UNKNOWN = 'UNKNOWN',
  PART = 'part',
  ROUTER = 'router',
  STEP = 'step',
  TEST = 'test'
}

export class MapTypeUtils {
  public static toMapType(type: MapTypeService): MapType {
    switch (type) {
      case MapTypeService.PART:
        return MapType.PART;
      case MapTypeService.ROUTER:
        return MapType.ROUTER;
        case MapTypeService.STEP:
        return MapType.STEP;
        case MapTypeService.TEST:
        return MapType.TEST;
      default:
        return MapType.UNKNOWN;
    }
  }

  public static toMapTypeService(type: MapType): MapTypeService {
    switch (type) {
      case MapType.PART:
        return MapTypeService.PART;
      case MapType.ROUTER:
        return MapTypeService.ROUTER;
        case MapType.TEST:
        return MapTypeService.TEST;
        case MapType.STEP:
        return MapTypeService.STEP;
      default:
        return MapTypeService.UNKNOWN;
    }
  }
}
