import { AlertService } from '../core/services/alert.service';
import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { PDFService } from '../core/services/pdf-service';
import { FileServiceHttp } from '../core/services/file.servicehttp';
import { Router } from '@angular/router';
import { PartServiceHttp } from '../core/services/part.servicehttp';
import { Helper } from '../core/services/helper.service';
import { GateKeeperService} from '../core/shared/gatekeeper/user/user.service';
import { LoginService } from '../core/services/login.service';



@Component({
    selector: 'app-main',
    template: require('./main.component.html'),
    // styles: [require('./main.component.css')]
    styles: [require('./main.component.css').toString()]
})
export class MainComponent implements OnInit, OnDestroy, OnChanges {
    login = true;
    private _self = this;
    // get showAlert(){return this.alert.currentAlert != null; }
    // get currentAlert() { return this.alert.currentAlert; }

  // set pdfViewable to "true" to test
    get pdfViewable() {
      return this.pdfService.fileUrl != null;
    }

    get loading() {
      return Helper.showSpinner;
    }

    ngOnChanges(changes: SimpleChanges) {
      console.log('main.component.ngOnChanges');
      console.log(changes);

    }

    get pdfFile() { return this.pdfService.fileUrl; }

    constructor(private auth: AuthService,
      private gkAuth: GateKeeperService,
      public alert: AlertService,
      public loginService: LoginService,
      private pdfService: PDFService,
      private partService: PartServiceHttp,
      private fileService: FileServiceHttp,
      private router: Router) {  }

    ngOnInit() {
      console.log('main.component.ngOnInit');
      this.login = true;
      this.gkAuth.forceLogin = this;

      // let filePath = "//mshop-eng1/approved/pdfs/design reviews/misc/gva0005.pdf";
      // filePath = "//cube/ic/intracube/eng/engmanual/specs/9-1515.pdf";

      // this.pdfService.show('https://vadimdez.github.io/ng2-pdf-viewer/pdf-test.pdf');

    }

    forceLogin() {
      console.log('main.component.forceLogin');
      this.login = true;
      this.router.navigate(['/login']);

    }

    ngOnDestroy() {
      console.log('main.component.ngOnDestroy');
      
      if (this.gkAuth.systemLock != null)
      {
        this.auth.unlockItem(this.gkAuth.systemLock.id).subscribe(res1 => {
          this.loginService.logoutUser().subscribe(res2 => { } );
        });
      }
      else
      {
        this.loginService.logoutUser().subscribe(res2 => { } );
      }     
    }

    loginUser(data) {
        console.log('main.component.loginUser data:' + data);
        if (data) {
          this.login = data;
          return;
        }

        this.partService.getParts().subscribe(parts => {
        //  console.log('main.component.loginUser. setting login:' + data);
         this.login = data; // data;
        } ,
        error => console.log('main.component.loginUser getParts returned error') );
    }

    handleClosePdf(data) {
        this.pdfService.handleResponse(data);
     }

     handleLogout() {
       console.log('main.component.handleLogout');
      this.login = true;
      this.router.navigate(['/login']);
     }



  }


