enum EngManCategoryService {
    UNKNOWN = -1,
    DESIGN_CONTROL = 1,
    CARBON_AND_ALLOY_STEEL = 2,
    CASTINGS = 3,
    NONFERROUS_METALS = 4,
    ELASTOMERS = 5,
    HYDROSTATIC_TESTING = 6,
    HYDROSTATIC_DESIGN_VALIDATION = 7,
    WELDING_PROCEDURES_SPECIFICATIONS = 8,
    WELDING_PROCEDURES_QUALIFICATION_RECORDS = 10,
    ENGINEERING_PROCEDURES = 11,
    HEAT_TREATING_PROCEDURES = 12,
    NONDESTRUCTIVE_EXAMINATION = 13,
    STATIC_LOAD_TESTING = 14,
    REFERENCED_STANDARDS = 15,
    DIMENSIONAL_TOLERANCeS = 16
  }
  
  export enum EngManCategory {
    UNKNOWN = "",
    DESIGN_CONTROL = 'Design Control',
    CARBON_AND_ALLOY_STEEL = 'Carbon and Alloy Steel',
    CASTINGS = 'Castings',
    NONFERROUS_METALS = 'Nonferrous Metals',
    ELASTOMERS = 'Elastomers',
    HYDROSTATIC_TESTING = 'Hydrostatic Testing',
    HYDROSTATIC_DESIGN_VALIDATION = 'Hydrostatic Design Validation',
    WELDING_PROCEDURES_SPECIFICATIONS = 'Welding Procedures Specifications',
    WELDING_PROCEDURES_QUALIFICATION_RECORDS = 'Welding Procedure Qualification Records',
    ENGINEERING_PROCEDURES = 'Engineering Procedures',
    HEAT_TREATING_PROCEDURES = 'Heat Treating Procedures',
    NONDESTRUCTIVE_EXAMINATION = 'Nondestructive Examination',
    STATIC_LOAD_TESTING = 'Static Load Testing',
    REFERENCED_STANDARDS = 'Referenced Standards',
    DIMENSIONAL_TOLERANCES = 'Dimensional Tolerances'
  }
  
  export class EngManCategoryUtils {
  
    public static get engManCategoryArray(): string[] {
        return [
            EngManCategory.DESIGN_CONTROL,
            EngManCategory.CARBON_AND_ALLOY_STEEL,
            EngManCategory.CASTINGS,
            EngManCategory.NONFERROUS_METALS,
            EngManCategory.ELASTOMERS,
            EngManCategory.HYDROSTATIC_TESTING,
            EngManCategory.HYDROSTATIC_DESIGN_VALIDATION,
            EngManCategory.WELDING_PROCEDURES_SPECIFICATIONS,
            EngManCategory.WELDING_PROCEDURES_QUALIFICATION_RECORDS,
            EngManCategory.ENGINEERING_PROCEDURES,
            EngManCategory.HEAT_TREATING_PROCEDURES,
            EngManCategory.NONDESTRUCTIVE_EXAMINATION,
            EngManCategory.STATIC_LOAD_TESTING,
            EngManCategory.REFERENCED_STANDARDS,
            EngManCategory.DIMENSIONAL_TOLERANCES
        ];
    }
  
  
  
    public static toEngManCategory(type: EngManCategoryService): EngManCategory {
      switch (type) {
        case EngManCategoryService.DESIGN_CONTROL:
          return EngManCategory.DESIGN_CONTROL;
        case EngManCategoryService.CARBON_AND_ALLOY_STEEL:
          return EngManCategory.CARBON_AND_ALLOY_STEEL;
        case EngManCategoryService.CASTINGS:
          return EngManCategory.CASTINGS;
        case EngManCategoryService.NONFERROUS_METALS:
          return EngManCategory.NONFERROUS_METALS;

        case EngManCategoryService.ELASTOMERS:
            return EngManCategory.ELASTOMERS;
        case EngManCategoryService.HYDROSTATIC_TESTING:
            return EngManCategory.HYDROSTATIC_TESTING;
        case EngManCategoryService.HYDROSTATIC_DESIGN_VALIDATION:
            return EngManCategory.HYDROSTATIC_DESIGN_VALIDATION;
        case EngManCategoryService.WELDING_PROCEDURES_SPECIFICATIONS:
            return EngManCategory.WELDING_PROCEDURES_SPECIFICATIONS;
            
        case EngManCategoryService.WELDING_PROCEDURES_QUALIFICATION_RECORDS:
            return EngManCategory.WELDING_PROCEDURES_QUALIFICATION_RECORDS;
        case EngManCategoryService.ENGINEERING_PROCEDURES:
            return EngManCategory.ENGINEERING_PROCEDURES;
        case EngManCategoryService.HEAT_TREATING_PROCEDURES:
            return EngManCategory.HEAT_TREATING_PROCEDURES;
        case EngManCategoryService.NONDESTRUCTIVE_EXAMINATION:
            return EngManCategory.NONDESTRUCTIVE_EXAMINATION;
            case EngManCategoryService.STATIC_LOAD_TESTING:
                return EngManCategory.STATIC_LOAD_TESTING;
                case EngManCategoryService.REFERENCED_STANDARDS:
                    return EngManCategory.REFERENCED_STANDARDS;
                    case EngManCategoryService.DIMENSIONAL_TOLERANCeS:
                        return EngManCategory.DIMENSIONAL_TOLERANCES;
        default:
            return EngManCategory.UNKNOWN;
      }
    }
  
    public static toEngManCategoryService(type: EngManCategory): EngManCategoryService {
        switch (type) {
            case EngManCategory.DESIGN_CONTROL:
              return EngManCategoryService.DESIGN_CONTROL;
            case EngManCategory.CARBON_AND_ALLOY_STEEL:
              return EngManCategoryService.CARBON_AND_ALLOY_STEEL;
            case EngManCategory.CASTINGS:
              return EngManCategoryService.CASTINGS;
            case EngManCategory.NONFERROUS_METALS:
              return EngManCategoryService.NONFERROUS_METALS;
    
            case EngManCategory.ELASTOMERS:
                return EngManCategoryService.ELASTOMERS;
            case EngManCategory.HYDROSTATIC_TESTING:
                return EngManCategoryService.HYDROSTATIC_TESTING;
            case EngManCategory.HYDROSTATIC_DESIGN_VALIDATION:
                return EngManCategoryService.HYDROSTATIC_DESIGN_VALIDATION;
            case EngManCategory.WELDING_PROCEDURES_SPECIFICATIONS:
                return EngManCategoryService.WELDING_PROCEDURES_SPECIFICATIONS;
                
            case EngManCategory.WELDING_PROCEDURES_QUALIFICATION_RECORDS:
                return EngManCategoryService.WELDING_PROCEDURES_QUALIFICATION_RECORDS;
            case EngManCategory.ENGINEERING_PROCEDURES:
                return EngManCategoryService.ENGINEERING_PROCEDURES;
            case EngManCategory.HEAT_TREATING_PROCEDURES:
                return EngManCategoryService.HEAT_TREATING_PROCEDURES;
            case EngManCategory.NONDESTRUCTIVE_EXAMINATION:
                return EngManCategoryService.NONDESTRUCTIVE_EXAMINATION;
                case EngManCategory.STATIC_LOAD_TESTING:
                    return EngManCategoryService.STATIC_LOAD_TESTING;
                    case EngManCategory.REFERENCED_STANDARDS:
                        return EngManCategoryService.REFERENCED_STANDARDS;
                        case EngManCategory.DIMENSIONAL_TOLERANCES:
                            return EngManCategoryService.DIMENSIONAL_TOLERANCeS;
            default:
                return EngManCategoryService.UNKNOWN;
          }
    }
}