import { FormControl } from "@angular/forms";
import { Helper } from "../services/helper.service";

  export function restrictedWords(words:string[]) {
    return (control: FormControl): {[key: string]: any} => {
      if (!words || !control.value)
        return null;
      
      var invalidWords = words
      .map(w => control.value.includes(w) ? w : null)
      .filter(w => w != null);
     
        
      return !Helper.sIsEmptyArray(invalidWords) ? {'restrictedWords': invalidWords.join(', ') } : null;
    }
  }