import { Helper } from '../core/services/helper.service';
import { FixturesComponentBizLogic } from './fixtures.component.bizl';
import { Component, ViewChild, ElementRef, OnChanges, OnInit,
   DoCheck, AfterContentInit, AfterContentChecked, AfterViewInit, AfterViewChecked,  OnDestroy } from '@angular/core';
import { FormGroup, FormControl, NgModel, Validator, Validators } from '@angular/forms';
import { IFixture } from '../core/models/fixture.model';
import { AlertService } from '../core/services/alert.service';
import { AlertLevel, AlertReponse } from '../core/models/alert.model';
import { FixtureServiceHttp } from '../core/services/fixture.servicehttp';
import { SortCriteria} from '../core/models/sort.model';



@Component({
  selector: 'app-fixtures',
  template: require('./fixtures.component.html'),
  // styles: [require('./fixtures.component.css')]
  styles: [require('./fixtures.component.css').toString()]

})
export class FixturesComponent implements OnChanges, OnInit, DoCheck, AfterContentChecked, AfterViewChecked, AfterViewInit, OnDestroy, AfterContentInit {
  fixtures: IFixture[];
  FixtureFormGroup: FormGroup;
  FixtureDescription: FormControl;
  FixtureNumber: FormControl;
  selectedFixture: IFixture;
  public term: string;
  @ViewChild('fixtureForm' , {static: true}) fixtureForm: ElementRef;
  @ViewChild('table' , {static: true}) table: ElementRef;
  userSubmitted = false;
  overlayWasVisible = false;
  bizl: FixturesComponentBizLogic = null;
  dir ="asc";
  editMode: Boolean = false;
  @ViewChild('inputNumber' , {static: false}) inputNumber;

  constructor(private fixtureService: FixtureServiceHttp,
    private alert: AlertService,
  private utils: Helper) { }

  ngOnChanges() {
    console.log('fixtures.component.OnChanges');
  }

  ngOnInit() {
    console.log('fixtures.component.OnInit');

    this.FixtureDescription = new FormControl('', Validators.compose([
      Validators.required,
      this.makeValidateUniqueDescription()
    ]));

    this.FixtureNumber = new FormControl('',
       Validators.compose([
         Validators.required,
         this.makeValidateUniqueNumber()]));

    this.FixtureFormGroup = new FormGroup({
      number: this.FixtureNumber,
      description: this.FixtureDescription
    });


    this.fixtureService.getFixtures().subscribe(fixtures => {
      this.fixtures = fixtures;
      this.fixtures.sort(this.sortByNumberAsc);
      // this.sortTable(0);
    });
    this.bizl = new FixturesComponentBizLogic(this);
  }
 

  toFormGroup(fixture: IFixture) {
    this.FixtureFormGroup.patchValue({
        number: fixture.number,
        description: fixture.description
    });
  }


  onSorted(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'number')
          {
            this.fixtures.sort(this.sortByNumberDesc)
          }
          else 
          {
            this.fixtures.sort(this.sortByDescriptionDesc)
          }
          
        }
        else {
          if(criteria.sortColumn == 'number')
          {
            this.fixtures.sort(this.sortByNumberAsc)
          }
          else 
          {
            this.fixtures.sort(this.sortByDescriptionAsc)
          }
        }
      }
      
  }

  // sortTable(n, event) {
  //   var elementId: string = (event.target as Element).id;
   
  
  //   if (elementId.substring(0,4) == 'col1' && n == 0)
  //   {    
  //     if (this.dir == 'asc')
  //     {
  //       this.dir = 'desc'
  //       this.fixtures.sort(this.sortByNumberAsc)
  //     }
  //     else 
  //     {
  //       this.dir = 'asc'
  //       this.fixtures.sort(this.sortByNumberDesc)
  //     }
      
  //   }
  //   if (elementId.substring(0,4) == 'col2' && n == 1)
  //   if (this.dir == 'asc')
  //     {
  //       this.dir = 'desc'
  //       this.fixtures.sort(this.sortByDescriptionAsc)
  //     }
  //     else 
  //     {
  //       this.dir = 'asc'
  //       this.fixtures.sort(this.sortByDescriptionDesc)
  //     }
  // }
  
  
  sortByNumberAsc(a1: IFixture, a2: IFixture) { 
    return Helper.cmpString(a1.number, a2.number); 
  }
  
  sortByNumberDesc(a1: IFixture, a2: IFixture) { 
    return Helper.cmpStringDesc(a1.number, a2.number); 
  }
  
  sortByDescriptionAsc(a1: IFixture, a2: IFixture) { 
    return Helper.cmpString(a1.description, a2.description); 
  }
  
  sortByDescriptionDesc(a1: IFixture, a2: IFixture) {
    return Helper.cmpStringDesc(a1.description, a2.description); 
  }

  getNewFixture() {
    return this.fromFormGroup(this.FixtureFormGroup.getRawValue());
  }

  fromFormGroup(info): IFixture {
    return {
      id: this.utils.isExistingItem(this.selectedFixture) ? this.selectedFixture.id : null,
      number : info.number,
      description: info.description,
      inUse: this.utils.isExistingItem(this.selectedFixture) ? this.selectedFixture.inUse : false
    };
  }

  ngDoCheck() {
    console.log('fixtures.component.DoCheck');
  }

  getTitle() {
    if (this.bizl.isNewFixture) {
      return 'Add a Fixutre';
    } else {
      return 'Edit a Fixture';
    }
  }

  resetFormValidation() {
    this.userSubmitted = false;
    // yourForm.reset(), yourForm.resetForm() don't work, but this does:
    this.FixtureFormGroup.markAsPristine();
    this.FixtureFormGroup.markAsUntouched();
    this.FixtureFormGroup.updateValueAndValidity();
  }

  get isOverlayVisible() {
    return this.fixtureForm.nativeElement.offsetParent != null;
  }

  ngAfterContentChecked() {
    if (!this.isOverlayVisible && this.overlayWasVisible) {
      console.log('fixtures.component.ngAfterContentChecked...visible to hidden ');
      this.overlayWasVisible = false;
      this.resetFormValidation();
    } else if (this.isOverlayVisible && !this.overlayWasVisible) {
      console.log('fixtures.component.ngAfterContentChecked...hidden to visible');
      this.overlayWasVisible = true;
      this.userSubmitted = false;
      this.resetFormValidation();
    }
  }

  makeValidateUniqueNumber() {
    const _self = this;
    return function(fc: FormControl) {
      console.log('fixtures.component.validateUniqueNumber');
      const ret = (_self.FixtureFormGroup && _self.bizl.isUniqueNumber(_self.editMode)) ? null : {
        validateUnique: {
          valid: false
        }
      };
       console.log('fixtures.component.validateUniqueNumber ret ' + ret);
      return ret;
    };
  }

  makeValidateUniqueDescription() {
    const _self = this;
    return function(fc: FormControl) {
      console.log('fixtures.component.validateUniqueDescription');
      const ret = (_self.FixtureFormGroup && _self.bizl.isUniqueDescription(_self.editMode)) ? null : {
        validateUnique: {
          valid: false
        }
      };
     console.log('fixtures.component.validateUniqueDescription ret ' + ret);
      return ret;
    };
  }

  get fixtureDescriptionValdationError( ) {
    if (this.FixtureFormGroup.controls.description.errors.validateUnique) {
      return 'Description must be unique';
    } else {
      return 'Required';
    }
   }

   get fixtureNumberValdationError( ) {
    if (this.FixtureFormGroup.controls.number.errors.validateUnique) {
      return 'Number must be unique';
    } else {
      return 'Required';
    }
   }

  validateFixtureNumber() {
    return  (!this.bizl.isNewFixture && this.FixtureFormGroup.controls.number.pristine) ||
                (this.bizl.isNewFixture && !this.userSubmitted) ||
                 this.FixtureFormGroup.controls.number.valid;
  }

  validateNewFixtureInfo() {
    return this.FixtureFormGroup.valid;
  }

  validateFixtureDescription() {
      return  (!this.bizl.isNewFixture && this.FixtureFormGroup.controls.number.pristine) ||
        (this.bizl.isNewFixture && !this.userSubmitted) ||
           this.FixtureFormGroup.controls.description.valid;
    }

  ngAfterViewChecked() {
    //console.log('fixtures.component.AfterViewChecked');
    const c1 = $('.col1').width();
    const c2 = $('.col2').width();

    $('#col1').width(c1 + 1);
    $('#col2').width(c2);
    $('.SearchBar').width(c1 + c2 + 3);

    var th = $('thead').width();
    $("#Cover").width(th);
    // This is disabling all other form controls
    // $('#number').focus();
  }

  ngOnDestroy() {
    console.log('fixtures.component.OnDestroy');
  }

  addFixture() {
    this.editMode = false;
    this.overlayOpen(this.bizl.newFixtureDefaultInfo);
  }

  editFixture(fixture) {
    this.editMode = true;
    this.overlayOpen(fixture);
  }

  deleteFixture(p: IFixture) {
    this.alert.showYesNo('Are you sure you want to delete fixture ' + p.number + ' ' + p.description + '?',
    AlertLevel.WARNING,
    (ar: AlertReponse) => {
      console.log(ar);
      console.log(ar === AlertReponse.OK);
      console.log(AlertReponse.OK);
      if (ar === AlertReponse.YES) {
        this.fixtureService.deleteFixture(p.id).subscribe( res => {
          this.fixtures = this.fixtures.filter(x => x.id !== p.id);
        });
      }
    });
  }

 submit(formValues) {
   console.log('fixtures.components.submit');
   this.userSubmitted = true;
   console.log(this.FixtureFormGroup);
   console.log(this.bizl);
   console.log(this);
   if (!this.validateNewFixtureInfo()) {
     return;
   }

   const p = this.fromFormGroup(formValues);
   console.log(p);
   if (this.bizl.canCreate) {
     console.log('...create');
     this.fixtureService.createFixture(p).subscribe(added => {
       this.fixtures.push(added);
       this.fixtures.sort(this.sortByNumberAsc);       
       this.closeOverlay();
     });
   } else if (this.bizl.canUpdate) {
    console.log('...post');
    this.fixtureService.updateFixture(p).subscribe(updated => {
      this.fixtures = this.fixtures.filter(x => x.id !== updated.id);
      this.fixtures.push(updated);
      this.fixtures.sort(this.sortByNumberAsc);      
      this.closeOverlay();
    });
   } else {
     this.closeOverlay();
   }
 }

  
  closeOverlay() {
    this.selectedFixture = null;    
    document.getElementById('FixtureOverlay').style.display = 'none';
  }
  overlayOpen(f: IFixture) {
    this.selectedFixture = f;
    const _self = this;
    this.toFormGroup(this.selectedFixture);
    document.getElementById('FixtureOverlay').style.display = 'block';
    const modal = document.getElementById('FixtureOverlay');
    this.inputNumber.nativeElement.focus();
    // When the user clicks anywhere outside of the modal, this closes it
    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = 'none';
            _self.selectedFixture = null;
        }
    };
  }

  ngAfterViewInit() {

        console.log('File.Component.AfterViewInit');
    // runs every time the user re-sizes the window
        $(window).resize(function(){
      // gets td column widths
       const c1 = $('.col1').width();
       const c2 = $('.col2').width();
       const th = $('thead').width();
      // sets th column widths to match td's
        $("#Cover").width(th);
        $('#col1').width(c1 + 1);
        $('#col2').width(c2);
        $('.SearchBar').width(c1 + c2 + 2);
        console.log ('resize header occured');
        });


      }
    ngAfterContentInit(){
      $(document).ready(function(){
        $('.SearchField').focus();
      })
    }
}
