import { UnitType } from '../enums/unittype';
// import { NoAttributeParameterDecoratorRule } from 'codelyzer';
import { SpecColorsProcessor } from 'jasmine-spec-reporter/built/processors/spec-colors-processor';
import {Injectable } from '@angular/core';
import {IRouter, RouterType} from '../models/router.model';
import {IRouterStep, IRouterStepNotes, PrintOption, PrintType} from '../models/routerstep.model';
import {IRouterExtended, IRouterStepExtended, IMDPartFile} from '../models/mock-data.model';
import {IBOMItem} from '../models/bom.model';
import {RouterState} from '../enums/router-state';
import {Helper} from './helper.service';
import { ITest, TestDeviceType, TestType } from '../models/test.model';
import { IPart, IPartInfo, IEtchingInfo, TraceType } from '../models/part.model';
import {IWorkCenter, IWorkCenterExtended, WorkCenterType, ScheduleType} from '../models/workcenter.model';
import { FileType, IFile, IRouterFile, MapType } from '../models/file.model';
import {IProgram, IRouterStepProgram } from '../models/program.model';
import {ITool, IToolBag} from '../models/tool.model';
import {IWorkStation, WorkStationType } from '../models/workstation.model';
import {IFixture, IRouterStepFixture} from '../models/fixture.model';
import { PartType } from '../models/part.model';
import { Guid } from '../models/guid.model';
import { IOnshapeDocument } from '../shared/onshape/onshapedocuments.model';


@Injectable()
export class MockDataService {
  ROUTERNOTES = [];
  ROUTERSTEPNOTES = [];
  PARTINFOS: IPartInfo[] = [];
  PARTFILES: IMDPartFile[] = [];
  ROUTERSTEPTOOLS: IToolBag[] = [];

  TOOLS: ITool[] = [
    {
       id: Guid.newGuid(),
       number: 'Tool1',
       description: '1/4-20 PLUG',
       order: 1,
       inUse: false,
       userDefined: true
    },
    {
        id: Guid.newGuid(),
        number: 'Tool2',
        description: '10.315.501 BORING HD',
        order: 1,
        inUse: false,
        userDefined: true
    },
    {
        id: Guid.newGuid(),
        number: 'Tool3',
        description: '1005222R01 (KM50NEL6)',
        order: 1,
        inUse: false,
        userDefined: true
    },
    {
        id: Guid.newGuid(),
        number: 'Tool4',
        description: '11.361.052 EXTENSION',
        order: 1,
        inUse: false,
        userDefined: true
    }];

  PROGRAMS: IRouterStepProgram[] = [
  {
    program: {
      id: Guid.newGuid(),
      number: 'T0209',
      description: 'VC0041S NZX2000 Turret3',
      inUse: false
    },
    stepId: null
  },
  {
    program: {
    id: Guid.newGuid(),
    number: 'T0200',
    description: 'VC0068S NZX2000 Turret1',
    inUse: false},
    stepId: null
  },
  {
    program: {id: Guid.newGuid(),
    number: 'E5644',
    description: 'OA0097 A or B;Drill & Tap 9/16" Autoclave',
    inUse: false},
    stepId: null
  },
  {
    program: {id: Guid.newGuid(),
    number: 'E5981',
    description: 'OA0147;DRILL HOLES/GRS FTTNG-FORGING',
    inUse: false},
    stepId: null
  }];

  FIXTURES: IRouterStepFixture[] = [
    {
      fixture: {
        id: Guid.newGuid(),
        number: '(SL-35) I',
        description: '(SL-35) ID MANDREL WITH 2.748 COLLET',
        inUse: false
      },
      stepId: null
    },
    {
      fixture: {
      id: Guid.newGuid(),
      number: '5 DEGREE BLOCKS',
      description: '5 DEGREE BLOCKS FOR SINE PLATE',
      inUse: false},
      stepId: null
    },
    {
      fixture: {id: Guid.newGuid(),
      number: 'B447',
      description: 'JAWS 1',
      inUse: false},
      stepId: null
    }];

  FILES: IFile[] = [];
  ROUTERFILES: IRouterFile[] = [];

  ROUTERID: string[]= [
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid(),
    Guid.newGuid()
  ];

  private PARTS: IPart[] = [
    {
      id: Guid.newGuid(),
      number: 'VC0049S',
       description: 'INSERT SET, 2" H2S (SPLIT)',
       unit: UnitType.EACH,
       trace: TraceType.NONE,
       type: PartType.DISCRETE,
       name: '',
       fGLot: 1
    },
    {
      id: Guid.newGuid(),
      number: 'VA0109-S',
       description: 'VALVE, 2" x 2"1502 H2S 10M',
       unit: UnitType.EACH,
       trace: TraceType.NONE,
       type: PartType.DISCRETE,
       name: '',
       fGLot: 1
    },
    {
      id: Guid.newGuid(),
      number: 'CC0034',
       description: 'CHOKE INDICATOR, 2"MAX H2 (FOR 3"CHOKE)',
       unit: UnitType.EACH,
       trace: TraceType.NONE,
       type: PartType.DISCRETE,
       name: '',
       fGLot: 1
    },
    {
      id: Guid.newGuid(),
      number: 'CC0037',
        description: 'CHOKE SEAT, 3/4" FC-140 6"LINED CARBIDE',
        unit: UnitType.EACH,
        trace: TraceType.NONE,
        type: PartType.DISCRETE,
        name: '',
        fGLot: 1
    },
    {
      id: Guid.newGuid(),
      number: 'VC0027S',
        description: 'INSERT SET, 2" STD (SPLIT)',
        unit: UnitType.EACH,
        trace: TraceType.NONE,
        type: PartType.DISCRETE,
        name: '',
        fGLot: 1
    },
    {
      id: Guid.newGuid(),
      number: 'VA0509',
       description: 'VALVE, 3" x 5"E40 3.75"PORT 15M STD (MANUAL) *UNIsert',
       unit: UnitType.EACH,
       trace: TraceType.NONE,
       type: PartType.DISCRETE,
       name: '',
       fGLot: 1
    },
    {
      id: Guid.newGuid(),
      number: 'VA0395',
       description: 'VALVE, 3" x 4.5"PH-6 B/P STD 15M (ACT)*MANUAL OVERRIDE',
       unit: UnitType.EACH,
       trace: TraceType.NONE,
       type: PartType.DISCRETE,
       name: '',
       fGLot: 1
    },
    {
      id: Guid.newGuid(),
      number: 'CA0014',
       description: 'CHOKE TEE, 2"1502 STD 15M',
       unit: UnitType.EACH,
       trace: TraceType.NONE,
       type: PartType.DISCRETE,
       name: '',
       fGLot: 1
    },
    {
      id: Guid.newGuid(),
      number: 'CA0015',
       description: 'CHOKE TEE, 2"1502 H2S 10M',
       unit: UnitType.EACH,
       trace: TraceType.NONE,
       type: PartType.DISCRETE,
       name: '',
       fGLot: 1
    },
    {
      id: Guid.newGuid(),
      number: 'CC0018',
       description: 'CHOKE BODY, 2"1502 H2S 10M',
       unit: UnitType.EACH,
       trace: TraceType.NONE,
       type: PartType.DISCRETE,
       name: '',
       fGLot: 1
    },  ];

  ROUTERS: IRouterExtended[] = [
      {
        id: this.ROUTERID[0],
        partId: this.PARTS.find(p => p.number === 'VC0027S').id,
        partNumber: 'VC0027S',
        partDescription: 'INSERT SET, 2" STD (SPLIT)',
        revision: 1,
        state: RouterState.INACTIVE,
        type: RouterType.CELL,
        lastUpdate: new Date('September 1, 2015 08:13:00'),
        updatedBy: 'R. Eiffert',
        userId: Guid.newGuid(),
        activeWIPTotal: 0,
        targetWIP: 100,
        psl: 1,
        steps: []
      },
      {
        id: Guid.newGuid(),
        partId: this.PARTS.find(p => p.number === 'VC0027S').id,
        partNumber: 'VC0027S',
        partDescription: 'INSERT SET, 2" STD (SPLIT)',
        revision: 2,
        state: RouterState.INACTIVE,
        type: RouterType.CELL,
        lastUpdate: new Date('October 30, 2015 23:13:00'),
        updatedBy: 'D. Ybarra',
        userId: Guid.newGuid(),
        activeWIPTotal: 0,
        targetWIP: 100,
        psl: 1,
        steps: []
      },
      {
        id: Guid.newGuid(),
        partId: this.PARTS.find(p => p.number === 'VC0027S').id,
        partNumber: 'VC0027S',
        partDescription: 'INSERT SET, 2" STD (SPLIT)',
        revision: 3,
        state: RouterState.HOLD_ALL_LOCKED,
        type: RouterType.CELL,
        lastUpdate: new Date('December 11, 2016 15:13:00'),
        updatedBy: 'D. Ybarra',
        userId: Guid.newGuid(),
        activeWIPTotal: 5,
        targetWIP: 100,
        psl: 1,
        steps: []
      },
      {
        id: Guid.newGuid(),
        partId: this.PARTS.find(p => p.number === 'VC0027S').id,
        partNumber: 'VC0027S',
        partDescription: 'INSERT SET, 2" STD (SPLIT)',
        revision: 4,
        state: RouterState.HOLD_NEW_LOCKED,
        type: RouterType.CELL,
        lastUpdate: new Date('January 5, 2017 01:00:01'),
        updatedBy: 'D. Ybarra',
        userId: Guid.newGuid(),
        activeWIPTotal: 5,
        targetWIP: 100,
        psl: 1,
        steps: []
      },
      {
        id: Guid.newGuid(),
        partId: this.PARTS.find(p => p.number === 'VC0027S').id,
        partNumber: 'VC0027S',
        partDescription: 'INSERT SET, 2" STD (SPLIT)',
        revision: 5,
        state: RouterState.ACTIVE,
        type: RouterType.CELL,
        lastUpdate: new Date('Febuary 11, 2017 12:47:00'),
        updatedBy: 'D. Ybarra',
        userId: Guid.newGuid(),
        activeWIPTotal: 5,
        targetWIP: 100,
        psl: 1,
         steps: []
      },
      {
        id: Guid.newGuid(),
        partId: this.PARTS.find(p => p.number === 'VC0027S').id,
        partNumber: 'VC0027S',
        partDescription: 'INSERT SET, 2" STD (SPLIT)',
        revision: 6,
        state: RouterState.PENDING,
        type: RouterType.CELL,
        lastUpdate: new Date('August 11, 2017 05:13:00'),
        updatedBy: 'D. Ybarra',
        userId: Guid.newGuid(),
        activeWIPTotal: 0,
        targetWIP: 100,
        psl: 1,
         steps: []
      },
      {
        id: Guid.newGuid(),
        partId: this.PARTS.find(p => p.number === 'CC0037').id,
        partNumber: 'CC0037',
        partDescription: 'CHOKE SEAT, 3/4" FC-140 6"LINED CARBIDE',
        revision: 1,
        state: RouterState.HOLD_NEW_WIP,
        type: RouterType.CELL,
        lastUpdate: new Date('August 28, 2011 13:13:00'),
        updatedBy: 'D. Ybarra',
        userId: Guid.newGuid(),
        activeWIPTotal: 5,
        targetWIP: 100,
        psl: 1,
         steps: []
      },
      {
        id: Guid.newGuid(),
        partId: this.PARTS.find(p => p.number === 'CC0034').id,
        partNumber: 'CC0034',
        partDescription: 'CHOKE INDICATOR, 2"MAX H2 (FOR 3"CHOKE)',
        revision: 1,
        state: RouterState.HOLD_ALL_WIP,
        type: RouterType.CELL,
        lastUpdate: new Date('March 11, 2014 22:13:00'),
        updatedBy: 'D. Ybarra',
        userId: Guid.newGuid(),
        activeWIPTotal: 5,
        targetWIP: 100,
        psl: 1,
         steps: []
      },
      {
        id: Guid.newGuid(),
        partId: this.PARTS.find(p => p.number === 'CC0034').id,
        partNumber: 'CC0034',
        partDescription: 'CHOKE INDICATOR, 2"MAX H2 (FOR 3"CHOKE)',
        revision: 2,
        state: RouterState.PENDING,
        type: RouterType.CELL,
        lastUpdate: new Date('March 11, 2015 22:13:00'),
        updatedBy: 'D. Ybarra',
        userId: Guid.newGuid(),
        activeWIPTotal: 0,
        targetWIP: 100,
        psl: 1,
         steps: []
      },
      {
        id: Guid.newGuid(),
        partId: this.PARTS.find(p => p.number === 'VA0109-S').id,
        partNumber: 'VA0109-S',
        partDescription: 'VALVE, 2" x 2"1502 H2S 10M',
        revision: 1,
        state: RouterState.HOLD_ALL_LOCKED,
        type: RouterType.CELL,
        lastUpdate: new Date('October 30, 2015 21:13:00'),
        updatedBy: 'D. Ybarra',
        userId: Guid.newGuid(),
        activeWIPTotal: 5,
        targetWIP: 100,
        psl: 1,
         steps: []
      },
      {
        id: Guid.newGuid(),
        partId: this.PARTS.find(p => p.number === 'VA0109-S').id,
         partNumber: 'VA0109-S',
        partDescription: 'VALVE, 2" x 2"1502 H2S 10M',
        revision: 2,
        state: RouterState.HOLD_NEW_LOCKED,
        type: RouterType.CELL,
        lastUpdate: new Date('December 11, 2016 15:13:00'),
        updatedBy: 'D. Ybarra',
        userId: Guid.newGuid(),
        activeWIPTotal: 15,
        targetWIP: 100,
        psl: 1,
         steps: []
      },
      {
        id: Guid.newGuid(),
        partId: this.PARTS.find(p => p.number === 'VA0109-S').id,
            partNumber: 'VA0109-S',
        partDescription: 'VALVE, 2" x 2"1502 H2S 10M',
        revision: 3,
        state: RouterState.ACTIVE,
        type: RouterType.CELL,
        lastUpdate: new Date('August 5, 2017 01:00:01'),
        updatedBy: 'D. Ybarra',
        userId: Guid.newGuid(),
        activeWIPTotal: 25,
        targetWIP: 100,
        psl: 1,
         steps: []
      },
      {
        id: Guid.newGuid(),
        partId: this.PARTS.find(p => p.number === 'VA0109-S').id,
          partNumber: 'VA0109-S',
        partDescription: 'VALVE, 2" x 2"1502 H2S 10M',
        revision: 4,
        state: RouterState.PENDING,
        type: RouterType.CELL,
        lastUpdate: new Date('September 11, 2017 12:47:00'),
        updatedBy: 'D. Ybarra',
        userId: Guid.newGuid(),
        activeWIPTotal: 0,
        targetWIP: 100,
        psl: 1,
         steps: []
      },
      {
        id: Guid.newGuid(),
        partId: this.PARTS.find(p => p.number === 'VC0049S').id,
          partNumber: 'VC0049S',
        partDescription: 'INSERT SET, 2" H2S (SPLIT)',
        revision: 1,
        state: RouterState.ACTIVE,
        type: RouterType.CELL,
        lastUpdate: new Date('September 11, 2017 12:47:00'),
        updatedBy: 'D. Ybarra',
        userId: Guid.newGuid(),
        activeWIPTotal: 100,
        targetWIP: 100,
        psl: 1,
         steps: []
      }
    ];

  WORKCENTERS: IWorkCenterExtended[] = [
  {
    workCenter: {id: Guid.newGuid(),
    name: '',
    type: WorkCenterType.MACHINING,
    code: 1,
    description: 'Issue',
    warehouse: 'MAIN',
    scheduleType: ScheduleType.STANDARD,
    isOutsource: false,
    inUse: false},
    workStations: []
    },
  {
    workCenter: {id: Guid.newGuid(),
    name: '',
    type: WorkCenterType.MACHINING,
    code: 2,
    description: 'Deburr',
    warehouse: 'MAIN',
    scheduleType: ScheduleType.STANDARD,
    isOutsource: false,
    inUse: false},
    workStations: []
  },
  {
    workCenter: {id: Guid.newGuid(),
    name: '',
    type: WorkCenterType.MACHINING,
    code: 3,
    description: 'Lathe',
    warehouse: 'MAIN',
    scheduleType: ScheduleType.STANDARD,
    isOutsource: false,
    inUse: false},
    workStations: []
  },
  {
    workCenter: {id: Guid.newGuid(),
    name: '',
    type: WorkCenterType.MACHINING,
    code: 4,
    description: 'Ship to Nitride',
    warehouse: 'MAIN',
    scheduleType: ScheduleType.STANDARD,
    isOutsource: false,
    inUse: false},
    workStations: []
  },
  {
    workCenter: {id: Guid.newGuid(),
    name: '',
    type: WorkCenterType.MACHINING,
    code: 5,
    description: 'Receive from Nitride',
    warehouse: 'MAIN',
    scheduleType: ScheduleType.STANDARD,
    isOutsource: true,
    inUse: false},
    workStations: []
  },
  {
    workCenter: {id: Guid.newGuid(),
    name: '',
    type: WorkCenterType.MACHINING,
    code: 6,
    description: 'Inspection',
    warehouse: 'MAIN',
    scheduleType: ScheduleType.STANDARD,
    isOutsource: false,
    inUse: false},
    workStations: []
  },
  {
    workCenter: {id: Guid.newGuid(),
    name: '',
    type: WorkCenterType.MACHINING,
    code: 7,
    description: 'Machine',
    warehouse: 'MAIN',
    scheduleType: ScheduleType.STANDARD,
    isOutsource: false,
    inUse: false},
    workStations: []
  }];



  OnShapeDocuments: IOnshapeDocument[] = [
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 0,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "WRITE",
    "EXPORT",
    "COMMENT",
    "DELETE",
    "COPY",
    "RESHARE",
    "READ",
    "LINK"
    ],
    parentId: null,
    projectId: null,
    canUnshare: true,
    thumbnail: {
    sizes: [ ],
    secondarySizes: [ ],
    href: null,
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: "b8a7cbf484daf411b3cc01e7",
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/dc5821ca95fccc9d447b1513/workspaces/252a5484d5297e3f10b9eaf2",
    id: "252a5484d5297e3f10b9eaf2",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "FULL",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-05-22T14:20:33.839+0000",
    createdBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/5cc8407a1d766716f8def480",
    id: "5cc8407a1d766716f8def480",
    name: "Joe Pedroza Jr"
    },
    modifiedBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/5cc8407a1d766716f8def480",
    id: "5cc8407a1d766716f8def480",
    name: "Joe Pedroza Jr"
    },
    isContainer: false,
    canMove: true,
    isEnterpriseOwned: true,
    modifiedAt: "2019-05-22T14:21:54.404+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/dc5821ca95fccc9d447b1513",
    id: "dc5821ca95fccc9d447b1513",
    name: "Dixie Joe Test"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 0,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "EXPORT",
    "COMMENT",
    "READ",
    "LINK"
    ],
    parentId: "fa7e0530b6ffc98df6d1e1ce",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/c10c4c35605c27aab0dde8db/w/d2d7788ea084fa37ba0d95c5/s/600x340?t=1558534696454",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/c10c4c35605c27aab0dde8db/w/d2d7788ea084fa37ba0d95c5/s/300x170?t=1558534696454",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/c10c4c35605c27aab0dde8db/w/d2d7788ea084fa37ba0d95c5/s/70x40?t=1558534696454",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/c10c4c35605c27aab0dde8db/w/d2d7788ea084fa37ba0d95c5/s/300x300?t=1558534696454",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/c10c4c35605c27aab0dde8db/w/d2d7788ea084fa37ba0d95c5?t=1558534696454",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/c10c4c35605c27aab0dde8db/workspaces/d2d7788ea084fa37ba0d95c5",
    id: "d2d7788ea084fa37ba0d95c5",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-05-22T14:17:34.219+0000",
    createdBy: {
    state: 1,
    image: "https://profiles.onshape.com/98e159da0679c084324ee7966517ac5a1fefa931f08526f1e55c2e81606ad3a0.png",
    href: "https://diwmsi.onshape.com/api/users/57c068da56237c105179d2fa",
    id: "57c068da56237c105179d2fa",
    name: "LMS"
    },
    modifiedBy: {
    state: 1,
    image: "https://profiles.onshape.com/98e159da0679c084324ee7966517ac5a1fefa931f08526f1e55c2e81606ad3a0.png",
    href: "https://diwmsi.onshape.com/api/users/57c068da56237c105179d2fa",
    id: "57c068da56237c105179d2fa",
    name: "LMS"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-05-22T14:19:05.582+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/c10c4c35605c27aab0dde8db",
    id: "c10c4c35605c27aab0dde8db",
    name: "UC0050"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 1,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "355da58010acffcefa7272b6",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/20021d9009977fba01984f31/w/f3eb267f2911f56c23e207c3/s/70x40?t=1558470689220",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/20021d9009977fba01984f31/w/f3eb267f2911f56c23e207c3/s/300x170?t=1558470689220",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/20021d9009977fba01984f31/w/f3eb267f2911f56c23e207c3/s/600x340?t=1558470689220",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/20021d9009977fba01984f31/w/f3eb267f2911f56c23e207c3/s/300x300?t=1558470689220",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/20021d9009977fba01984f31/w/f3eb267f2911f56c23e207c3?t=1558470689220",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/20021d9009977fba01984f31/workspaces/f3eb267f2911f56c23e207c3",
    id: "f3eb267f2911f56c23e207c3",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-05-21T20:13:21.004+0000",
    createdBy: {
    state: 1,
    image: "https://profiles.onshape.com/98e159da0679c084324ee7966517ac5a1fefa931f08526f1e55c2e81606ad3a0.png",
    href: "https://diwmsi.onshape.com/api/users/57c068da56237c105179d2fa",
    id: "57c068da56237c105179d2fa",
    name: "LMS"
    },
    modifiedBy: {
    state: 1,
    image: "https://profiles.onshape.com/98e159da0679c084324ee7966517ac5a1fefa931f08526f1e55c2e81606ad3a0.png",
    href: "https://diwmsi.onshape.com/api/users/57c068da56237c105179d2fa",
    id: "57c068da56237c105179d2fa",
    name: "LMS"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-05-21T20:29:22.886+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/20021d9009977fba01984f31",
    id: "20021d9009977fba01984f31",
    name: "VA-4 X 4\" 1502"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 1,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "520a8003f2bea480bef5ffcf",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/b51ced06ef76854b6313c1b3/w/e05c65135cbed894f9b1cad1/s/300x300?t=1558365436452",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/b51ced06ef76854b6313c1b3/w/e05c65135cbed894f9b1cad1/s/600x340?t=1558365436452",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/b51ced06ef76854b6313c1b3/w/e05c65135cbed894f9b1cad1/s/70x40?t=1558365436452",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/b51ced06ef76854b6313c1b3/w/e05c65135cbed894f9b1cad1/s/300x170?t=1558365436452",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/b51ced06ef76854b6313c1b3/w/e05c65135cbed894f9b1cad1?t=1558365436452",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/b51ced06ef76854b6313c1b3/workspaces/e05c65135cbed894f9b1cad1",
    id: "e05c65135cbed894f9b1cad1",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-05-20T15:06:11.113+0000",
    createdBy: {
    state: 1,
    image: "https://profiles.onshape.com/98e159da0679c084324ee7966517ac5a1fefa931f08526f1e55c2e81606ad3a0.png",
    href: "https://diwmsi.onshape.com/api/users/57c068da56237c105179d2fa",
    id: "57c068da56237c105179d2fa",
    name: "LMS"
    },
    modifiedBy: {
    state: 1,
    image: "https://profiles.onshape.com/98e159da0679c084324ee7966517ac5a1fefa931f08526f1e55c2e81606ad3a0.png",
    href: "https://diwmsi.onshape.com/api/users/57c068da56237c105179d2fa",
    id: "57c068da56237c105179d2fa",
    name: "LMS"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-05-20T15:17:11.292+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/b51ced06ef76854b6313c1b3",
    id: "b51ced06ef76854b6313c1b3",
    name: "ADAPTER, 3\" 1502 FxF"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 2,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "b24cec7c0aa3af3bab8475bc",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/3ce332dabeedd7c4066d985f/w/e4e1b38a8dff073f7c257511/s/70x40?t=1558364579128",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/3ce332dabeedd7c4066d985f/w/e4e1b38a8dff073f7c257511/s/300x170?t=1558364579128",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/3ce332dabeedd7c4066d985f/w/e4e1b38a8dff073f7c257511/s/600x340?t=1558364579128",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/3ce332dabeedd7c4066d985f/w/e4e1b38a8dff073f7c257511/s/300x300?t=1558364579128",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/3ce332dabeedd7c4066d985f/w/e4e1b38a8dff073f7c257511?t=1558364579128",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/3ce332dabeedd7c4066d985f/workspaces/e4e1b38a8dff073f7c257511",
    id: "e4e1b38a8dff073f7c257511",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-05-20T14:47:38.669+0000",
    createdBy: {
    state: 1,
    image: "https://profiles.onshape.com/98e159da0679c084324ee7966517ac5a1fefa931f08526f1e55c2e81606ad3a0.png",
    href: "https://diwmsi.onshape.com/api/users/57c068da56237c105179d2fa",
    id: "57c068da56237c105179d2fa",
    name: "LMS"
    },
    modifiedBy: {
    state: 1,
    image: "https://profiles.onshape.com/98e159da0679c084324ee7966517ac5a1fefa931f08526f1e55c2e81606ad3a0.png",
    href: "https://diwmsi.onshape.com/api/users/57c068da56237c105179d2fa",
    id: "57c068da56237c105179d2fa",
    name: "LMS"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-05-20T15:02:53.704+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/3ce332dabeedd7c4066d985f",
    id: "3ce332dabeedd7c4066d985f",
    name: "BLANK, 3\" 1502 M"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 1,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "b24cec7c0aa3af3bab8475bc",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/2fac2bbe7937e257a514ee01/w/69b1d05b22d500cf421b2fc4/s/600x340?t=1557430195959",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/2fac2bbe7937e257a514ee01/w/69b1d05b22d500cf421b2fc4/s/300x170?t=1557430195959",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/2fac2bbe7937e257a514ee01/w/69b1d05b22d500cf421b2fc4/s/70x40?t=1557430195959",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/2fac2bbe7937e257a514ee01/w/69b1d05b22d500cf421b2fc4/s/300x300?t=1557430195959",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/2fac2bbe7937e257a514ee01/w/69b1d05b22d500cf421b2fc4?t=1557430195959",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/2fac2bbe7937e257a514ee01/workspaces/69b1d05b22d500cf421b2fc4",
    id: "69b1d05b22d500cf421b2fc4",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-05-09T19:01:11.800+0000",
    createdBy: {
    state: 1,
    image: "https://profiles.onshape.com/98e159da0679c084324ee7966517ac5a1fefa931f08526f1e55c2e81606ad3a0.png",
    href: "https://diwmsi.onshape.com/api/users/57c068da56237c105179d2fa",
    id: "57c068da56237c105179d2fa",
    name: "LMS"
    },
    modifiedBy: {
    state: 1,
    image: "https://profiles.onshape.com/98e159da0679c084324ee7966517ac5a1fefa931f08526f1e55c2e81606ad3a0.png",
    href: "https://diwmsi.onshape.com/api/users/57c068da56237c105179d2fa",
    id: "57c068da56237c105179d2fa",
    name: "LMS"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-05-09T19:33:33.835+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/2fac2bbe7937e257a514ee01",
    id: "2fac2bbe7937e257a514ee01",
    name: "WINGNUT, 4\" 602"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 0,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "3852e585a6e35daf975f5d3e",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/86ad2c95007b332aa927dafa/w/88098b3707d30d4dc47bd4ef/s/300x300?t=1557765389572",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/86ad2c95007b332aa927dafa/w/88098b3707d30d4dc47bd4ef/s/300x170?t=1557765389572",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/86ad2c95007b332aa927dafa/w/88098b3707d30d4dc47bd4ef/s/600x340?t=1557765389572",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/86ad2c95007b332aa927dafa/w/88098b3707d30d4dc47bd4ef/s/70x40?t=1557765389572",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/86ad2c95007b332aa927dafa/w/88098b3707d30d4dc47bd4ef?t=1557765389572",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/86ad2c95007b332aa927dafa/workspaces/88098b3707d30d4dc47bd4ef",
    id: "88098b3707d30d4dc47bd4ef",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-05-09T18:28:44.827+0000",
    createdBy: {
    state: 1,
    image: "https://profiles.onshape.com/98e159da0679c084324ee7966517ac5a1fefa931f08526f1e55c2e81606ad3a0.png",
    href: "https://diwmsi.onshape.com/api/users/57c068da56237c105179d2fa",
    id: "57c068da56237c105179d2fa",
    name: "LMS"
    },
    modifiedBy: {
    state: 1,
    image: "https://profiles.onshape.com/98e159da0679c084324ee7966517ac5a1fefa931f08526f1e55c2e81606ad3a0.png",
    href: "https://diwmsi.onshape.com/api/users/57c068da56237c105179d2fa",
    id: "57c068da56237c105179d2fa",
    name: "LMS"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-05-13T16:36:05.163+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/86ad2c95007b332aa927dafa",
    id: "86ad2c95007b332aa927dafa",
    name: "EL, 4\"602FM"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 6,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "3c3bf52174dd27c3d06686bd",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/54285f0d22644c0bdd77973e/w/def0dd6a138f483ace3c87dd/s/300x170?t=1557348184163",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/54285f0d22644c0bdd77973e/w/def0dd6a138f483ace3c87dd/s/70x40?t=1557348184163",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/54285f0d22644c0bdd77973e/w/def0dd6a138f483ace3c87dd/s/300x300?t=1557348184163",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/54285f0d22644c0bdd77973e/w/def0dd6a138f483ace3c87dd/s/600x340?t=1557348184163",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/54285f0d22644c0bdd77973e/w/def0dd6a138f483ace3c87dd?t=1557348184163",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/54285f0d22644c0bdd77973e/workspaces/def0dd6a138f483ace3c87dd",
    id: "def0dd6a138f483ace3c87dd",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-05-08T20:07:49.892+0000",
    createdBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    modifiedBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-05-08T20:46:45.425+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/54285f0d22644c0bdd77973e",
    id: "54285f0d22644c0bdd77973e",
    name: "VC0773"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 0,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "355da58010acffcefa7272b6",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/7de101de8ed6524ac13ba297/w/a6e9b518556eaf46474e289e/s/600x340?t=1557858164618",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/7de101de8ed6524ac13ba297/w/a6e9b518556eaf46474e289e/s/300x170?t=1557858164618",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/7de101de8ed6524ac13ba297/w/a6e9b518556eaf46474e289e/s/300x300?t=1557858164618",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/7de101de8ed6524ac13ba297/w/a6e9b518556eaf46474e289e/s/70x40?t=1557858164618",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/7de101de8ed6524ac13ba297/w/a6e9b518556eaf46474e289e?t=1557858164618",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/7de101de8ed6524ac13ba297/workspaces/a6e9b518556eaf46474e289e",
    id: "a6e9b518556eaf46474e289e",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: true,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: true,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-05-08T19:23:24.469+0000",
    createdBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    modifiedBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-05-14T18:20:35.265+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/7de101de8ed6524ac13ba297",
    id: "7de101de8ed6524ac13ba297",
    name: "VA0858"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 0,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "d6c19e5cb29ef3e324ea6309",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/2da03cfe4bcfd70a06b8d794/w/d3142a3c689a1fc0f686acf6/s/300x170?t=1557339073021",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/2da03cfe4bcfd70a06b8d794/w/d3142a3c689a1fc0f686acf6/s/70x40?t=1557339073021",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/2da03cfe4bcfd70a06b8d794/w/d3142a3c689a1fc0f686acf6/s/600x340?t=1557339073021",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/2da03cfe4bcfd70a06b8d794/w/d3142a3c689a1fc0f686acf6/s/300x300?t=1557339073021",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/2da03cfe4bcfd70a06b8d794/w/d3142a3c689a1fc0f686acf6?t=1557339073021",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/2da03cfe4bcfd70a06b8d794/workspaces/d3142a3c689a1fc0f686acf6",
    id: "d3142a3c689a1fc0f686acf6",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-05-08T17:23:51.140+0000",
    createdBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    modifiedBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-05-08T18:11:38.116+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/2da03cfe4bcfd70a06b8d794",
    id: "2da03cfe4bcfd70a06b8d794",
    name: "AAG-5LT-IW62"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 2,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "3c3bf52174dd27c3d06686bd",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/205276e8de01af674e4fddb1/w/c7045123a0fca3fa2ca749a9/s/300x300?t=1557336194821",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/205276e8de01af674e4fddb1/w/c7045123a0fca3fa2ca749a9/s/300x170?t=1557336194821",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/205276e8de01af674e4fddb1/w/c7045123a0fca3fa2ca749a9/s/70x40?t=1557336194821",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/205276e8de01af674e4fddb1/w/c7045123a0fca3fa2ca749a9/s/600x340?t=1557336194821",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/205276e8de01af674e4fddb1/w/c7045123a0fca3fa2ca749a9?t=1557336194821",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/205276e8de01af674e4fddb1/workspaces/c7045123a0fca3fa2ca749a9",
    id: "c7045123a0fca3fa2ca749a9",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-05-08T17:19:12.214+0000",
    createdBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    modifiedBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-05-08T17:23:24.131+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/205276e8de01af674e4fddb1",
    id: "205276e8de01af674e4fddb1",
    name: "VC0840"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 2,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "d6c19e5cb29ef3e324ea6309",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/96e0e3fa2c0a892810c55154/w/f51ead0b3a3c805a6f9d587f/s/300x300?t=1557335889813",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/96e0e3fa2c0a892810c55154/w/f51ead0b3a3c805a6f9d587f/s/600x340?t=1557335889813",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/96e0e3fa2c0a892810c55154/w/f51ead0b3a3c805a6f9d587f/s/300x170?t=1557335889813",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/96e0e3fa2c0a892810c55154/w/f51ead0b3a3c805a6f9d587f/s/70x40?t=1557335889813",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/96e0e3fa2c0a892810c55154/w/f51ead0b3a3c805a6f9d587f?t=1557335889813",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/96e0e3fa2c0a892810c55154/workspaces/f51ead0b3a3c805a6f9d587f",
    id: "f51ead0b3a3c805a6f9d587f",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-05-08T17:17:23.759+0000",
    createdBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    modifiedBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-05-08T17:18:33.085+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/96e0e3fa2c0a892810c55154",
    id: "96e0e3fa2c0a892810c55154",
    name: "ACG-5LT-16000"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 3,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "d6c19e5cb29ef3e324ea6309",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/95e50b5bebe342ce3f8dad4c/w/fc54f805d97303a73c14d43e/s/70x40?t=1557335636772",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/95e50b5bebe342ce3f8dad4c/w/fc54f805d97303a73c14d43e/s/300x170?t=1557335636772",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/95e50b5bebe342ce3f8dad4c/w/fc54f805d97303a73c14d43e/s/300x300?t=1557335636772",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/95e50b5bebe342ce3f8dad4c/w/fc54f805d97303a73c14d43e/s/600x340?t=1557335636772",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/95e50b5bebe342ce3f8dad4c/w/fc54f805d97303a73c14d43e?t=1557335636772",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/95e50b5bebe342ce3f8dad4c/workspaces/fc54f805d97303a73c14d43e",
    id: "fc54f805d97303a73c14d43e",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-05-08T15:43:50.454+0000",
    createdBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    modifiedBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-05-08T17:28:09.054+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/95e50b5bebe342ce3f8dad4c",
    id: "95e50b5bebe342ce3f8dad4c",
    name: "AA0074"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 4,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "b5ed00e3c2a50f807c23e7fe",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/51aff972a5f3388b4377e728/w/1623698759a493c54b1802b9/s/600x340?t=1557242943653",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/51aff972a5f3388b4377e728/w/1623698759a493c54b1802b9/s/300x300?t=1557242943653",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/51aff972a5f3388b4377e728/w/1623698759a493c54b1802b9/s/70x40?t=1557242943653",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/51aff972a5f3388b4377e728/w/1623698759a493c54b1802b9/s/300x170?t=1557242943653",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/51aff972a5f3388b4377e728/w/1623698759a493c54b1802b9?t=1557242943653",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/51aff972a5f3388b4377e728/workspaces/1623698759a493c54b1802b9",
    id: "1623698759a493c54b1802b9",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-05-07T13:31:23.266+0000",
    createdBy: {
    state: 1,
    image: "https://profiles.onshape.com/0c51cf01842d29e27212bf9530e2720db0f64cb4105402da06412780d414194f.png",
    href: "https://diwmsi.onshape.com/api/users/574f0e1de4b0ae264c7e6250",
    id: "574f0e1de4b0ae264c7e6250",
    name: "WJT"
    },
    modifiedBy: {
    state: 1,
    image: "https://profiles.onshape.com/0c51cf01842d29e27212bf9530e2720db0f64cb4105402da06412780d414194f.png",
    href: "https://diwmsi.onshape.com/api/users/574f0e1de4b0ae264c7e6250",
    id: "574f0e1de4b0ae264c7e6250",
    name: "WJT"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-05-07T15:28:32.787+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/51aff972a5f3388b4377e728",
    id: "51aff972a5f3388b4377e728",
    name: "OA0156"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 0,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "4f18f184048fdf0e0f20a52d",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/15571c1f4ab08fa9549d7070/w/8e92cb80a89f956613949f9e/s/600x340?t=1558542734460",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/15571c1f4ab08fa9549d7070/w/8e92cb80a89f956613949f9e/s/300x300?t=1558542734460",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/15571c1f4ab08fa9549d7070/w/8e92cb80a89f956613949f9e/s/70x40?t=1558542734460",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/15571c1f4ab08fa9549d7070/w/8e92cb80a89f956613949f9e/s/300x170?t=1558542734460",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/15571c1f4ab08fa9549d7070/w/8e92cb80a89f956613949f9e?t=1558542734460",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/15571c1f4ab08fa9549d7070/workspaces/8e92cb80a89f956613949f9e",
    id: "8e92cb80a89f956613949f9e",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-05-06T21:37:11.778+0000",
    createdBy: {
    state: 1,
    image: "https://profiles.onshape.com/0c51cf01842d29e27212bf9530e2720db0f64cb4105402da06412780d414194f.png",
    href: "https://diwmsi.onshape.com/api/users/574f0e1de4b0ae264c7e6250",
    id: "574f0e1de4b0ae264c7e6250",
    name: "WJT"
    },
    modifiedBy: {
    state: 1,
    image: "https://profiles.onshape.com/0c51cf01842d29e27212bf9530e2720db0f64cb4105402da06412780d414194f.png",
    href: "https://diwmsi.onshape.com/api/users/574f0e1de4b0ae264c7e6250",
    id: "574f0e1de4b0ae264c7e6250",
    name: "WJT"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-05-07T17:56:36.096+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/15571c1f4ab08fa9549d7070",
    id: "15571c1f4ab08fa9549d7070",
    name: "FORGING-3-VLV-FLG"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 1,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "3c3bf52174dd27c3d06686bd",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/348f0a8dfd48a8e8ae523b31/w/6b04799165e4223fe761c2aa/s/600x340?t=1558386187432",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/348f0a8dfd48a8e8ae523b31/w/6b04799165e4223fe761c2aa/s/300x170?t=1558386187432",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/348f0a8dfd48a8e8ae523b31/w/6b04799165e4223fe761c2aa/s/300x300?t=1558386187432",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/348f0a8dfd48a8e8ae523b31/w/6b04799165e4223fe761c2aa/s/70x40?t=1558386187432",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/348f0a8dfd48a8e8ae523b31/w/6b04799165e4223fe761c2aa?t=1558386187432",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/348f0a8dfd48a8e8ae523b31/workspaces/6b04799165e4223fe761c2aa",
    id: "6b04799165e4223fe761c2aa",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-05-03T15:54:34.999+0000",
    createdBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    modifiedBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/5b2419bcf0689e0d51fa1458",
    id: "5b2419bcf0689e0d51fa1458",
    name: "Release Management system Dixie Iron Works, Ltd"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-05-03T17:30:44.445+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/348f0a8dfd48a8e8ae523b31",
    id: "348f0a8dfd48a8e8ae523b31",
    name: "VC0879"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 2,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "520a8003f2bea480bef5ffcf",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/3d3351e3a47388c13e7b112d/w/1fa6d452d20b831b1e0bdf49/s/300x170?t=1556292803657",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/3d3351e3a47388c13e7b112d/w/1fa6d452d20b831b1e0bdf49/s/70x40?t=1556292803657",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/3d3351e3a47388c13e7b112d/w/1fa6d452d20b831b1e0bdf49/s/600x340?t=1556292803657",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/3d3351e3a47388c13e7b112d/w/1fa6d452d20b831b1e0bdf49/s/300x300?t=1556292803657",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/3d3351e3a47388c13e7b112d/w/1fa6d452d20b831b1e0bdf49?t=1556292803657",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/3d3351e3a47388c13e7b112d/workspaces/1fa6d452d20b831b1e0bdf49",
    id: "1fa6d452d20b831b1e0bdf49",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-04-26T15:13:08.935+0000",
    createdBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    modifiedBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-04-26T15:33:17.562+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/3d3351e3a47388c13e7b112d",
    id: "3d3351e3a47388c13e7b112d",
    name: "DA0467"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 2,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "520a8003f2bea480bef5ffcf",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/6f5ae87544550556df9551f1/w/69ffc894abb3bdecec705401/s/300x170?t=1556291837210",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/6f5ae87544550556df9551f1/w/69ffc894abb3bdecec705401/s/600x340?t=1556291837210",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/6f5ae87544550556df9551f1/w/69ffc894abb3bdecec705401/s/300x300?t=1556291837210",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/6f5ae87544550556df9551f1/w/69ffc894abb3bdecec705401/s/70x40?t=1556291837210",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/6f5ae87544550556df9551f1/w/69ffc894abb3bdecec705401?t=1556291837210",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/6f5ae87544550556df9551f1/workspaces/69ffc894abb3bdecec705401",
    id: "69ffc894abb3bdecec705401",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-04-26T15:08:42.035+0000",
    createdBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    modifiedBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/57507904e4b0ea51364aaaf4",
    id: "57507904e4b0ea51364aaaf4",
    name: "POM"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-04-26T15:17:09.685+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/6f5ae87544550556df9551f1",
    id: "6f5ae87544550556df9551f1",
    name: "DC0694"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 2,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "EXPORT",
    "COMMENT",
    "READ",
    "LINK"
    ],
    parentId: "fa7e0530b6ffc98df6d1e1ce",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/83ccf8daf55133d8da5d5548/w/83c75a00b5fcd850eecaee6a/s/70x40?t=1555965808094",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/83ccf8daf55133d8da5d5548/w/83c75a00b5fcd850eecaee6a/s/300x170?t=1555965808094",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/83ccf8daf55133d8da5d5548/w/83c75a00b5fcd850eecaee6a/s/300x300?t=1555965808094",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/83ccf8daf55133d8da5d5548/w/83c75a00b5fcd850eecaee6a/s/600x340?t=1555965808094",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/83ccf8daf55133d8da5d5548/w/83c75a00b5fcd850eecaee6a?t=1555965808094",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/83ccf8daf55133d8da5d5548/workspaces/83c75a00b5fcd850eecaee6a",
    id: "83c75a00b5fcd850eecaee6a",
    name: "HF0007"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-04-22T20:32:50.665+0000",
    createdBy: {
    state: 1,
    image: "https://profiles.onshape.com/0c51cf01842d29e27212bf9530e2720db0f64cb4105402da06412780d414194f.png",
    href: "https://diwmsi.onshape.com/api/users/574f0e1de4b0ae264c7e6250",
    id: "574f0e1de4b0ae264c7e6250",
    name: "WJT"
    },
    modifiedBy: {
    state: 1,
    image: "https://profiles.onshape.com/0c51cf01842d29e27212bf9530e2720db0f64cb4105402da06412780d414194f.png",
    href: "https://diwmsi.onshape.com/api/users/574f0e1de4b0ae264c7e6250",
    id: "574f0e1de4b0ae264c7e6250",
    name: "WJT"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-04-22T20:43:26.661+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/83ccf8daf55133d8da5d5548",
    id: "83ccf8daf55133d8da5d5548",
    name: "HF0007"
    },
    {
    jsonType: "document-summary",
    numberOfTimesReferenced: 0,
    numberOfTimesCopied: 0,
    likes: 0,
    recentVersion: null,
    hasRelevantInsertables: false,
    trash: false,
    totalWorkspacesUpdating: 0,
    totalWorkspacesScheduledForUpdate: 0,
    permissionSet: [
    "COMMENT",
    "READ"
    ],
    parentId: "355da58010acffcefa7272b6",
    projectId: null,
    canUnshare: false,
    thumbnail: {
    sizes: [
    {
    sheetName: null,
    size: "300x170",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/993ef362e23e4a31bec90137/w/09c9f235d0b1a9a6a061b78f/s/300x170?t=1555105855198",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "300x300",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/993ef362e23e4a31bec90137/w/09c9f235d0b1a9a6a061b78f/s/300x300?t=1555105855198",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "70x40",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/993ef362e23e4a31bec90137/w/09c9f235d0b1a9a6a061b78f/s/70x40?t=1555105855198",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    },
    {
    sheetName: null,
    size: "600x340",
    href: "https://diwmsi.onshape.com/api/thumbnails/d/993ef362e23e4a31bec90137/w/09c9f235d0b1a9a6a061b78f/s/600x340?t=1555105855198",
    mediaType: "image/png",
    uniqueId: null,
    viewOrientation: "",
    renderMode: ""
    }
    ],
    secondarySizes: null,
    href: "https://diwmsi.onshape.com/api/thumbnails/d/993ef362e23e4a31bec90137/w/09c9f235d0b1a9a6a061b78f?t=1555105855198",
    id: null
    },
    supportTeamUserAndShared: false,
    likedByCurrentUser: false,
    documentLabels: null,
    tags: [ ],
    defaultElementId: null,
    defaultWorkspace: {
    href: "https://diwmsi.onshape.com/api/documents/d/993ef362e23e4a31bec90137/workspaces/09c9f235d0b1a9a6a061b78f",
    id: "09c9f235d0b1a9a6a061b78f",
    name: "Main"
    },
    createdWithEducationPlan: false,
    notRevisionManaged: false,
    anonymousAccessAllowed: false,
    anonymousAllowsExport: false,
    trashedAt: null,
    hasReleaseRevisionableObjects: false,
    isOrphaned: false,
    public: false,
    userAccountLimitsBreached: false,
    isUsingManagedWorkflow: false,
    permission: "READ",
    owner: {
    acceptOwnershipTransfer: false,
    isEnterpriseOwnedResource: true,
    image: "https://profiles.onshape.com/badb5e7a1ebbd1fdbcd5e0e595cfd891401aa94629d1d2a9a214cbc9d125e86f.png",
    type: 1,
    href: "https://diwmsi.onshape.com/api/companies/57c05f0a56237c1051792736",
    id: "57c05f0a56237c1051792736",
    name: "Dixie Iron Works, Ltd"
    },
    description: null,
    createdAt: "2019-04-12T20:27:24.344+0000",
    createdBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/56c243f1e4b0692ed2012357",
    id: "56c243f1e4b0692ed2012357",
    name: "JCR"
    },
    modifiedBy: {
    state: 1,
    image: "/images/placeholder-user.png",
    href: "https://diwmsi.onshape.com/api/users/56c243f1e4b0692ed2012357",
    id: "56c243f1e4b0692ed2012357",
    name: "JCR"
    },
    isContainer: false,
    canMove: false,
    isEnterpriseOwned: true,
    modifiedAt: "2019-04-12T21:50:59.814+0000",
    isMutable: false,
    resourceType: "document",
    hasPendingOwner: false,
    href: "https://diwmsi.onshape.com/api/documents/993ef362e23e4a31bec90137",
    id: "993ef362e23e4a31bec90137",
    name: "CM_VA-316-0003"
    }   
  ]

  constructor(private helper: Helper) {
    console.log('MockDataService constructor');
    this.initData();
    this.initFiles();
  }

  createRouter(r: IRouter): IRouter {
    const ret: IRouterExtended = this.createRouterExtendend(r);

    this.ROUTERS.push(ret);

    return this.extractIRouter(ret);
  }

  private extractIRouter(info: IRouterExtended): IRouter {
    if (info == null) {
      return null;
    }
    return {
        id: info.id,
        partId: info.partId,
        partNumber: info.partNumber,
        partDescription: info.partDescription,
        revision: info.revision,
        state: info.state,
        type: info.type,
        lastUpdate: info.lastUpdate,
        userId: info.userId,
        updatedBy: info.updatedBy,
        activeWIPTotal: info.activeWIPTotal,
        targetWIP: info.targetWIP,
        psl: info.psl
    };
  }

  private createRouterExtendend(r: IRouter): IRouterExtended {
    return {
        id: Guid.newGuid(),
        partId: r.partId,
        partNumber: r.partNumber,
        partDescription: r.partDescription,
        revision: 1,
        state: RouterState.PENDING,
        type: r.type,
        lastUpdate: new Date(),
        updatedBy: 'R. Eiffert',
        userId: Guid.newGuid(),
        activeWIPTotal: 0,
        targetWIP: 0,
        psl: 1,
        steps: []
    };
  }

  private createRouterFromTemplate(r: IRouterExtended): IRouterExtended {
    const router = {
        id: Guid.newGuid(),
        partId: r.partId,
        partNumber: r.partNumber,
        partDescription: r.partDescription,
        revision: r.revision,
        state: r.state,
        type: r.type,
        lastUpdate: r.lastUpdate,
        updatedBy: r.updatedBy,
        userId: r.userId,
        activeWIPTotal: r.activeWIPTotal,
        targetWIP: r.targetWIP,
        psl: r.psl,
        steps: []
    };

    router.steps = this.createStepsFromTemplate(router.id, r.steps);
    return router;
  }

  private copyRouterExtended(r: IRouterExtended): IRouterExtended {
    return {
        id: r.id,
        partId: r.partId,
        partNumber: r.partNumber,
        partDescription: r.partDescription,
        revision: r.revision,
        state: r.state,
        type: r.type,
        lastUpdate: r.lastUpdate,
        updatedBy: r.updatedBy,
        userId: r.userId,
        activeWIPTotal: r.activeWIPTotal,
        targetWIP: r.targetWIP,
        psl: r.psl,
        steps: this.copySteps(r.id, r.steps)
    };
  }

  private createStepsFromTemplate(routerId: string, steps: IRouterStepExtended[]): IRouterStepExtended[] {
    const ret: IRouterStepExtended[] = [];

    for (const s of steps)
    {
        ret.push(this.createStepFromTemplate(routerId, s));
    }
    return ret;
  }

  private copySteps(routerId: string, steps: IRouterStepExtended[]): IRouterStepExtended[] {
    const ret: IRouterStepExtended[] = [];

    for (const s of steps)
    {
      ret.push(this.copyRouterStepExtended(routerId, s));
    }
    return ret;
  }

  createRouterFromRouter(routerId: string): IRouter {
    const template = this.ROUTERS.find(x => x.id === routerId);
    if (template) {
    const r = this.copyRouterExtended(template);
    r.id = Guid.newGuid();
    r.partNumber = '';
    r.partDescription = '';
    r.revision = 1;
    r.activeWIPTotal = 0;
    r.lastUpdate = new Date();
    r.updatedBy = 'R. Eiffert';
    this.ROUTERS.push(r);
    return this.extractIRouter(r);
    }

    return null;
  }

  private getLatestVersionRouter(routerId: string): IRouterExtended {
    const r = this.getRouterExtended(routerId);
    if (r == null) {
      return null; 
    }

    const routers = this.ROUTERS.filter(x => x.partNumber === r.partNumber);
    if (routers == null || routers.length === 0) {
        return null;
    }

    const sorted =  routers.sort((x, y): number => {
      if (x.revision < y.revision) { return -1; }
      if (x.revision > y.revision) { return 1; }
      return 0;
    });

    const template = sorted[sorted.length - 1];
    return template;
  }

  createNewRouterVersion(routerId: string): IRouter {
    const info = this.getLatestVersionRouter(routerId);
    if (info == null) {
        return null;
    }

    const r = this.copyRouterExtended(info);
    r.id = Guid.newGuid();
    r.revision = info.revision + 1;
    r.lastUpdate = new Date();
    r.state = RouterState.PENDING;
    this.ROUTERS.push(r);
    return this.extractIRouter(r);
  }

  getRouterNotes(routerId: string): string {
    const ret = this.ROUTERNOTES.find(x => x.id === routerId);
    if (ret) {
      return ret.notes;
    } else  {
    return null;
    }
  }

  updateRouterNotes(routerId: string, notes: string) {
    const ret = this.ROUTERNOTES.find(x => x.id === routerId);
    if (ret) {
      ret.notes = notes;
    } else  {
      this.ROUTERNOTES.push({id: routerId, notes: notes});
    }
  }

  // ROUTERNOTES = [];

  updateRouter(info: IRouter): IRouter {
    const r = this.getRouterExtended(info.id);
    if (r) {
      r.id = info.id;
      r.partNumber = info.partNumber;
      r.partDescription = info.partDescription;
      r.revision = info.revision;
      r.state = info.state;
      r.type = info.type;
      r.lastUpdate = info.lastUpdate;
      r.updatedBy = info.updatedBy;
      r.activeWIPTotal = info.activeWIPTotal;
      return this.extractIRouter(r);
    }
    return null;
  }

  getRouter(routerId: string): IRouter {
    const r = this.getRouterExtended(routerId);
    return this.extractIRouter(r);
  }

  private getRouterExtended(routerId: string): IRouterExtended {
    const r = this.ROUTERS.find(x => x.id === routerId);
    return r;
  }

  getRouters(): IRouter[] {
    const retList: IRouter[] = [];
    for (const r of this.ROUTERS) {
      retList.push(r);
    }

     return retList;
  }

  deleteRouter(routerId: string): void {
    this.ROUTERS = this.ROUTERS.filter(x => x.id !== routerId);
    if (this.ROUTERS == null) {
      this.ROUTERS = [];
    }
  }

  publishRouter(router: IRouter): IRouter {
    if (router.state === RouterState.PENDING) {
      const r = this.ROUTERS.find(ro => r.id === router.id);
      if (r) {
        r.state = RouterState.ACTIVE;
        return this.extractIRouter(r);
      }
    }
    return null;
  }

  private createStepFromTemplate(newRouterId: string, info: IRouterStepExtended): IRouterStepExtended {
    if (info == null) {
      return null;
    }

    const s = {
          id: Guid.newGuid(),
          routerId: newRouterId,
          step: info.step,
          description: info.description,
          printOption: info.printOption,
          setupTime: info.setupTime,
          avgTime: info.avgTime,
          familyId: info.familyId,
          runTime: info.runTime,
          idleTime: info.idleTime,
          doFgLot: info.doFgLot,
          deferTesting: info.deferTesting,
          workCenterId: info.workCenterId,
          workCenterName: info.workCenterName,
          printType: info.printType,
          bestTime: info.bestTime,
          tests: [],
          bomItems: []
        };

    s.tests = this.copyTests(s, info.tests);

    s.bomItems = this.copyBOMItems(s, info.bomItems);

    return s;
  }

  private copyRouterStepExtended(newRouterId: string, info: IRouterStepExtended): IRouterStepExtended {
    if (info == null) {
      return null;
    }

    const newStepId: string = Guid.newGuid();

    const s = {
          id: newStepId,
          routerId: newRouterId,
          step: info.step,
          description: info.description,
          printOption: info.printOption,
          setupTime: info.setupTime,
          avgTime: info.avgTime,
          familyId: info.familyId,
          runTime: info.runTime,
          idleTime: info.idleTime,
          doFgLot: info.doFgLot,
          deferTesting: info.deferTesting,
          workCenterId: info.workCenterId,
          workCenterName: info.workCenterName,
          printType: info.printType,
          bestTime: info.bestTime,
          tests: [],
          bomItems: []
        };

    s.tests = this.copyTests(s, info.tests);

    s.bomItems = this.copyBOMItems(s, info.bomItems);

    return s;
  }

  createIRouterStepFromIRouterStepExtended(info: IRouterStepExtended): IRouterStep {
    return {
          id: info.id,
          routerId: info.routerId,
          step: info.step,
          description: info.description,
          printOption: info.printOption,
          setupTime: info.setupTime,
          avgTime: info.avgTime,
          familyId: info.familyId,
          runTime: info.runTime,
          idleTime: info.idleTime,
          doFgLot: info.doFgLot,
          deferTesting: info.deferTesting,
          workCenterId: info.workCenterId,
          workCenterName: info.workCenterName,
          printType: info.printType,
          bestTime: info.bestTime,
        };
  }

  createIRouterStepListfromIRouterExtendedList(sList: IRouterStepExtended[]): IRouterStep[] {
    if (this.helper.isEmptyArray(sList)) {
      return null;
    }

    const retList: IRouterStep[] = [];
    for (const s of sList)  {
        retList.push(this.createIRouterStepFromIRouterStepExtended(s));
      }

    return retList;
  }

  createRouterStep(info: IRouterStep): IRouterStep {
    const r = this.getRouterExtended(info.routerId);
    if (r) {
      const s = {
            id: Guid.newGuid(),
            routerId: info.routerId,
            step: info.step,
            description: info.description,
            printOption: info.printOption,
            setupTime: info.setupTime,
            avgTime: info.avgTime,
            familyId: info.familyId,
            runTime: info.runTime,
            idleTime: info.idleTime,
            doFgLot: info.doFgLot,
            deferTesting: info.deferTesting,
            workCenterId: info.workCenterId,
            workCenterName: info.workCenterName,
            printType: info.printType,
            bestTime: info.bestTime,
            bomItems: [],
            tests: []
          };

      r.steps.push(s);

      return this.createIRouterStepFromIRouterStepExtended(s);
    }
    return null;
  }

  getRouterSteps(routerId: string): IRouterStep[] {
      const r = this.getRouterExtended(routerId);
      if (r) {return this.createIRouterStepListfromIRouterExtendedList(r.steps); } else  { return null; }
  }

  getRouterStep(routerStepId: string): IRouterStep {
    for (const r of this.ROUTERS) {
      for (const s of r.steps) {
        if (s.id === routerStepId) {
          return this.createIRouterStepFromIRouterStepExtended(s);
        }
      }
      return null;
    }
  }

  getRouterStepNotes(stepId: string): IRouterStepNotes {
    const ret = this.ROUTERSTEPNOTES.find(x => x.id === stepId);
    if (ret) {
      return {
        id: ret.id,
        userNotes: ret.userNotes,
        setupNotes: ret.setupNotes
      };
    } else  {
      return null;
    }
  }

  updateRouterStepNotes(stepId: string, setupNotes: string, userNotes: string) {
    const ret = this.ROUTERSTEPNOTES.find(x => x.id === stepId);
    if (ret) {
      ret.setupNotes = setupNotes;
      ret.userNotes = userNotes;
    } else  {
      this.ROUTERSTEPNOTES.push({id: stepId, setupNotes: setupNotes, userNotes: userNotes});
    }
  }

  // ROUTERSTEPNOTES = [];

  updateRouterStep(info: IRouterStep): IRouterStep {
    const s = this.getRouterStepExtended(info.id);
    if (s) {
      s.step = info.step;
      s.description = info.description;
      s.printOption = info.printOption;
      s.setupTime = info.setupTime;
      s.avgTime = info.avgTime;
      s.familyId = info.familyId;
      s.runTime = info.runTime;
      s.idleTime = info.idleTime;
      s.deferTesting = info.deferTesting;
      s.workCenterId = info.workCenterId,
      s.printType = info.printType,
      s. bestTime = info.bestTime;

      return this.createIRouterStepFromIRouterStepExtended(s);
    }

    return null;
  }

  private getRouterStepExtended(id: string): IRouterStepExtended {
    for (const r of this.ROUTERS){
      for (const s of r.steps) {
        if (s.id === id) {
          return s;
        }
      }
      return null;
    }
  }

  deleteRouterStep(id: string): void {
    const s = this.getRouterStepExtended(id);
    if (s) {
      const r = this.getRouterExtended(s.routerId);
      r.steps = r.steps.filter(x => x.id !== id);
      if (r.steps === null) {
        r.steps = [];
      }
    }
  }

  /**BOM Item */
  copyBOMItem(info: IBOMItem): IBOMItem {
    return {
      id: info.id,
      routerStepId: info.routerStepId,
      step: info.step,
      stepDescription: info.stepDescription,
      partDescription: info.partDescription,
      partId: info.partId,
      partNumber: info.partNumber,
      partType: info.partType,
      qty: info.qty,
      sizeEach: info.sizeEach,
      partUnitType: info.partUnitType,
      partTraceType: info.partTraceType,
      autoAlloc: info.autoAlloc
    };
  }

  createNewBOMItem(info: IBOMItem, routerStep: IRouterStep = null, id: string= null): IBOMItem {
    const b = this.copyBOMItem(info);
    if (routerStep != null) {
      b.routerStepId = routerStep.id;
      b.step = routerStep.step;
    }

    if (id == null) { b.id = Guid.newGuid() ; } else { b.id = id; } return b;
  }

  copyBOMItems(routerStep: IRouterStep, bomItems: IBOMItem[]): IBOMItem[] {
    if (this.helper.isEmptyArray(bomItems)) {
      return null;
    }

    const retList: IBOMItem[] = [];

    for (const b of bomItems) {
      retList.push(this.createNewBOMItem(b, routerStep));
    }

    return retList;
  }

  createBOMItem(info: IBOMItem): IBOMItem {
    const s = this.getRouterStepExtended(info.routerStepId);
    if (s) {
      const b = {
            id: Guid.newGuid(),
            routerStepId: info.routerStepId,
            step: info.step,
            stepDescription: info.stepDescription,
            partDescription: info.partDescription,
            partId: info.partId,
            partNumber: info.partNumber,
            partType: info.partType,
            qty: info.qty,
            sizeEach: info.sizeEach,
            partUnitType: info.partUnitType,
            partTraceType: info.partTraceType,
            autoAlloc: info.autoAlloc
      };

      s.bomItems.push(b);
      return this.copyBOMItem(b);
    } else {
      return null; 
    }
  }

  getRouterBOMItems(routerId: string): IBOMItem[] {
    const r = this.getRouterExtended(routerId);
    const ret: IBOMItem[] = [];
    if (r) {
      for (const s of r.steps) {
        for (const b of s.bomItems) {
          ret.push(this.copyBOMItem(b));
        }
      }
    }
    return ret;
  }

  getRouterStepBOMItems(routerStepId: string): IBOMItem[] {
    const s = this.getRouterStepExtended(routerStepId);
    const ret: IBOMItem[] = [];
    if (s) { for (const b of s.bomItems) { ret.push(this.copyBOMItem(b)); } } return ret; 
  }

  getBOMItem(id: string): IBOMItem {
    const b = this.findBOMItem(id);
    if (b) { return this.copyBOMItem(b); } else { return null; }
  }

  private findBOMItem(id: string): IBOMItem {
    for (const r of this.ROUTERS) {
      for (const s of r.steps) {
        for (const b of s.bomItems) {
          if (b.id === id) {  return b; }
        }
      }
      return null;
    }
  }

  updateBOMItem( info: IBOMItem): IBOMItem {
    const b  = this.findBOMItem(info.id);
    if (b == null) {
      return null;
    }

    b.routerStepId = info.routerStepId;
    b.step = info.step;
    b.partId = info.partId;
    b.partNumber = info.partNumber;

    b.qty = info.qty;
    b.sizeEach = info.sizeEach;
    b.partUnitType = info.partUnitType;
    b.autoAlloc = info.autoAlloc;

    return this.copyBOMItem(b);
  }

  deleteBOMItem(id: string): void {
    const b = this.findBOMItem(id);
    if (b) {
      const s = this.getRouterStepExtended(b.routerStepId);
      s.bomItems = s.bomItems.filter(x => x.id !== id);
      if (s.bomItems == null) {
        s.bomItems = [];
      }
    }
  }

  /** TEST */
  copyTest(info: ITest): ITest {
    const t = {
      id: info.id,
      routerStepId: info.routerStepId,
      step: info.step,
      stepDescription: info.stepDescription,
      specificationId: null,
      specificationName: null,
      description: info.description,
      type: info.type,
      min: info.min,
      max: info.max,
      frequency: info.frequency,
      notes: info.notes,
      unitType: info.unitType,
      testOrder: info.testOrder,
      testDeviceType: info.testDeviceType,
      testDeviceCount: info.testDeviceCount
    };

    return t;
  }

  private createNewTest(routerStep: IRouterStep, info: ITest): ITest {
    const t = this.copyTest(info);
    t.id = Guid.newGuid();
    t.routerStepId = routerStep.id;
    t.step = routerStep.step;

    return t;
  }

  copyTests(routerStep: IRouterStep, tests: ITest[]): ITest[] {
    if (this.helper.isEmptyArray(tests)) {
      return null;
    }

    const retList: ITest[] = [];

    for (const t of tests) {
      retList.push(this.createNewTest(routerStep, t));
    }

    return retList;
  }

  createTest(info: ITest): ITest {
    const s = this.getRouterStepExtended(info.routerStepId);
    if (s) {
      const t = this.createNewTest(s, info);
      s.tests.push(t);
      return this.copyTest(t);
    } else {
      return null;
    }
  }

  getTest(testId: String): ITest {
    const t = this.findTest(testId);
    if (t) {
      return this.copyTest(t);
    } else {
      return null;
    }
  }

  private findTest(testId: String): ITest {
    for (const r of this.ROUTERS) {
      for (const s of r.steps) {
        for (const t of s.tests) {
            if (t.id === testId) {
              return t;
            }
        }
      }
    }
    return null;
  }

  getRouterStepTests(routerStepId: string): ITest[] {
    const s = this.getRouterStepExtended(routerStepId);
    if (s) {
    const retList: ITest[] = [];
    for (const t of s.tests) {
      retList.push(this.copyTest(t));
    }
      return retList;
    }

    return null;
  }

  getRouterTests(routerId: string): ITest[] {
    const r = this.getRouterExtended(routerId);
    if (r == null) {
      return null;
    }
    const retList: ITest[] = [];

    for (const s of r.steps) {
      for (const t of s.tests) {
        retList.push(this.copyTest(t));
      }
      return retList;
    }
  }

  updateTest(info: ITest): ITest {
    const t = this.findTest(info.id);
    if (t == null) {
      return null;
    }
    t.routerStepId = info.routerStepId,
    t.step = info.step;
    t.specificationId = info.specificationId,
    t.description = info.description,
    t.type = info.type,
    t.min = info.min,
    t.max = info.max,
    t.frequency = info.frequency,
    t.notes = info.notes,
    t.unitType = info.unitType,
    t.testOrder = info.testOrder,
    t.testDeviceType = info.testDeviceType,
    t.testDeviceCount = info.testDeviceCount;

    return this.copyTest(t);
  }

  deleteTest(id: string): void {
    for (const r of this.ROUTERS) {
      for (const s of r.steps) {
        for (const t of s.tests) {
            if (t.id === id) {
              s.tests = s.tests.filter(x => x.id !== id);
            }
            if (s.tests == null) {
              s.tests = [];
            }
        }
      }
      return;
    }
  }

  /* PARTS */
  getPart(id: string): IPart {
    return this.PARTS.find(x => x.id === id);
  }

  getRouterPart(router: IRouter): IPart {
    return this.PARTS.find(x => x.number === router.partNumber);
  }

  getParts(): IPart[] {
    return this.PARTS;
  }

  getEtchingInfo(partId: string): IEtchingInfo {
    const pi = this.PARTINFOS.find(x => x.partId === partId);
    if (pi) {
      return this.helper.copyIEtchingInfo(pi.etchingInfo);
    } else {
      return null;
    }
  }

  updateEtchingInfo(partId: string, etchingInfo: IEtchingInfo): IPartInfo {
    let pi = this.PARTINFOS.find(x => x.partId === partId);
    if (pi) {
      pi.etchingInfo = this.helper.copyIEtchingInfo(etchingInfo);
      return this.helper.copyIPartInfo(pi);
    } else {
      pi = this.helper.makeIPartInfo(etchingInfo);
      pi.partId = partId;
      this.PARTINFOS.push(pi);
      return this.helper.copyIPartInfo(pi);
    }
  }

  // PARTINFOS: IPartInfo[] = [];

  /* Work Centers */
  createWorkCenter(workCenter: IWorkCenter): IWorkCenter {
    const ret = this.helper.makeIWorkCenterExtended(workCenter);
    ret.workCenter.id = Guid.newGuid();
    this.WORKCENTERS.push(ret);
    return this.helper.extractIWorkCenter(ret);
  }

  getWorkCenter(id: string): IWorkCenter {
    const wc = this.WORKCENTERS.find(x => x.workCenter.id === id);
    return this.helper.extractIWorkCenter(wc);
  }

  getWorkCenters(): IWorkCenter[] {
    const ret: IWorkCenter[] = [];
    for (const wc of this.WORKCENTERS) {
      ret.push(this.helper.extractIWorkCenter(wc));
    }
    return ret;
  }

  updateWorkCenter(info: IWorkCenter): IWorkCenter {
    const ret = this.WORKCENTERS.find(x => x.workCenter.id === info.id);
    if (ret) {
      ret.workCenter.type = info.type;
      ret.workCenter.description = info.description;
      ret.workCenter.isOutsource = info.isOutsource;
      ret.workCenter.scheduleType = info.scheduleType;
      ret.workCenter.warehouse = info.warehouse;
    }
    return this.helper.extractIWorkCenter(ret);
  }


  updateWorkCenterAndStations(w: IWorkCenter, stations:IWorkStation[]): IWorkCenter {

      return w;
  }

  deleteWorkCenter(id: string): number {
    this.WORKCENTERS = this.WORKCENTERS.filter(x => x.workCenter.id !== id);
    return 1;
  }

  /* Work Stations */
  createWorkStation(workStation: IWorkStation): IWorkStation {
    const ret = this.helper.copyIWorkStation(workStation);
    ret.id = Guid.newGuid();
    const wc = this.WORKCENTERS.find(x => x.workCenter.id === workStation.workCenterId);
    wc.workStations.push(ret);
    return this.helper.copyIWorkStation(ret);
  }

  getWorkStationsForWorkCenter(workCenterId: string): IWorkStation[] {
    const wc = this.WORKCENTERS.find(x => x.workCenter.id === workCenterId);
    const ret: IWorkStation[] = [];

    for (const ws of wc.workStations) {
      ret.push(this.helper.copyIWorkStation(ws));
    }

    return ret;
  }

  updateWorkStation(info: IWorkStation): IWorkStation {
    const wc = this.WORKCENTERS.find(x => x.workCenter.id === info.workCenterId);
    if (wc) {
      for (const ws of wc.workStations){
        if (ws.id === info.id) {
          ws.number = info.number;
          ws.description = info.description;
          return this.helper.copyIWorkStation(ws);
        }
      }
    }
    return null;
  }

  deleteWorkStation(id: string): number {
    for (const wc of this.WORKCENTERS){
      for (const ws of wc.workStations){
        if (ws.id === id) {
          wc.workStations = wc.workStations.filter(x => x.id !== id);
          return 1;
        }
      }
    }
    return 0;
  }

  // FILES
  private extractIFile(info: IRouterFile): IFile {
    if (info == null) {
      return null;
    }
    return {
          id: info.id,
          name: info.name,
          specificationName: info.specificationName,
          description: info.description,
          revision: info.revision,
          type: info.type,
          url: info.url,
          sourceUrl: info.sourceUrl,
          revisionDate: info.revisionDate
    };
  }

  createFile(f: IFile): IFile {
    const newFile = this.helper.copyFile(f);
    newFile.id = Guid.newGuid();
    newFile.url = '/nomuda/files/' + newFile.name;
    this.FILES.push(newFile);
    return this.helper.copyFile(newFile);
  }

  getFile(id: string): IFile {
    const ret = this.FILES.find( f => f.id === id);
    return this.helper.copyFile(ret);
  }

  getFiles(): IFile[] {
    const ret: IFile[] = [];
    for (const f of this.FILES){
      ret.push(this.helper.copyFile(f));
    }

    return ret;
  }

  addFileToRouterStep(routerId: string, step: IRouterStep, f: IFile): IRouterFile {
    const file = this.helper.copyFile(f);
    const rf = this.helper.makeIRouterFile(routerId, step, file);

    this.ROUTERFILES.push(rf);
    return this.helper.copyIRouterFile(rf);
  }

  addFileToPart(partId: string, f: IFile): number {
    const tmp = this.PARTFILES.find(x => x.partId === partId && x.file.id === f.id);
    if (tmp) {
      return 0;
    }

    const file = this.helper.copyFile(f);
    const pf = {
      partId: partId,
      file: this.helper.copyFile(f)
    };

    this.PARTFILES.push(pf);

    return 1;
  }

  removeFileFromPart(partId: string, file: IFile): number {
    const partFile = this.PARTFILES.find(x => x.partId === partId && x.file.id === file.id);
    if (partFile) {
      this.PARTFILES = this.PARTFILES.filter(x => x !== partFile);
      return 1;
    }
    return 0;
  }

  getFilesForPart(partId: string): IFile[] {
    const ret: IFile[] = [];
    for (const pf of this.PARTFILES){
      if (pf.partId === partId) {
        ret.push(pf.file);
      }
    }
    return ret;
  }

  // PARTFILES: IMDPartFile[] = [];

  addFileToRouter(routerId: string, f: IFile): IRouterFile {
    const file = this.helper.copyFile(f);
    const rf = this.helper.makeIRouterFile(routerId, null, file);

    this.ROUTERFILES.push(rf);
    return this.helper.copyIRouterFile(rf);
  }

  removeFileFromRouter(f: IRouterFile): number {
    const rf = this.ROUTERFILES.find(x => x.routerId === f.routerId && x.routerOperationId === f.routerOperationId && x.id === f.id);
    if (rf) {
      this.ROUTERFILES = this.ROUTERFILES.filter(x => x !== rf);
      return 1;
    }
    return 0;
  }

  removeFileFromRouterStep(f: IRouterFile): number {
    const rf = this.ROUTERFILES.find(x => x.routerId === f.routerId && x.routerOperationId === f.routerOperationId && x.id === f.id);
    if (rf) {
      this.ROUTERFILES = this.ROUTERFILES.filter(x => x !== rf);
      return 1;
    }
    return 0;
  }

  getFilesForRouterStep(stepId: string): IRouterFile[] {
    console.log('getFilesForRouterStep');
    let ret: IRouterFile[];

    const step = this.getRouterStep(stepId);

    ret = this.getFilesForRouter(step.routerId);
    ret = ret.filter(x => x.routerOperationNumber === null || x.routerOperationId === stepId);
    console.log('getFilesForRouterStep returns file count:' + ret ? ret.length : 'null');
    return ret;
  }

  getFilesForRouter(routerId: string): IRouterFile[] {
    console.log('getFilesForRouter');
    const ret: IRouterFile[] = [];
    for (const f of this.ROUTERFILES){
      if (f.routerId === routerId) {
        ret.push(this.helper.copyIRouterFile(f));
      }
    }
    console.log('......router files:' + (ret ? ret.length : 'null'));
    const rt = this.getRouter(routerId);
    const pt = this.getRouterPart(rt);

    for (const pf of this.PARTFILES){
      if (pf.partId === pt.id) {
        const rf = this.helper.makeIRouterFile(rt.id, null, pf.file);
        rf.mapType = MapType.PART;

        ret.push(rf);
      }
    }
    console.log('getFilesForRouter returns:' + (ret ? ret.length : 'null'));
    return ret;
  }

  getEngineeringDrawings(): IFile[] {
    const ret: IFile[] = [];
    for (const f of this.FILES){
      if ( f.id !== null && f.type === FileType.DRAWING) {
        ret.push( this.helper.copyFile(f));
      }
    }
    return ret;
  }

  getEngineeringSpecs(): IFile[] {
    const ret: IFile[] = [];
    for (const f of this.FILES){
      if ( f.id !== null && f.type === FileType.SPEC) {
        ret.push( this.helper.copyFile(f));
      }
    }

    return ret;
  }

  getRoutersAssociatedWithFile(id: string): IRouter[] {
    const ret: IRouter[] = [];
    for ( const rf of this.ROUTERFILES ) {
      if (rf.id === id) {
          const r = this.getRouter(rf.routerId);
              ret.push(r);
      }
    }
    return ret;
  }

  deleteFile(id: string): number {
    this.FILES = this.FILES.filter(x => x.id !== id);
    return 1;
  }

  initFiles(): void {
    const fileType: FileType = FileType.SPEC;
    
    for (const p of this.PARTS){
      const f = {
        id: Guid.newGuid(),
        name: p.number,
        specificationName: p.number,
        description: p.number + ' '  + fileType,
        revision: 'A',
        revisionDate: new Date(),
        type: fileType,
        url : '/nomuda/files/' + p.number,
        sourceUrl : '/engineering/files/' + p.number
      };
      this.FILES.push(f);
    }
  }

  // FILES

  // PROGRAMS
  createProgram(info: IProgram): IProgram {
    const ret = this.helper.makeIRouterStepProgram(info);
    ret.program.id = Guid.newGuid();
    this.PROGRAMS.push(ret);
    return this.helper.extractIProgram(ret);
  }

  getProgram(id: string): IProgram {
    const ret = this.PROGRAMS.find( f => f.program.id === id);
    return this.helper.extractIProgram(ret);
  }

  getPrograms(): IProgram[] {
    const ret: IProgram[] = [];
    for (const p of this.PROGRAMS){
      ret.push(this.helper.extractIProgram(p));
    }

    return ret;
  }

  addProgramToRouterStep(stepId: string, program: IProgram): IProgram {
    const ret = this.PROGRAMS.find(x => x.program.id === program.id);
    if (ret ) {
      ret.stepId = stepId;
      return this.helper.extractIProgram(ret);
    }

    return null;
  }

  removeProgramFromRouterStep(stepId: string, programId: string): number {
    const ret = this.PROGRAMS.find(x => x.program.id === programId && x.stepId === stepId);
    if (ret ) {
      ret.stepId = null;
      return 1;
    }

    return 0;
  }

  getProgramsForRouterStep(stepId: string): IProgram[] {
    const ret: IProgram[] = [];
    for (const p of this.PROGRAMS){
      if (p.stepId === stepId) {
        ret.push(this.helper.extractIProgram(p));
      }
    }

    return ret;
  }

  updateProgram(p: IProgram): IProgram {
    const target = this.PROGRAMS.find(x => x.program.id === p.id);
    if (target) {
      target.program.number = p.number;
      target.program.description = p.description;
      return this.helper.extractIProgram(target);
    }

    return null;
  }

  deleteProgram(id: string): number {
    this.PROGRAMS = this.PROGRAMS.filter(x => x.program.id !== id);
    return 1;
  }

  // PROGRAMS

  // FIXTURES
  createFixture(info: IFixture): IFixture {
    const ret = this.helper.makeIRouterStepFixture(info);
    ret.fixture.id = Guid.newGuid();
    this.FIXTURES.push(ret);
    return this.helper.extractIFixture(ret);
  }

  getFixtures(): IFixture[] {
    const ret: IFixture[] = [];
    for (const f of this.FIXTURES){
      ret.push(this.helper.extractIFixture(f));
    }

    return ret;
  }

  addFixtureToRouterStep(stepId: string, fixture: IFixture): IFixture {
    const ret = this.FIXTURES.find(x => x.fixture.id === fixture.id);
    if (ret ) {
      ret.stepId = stepId;
      return this.helper.extractIFixture(ret);
    }

    return null;
  }

  removeFixtureFromRouterStep(stepId: string, fixtureId: string): number {
    const ret = this.FIXTURES.find(x => x.fixture.id === fixtureId && x.stepId === stepId);
    if (ret ) {
      ret.stepId = null;
      return 1;
    }

    return 0;
  }

  getFixturesForRouterStep(stepId: string): IFixture[] {
    const ret: IFixture[] = [];
    for (const f of this.FIXTURES){
      if (f.stepId === stepId) {
        ret.push(this.helper.extractIFixture(f));
      }
    }

    return ret;
  }

  updateFixture(f: IFixture): IFixture {
    const target = this.FIXTURES.find(x => x.fixture.id === f.id);
    if (target) {
      target.fixture.number = f.number;
      target.fixture.description = f.description;
      return this.helper.extractIFixture(target);
    }

    return null;
  }

  deleteFixture(id: string): number {
    this.FIXTURES = this.FIXTURES.filter(x => x.fixture.id !== id);
    return 1;
  }

  // FIXTURES

  // TOOLS
  createTool(info: ITool): ITool {
    const ret = this.helper.copyTool(info);
    ret.id = Guid.newGuid();
    this.TOOLS.push(ret);
    return this.helper.copyTool(ret);
  }

  getTool(id: string): ITool {
    const ret = this.TOOLS.find( f => f.id === id);
    return this.helper.copyTool(ret);
  }

  getTools(): ITool[] {
    const ret: ITool[] = [];
    for (const t of this.TOOLS){
      ret.push(this.helper.copyTool(t));
    }

    return ret;
  }

  updateTool(p: ITool): ITool {
    const target = this.TOOLS.find(x => x.id === p.id);
    if (target) {
      target.description = p.description;
      return this.helper.copyTool(target);
    }

    return null;
  }

  deleteTool(id: string): number {
    this.TOOLS = this.TOOLS.filter(x => x.id !== id);
    return 1;
  }

  createToolBag(stepId: string, info: IToolBag): IToolBag {
    console.log('mock.createToolBag');
    const ret = this.helper.copyIToolBag(info);

    ret.id = Guid.newGuid();
    console.log('...station tools length:' + this.ROUTERSTEPTOOLS.length);
    this.ROUTERSTEPTOOLS.push(ret);
    console.log('...station tools length:' + this.ROUTERSTEPTOOLS.length);
    console.log('...returns st:' + ret.id);
    return this.helper.copyIToolBag(ret);
  }

  updateToolBag(info: IToolBag): IToolBag {
    return null;
  }

  deleteToolBag(stId: string): number {
    this.ROUTERSTEPTOOLS = this.ROUTERSTEPTOOLS.filter(x => x.id !== stId);
    return 1;
  }

  getToolBagsForRouterStep(stepId: string): IToolBag[] {
    const ret: IToolBag[] = [];
    for (const st of this.ROUTERSTEPTOOLS){

    }

    return this.helper.copyIToolBags(ret);
  }

  // TOOLS
  initData(): void {
    for (const r of this.ROUTERS)
    {
      const s1 = this.makeRouterStepExtended(r.id, Guid.newGuid(), 'Issue Material', 'Issue', 1);
      const s2 = this.makeRouterStepExtended(r.id, Guid.newGuid(), 'Lathe Material', 'Lathe', 2);
      const b1 = this.makeBOMItem(s1, 'CA0015', 1, 1);
      const b2 = this.makeBOMItem(s2, 'CC0018', 3, 1);

      const t1 = this.makeIPITest(s1, 1, 2);
      const t2 = this.makeIPITest(s2, 10, 20);
      s1.bomItems = [b1];
      s1.tests = [t1];

      s2.bomItems = [b2];
      s2.tests = [t2];

      r.steps = [s1, s2];
    }
  }

  private makeIPITest(routerStep: IRouterStep, min: number, max: number): ITest {
    return {
      id: Guid.newGuid(),
      routerStepId: routerStep.id,
      step: routerStep.step,
      stepDescription: routerStep.description,
      specificationId: null,
      specificationName: null,
      description: 'IPI test',
      type: TestType.IPI,
      min: min,
      max: max,
      frequency: 1,
      notes: 'test notes',
      unitType: UnitType.INCH,
      testOrder: 0,
      testDeviceType: TestDeviceType.SERIALIZED,
      testDeviceCount: 0
    };
  }

  makeBOMItem(routerStep: IRouterStep, partNumber: string, qty: number, sizeEach: number): IBOMItem {
    const p: IPart = this.PARTS.find(x => x.number === partNumber);

    return {
        id: Guid.newGuid(),
        routerStepId: routerStep.id,
        step: routerStep.step,
        stepDescription: routerStep.description,
        partNumber: p.number,
        partDescription: p.description,
        partId: p.id,
        partType: p.type,
        qty: qty,
        sizeEach: sizeEach,
        partUnitType: UnitType.EACH,
        partTraceType: TraceType.SERIAL,
        autoAlloc: false
    };
  }

  makeRouterStepExtended(routerId: string, id: string, description: string, workCenterDescription: string,  step: number): IRouterStepExtended {
    const wc = this.WORKCENTERS.find(w => w.workCenter.description === workCenterDescription);

    return {
          id: id,
          routerId: routerId,
          step: step,
          description: description,
          printOption: PrintOption.AUTO,
          setupTime: 100,
          avgTime: 200,
          familyId: 'D1',
          runTime: 300,
          idleTime: 400,
          doFgLot: false,
          deferTesting: false,
          workCenterId: wc.workCenter.id,
          workCenterName: wc.workCenter.name,
          printType: PrintType.S1,
          bestTime: 500,
          bomItems: [],
          tests: []
    };
  }

  // ROUTERSTEPTOOLS: IToolBag[] = [];

}
