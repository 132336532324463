import { Helper } from '../services/helper.service';


export interface IRouterStep {
  id: string;
  routerId: string;
  step: number;
  description: string;
  printOption: PrintOption;
  setupTime: number;
  avgTime: number;
  familyId: string;
  runTime: number;
  idleTime: number;
  doFgLot: boolean;
  deferTesting: boolean;
  workCenterId: string;
  workCenterName: string;
  printType: PrintType;
  bestTime: number;
}

export interface IRouterStepNotes {
  id: string;
  setupNotes: string;
  userNotes: string;
}

enum PrintOptionService {
  UNKNOWN = 0,
  AUTO = 1,
  OPTIONAL = 2
}

export enum PrintOption {
  UNKNOWN = 'UNKNOWN',
  AUTO = 'auto',
  OPTIONAL = 'optional'
}

export interface IRouterStepNDE {
  ndeName: string;
  parameter: string;
  value: string;
  specificationName: string;
  specificationFileName: string;
  specificationRevision: string;
  specificationPath: string;

}

export class IdOrder {
  constructor(public id: string, public order: number) {}
}

export class StepOrder {
  public userId: string = null;

  constructor(
    public routerId: string, 
    public map: IdOrder[]) {}
}

export class RouterStepUtils {
  static areEqual(r1: IRouterStep, r2: IRouterStep) {
    if (r1 == null) {
      return r2 == null;
    }

    if (r2 == null) {
      return false;
    }

    return Helper.areEqualStr(r1.id, r2.id) &&
      Helper.areEqualStr(r1.routerId, r2.routerId) &&
      r1.step === r2.step &&
      Helper.areEqualStr(r1.description, r2.description) &&
      r1.printOption === r2.printOption &&
      r1.setupTime === r2.setupTime &&
      r1.avgTime === r2.avgTime &&
      Helper.areEqualStr(r1.familyId, r2.familyId) &&
      r1.runTime === r2.runTime &&
      r1.idleTime === r2.idleTime &&
      r1.doFgLot === r2.doFgLot &&
      r1.deferTesting === r2.deferTesting &&
      Helper.areEqualStr(r1.workCenterId, r2.workCenterId) &&
      r1.workCenterName === r2.workCenterName &&
      r1.printType === r2.printType &&
      r1.bestTime === r2.bestTime;
  }

  static copy(to: IRouterStep, from: IRouterStep) {
    to.id = from.id;
    to.routerId = from.routerId;
    to.step = from.step;
    to.description = from.description;
    to.printOption = from.printOption;
    to.setupTime = from.setupTime;
    to.avgTime = from.avgTime;
    to.familyId = from.familyId;
    to.runTime = from.runTime;
    to.idleTime = from.idleTime;
    to.doFgLot = from.doFgLot;
    to.deferTesting = from.deferTesting;
    to.workCenterId = from.workCenterId;
    to.workCenterName = from.workCenterName;
    to.printType = from.printType;
    to.bestTime = from.bestTime;
  }

  public static toServerInfo(s: IRouterStep): any {
    return {
      id:  s.id, //!== null ? s.id : "",
      routerId: s.routerId,
      step: s.step,
      description: s.description,
      printOption: PrintOptionUtils.toPrintOptionService(s.printOption),
      setupTime: s.setupTime,
      avgTime: s.avgTime,
      familyId: s.familyId,
      runTime: s.runTime,
      idleTime: s.idleTime,
      doFgLot: s.doFgLot,
      deferTesting: s.deferTesting,
      workCenterId: s.workCenterId,
      printType: PrintTypeUtils.toPrintTypeService(s.printType),
      bestTime: s.bestTime,
      userId: null
    };
  }

  public static toIRouterStep(info: any): IRouterStep {
    info.printOption = PrintOptionUtils.toPrintOption(info.printOption);
    info.printType = PrintTypeUtils.toPrintType(info.printType);
    return <IRouterStep> info;
  }
}

export class PrintOptionUtils {
  public static get options() {
    const ret: PrintOption[] = [];
    ret.push(PrintOption.AUTO);
    ret.push(PrintOption.OPTIONAL);
    return ret;
  }

  public static toPrintOption(type: PrintOptionService): PrintOption {
    switch (type) {
      case PrintOptionService.AUTO:
        return PrintOption.AUTO;
      case PrintOptionService.OPTIONAL:
        return PrintOption.OPTIONAL;
      default:
        return PrintOption.UNKNOWN;
    }
  }

  public static toPrintOptionService(type: PrintOption): PrintOptionService {
    switch (type) {
      case PrintOption.AUTO:
        return PrintOptionService.AUTO;
      case PrintOption.OPTIONAL:
        return PrintOptionService.OPTIONAL;
      default:
        return PrintOptionService.UNKNOWN;
    }
  }
}

enum PrintTypeService {
  UNKNOWN = 0,
  S1 = 1,
  M1 = 2
}

export enum PrintType {
  UNKNOWN = 'unknown',
  S1 = 's1',
  M1 = 'm1'
}

export class PrintTypeUtils {
  public static get types() {
    const ret: PrintType[] = [];
    ret.push(PrintType.M1);
    ret.push(PrintType.S1);
    return ret;
  }

  public static toPrintType(type: PrintTypeService): PrintType {
    switch (type) {
      case PrintTypeService.S1:
        return PrintType.S1;
      case PrintTypeService.M1:
        return PrintType.M1;
      default:
        return PrintType.UNKNOWN;
    }
  }

  public static toPrintTypeService(type: PrintType): PrintTypeService {
    switch (type) {
      case PrintType.S1:
        return PrintTypeService.S1;
      case PrintType.M1:
        return PrintTypeService.M1;
      default:
        return PrintTypeService.UNKNOWN;
    }
  }
}
