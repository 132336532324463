import { Injectable, EventEmitter } from '@angular/core';
import { IUserMaintenance, IAuthorizedWorkStation } from '../models/usermaintenance.model';
import { IWorkStation } from '../models/workstation.model';
import { WorkStationServiceHttp } from './workstation.servicehttp';

@Injectable()
export class UserMaintenanceDataService {
  selectedUser: IUserMaintenance = null;
  selectedWorkStation: IAuthorizedWorkStation = null;

  warehouses: string[] = ['MAIN', 'MFGSCT', 'MPWTX', 'MPSTX' ];
  workStations: IWorkStation[];

  get newUserTemplate(): IUserMaintenance  {
    return{
        userId: null,
        manufacturingUserId: null,
        userName: null,
        firstName: null,
        lastName: null,
        middleInitial: null,
        employeeID: null,
        empNum: null,
        email: null,
        defaultWarehouse: null,
        defaultWarehouse1: null,
        defaultWorkStation: null,
        workstationDescription: null,
        active: false,
        userEnabled: false,
    };
  }
  get newAuthorizedWorkStationTemplate(): IAuthorizedWorkStation  {
    return {
        workStationId: null,
        workCenterId: null,
        serialNumber: null,
        workStationNumber: null,
        description: null,
        type: null,
        createDate: null,
        active: false,
        workCenterWarehouse: null,
        workCenterType: null,
        workCenterDescription: null,
        workCenterScheduleType: null,
        workCenterIsOutSource: null,
        printerId: null,
        printerSerialNumber: null,
        printerIPAddress: null,
        printerOEMSerialNumber: null,
        workCenterCreateDate: null,       
    };
  }

 /*  getAvailableWorkStations() {
    this.wsService.getWorkStations().subscribe( stations => {
    this.workStations  = stations;
  });
 } */

  constructor(private wsService: WorkStationServiceHttp) {
    this.wsService.getWorkStations().subscribe ( stations => {
        this.workStations = stations;
      });
  }

}
