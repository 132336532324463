import { Component, OnInit, ViewChild, ElementRef, AfterContentChecked, OnDestroy, AfterViewInit, AfterViewChecked } from '@angular/core';
import { IToolBag, ITool, IWorkCenterToolKit, IToolBagTool, ToolUtils, IToolKit, WorkCenterToolKit } from '../../../core/models/tool.model';
import { Helper } from '../../../core/services/helper.service';
import { ToolServiceHttp } from '../../../core/services/tool.servicehttp';
import { WorkCenterService } from '../../../core/services/workcenter.service';
import { IWorkCenter } from '../../../core/models/workcenter.model';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, Validator } from '@angular/forms';
import { SpecToolComponent } from '../../../step-detail/step-spec/spec-tool/spec-tool.component';
import { AlertService } from '../../../core/services/alert.service';
import { AlertLevel, AlertReponse } from '../../../core/models/alert.model';
import { WorkCenterToolKitDomainService } from '../../../core/services/workCenterToolKit-domain.service';
import { SortCriteria} from '../../../core/models/sort.model';

@Component({
  selector: 'tool-kit-detail',
  template: require('./tool-kit-detail.component.html'),
  // styles: [require('./tool-kit-detail.component.css')]
  styles: [require('./tool-kit-detail.component.css').toString()]
})
export class ToolKitDetailComponent implements OnInit, OnDestroy, AfterContentChecked, AfterViewInit, AfterViewChecked {

  workCenterToolKitFormGroup: FormGroup;
  @ViewChild('table', {static: true}) table: ElementRef;
  @ViewChild('ToolBagInfo', {static: true}) stationToolInfo: SpecToolComponent;
  @ViewChild('selectWorkCenter', {static: false}) selectWorkCenter: ElementRef;
  selectedWorkCenter: IWorkCenter = null;
  selectedWorkCenterPlaceholder: string = null;
  
  selectWorkCenterPlaceholder = 'Select a work center';
  stations: IToolBag[] = [];
  tools: ITool[] = null;
  kitModal = false;
  createModal = false;
  overlayWasVisible = false;
  descriptionValidationError;
  workCenters: IWorkCenter[] = null;
  workCenterToolKitId: string = null;
  workCenterToolKit: IWorkCenterToolKit = null;
  defaultToolBag: IToolBag = {
                  id: null,
                  description: null,
                  tools: [],
                  notes: null
                };
  selectedToolBag: IToolBag = null;
  defaultWorkCenterToolKit: IWorkCenterToolKit = {
    id: null,
    description: null,
    workCenterId: null,
    workCenterDescription: null,
    workCenterWarehouse: null,
    toolBags: []
  };
  dir = 'asc';
  editMode: Boolean = false;
  set submittedToolBag(val: boolean) {
    if (this.stationToolInfo) {
      this.stationToolInfo.userSubmitted = val;
    }
  }

  constructor(private toolService: ToolServiceHttp, private utils: Helper, private workCenterService: WorkCenterService,
    private alert: AlertService,
    private route: ActivatedRoute,
  private domain: WorkCenterToolKitDomainService) { }

  ngOnInit() {
    console.log('tool-kit-detail.component.ngOnInit');
    this.workCenterToolKitId = this.route.snapshot.params['id'];

    //if (this.workCenterToolKitId)

    this.workCenterToolKitFormGroup = new FormGroup({
      workCenter: new FormControl ('', Validators.required),
      description: new FormControl('', Validators.compose([Validators.required, this.makeValidateUniqueDescription()]))
    });

    this.workCenterService.getWorkCenters().subscribe(centers => {
      this.workCenterToolKit = null;
      this.workCenters = centers;
      this.workCenters.sort(this.sortWarehouseDescription);

      if (this.workCenterToolKitId != null) {
        this.toolService.getWorkCenterToolKit(this.workCenterToolKitId).subscribe( toolKit => {
          this.workCenterToolKit = toolKit;
          this.toolService.getWorkCenterToolBags(this.workCenterToolKitId).subscribe(bags => {
            console.log(bags);
            this.toolService.getWorkCenterToolKitTools(this.workCenterToolKitId).subscribe(toolKitTools => {
              this.stations = this.utils.addToolsToToolBags(bags, toolKitTools);
              this.stations.sort(Helper.sort_description);

              console.log(this.stations);
              this.workCenterService.getWorkCenter(this.workCenterToolKit.workCenterId).subscribe(center => {
                this.patchFormGroup(this.workCenterToolKit, center);
              });
            });
          });
        });

      } else {
        this.patchFormGroup(this.defaultWorkCenterToolKit, null);
      }
    });

  }

  getToolName(t: ITool): string {
    return this.utils.getToolName(t);
   }

  makeValidateUniqueDescription() {
    const _self = this;
    return function(fc: FormControl) {
      console.log('tool-kit-detail.component.validateUniqueKitDescription');
      const ret = _self.workCenterToolKitFormGroup && _self.isUniqueToolKit ? null : {
        validateUnique: {
          valid: false
        }
      };
      console.log('spec-tool.component ret ' + ret);
      return ret;
    };
  }


  ngAfterViewInit() {
    // console.log('File.Component.AfterViewInit');
    // runs every time the user re-sizes the window
        $(window).resize(function(){
      // gets td column widths
        const c1 = $('.col1').width();

        const th = c1-8;
        // sets th column widths to match td's
        $('#Cover').width(th + 7);
        $('#col1').width(c1+1);

        $('.SearchBar').width(th);
        console.log ('resize header occured');
        });
  }


  selectWorkCenterHandler() {
    /*
    this.workCenterToolKitFormGroup.patchValue({
      description: this.selectedWorkCenter.description + ' tool kit'
    });*/
  }


  fromFormValues(info ): IWorkCenterToolKit {
       return {
          id : this.utils.isExistingItem(this.workCenterToolKit) ? this.workCenterToolKit.id : null,
          description: (info.description ? info.description.trim() : ''),
          workCenterId : this.selectedWorkCenter ? this.selectedWorkCenter.id : null,
          workCenterWarehouse: this.selectedWorkCenter ? this.selectedWorkCenter.warehouse : null,
          workCenterDescription: this.selectedWorkCenter ? this.selectedWorkCenter.description : null,
          toolBags: []
        };
      }

      private patchFormGroup(info: IWorkCenterToolKit, workCenter: IWorkCenter) {
        console.log('tool-kit-detail.component.patchFormGroup....');
        console.log(info);
        const wc = this.utils.isExistingItem(workCenter) ? this.workCenters.find(x => x.id === workCenter.id) : null;

        this.selectedWorkCenter = wc;

        if (info) {
          this.workCenterToolKitFormGroup.patchValue({
            description: info.description
          });
        }
      }


     

  sortWarehouseDescription(a: IWorkCenter, b: IWorkCenter): number {
    let ret = Helper.cmpStringAlphaNumeric(a.warehouse, b.warehouse);
    if (ret === 0) {
      ret = Helper.cmpStringAlphaNumeric(a.description, b.description);
    }

    return ret;
  }
  sortNumber(a: ITool, b: ITool): number  {
    return Helper.cmpStringAlphaNumeric(a.number, b.number);
  }

  ngOnDestroy() {
    console.log('tool-kit-detail.component.ngOnDestroy');
  }

  get isOverlayVisible() {
    return false; // this.reasonCodeForm.nativeElement.offsetParent != null;
  }

  ngAfterContentChecked() {
    // console.log('tool-kit-detail.component.ngAfterContentChecked');
    if (!this.isOverlayVisible && this.overlayWasVisible) {
      console.log('tool-kit-detail.component.ngAfterContentChecked...visible to hidden ');
      this.overlayWasVisible = false;
      // this.resetFormValidation();
    } else if (this.isOverlayVisible && !this.overlayWasVisible) {
      console.log('tool-kit-detail.component.ngAfterContentChecked...hidden to visible');
      this.overlayWasVisible = true;
     // this.userSubmitted = false;
      // this.resetFormValidation();
    }
  }

  get hasChanges(): Boolean {
    console.log('tool-kit-detail.component.hasChanges');
    console.log(this.workCenterToolKit);
    console.log(this.newToolkit);
    const ret = this.validateDescription() && !WorkCenterToolKit.areEqual(this.workCenterToolKit, this.newToolkit);
    console.log(ret);
    return ret;
  }

  get newToolkit(): IWorkCenterToolKit {
    const tk = this.fromFormValues(this.workCenterToolKitFormGroup.getRawValue());
    return tk;
  }

    addToolBag() {
      console.log('tool-kit-detail.addToolBag');  
          
        if (this.workCenterToolKit == null) {
          this.editMode = false;
          const kit: IWorkCenterToolKit = this.fromFormValues(this.workCenterToolKitFormGroup.getRawValue());
          this.toolService.createWorkCenterToolKit(kit.workCenterId, kit.description).subscribe(wctk => {
            this.workCenterToolKit = wctk;
            this.selectedToolBag = this.copyToolBag(this.defaultToolBag);
            //this.
            this.createToggle();
          });
        } else {
          this.editMode = true;
          this.selectedToolBag = this.copyToolBag(this.defaultToolBag);
          this.createToggle();
        }
    }

    editToolBag(st: IToolBag) {
      this.editMode = true;
      this.selectedToolBag = st;
      this.createToggle();
    }

    removeToolBag(st: IToolBag) {
      this.alert.showYesNo('Are you sure you want to delete tool set for ' + st.description + '?',
      AlertLevel.WARNING,
      (ar: AlertReponse) => {
        console.log(ar);
        console.log(ar === AlertReponse.OK);
        console.log(AlertReponse.OK);
        if (ar === AlertReponse.YES) {
          this.toolService.removeWorkCenterToolKitToolBag(this.workCenterToolKit.id, st.id).subscribe( res => {
            this.stations = this.stations.filter(x => x.id !== st.id);
          });
        }
      });
    }


    copyTool(from: ITool): ITool {
      return {
        id: from.id,
        description: from.description,
        number: from.number,
        inUse: from.inUse,
        userDefined: from.userDefined ,
        order: from.order
      };
    }

    copyTools(tools: ITool[]): ITool[] {
      const ret: ITool[] = [];
      for (const t of tools) {
        ret.push(this.copyTool(t));
      }
      return ret;
    }

    copyToolBag(from: IToolBag): IToolBag {
      return {
        id: from.id,
        description: from.description,
        tools: this.copyTools(from.tools),
        notes: from.notes
      };
    }

  createToggle() {
    if (this.createModal === false) {
      this.createModal = true;
    } else {
      this.createModal = false;
    }
  }
  handleCreate(data) {
    this.createModal = data;
  }

  launchKitManage() {
    if (!this.workCenterToolKit) {
      const kit: IWorkCenterToolKit = this.fromFormValues(this.workCenterToolKitFormGroup.getRawValue());
      this.toolService.createWorkCenterToolKit(kit.workCenterId, kit.description).subscribe( wctk => {
        this.workCenterToolKit = wctk;
        this.stations = [];
        this.kitModal = true;
      });
    } else {
      this.kitModal = true;
    }
  }

  kitToggle() {
    if (this.kitModal === false) {
      this.kitModal = true;
    } else {
      this.kitModal = false;
    }
  }
  handleKit(data) {
    this.kitModal = false;
    this.stations = <IToolBag[]> data;
    this.stations.sort(Helper.sort_description);
  }

  handleKitCreate(data) {
    this.createModal = data;
  }


  // sortTable(n, event) {
  //   var elementId: string = (event.target as Element).id;
   
  
  //   if (elementId.substring(0,4) == 'col1' && n == 0)
  //   {    
  //     if (this.dir == 'asc')
  //     {
  //       this.dir = 'desc'
  //       this.stations.sort(this.sortByDescriptionAsc)
  //     }
  //     else 
  //     {
  //       this.dir = 'asc'
  //       this.stations.sort(this.sortByDescriptionDesc)
  //     }
      
  //   }   
  // } 

  onSorted(criteria: SortCriteria)
  {    
      var elementId: string = (event.target as Element).id;
      if(elementId.substring(0,3) == 'col')
      {
        if(criteria.sortDirection === 'desc'){          
          if(criteria.sortColumn == 'description')
          {
            this.stations.sort(this.sortByDescriptionDesc)
          } 
        }
        else {
          if(criteria.sortColumn == 'description')
          {
            this.stations.sort(this.sortByDescriptionAsc)
          }              
        }
      }
      
  }

  
  sortByDescriptionAsc(a1: IToolBag, a2: IToolBag) { 
    return Helper.cmpStringAlphaNumeric(a1.description, a2.description); 
  }
  
  sortByDescriptionDesc(a1: IToolBag, a2: IToolBag) {
    return Helper.cmpStringAlphaNumericDesc(a1.description, a2.description); 
  }

  validateDescription() {
    const valid = this.workCenterToolKitFormGroup.controls.description.valid;

    if (!valid) {
      if (this.workCenterToolKitFormGroup.controls.description.errors) {
        if (this.workCenterToolKitFormGroup.controls.description.errors.validateUnique) {
          this.descriptionValidationError = 'Must be Unique';
        } else if (this.workCenterToolKitFormGroup.controls.description.errors.required) {
          this.descriptionValidationError = 'Required';
        }
      }
    }

    return valid;

  }




sortByDescription(a: IToolBag, b: IToolBag) {
  return Helper.cmpStringAlphaNumeric(a.description, b.description);
}

get canSubmit(){
  return this.stationToolInfo != null &&
   this.stationToolInfo.bizl.canSubmit;
}


  ngAfterViewChecked() {
    // console.log('tools.component.AfterViewChecked');
     const c1 = $('.col1').width();
     const th = c1 - 8;

     // console.log(c1);
     $('#col1').width(c1 - 40);
     $('.SearchBar').width(th);
     $('#Cover').width(th + 7);

   }



      closeOverlay() {

      }

      onToolBagDone(toolBag: IToolBag) {
        console.log('tool-kit-detail.component.onToolBagDone');
        console.log(toolBag);
        const tb = this.stations.find(x => x.id === toolBag.id);
        console.log(tb);
        if (!tb) {
          this.toolService.addWorkCenterToolKitToolBag(this.workCenterToolKit.id, toolBag.id).subscribe( ret => {
            this.stations.push(toolBag);
            this.stations.sort(this.sortByDescription);
            this.createToggle();
          });
        } else {
          this.utils.replaceItemInList(this.stations, toolBag);
          this.stations.sort(this.sortByDescription);
          this.createToggle();
        }
      }

      public get canAddKitDescription(): boolean {
        return this.selectedWorkCenter != null;
      }

      public get canManageKit(): boolean {
        return this.canAddKitDescription && this.isUniqueToolKit;
      }

      public get canAddStations(): boolean {
        return this.selectedWorkCenter != null && this.isUniqueToolKit;
      }

      public get isUniqueToolKit(): boolean {
        const kit = this.fromFormValues(this.workCenterToolKitFormGroup.getRawValue());
        return !this.utils.isEmptyString(kit.description) &&
         !this.utils.listContainsSimilarDifferentItemEdit(this.domain.workCenterToolKits, kit, this.cmpDescription);

      }

      cmpDescription(a: IWorkCenterToolKit, b: IWorkCenterToolKit) {
        const descA = a.description ? a.description.trim().toLowerCase() : '';
        const descB = b.description ? b.description.trim().toLowerCase() : '';

        return Helper.cmpStringAlphaNumeric(descA, descB);
      }

      getTargetToolKit(): IToolKit {
        const tmp: WorkCenterToolKit = new WorkCenterToolKit(this.workCenterToolKit, this.toolService);
        return tmp;
      }

}
