export interface INDEType_RetrieveSpecs {
    Name: string;
    InspectionID: number;
    Specification: string;
    Directory: string;
    SpecificationFileID: string; //GUID       
}

export interface INDEType_RetrieveInspectionParametersByGroup {
    Name: string;
    Value: string 
}

export class NDEType
{
    nDETypeID: number;
    groupID: number;
    specification: string;
}

export class PartNDEMappingNew
{
    partID: string;
    inspectionID: number;
    groupID: number;
    specificationFileID: string;
}

export class NDEType_RetrieveAllPartNDEMappings
{
    ID: string;
    NDE: string;
    Spec: string;
    Group: string;
}

