import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { INDEType_RetrieveSpecs, INDEType_RetrieveInspectionParametersByGroup, NDEType, PartNDEMappingNew, NDEType_RetrieveAllPartNDEMappings } from '../../../core/models/nde.model';
import { PartServiceHttp } from '../../../core/services/part.servicehttp';
import { FormGroup, FormControl, AbstractControl, NgModel, Validators, NgControlStatus, ControlContainer, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { PDFService } from '../../../core/services/pdf-service';

@Component({
  selector: 'part-nde',
  template: require('./part-nde.component.html'),
  styles: [require('./part-nde.component.css')]
})

export class PartNdeComponent implements OnInit {
  inspectionParameters: INDEType_RetrieveInspectionParametersByGroup[] = [];
  partNDEMappings: NDEType_RetrieveAllPartNDEMappings[] = [];
  partNDEMappingNew: PartNDEMappingNew = new PartNDEMappingNew();
  specTypes: INDEType_RetrieveSpecs[] = [];
  groups: number[] = [];
  specificationFile: string;
  @ViewChild('btnViewSpecification', {static: true}) btnViewSpecification: ElementRef;

  //-- HTML Form Elements
  fgPartNDE: FormGroup;
  fcNDEType: FormControl;
  fcSpecification: FormControl;
  fcGroup: FormControl;

  constructor(private partService: PartServiceHttp, private route: ActivatedRoute, private pdfService: PDFService) { }

  ngOnInit() {
    //-- Set global partNDEMappingNew partID
    this.partNDEMappingNew.partID = this.route.snapshot.params['id'];

    //-- Form Control Validation
    //this.fcNDEType = new FormControl('', Validators.required);
    //this.fcSpecification = new FormControl('', Validators.required);
    //this.fcGroup = new FormControl('', Validators.compose([
    //  Validators.required,
    //]));

    this.fgPartNDE = new FormGroup({
      'fcnNDEType': new FormControl('', Validators.required),
      'fcnSpecification': new FormControl('', Validators.required),
      'fcnGroup': new FormControl('', Validators.compose([Validators.required]))
    });

    //-- Get existing Part NDE Mappings
    this.partService.GetPartNDEMappings(this.partNDEMappingNew.partID).subscribe(partNDEMappings => {
      this.partNDEMappings = partNDEMappings;
    });
  }

  get fcnNDEType() {
    return this.fgPartNDE.get("fcnNDEType");
  }

  get fcnSpecification() {
    return this.fgPartNDE.get("fcnSpecification");
  }

  get fcnGroup() {
    return this.fgPartNDE.get("fcnGroup");
  }

  handleNDETypeChange(event:any)
  {
    //-- reset form controls
    this.resetFormControls(true);

    //-- Set global partNDEMappingNew inspectionID
    this.partNDEMappingNew.inspectionID = Number(event.target.value);

    this.partService.getSpecsByNDEType(this.partNDEMappingNew.inspectionID).subscribe(specs => {
      this.specTypes = specs;
    });

    this.fgPartNDE.markAsUntouched();
    this.fgPartNDE.get("fcnSpecification").markAsUntouched()
    this.fgPartNDE.get("fcnGroup").markAsUntouched();
  }

  handleSpecificationChange(event:any)
  {
    let nDETypeParamsG = new NDEType();
    let specificationFileID: string;

    nDETypeParamsG.nDETypeID = this.partNDEMappingNew.inspectionID;
    nDETypeParamsG.specification = this.fcnSpecification.value; //event.target.value;

    //-- Set global partNDEMappingNew specificationFileID
    specificationFileID = this.GetSelectedSpecificationFileID(nDETypeParamsG.specification, this.specTypes);
    this.partNDEMappingNew.specificationFileID = specificationFileID;

    console.log('part-nde.component.handleSpecificationChange.partNDEMappingNew.specificationFileID: ' + this.partNDEMappingNew.specificationFileID);

    this.partService.GetGroupsBySpec(nDETypeParamsG).subscribe(groups =>{
      this.groups = groups;
    });

    if (this.fcnSpecification.value == "")
    {
      //-- don't display 
      this.btnViewSpecification.nativeElement.style.display = "none";
      this.fgPartNDE.get("fcnSpecification").markAsUntouched();

      //-- Reset Group
      //-- reset global partNDEMappingNew object
      this.partNDEMappingNew.specificationFileID = null;
      this.partNDEMappingNew.groupID = null;
      //-- reset form control data
      this.groups = [];
      this.inspectionParameters = [];
      //-- reset Group form control
      this.fcnGroup.setValue("");
      this.fgPartNDE.get("fcnGroup").markAsUntouched();
    }
    else 
    {
      this.btnViewSpecification.nativeElement.style.display = "inline";
      this.fgPartNDE.get("fcnSpecification").markAsTouched();
    }
  }

  handleGroupChange(event:any)
  {
    //console.log('part-nde.component.handleGroupChange');

    let nDETypeParamsIP = new NDEType();
    
    nDETypeParamsIP.nDETypeID = this.partNDEMappingNew.inspectionID;
    nDETypeParamsIP.groupID = Number(this.fcnGroup.value); //Number(event.target.value);

    //-- Set global partNDEMappingNew groupID
    this.partNDEMappingNew.groupID = nDETypeParamsIP.groupID;

    this.partService.GetInspectionParametersByGroup(nDETypeParamsIP).subscribe(inspectionParameters =>{
      this.inspectionParameters = inspectionParameters;
    });

    if (this.fcnGroup.value == "")
    {     
      this.fgPartNDE.get("fcnGroup").markAsUntouched();
    }    
    else
    {
      this.fgPartNDE.get("fcnGroup").markAsTouched();
    }
  }

  handleSave(event:any): void
  {
    this.partService.CreatePartNDEMapping(this.partNDEMappingNew).subscribe(ret =>{
      const result = ret;
    });

    //-- reset form controls
    this.resetFormControls();

    //-- Refresh PartNDEMappings
    this.refreshPartNDEMappings();
  }

  handleDelete(partNDEMappingID:string): void
  {
    this.partService.RemovePartNDEMapping(partNDEMappingID).subscribe(ret =>{
      const result = ret;
    });

    //-- reset form controls
    this.resetFormControls();

    //-- Refresh PartNDEMappings
    this.refreshPartNDEMappings();
  }

  handleViewSpecification(): void
  {
    if (this.specificationFile)
    {
      this.pdfService.show(this.specificationFile);
    }
  }

  GetSelectedSpecificationFileID(specification: string, specs: INDEType_RetrieveSpecs[]): string
  {
    let returnSpecificationFileID: string;
    for (const spec of specs){ 
      if (spec.Specification == specification)
      {
        //-- set Specification file and directory
        this.specificationFile = spec.Directory;

        //-- set SpecificationFileID
        returnSpecificationFileID = spec.SpecificationFileID;
        break;
      }
    }
    return returnSpecificationFileID;
  }

  resetFormControls(ndeType?: boolean):void
  {
    //-- clear partNDEMappingNew
    this.partNDEMappingNew.inspectionID = null;
    this.partNDEMappingNew.groupID = null;
    this.partNDEMappingNew.specificationFileID = null;
    this.btnViewSpecification.nativeElement.style.display = "none";

    //-- clear form control data 
    this.inspectionParameters = [];
    this.specTypes = [];
    this.groups = [];

    //-- Clear/reset form controls
    if (!ndeType)
    {
      this.fcnNDEType.setValue("");
    }
    this.fcnSpecification.setValue("");
    this.fcnGroup.setValue("");
  }

  refreshPartNDEMappings(): void
  {
    setTimeout(()=>{
      this.getPartNDEMappings()  
    },2000);
  }

  getPartNDEMappings(): void
  {
    this.partNDEMappings = [];
    this.partService.GetPartNDEMappings(this.partNDEMappingNew.partID).subscribe(partNDEMappings => {
      this.partNDEMappings = partNDEMappings;
    });
  }
}
