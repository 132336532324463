import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[over]'
})
export class OverDirective {
    constructor(private elementRef: ElementRef) {
    }

    @HostListener('mouseover', ['$event'])
    onOver(event: MouseEvent): void {
        console.log(this.elementRef.nativeElement.offsetHeight)
    }
}