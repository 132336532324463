import { Error } from 'tslint/lib/error';
import { Injectable } from '@angular/core';
import { Alert, AlertType, AlertLevel, AlertReponse } from '../models/alert.model';
import {Helper} from './helper.service';

@Injectable()

export class AlertService {

    constructor(private helper: Helper) { }

    currentAlert: Alert = null;

    show(msg: string, level: AlertLevel = AlertLevel.INFO) {
     this.currentAlert = new Alert(AlertType.NORESPONSE, msg, level);
    }

    hide() {
      this.currentAlert = null;
    }

    showOk(msg: string, level: AlertLevel = AlertLevel.INFO, callback: Function = (rsp) => {}) {
      this.currentAlert = new Alert(AlertType.OK, msg, level, callback);
    }

    showYesNo(msg: string, level: AlertLevel = AlertLevel.INFO, callback: Function) {
      this.currentAlert = new Alert(AlertType.YESNO, msg, level, callback);
    }

    reShowAlert() {
      const tmp = this.currentAlert;
      this.currentAlert = null;
      this.currentAlert = tmp;
    }

    handleAlertResponse(a: Alert, res: AlertReponse) {
      if (a.callback != null) {
        this.currentAlert = null;
        a.callback(res);
      } else {
        throw new Error('unexpected null callback');
      }
    }
  }

