
import { Message } from '../enums/message';

export class ValidationResponse1 {

 get actionable() { return this.show && !this.disabled; }

 constructor( public show: boolean, public disabled: boolean, public err: string, public valid:Boolean = true) {}

 public dump(prefix:string){
     console.log(prefix + " sho:" + this.show + " dis:" + this.disabled + " val:" + this.valid + " err: " + this.err );
 }

}

