import { Helper } from '../services/helper.service';
export interface IProgram {
    id: string;
    number: string;
    description: string;
    inUse: boolean;
  }

export interface IRouterStepProgram {
  program: IProgram;
  stepId: string;
}


export class ProgramUtils {
  public static areEqual( p1: IProgram, p2: IProgram) {
    if (p1 == null) {
      return p2 == null;
    }

    if (p2 ==  null) {
      return false;
    }

    return  Helper.areEqualStr(p1.id, p2.id) &&
      Helper.areEqualStr(p1.description, p2.description) &&
      Helper.areEqualStr(p1.number, p2.number);
  }
}
