import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ComplexOuterSubscriber } from 'rxjs/internal/innerSubscribe';
import { FilesComponent } from '../../files/files.component';
import { Message, MessageType } from '../enums/message';
import { FileType, IFileExtended1 } from '../models/file.model';
import { Helper } from '../services/helper.service';
import { PartServiceHttp } from '../services/part.servicehttp';
import { ValidationResponse1 } from '../shared/validation-response1';


@Injectable({
  providedIn: 'root'
})
export class FileUpdateValidationService {

  constructor(private formGroup: FormGroup, private fnSourceType: () => string,file: IFileExtended1 ) { 
      //console.log("FileUpdateValidationService")
    this.defaultDescription = file.description;
    this.defaultEngManCategory = file.engManCategory;
    this.reset();
  }

  get title():string {

    var ret = "New File Version Details";
    if (this.nextClicked)
    {
        ret = "Associated Routers";
    }
    return ret;
  }

  get canAutoSetFileName():boolean{ return false; }

  get canAutoSetDescription():boolean { return false; }

  private defaultDescription: string = "";
  private defaultEngManCategory: string = "";


  reset()
  {
      //console.log("FileUpdateValidationService.resetForm");
      this.formGroup.controls.type.enable();
      this.formGroup.controls.onShapeURL.disable();
      if ( this.formGroup.controls.onShapeURL.value != "")
        this.formGroup.controls.onShapeURL.setValue("");
      this.formGroup.controls.fileUpload.disable();
      this.formGroup.controls.fileUpload.setValue(null);
      this.formGroup.controls.specificationName.disable();
      this.formGroup.controls.engManCategory.disable();
      this.formGroup.controls.engManCategory.setValue(this.defaultEngManCategory);
      this.formGroup.controls.revision.disable();
      this.formGroup.controls.description.disable();
      this.formGroup.controls.description.setValue(this.defaultDescription);
      this.formGroup.controls.updateEngineeringManualVersion.disable();
  }

    get typeValidation():ValidationResponse1 {
        const show = true; 
        const disabled = true;
        var err:string = null;
        const cnt = this.formGroup.controls.type;
        const valid = true; 
        const ret = new ValidationResponse1(show, disabled, err, valid);
        //console.log("FileUpdateValidationService.typeValidation");
        //console.log(ret);  
        return ret 
    }

    get specificationNameValidation():ValidationResponse1 {
        const show = true;
        const disabled = true;
        const cnt = this.formGroup.controls.specificationName;
        var err:string = null;
        const valid = true;
        const ret = new ValidationResponse1(show, disabled, err, valid);
        //console.log("FileUpdateValidationService.specificationNameValidation");
        //console.log(ret);
        return ret 
    }


    get engManCategoryValidation():ValidationResponse1{
        const show =  this.fileType == FileType.SPEC;
        const disabled = true;
        var err:string = null;
        const cnt = this.formGroup.controls.engManCategory;
    
        if (cnt.errors != null && cnt.errors != undefined && cnt.errors.required)
            err = "Required";
        
        const valid = disabled || cnt.valid;
    
        const ret = new ValidationResponse1(show, disabled, err, valid);
       // ret.dump("FileUpdateValidationService.engManCategoryValidation");
        return ret 
    }


    get revisionValidation():ValidationResponse1 {
        const show = true;
        const disabled = true;
        var err = null;
        const cnt = this.formGroup.controls.revision;
        
        if (cnt.errors != null && cnt.errors != undefined)
            {
               if (cnt.errors.specVersionExistsInEngineeringManual)
                err = "File name + revision not in IC Eng Manual";
            }  
        const valid = cnt.valid;
        const ret = new ValidationResponse1(show, disabled, err, valid);
            //console.log("FileUpdateValidationService.revisionValidation");
            //console.log(ret);   
        
        return ret 
    }




    get descriptionValidation():ValidationResponse1 {
        const show = true;
        const disabled = !this.fileSourceValid;
        var err:string = null;
        const cnt = this.formGroup.controls.description;

        if (cnt.errors != null && cnt.errors != undefined)
        {
            if (cnt.errors.required)
                err = "Required";
        }  
        const valid = disabled || 
            this.formGroup.controls.description.valid;
            const ret = new ValidationResponse1(show, disabled, err, valid);
            //console.log("FileUpdateValidationService.descriptionValidation");
            //console.log(ret);  
        return ret 
    }

    get onShapeURLValidation():ValidationResponse1 {
        const show = this.fnSourceType() == "onshape";
        const disabled = !show;
        var err:string = null;
        var cnt = this.formGroup.controls.onShapeURL;
    
        if (cnt.errors != null && cnt.errors != undefined)
        {
            if (cnt.errors?.required)
                err = "Required";
            else if (cnt.errors?.pattern)
                err = "onShape URL must start with https://diwmsi.onshape.com/";
        }  
    
        const valid = disabled || cnt.valid;
        const ret = new ValidationResponse1(show, disabled, err, valid);
       //console.log("FileUpdateValidationService.onShapeURLValidation");
       //console.log(ret);  
        return ret 
    }   

    get fileSourceValid():Boolean{
        return this.formGroup.controls.fileUpload.valid ||
          this.formGroup.controls.onShapeURL.valid;
    }
  
    get sourceTypeValidation():ValidationResponse1{
        const show = this.fileType == FileType.DRAWING || this.fileType == FileType.DESIGN ;
        const disabled = !show;
        var err:string = null;
        const valid = true;
    
        const ret = new ValidationResponse1(show, disabled, err, valid);
        //console.log("FileAddValidationService.specificationNameValidation");
        //console.log(ret);   
    
        return ret 
    }

    get sourceValidation():ValidationResponse1{
        const show = true;
        const disabled = false;
        var err:string = null;
        const valid = true;
    
        const ret = new ValidationResponse1(show, disabled, err, valid);
        //console.log("FileAddValidationService.specificationNameValidation");
        //console.log(ret);   
    
        return ret 
    }

    get fileUploadValidation():ValidationResponse1 {
        const show = this.fnSourceType() == "file";
        const disabled = !show;
        var err:string = null;
        var cnt = this.formGroup.controls.fileUpload;

        if (cnt.errors != null && cnt.errors != undefined){
                if (cnt.errors.required)
                    err = "Required";
        }  
        
        const valid = disabled || cnt.valid;
        const ret = new ValidationResponse1(show, disabled, err, valid);
        //console.log("FileUpdateValidationService.fileUploadValidation");
        //console.log(ret);  
    
        return ret 
    }

    get fileType():FileType {
        var ret = FileType.UNKNOWN;
        
        if (!Helper.isUndefinedObj(this.formGroup) &&
            !Helper.isUndefinedObj(this.formGroup.controls.type.value)) 
            {
              ret = this.formGroup.controls.type.value;
            }
            return ret;
      }

    get canDoNext():ValidationResponse1 {
        
        const show =  this.formGroup.valid && (this.fileType != FileType.DESIGN) && !this.nextClicked;
        const disabled = !show;
        var err:string = null;
        const valid = true;
        
        const ret = new ValidationResponse1(show, disabled, err, valid);
        //console.log("FileUpdateValidationService.canDoNext");
        //console.log(ret);  
        return ret; 
    }

    private _nextClicked:boolean = false;

    get nextClicked():boolean {return this._nextClicked;}
    set nextClicked(val:boolean) {this._nextClicked = val;}

    get canSubmit():ValidationResponse1 {
        const show = (this.formGroup.valid && this.fileType == FileType.DESIGN) || this.nextClicked;
        const disabled = !show;
        var err:string = null;
        const valid = true;
        
        const ret = new ValidationResponse1(show, disabled, err, valid);
        //console.log("FileUpdateValidationService.canSubmit nextClicked:" + this.nextClicked);
        //console.log(this.formGroup.controls.fileUpload);
        //console.log(this.formGroup.controls.onShapeURL);
        return ret; 
    }

  get updateEngManualValidation(): ValidationResponse1 {
      const type = (this.formGroup.controls != null &&
                    this.formGroup.controls != undefined &&
                    this.formGroup.controls.type != null &&
                    this.formGroup.controls.type != undefined)
                    ? this.formGroup.controls.type.value
                    : null;

    
      const show = (type == FileType.SPEC);
      const disabled = !this.formGroup.controls.fileUpload.valid;
      const msg = null;   
      const valid = true;
      const ret = new ValidationResponse1(show, disabled, msg, valid);
     //console.log("FileUpdateValidationService.updateEngManualValidation");
     //console.log(ret);
      return ret 
  }

  update()
  {
   //console.log("FileUpdateValidation.update");
    if (this.typeValidation.disabled)
        this.formGroup.controls.type.disable();
    else
        this.formGroup.controls.type.enable();

    if (this.onShapeURLValidation.disabled) {
        //console.log("...update.Disable onShapeURL");
        if (this.formGroup.controls.onShapeURL.value != "")  
            this.formGroup.controls.onShapeURL.setValue("");          
        this.formGroup.controls.onShapeURL.disable();
    } else {
        this.formGroup.controls.onShapeURL.enable();
    }        

    if (this.fileUploadValidation.disabled) {
        this.formGroup.controls.fileUpload.setValue(null);
        this.formGroup.controls.fileUpload.disable();
    } else {
        this.formGroup.controls.fileUpload.enable(); 
    }      

    if (this.specificationNameValidation.disabled)
        this.formGroup.controls.specificationName.disable();
    else
        this.formGroup.controls.specificationName.enable(); 

    if (this.engManCategoryValidation.disabled)
        this.formGroup.controls.engManCategory.disable();
    else
        this.formGroup.controls.engManCategory.enable(); 

    if (this.revisionValidation.disabled)
        this.formGroup.controls.revision.disable();
    else
        this.formGroup.controls.revision.enable(); 

    if (this.descriptionValidation.disabled)
        this.formGroup.controls.description.disable();
    else
        this.formGroup.controls.description.enable(); 

    if (this.updateEngManualValidation.disabled)
        this.formGroup.controls.updateEngineeringManualVersion.disable();
    else
        this.formGroup.controls.updateEngineeringManualVersion.enable();     

        this.formGroup.controls.holdState.disable();

    //this.formGroup.controls.type.updateValueAndValidity();
        
  }


}
