// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*Globals for Form Group*/\r\n\r\n\r\n/***************************************************/\r\n\r\n.FormGroupContainer {\r\n    padding: 25px 20px;\r\n}\r\n\r\n.FieldName {\r\n    color: #616161;\r\n    font-weight: 700;\r\n    font-size: 1.2em;\r\n}\r\n\r\n.InputField {\r\n    color: #616161;\r\n    border: 1px solid #e0e0e0;\r\n    height: 28px;\r\n    margin-bottom: 30px;\r\n    border-radius: 4px;\r\n    font-weight: 700;\r\n    font-size: .8em;\r\n    overflow: hidden;\r\n}\r\n\r\n\r\n/***************************************************/\r\n\r\n\r\n/*Custom Input Field Changes*/\r\n\r\n.LabelContainer {\r\n    float: left;\r\n    width: 120px;\r\n}\r\n\r\n.header {\r\n    float: left;\r\n}\r\n\r\noption {\r\n    padding: 30px;\r\n}\r\n\r\nem {\r\n    \r\n    color: #E05C65;\r\n    padding-left: 10px;\r\n}\r\n\r\n.error {\r\n    background-color: #E3C3C5;\r\n}\r\n\r\n.error ::-webkit-input-placeholder {\r\n    color: #999;\r\n}\r\n\r\n\r\n/*\r\n#PartNumber {\r\n    width: auto;\r\n} */\r\n\r\n#Revision {\r\n    width: 40px;\r\n    text-align: center;\r\n}\r\n\r\n#TargetWIP {\r\n    width: 40px;\r\n    text-align: center;\r\n}\r\n\r\n#PSLevel {\r\n    width: auto;\r\n    text-align: center;\r\n}\r\n\r\n#RouterType {\r\n    width: 120px;\r\n}\r\n\r\n\r\n/* virtual-scroll {\r\n    display: block;\r\n    width: 700px;\r\n    height: 200px;\r\n}\r\n\r\nlist-item {\r\n    display: block;\r\n    width: 100%;\r\n    height: 30px;\r\n} */\r\n\r\n.InputField {\r\n    width: 700px;\r\n    height: 30px;\r\n}\r\n\r\n#PartNumber {\r\n    display: block;\r\n    width: 100%;\r\n    height: 30px;\r\n}\r\n\r\n#selectPart {\r\n    width: auto;\r\n}\r\n\r\n.OptionField {\r\n    display: block;\r\n    width: 100%;\r\n    height: 30px;\r\n}\r\n\r\n#listConainer {\r\n    display: block;\r\n    width: 300px;\r\n    height: 30px;\r\n}\r\n\r\n\r\n/***************************************************/", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();