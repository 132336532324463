import { main } from '@angular/compiler-cli/src/main';
import { IReasonCode, ReasonCodeUtils } from '../core/models/reasoncode.model';
import { Helper } from '../core/services/helper.service';
import { ReasonCodeType } from '../core/models/reasoncode.model';



export class ReasonCodeComponentBizLogic {
  private utils: Helper = new Helper();

  constructor(private reasonCodeProvider: any) {
    // console.log('StepInfoComponentBizLogic');
    // console.log(router);
  }

  get reasonCode(){
    return this.reasonCodeProvider.getNewReasonCode();
  }

   get newReasonCodeDefaultInfo(): IReasonCode {
    return {
      id: '',
      description: '',
      code: '',
      type: ReasonCodeType.ADJUST_PARTINSTANCE_SIZE,
      inUse: false
    };
  }

  get isReasonCodeEditable() {
    return this.isNewReasonCode || !this.reasonCodeProvider.selectedReasonCode.inUse;
  }

get isNewReasonCode() {
   return !this.utils.isExistingItem(this.reasonCode);
}

get canCreate() {
  return this.isNewReasonCode;
}


canEdit(rc: IReasonCode) {
  return !rc.inUse;
}

canDelete(rc: IReasonCode) {
  return !rc.inUse;
}
get canUpdate(){

  return  !this.isNewReasonCode && this.reasonCodeProvider.validateNewReasonCodeInfo &&
   !ReasonCodeUtils.areEqual(this.reasonCodeProvider.selectedReasonCode, this.reasonCode);
}

get canSubmit(){
  return this.isNewReasonCode || this.canUpdate;
}


  public get isDescriptionEditable(): boolean {
    return true;
  }

  isUniqueTypeCode(editMode:Boolean) {
    let isUnique:Boolean = null; 
    if (editMode)
    {      
      isUnique =  !this.utils.listContainsSimilarDifferentItem(this.reasonCodeProvider.reasonCodes, this.reasonCode, this.compByTypeCode);
    }
    else
    {
      isUnique = !this.utils.listContainsItem(this.reasonCodeProvider.reasonCodes, this.reasonCode, this.compByTypeCode);
    }
    return isUnique;
    
  }


  compByTypeCode(a1: IReasonCode, a2: IReasonCode) {
    if (a1.type < a2.type) {
      return -1;
    } else if (a1.type > a2.type) {
      return 1;
    } else {
      return Helper.cmpStringAlphaNumeric(a1.code, a2.code);
    }
  }

}
