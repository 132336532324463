import { Helper } from '../../core/services/helper.service';
import { IUserMaintenance, IAuthorizedWorkStation } from '../../core/models/usermaintenance.model';
import { isNumber } from 'util';


export class UserDetailComponentBizLogic {
  private utils: Helper = new Helper();

  constructor(private detailsProvider: any) {}

  get isEnabled() {
    return true;
  }

  get canEditUser() {
    return true;
  }

  get canUpdateUser() {
    // place logic or permission check.
    return true;
  }

  get workStation(){
    return this.detailsProvider.workStationInfo;
  }

  get workStations(){
    return this.detailsProvider.workStations;
  }

  get originalWorkCenter(){
    return this.detailsProvider.selectedWorkCenter;
  }

  get originalWorkStation(){
    return this.detailsProvider.selectedWorkStation;
  }
  get isWorkStationValid(){
    return this.detailsProvider.isWorkStationInfoValid;
  }

  get isNewWorkStation() {
    return !this.utils.isExistingItem(this.workStation);
  }

  get workCenter(){
    return this.detailsProvider.workCenterInfo;
  }

  get isWorkCenterValid(){
    return this.detailsProvider.isWorkCenterInfoValid;
  }

  get isWorkCenterEditable() {
    return !this.workCenter.active;
  }

  get isWorkStationEditable() {
    return this.isWorkCenterEditable && !this.workStation.active;
  }

  get canSubmitWorkStation(){
    return this.isNewWorkStation ||
    this.canUpdateWorkStation;
  }

  get canAddWorkStation() {
    return this.isWorkCenterValid && this.isWorkCenterEditable;
  }

  get canDeleteWorkStation() {
    return this.isWorkCenterEditable && this.isWorkStationEditable;
  }

  get canCreateWorkStation() {
    return this.isNewWorkStation && this.isWorkStationValid;
  }

  get canUpdateWorkStation() {
    return this.isWorkStationEditable && this.isWorkStationValid;
  }

  get isUniqueNumber(){
    return !this.utils.listContainsSimilarDifferentItem(this.workStations, this.workStation, this.cmpNumber);
  }

  cmpNumber(a: IAuthorizedWorkStation, b: IAuthorizedWorkStation) {
    return Helper.cmpStringAlphaNumeric(a.workStationNumber, b.workStationNumber);
  }

}
