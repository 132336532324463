
import {Observable } from 'rxjs';
import { RouterStepServiceHttp } from '../core/services/routerstep.servicehttp';
import { IRouter } from '../core/models/router.model';
import { RouterDomainService } from '../core/services/router-domain.service';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { StepDetailComponent } from './step-detail.component';
import { StepInfoComponent } from './step-info/step-info.component';
import { of as observableOf} from 'rxjs/observable/of'

@Injectable()
export class CanDeactivateStepInfo implements CanDeactivate<StepDetailComponent> {
 constructor(private stepService: RouterStepServiceHttp,
 private domain: RouterDomainService) {}

 canDeactivate(
   component: StepDetailComponent,
   currentRoute: ActivatedRouteSnapshot,
   currentState: RouterStateSnapshot,
   nextState: RouterStateSnapshot
 ): Observable<boolean> {
  console.log('step-detail.candeactivateStep.canDeactivate');
   if (component && component.hasStepChanges) {
     console.log('step-info.candeactivate step ' + component.newStep.description);
      return this.stepService.updateRouterStep1(component.newStep);
   } else {
     return observableOf(true);
   }
 }
}
