import { Injectable } from '@angular/core';
import "rxjs/add/observable/of";
import { Helper } from './helper.service';
import 'rxjs/add/observable/throw';
import { IMyCache } from '../../imycache';
import { IMyCacheItem } from '../../imycacheitem';
import { TDiff } from './file.cache.service';

@Injectable()
export class MemoryCacheService implements IMyCache {
  private td: TDiff = new TDiff();
  private _cache:IMyCache = null;
  private _data:any[] = [];
  
  lastUpdate: number = new Date(2010,1,1).getTime();

  constructor(
    private utils: Helper,
    private secondaryCache: IMyCache
    ) {

  }

  private getIds(data: IMyCacheItem[]): string[] {
    const ret = [];
    if (!this.utils.isEmptyArray(data)) {
      for (const f of data) {
        ret.push(f.id);
      }
    }
    return ret;
  }

 // removed deleted data; replaced updated data; add new data
  update(data: IMyCacheItem[], lastUpdateTime: number): any[] {
    console.log("MemoryCacheService.update " +
    "updates.len:" + this.utils.getArrayLengthStr(data));
    var ret = this.getAll() || [];
    console.log("MemoryCacheService.update " +
      " cache.len:" + this.utils.getArrayLengthStr(ret));

    //console.log(data);

    //any updates to process?
    if (!this.utils.isEmptyArray(data)) {
      const ids = this.getIds(data);
      //remove all deleted and updated from the cache
      ret = ret.filter(x => !ids.includes(x.id)) || [];
      //add updated and new back to the cache
      const activeFiles = data.filter(x=>x.active == true) || [];
      console.log("MemoryCacheService.update active.len:" +
        this.utils.getArrayLengthStr(activeFiles));

      ret = ret.concat(activeFiles);
      this.load(ret, lastUpdateTime);
    }
    console.log("MemoryCacheService update ret.length:" + ret.length);
    return ret;
  }

  clear()
  {
    console.log("memory.cache.service clear:");
    this.lastUpdate = new Date(2010,1,1).getTime();
    this._data = [];
  }

  getAll():any[] {   
    console.log("memory.cache.service.getAll");
    var ret = [];
    //can this cache satisfy the request?
    if (!Helper.sIsEmptyArray(this._data)){
      ret = this._data;
    }
    else { //let's try to load from the secondary cache
      this.clear();
      if (!this.utils.isUndefined(this.secondaryCache))
      {
        var tmp = this.secondaryCache.getAll();
        if (!this.utils.isEmptyArray(tmp)){
          this.lastUpdate = this.secondaryCache.lastUpdate;
          ret = tmp;
        }
      }
    }
    console.log("memory.cache.service.getAll ret:" + ret.length);
    return ret;
  }

  load(data: any[], lastUpdate:number): void {
    console.log("memory.cache.service.load data.len:" + data.length);
    this._data = data;
    this.lastUpdate = lastUpdate
   
    if (!this.utils.isUndefined(this.secondaryCache))
        this.secondaryCache.load(data, lastUpdate);
  }


  get(id: string) {
    throw new Error('Method not implemented.');
  }
  set(data: any) {
    throw new Error('Method not implemented.');
  }
  flush(): void {
    throw new Error('Method not implemented.');
  }
}


