import { AuthService } from '../core/services/auth.service';
import { LoginService } from '../core/services/login.service';
import { UserMaintenanceServiceHttp} from '../core/services/usermaintenance.servicehttp';
import { Component, OnInit, EventEmitter, Output, AfterViewInit, AfterViewChecked, ViewChild, ElementRef, NgModule, Input } from '@angular/core';
import { ActivatedRoute , Router } from '@angular/router';
import { Helper } from '../core/services/helper.service';




@NgModule({
  declarations: [],
  imports: []
})

@Component({
  selector: 'login',
  template: require('./login.component.html'),
  styles: [require('./login.component.css').toString()]
  // styles: [require('./login.component.css')]
})
export class LoginComponent implements OnInit, AfterViewChecked {

  @Output() loggedIn = new EventEmitter();
  public routerId: string = null;
  public stepId: string= null;
  public scanBuffer: ScannedTextBuffer = new ScannedTextBuffer();
// ngIf DOM pdf window
  pdfViewable = true;
  @ViewChild('scanBox', {static: true}) scanBox: ElementRef;
  scanValue = 'null';
//
  constructor(private utils: Helper,
    private router: Router,
    private route: ActivatedRoute,
    private userMaintenanceService: UserMaintenanceServiceHttp,
   private auth: AuthService, private loginService: LoginService) {



  }

  ngOnInit() {
    console.log('login.component.ngOnInit');


    this.router.navigate(['/routers']);
    /*
    this.routerId = this.route.snapshot.params['id'];
    this.stepId = this.route.snapshot.params['stepId'];
    console.log(this.routerId);
    console.log(this.stepId);

    if (!this.utils.isEmptyString(this.routerId) || !this.utils.isEmptyString(this.stepId)) {
      console.log('...navigating to /routers');
      this.router.navigate(['/routers']);
    } */

  }


  settingsDropdown() {
    document.getElementById('myDropdown').classList.toggle('show');
  }

  loginUser(login: boolean) {
    this.onScan('SCAN');
  }
  private onScan(data: string) {
    console.log('login.component.onScan START:' + data);
    this.scanValue = '';

    // Call to gatekeeper loginService
     this.loginService.loginUser(data, () => {this.scanBox.nativeElement.value = ''}).subscribe(usr  => {
      // this.auth.loginScan(data).subscribe(resp => {
       console.log('loginScan return ');
        console.log(usr);
        this.scanBox.nativeElement.value = "";  // clear the user scanned value

        if (usr) { //valid user?         
          this.loggedIn.emit(false); // remove the login screen
        } else {
          this.loggedIn.emit(true);  // keep the login screen
        }

        // this.router.navigate(['/routers']);
    })

    console.log('login.component.onScan END');
  }


    public onKeyDown(e: KeyboardEvent): void {
   // console.log('onKeyDown: key:' + e.key + ' code:' + e.code);
   // console.log(e);




    // key event may have been received during transition between views when current subview is null
    this.scanBuffer.readCharCode(e.key);


    if (this.scanBuffer.hasScannedText) {

    this.onScan(this.scanBuffer.scannedText);
    this.scanBuffer.clear();
    }
    }
    viewPDF(data) {
      // sets ngIf pdf viewable to false
          this.pdfViewable = data;
        }
    ngAfterViewChecked() {
      $('#loginField').focus();
    }
    }



    export class ScannedTextBuffer {
    public static WAIT = 0;
    public static CAPTURE = 1;
    public static READY = 2;



    // private static scanPrefix: number[] = [0, 0, 71];
    private static scanPrefixALTG: string[] = ['Alt', 'g'];
    private nextIndex = 0;
    private buffer: string[] = [];
    private status = ScannedTextBuffer.WAIT;
    private scanIDWatcher: SequenceWatcher = new SequenceWatcher(ScannedTextBuffer.scanPrefixALTG);
    private onScanDetected: Function = null;


    constructor (onScanDetected: Function = null) {
    this.buffer = [];
    this.scanIDWatcher.reset();
    this.status = ScannedTextBuffer.WAIT;
    this.onScanDetected = onScanDetected;
    }


    public get hasScannedText(): Boolean
    {
    return this.status === ScannedTextBuffer.READY;
    }


    public get scannedText(): string
    {
      let ret = '';
      if (this.hasScannedText) {
        ret = this.getstringFromCharCodes(this.buffer);
      }
      return ret;
    }


    private getstringFromCharCodes(codes: string[]): string {
      let ret = '';

      if (!Helper.sIsEmptyArray(codes)) {
        for (const code of codes) {
          if (code === 'Shift') {
            continue;
          }
          ret = ret.concat(code);
        }
      }
      return ret;
    }


    public readCharCode(charCode: string): void {
    // after the scan has been detected, the very next char code resets status
    if (this.status === ScannedTextBuffer.READY) {
    this.status = ScannedTextBuffer.WAIT;
    }



    // trace("ScannedTextBuffer code:" + charCode + " charCode:" + string.fromCharCode(charCode));
    this.scanIDWatcher.readCharCode(charCode);


    if (this.status === ScannedTextBuffer.CAPTURE && !this.scanIDWatcher.codeDetected) {
      if (charCode === 'Enter') {
      this.status = ScannedTextBuffer.READY;
      } else {
      this.buffer.push(charCode);
      }
    }


    /*logLine("readCharCode:" +
    " char:" +
    String.fromCharCode(charCode) +
    " charCode:" + charCode.toString(16)+
    " status:" + statusToStr(status) +
    " scanStartDetected:" + String(scanIDWatcher.codeDetected?"TRUE":"FALSE") +
    " buf:" + getStringFromCharCodes(buffer) + " length:" + buffer.length.toString());
    */
    // start of new scan detected;; clear buffer for scanned text capture
    if (this.scanIDWatcher.codeDetected) {
    this.buffer = [];
    this.scanIDWatcher.reset();
    this.status = ScannedTextBuffer.CAPTURE;
    if (this.onScanDetected != null) {
    this.onScanDetected();
    }
    }
    }


    public clear(): void {
    this.scanIDWatcher.reset();
    this.buffer = [];
    this.status = ScannedTextBuffer.WAIT;
    }


    private statusToStr(stat: number): string {
    let ret = '';


    switch (stat) {
    case ScannedTextBuffer.WAIT:
    ret = 'ScannedTextBuffer.WAIT';
    break;
    case ScannedTextBuffer.CAPTURE:
    ret = 'ScannedTextBuffer.CAPTURE';
    break;
    case ScannedTextBuffer.READY:
    ret = 'ScannedTextBuffer.READY';
    break;
    default:
    throw new Error('unexpected status');
    }


    return ret;
    }
    }


export class SequenceWatcher {
  private watchSequence: string[] = null;
  public codeDetected = false;
  private buffer: Array<string>;

  constructor (seq: string[]) {
    if (Helper.sIsEmptyArray(seq)) {
      throw new Error('empty sequence');
    }
    this.watchSequence = seq;
    this.buffer = new Array<string>(seq.length);
    this.initBuffer();
  }

  private initBuffer(): void {
    for (let i = 0; i < this.buffer.length; i++) {
      this.buffer[i] = '';
    }
  }

  private get bufferEqualsWatch(): boolean  {
    let ret = true;

    for (let i = 0; i < this.watchSequence.length; i++) {
      if (this.buffer[i] !== this.watchSequence[i]) {
       ret = false;
       break;
      }
    }
    return ret;
  }


    private addCodeToBuffer(charCode: string): void {
      // shift buffert values to the left
      for (let i = 0; i < this.buffer.length - 1; i++) {
      this.buffer[i] = this.buffer[i + 1];
      }
      this.buffer[this.buffer.length - 1] = charCode;
    }


      public readCharCode(charCode: string): void {
        // console.log('SequenceWatcher.readcharCode:' + charCode);



        if (this.codeDetected) {
        this.codeDetected = false;
        }


        this.addCodeToBuffer(charCode);

       //  console.log(this.watchSequence);
       // console.log(this.buffer);
        // this.dumpBuffer();


        if (this.bufferEqualsWatch) {
          this.codeDetected = true;
          this.initBuffer();
        }
      }


      public reset(): void {
        this.codeDetected = false;
        this.initBuffer();
      }

      private dumpBuffer(): void {
        let str = '';

        for (const charCode of this.buffer)
        {

        str += charCode + ' ';

        }
        console.log(str);
      }
    }
