import { Component, OnInit, OnChanges, DoCheck, SimpleChanges,
  AfterViewInit, AfterContentInit,
  AfterViewChecked, AfterContentChecked, OnDestroy, ViewChild, ElementRef, Input } from '@angular/core';
import { FormGroup, FormControl, FormsModule, Validators } from '@angular/forms';
import { IEtchingInfo } from '../../../core/models/part.model';
import { ActivatedRoute } from '@angular/router';
import { ComponentVisibilitySpecification } from '../../../core/shared/ComponentVisibilitySpecification';
import { Helper } from '../../../core/services/helper.service';
import { IPart } from '../../../core/models/part.model';
import { PartServiceHttp } from '../../../core/services/part.servicehttp';

@Component({
  selector: 'part-ext-info',
  template: require('./part-ext-info.component.html'),
  //styles: [require('./part-ext-info.component.css')]
  styles: [require('./part-ext-info.component.css').toString()]
})
export class PartExtInfoComponent implements OnChanges, OnInit, AfterContentChecked,
AfterViewInit, OnDestroy {

  @Input() selectedPart: IPart;
  partExtFormGroup: FormGroup;
  isFgLotValid: boolean = false;

  constructor(private route: ActivatedRoute
    , private helper: Helper
    , private partService: PartServiceHttp) { }

  ngOnInit() {
    this.partExtFormGroup = new FormGroup({
      partNumber: new FormControl(),
      partDescription: new FormControl(),
      type: new FormControl(),
      unit: new FormControl(),
      trace: new FormControl(),
      fgLot: new FormControl('', Validators.required)
    });

    if (this.selectedPart)
    {
      this.setPartExtFormGroup();

      this.validatePartExtFormGroup();
    }
  }

  ngAfterContentChecked() {
    //console.log('part-ext-info.component.ngAfterContentChecked');
  }

  ngAfterViewInit() {
    //console.log('part-ext-info.component.ngAfterViewInit');
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log('part-ext-info.component.ngOnChanges');
  }

  ngOnDestroy() {
    //console.log('part-ext-info.component.ngOnDestroy');
  }

  setPartExtFormGroup(){
    this.partExtFormGroup.controls.partNumber.setValue(this.selectedPart.number);
    this.partExtFormGroup.controls.partNumber.disable();
    this.partExtFormGroup.controls.partDescription.setValue(this.selectedPart.description);
    this.partExtFormGroup.controls.partDescription.disable();
    this.partExtFormGroup.controls.type.setValue(this.selectedPart.type);
    this.partExtFormGroup.controls.type.disable();
    this.partExtFormGroup.controls.unit.setValue(this.selectedPart.unit);
    this.partExtFormGroup.controls.unit.disable();
    this.partExtFormGroup.controls.trace.setValue(this.selectedPart.trace);
    this.partExtFormGroup.controls.trace.disable();
    this.partExtFormGroup.controls.fgLot.setValue(this.selectedPart.fGLot);
  }

  validatePartExtFormGroup(){
    this.validateFgLot();
  }

  validateFgLot() {
    //-- validate that the value is between 1 and 20
    let fglot:number = Number(this.partExtFormGroup.controls.fgLot.value);

    //-- First check if entered value is a number
    if (!isNaN(Number(fglot)))
    {
      if (fglot >= 1 && fglot <= 20)
      {
        this.isFgLotValid = true;
        this.updatePart();
      }
      else
      {
        this.isFgLotValid = false;
      }
    }
    else
    {
      this.isFgLotValid = false;
    }
  }

  updatePart(){
    console.log('part-ext-info.component.updatePart');

    if (this.selectedPart.fGLot !== Number(this.partExtFormGroup.controls.fgLot.value))
    {
      this.selectedPart.fGLot = Number(this.partExtFormGroup.controls.fgLot.value);
      this.partService.updatePart(this.selectedPart).subscribe(updated => {
        
      });
    }
  }
}
